import {
  GetGuideStructureAvailablePrimaryMappingsQuery,
  GetGuideStructureAvailablePrimaryMappingsQueryVariables,
} from '@api/__gen__/gql'
import { gql, useLazyQuery } from '@apollo/client'
import { isNilOrEmpty } from 'app/helpers'
import { PrimaryMapping } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useEffect, useState } from 'react'

export const GET_GUIDE_STRUCTURE_AVAILABLE_PRIMARY_MAPPINGS = gql`
  query getGuideStructureAvailablePrimaryMappings($guideStructureId: ID!) {
    node(id: $guideStructureId) {
      ... on GuideStructure {
        _id
        availablePrimaryMappings {
          _id
          name
        }
      }
    }
  }
`

export default function useGetAvailablePrimaryMappings(
  shouldFetch: boolean = false,
  mappingsFromContext: PrimaryMapping[] = [],
) {
  const { properties } = useGetProperties()
  const guideStructureId = properties?._id

  const [mappings, setMappings] = useState<PrimaryMapping[]>([])

  const [query, { error, loading }] = useLazyQuery<
    GetGuideStructureAvailablePrimaryMappingsQuery,
    GetGuideStructureAvailablePrimaryMappingsQueryVariables
  >(GET_GUIDE_STRUCTURE_AVAILABLE_PRIMARY_MAPPINGS, {
    onCompleted: (data) => {
      if (
        data?.node?.__typename === 'GuideStructure' &&
        data?.node?.availablePrimaryMappings
      ) {
        const { availablePrimaryMappings } = data.node

        if (!isNilOrEmpty(mappingsFromContext)) {
          const filteredMappings = availablePrimaryMappings.filter(
            (availableMapping) => {
              return (
                mappingsFromContext.findIndex(
                  ({ _id }) => _id === availableMapping._id,
                ) === -1
              )
            },
          )

          setMappings(filteredMappings)
        } else {
          setMappings(availablePrimaryMappings)
        }
      }
    },
  })

  useEffect(() => {
    if (shouldFetch) {
      // eslint-disable-next-line no-inner-declarations
      async function fetchData() {
        await query({ variables: { guideStructureId: guideStructureId! } })
      }

      if (guideStructureId) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetchData()
      }
    }
  }, [guideStructureId, query, shouldFetch])

  return {
    availablePrimaryMappings: mappings,
    error,
    loading,
  }
}
