/* eslint-disable @typescript-eslint/no-shadow */
import {
  AutocompleteChangeReason,
  Checkbox,
  CircularProgress,
  ListItem,
  Autocomplete as MuiAutocomplete,
  createFilterOptions,
} from '@mui/material'
import TextField from 'app/components/TextField/TextField'
import { EM_DASH } from 'app/constants'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const StyledAutocomplete = styled(MuiAutocomplete)`
  & .MuiFormControl-root {
    margin-bottom: 0;
    margin-top: 0;
  }
` as typeof MuiAutocomplete

const StyledOption = styled(ListItem)`
  :&hover  {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }

  &.MuiAutocomplete-option[aria-selected='true'].Mui-focused {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }

  &.MuiAutocomplete-option[aria-selected='true'] {
    background-color: ${({ theme }) => theme.colors.neutral_06};
  }
`

const StyledCheckboxOption = styled.li<{ $selectAllOption: boolean }>`
  display: flex;
  column-gap: ${toRem(8)};
  border-bottom: ${({ $selectAllOption, theme }) =>
    $selectAllOption ? `1px solid ${theme.colors.neutral_05}` : 'none'};
`

const StyledChip = styled.div`
  align-items: center;
  display: inline-flex;
  background-color: ${({ theme }) => theme.colors.highlight_01};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
`

const StyledChipLabel = styled.span`
  overflow: hidden;
  padding: 0 ${toRem(8)};
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 172px;
`

const StyledLimitTag = styled.span`
  font-weight: bold;
  margin-left: ${toRem(8)};
`

interface DefaultProps {
  className?: string
  disabled?: boolean
  error?: boolean
  label: string
  loading?: boolean
  onInputChange?: (event: React.SyntheticEvent, inputValue: string) => void
  options: Option[]
  required?: boolean
  getOptionDisabled?: (option: Option) => boolean
  disallowSelectAll?: boolean
}

export interface AutocompleteProps extends DefaultProps {
  onChange: (
    event: React.SyntheticEvent,
    option: Option | null,
    reason?: AutocompleteChangeReason,
  ) => void
  value: Option | null
}

export interface MultiAutocompleteProps extends DefaultProps {
  onChange: (
    event: React.SyntheticEvent,
    option: Option[],
    reason?: AutocompleteChangeReason,
  ) => void
  value: Option[]
}

const filter = createFilterOptions<Option>()

export function MultiAutocomplete({
  className = '',
  disabled,
  error,
  label,
  loading,
  onChange,
  onInputChange,
  options,
  required,
  value,
  getOptionDisabled,
  disallowSelectAll,
}: MultiAutocompleteProps) {
  const allOptionsSelected = value.length === options.length

  const handleOnChange = (
    event: React.SyntheticEvent,
    selectedOptions: Option[],
    reason: AutocompleteChangeReason,
  ) => {
    const isSelectAll =
      selectedOptions.length &&
      selectedOptions[selectedOptions.length - 1].value === 'select-all'

    if ((isSelectAll && allOptionsSelected) || reason === 'clear') {
      onChange(event, [])
    } else if (isSelectAll && !allOptionsSelected) {
      onChange(event, options)
    } else {
      onChange(event, selectedOptions)
    }
  }

  return (
    <StyledAutocomplete
      disableCloseOnSelect
      className={className}
      disabled={disabled}
      filterOptions={(options, params) => {
        if (disallowSelectAll) return filter(options, params)
        return [
          { label: 'All', value: 'select-all' },
          ...filter(options, params),
        ]
      }}
      getOptionLabel={(o) => o.label}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      multiple
      getOptionDisabled={getOptionDisabled}
      onChange={handleOnChange}
      onInputChange={
        onInputChange
          ? (event, inputValue: string) => onInputChange(event, inputValue)
          : undefined
      }
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            label={label}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <StyledCheckboxOption
            {...props}
            $selectAllOption={option.value === 'select-all'}>
            <Checkbox checked={allOptionsSelected || selected} />
            {option.label}
          </StyledCheckboxOption>
        )
      }}
      renderTags={(currentSelection) => {
        const totalTags = currentSelection.length

        if (!totalTags) {
          return null
        }

        const firstSelection = currentSelection[0]
        const displayName = firstSelection.label ?? EM_DASH

        return (
          <>
            <StyledChip>
              <StyledChipLabel title={displayName}>
                {displayName}
              </StyledChipLabel>
            </StyledChip>

            {totalTags > 1 && <StyledLimitTag>+{totalTags - 1}</StyledLimitTag>}
          </>
        )
      }}
      size="small"
      value={value}
    />
  )
}

export function Autocomplete({
  className = '',
  disabled,
  error,
  label,
  loading,
  onChange,
  onInputChange,
  options,
  required,
  value,
}: AutocompleteProps) {
  return (
    <StyledAutocomplete
      autoHighlight
      className={className}
      disabled={disabled}
      getOptionLabel={(o) => o.label}
      isOptionEqualToValue={(o, v) => o.value === v.value}
      loading={loading}
      onChange={onChange}
      onInputChange={
        onInputChange
          ? (event, inputValue: string) => onInputChange(event, inputValue)
          : undefined
      }
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            error={error}
            label={label}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </>
              ),
            }}
          />
        )
      }}
      renderOption={(props, option) => {
        return <StyledOption {...props}>{option.label}</StyledOption>
      }}
      value={value}
    />
  )
}
