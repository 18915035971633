import { Fade } from '@mui/material'
import { Box } from '@mui/system'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import Button from 'app/components/Button/Button'
import Collapse from 'app/components/Icons/Collapse'
import Signout from 'app/components/Icons/Signout'
import LogoIcon from 'app/components/Logo/LogoIcon'
import LogoWithEnvironment from 'app/components/Logo/LogoWithEnvironment'
import SectionTitle from 'app/components/SectionTitle/SectionTitle'
import Tooltip from 'app/components/Tooltip/Tooltip'
import { ROLES } from 'app/constants'
import {
  BottomActionsList,
  PrimarySideNavListItem,
  StyledAvatar,
  getUserInitials,
} from 'app/layouts/Customer/AppSideNav/PrimarySideNav'
import InternalSideNavLink from 'app/layouts/Internal/InternalSideNav/InternalSideNavLink'
import useInternalNav from 'app/layouts/Internal/InternalSideNav/useInternalNav.hook'
import { SideNavDrawer } from 'app/layouts/SideNavDrawer'
import { LOGOUT_PATH } from 'app/packages/core/pages/logout/logout.constants'
import { USER_SCOPE_MAP } from 'app/packages/core/pages/users/users.constants'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const SIDEBAR_WIDTH = 285
const PRIMARY_SIDE_NAV_WIDTH = 56

const StyledBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.neutral_02};
  color: ${({ theme }) => theme.colors.neutral_08};
`

const StyledHR = styled.hr<{ $isManualTestUser: boolean }>`
  margin: 20px;
  margin-bottom: ${({ $isManualTestUser }) =>
    $isManualTestUser ? '10px' : '20px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $isManualTestUser, theme }) =>
    $isManualTestUser ? theme.colors.caution : theme.colors.neutral_08};
  border-bottom: none;
`

const StyledFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledUserScope = styled.span`
  color: ${({ theme }) => theme.colors.caution};
`

const StyledLogoContainer = styled(Box)`
  margin-left: 16px;
  margin-top: 16px;
  display: flex;
  height: 40px;

  :hover {
    cursor: pointer;
  }
`

const StyledSectionTitle = styled(SectionTitle)<{ $sideNavOpen: boolean }>`
  margin-left: ${({ $sideNavOpen }) => !$sideNavOpen && '-6px'};
`

export const StyledCollapse = styled(Collapse)`
  color: ${({ theme }) => theme.colors.neutral_08};
  opacity: 1;
  transition: opacity ${({ theme }) => theme.transitions.duration.shortest}ms;
`

export default function InternalSideNav({
  sideNavOpen,
  toggleSideNav,
}: {
  sideNavOpen: boolean
  toggleSideNav: (open: boolean) => void
}) {
  const {
    accessRole,
    name,
    permissions,
    userScope,
  } = useGetCustomerPermissions()
  const navigate = useNavigate()

  const isAdmin = ROLES.AFRESH_ADMIN === (accessRole || ROLES.AFRESH_ADMIN)

  const { sections } = useInternalNav({
    isAdmin,
    capabilities: permissions || [],
  })
  const isManualTestUser = userScope === 'MANUAL_TEST'

  const handleDrawerClose = () => {
    toggleSideNav(false)
  }

  return (
    <SideNavDrawer
      anchor="left"
      open={sideNavOpen}
      variant="permanent"
      $openWidth={SIDEBAR_WIDTH}
      $closedWidth={PRIMARY_SIDE_NAV_WIDTH}>
      <StyledBox sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <StyledLogoContainer onClick={() => navigate('/')}>
            {sideNavOpen ? (
              <LogoWithEnvironment logoColor="neutral_08" inverted />
            ) : (
              <LogoIcon color="neutral_08" />
            )}
          </StyledLogoContainer>
          {sideNavOpen && (
            <Button
              variant="icon"
              onClick={handleDrawerClose}
              aria-label="Collapse Sidebar">
              <Fade in={sideNavOpen}>
                <StyledCollapse />
              </Fade>
            </Button>
          )}
        </Box>
        {sections.map((s) => (
          <Box key={s.title} sx={{ marginTop: '24px' }}>
            {s.title && (
              <StyledSectionTitle $sideNavOpen={sideNavOpen}>
                {s.title}
              </StyledSectionTitle>
            )}
            {s.items.map((item) => item.component)}
          </Box>
        ))}
      </StyledBox>
      <StyledBox sx={{ paddingBottom: '20px' }}>
        {sideNavOpen ? (
          <>
            <StyledHR $isManualTestUser={isManualTestUser} />
            <Box
              sx={{
                padding: '0 24px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '12px',
              }}>
              <StyledFlexColumn>
                {userScope && userScope !== 'DEFAULT' && (
                  <StyledUserScope>{USER_SCOPE_MAP[userScope]}</StyledUserScope>
                )}
                <span>{name || ''}</span>
              </StyledFlexColumn>
            </Box>
            <InternalSideNavLink
              icon={Signout}
              label="Sign out"
              route={LOGOUT_PATH}
            />
          </>
        ) : (
          <BottomActionsList>
            {userScope && userScope !== 'DEFAULT' && (
              <StyledUserScope>{USER_SCOPE_MAP[userScope]}</StyledUserScope>
            )}
            <Tooltip title={name || ''} placement="right">
              <StyledAvatar>{getUserInitials(name)}</StyledAvatar>
            </Tooltip>
            <PrimarySideNavListItem
              inverseColor
              icon={<Signout />}
              name="Sign out"
              to={{ pathname: '/logout' }}
            />
          </BottomActionsList>
        )}
      </StyledBox>
    </SideNavDrawer>
  )
}
