import dayjs, { OpUnitType, QUnitType } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(weekOfYear)
dayjs.extend(isBetween)

export function getWeekOfYear(date: dayjs.Dayjs) {
  return date.week()
}

export function addDuration(
  date: string | Date,
  amount: number,
  unit: 'hours' | 'days' | 'weeks',
): Date {
  return dayjs(date).add(amount, unit).toDate()
}

export function convertToTimezone(date: string | Date, tz: string): Date {
  return dayjs(date).tz(tz, true).toDate()
}

export function getISODateString(value: string | Date): string {
  return dayjs(value).format('YYYY-MM-DD')
}
export function getDiffFromNow(
  dateA: string | Date,
  unit: QUnitType | OpUnitType,
): number {
  return dayjs(dateA).diff(dayjs(), unit)
}

export function getDaysDiff(
  dateA: string | Date,
  dateB: string | Date,
): number {
  return dayjs(dateA).diff(dateB, 'days')
}

export function isValid(date: Date): boolean {
  return dayjs(date).isValid()
}

export function formatDatetime(
  datetime: string | number | Date,
  options?: {
    format?: string
    timezone?: string
  },
): string {
  const format = options?.format || 'M/D/YYYY h:mm A'
  return options?.timezone
    ? dayjs(datetime).tz(options?.timezone).format(format)
    : dayjs(datetime).format(format)
}

export function formatDate(date: string | Date): string {
  return dayjs(date).format('MMM DD, YYYY')
}

export function sortDates(dateA: string | Date, dateB: string | Date): number {
  if (!dayjs(dateA).isValid() || !dayjs(dateB).isValid()) {
    return 0
  }

  return dayjs(dateA).valueOf() - dayjs(dateB).valueOf()
}

export function todayISODateString(): string {
  return dayjs().format('YYYY-MM-DD')
}

export function getDateValue(datetime: string | number | Date): number {
  return dayjs(datetime).valueOf()
}

export function getStartOfWeekDateString(date: string | Date): string {
  return dayjs(date).startOf('week').format('YYYY-MM-DD')
}

export function getEndOfWeekDateString(date: string | Date): string {
  return dayjs(date).endOf('week').format('YYYY-MM-DD')
}

export function equals(dateA: string | Date, dateB: string | Date): boolean {
  return dayjs(dateA).isSame(dayjs(dateB))
}

export function dateIsBetween(
  date: string | Date,
  startDate: string | Date,
  endDate: string | Date,
): boolean {
  return (
    dayjs(date).isSameOrAfter(startDate) && dayjs(date).isSameOrBefore(endDate)
  )
}

export function isAfterNow(date: string | Date) {
  return dayjs(date).isAfter(dayjs())
}

export function isBefore(dateA: string | Date, dateB: string | Date) {
  return dayjs(dateA).isBefore(dateB)
}

export function isSame(dateA: string | Date, dateB: string | Date) {
  return dayjs(dateA).isSame(dateB)
}

export function isSameDateDay(dateA: Date, dateB: Date) {
  return dateA.toISOString().slice(0, 10) === dateB.toISOString().slice(0, 10)
}
