import styled, { css } from 'styled-components'

export const StyledList = styled.div<{ height?: string }>`
  overflow-y: scroll;
  width: 100%;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`

export const StyledVirtualizedList = styled.div<{ totalSize: number }>`
  height: ${(props) => props.totalSize}px;
  position: relative;
  width: 100%;
`
