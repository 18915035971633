import { useReactiveVar } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import Accordion from 'app/components/Accordion/Accordion'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useGetDepartmentBundles } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartmentBundles'
import { $activeDepartmentBundleConfig } from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManager.variables'
import DepartmentBundleManagerAddEditModal from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManagerAddEditModal'
import DepartmentBundleManagerDeleteModal from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManagerDeleteModal'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import { toRem } from 'app/styles'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import DepartmentBundleManagerTable from './DepartmentBundleManagerTable'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`

export default function DepartmentBundleManager({
  disableVirtualization = false,
}: {
  disableVirtualization?: boolean
}) {
  const { activeCustomerId } = useCustomers()
  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const activeDepartmentBundleManagerConfig = useReactiveVar(
    $activeDepartmentBundleConfig,
  )

  const { rows, loading } = useGetDepartmentBundles({
    customerId: activeCustomerId,
  })

  useEffect(() => {
    const shouldSetEditState =
      activeDepartmentBundleManagerConfig !== null &&
      modalState !== ModalState.edit &&
      modalState !== ModalState.delete
    if (shouldSetEditState) {
      setModalState(ModalState.edit)
    }
  }, [activeDepartmentBundleManagerConfig, modalState, setModalState])

  const onSuccessfulSave = useCallback(async () => {
    setModalState(null)
    $activeDepartmentBundleConfig(null)
  }, [setModalState])

  const activeRow =
    activeDepartmentBundleManagerConfig !== null &&
    rows.find((row) => activeDepartmentBundleManagerConfig === row.id)

  return (
    <>
      <StyledHeader>Department Bundles</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          This configuration is used to define department bundles and the
          associated departments and products.
          <p>
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/afresh/Department-Bundle-Config-Tool-5d9cb22336bf4e95a495c7eec75950e0',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <DepartmentBundleManagerTable
        rows={rows}
        disableVirtualization={disableVirtualization}
        isLoading={loading}
        onClickAddNew={() => setModalState(ModalState.add)}
        setupDetailsExpanded={setupDetailsExpanded}
      />
      {modalState === ModalState.add && (
        <DepartmentBundleManagerAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeDepartmentBundleConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          rows={rows}
        />
      )}
      {modalState === ModalState.edit && activeRow && (
        <DepartmentBundleManagerAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeDepartmentBundleConfig(null)
            setModalState(null)
          }}
          onDelete={() => setModalState(ModalState.delete)}
          onSuccess={onSuccessfulSave}
          rows={rows}
          activeRow={activeRow}
        />
      )}
      {modalState === ModalState.delete && activeRow && (
        <DepartmentBundleManagerDeleteModal
          onClose={() => {
            $activeDepartmentBundleConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
        />
      )}
    </>
  )
}
