import { gql } from '@apollo/client'

export const CONFIG_AD_SCHEDULE_ROW_FIELDS = gql`
  fragment ConfigAdSchedulesRowFields on ConfigAdScheduleRow {
    _id
    id {
      label
      value
    }
    workflowId {
      label
      value
    }
    businessLevel {
      label
      value
    }
    businessLevelId {
      label
      value
    }
    startDate {
      label
      value
    }
    intervalWeeks {
      label
      value
    }
    createdAt {
      label
      value
    }
    updatedAt {
      label
      value
    }
  }
`
