import {
  DeleteGuideCategoryMutation,
  DeleteGuideCategoryMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { msgByErrorCode } from 'app/packages/storeSolutions/guideOrganization/api/errors'
import { GUIDE_STRUCTURE_CATEGORY_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureCategoryFields'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_GUIDE_STRUCTURE_CATEGORY = gql`
  ${GUIDE_STRUCTURE_CATEGORY_FIELDS}
  mutation deleteGuideCategory(
    $guideStructureId: ID!
    $guideStructureCategoryId: ID!
    $categoryIdForMappings: ID
  ) {
    deleteGuideStructureCategory(
      input: {
        guideStructureId: $guideStructureId
        guideStructureCategoryId: $guideStructureCategoryId
        categoryIdForMappings: $categoryIdForMappings
      }
    ) {
      guideStructureCategory {
        ...GuideStructureCategoryFields
      }
    }
  }
`

export default function useDeleteCategory() {
  const { properties } = useGetProperties()
  const guideStructureId = properties?._id

  const [mutation, result] = useMutation<
    DeleteGuideCategoryMutation,
    DeleteGuideCategoryMutationVariables
  >(DELETE_GUIDE_STRUCTURE_CATEGORY, {
    errorPolicy: 'none',
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors.length) {
        throw new Error(
          msgByErrorCode[getFirstErrorCode(graphQLErrors)] ||
            'Unable to process request.',
        )
      }

      if (networkError) {
        Sentry.captureMessage('Web Portal Network Error', {
          level: 'warning',
          extra: {
            networkError: JSON.stringify(networkError),
          },
        })

        throw new Error(
          `Unable to save due to the following network error: ${networkError}`,
        )
      }
    },
    refetchQueries: ['getGuideStructureCategories'],
  })

  return tuple([
    useCallback(
      async ({
        categoryId,
        categoryIdForMappings,
      }: {
        categoryId: string
        categoryIdForMappings?: string
      }) => {
        if (!guideStructureId) {
          throw new Error('Guide structure ID not found')
        }
        await mutation({
          variables: {
            guideStructureId,
            guideStructureCategoryId: categoryId,
            categoryIdForMappings,
          },
        })
      },
      [guideStructureId, mutation],
    ),
    result,
  ])
}
