import { gql } from '@apollo/client'

export const UPDATE_CONFIGURATIONS = gql`
  mutation updateConfigurations(
    $configurationInputs: [UpdateConfigurationInput!]!
  ) {
    updateConfigurations(input: $configurationInputs) {
      errors {
        configuration {
          name
          value
          type
          storeId
          workflowId
          categoryId
          itemId
        }
        message
        code
      }
    }
  }
`
