import { InventoryResultsDataFilters } from '@api/__gen__/gql'
import { useCustomers } from 'app/CustomerContext'
import { useGetCurrentInventories } from 'app/packages/inventoryManagement/inventoryResults/api/queries/useGetCurrentInventories'
import { useInventoryResultsContext } from 'app/packages/inventoryManagement/inventoryResults/context/inventoryResultsContext'
import EndingInventoriesTable, {
  EMPTY_FILTERS,
  PeriodType,
} from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/EndingInventoriesTable'
import { periodName } from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.helpers'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function InventoryResultsCurrent() {
  const navigate = useNavigate()
  const { activeCustomerKey } = useCustomers()
  const {
    isCountingPeriodActive,
    activeAccountingPeriod,
  } = useInventoryResultsContext()
  const [selectedFilters, setSelectedFilters] = useState<
    InventoryResultsDataFilters
  >(EMPTY_FILTERS)

  const currentInventoriesData = useGetCurrentInventories(selectedFilters)

  useEffect(() => {
    if (
      !isCountingPeriodActive &&
      !currentInventoriesData.isLoadingEndingInventories
    ) {
      navigate(`/${activeCustomerKey}/inventory-results/past`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCountingPeriodActive,
    currentInventoriesData.isLoadingEndingInventories,
  ])

  const setPartialFilters = (
    partialFilters: Partial<InventoryResultsDataFilters>,
  ) =>
    setSelectedFilters((currentFilters) => ({
      ...currentFilters,
      ...partialFilters,
    }))

  return (
    <EndingInventoriesTable
      periodType={PeriodType.CURRENT}
      inventoriesData={currentInventoriesData}
      headerText={periodName(activeAccountingPeriod)}
      selectedFilters={selectedFilters}
      setSelectedFilters={setPartialFilters}
      clearFiltersFunction={() => setSelectedFilters(EMPTY_FILTERS)}
    />
  )
}
