import { gql } from '@apollo/client'
import { ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/OrderDeliveryEvent'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'

export const STORE_WORKFLOW_SCHEDULE_FIELDS = gql`
  ${STORE_FIELDS}
  ${ORDER_DELIVERY_EVENT_FIELDS}
  fragment StoreWorkflowScheduleFields on OrderDeliveryStoreWorkflowSchedule {
    _id
    store {
      ...StoreFields
    }
    orderDeliveryEvents(
      startDate: $startDate
      endDate: $endDate
      includeApplied: $includeApplied
    ) {
      ...OrderDeliveryEventFields
    }
  }
`
