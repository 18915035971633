import { useCustomers } from 'app/CustomerContext'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import useGetProductBundles from 'app/api/queries/useGetProductBundles'
import { Spinner } from 'app/components/Spinner/Spinner'
import { LOGIN_PATH } from 'app/packages/core/pages/login/login.constants'
import { useAuthState, useIsAuthenticated } from 'app/state/authentication'
import React, { useEffect } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

const StyledSpinner = styled(Spinner)`
  height: 100vh;
`

export default function AuthorizedCustomerAccess({
  children,
}: {
  children: React.ReactNode
}) {
  const authState = useAuthState()
  const { customerKey } = useParams()
  const { activeCustomer, setActiveCustomer, customers } = useCustomers()
  const isAuthenticated = useIsAuthenticated(authState)
  const { loading: productBundlesLoading } = useGetProductBundles()
  const { loading: customerPermissionsLoading } = useGetCustomerPermissions()

  const navigate = useNavigate()

  // Add a customer key if none is entered
  useEffect(() => {
    if (!customerKey && customers?.length) {
      navigate(`/${customers[0]?.key}`)
    }
  }, [customers, customerKey, navigate])

  // pass active customerID up to the customer context
  useEffect(() => {
    if (customerKey !== activeCustomer?.key && customers) {
      const newCustomer = customers?.find((c) => c?.key === customerKey)
      if (newCustomer) setActiveCustomer(newCustomer)
    }
  }, [customerKey, activeCustomer, setActiveCustomer, customers])

  if (!isAuthenticated) {
    authState.nextLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`
    return <Navigate to={LOGIN_PATH} replace />
  }

  // If the customer is not in the list of customers, redirect to 404
  if (
    customers &&
    customerKey &&
    !customers?.find((c) => c?.key === customerKey)
  ) {
    return <Navigate to="/page-not-found" replace />
  }

  // If customer permissions or product bundles loading, show spinner
  // If customer key has changed and does not match the active customer key show spinner.
  if (
    customerPermissionsLoading ||
    productBundlesLoading ||
    (customerKey !== activeCustomer?.key && customers)
  ) {
    return <StyledSpinner />
  }

  return <>{children}</>
}
