import { Product, ProductBundle } from 'app/api/queries/useGetProductBundles'
import { stringifyParams } from 'app/helpers/queryString'
import {
  PRODUCT,
  PRODUCT_BUNDLE_TYPE,
  productRouteMap,
} from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import { Path } from 'react-router-dom'

export function getFirstWorkflowId(productBundle: ProductBundle) {
  return (
    productBundle.departmentBundles &&
    productBundle.departmentBundles[0]?.products[0] &&
    productBundle.departmentBundles[0]?.products[0]?.workflows &&
    productBundle.departmentBundles[0]?.products[0]?.workflows[0] &&
    productBundle.departmentBundles[0]?.products[0]?.workflows[0]?.id
  )
}

export function matchRoutes(routes: string[], activePath: string) {
  return routes.some((route) => activePath.includes(route))
}

export function matchProductRouteToPath(
  products: Product[],
  activePath: string,
) {
  let activeProduct: PRODUCT = PRODUCT.GUIDE_ORGANIZATION
  products.forEach((product: Product) => {
    if (matchRoutes(product.routes, activePath)) {
      activeProduct = product.type
    }
  })
  return activeProduct
}

export function getActiveProductBundle(
  productBundles: ProductBundle[],
  activePath: string,
): ProductBundle {
  let activeBundle: PRODUCT_BUNDLE_TYPE = PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS
  productBundles.forEach((productBundle) => {
    if (productBundle.departmentBundles) {
      productBundle.departmentBundles.forEach((departmentBundle) => {
        departmentBundle.products.forEach((product) => {
          if (matchRoutes(product.routes, activePath))
            activeBundle = productBundle.type
        })
      })
    }
    if (productBundle.products) {
      productBundle.products.forEach((product) => {
        if (matchRoutes(product.routes, activePath))
          activeBundle = productBundle.type
      })
    }
  })

  return (
    productBundles.find(
      (productBundle) => productBundle.type === activeBundle,
    ) || productBundles[0]
  )
}

export function getDefaultRouteForPrimaryNav(productBundle: ProductBundle) {
  if (
    productBundle.type === PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS &&
    productBundle.departmentBundles
  ) {
    const workflowId = getFirstWorkflowId(productBundle)
    return {
      pathname: productBundle.departmentBundles[0]?.products[0]?.routes[0],
      search: stringifyParams({
        deptBundleId: productBundle?.departmentBundles[0]?.id,
        ...(workflowId && { workflowId }),
      }),
    }
  }

  if (productBundle.products && productBundle.products.length) {
    return { pathname: productBundle.products[0]?.routes[0] }
  }

  return { pathname: '/' }
}

export function getRedirectRoute(
  productBundles: ProductBundle[],
  activeCustomerKey: string,
) {
  const availableRoutes: Partial<Path>[] = []
  productBundles.forEach((productBundle) => {
    if (productBundle.departmentBundles) {
      productBundle.departmentBundles.forEach((departmentBundle) => {
        departmentBundle.products.forEach((product) => {
          if (product.workflows) {
            product.workflows.forEach((workflow) => {
              availableRoutes.push({
                pathname: `/${activeCustomerKey}/${
                  productRouteMap[product.type][0]
                }`,
                search: stringifyParams({
                  workflowId: workflow.id,
                  deptBundleId: departmentBundle.id,
                }),
              })
            })
          } else {
            availableRoutes.push({
              pathname: `/${activeCustomerKey}/${
                productRouteMap[product.type][0]
              }`,
              search: stringifyParams({
                deptBundleId: departmentBundle.id,
              }),
            })
          }
        })
      })
    }
    if (productBundle.products) {
      productBundle.products.forEach((product) => {
        availableRoutes.push({
          pathname: `/${activeCustomerKey}/${productRouteMap[product.type][0]}`,
        })
      })
    }
  })
  return availableRoutes[0]
}
