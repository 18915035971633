import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.56 10.69L12.5 9.75L14.56 8.81L15.5 6.75L16.44 8.81L18.5 9.75L16.44 10.69L15.5 12.75L14.56 10.69ZM4.5 14.75L5.44 12.69L7.5 11.75L5.44 10.81L4.5 8.75L3.56 10.81L1.5 11.75L3.56 12.69L4.5 14.75ZM9 9.75L10.09 7.34L12.5 6.25L10.09 5.16L9 2.75L7.91 5.16L5.5 6.25L7.91 7.34L9 9.75ZM5 21.25L11 15.24L15 19.24L23.5 9.68L22.09 8.27L15 16.24L11 12.24L3.5 19.75L5 21.25Z"
      fill="#121212"
    />
  </svg>,
  'OpsInsights',
)
