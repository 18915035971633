import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import TextField from 'app/components/TextField/TextField'
import React, { useState } from 'react'
import { BusinessLevelConfigs } from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/api/queries/useGetBusinessLevels'
import { toRem } from 'app/styles'
import styled from 'styled-components'
import useUpdateBusinessLevelConfig from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/api/mutations/useUpdateBusinessLevelConfigs'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'

const StyledTextField = styled(TextField)`
  display: flex;
  margin-top: ${toRem(16)};
`

interface ModalProps {
  row: BusinessLevelConfigs
  onClose: () => void
}

export default function EditDisplayNameModal({ row, onClose }: ModalProps) {
  const [updateConfig, { loading }] = useUpdateBusinessLevelConfig()

  const [displayName, setDisplayName] = useState(row.displayName.label)
  const [error, setError] = useState('')

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value)
  }

  const handleOnSave = async () => {
    try {
      await updateConfig(row.businessLevel.value, displayName)
      onClose()
    } catch (err) {
      setError((err as Error).message)
    }
  }

  return (
    <Dialog isOpen onClose={onClose}>
      <Header onClose={onClose}>
        <Title>{`Edit ʻ${row.businessLevel.label}ʼ Display Name`}</Title>
      </Header>
      <Body>
        Enter the display name for <strong>ʻ{row.businessLevel.label}ʼ</strong>
        <StyledTextField
          label={`ʻ${row.businessLevel.label}ʼ Display Name`}
          onChange={handleOnChange}
          required
          value={displayName}
        />
        {error && <AlertMessageBlock message={error} severityType="error" />}
      </Body>
      <Footer>
        <FooterActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!displayName}
            loading={loading}
            onClick={handleOnSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
