import { Tooltip as MUITooltip, TooltipProps } from '@mui/material'
import Button from 'app/components/Button/Button'
import React from 'react'
import styled from 'styled-components'

export const StyledTooltip = styled(MUITooltip)`
  height: 18px;
  width: 18px;
  svg {
    height: inherit;
    width: inherit;
  }
`

export interface IconTooltipProps {
  children: React.ReactElement<any, any>
  placement?: TooltipProps['placement']
  title: TooltipProps['title']
  id?: string
}

export default function Tooltip({
  children,
  placement = 'bottom',
  title,
  id = 'tooltip',
  ...rest
}: IconTooltipProps) {
  return (
    <StyledTooltip
      {...rest}
      enterDelay={400}
      enterNextDelay={400}
      placement={placement}
      title={title}
      id={id}>
      <Button variant="icon" aria-label="More info" aria-describedby={id}>
        {children}
      </Button>
    </StyledTooltip>
  )
}
