import CardTitle from 'app/components/CardTitle/CardTitle'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import { IOS_APP_REDIRECT_PATH_PREFIX } from 'app/packages/core/pages/login/login.constants'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import assignLocation from 'shared/assignLocation'

export default function IosAppRedirect() {
  const { pathname, search, hash } = useLocation()

  const appPath = pathname.replace(IOS_APP_REDIRECT_PATH_PREFIX, '')
  const appUrl = `afresh:/${appPath}${search}${hash}`

  useEffect(() => {
    assignLocation(appUrl)
  }, [appUrl])

  return (
    <SingleCardPage width={480}>
      <CardTitle>SSO for iOS app</CardTitle>
      Redirecting to app...
    </SingleCardPage>
  )
}
