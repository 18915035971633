import {
  BusinessLevelOptionsQuery,
  BusinessLevelOptionsQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { isNil } from 'lodash-es'
import { Option } from 'shared/types'

export const GET_BUSINESS_LEVEL_OPTIONS = gql`
  query businessLevelOptions($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        businessLevels {
          level
          displayName
        }
      }
    }
  }
`

export default function useGetBusinessLevelOptions() {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<
    BusinessLevelOptionsQuery,
    BusinessLevelOptionsQueryVariables
  >(GET_BUSINESS_LEVEL_OPTIONS, {
    skip: isNil(activeCustomerId),
    variables: { customerId: activeCustomerId! },
  })

  let businessLevelOptions: Option[] = []

  if (
    data &&
    data.node &&
    data.node.__typename === 'Customer' &&
    data.node.businessLevels
  ) {
    businessLevelOptions = data.node.businessLevels.map((businessLevel) => ({
      label: businessLevel.displayName,
      value: businessLevel.level,
    }))
  }

  return {
    data: businessLevelOptions,
    isLoading: loading,
  }
}
