import { Box, Theme } from '@mui/material'
import { useCustomers } from 'app/CustomerContext'
import Import from 'app/components/Icons/Import'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const StyledHeader = styled(Box)<{ theme: Theme }>`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: bold;
  margin-top: 24px;
  margin-left: 16px;
  color: ${({ theme }) => theme.colors.neutral_02};
`
const StyledInternalHomeMain = styled(Box)<{ theme: Theme }>`
  padding-left: 112px;
  padding-top: 8px;
  height: 100vh;
`

const StyledSubheader = styled.h3<{ theme: Theme }>`
  font-weight: 700;
  font-size: 20px;
  margin: 16px 0px;
  color: ${({ theme }) => theme.colors.neutral_02};
`

const StyledSubtext = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 16px 0;
  padding: 0px;
`

const StyledCustomerAdminSection = styled(Box)`
  padding-top: 16px;
`

const LinkTextContainer = styled.span`
  margin: 0px 8px;
`

const StyledCustomersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const CustomerLink = styled(Link)`
  line-height: 19px;
`

const ExternalLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 16px;
`

export default function InternalHome() {
  const { customers, activeCustomerId, setActiveCustomer } = useCustomers()

  useEffect(() => {
    if (activeCustomerId) {
      setActiveCustomer(null)
    }
  }, [setActiveCustomer, activeCustomerId])

  return (
    <>
      <StyledHeader>Admin Home</StyledHeader>
      <StyledInternalHomeMain>
        <StyledSubheader>GENERAL ADMIN</StyledSubheader>
        <ExternalLink to={{ pathname: '/dashboard/integrations' }} launch>
          <Import />
          <LinkTextContainer>All File Uploads</LinkTextContainer>
          <OpenInNew />
        </ExternalLink>
        <StyledCustomerAdminSection>
          <StyledSubheader>CUSTOMER ADMIN</StyledSubheader>
          <StyledSubtext>
            Choose a customer to manage users, adjust configurations, and access
            Eng tools.{' '}
          </StyledSubtext>
          <StyledCustomersContainer>
            {customers?.map((customer) => {
              if (!customer) return null
              return (
                <CustomerLink
                  key={customer.id}
                  to={{ pathname: `/internal/${customer.key}/users` }}>
                  {customer.name}
                </CustomerLink>
              )
            })}
          </StyledCustomersContainer>
        </StyledCustomerAdminSection>
      </StyledInternalHomeMain>
    </>
  )
}
