import { AuthTestQuery, AuthTestQueryVariables } from '@api/__gen__/gql'
import { ApolloClient, ApolloProvider, gql } from '@apollo/client'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { Router } from '@remix-run/router'
import { useApolloClient } from 'app/api/common/useApolloClient'
import { USER_DETAILS } from 'app/api/fragments/UserDetails'
import { useUserContext } from 'app/context/userContext'
import { datalayer } from 'app/datalayer'
import { initializeUserWithExternalSDKs } from 'app/packages/core/pages/login/login.helpers'
import {
  AuthContext,
  AuthenticationState,
  useIsAuthenticated,
} from 'app/state/authentication'
import { getTheme } from 'app/styles'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useRef } from 'react'
import { RouterProvider } from 'react-router-dom'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

export const authTestGql = gql`
  ${USER_DETAILS}
  query authTest {
    viewer {
      user2 {
        ...UserDetails
      }
    }
  }
`

interface Props {
  // use this client instead of the one we create ourserves (for testing)
  apolloClient?: ApolloClient<object>
  router: Router
}

export default function App(props: Props) {
  const authState = useRef(new AuthenticationState())
  const isAuthenticated = useIsAuthenticated(authState.current)
  const { client } = useApolloClient(authState.current, props.apolloClient)
  const { setUser } = useUserContext()

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (authState.current.getTokens() && !authState.current.isAuthenticated) {
        // do a GQL query to see if the tokens are valid
        const result = await client.query<
          AuthTestQuery,
          AuthTestQueryVariables
        >({
          query: authTestGql,
          fetchPolicy: 'no-cache',
        })
        if (result.data.viewer?.user2) {
          const { user2: userData } = result.data.viewer
          const user = setUser(userData)
          initializeUserWithExternalSDKs(user)

          authState.current.isAuthenticated = true
          authState.current.accessRole = userData.accessRole
        } else {
          authState.current.isAuthenticated = false
        }
      } else {
        authState.current.isAuthenticated = false
      }
    })()
  }, [client, setUser])

  // TODO: need to listen to errors and show all network errs the same way (tost)

  datalayer.useEvent('UNAUTHORIZED', () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    authState.current.logout()
    window.location.reload()
  })

  // don't render routes until we figure out if we're authenticated
  if (isAuthenticated === undefined) {
    return <div />
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme()}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <SnackbarProvider
            preventDuplicate
            autoHideDuration={6000}
            maxSnack={4}>
            <StyledContainer>
              <AuthContext.Provider value={authState.current}>
                <RouterProvider router={props.router} />
              </AuthContext.Provider>
            </StyledContainer>
          </SnackbarProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

App.defaultProps = {
  apolloClient: undefined,
}
