import { gql } from '@apollo/client'

export const GUIDE_STRUCTURE_CATEGORY_FIELDS = gql`
  fragment GuideStructureCategoryFields on GuideStructureCategory {
    _id
    hasBeenEdited
    isEmpty
    itemCategory {
      _id
      databaseId
      name
      parentId
      sortIndex
    }
    primaryMappings {
      _id
      name
    }
    orderingConfiguration {
      autoInventoryEnabled
      backInventoryRequired
      floorInventoryRequired
      floorInventoryUnits
    }
    inventoryConfiguration {
      backInventoryUnits
      floorInventoryRequired
      floorInventoryUnits
    }
  }
`
