import { useSearchParamsState } from 'app/hooks/useSearchParamsState'
import tuple from 'shared/tuple'

/**
 * List of Store IDs used for filtering
 */
export default function useStoreIdsSearchParam() {
  const [storeIdsParams, setStoreIdsParams] = useSearchParamsState('storeIds')
  const selectedStoreIds = storeIdsParams ? storeIdsParams.split(',') : []

  const setStoreIds = (newStoreIds: string[]) =>
    setStoreIdsParams(newStoreIds.join(','))

  return tuple([selectedStoreIds, setStoreIds])
}
