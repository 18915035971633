import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { CONFIG_AD_SCHEDULE_ROW_FIELDS } from 'app/packages/internal/customerConfigs/adSchedules/api/fragments/ConfigAdScheduleRowFields'
import { EditConfigAdSchedulesArgs } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_CONFIG_AD_SCHEDULES = gql`
  ${CONFIG_AD_SCHEDULE_ROW_FIELDS}
  mutation updateConfigAdSchedules(
    $updateConfigAdSchedulesInput: UpdateConfigAdSchedulesInput!
  ) {
    updateConfigAdSchedules(input: $updateConfigAdSchedulesInput) {
      results {
        ...ConfigAdSchedulesRowFields
      }
    }
  }
`

export function useUpdateConfigAdSchedules() {
  const { activeCustomerId } = useCustomers()
  const [updateConfigAdSchedules, result] = useMutation(
    UPDATE_CONFIG_AD_SCHEDULES,
    {
      refetchQueries: ['ConfigAdSchedules'],
    },
  )

  return tuple([
    useCallback(
      async (input: EditConfigAdSchedulesArgs) => {
        const { errors } = await updateConfigAdSchedules({
          variables: {
            updateConfigAdSchedulesInput: {
              customerId: activeCustomerId,
              configAdSchedules: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error('Update Ad Schedules Config Failed')
        }
      },
      [activeCustomerId, updateConfigAdSchedules],
    ),
    result,
  ])
}
