import { TextField as MuiTextField, TextFieldProps } from '@mui/material'
import React from 'react'
import { useId } from 'shared/generateId'
import styled from 'styled-components'

const StyledTextField = styled(MuiTextField)`
  & .MuiFormHelperText-root {
    line-height: 0.75rem;
    margin: 0;
  }
`

export default function TextField({
  className = '',
  helperText = '',
  ...textFieldProps
}: TextFieldProps) {
  const id = useId()
  return (
    <StyledTextField
      className={className}
      id={`id_${id}`} // needed for tests to associate label with form field
      helperText={helperText}
      {...textFieldProps}
    />
  )
}
