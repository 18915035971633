import BreadcrumbsNavigation from 'app/components/Breadcrumbs/Breadcrumbs'
import { Spinner } from 'app/components/Spinner/Spinner'
import { MIN_HEADER_HEIGHT } from 'app/constants'
import useGetLookerDashboards from 'app/packages/opsInsights/api/queries/useGetDashboard'
import { toRem } from 'app/styles'
import React from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

const StyledBreadcrumbs = styled(BreadcrumbsNavigation)`
  padding-top: ${toRem(4)};
`

const StyledIFrame = styled.iframe`
  height: calc(100vh - ${MIN_HEADER_HEIGHT}px);
  width: 100%;
  border-width: 0px;
  padding-top: ${toRem(24)};
  padding-right: ${toRem(24)};
`

const StyledSpinner = styled(Spinner)`
  height: 100vh;
`

export default function OpsInsightsDashboardView() {
  const { id } = useParams()
  const { portalDashboardDetails, isLoading, error } = useGetLookerDashboards({
    portalDashboardId: id,
  })

  if (error) {
    return <div>Error: {error.message}</div>
  }

  if (isLoading || portalDashboardDetails === null) {
    return <StyledSpinner />
  }

  const crumbs = [
    {
      route: '../dashboards',
      name: 'Insights',
    },
    {
      route: null,
      name: portalDashboardDetails.categoryName,
    },
    {
      route: null,
      name: portalDashboardDetails.name,
    },
  ]

  return (
    <>
      <StyledBreadcrumbs crumbs={crumbs} />
      {portalDashboardDetails.lookerDashboards.map(({ embedUrl }) => (
        <StyledIFrame
          title="Dashboard Content"
          // TODO: To properly support rendering multiple embedUrls on one page,
          // StyledIFrame needs to size the iframes according to their content
          // height[1]. As of 2024-04-26, each iframe gets the full height of
          // the viewport, so only the first iframe is visible.
          // [1]: https://stackoverflow.com/a/75137654
          src={embedUrl}
        />
      ))}
    </>
  )
}
