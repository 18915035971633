import {
  UpdateItemOverridesMutation,
  UpdateItemOverridesMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { msgByErrorCode } from 'app/packages/storeSolutions/guideOrganization/api/errors'
import { GUIDE_STRUCTURE_ITEM_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureItem'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import { useCallback } from 'react'
import tuple from 'shared/tuple'
import segment from 'src/__generated__/segment'

export const UPDATE_ITEM_OVERRIDES = gql`
  ${GUIDE_STRUCTURE_ITEM_FIELDS}
  mutation updateItemOverrides(
    $guideStructureId: ID!
    $overrides: [UpsertItemCategoryOverridesEventArgs!]!
  ) {
    upsertItemCategoryOverrides(
      input: { guideStructureId: $guideStructureId, overrides: $overrides }
    ) {
      results {
        ...GuideStructureItemFields
      }
    }
  }
`

export default function useUpdateItemOverrides() {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { properties } = useGetProperties()
  const guideStructureId = properties?._id

  const [mutation, result] = useMutation<
    UpdateItemOverridesMutation,
    UpdateItemOverridesMutationVariables
  >(UPDATE_ITEM_OVERRIDES, {
    onCompleted: (data) => {
      segment.guideOrganizationItemOverridesSaved({
        itemOverridesResult: data.upsertItemCategoryOverrides?.results ?? [],
        ...defaultProperties,
      })
    },
    refetchQueries: [
      'getGuideStructureCategories',
      'getGuideStructureItems',
      'getGuideStructureProperties',
    ],
  })

  return tuple([
    useCallback(
      async (overrides: { itemCategoryId: string; itemId: string }[]) => {
        if (!guideStructureId) {
          throw new Error('Guide structure ID not found')
        }

        const { errors } = await mutation({
          variables: {
            guideStructureId,
            overrides,
          },
        })

        if (errors?.length) {
          throw new Error(
            msgByErrorCode[getFirstErrorCode(errors)] ||
              'Unable to process request.',
          )
        }
      },
      [guideStructureId, mutation],
    ),
    result,
  ])
}
