import { gql } from '@apollo/client'
import { ORDER_AUTO_UPDATE_FIELDS } from 'app/packages/internal/customerConfigs/orderAutoUpdate/api/fragments/OrderAutoUpdate'

export const ORDER_AUTO_UPDATE = gql`
  ${ORDER_AUTO_UPDATE_FIELDS}

  query orderAutoUpdate($customerId: ID!) {
    node(id: $customerId) {
      ... on Customer {
        orderAutoUpdate {
          ...OrderAutoUpdateFields
        }
      }
    }
  }
`
