import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import React from 'react'

export interface Props {
  confirmationMessage: string | React.ReactNode
  errorMessage?: string
  isLoading: boolean
  onClose: () => void
  onDone: () => Promise<void>
  title: string
}

export default function DeleteConfirmationModal({
  confirmationMessage,
  errorMessage,
  isLoading,
  onClose,
  onDone,
  title,
}: Props) {
  return (
    <Dialog disabled={isLoading} isOpen onClose={onClose}>
      <Header disabled={isLoading} onClose={onClose}>
        <Title>{title}</Title>
      </Header>
      <Body>
        {confirmationMessage}
        {errorMessage && (
          <AlertMessageBlock message={errorMessage} severityType="error" />
        )}
      </Body>
      <Footer>
        <FooterActions>
          <Button disabled={isLoading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={onDone}>
            Confirm
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
