import {
  ConfigAdScheduleTableMeta,
  ConfigTableCell,
  CustomerConfigAdSchedulesOptionsQuery,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'

export const GET_CONFIG_AD_SCHEDULES_OPTIONS = gql`
  query CustomerConfigAdSchedulesOptions(
    $customerId: ID!
    $searchTerm: String
  ) {
    customerConfig(customerId: $customerId) {
      adSchedulesOptions {
        workflowOptions {
          label
          value
        }
        businessLevelOptions {
          label
          value
        }
        regionOptions(limit: 200) {
          label
          value
        }
        businessUnitOptions {
          label
          value
        }
        storeOptions(searchTerm: $searchTerm, limit: 200) {
          label
          value
        }
      }
    }
  }
`

export const GET_CONFIG_AD_SCHEDULES_STORE_OPTIONS = gql`
  query CustomerConfigAdSchedulesStoreOptions(
    $customerId: ID!
    $searchTerm: String
  ) {
    customerConfig(customerId: $customerId) {
      adSchedulesOptions {
        storeOptions(searchTerm: $searchTerm, limit: 200) {
          label
          value
        }
      }
    }
  }
`

/* we need this to accurately display the values according to the designs */
function concatLabel(cell: ConfigTableCell, labelWithValue = false) {
  return {
    label: labelWithValue ? `${cell.value} (${cell.label})` : cell.label,
    value: cell.value,
  }
}

function generateOptionsLabels(options: ConfigAdScheduleTableMeta) {
  return {
    businessLevelOptions: options?.businessLevelOptions?.map((c) =>
      concatLabel(c),
    ),
    businessUnitOptions: options?.businessUnitOptions?.map((c) =>
      concatLabel(c, true),
    ),
    regionOptions: options?.regionOptions?.map((c) => concatLabel(c, true)),
    storeOptions: options?.storeOptions?.map((c) => concatLabel(c, true)),
    workflowOptions: options?.workflowOptions?.map((c) => concatLabel(c)),
  }
}

export function useGetAdSchedulesOptions({
  customerId,
}: {
  customerId: CustomerId | undefined
}) {
  const { data, loading, refetch } = useQuery<
    CustomerConfigAdSchedulesOptionsQuery
  >(GET_CONFIG_AD_SCHEDULES_OPTIONS, {
    variables: { customerId },
  })
  if (data && data.customerConfig && data.customerConfig.adSchedulesOptions) {
    return {
      options: generateOptionsLabels(data.customerConfig.adSchedulesOptions),
      refetch,
      loading,
    }
  }

  return {
    options: undefined,
    loading,
    refetch,
  }
}

export function useGetAdSchedulesStoreOptions({
  customerId,
  storeSearchTerm,
}: {
  customerId: CustomerId | undefined
  storeSearchTerm?: string
}) {
  const { data, loading, refetch } = useQuery<
    CustomerConfigAdSchedulesOptionsQuery
  >(GET_CONFIG_AD_SCHEDULES_STORE_OPTIONS, {
    skip: false,
    // we don't cache these results because they interfere with the cache of the rows
    // see https://github.com/afresh-technologies/afresh-web-portal/pull/1146
    fetchPolicy: 'no-cache',
    variables: { customerId, searchTerm: storeSearchTerm },
  })
  if (data && data.customerConfig && data.customerConfig.adSchedulesOptions) {
    return {
      options: {
        storeOptions: data.customerConfig.adSchedulesOptions.storeOptions?.map(
          (c) => concatLabel(c, true),
        ),
      },
      refetch,
      loading,
    }
  }

  return {
    options: undefined,
    loading,
    refetch,
  }
}
