import {
  GetMatchingStoresQuery,
  GetMatchingStoresQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { useLocation } from 'react-router-dom'

export const GET_MATCHING_STORES = gql`
  ${STORE_FIELDS}
  query getMatchingStores(
    $storeScheduleId: ID!
    $includeOverrides: Boolean
    $includeStoreClosures: Boolean
  ) {
    node(id: $storeScheduleId) {
      ... on OrderDeliveryStoreWorkflowSchedule {
        _id
        matchingSchedules(
          includeOverrides: $includeOverrides
          includeStoreClosures: $includeStoreClosures
        ) {
          _id
          store {
            ...StoreFields
          }
        }
      }
    }
  }
`

export function useGetMatchingStores(storeScheduleId: string) {
  const location = useLocation()

  const { data, error, loading } = useQuery<
    GetMatchingStoresQuery,
    GetMatchingStoresQueryVariables
  >(GET_MATCHING_STORES, {
    fetchPolicy: 'no-cache',
    variables: {
      storeScheduleId,
      ...(location.pathname.includes('calendar')
        ? {
            includeOverrides: true,
            includeStoreClosures: true,
          }
        : {}),
    },
  })

  let stores: Store[] = []

  if (
    data &&
    data.node &&
    data.node.__typename === 'OrderDeliveryStoreWorkflowSchedule' &&
    data.node.matchingSchedules
  ) {
    stores = data.node.matchingSchedules.map((node) => toStore(node.store))
  }

  let errorMessage = null
  if (!loading) {
    if (error) {
      errorMessage = 'Failed to fetch matching stores.'
    } else if (!stores.length) {
      errorMessage = 'No matching stores.'
    }
  }

  return { stores, errorMessage, loading }
}
