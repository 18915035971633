import { noop } from 'app/helpers/index'
import { MutableRefObject, useEffect } from 'react'

const FOCUSABLE_ELEMENTS =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

function focusElement($el: Element) {
  if (!$el) {
    return
  }

  ;($el as HTMLElement).focus()
}

export default function useFocusTrap(
  containerRef: MutableRefObject<HTMLElement | null>,
) {
  useEffect(() => {
    const container = containerRef.current

    if (!container) {
      return noop
    }

    const focusableElements = container.querySelectorAll(FOCUSABLE_ELEMENTS)
    const firstFocusableElement = focusableElements[0]
    const lastFocusableElement = focusableElements[focusableElements.length - 1]

    focusElement(firstFocusableElement)

    function handleOnKeyDown(event: KeyboardEvent) {
      if (event.key !== 'Tab') {
        return
      }

      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault()
          focusElement(lastFocusableElement)
        }
      } else if (document.activeElement === lastFocusableElement) {
        event.preventDefault()
        focusElement(firstFocusableElement)
      }
    }

    window.addEventListener('keydown', handleOnKeyDown)

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown)
    }
  }, [containerRef])
}
