import { Box } from '@mui/system'
import React, { Suspense, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { useCustomers } from 'app/CustomerContext'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import Button from 'app/components/Button/Button'
import FallbackLoader from 'app/components/FallbackLoader/FallbackLoader'
import Expand from 'app/components/Icons/Expand'
import { ROLES } from 'app/constants'
import useToggleState from 'app/hooks/useToggleState'
import Main from 'app/layouts/Main'
import { LOGIN_PATH } from 'app/packages/core/pages/login/login.constants'
import { useAuthState, useIsAuthenticated } from 'app/state/authentication'
import InternalSideNav from './InternalSideNav/InternalSideNav'

const DrawerOpenButton = styled(Button)`
  position: absolute;
  height: ${({ theme }) => theme.typography.pxToRem(104)};
  display: flex;
  align-items: flex-start;
  z-index: ${({ theme }) => theme.zIndex.appBar + 2};
  padding: 0px;
  top: ${({ theme }) => theme.typography.pxToRem(2)};
  margin-left: ${({ theme }) => theme.typography.pxToRem(12)};

  :focus-visible {
    height: 24px;
    width: 24px;
  }
`

const StyledExpand = styled(Expand)`
  color: ${({ theme }) => theme.colors.neutral_03};
`

export const InternalLayoutContext = React.createContext({
  sideNavOpen: true,
})

export interface Props {
  children?: React.ReactNode
}

export default function AuthorizedInternalLayout({ children }: Props) {
  const authState = useAuthState()
  const isAuthenticated = useIsAuthenticated(authState)
  const { accessRole } = useGetCustomerPermissions()
  const { customerKey } = useParams()
  const {
    activeCustomer,
    activeCustomerId,
    setActiveCustomer,
    customers,
  } = useCustomers()
  const [sideNavOpen, { toggle: toggleSideNav, setOpen }] = useToggleState(true)

  // Set active customer per URL parameter
  useEffect(() => {
    // If customers are loaded, customerKey exists in URL
    const shouldSetNewActiveCustomer =
      customers &&
      customers?.length > 0 &&
      customerKey &&
      customerKey !== activeCustomer?.key
    if (shouldSetNewActiveCustomer) {
      if (customerKey === '__AFRESH__') {
        setActiveCustomer(null)
      } else {
        const customer = customers?.find((cust) => cust?.key === customerKey)
        if (customer) setActiveCustomer(customer)
      }
    }
  }, [
    customerKey,
    activeCustomerId,
    activeCustomer,
    setActiveCustomer,
    customers,
  ])

  if (!isAuthenticated) {
    authState.nextLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`
    return <Navigate to={LOGIN_PATH} replace />
  }

  if (accessRole && accessRole !== ROLES.AFRESH_ADMIN) {
    return <Navigate to="/page-not-found" />
  }

  return (
    <InternalLayoutContext.Provider
      value={{
        sideNavOpen,
      }}>
      <Box display="flex">
        <Box>
          <InternalSideNav
            sideNavOpen={sideNavOpen}
            toggleSideNav={toggleSideNav}
          />
        </Box>
        <Box>
          {!sideNavOpen && (
            <DrawerOpenButton variant="icon" onClick={setOpen}>
              <StyledExpand />
            </DrawerOpenButton>
          )}
        </Box>
        <Main $open={sideNavOpen}>
          <Suspense fallback={<FallbackLoader />}>{children}</Suspense>
        </Main>
      </Box>
    </InternalLayoutContext.Provider>
  )
}
