import { gql } from '@apollo/client'

export const authMethodGql = gql`
  query authMethod($email: String!) {
    user(email: $email) {
      authenticationMethod {
        ... on AuthenticationMethodPassword {
          needsReset
        }
        ... on AuthenticationMethodOidc {
          authUrl(clientType: CONSOLE)
        }
      }
    }
  }
`
