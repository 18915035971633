import { Store, UnloadOrderSequence } from '@api/__gen__/gql'
import { DialogContent, Tooltip } from '@mui/material'
import useGetOrderWorkflows from 'app/api/queries/useGetOrderWorkflows'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import useDraftState from 'app/hooks/useDraftState'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useUpsertUnloadOrderSequence } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/mutations/useUpsertUnloadOrderSequence'
import { UnloadOrderSequenceRow } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/queries/useGetUnloadOrderSequence'
import { toRem } from 'app/styles'
import { isEqual } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { DayOfWeek, Option } from 'shared/types'
import styled from 'styled-components'

const FIELD_WIDTH = 304

export const StyledInnerWrapper = styled.div`
  max-height: ${toRem(450)};
`

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
`

const StyledConfigId = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export const StyledAutocomplete = styled(Autocomplete)`
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export interface UnloadOrderSequenceAddEditModalProps {
  modalState: ModalState
  activeRow?: UnloadOrderSequenceRow
  rows: UnloadOrderSequenceRow[]
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
  storeOptions?: Option[]
  storeSearchLoading?: boolean
}

interface FormValues {
  store: Partial<Store> | null
  workflowId: string
  dayOfWeek: number
  unloadOrderSequence: UnloadOrderSequence
}

const getCurrentRowValues = (row: UnloadOrderSequenceRow) => {
  return {
    workflowId: row.workflow?._id || '-1',
    store: row.store || null,
    dayOfWeek: row.dayOfWeek,
    unloadOrderSequence: row.unloadOrderSequence,
  }
}

function getInitialFormState(activeRow?: UnloadOrderSequenceRow): FormValues {
  if (!activeRow) {
    return {
      store: null,
      workflowId: '-1',
      dayOfWeek: -1,
      unloadOrderSequence: 'UNLOAD_THEN_ORDER',
    }
  }

  return getCurrentRowValues(activeRow)
}

function validateFormValues(
  formValues: FormValues,
  rows: UnloadOrderSequenceRow[],
) {
  const formValid = !rows.some((row) => {
    const currentRowValues = getCurrentRowValues(row)
    return isEqual(
      { ...currentRowValues, unloadOrderSequence: '' },
      { ...formValues, unloadOrderSequence: '' },
    )
  })

  return formValid
}

const DAYS_OF_WEEK_OPTIONS = [
  {
    value: '-1',
    label: 'All Days (-1)',
  },
  {
    value: DayOfWeek.SUNDAY,
    label: `Sunday (${DayOfWeek.SUNDAY})`,
  },
  {
    value: DayOfWeek.MONDAY,
    label: `Monday (${DayOfWeek.MONDAY})`,
  },
  {
    value: DayOfWeek.TUESDAY,
    label: `Tuesday (${DayOfWeek.TUESDAY})`,
  },
  {
    value: DayOfWeek.WEDNESDAY,
    label: `Wednesday (${DayOfWeek.WEDNESDAY})`,
  },
  {
    value: DayOfWeek.THURSDAY,
    label: `Thursday (${DayOfWeek.THURSDAY})`,
  },
  {
    value: DayOfWeek.FRIDAY,
    label: `Friday (${DayOfWeek.FRIDAY})`,
  },
  {
    value: DayOfWeek.SATURDAY,
    label: `Saturday (${DayOfWeek.SATURDAY})`,
  },
]

export default function UnloadOrderSequenceAddEditModal({
  modalState,
  rows,
  activeRow,
  storeOptions = [],
  onClose,
  onSuccess,
  onDelete,
  storeSearchLoading,
}: UnloadOrderSequenceAddEditModalProps) {
  const {
    data: workflowOptions,
    isLoading: workflowsLoading,
  } = useGetOrderWorkflows()
  const [upsertUnloadOrderSequence] = useUpsertUnloadOrderSequence()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )
  const [saving, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formValid, setFormValid] = useState(
    validateFormValues(formValues, rows),
  )

  useEffect(() => {
    const isValid = validateFormValues(formValues, rows)
    setFormValid(isValid)
  }, [formValues, rows])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await upsertUnloadOrderSequence({
        id: activeRow?._id ? activeRow._id : undefined,
        workflowId: formValues.workflowId,
        storeId: formValues.store?._id || '-1',
        vendorId: '-1',
        dayOfWeek: formValues.dayOfWeek,
        unloadOrderSequence: formValues.unloadOrderSequence,
      })
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const loading = workflowsLoading || saving

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Unload Order Sequence Override`}</Title>
      </Header>
      <Body>
        <StyledDialogContent>
          {activeRow && (
            <StyledConfigId>
              Config ID: <strong>{activeRow.databaseId}</strong>
            </StyledConfigId>
          )}
          <span>
            Config targets must <strong>not</strong> be duplicates of existing
            configs
          </span>
          <SelectFieldContainer>
            <StyledConfigId>
              <strong>Targets</strong>
            </StyledConfigId>
            <SingleSelect
              width={FIELD_WIDTH}
              options={[
                {
                  label: 'All Workflows (-1)',
                  value: '-1',
                },
                ...(workflowOptions.map(({ workflowName, databaseId, id }) => {
                  return {
                    label: `${workflowName} (${databaseId})`,
                    value: id,
                  }
                }) ?? []),
              ]}
              value={formValues.workflowId}
              label="Workflow"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.workflowId = e.target.value
                })
              }
            />
            <StyledAutocomplete
              options={
                [{ label: 'All Stores (-1)', value: '-1' }, ...storeOptions] ??
                []
              }
              label="Store"
              loading={storeSearchLoading}
              value={
                formValues.store
                  ? {
                      value: formValues.store._id || '-1',
                      label: formValues.store?.name || 'All Stores',
                    }
                  : { value: '-1', label: 'All Stores (-1)' }
              }
              onChange={(_, value) => {
                setFormValues((draft) => {
                  draft.store = {
                    _id: value?.value as string,
                    name: value?.label,
                  }
                })
              }}
            />
            <SingleSelect
              width={FIELD_WIDTH}
              options={DAYS_OF_WEEK_OPTIONS}
              value={formValues.dayOfWeek.toString()}
              label="Day of Week"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.dayOfWeek = +e.target.value
                })
              }
            />
            <StyledConfigId>
              <strong>Config Value</strong>
            </StyledConfigId>
            <SingleSelect
              width={FIELD_WIDTH}
              options={
                [
                  {
                    label: 'Unload Then Order',
                    value: 'UNLOAD_THEN_ORDER',
                  },
                  {
                    label: 'Order Then Unload',
                    value: 'ORDER_THEN_UNLOAD',
                  },
                ] ?? []
              }
              value={formValues.unloadOrderSequence}
              label="Sequence"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.unloadOrderSequence = e.target
                    .value as UnloadOrderSequence
                })
              }
            />
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && (
          <FooterActions justify="start">
            <Button
              disabled={loading}
              startIcon={<Delete />}
              onClick={onDelete}
              variant="tertiary">
              Delete
            </Button>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Tooltip
            title={!formValid ? 'Cannot save duplicate config' : null}
            placement="top">
            <span>
              <Button
                disabled={loading || !formValid}
                loading={loading}
                onClick={handleSave}>
                Save
              </Button>
            </span>
          </Tooltip>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
