import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import { LOGIN_PATH } from 'app/packages/core/pages/login/login.constants'
import {
  ResetPasswordForm,
  ResetPasswordSuccess,
} from 'app/packages/core/pages/login/RequestPasswordReset/RequestPasswordReset'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

export default function ForgotPassword() {
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email') ?? ''
  const [hasReset, setHasReset] = useState(false)
  const navigate = useNavigate()

  return (
    <SingleCardPage width={480}>
      {hasReset ? (
        <ResetPasswordSuccess
          email={email}
          onGoBack={() => navigate(LOGIN_PATH)}
        />
      ) : (
        <ResetPasswordForm
          isFirstTimeSettingPassword={false}
          initialEmailValue={email}
          onSuccess={() => {
            setHasReset(true)
          }}
          onGoBack={() => navigate(LOGIN_PATH)}
        />
      )}
    </SingleCardPage>
  )
}
