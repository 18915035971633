import { CreateDepartmentBundleInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { DEPARTMENT_BUNDLE_ERRORS } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/errors'
import { DEPARTMENT_BUNDLE_FIELDS } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/fragments/departmentBundleFields'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_DEPARTMENT_BUNDLE = gql`
  ${DEPARTMENT_BUNDLE_FIELDS}
  mutation createDepartmentBundle($input: CreateDepartmentBundleInput!) {
    createDepartmentBundle(input: $input) {
      departmentBundle {
        ...DepartmentBundleFields
      }
    }
  }
`

export function useCreateDepartmentBundle() {
  const [createDepartmentBundle, result] = useMutation(
    CREATE_DEPARTMENT_BUNDLE,
    {
      refetchQueries: ['getDepartmentBundles'],
    },
  )

  return tuple([
    useCallback(
      async (input: CreateDepartmentBundleInput) => {
        const { id, name, type, departmentIds, productIds } = input
        const { errors } = await createDepartmentBundle({
          variables: {
            input: {
              id,
              name,
              type,
              departmentIds,
              productIds,
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            DEPARTMENT_BUNDLE_ERRORS[getFirstErrorCode(errors)] ||
              'Create Department Bundle Failed',
          )
        }
      },
      [createDepartmentBundle],
    ),
    result,
  ])
}
