import {
  StyledList,
  StyledVirtualizedList,
} from 'app/components/VirtualizedList/styles'
import { VirtualizedListProps } from 'app/components/VirtualizedList/VirtualizedList.types'
import { colorPalette } from 'app/styles'
import React, { useCallback, useRef } from 'react'
import { defaultRangeExtractor, Range, useVirtual } from 'react-virtual'

function StickyVirtualizedList<P extends {}>({
  className = '',
  height,
  numOfRows,
  renderRow,
  stickyIndices = [],
}: VirtualizedListProps<P>) {
  const activeStickyIndexRef = useRef<number>(0)
  const parentRef = useRef<HTMLDivElement>(null)

  const isActiveSticky = (index: number) =>
    stickyIndices.includes(index) && activeStickyIndexRef.current === index
  const isSticky = (index: number) => stickyIndices.includes(index)

  const rowVirtualizer = useVirtual({
    parentRef,
    rangeExtractor: useCallback(
      (range: Range) => {
        activeStickyIndexRef.current =
          [...stickyIndices].reverse().find((index) => range.start >= index) ||
          0

        const next = new Set([
          activeStickyIndexRef.current,
          ...defaultRangeExtractor(range),
        ])

        return [...next].sort((a, b) => a - b)
      },
      [stickyIndices],
    ),
    size: numOfRows,
  })

  return (
    <StyledList
      className={`${className} sticky-list`}
      data-testid="StickyList"
      height={height}
      ref={parentRef}>
      <StyledVirtualizedList totalSize={rowVirtualizer.totalSize}>
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return renderRow({
            index: virtualRow.index || 0,
            isSticky: isSticky(virtualRow.index || 0),
            key: virtualRow.index || 0,
            style: {
              left: 0,
              top: 0,
              width: '100%',
              ...(isSticky(virtualRow.index)
                ? {
                    backgroundColor: colorPalette.highlight_07,
                    color: colorPalette.secondary,
                    cursor: 'initial',
                    fontWeight: 700,
                    zIndex: 1,
                    transform: `translateY(${virtualRow.start}px)`,
                  }
                : {}),
              ...(isActiveSticky(virtualRow.index)
                ? {
                    position: 'sticky',
                    transform: undefined,
                  }
                : {
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`,
                  }),
            },
          })
        })}
      </StyledVirtualizedList>
    </StyledList>
  )
}

export default React.memo(StickyVirtualizedList)
