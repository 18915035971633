import { gql } from '@apollo/client'

export const WORKFLOW_AVAILABILITY_ROW = gql`
  fragment WorkflowAvailabilityRow on WorkflowAvailability {
    _id
    workflow {
      _id
      id
      name
      departmentBundle {
        name
      }
    }
    businessLevel {
      _id
      id
      name
      businessLevelMetadata {
        level
        displayName
      }
    }
    available
    createdAt
    updatedAt
  }
`
