import {
  EditUserCustomerInfoQuery,
  EditUserCustomerInfoQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'

export const GET_CUSTOMER_DEPARTMENTS = gql`
  query getCustomerDepartments($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        departments {
          _id
          customerDepartmentId
          name
        }
      }
    }
  }
`

export interface Department {
  _id: string
  customerDepartmentId: number
  name: string
}

export function useGetDepartments(
  customerId: string | null,
): Department[] | null {
  const { data: rawData } = useQuery<
    EditUserCustomerInfoQuery,
    EditUserCustomerInfoQueryVariables
  >(GET_CUSTOMER_DEPARTMENTS, {
    skip: customerId === null, // don't do query for Afresh users
    variables: { customerId: customerId || '' },
  })

  if (rawData?.node?.__typename === 'Customer') {
    return rawData.node?.departments
  }

  return null
}
