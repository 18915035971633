import { dispatchEvent } from 'app/state/useEventListener'
import { getDefaultHeaders } from 'app/utils'

function generateErrMessage(statusText: string, message?: string | null) {
  if (message) {
    return `${statusText}: ${message}`
  }
  return statusText
}

export function rawAPICall(uri: string, data: Partial<Request> = {}) {
  const headers = getDefaultHeaders()

  // TODO: add retry logic (passed in the opt = {} in 3 args)
  return fetch(uri, { ...data, headers })
    .then((res) => {
      if (!res.ok) {
        return res
          .json()
          .catch(() => {
            // could not parse the JSON
            throw new Error(generateErrMessage(res.statusText))
          })
          .then(({ message }) => {
            // got valid JSON with error message
            throw new Error(generateErrMessage(res.statusText, message))
          })
      }

      return res.json()
    })
    .catch((err) => {
      dispatchEvent('ERROR', { severity: 'warning', err, uri, data })
      throw err
    })
}
