import Button from 'app/components/Button/Button'
import useToggleState from 'app/hooks/useToggleState'
import { GuideStructureCategory } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import EditorModal from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import React from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledEditButton = styled(Button)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-left: auto;
`

export default function EditSection({
  categoryEditContext,
  isDraft,
}: {
  categoryEditContext: GuideStructureCategory
  isDraft: boolean
}) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const [opened, { toggle }] = useToggleState()

  const handleOnClick = () => {
    segment.guideOrganizationEditSectionButtonClicked({
      sectionName: categoryEditContext.itemCategory.name,
      ...defaultProperties,
    })
    toggle()
  }

  return (
    <>
      <StyledEditButton onClick={handleOnClick} variant="tertiary">
        {isDraft ? 'Edit' : 'View'}
      </StyledEditButton>
      {opened && (
        <EditorModal
          categoryEditContext={categoryEditContext}
          onClose={toggle}
          viewOnly={!isDraft}
        />
      )}
    </>
  )
}
