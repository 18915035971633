import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_UNLOAD_ORDER_SEQUENCE = gql`
  mutation deleteUnloadOrderSequence($input: UpdateUnloadOrderSequenceInput!) {
    updateUnloadOrderSequence(input: $input) {
      results {
        _id
      }
    }
  }
`

export function useDeleteUnloadOrderSequence() {
  const [deleteUnloadOrderSequence, result] = useMutation(
    DELETE_UNLOAD_ORDER_SEQUENCE,
    {
      refetchQueries: ['CustomerConfigUnloadOrderSequence'],
    },
  )

  return tuple([
    useCallback(
      async (input: string) => {
        const { errors } = await deleteUnloadOrderSequence({
          variables: {
            input: {
              toRemove: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error('Delete  Unload Order Sequence config failed')
        }
      },
      [deleteUnloadOrderSequence],
    ),
    result,
  ])
}
