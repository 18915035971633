import {
  ConfigAdScheduleRow,
  ConfigAdScheduleTableMeta,
} from '@api/__gen__/gql'
import { DialogContent, TextField } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { isNilOrEmpty } from 'app/helpers'
import useDraftState from 'app/hooks/useDraftState'
import { useAddConfigAdSchedules } from 'app/packages/internal/customerConfigs/adSchedules/api/mutations/useAddConfigAdSchedules'
import { useUpdateConfigAdSchedules } from 'app/packages/internal/customerConfigs/adSchedules/api/mutations/useUpdateConfigAdSchedules'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { toRem } from 'app/styles'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const FIELD_WIDTH = 304

export const StyledInnerWrapper = styled.div`
  max-height: ${toRem(450)};
`

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
`

const StyledConfigId = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`
const StyledTextField = styled(TextField)`
  width: ${toRem(FIELD_WIDTH)};
  margin: 0;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export const StyledAutocomplete = styled(Autocomplete)`
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export interface AdSchedulesAddEditModalProps {
  modalState: ModalState
  activeRow?: ConfigAdScheduleRow
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
  options?: ConfigAdScheduleTableMeta | null
  onChangeStoreSearch: (value: string) => void
  storeSearchLoading?: boolean
}

interface FormValues {
  workflowId: string
  businessLevel: string
  businessLevelId?: string
  startDate: string
  intervalWeeks: number
}

function getInitialFormState(activeRow?: ConfigAdScheduleRow): FormValues {
  // if no active row
  if (!activeRow) {
    return {
      workflowId: '',
      businessLevel: '',
      businessLevelId: '',
      startDate: '',
      intervalWeeks: 0,
    }
  }

  // get initial values for active row
  return {
    workflowId: activeRow.workflowId.value,
    businessLevel: activeRow.businessLevel.value,
    businessLevelId: activeRow.businessLevelId?.value,
    startDate: activeRow.startDate.value,
    intervalWeeks: +activeRow.intervalWeeks.value,
  }
}

function validateFormValues(formValues: FormValues) {
  let formValid = true

  if (formValues.businessLevel === '') formValid = false
  if (formValues.startDate === '') formValid = false
  if (formValues.intervalWeeks === 0) formValid = false

  return formValid
}

export default function AdSchedulesAddEditModalModal({
  modalState,
  activeRow,
  options,
  onClose,
  onSuccess,
  onDelete,
  onChangeStoreSearch,
  storeSearchLoading,
}: AdSchedulesAddEditModalProps) {
  const [addConfigAdSchedules] = useAddConfigAdSchedules()
  const [updateConfigAdSchedules] = useUpdateConfigAdSchedules()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [datePickerTouched, setDatePickerTouched] = useState(false)
  const [formValid, setFormValid] = useState(validateFormValues(formValues))
  const [
    businessLevelIdValue,
    setBusinessLevelIdValue,
  ] = useState<Option | null>(activeRow?.businessLevelId ?? null)

  useEffect(() => {
    setFormValid(validateFormValues(formValues))
  }, [formValues])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      if (!activeRow) {
        await addConfigAdSchedules({
          workflowId: formValues.workflowId,
          businessLevel: formValues.businessLevel,
          businessLevelId: formValues.businessLevelId || null,
          startDate: formValues.startDate,
          intervalWeeks: formValues.intervalWeeks,
        })
      } else {
        await updateConfigAdSchedules({
          id: activeRow._id,
          workflowId: formValues.workflowId,
          businessLevel: formValues.businessLevel,
          businessLevelId: formValues.businessLevelId || null,
          startDate: formValues.startDate,
          intervalWeeks: formValues.intervalWeeks,
        })
      }
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const businessLevelIDOptions = useMemo(() => {
    if (!options) {
      return []
    }

    const availableOptions: Record<string, Option[]> = {
      BUSINESS_UNIT: options.businessUnitOptions,
      REGION: options.regionOptions,
      STORE: options.storeOptions,
    }

    return availableOptions[formValues.businessLevel] ?? []
  }, [formValues.businessLevel, options])

  const handleStartDateChange = useCallback(
    (value: string | null) => {
      setFormValues((draft) => {
        if (value === null) {
          draft.startDate = ''
          return
        }
        const draftDate = dayjs(value)
          .utc()
          .set('hour', 19)
          .set('minute', 0)
          .set('second', 0)
          .set('millisecond', 0)

        draft.startDate = draftDate.isValid() ? draftDate.toISOString() : ''
      })
    },
    [setFormValues],
  )

  const showAutocomplete =
    formValues.businessLevel === 'STORE' ||
    formValues.businessLevel === 'REGION'

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Ad Schedule`}</Title>
      </Header>
      <Body>
        <StyledDialogContent>
          {activeRow && (
            <StyledConfigId>
              Config ID: <strong>{activeRow.id.value}</strong>
            </StyledConfigId>
          )}
          <SelectFieldContainer>
            <SingleSelect
              width={FIELD_WIDTH}
              disabled={isNilOrEmpty(options?.workflowOptions)}
              options={options?.workflowOptions ?? []}
              value={formValues.workflowId}
              label="Workflow"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.workflowId = e.target.value
                })
              }
            />
            <SingleSelect
              width={FIELD_WIDTH}
              disabled={!options?.businessLevelOptions}
              options={options?.businessLevelOptions || []}
              value={formValues.businessLevel}
              label="Business Level"
              handleChange={(e) => {
                setBusinessLevelIdValue(null)
                setFormValues((draft) => {
                  draft.businessLevel = e.target.value
                  draft.businessLevelId = ''
                })
              }}
            />
            {showAutocomplete && (
              <StyledAutocomplete
                options={businessLevelIDOptions}
                onInputChange={(event, value) => {
                  if (
                    event?.type === 'click' ||
                    formValues.businessLevel !== 'STORE'
                  ) {
                    return
                  }

                  onChangeStoreSearch(value)
                }}
                label="Business Level ID"
                loading={storeSearchLoading}
                value={businessLevelIdValue}
                onChange={(_, value) => {
                  setFormValues((draft) => {
                    draft.businessLevelId = value?.value ?? ''
                  })

                  setBusinessLevelIdValue(value)
                }}
              />
            )}
            {!showAutocomplete && (
              <SingleSelect
                width={FIELD_WIDTH}
                disabled={
                  formValues.businessLevel === 'CHAIN' ||
                  !formValues.businessLevel ||
                  isNilOrEmpty(businessLevelIDOptions)
                }
                options={businessLevelIDOptions}
                value={formValues.businessLevelId || ''}
                label="Business Level ID"
                handleChange={(e) => {
                  setFormValues((draft) => {
                    draft.businessLevelId = e.target.value
                  })
                }}
              />
            )}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date at 19:00 UTC"
                value={formValues.startDate}
                onChange={(value) => handleStartDateChange(value)}
                onClose={() => setDatePickerTouched(true)}
                renderInput={(params) => {
                  return (
                    <StyledTextField
                      {...params}
                      id="date-picker"
                      fullWidth
                      error={datePickerTouched && params.error}
                      label="Start Date at 19:00 UTC"
                    />
                  )
                }}
              />
            </LocalizationProvider>
            <StyledTextField
              id="interval-weeks"
              label="Interval Weeks"
              variant="outlined"
              type="number"
              fullWidth
              value={formValues.intervalWeeks ? formValues.intervalWeeks : ''}
              onChange={(e) => {
                setFormValues((draft) => {
                  draft.intervalWeeks = Math.abs(Math.round(+e.target.value))
                })
              }}
            />
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && (
          <FooterActions justify="start">
            <Button
              startIcon={<Delete />}
              onClick={onDelete}
              variant="tertiary">
              Delete
            </Button>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading || !formValid}
            loading={loading}
            onClick={handleSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
