import {
  BusinessLevel,
  CustomerConfigWorkflowAvailabilityQuery,
  OffsetPageInfo,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'
import { WORKFLOW_AVAILABILITY_ROW } from 'app/packages/internal/customerConfigs/workflowAvailability/api/fragments/WorkflowAvailabilityRow'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'

export const GET_CONFIG_WORKFLOW_AVAILABILITY = gql`
  ${WORKFLOW_AVAILABILITY_ROW}
  query CustomerConfigWorkflowAvailability(
    $customerId: ID!
    $offset: Int
    $limit: Int
    $workflowId: ID
    $businessLevel: BusinessLevel
    $searchTerm: String
  ) {
    customerConfig(customerId: $customerId) {
      workflowAvailability(
        offset: $offset
        limit: $limit
        workflowId: $workflowId
        businessLevel: $businessLevel
        searchTerm: $searchTerm
      ) {
        edges {
          node {
            ...WorkflowAvailabilityRow
          }
        }
        pageInfo {
          totalCount
          totalPages
          currentPage
          pageSize
        }
      }
    }
  }
`

export interface WorkflowAvailabilityRow {
  _id: string
  workflow: {
    __typename: 'Workflow'
    _id: string
    id: string
    name: string
    departmentBundle: {
      __typename: 'DepartmentBundle'
      name: string
    }
  }
  businessLevel: {
    __typename: 'BusinessLevelInstance'
    _id: string
    id: string
    name?: string | null
    businessLevelMetadata: {
      __typename: 'BusinessLevelMetadata'
      level: BusinessLevel
      displayName: string
    }
  }
  available: boolean
  createdAt: string
  updatedAt: string
}

export function useGetWorkflowAvailability({
  customerId,
  offset,
  limit,
  workflowId,
  businessLevel,
  searchTerm,
}: {
  customerId: CustomerId | undefined
  offset: number
  limit: number
  workflowId: string | null
  businessLevel: BusinessLevel | null
  searchTerm: string | null
}) {
  const [storedPageInfo, setStoredPageInfo] = useState<OffsetPageInfo>({
    __typename: 'OffsetPageInfo',
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const { data, loading, refetch } = useQuery<
    CustomerConfigWorkflowAvailabilityQuery
  >(GET_CONFIG_WORKFLOW_AVAILABILITY, {
    variables: {
      customerId,
      offset,
      limit,
      workflowId,
      businessLevel,
      searchTerm,
    },
    skip: !customerId,
  })

  useEffect(() => {
    if (data?.customerConfig?.workflowAvailability?.pageInfo) {
      setStoredPageInfo(data.customerConfig.workflowAvailability.pageInfo)
    }
  }, [data?.customerConfig?.workflowAvailability?.pageInfo])

  if (
    data &&
    data.customerConfig &&
    data.customerConfig?.workflowAvailability &&
    data.customerConfig.workflowAvailability?.edges
  ) {
    return {
      rows: compact(
        data.customerConfig.workflowAvailability.edges.map(
          (edge) => edge?.node,
        ),
      ),
      pageInfo: data.customerConfig.workflowAvailability.pageInfo,
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    pageInfo: storedPageInfo,
    loading,
    refetch,
  }
}
