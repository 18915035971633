import { Customer, useCustomers } from 'app/CustomerContext'
import BreadcrumbsNavigation from 'app/components/Breadcrumbs/Breadcrumbs'
import CardContainer from 'app/components/CardContainer/CardContainer'
import { toRem } from 'app/styles'
import { compact } from 'lodash-es'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import customerConfigRoutes from './customerConfigs/routes.constants'
import engToolRoutes from './engTools/routes.constants'

const StyledCustomerHeader = styled.div`
  padding: ${toRem(8)} ${toRem(48)};
  height: ${toRem(32)};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: ${toRem(8)};
  margin-left: ${toRem(-24)};
  background-color: ${({ theme }) => theme.colors.highlight_07};
`
const StyledCustomerName = styled.span`
  font-weight: 700;
  height: ${toRem(16)};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral_02};
`

const StyledBreadcrumbs = styled(BreadcrumbsNavigation)`
  padding: 0 1.5rem;
`

/*
  Gets the breadcrumb config 
  Defines the 2 base routes for internal and customer crumbs
  Gets the rest of the routes from the combined eng-tools and customer-config route files, dynamically
  can be expanded to include route children in the future
*/

export function getBreadcrumbs(
  pathname: string,
  activeCustomer: Customer | undefined,
) {
  const pathName = pathname.split(`/internal/${activeCustomer?.key}/`)[1]
  const parsedPathNames = pathName?.split('/')

  const homeCrumb = {
    route: '/internal',
    name: 'Home',
  }

  const customerCrumb = {
    route: null,
    name: activeCustomer?.name || 'Afresh',
  }

  const usersCrumb = {
    name: 'Users',
    path: 'users',
    route: 'users',
  }

  if (parsedPathNames) {
    const restOfCrumbs = parsedPathNames.map((path) => {
      const routeObject = [
        usersCrumb,
        ...customerConfigRoutes.routes,
        ...engToolRoutes.routes,
      ].find(
        (routeConfig) =>
          routeConfig.path === path || routeConfig.route === path,
      )
      if (!routeObject) return null

      return {
        name: routeObject.name,
        route: `/internal/${activeCustomer?.key}/${routeObject.route}`,
      }
    })
    return compact([homeCrumb, customerCrumb, ...restOfCrumbs])
  }
  return [homeCrumb, customerCrumb, usersCrumb]
}

export default function InternalCustomer() {
  const { activeCustomer } = useCustomers()
  const location = useLocation()
  const crumbs = getBreadcrumbs(location.pathname, activeCustomer)
  return (
    <>
      <StyledCustomerHeader>
        <StyledCustomerName>
          {activeCustomer?.name.toUpperCase()}
        </StyledCustomerName>
      </StyledCustomerHeader>
      <StyledBreadcrumbs crumbs={crumbs} />
      <CardContainer>
        <Outlet />
      </CardContainer>
    </>
  )
}
