import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Dialog as MuiDialog,
} from '@mui/material'
import { CloseDialogButton } from 'app/components/CloseDialogButton/CloseDialogButton'
import {
  DialogBreakPoints,
  DialogProps,
} from 'app/components/Dialog/Dialog.types'
import { noop } from 'app/helpers'
import useFocusTrap from 'app/hooks/useFocusTrap'
import useKeyPress from 'app/hooks/useKeyPress'
import { toRem } from 'app/styles'
import React, { useRef } from 'react'
import styled from 'styled-components'

const dimensions: { [size: string]: Record<string, string> } = {
  sm: { maxHeight: toRem(440), minHeight: toRem(264), width: toRem(288) },
  md: { maxHeight: toRem(800), minHeight: toRem(200), width: toRem(512) },
  lg: { maxHeight: toRem(800), minHeight: toRem(272), width: toRem(856) },
  xl: { maxHeight: toRem(800), minHeight: toRem(272), width: toRem(1000) },
}

const StyledPortal = styled.div<{ size: DialogBreakPoints }>`
  display: flex;
  flex-direction: column;

  ${({ size }) => {
    const { maxHeight, minHeight, width } = dimensions[size]

    return {
      maxHeight,
      minHeight,
      width,
    }
  }}
`

const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral_08};
  display: flex;
  flex-direction: column;
  padding: ${toRem(24)} ${toRem(24)} ${toRem(0)};
  position: sticky;
  top: 0px;
  z-index: 2;
`

const StyledTitleIconWrapper = styled.div`
  display: flex;
  column-gap: ${toRem(4)};
`

const StyledTitle = styled.h2`
  line-height: ${toRem(24)};
  margin: 0;
  padding: 0;
`

const StyledSubTitle = styled.span`
  line-height: ${toRem(24)};
  font-size: ${({ theme }) => theme.fontSizes.md};
`

const StyledClose = styled(CloseDialogButton)`
  position: absolute;
  right: 20px;
  top: 23px;
`

const StyledBody = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${toRem(16)} ${toRem(24)};
`

const StyledFooter = styled.div`
  display: flex;
  margin-top: auto;
  padding: ${toRem(0)} ${toRem(24)} ${toRem(24)};
`

const StyledFooterActions = styled.div<{ justify: string }>`
  align-items: center;
  column-gap: ${toRem(12)};
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: ${({ justify }) => `flex-${justify}`};
`

export const StyledP = styled.p`
  margin: 0;
`

function Header({ children, className = '', disabled, onClose }: DialogProps) {
  return (
    <StyledHeader className={className}>
      {children}
      <StyledClose disabled={disabled} onClose={onClose!} />
    </StyledHeader>
  )
}

function Title({ children, className = '', icon }: DialogProps) {
  if (icon) {
    return (
      <StyledTitleIconWrapper>
        {icon}
        <StyledTitle className={className}>{children}</StyledTitle>
      </StyledTitleIconWrapper>
    )
  }

  return <StyledTitle className={className}>{children}</StyledTitle>
}

function SubTitle({ children, className = '' }: DialogProps) {
  return <StyledSubTitle className={className}>{children}</StyledSubTitle>
}

function Body({ children, className = '' }: DialogProps) {
  return <StyledBody className={className}>{children}</StyledBody>
}

function Footer({
  children,
  className = '',
}: {
  children?: React.ReactNode
  className?: string
}) {
  return <StyledFooter className={className}>{children}</StyledFooter>
}

function FooterActions({
  children,
  className = '',
  justify = 'end',
}: {
  children: React.ReactNode
  className?: string
  justify?: 'end' | 'start'
}) {
  return (
    <StyledFooterActions className={className} justify={justify}>
      {children}
    </StyledFooterActions>
  )
}

const StyledLeftPane = styled.div`
  position: fixed;
  width: ${toRem(278)};
`

const LeftPaneList = styled(List)`
  counter-reset: list-number;
  margin-top: ${toRem(16)};
`
function LeftPane({ children }: { children: React.ReactNode }) {
  return (
    <StyledLeftPane>
      <LeftPaneList disablePadding>{children}</LeftPaneList>
    </StyledLeftPane>
  )
}

const RightPane = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.neutral_05};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-left: ${toRem(278)};
  padding: ${toRem(24)};
  position: relative;
  row-gap: ${toRem(12)};
  min-height: ${toRem(375)};
`

const LeftPaneListItemButton = styled(ListItemButton)`
  padding: ${toRem(8)} ${toRem(24)};

  * {
    line-height: 1.2;
  }

  &:hover {
    background-color: transparent;

    & * {
      font-weight: bold;
    }
  }

  &.Mui-selected,
  &.Mui-selected:hover,
  &.Mui-selected.Mui-focusVisible {
    background-color: ${({ theme }) => theme.colors.highlight_03};

    & * {
      font-weight: bold;
    }
  }

  &.Mui-focusVisible {
    background-color: ${({ theme }) => theme.colors.highlight_03};
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    outline-offset: -4px;
  }
`

const LeftPaneListItemText = styled(ListItemText)<{ $increment?: boolean }>`
  column-gap: ${toRem(8)};
  display: flex;
  ${({ $increment }) =>
    $increment &&
    `
      &:before {
        content: counter(list-number) '. ';
        counter-increment: list-number;
      }
    `}
`

function LeftPaneListItem({
  disabled = false,
  onClick,
  selected = false,
  text = '',
  showStepNumber = false,
}: {
  disabled?: boolean
  onClick?: () => void
  selected?: boolean
  text?: string
  showStepNumber?: boolean
}) {
  return (
    <ListItem disablePadding>
      <LeftPaneListItemButton
        onClick={onClick}
        disabled={disabled}
        selected={selected}>
        <LeftPaneListItemText primary={text} $increment={showStepNumber} />
      </LeftPaneListItemButton>
    </ListItem>
  )
}

const SideTabFooter = styled(Footer)`
  padding: ${toRem(24)} 0 0;
`

function Dialog({
  children,
  disabled = false,
  isOpen = false,
  onClose,
  size = 'md',
  ...rest
}: DialogProps) {
  const portalRef = useRef<HTMLDivElement>(null)

  useKeyPress('Escape', disabled ? noop : onClose)
  useFocusTrap(portalRef)

  return (
    <MuiDialog
      {...rest}
      disableEscapeKeyDown
      maxWidth={false}
      open={isOpen}
      ref={portalRef}>
      <StyledPortal size={size}>{children}</StyledPortal>
    </MuiDialog>
  )
}

export {
  Body,
  Footer,
  FooterActions,
  Header,
  LeftPane,
  LeftPaneList,
  LeftPaneListItem,
  LeftPaneListItemButton,
  LeftPaneListItemText,
  RightPane,
  SideTabFooter,
  SubTitle,
  Title,
}
export default Dialog
