import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const StyledFallbackLoader = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
`

export default function FallbackLoader({ className }: { className?: string }) {
  return (
    <StyledFallbackLoader className={className}>
      <CircularProgress />
    </StyledFallbackLoader>
  )
}
