import * as Sentry from '@sentry/react'
import { isEmpty, isNil, isNumber } from 'lodash-es'
import { lazy } from 'react'

export const isNilOrEmpty = <T>(value: T) => {
  return isNil(value) || (!isNumber(value) && isEmpty(value))
}

export const areAllNilOrEmpty = <T>(values: T[]) => {
  return values.every(isNilOrEmpty)
}

export const areSomeNilOrEmpty = <T>(values: T[]) => {
  return values.some(isNilOrEmpty)
}

export const itExists = <T>(value: T) => {
  return !isNilOrEmpty(value)
}

export const allExist = <T>(values: T[]) => {
  return !areSomeNilOrEmpty(values)
}

export const someExist = <T>(values: T[]) => {
  return !areAllNilOrEmpty(values)
}

export const noop = () => {}

interface Options {
  retries?: number
  interval?: number
  exponentialBackoff?: boolean
}

// taken from https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry<R>(
  fn: () => Promise<R>,
  { retries = 3, interval = 500, exponentialBackoff = true }: Options = {},
) {
  return new Promise<R>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retries === 1) {
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, {
            retries: retries - 1,
            interval: exponentialBackoff ? interval * 2 : interval,
          }).then(resolve, reject)
        }, interval)
      })
  })
}

// Store whether refresh has been tried in local storage
// so that we do not infinitely refresh if there's an issue.
const REFRESH_ATTEMPTED_KEY = 'refresh_attempted'
export function lazyLoadPage(pageImportFn: () => Promise<any>) {
  return lazy(async () => {
    try {
      const component = await pageImportFn()

      window.sessionStorage.setItem(REFRESH_ATTEMPTED_KEY, 'false')

      return component
    } catch (error) {
      if (error instanceof Error && error.name === 'ChunkLoadError') {
        const hasPageBeenRefreshed = JSON.parse(
          window.sessionStorage.getItem(REFRESH_ATTEMPTED_KEY) || 'false',
        )

        if (!hasPageBeenRefreshed) {
          Sentry.captureMessage(
            `Failed to load page, attempting refresh: ${error.message}`,
            {
              level: 'warning',
            },
          )

          window.sessionStorage.setItem(REFRESH_ATTEMPTED_KEY, 'true')
          return window.location.reload()
        }

        throw error
      } else {
        return retry(pageImportFn)
      }
    }
  })
}
