import { UpdateWorkflowAvailabilityInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { WORKFLOW_AVAILABILITY_ERRORS } from 'app/packages/internal/customerConfigs/workflowAvailability/api/errors'
import { WORKFLOW_AVAILABILITY_ROW } from 'app/packages/internal/customerConfigs/workflowAvailability/api/fragments/WorkflowAvailabilityRow'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_WORKFLOW_AVAILABILITY = gql`
  ${WORKFLOW_AVAILABILITY_ROW}
  mutation updateWorkflowAvailability(
    $updateWorkflowAvailabilityInput: [UpdateWorkflowAvailabilityInput!]!
  ) {
    updateWorkflowAvailability(input: $updateWorkflowAvailabilityInput) {
      results {
        ...WorkflowAvailabilityRow
      }
    }
  }
`

export function useUpdateWorkflowAvailability() {
  const [updateWorkflowAvailability, result] = useMutation(
    UPDATE_WORKFLOW_AVAILABILITY,
    {
      refetchQueries: ['CustomerConfigWorkflowAvailability'],
    },
  )

  return tuple([
    useCallback(
      async (input: UpdateWorkflowAvailabilityInput) => {
        const { errors } = await updateWorkflowAvailability({
          variables: {
            updateWorkflowAvailabilityInput: [input],
          },
        })

        if (errors?.length) {
          throw new Error(
            WORKFLOW_AVAILABILITY_ERRORS[getFirstErrorCode(errors)] ||
              `Update workflow availability config failed: ${errors[0].message}`,
          )
        }
      },
      [updateWorkflowAvailability],
    ),
    result,
  ])
}
