import { useNavigationProperties } from 'app/Analytics'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'

/*
  Additional 'default' properties we want to capture for all events. This
  is applied on top of the user properties we initialize
  for all segment events.
*/
export const useGuideOrganizationInitializeProperties = () => {
  const navigationProperties = useNavigationProperties()
  const { properties } = useGetProperties()

  return {
    ...navigationProperties,
    guideStructureId: properties?._id ?? null,
    productName: 'Guide Organization',
  }
}
