import { CreateCustomerInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      customer {
        _id
        id
        key
        name
      }
    }
  }
`

export default function useCreateCustomer() {
  const [mutation, result] = useMutation(CREATE_CUSTOMER, {
    onError: (e) => {
      throw new Error(getFirstErrorCode(e.graphQLErrors))
    },
  })

  return tuple([
    useCallback(
      async ({ key, name }: CreateCustomerInput) => {
        return mutation({
          variables: {
            input: {
              key,
              name,
            },
          },
        })
      },
      [mutation],
    ),
    result,
  ])
}
