import LinearProgress from 'app/components/LinearProgress/LinearProgress'
import Tag from 'app/components/Tag/Tag'
import { TOP_BAR_HEIGHT } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import { LayoutContext } from 'app/layouts/Customer/CustomerPageLayout'
import { SectionsListProps } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import EditSection from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditButton'
import { toRem } from 'app/styles'
import React, { useContext } from 'react'

import styled from 'styled-components'

const StyledMessage = styled.span`
  display: inline-block;
  padding: ${toRem(20)};
`

const StyledList = styled.ul<{ $hasTopBar: boolean }>`
  list-style: none;
  margin: 0;
  max-height: ${({ $hasTopBar }) =>
    `calc(100vh - (${toRem(248)} + ${
      $hasTopBar ? toRem(TOP_BAR_HEIGHT + 14) : '0rem'
    }))`};
  overflow: auto;
  padding: 0;
`

const StyledListItem = styled.li`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.highlight_07};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  color: ${({ theme }) => theme.colors.secondary};
  column-gap: ${toRem(8)};
  display: flex;
  height: 50px;
  font-weight: bold;
  padding: ${toRem(20)};
`

const StyledCategoryId = styled.span`
  font-weight: normal;
`

export default function SectionsList({
  categories,
  hasError,
  isLoading,
}: SectionsListProps) {
  const { properties } = useGetProperties()
  const { hasTopBar } = useContext(LayoutContext)

  const showLinearProgress = isLoading && !hasError
  const showErrorMessage = hasError && !isLoading
  const isEmpty =
    isNilOrEmpty(categories) && !(showLinearProgress || showErrorMessage)
  const renderList = !(showLinearProgress || showErrorMessage || isEmpty)

  const isDraft = properties?.status === 'DRAFT'

  return (
    <StyledList data-testid="sections" $hasTopBar={hasTopBar}>
      {showLinearProgress && <LinearProgress />}
      {showErrorMessage && (
        <StyledMessage>Failed to fetch sections.</StyledMessage>
      )}
      {isEmpty && <StyledMessage>No results found.</StyledMessage>}
      {renderList &&
        categories.map((data) => {
          return (
            <StyledListItem data-testid="sectionsListItem" key={data.id}>
              <>
                {data.itemCategory.name}
                {data.itemCategory.name !== 'Uncategorized' && (
                  <StyledCategoryId>
                    | ID: {data.itemCategory.databaseId}
                  </StyledCategoryId>
                )}
                {data.hasBeenEdited && (
                  <Tag text="EDITS APPLIED" color="highlight_04" />
                )}
                <EditSection categoryEditContext={data} isDraft={isDraft} />
              </>
            </StyledListItem>
          )
        })}
    </StyledList>
  )
}
