import Button from 'app/components/Button/Button'
import ChevronDown from 'app/components/Icons/ChevronDown'
import ChevronUp from 'app/components/Icons/ChevronUp'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledListContainer = styled.div`
  overflow-y: auto;
  width: 100%;
`

const StyledButton = styled(Button)`
  padding: 0;
`

const StyledItemList = styled.ul<{ $isVisible: boolean }>`
  height: ${(props) => (props.$isVisible ? 'auto' : 0)};
  margin: 0;
  opacity: ${(props) => (props.$isVisible ? 1 : 0)};
  overflow: hidden;
  padding: 0;
`

export interface OverflowListProps {
  items: string[]
  maxItems: number
}

export function OverflowList(props: OverflowListProps) {
  const { items, maxItems } = props
  const [showMore, setShowMore] = useState(false)

  const hasItemsBelowCutoff = items.length > maxItems
  const aboveCutoff = items.slice(0, maxItems)
  const belowCutoff = items.slice(maxItems)

  return (
    <>
      <StyledListContainer>
        <StyledItemList $isVisible>
          {aboveCutoff.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </StyledItemList>

        <StyledItemList $isVisible={showMore}>
          {belowCutoff.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </StyledItemList>
      </StyledListContainer>

      {hasItemsBelowCutoff && (
        <StyledButton
          variant="tertiary"
          startIcon={showMore ? <ChevronUp /> : <ChevronDown />}
          onClick={() => setShowMore(!showMore)}>
          {showMore ? 'View less' : `View more (${items.length - maxItems})`}
        </StyledButton>
      )}
    </>
  )
}
