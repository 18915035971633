import {
  GetGuideStructurePropertiesQuery,
  GetGuideStructurePropertiesQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { useDepartmentBundleId } from 'app/hooks/useDepartmentBundleId'
import { $activeBusinessLevel } from 'app/layouts/Customer/CustomerPageLayout.variables'
import { GUIDE_STRUCTURE_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructure'
import { isNil } from 'lodash-es'

export const GET_GUIDE_STRUCTURE_PROPERTIES = gql`
  ${GUIDE_STRUCTURE_FIELDS}
  query getGuideStructureProperties(
    $customerId: ID!
    $departmentBundleId: ID!
    $businessLevelInstanceId: ID!
  ) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        guideStructure(
          departmentBundleId: $departmentBundleId
          businessLevelInstanceId: $businessLevelInstanceId
        ) {
          ...GuideStructureFields
        }
      }
    }
  }
`

export default function useGetProperties() {
  const { activeCustomerId } = useCustomers()
  const [departmentBundleId] = useDepartmentBundleId()
  const businessLevelInstanceId = useReactiveVar($activeBusinessLevel)

  const { data, error, loading } = useQuery<
    GetGuideStructurePropertiesQuery,
    GetGuideStructurePropertiesQueryVariables
  >(GET_GUIDE_STRUCTURE_PROPERTIES, {
    onError: (err) => {
      Sentry.captureMessage(
        `Failed to fetch guide structure for ${activeCustomerId}: ${err}`,
        {
          level: 'warning',
        },
      )
    },
    skip:
      isNil(activeCustomerId) ||
      isNil(businessLevelInstanceId) ||
      !departmentBundleId,
    variables: {
      customerId: activeCustomerId!,
      departmentBundleId,
      businessLevelInstanceId: businessLevelInstanceId!,
    },
  })

  const guideStructure =
    data?.node?.__typename === 'Customer' ? data.node.guideStructure : undefined

  const showInventorySettings =
    !isNil(guideStructure) && guideStructure.showInventorySettings

  return {
    properties: guideStructure ?? null,
    showInventorySettings,
    primaryKeyType:
      guideStructure?.primaryCategoryKeyType === 'CLASS'
        ? 'Major Category'
        : 'Category',
    primaryKeyTypePlural:
      guideStructure?.primaryCategoryKeyType === 'CLASS'
        ? 'Major Categories'
        : 'Categories',
    error,
    loading,
  }
}
