import { get, pick } from 'lodash-es'
import qs from 'query-string'

const parseOptions = {
  parseBooleans: true,
}

const cleanQuery = (parsedQuery: qs.ParsedQuery<string>) => {
  const cleanedQueryObj: qs.ParsedQuery<string> = {}

  // eslint-disable-next-line no-restricted-syntax
  for (const query in parsedQuery) {
    if (parsedQuery[query]) {
      cleanedQueryObj[query] = parsedQuery[query]
    }
  }

  return cleanedQueryObj
}

export const parseQueryString = (queryString: string) => {
  return qs.parse(queryString, parseOptions)
}

export const stringifyParams = (params: { [key: string]: string }) => {
  return qs.stringify(params)
}

const setQueryStringWithoutPageReload = (queryString: string) => {
  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryString}`

  window.history.replaceState({ path: newUrl }, '', newUrl)
}

export const convertArrayToQueryStringValue = <T>(arr: T[]) => {
  return arr.join(',')
}

export const getAllQueryStringValues = (
  keys: string[],
  queryString: string = window.location.search,
) => {
  return pick(parseQueryString(queryString), keys)
}

export const getQueryStringValue = (
  key: string,
  queryString: string = window.location.search,
) => {
  return get(parseQueryString(queryString), key, null)
}

export const setQueryStringValues = (
  newValue: Record<string, string>,
  queryString: string = window.location.search,
) => {
  const newValues = {
    ...(queryString !== window.location.search
      ? qs.parse(window.location.search)
      : {}),
    ...qs.parse(queryString),
    ...newValue,
  }

  setQueryStringWithoutPageReload(
    decodeURIComponent(qs.stringify(cleanQuery(newValues))),
  )
}
