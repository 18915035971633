import React from 'react'
import styled from 'styled-components'

const StyledFilters = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  column-gap: 12px;
  display: flex;
  padding: 12px 20px;
`

interface FiltersProps {
  children: React.ReactNode
}

export default function Filters({ children }: FiltersProps) {
  return <StyledFilters>{children}</StyledFilters>
}
