import Button from 'app/components/Button/Button'

import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { useDeleteConfiguration } from 'app/packages/internal/customerConfigs/configurations/api/mutations/useDeleteConfiguration'
import { ConfigurationsRow } from 'app/packages/internal/customerConfigs/configurations/api/queries/useGetConfigurations'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

export const StyledInnerWrapper = styled.div`
  max-height: 450px;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export interface ConfigurationsDeleteModalProps {
  activeRow: ConfigurationsRow
  onClose: () => void
  onSuccess: () => void
}

export default function ConfigurationsDeleteModal({
  activeRow,
  onClose,
  onSuccess,
}: ConfigurationsDeleteModalProps) {
  const [loading, setLoading] = useState(false)
  const [deleteConfiguration] = useDeleteConfiguration()
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await deleteConfiguration(activeRow._id)
      setLoading(false)
      onSuccess()
    } catch (error) {
      setLoading(false)
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete RDB Configuration</Title>
      </Header>

      <Body>
        Delete the following configuration?
        <p>
          <strong>Configuration Type:</strong>{' '}
          {activeRow.configurationType.name}
          <br />
          {activeRow.store && (
            <>
              <strong>Store:</strong> {activeRow.store?.name} (
              {activeRow.store?.id})<br />
            </>
          )}
          {activeRow.workflow && (
            <>
              <strong>Workflow:</strong> {activeRow.workflow?.name} (
              {activeRow.workflow?.id})<br />
            </>
          )}
          <strong>Value:</strong> {activeRow.value}
        </p>
        {errorMessage && (
          <StyledErrorMessage message={errorMessage} severityType="error" />
        )}
      </Body>

      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleDelete}>
            Delete
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
