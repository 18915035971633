import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import Link from 'app/components/Link/Link'
import React from 'react'
import styled from 'styled-components'

export interface Crumb {
  name: string
  route?: string | null
}

export interface Props {
  crumbs: Crumb[]
  className?: string
}

const StyledBreadcrumbs = styled(MuiBreadcrumbs)`
  margin: 0;
`

export default function Breadcrumbs({ crumbs, className }: Props) {
  return (
    <StyledBreadcrumbs separator=">" className={className}>
      {crumbs.map((crumb, index) => {
        if (index === crumbs.length - 1 || !crumb.route) {
          return (
            <Typography key={crumb.name} color="text.primary">
              {crumb.name}
            </Typography>
          )
        } else {
          return (
            <Link key={crumb.name} to={{ pathname: crumb.route }}>
              {crumb.name}
            </Link>
          )
        }
      })}
    </StyledBreadcrumbs>
  )
}
