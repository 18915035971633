export const MIN_HEADER_HEIGHT = 80
export const TOP_BAR_HEIGHT = 56

export enum ErrorCode {
  UserEmailTaken = 'USER__EMAIL_TAKEN',
  UserInviteDateInvalid = 'USER_INVITE_DATE_INVALID',
  UserInvalidDataForAccessRole = 'USER__INVALID_DATA_FOR_ACCESS_ROLE',
}

export const UTC_TIMEZONE = 'UTC'

export enum USER_ACCESS_PERMISSIONS {
  DATA_UPLOAD = 'DATA_UPLOAD',
  DC_REPLENISHMENT_MANAGE = 'DC_REPLENISHMENT_MANAGE',
  DC_INSIGHTS_MANAGE = 'DC_INSIGHTS_MANAGE',
  ENGINEERING_TOOLS = 'ENGINEERING_TOOLS',
  INVENTORY_APPROVAL = 'INVENTORY_APPROVAL',
  INVENTORY_VALIDATION = 'INVENTORY_VALIDATION',
  INTERNAL_ORDER_MANAGE = 'INTERNAL_ORDER_MANAGE',
  USERS_MANAGE = 'USERS_MANAGE',
  OPS_INSIGHTS = 'OPS_INSIGHTS',
}

export enum ROLES {
  AFRESH_ADMIN = 'AFRESH_ADMIN',
  AFRESH_SYSTEM = 'AFRESH_SYSTEM',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  CUSTOMER_MANAGER = 'CUSTOMER_MANAGER',
  CUSTOMER_STORE = 'CUSTOMER_STORE',
  CUSTOMER_SUPERVISOR = 'CUSTOMER_SUPERVISOR',
  CUSTOMER_DC_BUYER = 'CUSTOMER_DC_BUYER',
  CUSTOMER_REVIEWER = 'CUSTOMER_REVIEWER',
}

export enum ModalEditModes {
  ADD,
  REMOVE,
  UNDO,
}

export const EM_DASH = '—'

export const ENTER = 'Enter'
export const SPACE = ' '

/**
 * Only users with one of these access_roles can login to Portal.
 */
export const PORTAL_ACCESS_ROLES = [
  ROLES.AFRESH_ADMIN,
  ROLES.CUSTOMER_ADMIN,
  ROLES.CUSTOMER_DC_BUYER,
  ROLES.CUSTOMER_STORE,
  ROLES.CUSTOMER_SUPERVISOR,
  ROLES.CUSTOMER_REVIEWER,
]

export enum ENVIRONMENTS {
  'PRODUCTION' = 'production',
  'STAGING' = 'staging',
  'DEVELOPMENT' = 'development',
}

export const PAGE_SIZE_OPTIONS = [25, 50, 100]
