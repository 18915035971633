import { CreateWorkflowAvailabilityInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { WORKFLOW_AVAILABILITY_ERRORS } from 'app/packages/internal/customerConfigs/workflowAvailability/api/errors'
import { WORKFLOW_AVAILABILITY_ROW } from 'app/packages/internal/customerConfigs/workflowAvailability/api/fragments/WorkflowAvailabilityRow'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_WORKFLOW_AVAILABILITY = gql`
  ${WORKFLOW_AVAILABILITY_ROW}
  mutation createWorkflowAvailability(
    $createWorkflowAvailabilityInput: [CreateWorkflowAvailabilityInput!]!
  ) {
    createWorkflowAvailability(input: $createWorkflowAvailabilityInput) {
      results {
        ...WorkflowAvailabilityRow
      }
    }
  }
`

export function useAddWorkflowAvailability() {
  const [addWorkflowAvailability, result] = useMutation(
    CREATE_WORKFLOW_AVAILABILITY,
    {
      refetchQueries: ['CustomerConfigWorkflowAvailability'],
    },
  )

  return tuple([
    useCallback(
      async (input: CreateWorkflowAvailabilityInput) => {
        const { errors } = await addWorkflowAvailability({
          variables: {
            createWorkflowAvailabilityInput: [input],
          },
        })

        if (errors?.length) {
          throw new Error(
            WORKFLOW_AVAILABILITY_ERRORS[getFirstErrorCode(errors)] ||
              `Add new workflow availability config failed: ${errors[0].message}`,
          )
        }
      },
      [addWorkflowAvailability],
    ),
    result,
  ])
}
