import {
  BusinessUnit,
  GetInventoryResultsFiltersQuery,
  GetInventoryResultsFiltersQueryVariables,
  InventoryResultsDataFilters,
  InventoryResultsFiltersStoreFragment,
  Region,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { isNil } from 'lodash-es'

export const GET_INVENTORY_RESULTS_FILTERS = gql`
  query GetInventoryResultsFilters($businessUnitIds: [ID!], $regionIds: [ID!]) {
    inventoryResultsFilters {
      stores(regionIds: $regionIds, businessUnitIds: $businessUnitIds) {
        edges {
          node {
            ...InventoryResultsFiltersStore
          }
        }
      }
      regions(businessUnitIds: $businessUnitIds) {
        id
        name
        businessUnitId
      }
      businessUnits {
        id
        name
      }
      accountingPeriods {
        id
        periodNumber
        periodYearName
      }
      inventoryStatuses
      departmentBundles {
        id
        name
      }
    }
  }

  fragment InventoryResultsFiltersStore on Store {
    _id
    customerStoreId
    name
    regionId
    regionName
    businessUnitId
    businessUnitName
  }
`

export function areStoreFiltersValid(
  stores: Store[],
  regions: Region[],
  businessUnits: BusinessUnit[],
  selectedStoreIds: string[],
  selectedRegionIds: string[],
  selectedBusinessUnitIds: string[],
) {
  // Hides an API bug. If filtering by a store (from region A) and a region (region B)
  // We'd expect no results. This makes sure we see no results.
  const storeIds = new Set(stores.map((store) => store.customerStoreId))
  const areStoresValid =
    selectedStoreIds.length === 0 ||
    selectedStoreIds.some((storeId) => storeIds.has(storeId))

  const regionIds = new Set(regions.map((region) => region.id))
  const areRegionsValid =
    selectedRegionIds.length === 0 ||
    selectedRegionIds.some((regionId) => regionIds.has(regionId))

  const businessUnitIds = new Set(businessUnits.map((bu) => bu.id))
  const areBusinessUnitsValid =
    selectedBusinessUnitIds.length === 0 ||
    selectedBusinessUnitIds.some((buId) => businessUnitIds.has(buId))

  return areStoresValid && areRegionsValid && areBusinessUnitsValid
}

export type Store = InventoryResultsFiltersStoreFragment

export interface DepartmentBundle {
  id: string
  name: string
}

export default function useGetEndingInventoryFilters(
  filters: InventoryResultsDataFilters,
) {
  const { activeCustomerId } = useCustomers()

  const { error, data, loading, refetch } = useQuery<
    GetInventoryResultsFiltersQuery,
    GetInventoryResultsFiltersQueryVariables
  >(GET_INVENTORY_RESULTS_FILTERS, {
    skip: isNil(activeCustomerId),
    variables: {
      businessUnitIds: filters.businessUnitIds,
      regionIds: filters.regionIds,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    Sentry.captureException(error, (sentryScope) => {
      sentryScope.setContext('inventory', {
        activeCustomerId,
      })
      sentryScope.setTransactionName(`Failed to fetch inventory filters`)
      sentryScope.setLevel('warning')
      return sentryScope
    })
  }

  if (data) {
    const stores: Store[] = []
    data.inventoryResultsFilters.stores.edges.forEach((e) => {
      if (e.node) {
        stores.push(e.node)
      }
    })
    stores.sort((a, b) => {
      return a.customerStoreId.localeCompare(b.customerStoreId, undefined, {
        numeric: true,
      })
    })
    const {
      regions,
      businessUnits,
      departmentBundles,
      accountingPeriods,
      inventoryStatuses,
    } = data.inventoryResultsFilters

    return {
      filtersError: error,
      stores,
      regions,
      businessUnits,
      departmentBundles,
      accountingPeriods,
      inventoryStatuses,
      isLoadingFilters: loading,
    }
  }

  return {
    filtersError: error,
    stores: [],
    regions: [],
    businessUnits: [],
    departmentBundles: [],
    accountingPeriods: [],
    inventoryStatuses: [],
    isLoadingFilters: loading,
    refetch,
  }
}
