import {
  OrderWorkflowsQuery,
  OrderWorkflowsQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { isNil } from 'lodash-es'

export interface Workflow {
  id: string
  databaseId: string
  workflowName: string
}

export const GET_ORDER_WORKFLOWS = gql`
  query orderWorkflows($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        workflows(type: ORDER) {
          _id
          id
          name
        }
      }
    }
  }
`

export default function useGetOrderWorkflows() {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<
    OrderWorkflowsQuery,
    OrderWorkflowsQueryVariables
  >(GET_ORDER_WORKFLOWS, {
    skip: isNil(activeCustomerId),
    variables: { customerId: activeCustomerId! },
  })

  let orderWorkflows: Workflow[] = []

  if (
    data &&
    data.node &&
    data.node.__typename === 'Customer' &&
    data.node.workflows
  ) {
    orderWorkflows = data.node.workflows.filter(Boolean).map((workflow) => {
      return {
        id: workflow!._id,
        databaseId: workflow!.id,
        workflowName: workflow!.name,
      }
    })
  }

  return {
    data: orderWorkflows,
    isLoading: loading,
  }
}
