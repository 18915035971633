import {
  DeleteStoreClosureEventMutation,
  DeleteStoreClosureEventMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { STORE_CLOSURE_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreClosureEventFields'
import { ORDER_DELIVERY_ERROR_CODES } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { hasErrorCode } from 'app/utils'
import tuple from 'shared/tuple'

export const DELETE_STORE_CLOSURE_EVENT = gql`
  ${STORE_CLOSURE_EVENT_FIELDS}
  mutation deleteStoreClosureEvent($id: ID!) {
    deleteStoreClosureEvent(input: { id: $id }) {
      storeClosureEvent {
        ...StoreClosureEventFields
      }
    }
  }
`

export default function useDeleteStoreClosureEvent() {
  const [deleteStoreClosureEvent, result] = useMutation<
    DeleteStoreClosureEventMutation,
    DeleteStoreClosureEventMutationVariables
  >(DELETE_STORE_CLOSURE_EVENT, {
    refetchQueries: ['getStoreClosures', 'getOrderDeliveryScheduleIssues'],
    awaitRefetchQueries: true,
    onError: (error) => {
      Sentry.captureMessage(error.message, {
        level: 'warning',
        extra: {
          gqlErrors: JSON.stringify(error),
        },
      })
    },
  })

  const deleteStoreClosureEventWrapper = async ({
    variables,
  }: {
    variables: DeleteStoreClosureEventMutationVariables
  }) => {
    const { errors } = await deleteStoreClosureEvent({
      variables,
    })

    if (errors && !!errors.length) {
      if (
        hasErrorCode(errors, ORDER_DELIVERY_ERROR_CODES.STORE_CLOSURE_IN_PAST)
      ) {
        throw new Error('Cannot delete a store closure in the past.')
      }

      throw new Error('Unable to delete store closure.')
    }
  }

  return tuple([deleteStoreClosureEventWrapper, result])
}
