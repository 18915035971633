import { Checkbox, FormControlLabel, List, ListItem } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import styled from 'styled-components'

/**
 * A type that allows you to specify required properties, the rest made optional.
 */
type RequireOnly<T, K extends keyof T> = Pick<T, K> & Partial<Omit<T, K>>

const DEFAULT_LIST_HEIGHT = 300
const ITEM_HEIGHT = 32

const StyledList = styled(List)<{ $height?: number; $width: number }>`
  height: ${({ $height }) => ($height ? `${$height}px` : 'inherit')};
  width: ${({ $width }) => `${$width}px`};
  max-height: calc(100vh - 372px);
  overflow-y: auto;
`

const StyledMenuItem = styled(ListItem)`
  padding: 0;
`

const StyledFormLabel = styled(FormControlLabel)`
  display: flex;
  height: ${ITEM_HEIGHT}px;
  line-height: 32px;
  margin: 0;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.neutral_06};
  }
`

const StyledCheckbox = styled(Checkbox)`
  color: ${({ theme }) => theme.colors.secondary};
  margin: 0 8px;
  padding: 0;

  &.Mui-checked,
  &.Mui-checked + span {
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: bold;
  }

  & + span {
    display: inline-block;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 188px;
  }
`

interface Option {
  id: string
  name: string
}

export interface ListProps {
  filter: string
  onSelect: (selectedId: string) => void
  options: Option[]
  selectedOptions: Set<string>
  virtualize?: boolean
  height?: number
  width?: number
}

export default function MultiSelectList({
  options,
  filter,
  onSelect,
  selectedOptions,
  virtualize,
  height,
  width = 250,
}: ListProps) {
  const list = useMemo(() => {
    if (!filter) {
      return options
    }

    return options.filter((option) => {
      return (
        option.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !==
        -1
      )
    })
  }, [filter, options])

  // The height for our virtualized, fixed-height list.
  // Shrink the height to match for lists with few items.
  const fixedHeight = Math.min(
    height ?? DEFAULT_LIST_HEIGHT,
    list.length * ITEM_HEIGHT,
  )
  // Don't override if height isn't set via props - allow list size to grow.
  const variableHeight = height ? fixedHeight : undefined

  const handleOnSelect = useCallback(
    (selectedId: string) => onSelect(selectedId),
    [onSelect],
  )

  const renderItem = ({
    index,
    style,
  }: RequireOnly<ListChildComponentProps, 'index'>) => {
    const option = list[index]
    const isChecked = selectedOptions.has(option.id)
    return (
      <StyledMenuItem key={option.id} style={style}>
        <StyledFormLabel
          checked={isChecked}
          control={<StyledCheckbox />}
          label={option.name}
          onChange={() => handleOnSelect(option.id)}
          title={option.name}
        />
      </StyledMenuItem>
    )
  }

  // Better performance for larger lists
  // Use virtualization if you see lag or lockups when the list first renders
  if (virtualize || list.length > 100) {
    // Consider using `react-virtualized-auto-sizer` if we want the list to fill the page vertically
    // https://github.com/bvaughn/react-window/blob/6ff5694ac810617515acf74401ba68fe2951133b/README.md?plain=1#L117
    return (
      <FixedSizeList
        height={fixedHeight}
        width={width}
        itemCount={list.length}
        itemSize={ITEM_HEIGHT}
        overscanCount={5}>
        {renderItem}
      </FixedSizeList>
    )
  }

  return (
    <StyledList $width={width} $height={variableHeight}>
      {list.map((_, index) => renderItem({ index }))}
    </StyledList>
  )
}
