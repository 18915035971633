import { AddCircleOutline } from '@mui/icons-material'
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { useCustomers } from 'app/CustomerContext'
import Button from 'app/components/Button/Button'
import TableHeader from 'app/components/Tables/TableHeader'
import CreateCustomerModal from 'app/packages/internal/customerSetup/pages/CreateCustomerModal'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledTable = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 400px)' : 'calc(100vh - 230px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 400px)' : 'calc(100vh - 230px)'};
  padding-top: ${toRem(12)};

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }
`

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

const columns: GridColDef[] = [
  {
    field: 'databaseId',
    headerName: 'ID',
    // convert to number for sorting purposes
    valueGetter: (_, row) => +row.databaseId,
    minWidth: 180,
  },
  {
    field: 'key',
    headerName: 'Key',
    minWidth: 180,
  },
  {
    field: 'name',
    headerName: 'Name',
    minWidth: 200,
  },
]

export default function CustomerSetupTable({
  setupDetailsExpanded,
}: {
  setupDetailsExpanded: boolean
}) {
  const { customers } = useCustomers()
  const [showAddNew, setShowAddNew] = useState(false)

  if (!customers) return null
  return (
    <>
      <StyledTable $setupDetailsExpanded={setupDetailsExpanded}>
        <TableHeader>
          <StyledTableHeaderButtonsContainer>
            <StyledAddNewButton onClick={() => setShowAddNew(true)}>
              <StyledAddNewIcon /> Create New
            </StyledAddNewButton>
          </StyledTableHeaderButtonsContainer>
        </TableHeader>
        <DataGridPremium
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'databaseId',
                  sort: 'asc',
                },
              ],
            },
          }}
          columns={columns}
          disableAggregation
          disableRowGrouping
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          getRowId={(row) => row.id}
          rows={customers}
        />
      </StyledTable>
      {showAddNew && (
        <CreateCustomerModal onClose={() => setShowAddNew(false)} />
      )}
    </>
  )
}
