import {
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import tuple from 'shared/tuple'

export const mutation = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(input: { resetPasswordToken: $token, password: $password }) {
      user {
        _id
        accessRole
      }
    }
  }
`

export function useResetPassword() {
  const [resetPassword, result] = useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(mutation)

  return tuple([
    (token: string, password: string) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      return resetPassword({ variables: { token, password } })
    },
    result,
  ])
}
