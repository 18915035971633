import { ConfigAdScheduleRow } from '@api/__gen__/gql'
import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import Button from 'app/components/Button/Button'
import TableHeader from 'app/components/Tables/TableHeader'
import { EM_DASH } from 'app/constants'
import { formatDatetime } from 'app/helpers/date'
import { $activeAdSchedulesConfig } from 'app/packages/internal/customerConfigs/adSchedules/pages/AdSchedules.variables'
import React from 'react'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 635px)' : 'calc(100vh - 310px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 635px)' : 'calc(100vh - 310px)'};
  padding-top: 12px;

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

export interface Props {
  isLoading?: boolean
  rows: ConfigAdScheduleRow[]
  setupDetailsExpanded: boolean
  onClickAddNew: () => void
}

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

export const AdScheduleColumns: GridColDef[] = [
  {
    field: 'id',
    valueGetter: (_, row) => row.id.label,
    headerName: 'Config ID',
    width: 72,
  },
  {
    field: 'workflowId',
    valueGetter: (_, row) => row.workflowId.label,
    headerName: 'Workflow',
    width: 152,
  },
  {
    field: 'businessLevel',
    valueGetter: (_, row) => row.businessLevel.label,
    headerName: 'Business Level',
    width: 152,
  },
  {
    field: 'businessLevelId',
    valueGetter: (_, row) => row.businessLevelId?.label,
    renderCell: (row) => {
      return row.value ?? EM_DASH
    },
    headerName: 'Business Level ID',
    width: 152,
  },
  {
    field: 'startDate',
    valueGetter: (_, row) => row.startDate.label,
    renderCell: (row) => formatDatetime(row.value, { timezone: 'UTC' }),
    headerName: 'Start Date (UTC)',
    width: 152,
  },
  {
    field: 'intervalWeeks',
    valueGetter: (_, row) => row.intervalWeeks.label,
    headerName: 'Interval Weeks',
    width: 104,
  },
  {
    field: 'createdAt',
    valueGetter: (_, row) => row.createdAt.label,
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Created At',
    width: 152,
  },
  {
    field: 'updatedAt',
    valueGetter: (_, row) => row.updatedAt.label,
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Updated At',
    width: 152,
  },
]

export default function AdSchedulesTable(props: Props) {
  const { isLoading, rows, onClickAddNew, setupDetailsExpanded } = props

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <TableHeader>
        <StyledTableHeaderButtonsContainer>
          <StyledAddNewButton onClick={onClickAddNew}>
            <StyledAddNewIcon /> Add New
          </StyledAddNewButton>
        </StyledTableHeaderButtonsContainer>
      </TableHeader>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row._id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
        }}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        columns={AdScheduleColumns}
        loading={isLoading}
        onRowClick={({ row }) => $activeAdSchedulesConfig(row.id.value)}
      />
    </StyledTableContainer>
  )
}
