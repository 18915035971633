import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { WORKFLOW_AVAILABILITY_ERRORS } from 'app/packages/internal/customerConfigs/workflowAvailability/api/errors'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_WORKFLOW_AVAILABILITY = gql`
  mutation deleteWorkflowAvailability(
    $deleteWorkflowAvailabilityInput: DeleteWorkflowAvailabilityInput!
  ) {
    deleteWorkflowAvailability(input: $deleteWorkflowAvailabilityInput) {
      results {
        _id
      }
    }
  }
`

export function useDeleteWorkflowAvailability() {
  const [deleteWorkflowAvailability, result] = useMutation(
    DELETE_WORKFLOW_AVAILABILITY,
    {
      refetchQueries: ['CustomerConfigWorkflowAvailability'],
    },
  )

  return tuple([
    useCallback(
      async (input: string) => {
        const { errors } = await deleteWorkflowAvailability({
          variables: {
            deleteWorkflowAvailabilityInput: {
              ids: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            WORKFLOW_AVAILABILITY_ERRORS[getFirstErrorCode(errors)] ||
              `Delete workflow availability config failed: ${errors[0].message}`,
          )
        }
      },
      [deleteWorkflowAvailability],
    ),
    result,
  ])
}
