import { OffsetPageInfo } from '@api/__gen__/gql'
import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import SearchBox from 'app/components/SearchBox/SearchBox'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import Tag from 'app/components/Tag/Tag'
import { PAGE_SIZE_OPTIONS } from 'app/constants'
import { formatDatetime } from 'app/helpers/date'
import { WorkflowAvailabilityRow } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetWorkflowAvailability'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import { $activeWorkflowAvailabilityConfig } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability.variables'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 320px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 320px)'};

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

const StyledFilters = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  column-gap: 12px;
  display: flex;
  padding: 12px 20px;
`

export interface Props {
  isLoading?: boolean
  autoPageSize: boolean
  disableVirtualization?: boolean
  rows: WorkflowAvailabilityRow[]
  pageInfo?: OffsetPageInfo
  setupDetailsExpanded: boolean
  onPageChange: (pageIndex: number, pageSize: number) => void
  onClickAddNew: () => void
  workflowFilters: Option[] | null
  workflowFilter: string
  setWorkflowFilter: (value: string) => void
  businessLevelFilters: Option[] | null
  businessLevelFilter: string
  setBusinessLevelFilter: (value: string) => void
  searchTerm: string
  setSearchTerm: (value: string) => void
}

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${toRem(300)};
  line-height: 0;
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export const WorkflowAvailabilityColumns: GridColDef[] = [
  {
    field: 'workflow',
    valueGetter: (_, row) => row.workflow,
    headerName: 'Workflow',
    renderCell: (row) => {
      if (!row.value) return 'ALL (-1)'
      return `${row.value.name} (${row.value.id})`
    },
    width: 180,
    sortable: false,
  },
  {
    field: 'departmentBundle',
    valueGetter: (_, row) => row.workflow.departmentBundle.name,
    headerName: 'Department Bundle',
    width: 182,
    sortable: false,
  },
  {
    field: 'businessLevel',
    valueGetter: (_, row) => row.businessLevel,
    renderCell: (row) => {
      if (!row.value) return 'CHAINWIDE (-1)'
      return `${row.value.businessLevelMetadata.displayName}`
    },
    headerName: 'Business Level',
    width: 140,
    sortable: false,
  },
  {
    field: 'businessLevelInstance',
    valueGetter: (_, row) => row.businessLevel,
    renderCell: (row) => {
      if (!row.value || row.value.id === '-1') return 'CHAINWIDE (-1)'
      return `${row.value.name} (${row.value.id})`
    },
    headerName: 'Business Level Instance',
    width: 200,
    sortable: false,
  },
  {
    field: 'available',
    renderCell: (row) =>
      row.value ? (
        <Tag text="Enabled" color="highlight_08" />
      ) : (
        <Tag text="Disabled" color="highlight_02" />
      ),
    headerName: 'Availability',
    width: 100,
    sortable: false,
  },
  {
    field: 'createdAt',
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Created At',
    width: 150,
    sortable: false,
  },
  {
    field: 'updatedAt',
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Updated At',
    width: 150,
    sortable: false,
  },
]

export default function WorkflowAvailabilityTable(props: Props) {
  const {
    isLoading,
    rows,
    onClickAddNew,
    setupDetailsExpanded,
    pageInfo,
    onPageChange,
    disableVirtualization = false,
    workflowFilters,
    workflowFilter,
    setWorkflowFilter,
    businessLevelFilters,
    businessLevelFilter,
    setBusinessLevelFilter,
    searchTerm,
    setSearchTerm,
    autoPageSize,
  } = props

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <StyledFilters>
        <SingleSelect
          options={workflowFilters || []}
          value={workflowFilter}
          label="Workflow"
          handleChange={(e) => setWorkflowFilter(e.target.value)}
        />
        <SingleSelect
          options={businessLevelFilters || []}
          value={businessLevelFilter}
          label="Business Level"
          handleChange={(e) => setBusinessLevelFilter(e.target.value)}
        />
        <SearchBox
          placeholderText="Search Business Level ID"
          defaultValue={searchTerm}
          onSearchTextChange={(term) => setSearchTerm(term)}
        />
        <StyledTableHeaderButtonsContainer>
          <StyledAddNewButton onClick={onClickAddNew}>
            <StyledAddNewIcon /> Add New
          </StyledAddNewButton>
        </StyledTableHeaderButtonsContainer>
      </StyledFilters>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row._id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
        }}
        paginationModel={{
          pageSize: pageInfo?.pageSize ?? DEFAULT_PAGE_SIZE,
          page: pageInfo?.currentPage ? pageInfo?.currentPage - 1 : 0,
        }}
        paginationMode="server"
        onPaginationModelChange={(paginationModel) => {
          onPageChange(paginationModel.page, paginationModel.pageSize)
        }}
        rowCount={pageInfo?.totalCount ?? 0}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        columns={WorkflowAvailabilityColumns}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        pagination
        loading={isLoading}
        onRowClick={({ row }) => $activeWorkflowAvailabilityConfig(row._id)}
        autoPageSize={autoPageSize}
      />
    </StyledTableContainer>
  )
}
