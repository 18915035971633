import { CustomerListQuery, CustomerListQueryVariables } from '@api/__gen__/gql'
import { ApolloClient, gql } from '@apollo/client'
import { useUserContext } from 'app/context/userContext'
import { reidentifyUserWithExternalSDKs } from 'app/packages/core/pages/login/login.helpers'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

export const LOCAL_STORAGE_KEY = 'activeCustomer'

export type CustomerId = string | null

export type AfreshCustomer = null

export interface ExternalCustomer {
  id: string
  databaseId: string
  name: string
  key: string
}

export type Customer = ExternalCustomer | AfreshCustomer

export type Customers = Customer[]

export const customerListGql = gql`
  query customerList {
    viewer {
      permissions {
        customers {
          _id
          id
          name
          key
        }
      }
    }
  }
`

export async function fetchCustomers(
  client: ApolloClient<unknown>,
): Promise<Customers> {
  const resp = await client.query<
    CustomerListQuery,
    CustomerListQueryVariables
  >({ query: customerListGql })
  return (resp.data?.viewer?.permissions?.customers || []).map((cust) =>
    cust
      ? { id: cust._id, databaseId: cust.id, name: cust.name, key: cust.key }
      : null,
  )
}

interface CustomerContextObj {
  activeCustomer: Customer | undefined
  activeCustomerId: CustomerId | undefined
  activeCustomerKey: string
  setActiveCustomer: (customer: Customer) => void
  customers: Customers | undefined
  setCustomers: (customers: Customers | undefined) => void
  activeCustomerRef: React.MutableRefObject<CustomerId | undefined>
}

export const CustomerContext = React.createContext<CustomerContextObj>({
  activeCustomer: undefined,
  activeCustomerId: undefined,
  activeCustomerKey: '',
  setActiveCustomer: () => {},
  customers: undefined,
  setCustomers: () => {},
  activeCustomerRef: { current: undefined },
})

interface Props {
  children: React.ReactNode
  initialCustomers?: Customers // for testing
}

export function CustomerContextWrapper({ children, initialCustomers }: Props) {
  const [activeCustomer, setActiveCustomer] = useState<Customer | undefined>(
    undefined,
  )
  const [customers, setCustomers] = useState<Customers | undefined>(undefined)
  const activeCustomerRef = useRef<CustomerId | undefined>(undefined)
  const { user } = useUserContext()

  const setActiveCustomerWrapper = useCallback(
    (customer: Customer) => {
      activeCustomerRef.current = customer?.id
      setActiveCustomer(customer)
    },
    [setActiveCustomer],
  )

  const setCustomersWrapper = useCallback(
    (custs: Customers | undefined) => {
      // when the user signs out, we clear the customer info
      if (custs === undefined) {
        activeCustomerRef.current = undefined
        setActiveCustomer(undefined)
        setCustomers(undefined)
      }

      // after authentication, save customers and load active one from disk
      else {
        setCustomers(custs)
        setActiveCustomer(custs[0]) //  // fall back to first customer
        activeCustomerRef.current = custs[0]?.id
      }
    },
    [setActiveCustomer, setCustomers],
  )

  useEffect(() => {
    if (initialCustomers) {
      setCustomersWrapper(initialCustomers)
    }
  }, [initialCustomers, setCustomersWrapper])

  useEffect(() => {
    if (activeCustomer && user) {
      reidentifyUserWithExternalSDKs(user, activeCustomer)
    }
  }, [activeCustomer, user])

  return (
    <CustomerContext.Provider
      value={{
        activeCustomerId: activeCustomer === null ? null : activeCustomer?.id,
        activeCustomer,
        activeCustomerKey: activeCustomer?.key || '',
        setActiveCustomer: setActiveCustomerWrapper,
        customers,
        setCustomers: setCustomersWrapper,
        activeCustomerRef,
      }}>
      {children}
    </CustomerContext.Provider>
  )
}

CustomerContextWrapper.defaultProps = {
  initialCustomers: undefined,
}

export function useCustomers() {
  return useContext(CustomerContext)
}
