import {
  CreateOrderDeliveryEventsMutation,
  CreateOrderDeliveryEventsMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import useWorkflowId from 'app/hooks/useWorkflowId'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_ORDER_DELIVERY_EVENTS = gql`
  mutation createOrderDeliveryEvents(
    $scheduleId: ID!
    $orderDeliveryEvents: [CreateOrderDeliveryEventArgs!]!
  ) {
    createOrderDeliveryEvents(
      input: {
        scheduleId: $scheduleId
        orderDeliveryEvents: $orderDeliveryEvents
      }
    ) {
      orderDeliveryEvents {
        _id
        delivery {
          date
          isEditable
        }
        order {
          date
          isEditable
        }
      }
    }
  }
`

export default function useCreateOrderDeliveryEvents() {
  const { scheduleId } = useGetOrderDeliverySchedule()
  const [workflowId] = useWorkflowId()

  const [mutation, result] = useMutation<
    CreateOrderDeliveryEventsMutation,
    CreateOrderDeliveryEventsMutationVariables
  >(CREATE_ORDER_DELIVERY_EVENTS, {
    refetchQueries: ['orderDeliveryEvents', 'getOrderDeliveryScheduleIssues'],
  })

  return tuple([
    useCallback(
      async (
        storeIds: string[],
        newOrderDate: string,
        newDeliveryDate: string,
      ) => {
        const { errors } = await mutation({
          variables: {
            scheduleId: scheduleId!,
            orderDeliveryEvents: storeIds.map((storeId) => {
              return {
                storeId,
                workflowId: workflowId!,
                orderDate: newOrderDate,
                deliveryDate: newDeliveryDate,
              }
            }),
          },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [scheduleId, mutation, workflowId],
    ),
    result,
  ])
}
