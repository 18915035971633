import { Box } from '@mui/material'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
}

export default function SectionTitle(props: Props) {
  return (
    <Box
      className={props.className}
      sx={{ textTransform: 'uppercase', padding: '10px 20px' }}
      fontSize={14}
      fontWeight="fontWeightBold">
      {props.children}
    </Box>
  )
}
