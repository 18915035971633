import { AddCircleOutline, Storefront } from '@mui/icons-material'
import Button from 'app/components/Button/Button'
import { OverflowList } from 'app/components/OverflowList/OverflowList'
import { Popover } from 'app/components/Popover/Popover'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { SelectBulkEditModal } from 'app/packages/storeSolutions/schedules/pages/BulkEdit/SelectBulkEditModal'
import { MAX_STORE_NAMES_SHOWN } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledStoreName = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 16px;
  row-gap: 4px;
  width: 100%;
`

const StyledButton = styled(Button)`
  padding: 0;
`

export interface StoreNameProps {
  addForm: React.ReactElement
  ariaLabel: string
  bulkEditStores: Store[]
  onSelectStores: (stores: Store[]) => void
  store: Store
  storeScheduleId: string
}

function StoreName({
  addForm,
  ariaLabel,
  bulkEditStores,
  onSelectStores,
  store,
  storeScheduleId,
}: StoreNameProps) {
  const { isEditing } = useGetOrderDeliverySchedule()

  const [showMatchingStoresModal, setShowMatchingStoresModal] = useState(false)

  const onClickEditMatchingStores = async () => {
    setShowMatchingStoresModal(true)
  }

  const isBulkEdit = bulkEditStores.length
  const canEditMatchingStores = isEditing && !isBulkEdit

  return (
    <>
      <StyledStoreName>
        {isBulkEdit ? (
          <OverflowList
            items={bulkEditStores.map((s) => s.name)}
            maxItems={MAX_STORE_NAMES_SHOWN}
          />
        ) : (
          <span>{store.name}</span>
        )}

        {isEditing && (
          <Popover
            renderAnchor={(params) => (
              <StyledButton
                aria-label={ariaLabel}
                onClick={params.onClick}
                startIcon={<AddCircleOutline />}
                variant="tertiary">
                Add event
              </StyledButton>
            )}
            withOverlay>
            {addForm}
          </Popover>
        )}

        {canEditMatchingStores && (
          <StyledButton
            onClick={onClickEditMatchingStores}
            startIcon={<Storefront />}
            variant="tertiary">
            Edit matching stores
          </StyledButton>
        )}
      </StyledStoreName>

      {showMatchingStoresModal && (
        <SelectBulkEditModal
          storeScheduleId={storeScheduleId}
          selectedStore={store}
          onClose={() => {
            setShowMatchingStoresModal(false)
          }}
          onSelectStores={onSelectStores}
        />
      )}
    </>
  )
}

export default React.memo(StoreName)
