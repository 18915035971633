import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 19.25H19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M7.3598 10.4898H10.4998V16.0298C10.4998 16.5798 10.9498 17.0298 11.4998 17.0298H12.4998C13.0498 17.0298 13.4998 16.5798 13.4998 16.0298V10.4898H16.6398C17.1598 10.4898 17.4298 9.8698 17.0698 9.4898L12.7298 4.8098C12.3298 4.3798 11.6598 4.3798 11.2598 4.8098L6.9198 9.4898C6.5698 9.8698 6.8398 10.4898 7.3498 10.4898H7.3598Z"
      fill="currentColor"
    />
  </svg>,
  'Import',
)
