import { ConfigAdScheduleTableMeta } from '@api/__gen__/gql'
import { useReactiveVar } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import Accordion from 'app/components/Accordion/Accordion'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import { useGetAdSchedules } from 'app/packages/internal/customerConfigs/adSchedules/api/queries/useGetAdSchedules'
import {
  useGetAdSchedulesOptions,
  useGetAdSchedulesStoreOptions,
} from 'app/packages/internal/customerConfigs/adSchedules/api/queries/useGetAdSchedulesOptions'
import { $activeAdSchedulesConfig } from 'app/packages/internal/customerConfigs/adSchedules/pages/AdSchedules.variables'
import AdSchedulesAddEditModal from 'app/packages/internal/customerConfigs/adSchedules/pages/AdSchedulesAddEditModal'
import AdSchedulesDeleteModal from 'app/packages/internal/customerConfigs/adSchedules/pages/AdSchedulesDeleteModal'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import { toRem } from 'app/styles'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import AdSchedulesTable from './AdSchedulesTable'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`

export const DEFAULT_PAGE_SIZE = 50

export default function AdSchedules() {
  const { activeCustomerId, activeCustomer } = useCustomers()
  const [storeSearchTerm, setStoreSearchTerm] = useState('')
  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const activeAdSchedulesConfig = useReactiveVar($activeAdSchedulesConfig)

  const { rows, loading } = useGetAdSchedules({
    customerId: activeCustomerId,
  })

  const { options, loading: optionsLoading } = useGetAdSchedulesOptions({
    customerId: activeCustomerId,
  })

  const {
    options: storeOptions,
    loading: storeOptionsLoading,
  } = useGetAdSchedulesStoreOptions({
    customerId: activeCustomerId,
    storeSearchTerm,
  })

  useEffect(() => {
    const shouldSetEditState =
      activeAdSchedulesConfig !== null &&
      modalState !== ModalState.edit &&
      modalState !== ModalState.delete
    if (shouldSetEditState) {
      setModalState(ModalState.edit)
    }
  }, [activeAdSchedulesConfig, modalState, setModalState])

  const onSuccessfulSave = useCallback(async () => {
    setModalState(null)
    $activeAdSchedulesConfig(null)
  }, [setModalState])

  const activeRow =
    activeAdSchedulesConfig !== null &&
    rows.find((row) => activeAdSchedulesConfig === row.id.value)

  return (
    <>
      <StyledHeader>Ad Schedules</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          This configuration is used to define the day-of-week ad schedule
          starts.
          <p>
            <strong>Start date:</strong> Selecting a date (vs. day-of-week) is
            necessary because ad interval may be &gt; 1 week. Typically,
            day-of-week ad schedule starts = when the floor display change is
            due by. Once the date for the ad schedule goes into effect, it will
            work in perpetuity following the specified interval. The start date
            timezone is hardcoded to 19:00 UTC time here so that it should be
            the same day when converted to local time. (This works right now
            because Afresh is only in North America.)
          </p>
          <p>
            <strong>Interval Weeks:</strong> Set this to the correct ad schedule
            interval weeks (eg. 1 interval week = ad schedule starts on X day
            every week; 2 interval weeks = ad schedule starts on X day every 2
            weeks)
          </p>
          <p>
            Note: Best practice is to have a chain-wide default. Use the biggest
            logical grouping whenever possible (eg. one entry for the whole
            region, vs. an entry for every store)
          </p>
          <p>
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/Afresh-Admin-Customer-Configs-Documentation-7972a905bd83461d802f5d75438ffd88',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <AdSchedulesTable
        rows={rows}
        isLoading={loading || optionsLoading}
        onClickAddNew={() => setModalState(ModalState.add)}
        setupDetailsExpanded={setupDetailsExpanded}
      />
      {modalState === ModalState.add && (
        <AdSchedulesAddEditModal
          modalState={modalState}
          options={{ ...options, ...storeOptions } as ConfigAdScheduleTableMeta} // merge options and searched store options
          onClose={() => {
            $activeAdSchedulesConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          storeSearchLoading={storeOptionsLoading}
          onChangeStoreSearch={(value) => setStoreSearchTerm(value)}
        />
      )}
      {modalState === ModalState.edit && activeRow && (
        <AdSchedulesAddEditModal
          modalState={modalState}
          options={{ ...options, ...storeOptions } as ConfigAdScheduleTableMeta} // merge options and searched store options
          onClose={() => {
            $activeAdSchedulesConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
          onDelete={() => setModalState(ModalState.delete)}
          storeSearchLoading={storeOptionsLoading}
          onChangeStoreSearch={(value) => setStoreSearchTerm(value)}
        />
      )}
      {modalState === ModalState.delete && activeRow && (
        <AdSchedulesDeleteModal
          onClose={() => {
            $activeAdSchedulesConfig(null)
            setModalState(null)
          }}
          customerName={activeCustomer?.name}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
        />
      )}
    </>
  )
}
