import MultiSelectList from 'app/components/MultiSelect/List'
import { Option } from 'app/components/MultiSelect/MultiSelect'
import TopLevelControls from 'app/components/MultiSelect/TopLevelControls'
import SearchBox from 'app/components/SearchBox/SearchBox'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledSearchBoxContainer = styled.div`
  padding: 0 8px;
`

const StyledSearchBox = styled(SearchBox)`
  margin: 4px 0;
  width: 100%;
`

interface SelectMenuProps {
  numSelected: number
  handleOnClear: () => void
  handleOnSelectAll: () => void
  handleOnSelect: (selectedId: string) => void
  options: Option[]
  selectedOptions: Set<string>
  virtualize?: boolean
  width?: number
}

export default function SelectMenu({
  numSelected,
  handleOnClear,
  handleOnSelectAll,
  handleOnSelect,
  options,
  selectedOptions,
  virtualize,
  width,
}: SelectMenuProps) {
  const [searchFilter, setSearchFilter] = useState('')

  const preventModalClosingOnTab = (e: React.KeyboardEvent) => {
    if (e.key === 'Tab') e.stopPropagation()
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div data-testid="select-menu" onKeyDown={preventModalClosingOnTab}>
      <TopLevelControls
        numSelected={numSelected}
        onClear={handleOnClear}
        onSelectAll={handleOnSelectAll}
        disableSelectAll={numSelected === options.length}
      />
      <StyledSearchBoxContainer>
        <StyledSearchBox autoFocus onSearchTextChange={setSearchFilter} />
      </StyledSearchBoxContainer>
      <MultiSelectList
        width={width}
        virtualize={virtualize}
        filter={searchFilter}
        onSelect={handleOnSelect}
        options={options}
        selectedOptions={selectedOptions}
      />
    </div>
  )
}
