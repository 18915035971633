import { AvailableProduct } from '@api/__gen__/gql'
import {
  DialogContent,
  FormControl,
  FormHelperText,
  Tooltip,
} from '@mui/material'
import { useCustomers } from 'app/CustomerContext'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import { MultiAutocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TextField from 'app/components/TextField/TextField'
import useDraftState from 'app/hooks/useDraftState'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useCreateDepartmentBundle } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/mutations/useCreateDepartmentBundle'
import { useUpdateDepartmentBundle } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/mutations/useUpdateDepartmentBundle'
import { useGetAvailableProducts } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetAvailableProducts'
import { DepartmentBundleRow } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartmentBundles'
import { useGetDepartments } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartments'
import { DepartmentBundleTypeEnum } from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManager.types'
import { toRem } from 'app/styles'
import { isEqual } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const FIELD_WIDTH = 304

export const StyledInnerWrapper = styled.div`
  max-height: ${toRem(450)};
`

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
`

const StyledConfigId = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export const StyledAutocomplete = styled(MultiAutocomplete)`
  &&& .MuiFormControl-root {
    margin: 0;
  }
`

export const StyledFormHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.colors.error};
`

export interface DepartmentBundleManagerAddEditModalProps {
  modalState: ModalState
  activeRow?: DepartmentBundleRow
  rows: DepartmentBundleRow[]
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
}

interface FormValues {
  id: string
  name: string
  type: string
  departments: Option[] | null
  products: Option[] | null
}

const getCurrentRowValues = (row: DepartmentBundleRow) => {
  return {
    id: row.id,
    name: row.name,
    type: row.type,
    departments:
      row.departments?.map((department) => ({
        label: department.name,
        value: department._id,
      })) || [],
    products:
      row.products?.map((product) => ({
        label: product.type,
        value: product._id,
      })) || [],
  }
}

function getInitialFormState(activeRow?: DepartmentBundleRow): FormValues {
  if (!activeRow) {
    return {
      id: '',
      name: '',
      type: '',
      departments: [],
      products: [],
    }
  }

  return getCurrentRowValues(activeRow)
}

function validateFormValues(
  formValues: FormValues,
  rows: DepartmentBundleRow[],
  activeRow?: DepartmentBundleRow,
) {
  const { id, name, type, departments, products } = formValues
  if (
    id === '' ||
    name === '' ||
    type === '' ||
    !departments ||
    !products ||
    departments?.length === 0
  ) {
    return false
  }

  const formValid = !rows
    // Filter active row out of rows to check
    .filter((row) => row.id !== activeRow?.id)
    .some((row) => {
      if (row.name.toLowerCase() === formValues.name.toLowerCase()) return true
      const currentRowValues = getCurrentRowValues(row)
      return isEqual({ ...currentRowValues }, { ...formValues })
    })

  return formValid
}

export default function DepartmentBundleManagerAddEditModal({
  modalState,
  rows,
  activeRow,
  onClose,
  onSuccess,
  onDelete,
}: DepartmentBundleManagerAddEditModalProps) {
  const { activeCustomerId } = useCustomers()

  const departments = useGetDepartments(activeCustomerId || null)
  const { products } = useGetAvailableProducts()
  const [nameTooLong, setNameTooLong] = useState(false)

  const productOptions = useMemo(() => {
    if (products === undefined) return []
    return products
      .filter((product: AvailableProduct) => product.type !== 'USER_MANAGEMENT')
      .map((product: AvailableProduct) => ({
        label: product.type,
        value: product._id.toString(),
      }))
  }, [products])

  const departmentOptions: Option[] = useMemo(() => {
    if (departments === null || departments.length === 0) return []
    return departments.map((department) => ({
      label: `${department.name} (${department.customerDepartmentId})`,
      value: department._id.toString(),
    }))
  }, [departments])

  const [createDepartmentBundle] = useCreateDepartmentBundle()
  const [updateDepartmentBundle] = useUpdateDepartmentBundle()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )
  const [saving, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formValid, setFormValid] = useState(
    validateFormValues(formValues, rows, activeRow),
  )

  useEffect(() => {
    const isValid = validateFormValues(formValues, rows, activeRow)
    setFormValid(isValid)
  }, [formValues, rows, activeRow])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      if (activeRow) {
        await updateDepartmentBundle({
          id: activeRow._id,
          name: formValues.name,
          type: formValues.type as DepartmentBundleTypeEnum,
          departmentIds:
            formValues.departments?.map((department) => department.value) || [],
          productIds:
            formValues.products?.map((product) => product.value) || [],
        })
      } else {
        await createDepartmentBundle({
          id: formValues.id,
          name: formValues.name,
          type: formValues.type as DepartmentBundleTypeEnum,
          departmentIds:
            formValues.departments?.map((department) => department.value) || [],
          productIds:
            formValues.products?.map((product) => product.value) || [],
        })
      }
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const loading = saving

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Department Bundle`}</Title>
      </Header>
      <Body>
        <StyledDialogContent>
          {activeRow && (
            <StyledConfigId>
              Config ID: <strong>{activeRow.id}</strong>
            </StyledConfigId>
          )}
          <SelectFieldContainer>
            {modalState === ModalState.add && (
              <TextField
                required
                type="number"
                value={formValues.id}
                label="ID"
                onChange={(e) => {
                  setFormValues((draft) => {
                    draft.id = e.target.value
                  })
                }}
              />
            )}
            <FormControl>
              <TextField
                required
                value={formValues.name}
                label="Name"
                error={nameTooLong}
                onChange={(e) => {
                  if (e.target.value.trim().length >= 40) {
                    setNameTooLong(true)
                    return
                  }
                  setNameTooLong(false)
                  setFormValues((draft) => {
                    draft.name = e.target.value
                  })
                }}
              />
              {nameTooLong && (
                <StyledFormHelperText>
                  Name must be 40 characters or less
                </StyledFormHelperText>
              )}
            </FormControl>
            <SingleSelect
              required
              width={FIELD_WIDTH}
              options={Object.keys(DepartmentBundleTypeEnum).map((type) => ({
                label: type,
                value: type,
              }))}
              value={formValues.type}
              label="Type"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.type = e.target.value
                })
              }
            />
            <StyledAutocomplete
              required
              options={departmentOptions || []}
              label="Departments"
              getOptionDisabled={(option) =>
                rows
                  .filter((row) => row._id !== activeRow?._id)
                  .some((row) =>
                    row.departments?.some((dep) => dep._id === option.value),
                  )
              }
              disallowSelectAll
              value={formValues.departments || []}
              onChange={(_, value) => {
                setFormValues((draft) => {
                  draft.departments = value
                })
              }}
            />
            <StyledAutocomplete
              options={productOptions || []}
              label="Products"
              value={formValues.products || []}
              onChange={(_, value) => {
                setFormValues((draft) => {
                  draft.products = value
                })
              }}
            />
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && (
          <FooterActions justify="start">
            <Tooltip
              title={rows.length === 1 ? 'Cannot delete the only config' : null}
              placement="top">
              <span>
                <Button
                  disabled={rows.length === 1 || loading}
                  startIcon={<Delete />}
                  onClick={onDelete}
                  variant="tertiary">
                  Delete
                </Button>
              </span>
            </Tooltip>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Tooltip
            title={!formValid ? 'Cannot save duplicate config' : null}
            placement="top">
            <span>
              <Button
                disabled={loading || !formValid}
                loading={loading}
                onClick={handleSave}>
                Save
              </Button>
            </span>
          </Tooltip>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
