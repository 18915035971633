import { Box, Theme } from '@mui/material'
import Link from 'app/components/Link/Link'
import { RouteConfig } from 'app/packages/internal/routeTypes'
import { toRem } from 'app/styles'
import React from 'react'

import styled from 'styled-components'

const StyledHeader = styled(Box)<{ theme: Theme }>`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.neutral_02};
`

const StyledLinksContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`
const ConfigLink = styled(Link)`
  margin-top: ${toRem(16)};
`

export default function PageIndexInternal(props: {
  indexName: string
  childLinks: RouteConfig[]
}) {
  const { indexName, childLinks } = props
  return (
    <>
      <StyledHeader>{indexName}</StyledHeader>
      <StyledLinksContainer>
        {childLinks.map((childLink: { path: string; name: string }) => (
          <ConfigLink key={childLink.path} to={{ pathname: childLink.path }}>
            {childLink.name}
          </ConfigLink>
        ))}
      </StyledLinksContainer>
    </>
  )
}
