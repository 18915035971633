import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0002 21.3002C17.1364 21.3002 21.3002 17.1364 21.3002 12.0002C21.3002 6.86395 17.1364 2.7002 12.0002 2.7002C6.86395 2.7002 2.7002 6.86395 2.7002 12.0002C2.7002 17.1364 6.86395 21.3002 12.0002 21.3002Z"
      fill="#C32A2F"
    />
    <path
      d="M12 3.4C16.74 3.4 20.6 7.26 20.6 12C20.6 16.74 16.74 20.6 12 20.6C7.26 20.6 3.4 16.74 3.4 12C3.4 7.26 7.26 3.4 12 3.4ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      fill="#C32A2F"
    />
    <path
      d="M12 12.5601V6.81006"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 17.5C12.6904 17.5 13.25 16.9404 13.25 16.25C13.25 15.5596 12.6904 15 12 15C11.3096 15 10.75 15.5596 10.75 16.25C10.75 16.9404 11.3096 17.5 12 17.5Z"
      fill="white"
    />
  </svg>,
  'ErrorSystemStatus',
)
