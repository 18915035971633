import {
  EndingInventoryStatus,
  InventoryResultsDataFilters,
} from '@api/__gen__/gql'
import {
  InventoryStatus,
  STATUS_NOT_STARTED,
} from './InventoryResults.constants'

export function numberToCostString(value: number) {
  return value.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export function formatNumberAndUnits(
  value: number | null,
  unitType: string | null,
) {
  return `${value} ${unitType}`
}

export const usdValueFormatter = (value: number) => {
  if (value === undefined) {
    return ''
  }
  return numberToCostString(Number(value))
}

export const periodName = (
  accountingPeriod?: null | {
    id: string
    periodNumber: number
    periodYearName: string
  },
) =>
  accountingPeriod
    ? `P${accountingPeriod.periodNumber} ${accountingPeriod.periodYearName}`
    : ''

/**
 * Maps the full list supported by the API into the simplified list of status from {@link InventoryStatus}.
 * This is the logical opposite of {@link convertUIStatusesToGQLStatuses}
 */
export function convertGQLStatusToUIStatus(status: EndingInventoryStatus) {
  if (['PENDING', 'PENDING_APPROVAL'].includes(status)) {
    return InventoryStatus.SUBMITTED
  } else if (status === 'TRANSMITTED') {
    return InventoryStatus.APPROVED
  }
  return status as InventoryStatus
}

/**
 * Maps the simplified list of status from {@link InventoryStatus} into the full list supported by the API.
 * multiple statuses, like PENDING and PENDING_APPROVAL in the API, map to a single status in the Portal. This is because
 * we support statuses for customers who have onboarding to different features, like in the case of PENDING vs PENDING_APPROVAL,
 * customers who have Inventory Approval enabled or disabled.
 *
 * This is the logical opposite of {@link convertGQLStatusToUIStatus}
 */
export function convertUIStatusesToGQLStatuses(statuses: string[]) {
  const statusesGQL: EndingInventoryStatus[] = []
  statuses.forEach((status) => {
    if (status === 'SUBMITTED') {
      statusesGQL.push('PENDING')
      statusesGQL.push('PENDING_APPROVAL')
    } else if (status === 'APPROVED') {
      statusesGQL.push('TRANSMITTED')
    }
    statusesGQL.push(status as EndingInventoryStatus)
  })
  return statusesGQL
}

export function convertUIStatusToText(
  status: InventoryStatus | typeof STATUS_NOT_STARTED,
) {
  switch (status) {
    case STATUS_NOT_STARTED:
      return 'Not Started'
    case InventoryStatus.OPEN:
      return 'Opened'
    case InventoryStatus.SUBMITTED:
      return 'Submitted'
    case InventoryStatus.APPROVED:
      return 'Approved'
    case InventoryStatus.REOPENED:
      return 'Re-opened'
    default:
      return status
  }
}

export const areAnyFiltersSelected = (
  filters: InventoryResultsDataFilters,
): boolean =>
  Object.values(filters).some((filter) =>
    Array.isArray(filter) ? filter.length > 0 : !!filter,
  )
