import { useCustomers } from 'app/CustomerContext'
import useGetProductBundles from 'app/api/queries/useGetProductBundles'
import { useSearchParamsState } from 'app/hooks/useSearchParamsState'
import { getRedirectRoute } from 'app/layouts/Customer/CustomerPageLayout.helpers'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import tuple from 'shared/tuple'

export function useDepartmentBundleId() {
  const [departmentBundleId, setDepartmentBundleId] = useSearchParamsState(
    'deptBundleId',
  )

  const { activeCustomerKey } = useCustomers()

  const navigate = useNavigate()

  const {
    productBundles,
    activeProductBundle,
    loading,
  } = useGetProductBundles()

  // validated departmentBundleId, throw error if invalid
  useEffect(() => {
    // validate the product bundle has department bundles
    if (productBundles && !loading && activeProductBundle?.departmentBundles) {
      // If the departmentBundleId is not valid
      const invalidDepartmentBundle =
        departmentBundleId &&
        productBundles &&
        !activeProductBundle?.departmentBundles?.some(
          (departmentBundle) => departmentBundle.id === departmentBundleId,
        )

      // If no department bundleID in the URL
      const noDepartmentBundle = !departmentBundleId

      // if either are true, force a redirect to the default redirect route for that product bundle
      // This is to prevent the user from having an incorrect set of states that could cause bad data
      if (
        (invalidDepartmentBundle || noDepartmentBundle) &&
        activeProductBundle?.departmentBundles?.length &&
        activeProductBundle?.departmentBundles[0] &&
        activeCustomerKey
      ) {
        const redirectRoute = getRedirectRoute(
          productBundles,
          activeCustomerKey,
        )
        navigate(redirectRoute, { replace: true })
      }
    }
  }, [
    departmentBundleId,
    activeCustomerKey,
    productBundles,
    activeProductBundle,
    loading,
    setDepartmentBundleId,
    navigate,
  ])

  return tuple([departmentBundleId, setDepartmentBundleId])
}
