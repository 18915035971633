import { lazyLoadPage } from 'app/helpers'
import InternalPageIndex from 'app/packages/internal/InternalPageIndex'
import { RouteConfig } from 'app/packages/internal/routeTypes'
import React from 'react'

import Authorized from 'app/Authorized'
import { USER_ACCESS_PERMISSIONS } from 'app/constants'
import Configurations from 'app/packages/internal/engTools/configurations/pages/Configurations'

const OrderManagement = lazyLoadPage(() =>
  import('app/packages/storeSolutions/orderManagement/pages/OrderManagement'),
)

const childRoutes: RouteConfig[] = [
  {
    name: 'Configs Updater Tool',
    path: 'configs-updater',
    route: 'eng-tools/configs-updater',
    element: (
      <Authorized permission={USER_ACCESS_PERMISSIONS.ENGINEERING_TOOLS}>
        <Configurations />
      </Authorized>
    ),
  },
  {
    name: 'Manage Orders',
    path: 'manage-orders',
    route: 'eng-tools/manage-orders',
    element: (
      <Authorized permission={USER_ACCESS_PERMISSIONS.INTERNAL_ORDER_MANAGE}>
        <OrderManagement />
      </Authorized>
    ),
  },
]

const engToolsRoutes = {
  path: 'eng-tools',
  routes: [
    {
      index: true,
      name: 'Eng Tools',
      route: 'eng-tools',
      element: (
        <InternalPageIndex childLinks={childRoutes} indexName="Eng Tools" />
      ),
    } as RouteConfig,
    ...childRoutes,
  ],
}

export default engToolsRoutes
