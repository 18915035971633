import { Alert, AlertColor, AlertTitle } from '@mui/material'
import CautionSystemStatusFilled from 'app/components/Icons/CautionSystemStatusFilled'
import React, { CSSProperties, ReactElement } from 'react'

export interface AlertMessageBlockProps {
  action?: ReactElement
  className?: string
  includeDescription?: boolean
  message: string | ReactElement
  onClose?: () => void
  severityType: AlertColor
  stylesOverride?: CSSProperties
  title?: ReactElement | string
}

export default function AlertMessageBlock({
  action,
  className,
  includeDescription,
  message,
  onClose,
  severityType,
  stylesOverride,
  title,
}: AlertMessageBlockProps) {
  return (
    <Alert
      action={action}
      className={className}
      onClose={onClose}
      iconMapping={{ warning: <CautionSystemStatusFilled /> }}
      severity={severityType}
      style={stylesOverride}>
      {includeDescription && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}
