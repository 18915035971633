import Tag, { TagProps } from 'app/components/Tag/Tag'
import {
  InventoryStatus,
  STATUS_NOT_STARTED,
} from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.constants'
import { convertUIStatusToText } from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.helpers'
import React from 'react'

export default function StatusTag({
  className,
  status,
}: {
  className?: string
  status: InventoryStatus | typeof STATUS_NOT_STARTED
}) {
  let color: TagProps['color']
  const text: string = convertUIStatusToText(status).toUpperCase()
  switch (status) {
    case STATUS_NOT_STARTED:
    case InventoryStatus.OPEN:
      color = 'neutral_05'
      break
    case InventoryStatus.SUBMITTED:
      color = 'highlight_06'
      break
    case InventoryStatus.APPROVED:
      color = 'highlight_08'
      break
    case InventoryStatus.REOPENED:
      color = 'highlight_02'
      break
    default:
      color = 'neutral_05'
  }

  return <Tag className={className} text={text} color={color} />
}
