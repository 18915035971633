import { OrderingConfigurationEditState } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import {
  SettingsGroup,
  StyledCheckbox,
  StyledFormControlLabel,
} from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/SettingsGroup'
import { UnitsSelector } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/UnitsSelector'
import React from 'react'

interface OrderingSettingProps {
  orderingConfiguration: OrderingConfigurationEditState
  onChange: (updatedConfiguration: OrderingConfigurationEditState) => void
  viewOnly: boolean
}

export default function OrderingSettings(props: OrderingSettingProps) {
  const { orderingConfiguration, onChange, viewOnly } = props
  return (
    <>
      <b>Ordering Settings</b>
      <div>These settings apply to all items in this section only.</div>

      <SettingsGroup
        title="Pre-Filled Inventory"
        tooltipText="This input determines if Afresh generates inventory estimates & recommendations for items in the section. This setting is often used for sections that have many shelf-stable items."
        showBorder={false}>
        <StyledFormControlLabel
          data-testid="auto-inventory"
          control={
            <StyledCheckbox
              checked={orderingConfiguration.autoInventoryEnabled}
              onChange={(e) =>
                onChange({
                  ...orderingConfiguration,
                  autoInventoryEnabled: e.target.checked,
                })
              }
              disabled={viewOnly}
            />
          }
          label="Enable pre-filled inventory"
        />
      </SettingsGroup>

      <SettingsGroup
        title="Back Inventory"
        tooltipText="This input determines if a user is required to take back inventory before ordering.">
        <StyledFormControlLabel
          data-testid="back-inventory-required"
          control={
            <StyledCheckbox
              checked={orderingConfiguration.backInventoryRequired}
              onChange={(e) =>
                onChange({
                  ...orderingConfiguration,
                  backInventoryRequired: e.target.checked,
                })
              }
              disabled={viewOnly}
            />
          }
          label="Require inventory check before ordering"
        />
      </SettingsGroup>

      <SettingsGroup
        title="Floor Inventory"
        tooltipText="These inputs determine if a user is required to take floor inventory before ordering and what unit type the items should be counted as.">
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={orderingConfiguration.floorInventoryRequired}
              data-testid="floor-inventory-required"
              onChange={(e) =>
                onChange({
                  ...orderingConfiguration,
                  floorInventoryRequired: e.target.checked,
                })
              }
              disabled={viewOnly}
            />
          }
          label="Require inventory check before ordering"
        />
        <UnitsSelector
          value={orderingConfiguration.floorInventoryUnits}
          onChange={(units) =>
            onChange({
              ...orderingConfiguration,
              floorInventoryUnits: units,
            })
          }
          disabled={viewOnly}
        />
      </SettingsGroup>
    </>
  )
}
