import { InventoryConfigurationEditState } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import {
  SettingsGroup,
  StyledCheckbox,
  StyledFormControlLabel,
} from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/SettingsGroup'
import { UnitsSelector } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/UnitsSelector'
import React from 'react'

interface InventorySettingsProps {
  inventoryConfiguration: InventoryConfigurationEditState
  onChange: (newInventoryConfiguration: InventoryConfigurationEditState) => void
  viewOnly: boolean
}

export default function InventorySettings(props: InventorySettingsProps) {
  const { inventoryConfiguration, onChange, viewOnly } = props
  return (
    <>
      <b>Inventory Settings</b>
      <div>These settings apply to all items in this section only.</div>

      <SettingsGroup
        title="Back Inventory"
        tooltipText="These inputs determine what unit type the items should be counted as when a user takes period ending inventory.">
        <UnitsSelector
          value={inventoryConfiguration.backInventoryUnits}
          onChange={(units) =>
            onChange({ ...inventoryConfiguration, backInventoryUnits: units })
          }
          disabled={viewOnly}
        />
      </SettingsGroup>

      <SettingsGroup
        title="Floor Inventory"
        tooltipText="These inputs determine if a user is required to take floor inventory and what unit type the items should be counted as during period ending inventory.">
        <StyledFormControlLabel
          data-testid="floor-inventory-required"
          control={
            <StyledCheckbox
              checked={inventoryConfiguration.floorInventoryRequired}
              onChange={(e) =>
                onChange({
                  ...inventoryConfiguration,
                  floorInventoryRequired: e.target.checked,
                })
              }
              disabled={viewOnly}
            />
          }
          label="Require inventory check during ending inventory"
        />
        <UnitsSelector
          value={inventoryConfiguration.floorInventoryUnits}
          onChange={(units) =>
            onChange({ ...inventoryConfiguration, floorInventoryUnits: units })
          }
          disabled={viewOnly}
        />
      </SettingsGroup>
    </>
  )
}
