import {
  ConfigurationDimension,
  ConfigurationName,
  ConfigurationTypeEnum,
  ConfigurationsQuery,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'

export const GET_CONFIGURATIONS = gql`
  query Configurations($customerId: ID!) {
    customerConfig(customerId: $customerId) {
      configurations {
        _id
        value
        configurationType {
          name
          valueType
          allowedDimensions
        }
        store {
          _id
          id
          name
        }
        workflow {
          _id
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`

export interface ConfigurationsRow {
  __typename: 'Configuration'
  _id: string
  value: string
  configurationType: {
    __typename: 'ConfigurationType'
    name: ConfigurationName
    valueType: ConfigurationTypeEnum
    allowedDimensions: ConfigurationDimension[]
  }
  store?: {
    __typename: 'Store'
    _id: string
    id: string
    name: string
  } | null
  workflow?: {
    __typename: 'Workflow'
    _id: string
    id: string
    name: string
  } | null
  createdAt?: string | null
  updatedAt?: string | null
}

export function useGetConfigurations({
  configNameFilter,
}: {
  configNameFilter: string
}) {
  const { activeCustomerId } = useCustomers()
  const { data, loading, refetch } = useQuery<ConfigurationsQuery>(
    GET_CONFIGURATIONS,
    {
      variables: {
        customerId: activeCustomerId,
      },
      fetchPolicy: 'no-cache',
    },
  )

  if (data && data.customerConfig.configurations) {
    return {
      rows: configNameFilter
        ? data.customerConfig.configurations.filter(
            (config) => config.configurationType.name === configNameFilter,
          )
        : data.customerConfig.configurations,
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    loading,
    refetch,
  }
}
