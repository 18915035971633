import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import Input from 'app/components/Input/Input'
import { noop } from 'app/helpers'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

export interface SingleSelectProps {
  className?: string
  disabled?: boolean
  error?: boolean
  fullWidth?: boolean
  handleChange(e: SelectChangeEvent<string>): void
  label?: string
  labelId?: string
  name?: string
  onBlur?: () => void
  options: Option[]
  renderValue?: (value: string) => React.ReactElement
  required?: boolean
  title?: string
  value: string
  width?: number
}

export const getBooleanSelectOptions = ({
  trueLabel = 'True',
  falseLabel = 'False',
}: {
  trueLabel?: string
  falseLabel?: string
}): Option[] => [
  { label: '--', value: '' },
  { label: trueLabel, value: 'true' },
  { label: falseLabel, value: 'false' },
]

export const getBooleanValueFromOptionValue = (
  optionValue: string,
): boolean | undefined => {
  let booleanValue: boolean | undefined
  if (optionValue === 'true') {
    booleanValue = true
  } else if (optionValue === 'false') {
    booleanValue = false
  }

  return booleanValue
}

const StyledFormControl = styled(FormControl)<{
  $width: number
  fullWidth: boolean
}>`
  ${({ $width, fullWidth }) =>
    !fullWidth &&
    `
      max-width: ${toRem(320)};
      min-width: ${toRem(200)};
      width: ${toRem($width)};
    `}
`

const StyledMenuItem = styled(MenuItem)`
  min-height: ${({ theme }) => theme.typography.pxToRem(24)};
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`

const StyledInputLabel = styled(InputLabel)`
  line-height: ${({ theme }) => theme.typography.pxToRem(24)};
`

export default function SelectField({
  disabled = false,
  error = false,
  fullWidth = false,
  handleChange,
  label,
  labelId,
  name,
  onBlur = noop,
  options,
  renderValue,
  required,
  title,
  value,
  width = 200,
  ...rest
}: SingleSelectProps) {
  const id = labelId || label?.toLowerCase().split(' ').join('-')
  return (
    <StyledFormControl
      {...rest}
      $width={width}
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      required={required}
      size="small"
      title={title || value || label}>
      <StyledInputLabel id={`select-field-${id}`}>{label}</StyledInputLabel>
      <Select
        input={<Input disabled={disabled} label={label} />}
        labelId={`select-field-${id}`}
        aria-label={`${label} select field. Click to access ${label} options.`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 'calc(100vh - 288px)',
              maxWidth: width,
            },
          },
        }}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value}
        renderValue={renderValue}>
        {options.map((option) => {
          return (
            <StyledMenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}>
              {option.label}
            </StyledMenuItem>
          )
        })}
      </Select>
    </StyledFormControl>
  )
}
