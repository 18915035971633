import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.1904 5.62012C12.6704 5.62012 14.6904 7.64012 14.6904 10.1201C14.6904 12.6001 12.6704 14.6201 10.1904 14.6201C7.71043 14.6201 5.69043 12.6001 5.69043 10.1201C5.69043 7.64012 7.71043 5.62012 10.1904 5.62012ZM10.1904 3.62012C6.60043 3.62012 3.69043 6.53012 3.69043 10.1201C3.69043 13.7101 6.60043 16.6201 10.1904 16.6201C13.7804 16.6201 16.6904 13.7101 16.6904 10.1201C16.6904 6.53012 13.7804 3.62012 10.1904 3.62012Z"
      fill="currentColor"
    />
    <path
      d="M14.1904 14.25L20.3104 20.38"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>,
  'Search',
)
