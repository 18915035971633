import { UpdateConfigurationInput2 } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { CONFIGURATIONS_ERRORS } from 'app/packages/internal/customerConfigs/configurations/api/errors'
import { CONFIGURATION_ROW } from 'app/packages/internal/customerConfigs/configurations/api/fragments/ConfigurationRow'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_CONFIGURATION = gql`
  ${CONFIGURATION_ROW}
  mutation updateConfiguration(
    $updateConfigurationInput: UpdateConfigurationInput2!
  ) {
    updateConfiguration(input: $updateConfigurationInput) {
      configuration {
        ...ConfigurationRow
      }
    }
  }
`

export function useUpdateConfiguration() {
  const [updateConfiguration, result] = useMutation(UPDATE_CONFIGURATION, {
    refetchQueries: ['Configurations'],
  })

  return tuple([
    useCallback(
      async (input: UpdateConfigurationInput2) => {
        const { errors } = await updateConfiguration({
          variables: {
            updateConfigurationInput: input,
          },
        })

        if (errors?.length) {
          throw new Error(
            CONFIGURATIONS_ERRORS[getFirstErrorCode(errors)] ||
              `Update configuration failed: ${errors[0].message}`,
          )
        }
      },
      [updateConfiguration],
    ),
    result,
  ])
}
