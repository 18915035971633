import {
  GetStoreClosuresQuery,
  GetStoreClosuresQueryVariables,
  StoreClosureType,
  StoreFieldsFragment,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { ITEM_HEIGHT } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import { StoreClosure } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { isNil } from 'lodash-es'

const ROW_DIVIDER = {
  id: 'divider',
  closureDate: '',
  closureType: 'ANOMALOUS' as StoreClosureType,
  isEditable: false,
  itemHeight: ITEM_HEIGHT,
  stores: null,
}

export const GET_STORE_CLOSURES = gql`
  ${STORE_FIELDS}
  query getStoreClosures($scheduleId: ID!) {
    node(id: $scheduleId) {
      _id
      ... on OrderDeliverySchedule {
        _id
        closures {
          edges {
            node {
              _id
              date
              closureType
              isChainwide
              isEditable
              stores {
                ...StoreFields
              }
            }
          }
        }
      }
    }
  }
`

interface CurrentAndPastStoreClosures {
  current: StoreClosure[]
  past: StoreClosure[]
}

export function useGetStoreClosures() {
  const { scheduleId } = useGetOrderDeliverySchedule()

  const { data, loading } = useQuery<
    GetStoreClosuresQuery,
    GetStoreClosuresQueryVariables
  >(GET_STORE_CLOSURES, {
    skip: isNil(scheduleId),
    variables: { scheduleId: scheduleId! },
  })

  let storeClosures: StoreClosure[] = []

  if (data && data.node && data.node.__typename === 'OrderDeliverySchedule') {
    const { current, past } = data.node.closures.edges.reduce<
      CurrentAndPastStoreClosures
    >(
      (closures, { node }) => {
        const closure = {
          id: node!._id,
          closureDate: node!.date,
          closureType: node!.closureType,
          isEditable: node!.isEditable,
          itemHeight: ITEM_HEIGHT,
          stores: node!.stores
            ? node!.stores
                .filter((s): s is StoreFieldsFragment => !!s)
                .map((s) => toStore(s))
            : null,
        }

        if (closure.isEditable) {
          closures.current.push(closure)
        } else {
          closures.past.push(closure)
        }

        return closures
      },
      { current: [], past: [] },
    )

    storeClosures = [
      ...current,
      ...(past.length > 0 ? [ROW_DIVIDER, ...past] : past),
    ]
  }

  return {
    storeClosures,
    isLoading: loading,
  }
}
