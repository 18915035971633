import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 5.5V19.5H5V5.5H19ZM19 3.5H5C3.9 3.5 3 4.4 3 5.5V19.5C3 20.6 3.9 21.5 5 21.5H19C20.1 21.5 21 20.6 21 19.5V5.5C21 4.4 20.1 3.5 19 3.5Z"
      fill="currentColor"
    />
    <path
      d="M18.3701 5.74414H5.62012V6.81814H18.3701V5.74414Z"
      fill="currentColor"
    />
    <path
      d="M19.3801 4.31201H4.62012V8.25H19.3801V4.31201Z"
      fill="currentColor"
    />
    <path
      d="M6.75 6.25V2.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M17.25 6.25V2.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>,
  'Calendar',
)
