import { SelectChangeEvent } from '@mui/material'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import { getCategoryOptions } from 'app/packages/storeSolutions/guideOrganization/pages/guideOrganization.helpers'
import { concat } from 'lodash-es'
import React from 'react'

interface Props {
  className?: string
  includeDefault?: boolean
  onSelect: (id: string) => void
  title?: string
  value: string
}

export default function SectionsSelect({
  className = '',
  includeDefault = true,
  onSelect,
  title = 'Section',
  value,
}: Props) {
  const { categories } = useGetCategories()

  const handleOnSelect = ({ target }: SelectChangeEvent<string>) => {
    onSelect(target.value)
  }

  const categoryOptions = getCategoryOptions(categories)

  const options = includeDefault
    ? concat([{ label: '--', value: '' }], categoryOptions)
    : categoryOptions

  return (
    <SingleSelect
      className={className}
      handleChange={handleOnSelect}
      options={options}
      label={title}
      value={value}
    />
  )
}
