import { StyledTableContainer } from 'app/components/Tables/StyledTableComponents'
import { BaseRow, TableProps } from 'app/components/Tables/Tables.types'
import { isNilOrEmpty } from 'app/helpers'
import React, { useMemo } from 'react'
import { useBlockLayout, useTable } from 'react-table'
import BaseTable from './BaseTable/BaseTable'

export default function Table<T extends BaseRow>(props: TableProps<T>) {
  const { className, columns, data, height, isLoading, style } = props

  const memoizedColumns = useMemo(() => columns, [columns])

  const tableInstance = useTable(
    {
      columns: memoizedColumns,
      data,
    },
    useBlockLayout,
  )

  const isEmptyState = !isLoading && isNilOrEmpty(data)

  return (
    <StyledTableContainer
      style={style}
      className={className}
      $isEmptyState={isEmptyState}
      height={height}>
      <BaseTable
        {...props}
        tableInstance={tableInstance}
        isEmptyState={isEmptyState}
      />
    </StyledTableContainer>
  )
}
