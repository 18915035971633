import RadioButtons from 'app/components/RadioButtons/RadioButtons'
import { InventoryInputUnitEnum } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import React from 'react'

export interface UnitsSelectorProps {
  value?: InventoryInputUnitEnum
  disabled?: boolean
  onChange: (selected: InventoryInputUnitEnum) => void
}

export function UnitsSelector(props: UnitsSelectorProps) {
  const { value, onChange, disabled } = props
  return (
    <RadioButtons
      buttons={[
        { label: 'Cases (cs)', value: InventoryInputUnitEnum.CASES },
        {
          label: 'Eaches (ea)',
          value: InventoryInputUnitEnum.EACHES,
        },
      ]}
      disabled={disabled}
      value={value}
      label="Units"
      onChange={(e) => onChange(e.target.value as InventoryInputUnitEnum)}
      required
      showLabel
    />
  )
}
