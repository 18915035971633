export const getStickyRows = <T>(groupedRows: { [section: string]: T[] }) => {
  const indices: number[] = []
  let prevIndex = 0
  let prevLengthAtIndex = 0

  const flattenedRows = Object.keys(groupedRows).flatMap((section, index) => {
    if (index === 0) {
      indices.push(index)
      prevLengthAtIndex = groupedRows[section].length
    } else {
      const nextIndex = prevLengthAtIndex + prevIndex + 1
      indices.push(nextIndex)
      prevIndex = nextIndex
      prevLengthAtIndex = groupedRows[section].length
    }

    return [{ sectionName: section }, ...groupedRows[section]]
  })

  return { rows: flattenedRows, stickyIndices: indices }
}
