import { gql } from '@apollo/client'

export const GUIDE_STRUCTURE_FIELDS = gql`
  fragment GuideStructureFields on GuideStructure {
    _id
    createdAt
    updatedAt
    updatedBy {
      name
    }
    status
    hasBeenEdited
    primaryCategoryKeyType
    showInventorySettings
  }
`
