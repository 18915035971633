import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export function Spinner({ className }: { className?: string }) {
  return (
    <Centered className={className}>
      <CircularProgress />
    </Centered>
  )
}
