import { SvgIconComponent } from '@mui/icons-material'
import { Box, Theme, Typography } from '@mui/material'
import { InternalLayoutContext } from 'app/layouts/Internal/AuthorizedInternalLayout'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledNavLink = styled(NavLink)<{
  theme: Theme
}>`
  align-items: center;
  color: inherit;
  display: flex;
  height: 48px;
  padding: 0 20px;
  text-decoration: none;
  &:active,
  &:hover,
  &:visited {
    color: inherit;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.neutral_03};
    border-left: '4px solid';
    padding-left: '16px';
  }
`

const StyledNavItem = styled(Box)`
  align-items: center;
  column-gap: 10px;
  display: flex;
`

export interface SideNavLinkProps {
  end?: boolean
  icon: SvgIconComponent
  label: string
  route: string
}

export default function SideNavLink({
  end,
  icon,
  label,
  route,
}: SideNavLinkProps) {
  const Icon = icon
  const { sideNavOpen } = useContext(InternalLayoutContext)

  return (
    <StyledNavLink end={end} to={route}>
      <StyledNavItem>
        <Icon sx={{ width: '18px' }} />
        {sideNavOpen && <Typography>{label}</Typography>}
      </StyledNavItem>
    </StyledNavLink>
  )
}
