import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import React from 'react'

export interface Props {
  onClose: () => void
  onDone: () => void
  storeName: string
}

export default function DiscardChangesModal({
  onClose,
  onDone,
  storeName,
}: Props) {
  return (
    <Dialog onClose={onClose} isOpen>
      <Header onClose={onClose}>
        <Title>Discard Changes</Title>
      </Header>
      <Body>
        Discard changes to this order and delivery for{' '}
        <strong>{storeName}</strong>?
      </Body>
      <Footer>
        <FooterActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onDone}>Confirm</Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
