import { datadogRum } from '@datadog/browser-rum'
import { HEADER_KEYS } from 'app/api/common/constants'
import { readLocalState } from 'app/state/authentication'
import { GraphQLError } from 'graphql'
import { isEqual } from 'lodash-es'

export function hasErrorCode(errors: readonly GraphQLError[], code: string) {
  return errors.some((error) => error.extensions?.code === code)
}

// Datadog utils
export const addCustomAnalyticAction = (actionName: string, action: object) => {
  datadogRum.addAction(actionName, action)
}

export const isDirty = <T>(initial: T, current: T) => {
  return !isEqual(initial, current)
}

export const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.width = '100px'
  document.body.appendChild(outer)

  const widthNoScroll = outer.offsetWidth
  outer.style.overflow = 'scroll'

  const inner = document.createElement('div')
  inner.style.width = '100%'
  outer.appendChild(inner)

  const widthWithScroll = inner.offsetWidth
  if (outer.parentNode) {
    outer.parentNode.removeChild(outer)
  }

  return widthNoScroll - widthWithScroll
}

export const toTitleCase = (str: string) =>
  str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase())

/**
 * Omits decimal places if they're not necessary
 * This is useful when displaying the output of floating-point math,
 * which occasionally has many of decimals: https://stackoverflow.com/q/1458633/3900967
 */
export const roundDecimalsIfNecessary = (
  value: number,
  decimalPlaces = 2,
): number => {
  return +parseFloat(value.toString()).toFixed(decimalPlaces)
}

export function getDefaultHeaders(): Record<string, string> {
  const accessToken = readLocalState().tokens?.access
  const headers = {
    authorization: `Bearer ${accessToken}`,
    [HEADER_KEYS.AFRESH_CLIENT]: 'portal',
    [HEADER_KEYS.AFRESH_CLIENT_URL]: window.location.href,
  }

  return headers
}
