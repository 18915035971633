import {
  AccordionDetails,
  AccordionSummary,
  Accordion as MUIAccordion,
} from '@mui/material'
import ChevronDown from 'app/components/Icons/ChevronDown'
import React from 'react'
import styled from 'styled-components'

export interface Props {
  header: React.ReactElement | string
  children: React.ReactElement
  className?: string
  disabled?: boolean
  expanded?: boolean
  defaultExpanded?: boolean
  onChangeExpanded?(isExpanded: boolean): void
}

const StyledAccordion = styled(MUIAccordion)`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-radius: 4px;

  &.Mui-expanded {
    margin: 0;
  }
`

const StyledSummaryHeader = styled.span`
  color: ${({ theme }) => theme.colors.neutral_02};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 700;
  margin-top: 2px;
  display: flex;
  align-items: center;
`

const StyledSummary = styled(AccordionSummary)`
  column-gap: 16px;
  flex-direction: row-reverse;
  height: 50px;

  &.Mui-expanded {
    min-height: 50px;
  }

  [class*='MuiAccordionSummary-content'] {
    align-items: center;
    column-gap: 8px;
    margin: 0;
  }
`

const StyledDetails = styled(AccordionDetails)`
  padding: 0;
`

export function Accordion({
  header,
  children,
  className,
  disabled,
  expanded,
  defaultExpanded,
  onChangeExpanded,
}: Props) {
  return (
    <StyledAccordion
      className={className}
      expanded={expanded}
      defaultExpanded={defaultExpanded}
      onChange={(_, isExpanded) =>
        onChangeExpanded && onChangeExpanded(isExpanded)
      }
      disabled={disabled}
      elevation={0}>
      <StyledSummary expandIcon={<ChevronDown />}>
        <StyledSummaryHeader>{header}</StyledSummaryHeader>
      </StyledSummary>
      <StyledDetails>{children}</StyledDetails>
    </StyledAccordion>
  )
}

export default Accordion
