import { Tooltip as MUITooltip, TooltipProps } from '@mui/material'
import React from 'react'

export interface TableCellTooltipProps {
  children: React.ReactElement<any, any>
  title: TooltipProps['title']
  id?: string
  xOffset?: number
  yOffset?: number
}

const DEFAULT_Y_OFFSET = -50
const DEFAULT_X_OFFSET = 10

export default function TableCellTooltip({
  children,
  title,
  id = 'tooltip',
  xOffset = DEFAULT_X_OFFSET,
  yOffset = DEFAULT_Y_OFFSET,
  ...rest
}: TableCellTooltipProps) {
  return (
    <MUITooltip
      enterDelay={400}
      enterNextDelay={400}
      title={title}
      id={id}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [xOffset, yOffset],
            },
          },
        ],
      }}
      // allows enterDelay, enterNextDelay, title and id to be overridden
      {...rest}>
      {children}
    </MUITooltip>
  )
}
