import { gql } from '@apollo/client'

export const ILLOGICAL_DELIVERY_SEQUENCE_FIELDS = gql`
  fragment IllogicalDeliverySequenceFields on IllogicalDeliverySequence {
    firstOrderDeliveryEvent {
      _id
      order {
        date
      }
      delivery {
        date
      }
    }
    secondOrderDeliveryEvent {
      _id
      order {
        date
      }
      delivery {
        date
      }
    }
  }
`
