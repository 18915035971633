import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.0604 14.9702V16.9302H6.92043C6.44043 16.9302 5.97043 16.7402 5.63043 16.4002C5.29043 16.0602 5.10043 15.5902 5.10043 15.1102V5.9802H2.94043L3.01043 3.9502H5.24043C5.72043 3.9502 6.19043 4.1402 6.53043 4.4802C6.87043 4.8202 7.06043 5.2902 7.06043 5.7702V14.9702H21.0604Z"
      fill="currentColor"
    />
    <path
      d="M8.05957 2.91992V14.1599H20.0696V2.91992H8.05957ZM15.9996 5.98992H12.1296V4.91992H15.9996V5.98992Z"
      fill="currentColor"
    />
    <path
      d="M8.82957 21.08C9.80711 21.08 10.5996 20.2876 10.5996 19.31C10.5996 18.3325 9.80711 17.54 8.82957 17.54C7.85203 17.54 7.05957 18.3325 7.05957 19.31C7.05957 20.2876 7.85203 21.08 8.82957 21.08Z"
      fill="currentColor"
    />
    <path
      d="M17.4302 21.08C18.4077 21.08 19.2002 20.2876 19.2002 19.31C19.2002 18.3325 18.4077 17.54 17.4302 17.54C16.4526 17.54 15.6602 18.3325 15.6602 19.31C15.6602 20.2876 16.4526 21.08 17.4302 21.08Z"
      fill="currentColor"
    />
  </svg>,
  'InventoryResults',
)
