import {
  DayOfWeek,
  UpdateOrderDeliveryRecurringSchedulesMutation,
  UpdateOrderDeliveryRecurringSchedulesMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import { RECURRING_ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/RecurringOrderDeliveryEvent'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_RECURRING_SCHEDULES = gql`
  ${RECURRING_ORDER_DELIVERY_EVENT_FIELDS}
  mutation updateOrderDeliveryRecurringSchedules(
    $scheduleId: ID!
    $recurringSchedules: [ExistingOrderDeliveryRecurringScheduleArgs!]!
  ) {
    updateOrderDeliveryRecurringSchedules(
      input: {
        scheduleId: $scheduleId
        recurringSchedules: $recurringSchedules
      }
    ) {
      orderDeliveryRecurringSchedules {
        ...RecurringOrderDeliveryEventFields
      }
    }
  }
`

export default function useUpdateRecurringSchedules() {
  const { scheduleId } = useGetOrderDeliverySchedule()

  const [mutation, result] = useMutation<
    UpdateOrderDeliveryRecurringSchedulesMutation,
    UpdateOrderDeliveryRecurringSchedulesMutationVariables
  >(UPDATE_RECURRING_SCHEDULES, {
    refetchQueries: ['recurringOrders', 'getOrderDeliveryScheduleIssues'],
  })

  return tuple([
    useCallback(
      async (
        recurringScheduleIds: string[],
        newOrderDay: DayOfWeek,
        newDeliveryDay: DayOfWeek,
      ) => {
        const { errors } = await mutation({
          variables: {
            scheduleId: scheduleId!,
            recurringSchedules: recurringScheduleIds.map((id) => {
              return {
                existingRecurringScheduleId: id,
                newOrderDay,
                newDeliveryDay,
              }
            }),
          },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [mutation, scheduleId],
    ),
    result,
  ])
}
