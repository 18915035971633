import { Theme } from '@mui/material'
import React from 'react'
import {
  Link as ReactRouterLink,
  Path,
  RelativeRoutingType,
} from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(ReactRouterLink)<{ theme: Theme }>`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 19px;
`

const StyledExternalLink = styled.a<{ theme: Theme }>`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 19px;
`

export interface LinkProps {
  to: Partial<Path>
  children: React.ReactNode | string
  external?: boolean
  launch?: boolean
  relative?: RelativeRoutingType
  preventScrollReset?: boolean
}

export default function Link({
  to,
  launch,
  children,
  external,
  ...rest
}: LinkProps) {
  if (external) {
    return (
      <StyledExternalLink
        href={to.pathname}
        {...(launch ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
        {...rest}>
        {children}
      </StyledExternalLink>
    )
  }

  return (
    <StyledLink
      to={to}
      {...(launch ? { target: '_blank', rel: 'noreferrer noopener' } : {})}
      {...rest}>
      {children}
    </StyledLink>
  )
}
