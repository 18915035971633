import SearchBox from 'app/components/SearchBox/SearchBox'
import { ROW_HEIGHT_NUMBER } from 'app/components/Tables/Tables.constants'
import React from 'react'
import styled from 'styled-components'

export const TABLE_HEADER_HEIGHT = ROW_HEIGHT_NUMBER

const StyledHeaderContainer = styled.div`
  align-items: center;
  column-gap: 12px;
  padding: 12px 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
`

const StyledTotalCount = styled.span`
  line-height: 1.3;
  margin-left: auto;
`

interface TableHeaderProps {
  itemCount?: number
  children?: React.ReactNode
  onSearch?: (searchTerm: string) => void
  className?: string
}

export default function TableHeader({
  children,
  itemCount,
  onSearch,
  className,
}: TableHeaderProps) {
  return (
    <StyledHeaderContainer className={className}>
      {onSearch && <SearchBox onSearchTextChange={onSearch} />}
      {children}
      {!!itemCount && itemCount > 0 && (
        <StyledTotalCount>{`Item count: ${itemCount}`}</StyledTotalCount>
      )}
    </StyledHeaderContainer>
  )
}
