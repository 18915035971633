import { Box } from '@mui/system'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import React from 'react'
import styled from 'styled-components'

import { Avatar, Divider, List, Tooltip } from '@mui/material'
import { ProductBundle } from 'app/api/queries/useGetProductBundles'
import OpenInNew from 'app/components/Icons/OpenInNew'
import DistributionCenter from 'app/components/Icons/ProductBundles/DistributionCenter'
import InventoryResults from 'app/components/Icons/ProductBundles/InventoryResults'
import OpsInsights from 'app/components/Icons/ProductBundles/OpsInsights'
import StoreOperations from 'app/components/Icons/ProductBundles/StoreOperations'
import QuestionMark from 'app/components/Icons/QuestionMark'
import Signout from 'app/components/Icons/Signout'
import Link from 'app/components/Link/Link'
import { ROLES } from 'app/constants'
import { PRODUCT_BUNDLE_TYPE } from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import { getDefaultRouteForPrimaryNav } from 'app/layouts/Customer/CustomerPageLayout.helpers'
import { PRIMARY_SIDE_NAV_WIDTH } from 'app/layouts/Layout.constants'
import { USER_SCOPE_MAP } from 'app/packages/core/pages/users/users.constants'
import { Path } from 'react-router-dom'

const StyledUserScope = styled('span')`
  font-size: ${({ theme }) => theme.typography.pxToRem(10)};
  background-color: ${({ theme }) => theme.colors.neutral_08}
  color: ${({ theme }) => theme.colors.caution};
  text-transform: uppercase;
`

const SideNav = styled(Box)`
  height: calc(100vh - ${({ theme }) => theme.typography.pxToRem(56)});
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: ${({ theme }) => theme.typography.pxToRem(PRIMARY_SIDE_NAV_WIDTH)};
  min-width: ${({ theme }) => theme.typography.pxToRem(PRIMARY_SIDE_NAV_WIDTH)};
  background-color: ${({ theme }) => theme.colors.highlight_07};
  padding-top: ${({ theme }) => theme.typography.pxToRem(30)};
`

const ProductBundlesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`

export const BottomActionsList = styled(List)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`

const StyledBottomActionsDivider = styled(Divider)`
  width: ${({ theme }) => theme.typography.pxToRem(60)};
  border-color: ${({ theme }) => theme.colors.neutral_04};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(24)};
  margin-top: ${({ theme }) => theme.typography.pxToRem(8)};
`

export const StyledAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.highlight_03};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(12)};
  height: ${({ theme }) => theme.typography.pxToRem(32)};
  width: ${({ theme }) => theme.typography.pxToRem(32)};
  font-size: ${({ theme }) => theme.typography.pxToRem(12)};
  font-weight: 700;
`

export const StyledPrimaryNavListItem = styled(Link)<{
  $active?: boolean
  $inverseColor?: boolean
}>`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.typography.pxToRem(4)};
  max-height: ${({ theme }) => theme.typography.pxToRem(66)};
  min-height: ${({ theme }) => theme.typography.pxToRem(66)};
  width: ${({ theme }) => theme.typography.pxToRem(60)};
  border-radius: 4px;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(12)};
  color: ${({ theme, $inverseColor }) =>
    $inverseColor ? theme.colors.neutral_06 : theme.colors.neutral_02};

  :hover {
    background-color: ${({ theme, $inverseColor }) =>
      !$inverseColor && theme.colors.neutral_06};
  }

  ${({ $active, theme }) =>
    $active &&
    `
      background-color: ${theme.colors.highlight_03};
      font-weight: 700;
  `}
`

const StyledPrimaryNavButton = styled(StyledPrimaryNavListItem)`
  cursor: pointer;

  text-decoration: none;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 19px;
`

const StyledPrimaryNavName = styled.span`
  font-size: ${({ theme }) => theme.typography.pxToRem(10)};
  text-align: center;
  white-space: pre-wrap;
  line-height: 14px;
`

export function getUserInitials(name: string | undefined) {
  if (!name) return 'HI'
  return name
    .split(' ')
    .slice(0, 2)
    .map((splitName: string) => splitName[0])
    .filter((initial: string) => initial.match(/[a-z]/i))
    .join('')
}

export function PrimarySideNavListItem({
  icon,
  name,
  active,
  to,
  launch,
  inverseColor,
}: {
  icon: React.ReactElement
  name: string
  to: Partial<Path>
  active?: boolean
  launch?: boolean
  inverseColor?: boolean
}) {
  return (
    <StyledPrimaryNavListItem
      $inverseColor={inverseColor}
      $active={active}
      to={to}
      launch={launch}>
      {icon}
      <StyledPrimaryNavName>{name}</StyledPrimaryNavName>
    </StyledPrimaryNavListItem>
  )
}

function PrimarySideNavButton({
  icon,
  name,
  onClick,
}: {
  icon: React.ReactElement
  name: string
  onClick: () => void
}) {
  return (
    <StyledPrimaryNavButton as="div" onClick={onClick}>
      {icon}
      <StyledPrimaryNavName>{name}</StyledPrimaryNavName>
    </StyledPrimaryNavButton>
  )
}

const getIconForProductBundle = (productBundle: PRODUCT_BUNDLE_TYPE) => {
  switch (productBundle) {
    case PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS:
      return <StoreOperations />
    case PRODUCT_BUNDLE_TYPE.INVENTORY_RESULTS:
      return <InventoryResults />
    case PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER:
      return <DistributionCenter />
    case PRODUCT_BUNDLE_TYPE.OPS_INSIGHTS:
      return <OpsInsights />
    default:
      return <StoreOperations />
  }
}

export default function PrimarySideNav({
  activeProductBundle,
  productBundles,
}: {
  activeProductBundle: PRODUCT_BUNDLE_TYPE | undefined
  productBundles: ProductBundle[] | undefined
}) {
  const { name, userScope, accessRole } = useGetCustomerPermissions()

  return (
    <SideNav>
      <ProductBundlesList>
        {productBundles?.map((productBundle) => {
          const { type, name: productBundleName } = productBundle
          const Icon = getIconForProductBundle(type)
          return (
            <PrimarySideNavListItem
              key={type}
              icon={Icon}
              name={productBundleName}
              to={getDefaultRouteForPrimaryNav(productBundle) ?? '/'}
              active={activeProductBundle === type}
            />
          )
        })}
      </ProductBundlesList>
      <BottomActionsList>
        {window.Beacon && (
          <PrimarySideNavButton
            icon={<QuestionMark />}
            name="Help"
            onClick={() => window.Beacon('toggle')}
          />
        )}
        {(accessRole === ROLES.AFRESH_ADMIN ||
          accessRole === ROLES.AFRESH_SYSTEM) && (
          <PrimarySideNavListItem
            icon={<OpenInNew />}
            name="Admin Tools "
            to={{ pathname: '/internal' }}
            launch
          />
        )}
        <StyledBottomActionsDivider />
        {userScope && userScope !== 'DEFAULT' && (
          <StyledUserScope>{USER_SCOPE_MAP[userScope]}</StyledUserScope>
        )}
        <Tooltip title={name || ''} placement="right">
          <StyledAvatar>{getUserInitials(name)}</StyledAvatar>
        </Tooltip>
        <PrimarySideNavListItem
          icon={<Signout />}
          name="Sign out"
          to={{ pathname: '/logout' }}
        />
      </BottomActionsList>
    </SideNav>
  )
}
