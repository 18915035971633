import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { CONFIG_AD_SCHEDULE_ROW_FIELDS } from 'app/packages/internal/customerConfigs/adSchedules/api/fragments/ConfigAdScheduleRowFields'
import { AddConfigAdSchedulesArgs } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_CONFIG_AD_SCHEDULES = gql`
  ${CONFIG_AD_SCHEDULE_ROW_FIELDS}
  mutation createConfigAdSchedules(
    $createConfigAdSchedulesInput: CreateConfigAdSchedulesInput!
  ) {
    createConfigAdSchedules(input: $createConfigAdSchedulesInput) {
      results {
        ...ConfigAdSchedulesRowFields
      }
    }
  }
`

export function useAddConfigAdSchedules() {
  const { activeCustomerId } = useCustomers()
  const [addConfigAdSchedules, result] = useMutation(
    CREATE_CONFIG_AD_SCHEDULES,
    { refetchQueries: ['ConfigAdSchedules'] },
  )

  return tuple([
    useCallback(
      async (input: AddConfigAdSchedulesArgs) => {
        const { errors } = await addConfigAdSchedules({
          variables: {
            createConfigAdSchedulesInput: {
              customerId: activeCustomerId,
              configAdSchedules: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error('Add new Ad Schedule config failed')
        }
      },
      [activeCustomerId, addConfigAdSchedules],
    ),
    result,
  ])
}
