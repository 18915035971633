/* eslint-disable no-console */
import {
  EndingInventoryConnection,
  ReauthUserMutation,
  ReauthUserMutationVariables,
} from '@api/__gen__/gql'
import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  InMemoryCache,
  fromPromise,
  gql,
} from '@apollo/client'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { createUploadLink } from 'apollo-upload-client'
import { CustomerId, fetchCustomers, useCustomers } from 'app/CustomerContext'
import { HEADER_KEYS } from 'app/api/common/constants'
import { offsetPagination } from 'app/api/common/pagination'
import introspection from 'app/possibleTypes'
import {
  AuthenticationState,
  Tokens,
  useIsAuthenticated,
  useTokens,
} from 'app/state/authentication'
import { MutableRefObject, useEffect, useRef } from 'react'
import Observable from 'zen-observable'
import { useVars } from '../../VarsContext'

export const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

export function createCache(): InMemoryCache {
  return new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies: {
      BusinessLevelInstance: {
        keyFields: ['_id'],
      },
      ConfigAdSchedulesRow: {
        keyFields: ['_id'],
      },
      InventoryResultsFilters: {
        keyFields: [], // Don't cache
      },
      Viewer: {
        keyFields: [],
      },
      ViewerPermissions: {
        keyFields: [],
      },
      Query: {
        fields: {
          inventoryResultsData: offsetPagination<EndingInventoryConnection>([
            // KeyArgs ensure we don't accidentally overwrite cache across different queries
            'input',
            ['filters'],
          ]),
        },
      },
    },
  })
}

const reauthGql = gql`
  mutation reauthUser($refreshToken: String!) {
    reauthUser(input: { refreshToken: $refreshToken }) {
      accessToken
    }
  }
`

let pendingReauthRequest: Promise<string | null> | null = null

async function renewAccessToken(
  client: ApolloClient<unknown>,
  refreshToken: string,
) {
  // Only request a new access token for the first unauthenticated request.
  // Subsequent requests can get the new token from the existing Promise.
  if (!pendingReauthRequest) {
    pendingReauthRequest = new Promise((resolve) => {
      return client
        .mutate<ReauthUserMutation, ReauthUserMutationVariables>({
          mutation: reauthGql,
          variables: { refreshToken },
        })
        .then((response) => {
          resolve(response.data?.reauthUser?.accessToken || null)
          pendingReauthRequest = null
        })
        .catch(() => {
          resolve(null)
          pendingReauthRequest = null
        })
    })
  }

  return pendingReauthRequest
}

function handleGqlError(
  client: ApolloClient<unknown>,
  authState: AuthenticationState,
  { response, graphQLErrors, operation, forward }: ErrorResponse,
) {
  let isUnauthenticated = false
  if (graphQLErrors) {
    graphQLErrors.forEach((e) => {
      if (e.extensions?.code === 'UNAUTHENTICATED') {
        isUnauthenticated = true
      }
    })
  }

  const refreshToken = authState.getTokens()?.refresh
  if (isUnauthenticated && refreshToken && response) {
    return fromPromise(
      (async () => {
        const token = await renewAccessToken(client, refreshToken)
        if (token) {
          await authState.setTokens({ access: token, refresh: refreshToken })
        } else {
          // if the token refresh fails, clear the invalid tokens and reload
          // the page to force the user to reauthenticate
          await authState.setTokens(null)
          window.location.reload()
        }
        return token
      })(),
    ).flatMap((token) => {
      if (token) {
        // try again with new access token
        return forward(operation)
      } else {
        // return existing response (make TypeScript happy; we probably never
        // get here because of the reload() above)
        return Observable.of({
          ...response,
          context: operation.getContext(),
        })
      }
    })
  }

  return undefined
}

interface CustomerKeys {
  [customerId: string]: string
}

function createClient(params: {
  cache: InMemoryCache
  gqlUrl: string
  client: string
  authState: AuthenticationState
  activeCustomer: MutableRefObject<CustomerId | undefined>
  customerKeys: MutableRefObject<CustomerKeys>
}) {
  // Add headers for:
  //   - access token
  //   - loading the customer's tables on the server
  const dynamicHeadersLink = new ApolloLink((operation, forward) => {
    const tokens = params.authState.getTokens()

    const customerKey = params.activeCustomer.current
      ? params.customerKeys.current[params.activeCustomer.current]
      : null

    const searchParams = new URLSearchParams(window.location.search)
    const departmentBundleId = searchParams.get('deptBundleId')
    const workflowId = searchParams.get('workflowId')

    const context = operation.getContext()
    context.headers = {
      ...context.headers,
      ...(tokens && {
        Authorization: `Bearer ${tokens.access}`,
      }),
      ...(customerKey && {
        [HEADER_KEYS.AFRESH_CUSTOMER_KEY]: customerKey,
      }),
      ...(departmentBundleId && {
        [HEADER_KEYS.AFRESH_DEPARTMENT_BUNDLE_ID]: departmentBundleId,
      }),
      ...(workflowId && {
        [HEADER_KEYS.AFRESH_WORKFLOW_ID]: workflowId,
      }),
      [HEADER_KEYS.AFRESH_CLIENT_URL]: window.location.href,
    }
    operation.setContext(context)
    return forward(operation)
  })

  const httpLink = createUploadLink({
    uri: (op) => `${params.gqlUrl}/console/${op.operationName}`,
    headers: {
      [HEADER_KEYS.AFRESH_CLIENT]: params.client,
      // required for CSRF prevention, see https://github.com/afresh-technologies/afresh-web-portal/pull/1668
      'Apollo-Require-Preflight': 'true',
    },
  })

  const client: ApolloClient<unknown> = new ApolloClient({
    cache: params.cache,
    link: ApolloLink.from([
      onError((error) => handleGqlError(client, params.authState, error)),
      dynamicHeadersLink,
      new RetryLink(),
      httpLink,
    ]),
    queryDeduplication: true,
    defaultOptions,
  })

  return client
}

export function useApolloClient(
  authState: AuthenticationState,
  clientOverride?: ApolloClient<object>, // used for testing
) {
  const vars = useVars()
  const { activeCustomerRef, setCustomers, customers } = useCustomers()
  const customerKeys = useRef<CustomerKeys>({})

  // This tells us when we need to fetch customer info.
  const isAuthenticatedState = useIsAuthenticated(authState)

  const clientRef = useRef(
    clientOverride ||
      createClient({
        gqlUrl: vars.gqlUrl,
        client: vars.gqlClient,
        authState,
        cache: createCache(),
        activeCustomer: activeCustomerRef,
        customerKeys,
      }),
  )

  useTokens(authState, async (t: Tokens) => {
    // clear cache when the user logs out
    if (!t) {
      setCustomers(undefined)
      await clientRef.current.clearStore()
    }
  })

  // after authenticating, fetch viewable customers so that we can auto-set
  // the customer key header
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (isAuthenticatedState && activeCustomerRef.current === undefined) {
        const initialCustomers = await fetchCustomers(clientRef.current)
        customerKeys.current = {}
        initialCustomers.forEach((c) => {
          if (c) {
            customerKeys.current[c.id] = c.key
          }
        })
        setCustomers(initialCustomers)
      }
    })()
  }, [activeCustomerRef, isAuthenticatedState, setCustomers])

  useEffect(() => {
    // Remap customer keys ref if they change, currently only used for the Customer Setup tool
    if (customers) {
      customers.forEach((c) => {
        if (c) {
          customerKeys.current[c.id] = c.key
        }
      })
    }
  }, [customers])

  return { client: clientRef.current }
}
