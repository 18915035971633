import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.01953 7.97998V21.03H7.04953V10.97H16.9795V21.03H21.0195V7.97998L12.0195 1.97998L3.01953 7.97998Z"
      fill="currentColor"
    />
    <path d="M15 19.0303H13V21.0303H15V19.0303Z" fill="currentColor" />
    <path d="M13 15.9702H11V17.9702H13V15.9702Z" fill="currentColor" />
    <path d="M11 19.0303H9V21.0303H11V19.0303Z" fill="currentColor" />
    <path d="M16 11.9702H8V13.9702H16V11.9702Z" fill="currentColor" />
  </svg>,
  'DistributionCenter',
)
