import {
  EndingInventoryDataFragment,
  EndingInventoryStatus,
  InventoryResultsDataFilters,
} from '@api/__gen__/gql'
import { Box, LinearProgress } from '@mui/material'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { useCustomers } from 'app/CustomerContext'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import Button from 'app/components/Button/Button'
import NoRowsOverlay from 'app/components/DataGridComponents/NoRowsOverlay'
import ErrorDisplay from 'app/components/ErrorDisplay/ErrorDisplay'
import Close from 'app/components/Icons/Close'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import { ROW_HEIGHT } from 'app/components/Tables/Tables.constants'
import {
  InventoryResultsData,
  useInventoryResultsContext,
} from 'app/packages/inventoryManagement/inventoryResults/context/inventoryResultsContext'
import { getInventoryTableBodyColumns } from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/inventoriesTableColumns'
import { InventoryDueMessage } from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryDueMessage/InventoryDueMessage'
import {
  InventoryStatus,
  STATUS_NOT_STARTED,
} from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.constants'
import {
  areAnyFiltersSelected,
  convertGQLStatusToUIStatus,
  convertUIStatusToText,
  periodName,
} from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.helpers'
import { toTitleCase } from 'app/utils'
import dayjs from 'dayjs'
import { uniq } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import segment from 'src/__generated__/segment'
import styled, { css } from 'styled-components'

export enum PeriodType {
  CURRENT = 'Current Counting Period',
  PAST = 'Past Periods',
}

// To be replaced by App API calls [SC-82763]
const CustomerBusinessLevels: {
  [key: string]: { businessUnit?: string; region?: string }
} = {
  alb: { businessUnit: 'division', region: 'district' },
  bas: { region: 'district' },
  ft: { region: 'region' },
  heinens: {},
}

export const EMPTY_FILTERS: InventoryResultsDataFilters = {
  accountingPeriodIds: [],
  businessUnitIds: [],
  departmentBundleIds: [],
  inventoryStatuses: [],
  regionIds: [],
  storeIds: [],
}

export const getCustomerBusinessLevels = (customerKey: string | undefined) => {
  return CustomerBusinessLevels[customerKey ?? ''] ?? {}
}

export interface EndingInventoriesTableProps {
  inventoriesData: InventoryResultsData
  selectedFilters: InventoryResultsDataFilters
  setSelectedFilters: (
    partialFilters: Partial<InventoryResultsDataFilters>,
  ) => void
  clearFiltersFunction: () => void
  headerText?: string
  periodType: PeriodType
}

export interface EndingInventoryProps
  extends Omit<EndingInventoryDataFragment, 'status'> {
  status: InventoryStatus | typeof STATUS_NOT_STARTED
  storeId?: string
  businessUnitName?: string
  regionName?: string
  departmentBundleName?: string
  view?: React.ReactElement
}

// Height of the tabs component
const INVENTORY_TABS_HEIGHT = 58
// Height of the due message component
const INVENTORY_DUE_HEIGHT = 56
// Height of the filters component
const INVENTORY_FILTERS_HEIGHT = 66

const HeaderRowTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
`

const StyledHeaderContainer = styled.div<{ $isDueMessageHeader: boolean }>`
  align-items: center;
  column-gap: 12px;
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  line-height: ${ROW_HEIGHT};
  display: flex;
  ${({ $isDueMessageHeader }) => {
    if ($isDueMessageHeader) {
      return css`
        justify-content: space-between;
        padding: 2px 16px; /* large text adjustment */
      `
    } else {
      return css`
        padding: 12px 16px;
      `
    }
  }};
`

const ClearFiltersButton = styled(Button)`
  margin-left: auto;
  align-items: center;
  justify-content: center;
`

export default function EndingInventoriesTable({
  inventoriesData,
  selectedFilters,
  setSelectedFilters,
  clearFiltersFunction,
  periodType,
  headerText,
}: EndingInventoriesTableProps) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const lastPathPart = useMemo(
    () => pathname.substring(pathname.lastIndexOf('/') + 1),
    [pathname],
  )
  const { activeCustomer, activeCustomerKey } = useCustomers()
  const {
    isInventoryApprovalEnabled,
    isCountingPeriodActive,
    nextCountingPeriodEndDate,
  } = useInventoryResultsContext()
  const {
    endingInventories,
    stores,
    departmentBundles,
    regions,
    businessUnits,
    accountingPeriods,
    inventoryStatuses: allStatuses,
    hasNextPage,
    isLoadingEndingInventories: isLoading,
    error,
    refetch,
  } = inventoriesData

  const statuses = uniq(
    allStatuses.map((status) => convertGQLStatusToUIStatus(status)),
  )

  const loadNextPage = async () => {
    if (isLoading || endingInventories.length === 0 || !hasNextPage) {
      return null
    }

    try {
      return await inventoriesData.fetchNextPage()
    } catch (e) {
      // no-op, errors logged to Sentry one layer up
      return null
    }
  }

  const onClickInventory = (inventory: EndingInventoryProps) => {
    segment.endingInventoryOpened({
      endingInventoryId: inventory.id,
      isActiveInventory: inventory.isActive,
      isCountingPeriodActive,
      isInventoryAdjustmentsEnabled: inventory.isAdjustmentsEnabled,
      isInventoryApprovalEnabled,
      periodName: periodName(inventory.accountingPeriod),
      status: inventory.status,
    })
    navigate(`/${activeCustomerKey}/inventory-results/${inventory.id}`, {
      state: { from: lastPathPart },
    })
  }

  const { accessRole } = useGetCustomerPermissions()

  const storeOptions = useMemo(() => {
    return stores.map((store) => ({
      id: store.customerStoreId,
      name: `Store ${store.customerStoreId}`,
    }))
  }, [stores])
  const businessUnitOptions = useMemo(() => {
    return businessUnits.map((bu) => ({
      id: bu.id,
      name: bu.name ?? '',
    }))
  }, [businessUnits])
  const regionOptions = useMemo(() => {
    return regions.map((region) => ({
      id: region.id,
      name: region.name ?? '',
    }))
  }, [regions])
  const statusOptions = useMemo(() => {
    return statuses.map((status) => ({
      id: status,
      name: convertUIStatusToText(status),
    }))
  }, [statuses])
  const periodOptions = useMemo(() => {
    return accountingPeriods.map((period) => {
      const name = periodName(period)
      return { id: period.id, name }
    })
  }, [accountingPeriods])

  const showInventoryDueMessage =
    periodType === PeriodType.CURRENT &&
    nextCountingPeriodEndDate.isAfter(dayjs())

  // table height with padding calculation
  let headerHeight = INVENTORY_TABS_HEIGHT
  if (showInventoryDueMessage) {
    headerHeight += INVENTORY_DUE_HEIGHT
  }

  // To be replaced by a config
  const customerBusinessUnitsName = getCustomerBusinessLevels(
    activeCustomer?.key,
  )?.businessUnit
  const customerRegionsName = getCustomerBusinessLevels(activeCustomer?.key)
    ?.region

  const shouldDisplayFilters =
    storeOptions.length > 1 ||
    departmentBundles.length > 1 ||
    businessUnitOptions.length > 1 ||
    regionOptions.length > 1 ||
    statusOptions.length > 1 ||
    periodOptions.length > 1
  if (shouldDisplayFilters) {
    headerHeight += INVENTORY_FILTERS_HEIGHT
  }

  const ErrorMessage = useCallback(
    () => (
      <ErrorDisplay
        title="Sorry!"
        message="We were unable to load inventory results."
        onClickButton={refetch}
      />
    ),
    [refetch],
  )

  const EmptyState = useCallback(
    () => (
      <NoRowsOverlay
        headingText="No items found"
        subText="Try selecting different filters or clear filters to start over"
        buttonText="Clear Filters"
        buttonFunction={clearFiltersFunction}
      />
    ),
    [clearFiltersFunction],
  )

  return (
    <>
      {showInventoryDueMessage && (
        <StyledHeaderContainer $isDueMessageHeader>
          {headerText && <HeaderRowTitle>{headerText}</HeaderRowTitle>}
          <InventoryDueMessage
            dueDate={nextCountingPeriodEndDate}
            accessRole={accessRole}
          />
        </StyledHeaderContainer>
      )}
      {shouldDisplayFilters && (
        <StyledHeaderContainer $isDueMessageHeader={false}>
          {storeOptions.length > 1 && (
            <MultiSelect
              inputLabel="Store"
              options={storeOptions}
              onFilterOptions={(storeIds) => setSelectedFilters({ storeIds })}
              selectedOptions={selectedFilters.storeIds}
            />
          )}
          {departmentBundles.length > 1 && (
            <MultiSelect
              inputLabel="Department"
              options={departmentBundles}
              onFilterOptions={(departmentBundleIds) =>
                setSelectedFilters({ departmentBundleIds })
              }
              selectedOptions={selectedFilters.departmentBundleIds}
            />
          )}
          {customerBusinessUnitsName && businessUnitOptions.length > 1 && (
            <MultiSelect
              inputLabel={toTitleCase(customerBusinessUnitsName)}
              options={businessUnitOptions}
              onFilterOptions={(businessUnitIds) =>
                setSelectedFilters({ businessUnitIds })
              }
              selectedOptions={selectedFilters.businessUnitIds}
            />
          )}
          {customerRegionsName && regionOptions.length > 1 && (
            <MultiSelect
              inputLabel={toTitleCase(customerRegionsName)}
              options={regionOptions}
              onFilterOptions={(regionIds) => setSelectedFilters({ regionIds })}
              selectedOptions={selectedFilters.regionIds}
            />
          )}
          {statusOptions.length > 1 && (
            <MultiSelect
              inputLabel="Status"
              options={statusOptions}
              onFilterOptions={(inventoryStatuses) =>
                setSelectedFilters({
                  inventoryStatuses: inventoryStatuses as EndingInventoryStatus[],
                })
              }
              selectedOptions={selectedFilters.inventoryStatuses}
            />
          )}
          {periodOptions.length > 1 && (
            <MultiSelect
              inputLabel="Period"
              options={periodOptions}
              onFilterOptions={(accountingPeriodIds) =>
                setSelectedFilters({ accountingPeriodIds })
              }
              selectedOptions={selectedFilters.accountingPeriodIds}
            />
          )}
          {areAnyFiltersSelected(selectedFilters) && (
            <ClearFiltersButton
              variant="secondary"
              onClick={clearFiltersFunction}>
              <Close />
              <Box sx={{ margin: '2px 0 0 2px' }}>Clear filters</Box>
            </ClearFiltersButton>
          )}
        </StyledHeaderContainer>
      )}
      <DataGridPremium
        hideFooter
        style={{
          /*
            Table should:
            - Shrink to fit it's contents
            - Grow as content grows
            - Don't overflow the page (minHeight=0)
            - Scroll internally after it can't grow any more
            https://stackoverflow.com/a/66689926/3900967
            https://stackoverflow.com/a/14964944/3900967
          */
          display: 'flex',
          flexDirection: 'column',
          maxHeight: `calc(100% - ${headerHeight}px)`,
          minHeight: '0',
          borderTop: 'none',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          fontSize: '1rem',
        }}
        columns={getInventoryTableBodyColumns(
          periodType,
          customerBusinessUnitsName,
          customerRegionsName,
          onClickInventory,
        )}
        rows={endingInventories}
        initialState={{
          pinnedColumns: { left: ['__row_group_by_columns_group__'] },
        }}
        loading={isLoading}
        onRowClick={(ei) =>
          ei.row.status === STATUS_NOT_STARTED
            ? undefined
            : onClickInventory(ei.row)
        }
        slots={{
          noRowsOverlay: error ? ErrorMessage : EmptyState,
          loadingOverlay: () => <LinearProgress />,
        }}
        scrollEndThreshold={2000}
        onRowsScrollEnd={loadNextPage}
      />
    </>
  )
}
