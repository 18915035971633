import { BusinessLevelInstance } from '@api/__gen__/gql'
import { useCustomers } from 'app/CustomerContext'
import useGetAllWorkflows from 'app/api/queries/useGetAllWorkflows'
import useOffsetPagination from 'app/components/Tables/OffsetPaginatedTable/useOffsetPagination.hook'
import { useGetDepartmentBundles } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartmentBundles'
import { useGetStoreWorkflowsAvailability } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetStoreWorkflowsAvailability'
import { CHAINWIDE_LABEL } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailabilityAddEditModal'
import React, { useMemo, useState } from 'react'
import WorkflowAvailabilityStoresTable from './WorkflowAvailabilityStoresTable'

export const DEFAULT_PAGE_SIZE = 25

export function formatBusinessLevelInstanceOptionLabel(
  businessLevelInstance: BusinessLevelInstance,
) {
  if (businessLevelInstance.businessLevelMetadata.level === 'CHAIN') {
    return CHAINWIDE_LABEL
  }
  return `${businessLevelInstance.name} (${businessLevelInstance.id})`
}

export default function WorkflowAvailability({
  disableVirtualization = false,
  setupDetailsExpanded,
}: {
  disableVirtualization?: boolean
  setupDetailsExpanded: boolean
}) {
  const { activeCustomerId } = useCustomers()

  const [workflowFilter, setWorkflowFilter] = useState('')
  const [departmentBundleFilter, setDepartmentBundleFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const {
    pageSize,
    pageIndex,
    handlePageChange,
    setPageIndex,
  } = useOffsetPagination(DEFAULT_PAGE_SIZE)

  const { rows, pageInfo, loading } = useGetStoreWorkflowsAvailability({
    customerId: activeCustomerId,
    offset: pageIndex * pageSize,
    limit: pageSize,
    workflowId: workflowFilter || null,
    workflowDepartmentBundleId: departmentBundleFilter || null,
    searchTerm: searchTerm || null,
    regionId: null,
    businessUnitId: null,
  })

  const { data: workflows, isLoading: workflowsLoading } = useGetAllWorkflows()
  const {
    rows: departmentBundles,
    loading: departmentBundlesLoading,
  } = useGetDepartmentBundles({ customerId: activeCustomerId })

  const workflowOptions = useMemo(() => {
    return [
      { label: '--', value: '' },
      ...workflows.map((workflow) => ({
        label: `${workflow.databaseId} - ${workflow.workflowName}`,
        value: workflow.id,
      })),
    ]
  }, [workflows])

  const departmentBundleOptions = useMemo(() => {
    return [
      { label: '--', value: '' },
      ...departmentBundles.map((departmentBundle) => ({
        label: departmentBundle.name,
        value: departmentBundle._id,
      })),
    ]
  }, [departmentBundles])

  return (
    <WorkflowAvailabilityStoresTable
      rows={rows}
      disableVirtualization={disableVirtualization}
      pageInfo={pageInfo}
      autoPageSize={false}
      isLoading={loading || workflowsLoading || departmentBundlesLoading}
      onPageChange={handlePageChange}
      setupDetailsExpanded={setupDetailsExpanded}
      workflowFilters={workflowOptions}
      workflowFilter={workflowFilter}
      setWorkflowFilter={(value) => {
        setWorkflowFilter(value)
        setPageIndex(0)
      }}
      departmentBundleFilters={departmentBundleOptions}
      departmentBundleFilter={departmentBundleFilter}
      setDepartmentBundleFilter={(value) => {
        setDepartmentBundleFilter(value)
        setPageIndex(0)
      }}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  )
}
