import { AuthMethodQuery, AuthMethodQueryVariables } from '@api/__gen__/gql'
import { Box } from '@mui/material'
import Button from 'app/components/Button/Button'
import CardTitle from 'app/components/CardTitle/CardTitle'
import TextField from 'app/components/TextField/TextField'
import { useRequestPasswordReset } from 'app/packages/core/api/login/mutations/useRequestPasswordReset'
import { authMethodGql } from 'app/packages/core/api/login/queries/authMethod'
import React, { useCallback, useState } from 'react'
import assignLocation from 'shared/assignLocation'
import useAsyncQuery from 'shared/useAsyncQuery'
import useIsMounted from 'shared/useIsMounted'
import styled from 'styled-components'

interface ResetPasswordSuccessProps {
  email: string
  onGoBack: () => void
}

const StyledButton = styled(Button)`
  font-weight: normal;
  padding: 0;
`

export function ResetPasswordSuccess(props: ResetPasswordSuccessProps) {
  const { onGoBack } = props
  return (
    <>
      <CardTitle>Check your email</CardTitle>
      <Box>
        We sent an email to {props.email} that contains a link to reset your
        password.
      </Box>
      <Box mt={4}>
        <StyledButton variant="tertiary" onClick={onGoBack}>
          Back to sign in
        </StyledButton>
      </Box>
    </>
  )
}

interface ResetPasswordFormProps {
  isFirstTimeSettingPassword: boolean
  initialEmailValue?: string
  onSuccess: () => void
  onGoBack: () => void
}

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const {
    onGoBack,
    onSuccess,
    initialEmailValue,
    isFirstTimeSettingPassword,
  } = props
  const [email, setEmail] = useState(initialEmailValue || '')
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const resetPassword = useRequestPasswordReset()
  const isMounted = useIsMounted()

  const getAuthMethod = useAsyncQuery<
    AuthMethodQuery,
    AuthMethodQueryVariables
  >(authMethodGql)

  const submit = useCallback(async (): Promise<void> => {
    setSubmitInProgress(true)
    try {
      const result = await resetPassword({ variables: { email } })

      if (result.errors) {
        const errorCode = result.errors[0]?.extensions?.code
        if (errorCode === 'SSO_PASSWORD_RESET_REQUEST') {
          const authMethodResult = await getAuthMethod({ variables: { email } })
          const authMethod = authMethodResult.data?.user?.authenticationMethod
          if (authMethod?.__typename === 'AuthenticationMethodOidc') {
            assignLocation(authMethod.authUrl)
          } else {
            setErrorMessage('Something went wrong')
          }
        } else if (
          errorCode === 'EMAIL_INVALID' ||
          errorCode === 'USER__DOES_NOT_EXIST'
        ) {
          setErrorMessage('Account not found')
        } else {
          setErrorMessage('Something went wrong')
        }
      } else {
        onSuccess()
      }
    } catch (e) {
      setErrorMessage('Something went wrong')
    } finally {
      if (isMounted()) {
        setSubmitInProgress(false)
      }
    }
  }, [resetPassword, email, onSuccess, isMounted, getAuthMethod])

  return (
    <>
      <CardTitle>
        {isFirstTimeSettingPassword ? 'Sign in' : 'Reset password'}
      </CardTitle>
      {isFirstTimeSettingPassword ? (
        <>
          <Box>
            <b>You must set a password before you can sign in.</b>
          </Box>
          <Box mt={2}>
            Confirm your email address and we’ll email you a link to set your
            password.
          </Box>
        </>
      ) : (
        <Box>
          Enter your email address and we’ll send you a link to reset your
          password.
        </Box>
      )}
      <form
        onSubmit={(e) => {
          void submit() // eslint-disable-line no-void
          e.preventDefault()
        }}>
        <Box mt={4}>
          <TextField
            label="Email address"
            value={email}
            error={!!errorMessage}
            helperText={errorMessage}
            autoFocus
            fullWidth
            disabled={submitInProgress}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Button disabled={submitInProgress} onClick={submit}>
            {isFirstTimeSettingPassword ? 'Resend email' : 'Send link'}
          </Button>
        </Box>
      </form>
      <Box mt={4}>
        <StyledButton variant="tertiary" onClick={onGoBack}>
          Sign in with a different email
        </StyledButton>
      </Box>
    </>
  )
}

ResetPasswordForm.defaultProps = {
  initialEmailValue: '',
}

export interface SetPasswordProps {
  email: string
  onChangeEmail: () => void
}

export function SetPassword(props: SetPasswordProps) {
  const { email, onChangeEmail } = props
  const [hasReset, setHasReset] = useState(false)
  return (
    <>
      {hasReset ? (
        <ResetPasswordSuccess email={email} onGoBack={onChangeEmail} />
      ) : (
        <ResetPasswordForm
          isFirstTimeSettingPassword
          initialEmailValue={email}
          onSuccess={() => {
            setHasReset(true)
          }}
          onGoBack={onChangeEmail}
        />
      )}
    </>
  )
}
