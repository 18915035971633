import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_CONFIG_AD_SCHEDULES = gql`
  mutation deleteConfigAdSchedules(
    $deleteConfigAdSchedulesInput: DeleteConfigAdSchedulesInput!
  ) {
    deleteConfigAdSchedules(input: $deleteConfigAdSchedulesInput) {
      results
    }
  }
`

export function useDeleteConfigAdSchedules() {
  const { activeCustomerId } = useCustomers()
  const [deleteConfigAdSchedules, result] = useMutation(
    DELETE_CONFIG_AD_SCHEDULES,
    {
      refetchQueries: ['ConfigAdSchedules'],
    },
  )

  return tuple([
    useCallback(
      async (input: string) => {
        const { errors } = await deleteConfigAdSchedules({
          variables: {
            deleteConfigAdSchedulesInput: {
              customerId: activeCustomerId,
              ids: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error('Delete Ad Schedules Config Failed')
        }
      },
      [activeCustomerId, deleteConfigAdSchedules],
    ),
    result,
  ])
}
