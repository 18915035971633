import { Checkbox, CircularProgress } from '@mui/material'
import Tag from 'app/components/Tag/Tag'
import TableCellTooltip from 'app/components/Tooltip/TableCellTooltip'
import { ROW_HEIGHT_NUMBER } from 'app/components/VirtualizedList/VirtualizedList.constants'
import { EM_DASH, ENTER, SPACE } from 'app/constants'
import {
  GuideStructureOption,
  OrderableOption,
  RowItemProps,
  SelectedItem,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import ItemDetails from 'app/packages/storeSolutions/guideOrganization/pages/Items/ItemDetails'
import { get } from 'lodash-es'
import React, { useState } from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledRowContainer = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  flex-direction: column;
  line-height: ${ROW_HEIGHT_NUMBER}px;
`

const StyledStickyRow = styled.span`
  padding: ${({ theme }) => `0 ${theme.typography.pxToRem(20)}`};
`

const StyledCell = styled.div`
  box-sizing: border-box;
  display: inline-block;
  height: ${ROW_HEIGHT_NUMBER - 1}px;
  line-height: ${ROW_HEIGHT_NUMBER - 1}px;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const StyledItemNameCell = styled(StyledCell)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(280)}`};
  margin-left: ${({ theme }) => theme.typography.pxToRem(52)};
  position: relative;
`

const StyledOrderCodeCell = styled(StyledCell)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(132)}`};
`

const StyledMajorCategoryCell = styled(StyledCell)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(175)}`};
`

const StyledCategoryCell = styled(StyledCell)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(175)}`};
`

const StyledTag = styled(Tag)`
  margin: ${() => {
    const topBottomValue = (ROW_HEIGHT_NUMBER - 1) / 4
    return `${topBottomValue}px 0`
  }};
`

const StyledRow = styled.div`
  align-items: center;
  display: flex;
  height: ${ROW_HEIGHT_NUMBER}px;
  position: relative;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
    cursor: pointer;

    ${StyledTag} {
      background-color: ${({ theme }) => theme.colors.neutral_02};
      color: ${({ theme }) => theme.colors.neutral_08};
    }
  }

  &:not(:first-of-type) {
    ${StyledCell} {
      border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
    }
  }

  &:focus-visible {
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    outline-offset: -4px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`

const StyledCheckbox = styled(Checkbox)`
  height: 48px;
  left: 0;
  margin: ${({ theme }) => `0 0 ${theme.typography.pxToRem(2)}`};
  position: absolute;
  width: 58px;

  &.Mui-focusVisible {
    border-radius: 0;
    outline-offset: -8px;
  }
`

const StyledLoadingPlaceholder = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  column-gap: 16px;
  display: flex;
  height: ${ROW_HEIGHT_NUMBER}px;
  justify-content: center;
  padding: 8px;
`

export function RowSection({ sectionName, ...rest }: { sectionName: string }) {
  return (
    <StyledRowContainer {...rest}>
      <StyledStickyRow>{sectionName}</StyledStickyRow>
    </StyledRowContainer>
  )
}

export function RowItem({
  handleOnSelect,
  isFetching,
  isLastRow,
  isSelectedRow,
  row,
  style,
  isDraft,
}: RowItemProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null)

  const orderableOptions: OrderableOption[] = get(
    row,
    ['original', 'orderableOptions'],
    [],
  )

  let options: GuideStructureOption[] = orderableOptions
  if (options.length === 0) {
    options = [
      {
        name: row.original.afreshItemName,
        id: row.original.id, // for unique react key
      },
    ]
  }

  const { hasBeenEdited, itemSubClass, itemClass } = row.original

  const handleOnClick = (item: GuideStructureOption, index: number) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      [ENTER, SPACE].indexOf((event as React.KeyboardEvent).key) === -1
    ) {
      return
    }

    const substitutes = [
      ...orderableOptions.slice(0, index),
      ...orderableOptions.slice(index + 1),
    ]

    setSelectedItem(() => ({
      ...item,
      ...row.original,
      substitutes,
    }))

    segment.guideOrganizationItemRowClicked({
      afreshItemId: row.original.afreshItemId,
      itemCategory: row.original.category,
      itemClass: row.original.itemClass,
      itemSubClass: row.original.itemSubClass,
      itemId: item.id,
      itemName: item.name,
      orderCode: item.orderCode,
      ...defaultProperties,
    })
  }

  return (
    <>
      <StyledRowContainer {...row.getRowProps({ style })}>
        {options.map((option, optionIndex) => {
          const totalSubstitutes = orderableOptions.length - 1
          let substitutesDisplay = EM_DASH

          if (totalSubstitutes > 1) {
            substitutesDisplay = `${totalSubstitutes} items`
          } else if (totalSubstitutes === 1) {
            substitutesDisplay = '1 item'
          }

          const showCheckbox = isDraft && optionIndex === 0
          const label = {
            inputProps: { 'aria-label': `${option.name} checkbox` },
          }

          return (
            <StyledRow
              key={option.id}
              onClick={handleOnClick(option, optionIndex)}
              onKeyDown={handleOnClick(option, optionIndex)}
              role="button"
              tabIndex={0}>
              {showCheckbox && (
                <StyledCheckbox
                  {...label}
                  checked={isSelectedRow}
                  onClick={handleOnSelect}
                  onKeyDown={handleOnSelect}
                />
              )}
              <TableCellTooltip title={option.name}>
                <StyledItemNameCell>
                  {option.name || EM_DASH}
                </StyledItemNameCell>
              </TableCellTooltip>
              <StyledOrderCodeCell>
                {option.orderCode || EM_DASH}
              </StyledOrderCodeCell>
              <TableCellTooltip title={itemClass}>
                <StyledMajorCategoryCell>
                  {itemClass || EM_DASH}
                </StyledMajorCategoryCell>
              </TableCellTooltip>
              <TableCellTooltip title={itemSubClass}>
                <StyledCategoryCell>
                  {itemSubClass || EM_DASH}
                </StyledCategoryCell>
              </TableCellTooltip>
              <StyledCell>{substitutesDisplay}</StyledCell>
              {isDraft && hasBeenEdited && (
                <StyledCell>
                  <StyledTag color="highlight_04" text="Edits applied" />
                </StyledCell>
              )}
            </StyledRow>
          )
        })}

        {isLastRow && isFetching && (
          <StyledLoadingPlaceholder>
            <CircularProgress size={28} /> Loading more items...
          </StyledLoadingPlaceholder>
        )}
      </StyledRowContainer>

      {selectedItem && (
        <ItemDetails
          item={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </>
  )
}
