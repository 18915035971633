import { ENVIRONMENTS } from 'app/constants'
import React, { useContext } from 'react'

export interface Vars {
  apiUrl: string
  appVersion: string
  buildNum: string
  buildHash: string
  environment: ENVIRONMENTS | string
  gqlUrl: string
  gqlClient: string
  rolloutToken: string
  sentryDsn: string
}

export const DEFAULT_VARS: Vars = Object.freeze({
  apiUrl: '',
  appVersion: '',
  buildNum: '',
  buildHash: '',
  environment: '',
  gqlUrl: '',
  gqlClient: '',
  rolloutToken: '',
  sentryDsn: '',
})

export const VarsContext = React.createContext<Vars>(DEFAULT_VARS)

export function useVars(): Vars {
  const vars = useContext(VarsContext)
  return vars
}
