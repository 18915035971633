import { DynamicVirtualizedRowProps } from 'app/components/VirtualizedList/Dynamic'
import useGetScrollbarWidth from 'app/hooks/useGetScrollbarWidth'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import RowGroup from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/RowGroup'
import useGetTableColumns from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import {
  Order,
  StoreScheduleRow,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import Table, {
  TableProps,
} from 'app/packages/storeSolutions/schedules/pages/Table/Table'
import React, { CSSProperties, useCallback, useEffect, useState } from 'react'
import { Row } from 'react-table'
import styled from 'styled-components'

export const StyledRow = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  display: flex;
  width: 100%;

  & > :not(:first-child) {
    flex: 1;
  }

  & > :first-child {
    align-items: flex-start;
    cursor: auto;
    flex: 0 0 316px;
    font-weight: normal;
    height: 100%;
  }
`

// https://github.com/styled-components/styled-components/issues/1803#issuecomment-1181765843
const StyledTable = styled(Table)<{ $adjustedPadding: number }>`
  & > div[class^='Table__StyledTableHeaderRow'] {
    padding-right: ${({ $adjustedPadding }) => `${$adjustedPadding}px`};
  }
` as <T extends DynamicVirtualizedRowProps>(
  props: TableProps<T> & { $adjustedPadding: number },
) => React.ReactElement

export interface OrdersAndDeliveriesTableBodyProps {
  data: StoreScheduleRow[]
  isLoading: boolean
}

export default function OrdersAndDeliveriesTableBody({
  data,
  isLoading,
}: OrdersAndDeliveriesTableBodyProps) {
  const { isEditing } = useGetOrderDeliverySchedule()
  const [activeScheduleEvent, setActiveScheduleEvent] = useState<Order | null>(
    null,
  )

  const columns = useGetTableColumns()
  const scrollbarWidth = useGetScrollbarWidth()

  useEffect(() => {
    if (!isEditing && !!activeScheduleEvent) {
      setActiveScheduleEvent(null)
    }
  }, [activeScheduleEvent, isEditing])

  const renderRow = useCallback(
    (row: Row<StoreScheduleRow>, style: CSSProperties) => {
      return (
        <StyledRow {...row.getRowProps({ style })}>
          <RowGroup
            activeOrder={activeScheduleEvent}
            onChangeActiveOrder={setActiveScheduleEvent}
            row={row}
          />
        </StyledRow>
      )
    },
    [activeScheduleEvent],
  )

  return (
    <StyledTable
      $adjustedPadding={scrollbarWidth}
      columns={columns}
      data={data}
      isLoading={isLoading}
      renderRow={renderRow}
      type="DYNAMIC"
    />
  )
}
