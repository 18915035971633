import { Popover } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import Button from 'app/components/Button/Button'
import { DateNavigator } from 'app/components/DateNavigator/DateNavigator'
import Calendar from 'app/components/Icons/Calendar'
import {
  addDuration,
  dateIsBetween,
  equals,
  getISODateString,
} from 'app/helpers/date'
import useDateRangeSearchParam from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import styled from 'styled-components'

const CalendarButton = styled(Button)`
  margin: 0;

  span {
    margin: 0;
  }
`

const StyledPickersDay = styled(PickersDay)(({ theme }) => ({
  '&&&': {
    '&.MuiPickersDay-root.MuiPickersDay-today.Mui-selected': {
      borderColor: theme.colors.neutral_09,
      backgroundColor: theme.colors.neutral_09,
      color: theme.colors.neutral_08,
    },
    '&.Mui-selected.startDate': {
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
    '&.Mui-selected.endDate': {
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    },
    '&.Mui-selected': {
      borderRadius: 0,
    },
  },
})) as typeof PickersDay

interface OrdersAndDeliveriesDatePickerProps {
  className?: string
}
export function OrdersAndDeliveriesDatePicker(
  props: OrdersAndDeliveriesDatePickerProps,
) {
  const { className } = props
  const [
    { startDate, endDate },
    setSchedulesDateRange,
  ] = useDateRangeSearchParam()

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(startDate))
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLButtonElement | null>(
    null,
  )

  const onPreviousWeek = () => {
    const newStart = getISODateString(addDuration(startDate, -1, 'weeks'))
    const newEnd = getISODateString(addDuration(startDate, -1, 'days'))
    setSelectedDate(dayjs(newStart))
    setSchedulesDateRange({
      startDate: newStart,
      endDate: newEnd,
    })
  }

  const onNextWeek = () => {
    const newStart = getISODateString(addDuration(endDate, 1, 'days'))
    const newEnd = getISODateString(addDuration(endDate, 1, 'weeks'))
    setSelectedDate(dayjs(newStart))
    setSchedulesDateRange({
      startDate: newStart,
      endDate: newEnd,
    })
  }

  const onSelectDate = (date: Dayjs | null) => {
    if (!date) {
      return
    }

    setSelectedDate(date)

    const newStart = getISODateString(date.toString())
    const newEnd = getISODateString(addDuration(date.toString(), 6, 'days'))

    setSchedulesDateRange({
      startDate: newStart,
      endDate: newEnd,
    })
  }

  const renderWeekPicker = (
    day: Dayjs,
    _: (Dayjs | null)[],
    pickersDayProps: PickersDayProps<Dayjs>,
  ) => {
    const dayIsBetween = dateIsBetween(day.toString(), startDate, endDate)
    const isFirstDay = equals(day.toString(), startDate)
    const isLastDay = equals(day.toString(), endDate)

    const generateClassName = () => {
      let cx = ''
      if (dayIsBetween) {
        cx = `${cx} Mui-selected`
      }

      if (isFirstDay) {
        cx = `${cx} startDate`
      }

      if (isLastDay) {
        cx = `${cx} endDate`
      }

      return cx.trim()
    }

    return (
      <StyledPickersDay
        {...pickersDayProps}
        className={generateClassName()}
        disableMargin
      />
    )
  }

  return (
    <div className={className}>
      <CalendarButton
        variant="icon"
        aria-label="Open calendar date picker"
        onClick={(e) => setPopoverAnchor(e.currentTarget)}>
        <Calendar />
      </CalendarButton>
      <DateNavigator
        dateRange={{ startDate, endDate }}
        navigateForward={onNextWeek}
        navigateBackward={onPreviousWeek}
        navigateForwardAriaLabel="Next week"
        navigateBackwardAriaLabel="Previous week"
      />
      <Popover
        open={!!popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker
            date={selectedDate}
            minDate={dayjs().subtract(1, 'year')}
            renderDay={renderWeekPicker}
            onChange={onSelectDate}
          />
        </LocalizationProvider>
      </Popover>
    </div>
  )
}
