import { BusinessLevel, BusinessLevelInstance } from '@api/__gen__/gql'
import { useReactiveVar } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import useGetAllWorkflows from 'app/api/queries/useGetAllWorkflows'
import useOffsetPagination from 'app/components/Tables/OffsetPaginatedTable/useOffsetPagination.hook'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import useGetBusinessLevelOptions from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetBusinessLevelOptions'
import { useGetWorkflowAvailability } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetWorkflowAvailability'
import { $activeWorkflowAvailabilityConfig } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability.variables'
import WorkflowAvailabilityAddEditModal, {
  CHAINWIDE_LABEL,
} from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailabilityAddEditModal'
import WorkflowAvailabilityDeleteModal from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailabilityDeleteModal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import WorkflowAvailabilityTable from './WorkflowAvailabilityTable'

export const DEFAULT_PAGE_SIZE = 25

export function formatBusinessLevelInstanceOptionLabel(
  businessLevelInstance: BusinessLevelInstance,
) {
  if (businessLevelInstance.businessLevelMetadata.level === 'CHAIN') {
    return CHAINWIDE_LABEL
  }
  return `${businessLevelInstance.name} (${businessLevelInstance.id})`
}

export default function WorkflowAvailabilityConfigure({
  disableVirtualization = false,
  setupDetailsExpanded,
}: {
  disableVirtualization?: boolean
  setupDetailsExpanded: boolean
}) {
  const { activeCustomerId, activeCustomer } = useCustomers()

  const [workflowFilter, setWorkflowFilter] = useState('')
  const [businessLevelFilter, setBusinessLevelFilter] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const {
    data: businessLevelData,
    isLoading: businessLevelOptionsLoading,
  } = useGetBusinessLevelOptions()

  const [modalState, setModalState] = useState<ModalState | null>(null)
  const activeWorkflowAvailabilityConfig = useReactiveVar(
    $activeWorkflowAvailabilityConfig,
  )
  const {
    pageSize,
    pageIndex,
    handlePageChange,
    setPageIndex,
  } = useOffsetPagination(DEFAULT_PAGE_SIZE)

  const { rows, pageInfo, loading } = useGetWorkflowAvailability({
    customerId: activeCustomerId,
    offset: pageIndex * pageSize,
    limit: pageSize,
    workflowId: workflowFilter || null,
    businessLevel: (businessLevelFilter as BusinessLevel) || null,
    searchTerm: searchTerm || null,
  })

  const { data: workflows, isLoading: workflowsLoading } = useGetAllWorkflows()

  const activeRow =
    activeWorkflowAvailabilityConfig !== null &&
    rows.find((row) => activeWorkflowAvailabilityConfig === row._id)

  const workflowOptions = useMemo(() => {
    return [
      { label: '--', value: '' },
      ...workflows.map((workflow) => ({
        label: `${workflow.databaseId} - ${workflow.workflowName}`,
        value: workflow.id,
      })),
    ]
  }, [workflows])

  const businessLevelOptions = useMemo(() => {
    return [{ label: '--', value: '' }, ...businessLevelData]
  }, [businessLevelData])

  useEffect(() => {
    const shouldSetEditState =
      activeWorkflowAvailabilityConfig !== null &&
      modalState !== ModalState.edit &&
      modalState !== ModalState.delete
    if (shouldSetEditState) {
      setModalState(ModalState.edit)
    }
  }, [activeWorkflowAvailabilityConfig, modalState, setModalState])

  const onSuccessfulSave = useCallback(async () => {
    setModalState(null)
    $activeWorkflowAvailabilityConfig(null)
  }, [setModalState])

  return (
    <>
      <WorkflowAvailabilityTable
        rows={rows}
        disableVirtualization={disableVirtualization}
        pageInfo={pageInfo}
        autoPageSize={false}
        isLoading={loading || workflowsLoading || businessLevelOptionsLoading}
        onPageChange={handlePageChange}
        onClickAddNew={() => setModalState(ModalState.add)}
        setupDetailsExpanded={setupDetailsExpanded}
        workflowFilters={workflowOptions}
        workflowFilter={workflowFilter}
        setWorkflowFilter={(value) => {
          setWorkflowFilter(value)
          setPageIndex(0)
        }}
        businessLevelFilters={businessLevelOptions}
        businessLevelFilter={businessLevelFilter}
        setBusinessLevelFilter={(value) => {
          setBusinessLevelFilter(value)
          setPageIndex(0)
        }}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      {modalState === ModalState.add && (
        <WorkflowAvailabilityAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeWorkflowAvailabilityConfig(null)
            setModalState(null)
          }}
          workflowOptions={workflowOptions}
          businessLevelOptions={businessLevelOptions}
          onSuccess={onSuccessfulSave}
        />
      )}
      {modalState === ModalState.edit && activeRow && (
        <WorkflowAvailabilityAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeWorkflowAvailabilityConfig(null)
            setModalState(null)
          }}
          workflowOptions={workflowOptions}
          businessLevelOptions={businessLevelOptions}
          onSuccess={onSuccessfulSave}
          onDelete={() => setModalState(ModalState.delete)}
          activeRow={activeRow}
        />
      )}
      {modalState === ModalState.delete && activeRow && (
        <WorkflowAvailabilityDeleteModal
          onClose={() => {
            $activeWorkflowAvailabilityConfig(null)
            setModalState(null)
          }}
          customerName={activeCustomer?.name}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
        />
      )}
    </>
  )
}
