import { Box } from '@mui/material'
import Button from 'app/components/Button/Button'
import CardTitle from 'app/components/CardTitle/CardTitle'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import TextField from 'app/components/TextField/TextField'
import { useRequestPasswordReset } from 'app/packages/core/api/login/mutations/useRequestPasswordReset'
import React, { useCallback, useState } from 'react'
import useIsMounted from 'shared/useIsMounted'
import styled from 'styled-components'

interface ResetPasswordSuccessProps {
  email: string
  onGoBack: () => void
}

const StyledButton = styled(Button)`
  font-weight: normal;
  padding: 0;
`

export function ResetPasswordSuccess(props: ResetPasswordSuccessProps) {
  const { onGoBack, email } = props
  return (
    <>
      <CardTitle>Check your email</CardTitle>
      <Box>
        We sent an email to {email} that contains a link to reset your password.
      </Box>
      <Box mt={4}>
        <StyledButton variant="tertiary" onClick={onGoBack}>
          Back to sign in
        </StyledButton>
      </Box>
    </>
  )
}

export function ResendInvitation() {
  const [email, setEmail] = useState('')
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [submitComplete, setSubmitComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const resetPassword = useRequestPasswordReset()
  const isMounted = useIsMounted()

  const submit = useCallback(async (): Promise<void> => {
    setSubmitInProgress(true)
    try {
      const result = await resetPassword({ variables: { email } })

      if (result.errors) {
        const errorCode = result.errors[0]?.extensions?.code
        if (
          errorCode === 'EMAIL_INVALID' ||
          errorCode === 'USER__DOES_NOT_EXIST'
        ) {
          setErrorMessage('Email address not found')
        } else {
          setErrorMessage('Something went wrong')
        }
      } else {
        setSubmitComplete(true)
      }
    } catch (e) {
      setErrorMessage('Something went wrong')
    } finally {
      if (isMounted()) {
        setSubmitInProgress(false)
      }
    }
  }, [email, isMounted, resetPassword])

  if (submitComplete) {
    return (
      <SingleCardPage width={480}>
        <CardTitle>Check your email</CardTitle>
        <Box>
          We sent an email to {email} with a link to reset your password, which
          will create your account.
        </Box>
      </SingleCardPage>
    )
  }

  return (
    <SingleCardPage width={480}>
      <CardTitle>Resend invitation</CardTitle>
      <Box>
        Enter your email address and we&apos;ll send you a new account
        invitation.
      </Box>
      <form
        onSubmit={(e) => {
          void submit() // eslint-disable-line no-void
          e.preventDefault()
        }}>
        <Box mt={4}>
          <TextField
            label="Email address"
            value={email}
            error={!!errorMessage}
            helperText={errorMessage}
            autoFocus
            fullWidth
            disabled={submitInProgress}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Button disabled={submitInProgress} onClick={submit}>
            Send link
          </Button>
        </Box>
      </form>
    </SingleCardPage>
  )
}

export interface SetPasswordProps {
  email: string
  onChangeEmail: () => void
}
