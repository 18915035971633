import { SignOutMutation, SignOutMutationVariables } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import CardTitle from 'app/components/CardTitle/CardTitle'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import { LOGIN_PATH } from 'app/packages/core/pages/login/login.constants'
import { useAuthState } from 'app/state/authentication'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

export const signOutGql = gql`
  mutation signOut($input: SignOutInput!) {
    signOut(input: $input) {
      userId
    }
  }
`

export default function Logout() {
  const authState = useAuthState()
  const tokens = authState.getTokens()
  const [signOut, { called }] = useMutation<
    SignOutMutation,
    SignOutMutationVariables
  >(signOutGql)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      if (tokens && !called) {
        await signOut({
          variables: { input: { refreshToken: tokens.refresh } },
        })
        // this will cause the app the rerender and show the login page:
        await authState.logout()
      }
    })()
  }, [authState, called, signOut, tokens])

  if (called && !authState._isAuthenticated) {
    return <Navigate to={LOGIN_PATH} replace />
  }

  return (
    <SingleCardPage width={480}>
      <CardTitle>Sign out</CardTitle>
      <Box>Signing out...</Box>
    </SingleCardPage>
  )
}
