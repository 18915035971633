import Button from 'app/components/Button/Button'
import React from 'react'
import styled from 'styled-components'

const StyledControls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
`

const StyledControlButton = styled(Button)`
  padding: 0;
`

export interface TopLevelControlsProps {
  numSelected: number
  onClear: () => void
  onSelectAll: () => void
  disableSelectAll: boolean
}

export default function TopLevelControls({
  numSelected,
  onClear,
  onSelectAll,
  disableSelectAll,
}: TopLevelControlsProps) {
  return (
    <StyledControls>
      <StyledControlButton
        onClick={onSelectAll}
        variant="tertiary"
        disabled={disableSelectAll}>
        Select all
      </StyledControlButton>
      <StyledControlButton onClick={onClear} variant="tertiary">
        {`Clear ${numSelected > 0 ? `(${numSelected})` : ''}`}
      </StyledControlButton>
    </StyledControls>
  )
}
