import Button from 'app/components/Button/Button'
import { DateRange } from 'app/components/DateNavigator/DateNavigator.types'
import ChevronLeft from 'app/components/Icons/ChevronLeft'
import ChevronRight from 'app/components/Icons/ChevronRight'
import React from 'react'
import styled from 'styled-components'

export interface DateNavigatorProps {
  dateRange: DateRange
  navigateForward(): void
  navigateBackward(): void
  navigateForwardAriaLabel?: string
  navigateBackwardAriaLabel?: string
}

const StyledDateNavigatorContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledDate = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  font-weight: bold;
  margin: 0 12px;
`

export function DateNavigator(props: DateNavigatorProps) {
  const {
    dateRange,
    navigateForward,
    navigateBackward,
    navigateForwardAriaLabel,
    navigateBackwardAriaLabel,
  } = props

  return (
    <StyledDateNavigatorContainer>
      <Button
        variant="icon"
        aria-label={navigateBackwardAriaLabel || 'Navigate Backwards'}
        onClick={navigateBackward}>
        <ChevronLeft />
      </Button>
      <StyledDate data-testid="date-navigator">{`${dateRange.startDate} - ${dateRange.endDate}`}</StyledDate>
      <Button
        variant="icon"
        aria-label={navigateForwardAriaLabel || 'Navigate Forwards'}
        onClick={navigateForward}>
        <ChevronRight />
      </Button>
    </StyledDateNavigatorContainer>
  )
}
