import { Tooltip } from '@mui/material'
import { Popover } from 'app/components/Popover/Popover'
import { isNilOrEmpty } from 'app/helpers'
import { getDaysDiff } from 'app/helpers/date'
import { LayoutContext } from 'app/layouts/Customer/CustomerPageLayout'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import useDateRangeSearchParam from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import OrderDeliveryPair from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/OrderAndDeliveryPair'
import { OrderDeliveryEventForm } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/OrderDeliveryEventForm'
import { CELL_DISPLAY_VALUE } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import {
  CalendarDay,
  Order,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { isEqual } from 'lodash-es'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'

const BORDER_BUFFER = 4
const CAUTION_ICON_WIDTH = 24
const ORDER_AND_DELIVERY_PAIR_HEIGHT = 50
const ICON_HEIGHT = 32
const ICON_WIDTH_DEFAULT = 32
const TOP_PADDING = (ORDER_AND_DELIVERY_PAIR_HEIGHT - ICON_HEIGHT) / 2

const CalendarDayCell = styled.div<{
  $isClosure: boolean
  $isThresholdDate: boolean
  $isEditing: boolean
  height: string
}>`
  height: ${(props) => props.height};
  width: 100%;
  background-color: ${({ $isClosure, theme }) =>
    $isClosure ? theme.colors.neutral_07 : theme.colors.neutral_08};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  position: relative;
  ${({ $isThresholdDate, $isEditing, theme }) =>
    $isThresholdDate &&
    $isEditing &&
    `
    ::after {
      border-right: 6px solid ${theme.colors.neutral_03};
      content: '';
      height: calc(100% + 50px);
      left: 1px;
      margin-top: -25px;
      position: absolute;
      top: -25px;
      width: 100%;
      z-index: 1; 
    }`};
`

const CalendarRow = styled.div`
  display: flex;
`

const ClosedLabelContainer = styled.div`
  height: ${ORDER_AND_DELIVERY_PAIR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
`

function getTop(
  parentTop: number,
  orderIndex: number,
  hasClosure: boolean,
): number {
  return hasClosure
    ? parentTop + (orderIndex + 1) * ORDER_AND_DELIVERY_PAIR_HEIGHT
    : parentTop + orderIndex * ORDER_AND_DELIVERY_PAIR_HEIGHT
}

function getLeft(
  orderDeliveryEvent: Order,
  startDate: string,
  dayWidth: number,
  parentLeft: number,
  isSameDay: boolean,
): number {
  const isOrderConflict = !isNilOrEmpty(orderDeliveryEvent.order.issueTypes)
  const maybeLessLeft =
    // eslint-disable-next-line no-nested-ternary
    isOrderConflict && isSameDay
      ? CAUTION_ICON_WIDTH
      : isOrderConflict
      ? CAUTION_ICON_WIDTH / 2
      : 0

  if (orderDeliveryEvent.order.date < startDate) {
    return parentLeft
  }

  const daysFromStartToOrder = getDaysDiff(
    orderDeliveryEvent.order.date,
    startDate,
  )

  return (
    parentLeft +
    daysFromStartToOrder * dayWidth +
    dayWidth / 2 -
    (ICON_WIDTH_DEFAULT + (isSameDay ? ICON_WIDTH_DEFAULT : 0)) / 2 -
    maybeLessLeft
  )
}

function getWidth(
  orderDeliveryEvent: Order,
  startDate: string,
  endDate: string,
  dayWidth: number,
  isSameDay: boolean,
): number {
  const isDeliveryConflict = !isNilOrEmpty(
    orderDeliveryEvent.delivery.issueTypes,
  )
  const isOrderConflict = !isNilOrEmpty(orderDeliveryEvent.order.issueTypes)
  const maybeMoreWidth =
    isDeliveryConflict || isOrderConflict ? CAUTION_ICON_WIDTH / 2 : 0

  if (orderDeliveryEvent.order.date < startDate) {
    const daysFromStartToDelivery = getDaysDiff(
      orderDeliveryEvent.delivery.date,
      startDate,
    )
    return (
      daysFromStartToDelivery * dayWidth +
      dayWidth / 2 +
      ICON_WIDTH_DEFAULT / 2 +
      BORDER_BUFFER +
      maybeMoreWidth
    )
  }

  if (orderDeliveryEvent.delivery.date > endDate) {
    const daysFromOrderToEnd = getDaysDiff(
      endDate,
      orderDeliveryEvent.order.date,
    )
    return (
      daysFromOrderToEnd * dayWidth +
      dayWidth / 2 +
      ICON_WIDTH_DEFAULT / 2 +
      maybeMoreWidth
    )
  }

  if (isSameDay) {
    if (isDeliveryConflict || isOrderConflict) {
      return ICON_WIDTH_DEFAULT * 2 + CAUTION_ICON_WIDTH * 2
    }
    return ICON_WIDTH_DEFAULT * 2
  }

  return (
    getDaysDiff(
      orderDeliveryEvent.delivery.date,
      orderDeliveryEvent.order.date,
    ) *
      dayWidth +
    ICON_WIDTH_DEFAULT +
    BORDER_BUFFER +
    maybeMoreWidth
  )
}

export interface StoreCalendarProps {
  activeOrder: Order | null
  itemHeight: number
  onChangeActiveOrder: (order: Order | null) => void
  orders: Order[]
  schedule: CalendarDay[]
  storeId: string
  storeName: string
}

export function StoreCalendar(props: StoreCalendarProps) {
  const {
    activeOrder,
    itemHeight,
    schedule,
    storeId,
    storeName,
    onChangeActiveOrder,
    orders,
  } = props
  const CalendarRef = useRef<HTMLDivElement>(null)

  const [dayWidth, setDayWidth] = useState(0)
  const [parentTop, setParentTop] = useState(0)
  const [parentLeft, setParentLeft] = useState(0)

  const { sideNavOpen, openTransitioning, closeTransitioning } = useContext(
    LayoutContext,
  )

  const { editingCutoffDate, isEditing } = useGetOrderDeliverySchedule()
  const [{ startDate, endDate }] = useDateRangeSearchParam()

  // If there are any closures, we should scoot all of the order-delivery
  // pairs down a row to make room for the closure label.
  const hasClosure = schedule.some((day) => day.isClosed)

  const updateParentSize = useCallback(() => {
    if (CalendarRef.current) {
      setDayWidth(CalendarRef.current.offsetWidth / 7)
      setParentTop(CalendarRef.current.offsetTop + TOP_PADDING)
      setParentLeft(CalendarRef.current.offsetLeft)
    } else {
      setDayWidth(0)
    }
  }, [CalendarRef])

  useEffect(() => {
    updateParentSize()

    window.addEventListener('resize', updateParentSize)

    return () => {
      window.removeEventListener('resize', updateParentSize)
    }
  }, [updateParentSize])

  useEffect(() => {
    if (!openTransitioning && !closeTransitioning) {
      updateParentSize()
    }
  }, [updateParentSize, sideNavOpen, openTransitioning, closeTransitioning])

  return (
    <CalendarRow ref={CalendarRef}>
      {schedule.map((day: CalendarDay) => {
        return (
          <CalendarDayCell
            height={`${itemHeight}px`}
            $isClosure={day.isClosed}
            $isThresholdDate={day.date === editingCutoffDate}
            $isEditing={isEditing}
            key={`${storeId}_${day.date}`}>
            {day.isClosed && (
              <ClosedLabelContainer>
                {CELL_DISPLAY_VALUE.CLOSED}
              </ClosedLabelContainer>
            )}
          </CalendarDayCell>
        )
      })}
      {CalendarRef.current &&
        orders.map((orderDeliveryEvent, orderIndex) => {
          const isSameDay = isEqual(
            orderDeliveryEvent.order.date,
            orderDeliveryEvent.delivery.date,
          )
          const top = getTop(parentTop, orderIndex, hasClosure)
          const left = getLeft(
            orderDeliveryEvent,
            startDate,
            dayWidth,
            parentLeft,
            isSameDay,
          )
          const width = getWidth(
            orderDeliveryEvent,
            startDate,
            endDate,
            dayWidth,
            isSameDay,
          )

          const isActive = activeOrder?.id === orderDeliveryEvent.id

          const tooltipTitle =
            isEditing &&
            !orderDeliveryEvent.order.isEditable &&
            !orderDeliveryEvent.delivery.isEditable
              ? 'This order and delivery pair is no longer editable'
              : ''
          return (
            <Popover
              anchorPlacement="bottom"
              key={orderDeliveryEvent.id}
              handleOnCloseCallback={() => {
                onChangeActiveOrder(null)
              }}
              renderAnchor={({ onClick }) => (
                <Tooltip arrow placement="bottom-start" title={tooltipTitle}>
                  <OrderDeliveryPair
                    isActive={isActive}
                    isEditing={isEditing}
                    isSameDay={isSameDay}
                    dateRange={{ startDate, endDate }}
                    onChangeActiveOrder={onChangeActiveOrder}
                    onClick={onClick}
                    orderDeliveryEvent={orderDeliveryEvent}
                    position={{ left, top, width }}
                  />
                </Tooltip>
              )}
              withOverlay>
              <OrderDeliveryEventForm
                orderDeliveryEvent={orderDeliveryEvent}
                storeId={storeId}
                storeName={storeName}
              />
            </Popover>
          )
        })}
    </CalendarRow>
  )
}
