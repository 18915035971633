import { useMemo } from 'react'

export default function useGetScrollbarWidth() {
  return useMemo(() => {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.width = '100px'
    document.body.appendChild(outer)

    const widthNoScroll = outer.offsetWidth
    outer.style.overflow = 'scroll'

    const inner = document.createElement('div')
    inner.style.width = '100%'
    outer.appendChild(inner)

    const widthWithScroll = inner.offsetWidth
    if (outer.parentNode) {
      outer.parentNode.removeChild(outer)
    }

    return widthNoScroll - widthWithScroll
  }, [])
}
