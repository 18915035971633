import { StoreFieldsFragment } from '@api/__gen__/gql'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'

export function toStore(store: StoreFieldsFragment): Store {
  return {
    id: store._id,
    name: store.name.includes(store.customerStoreId)
      ? store.name
      : `${store.customerStoreId} - ${store.name}`,
  }
}
