import { datadogRum } from '@datadog/browser-rum'
import { LicenseInfo } from '@mui/x-license'
import * as Sentry from '@sentry/react'
import App from 'app/App'
import { CustomerContextWrapper } from 'app/CustomerContext'
import routerConfig from 'app/RouterConfig'
import { Vars, VarsContext } from 'app/VarsContext'
import { DebugMenu } from 'app/components/DebugMenu/DebugMenu'
import { RolloutProvider } from 'app/context/rolloutContext'
import { UserContextProvider } from 'app/context/userContext'
import internalError from 'app/internalError'
import React from 'react'
import 'react-app-polyfill/ie11'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter } from 'react-router-dom'

const vars: Vars = JSON.parse(
  document.getElementById('context')?.textContent || '',
)

if (vars.environment !== 'development') {
  Sentry.init({
    dsn: vars.sentryDsn,
    environment: vars.environment,
    // don't change this, we associate this with the
    //  autogenerated sourceMaps
    release: vars.buildHash,
  })

  Sentry.configureScope((scope) => {
    scope.setExtra('actor', 'web-client')
    scope.setTag('client_version', vars.appVersion)
  })

  datadogRum.init({
    applicationId: '30f52246-44d1-4a56-8539-a444596db612',
    clientToken: 'pub7b77fd7869ab1860e16ec09b933a92ea',
    site: 'datadoghq.com',
    service: 'afresh-web-portal',
    env: vars.environment,
    version: vars.appVersion,
    sampleRate: 100,
    premiumSampleRate: 0,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}

const reportErrorToDatadog = (error: Error) => {
  datadogRum.addError(error)
}

// License key is designed to be public: https://mui.com/x/introduction/licensing/#license-key-security
LicenseInfo.setLicenseKey(
  '2451f8842af67cb84ac15bd5560fe1dfTz05NjkyMyxFPTE3NTgyNDAwMDAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=',
)

const router = createBrowserRouter(routerConfig)

const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  // Sentry error boundary will catch our errors first, but we send them to datadog RUM, too
  <Sentry.ErrorBoundary fallback={internalError} onError={reportErrorToDatadog}>
    <VarsContext.Provider value={vars}>
      <UserContextProvider>
        <CustomerContextWrapper>
          <RolloutProvider>
            <App router={router} />
            <DebugMenu />
          </RolloutProvider>
        </CustomerContextWrapper>
      </UserContextProvider>
    </VarsContext.Provider>
  </Sentry.ErrorBoundary>,
)
