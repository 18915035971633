import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium'
import { formatDate } from 'app/helpers/date'
import useGetBusinessLevelConfigs, {
  BusinessLevelConfigs,
} from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/api/queries/useGetBusinessLevels'
import EditDisplayNameModal from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/pages/EditDisplayNameModal'
import { StyledEmptyState } from 'app/packages/internal/customerConfigs/styles'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledTable = styled.div`
  padding-top: ${toRem(12)};
  height: calc(100vh - 310px);
  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }
`

const columns: GridColDef[] = [
  {
    field: 'businessLevel',
    headerName: 'Afresh Business Level',
    minWidth: 180,
    valueGetter: (_, row) => row.businessLevel.label,
  },
  {
    field: 'displayName',
    valueGetter: (_, row) => row.displayName.label,
    headerName: 'Business Level Display Name',
    minWidth: 216,
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    renderCell: ({ row }) =>
      row.createdAt.label ? formatDate(row.createdAt.label) : '',
    valueGetter: (_, row) => row.createdAt.label,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated At',
    renderCell: ({ row }) =>
      row.createdAt.label ? formatDate(row.createdAt.label) : '',
    valueGetter: (_, row) => row.updatedAt.label,
  },
  {
    field: 'updatedBy',
    headerName: 'Updated By',
    valueGetter: (_, row) => row.updatedBy.label,
  },
]

export default function BusinessLevelDisplayNamesTable() {
  const { data, loading } = useGetBusinessLevelConfigs()
  const [activeRow, setActiveRow] = useState<BusinessLevelConfigs | null>(null)

  return (
    <>
      <StyledTable>
        <DataGridPremium
          columns={columns}
          disableAggregation
          disableRowGrouping
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          getRowId={(row) => row.id}
          loading={loading}
          onRowClick={({ row }) => setActiveRow(row)}
          rows={data}
          slots={{
            noRowsOverlay: () => (
              <StyledEmptyState>
                No config found for this customer
              </StyledEmptyState>
            ),
          }}
        />
      </StyledTable>

      {activeRow && (
        <EditDisplayNameModal
          onClose={() => setActiveRow(null)}
          row={activeRow}
        />
      )}
    </>
  )
}
