import {
  SignInMutation,
  SignInMutationVariables,
  UserDetailsFragment,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import * as Sentry from '@sentry/react'
import { USER_DETAILS } from 'app/api/fragments/UserDetails'
import Button from 'app/components/Button/Button'
import CardTitle from 'app/components/CardTitle/CardTitle'
import TextField from 'app/components/TextField/TextField'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useIsMounted from 'shared/useIsMounted'
import styled from 'styled-components'

export const signInGql = gql`
  ${USER_DETAILS}
  mutation signIn($email: String!, $password: String!) {
    signIn(input: { email: $email, password: $password }) {
      accessToken
      refreshToken
      user2 {
        ...UserDetails
      }
    }
  }
`

interface Props {
  email: string
  onAuthenticate: (
    accessToken: string,
    refreshToken: string,
    user: UserDetailsFragment | null,
  ) => unknown
  onChangeEmail: () => unknown
}

const StyledButton = styled(Button)`
  font-weight: normal;
  padding: 0;
`

export default function Password(props: Props) {
  const { onAuthenticate } = props
  const navigate = useNavigate()
  const isMounted = useIsMounted()
  const signIn = useMutation<SignInMutation, SignInMutationVariables>(
    signInGql,
  )[0]
  const [password, setPassword] = useState('')
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [authError, setAuthError] = useState<string | null>(null)

  const submit = useCallback(
    async (email: string, pw: string) => {
      setSubmitInProgress(true)
      const result = await signIn({ variables: { email, password: pw } })
      if (result?.data?.signIn) {
        await Promise.resolve(
          onAuthenticate(
            result?.data.signIn.accessToken || '',
            result?.data.signIn.refreshToken || '',
            result?.data.signIn.user2 || null,
          ),
        )
      } else if (result?.errors?.[0].extensions?.code === 'INVALID_PASSWORD') {
        setAuthError('Incorrect password.')
      } else {
        Sentry.captureMessage('unexpected password login failure', {
          user: { email },
          level: 'error',
          extra: {
            gqlErrors: JSON.stringify(result?.errors),
          },
        })
        setAuthError('An error occurred.')
      }
      if (isMounted()) {
        setSubmitInProgress(false)
      }
    },
    [isMounted, onAuthenticate, signIn],
  )

  return (
    <>
      <CardTitle>Sign in</CardTitle>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          await submit(props.email, password)
        }}>
        <Box fontSize={16} mb={3} mt={-1}>
          {props.email}
        </Box>
        <Box>
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={password}
            error={!!authError}
            helperText={authError || undefined}
            disabled={submitInProgress}
            autoFocus
            onChange={(e) => {
              setAuthError(null)
              setPassword(e.target.value)
            }}
          />
        </Box>
        <Box mt={2}>
          <Button type="submit" disabled={!password || submitInProgress}>
            Sign in
          </Button>
        </Box>
        <Box mt={4}>
          <StyledButton
            variant="tertiary"
            href="/forgot-password"
            onClick={(e) => {
              e.preventDefault()
              navigate(
                `/forgot-password?email=${encodeURIComponent(props.email)}`,
              )
            }}>
            Forgot password
          </StyledButton>{' '}
          ⸱{' '}
          <StyledButton variant="tertiary" onClick={props.onChangeEmail}>
            Sign in with a different email
          </StyledButton>
        </Box>
      </form>
    </>
  )
}
