import PasswordReset, {
  Confirmation,
} from 'app/components/PasswordReset/PasswordReset'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

export default function ResetPassword() {
  const [done, setDone] = useState(false)
  const params = useParams()
  const token = params.token ?? ''

  return (
    <SingleCardPage width={480}>
      {!done ? (
        <PasswordReset token={token} onSuccess={() => setDone(true)} />
      ) : (
        <Confirmation
          title="Password Saved"
          body="Your new password has been saved. Please return to the app to log in."
        />
      )}
    </SingleCardPage>
  )
}
