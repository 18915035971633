import { DynamicVirtualizedRowProps } from 'app/components/VirtualizedList/Dynamic'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { StoreClosure } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import Table, {
  TableProps,
} from 'app/packages/storeSolutions/schedules/pages/Table/Table'
import React, { CSSProperties, useCallback } from 'react'
import { Row } from 'react-table'
import styled from 'styled-components'
import useGetTableColumns from './useGetTableColumns.hook'

const StyledRowItem = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  display: flex;
  min-height: 50px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledRowItemCell = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  padding: 0 16px;
  width: 100%;
`

const StyledTable = styled(Table)`
  & > div[class^='Table__StyledTableHeaderRow'] > :first-child {
    flex: unset;
    padding-left: 16px;
  }

  & div[class^='Table__StyledTableHeaderRowGroup'] {
    justify-content: flex-start;
    padding: 0 16px 0 4px;
  }
` as <T extends DynamicVirtualizedRowProps>(
  props: TableProps<T>,
) => React.ReactElement

export interface StoreClosuresTableBodyProps {
  data: StoreClosure[]
  isLoading: boolean
  onEdit: (closure: StoreClosure) => void
  onDelete: (closure: StoreClosure) => void
}

export default function StoreClosuresTableBody(
  props: StoreClosuresTableBodyProps,
) {
  const { data, isLoading, onEdit, onDelete } = props

  const { isEditing } = useGetOrderDeliverySchedule()
  const columns = useGetTableColumns(onEdit, onDelete)

  const RowItem = useCallback(
    (row: Row<StoreClosure>, style: CSSProperties) => {
      if (row.original.id === 'divider') {
        return (
          <StyledRowItem {...row.getRowProps({ style })}>
            <StyledRowItemCell>Past Closures</StyledRowItemCell>
            <StyledRowItemCell />
            <StyledRowItemCell />
            {isEditing && <StyledRowItemCell />}
          </StyledRowItem>
        )
      }

      return (
        <StyledRowItem {...row.getRowProps({ style })}>
          {row.cells.map((cell) => {
            return (
              <StyledRowItemCell key={`${row.id}_${cell.column.id}`}>
                {cell.render('Cell')}
              </StyledRowItemCell>
            )
          })}
        </StyledRowItem>
      )
    },
    [isEditing],
  )

  const stickyIndex = data.findIndex(({ id }) => id === 'divider')

  return (
    <StyledTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      renderRow={RowItem}
      stickyIndices={stickyIndex > -1 ? [stickyIndex] : []}
      type="STICKY"
    />
  )
}
