import { gql } from '@apollo/client'

export const ENDING_INVENTORIES_FRAGMENTS = gql`
  fragment EndingInventoryData on EndingInventory {
    id
    status
    totalCost
    isActive
    isAdjustmentsEnabled
    accountingPeriod {
      id
      startDate
      endDate
      periodNumber
      periodYearName
    }
    workflow {
      id
      departmentBundle {
        id
        name
      }
    }
    store {
      id
      regionName
      businessUnitName
    }
  }
`

export const ACCOUNTING_PERIOD_FRAGMENTS = gql`
  fragment AccountingPeriodData on AccountingPeriod {
    id
    periodNumber
    periodYearName
    endDate
    previousPeriod {
      id
      periodNumber
      periodYearName
    }
  }
`
