import {
  GetGuideStructureCategoriesQuery,
  GetGuideStructureCategoriesQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { isNilOrEmpty } from 'app/helpers'
import { useDepartmentBundleId } from 'app/hooks/useDepartmentBundleId'
import { $activeBusinessLevel } from 'app/layouts/Customer/CustomerPageLayout.variables'
import { GUIDE_STRUCTURE_CATEGORY_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureCategoryFields'
import {
  GuideStructureCategory,
  GuideStructurePaginationArgs,
  InventoryInputUnitEnum,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import { isNil } from 'lodash-es'
import segment from 'src/__generated__/segment'

export const DEFAULT_PAGINATION_MAX_LIMIT = 1000

export const GET_GUIDE_STRUCTURE_CATEGORIES = gql`
  ${GUIDE_STRUCTURE_CATEGORY_FIELDS}
  query getGuideStructureCategories(
    $customerId: ID!
    $departmentBundleId: ID!
    $businessLevelInstanceId: ID!
    $category: ID
    $searchTerm: String
    $limit: Int
    $hasBeenEdited: Boolean
  ) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        guideStructure(
          departmentBundleId: $departmentBundleId
          businessLevelInstanceId: $businessLevelInstanceId
        ) {
          _id
          categories(
            category: $category
            hasBeenEdited: $hasBeenEdited
            limit: $limit
            searchTerm: $searchTerm
          ) {
            edges {
              node {
                ...GuideStructureCategoryFields
              }
            }
            maxSortIndex
            minSortIndex
            totalCount
          }
        }
      }
    }
  }
`

function getGuideStructureFromResult(
  data: GetGuideStructureCategoriesQuery | undefined,
) {
  if (data?.node?.__typename === 'Customer') {
    return data?.node?.guideStructure ?? null
  }

  return null
}

function transformQueryData(
  data?: GetGuideStructureCategoriesQuery,
): {
  categories: GuideStructureCategory[]
  maxSortIndex: number
  minSortIndex: number
} {
  const guideStructure = getGuideStructureFromResult(data)

  if (guideStructure && !isNilOrEmpty(guideStructure.categories)) {
    const { categories } = guideStructure

    const transformedCategories = categories.edges.map(({ node }) => {
      return {
        hasBeenEdited: node?.hasBeenEdited ?? false,
        isEmpty: node?.isEmpty ?? false,
        id: node._id,
        itemCategory: {
          id: node.itemCategory._id,
          databaseId: node.itemCategory.databaseId,
          name: node.itemCategory.name,
          parentId: node.itemCategory.parentId || null,
          sortIndex: node.itemCategory.sortIndex,
        },
        primaryMappings: node.primaryMappings.map(({ _id, name }) => ({
          _id,
          name,
        })),
        orderingConfiguration: node.orderingConfiguration
          ? {
              autoInventoryEnabled:
                node.orderingConfiguration.autoInventoryEnabled,
              backInventoryRequired:
                node.orderingConfiguration.backInventoryRequired,
              floorInventoryRequired:
                node.orderingConfiguration.floorInventoryRequired,
              floorInventoryUnits: node.orderingConfiguration
                .floorInventoryUnits as InventoryInputUnitEnum,
            }
          : undefined,
        inventoryConfiguration: node.inventoryConfiguration
          ? {
              backInventoryUnits: node.inventoryConfiguration
                .backInventoryUnits as InventoryInputUnitEnum,
              floorInventoryRequired:
                node.inventoryConfiguration.floorInventoryRequired,
              floorInventoryUnits: node.inventoryConfiguration
                .floorInventoryUnits as InventoryInputUnitEnum,
            }
          : undefined,
      }
    })

    return {
      categories: transformedCategories,
      maxSortIndex: categories.maxSortIndex,
      minSortIndex: categories.minSortIndex,
    }
  }

  return { categories: [], maxSortIndex: 1, minSortIndex: 0 }
}

export default function useGetCategories(
  args?: GuideStructurePaginationArgs | null,
) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { activeCustomerId } = useCustomers()
  const [departmentBundleId] = useDepartmentBundleId()
  const businessLevelInstanceId = useReactiveVar($activeBusinessLevel)

  const { data, error, loading } = useQuery<
    GetGuideStructureCategoriesQuery,
    GetGuideStructureCategoriesQueryVariables
  >(GET_GUIDE_STRUCTURE_CATEGORIES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (queryData: GetGuideStructureCategoriesQuery) => {
      const guideStructure = getGuideStructureFromResult(queryData)
      const totalCount = guideStructure?.categories?.totalCount ?? 0

      if (
        totalCount >= DEFAULT_PAGINATION_MAX_LIMIT - 100 &&
        totalCount <= DEFAULT_PAGINATION_MAX_LIMIT
      ) {
        Sentry.captureMessage(
          `Category total count for ${activeCustomerId} is approaching the max limit of ${DEFAULT_PAGINATION_MAX_LIMIT} records`,
          {
            level: 'warning',
          },
        )
      }

      if (totalCount > DEFAULT_PAGINATION_MAX_LIMIT) {
        Sentry.captureMessage(
          `Category total count for ${activeCustomerId} is greater than the limit of ${DEFAULT_PAGINATION_MAX_LIMIT} records`,
          {
            level: 'warning',
          },
        )
      }

      if (args?.searchTerm || args?.category) {
        segment.guideOrganizationSectionsTableFiltered({
          searchQuery: args?.searchTerm ?? null,
          selectedCategoryId: args?.category ?? null,
          selectedCategoryName:
            // This is kind of a hack to get the category name for the selected category
            args?.category
              ? guideStructure?.categories?.edges[0]?.node.itemCategory.name
              : null,
          ...defaultProperties,
        })
      }
    },
    onError: (err) => {
      Sentry.captureMessage(
        `Failed to fetch guide categories for ${activeCustomerId}: ${err}`,
        {
          level: 'warning',
          extra: {
            departmentBundleId,
            businessLevelInstanceId,
            args,
            stackTrace: err.stack,
            graphQLErrors: err.graphQLErrors,
          },
        },
      )
    },
    skip:
      isNil(activeCustomerId) ||
      !departmentBundleId ||
      isNil(businessLevelInstanceId),
    variables: {
      ...(args ?? {}),
      customerId: activeCustomerId!,
      departmentBundleId,
      businessLevelInstanceId: businessLevelInstanceId!,
      limit: DEFAULT_PAGINATION_MAX_LIMIT,
    },
  })

  return {
    ...transformQueryData(data),
    error,
    loading,
  }
}
