import { BusinessLevel } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const SET_PRODDUCT_BUNDLE_PRIMARY_BUSINESS_LEVEL = gql`
  mutation upsertProductBundleProperties(
    $input: [UpsertProductBundlePropertiesInput!]!
  ) {
    upsertProductBundleProperties(input: $input) {
      productBundleProperties {
        productBundleId
        primaryBusinessLevel
      }
    }
  }
`

export default function useSetProductBundlePrimaryBusinessLevel() {
  const [mutation, result] = useMutation(
    SET_PRODDUCT_BUNDLE_PRIMARY_BUSINESS_LEVEL,
    {
      onError: ({ graphQLErrors }) => {
        throw new Error(getFirstErrorCode(graphQLErrors))
      },
    },
  )

  return tuple([
    useCallback(
      async ({
        productBundleId,
        primaryBusinessLevel,
      }: {
        productBundleId: string
        primaryBusinessLevel: BusinessLevel
      }) => {
        await mutation({
          variables: {
            input: [
              {
                productBundleId,
                primaryBusinessLevel,
              },
            ],
          },
        })
      },
      [mutation],
    ),
    result,
  ])
}
