import Button from 'app/components/Button/Button'
import Delete from 'app/components/Icons/Delete'
import Edit from 'app/components/Icons/Edit'
import { Popover } from 'app/components/Popover/Popover'
import { EM_DASH } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import { formatDatetime } from 'app/helpers/date'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { StoreClosure } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useMemo } from 'react'
import { Column, Row } from 'react-table'
import styled from 'styled-components'

const StoreClosureTypes: { [key: string]: string } = {
  ANOMALOUS: 'One-off',
  HOLIDAY: 'Holiday',
}

const StyledStoreButton = styled(Button)<{ $isPast: boolean }>`
  padding: 0;
`

const StyledStoresList = styled.div`
  padding: 20px;
  max-height: 200px;
  overflow-y: auto;
`

const StyledButtonActions = styled.div`
  align-items: center;
  column-gap: 32px;
  display: flex;
`

const BASE_COLUMNS: Column<StoreClosure>[] = [
  {
    accessor: 'closureDate',
    Cell: ({ value }) => {
      return (
        <>
          {formatDatetime(value, {
            format: 'ddd, YYYY-MM-DD',
          })}
        </>
      )
    },
    Header: 'Closure Date',
  },
  {
    accessor: 'closureType',
    Cell: ({ value }) => {
      return <>{StoreClosureTypes[value] ?? EM_DASH}</>
    },
    Header: 'Closure Type',
  },
  {
    accessor: 'stores',
    Cell: ({ row, value }) => {
      if (isNilOrEmpty(value)) {
        return <>All stores</>
      }

      if (value!.length === 1) {
        return <>{value![0]?.name ?? EM_DASH}</>
      } else {
        return (
          <Popover
            renderAnchor={(params) => (
              <StyledStoreButton
                $isPast={!row.original.isEditable}
                variant="tertiary"
                onClick={params.onClick}>
                {value!.length} stores
              </StyledStoreButton>
            )}>
            <StyledStoresList>
              {value!.map((store) => (
                <div key={store.id}>{store.name}</div>
              ))}
            </StyledStoresList>
          </Popover>
        )
      }
    },
    Header: 'Stores',
  },
]

export default function useGetTableColumns(
  onEdit: (closure: StoreClosure) => void,
  onDelete: (closure: StoreClosure) => void,
): Column<StoreClosure>[] {
  const { isEditing } = useGetOrderDeliverySchedule()

  return useMemo(() => {
    if (!isEditing) {
      return BASE_COLUMNS
    }

    return [
      ...BASE_COLUMNS,
      {
        id: 'rowActions',
        Cell: ({ row }: { row: Row<StoreClosure> }) => {
          if (!row.original.isEditable) {
            return <></>
          }

          return (
            <StyledButtonActions>
              <Button
                variant="tertiary"
                startIcon={<Edit />}
                onClick={() => onEdit(row.original)}>
                Edit
              </Button>
              <Button
                variant="tertiary"
                startIcon={<Delete />}
                onClick={() => onDelete(row.original)}>
                Delete
              </Button>
            </StyledButtonActions>
          )
        },
      },
    ]
  }, [isEditing, onDelete, onEdit])
}
