import Accordion from 'app/components/Accordion/Accordion'
import CardContainer from 'app/components/CardContainer/CardContainer'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import useToggleState from 'app/hooks/useToggleState'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import CustomerSetupTable from 'app/packages/internal/customerSetup/pages/CustomerSetupTable'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`

export default function CustomerSetup() {
  const [open, { toggle }] = useToggleState(true)

  return (
    <CardContainer>
      <StyledHeader>Customer Setup</StyledHeader>
      <Accordion
        header="Instructions"
        expanded={open}
        onChangeExpanded={toggle}>
        <StyledSubheader>
          <p>
            Before creating a customer, we must have a schema created in RDB
            that matches the naming format of &#123;customerKey&#125;_db.
            <br />
            Customer creation will fail if this is not done.
            <br />
            <br />
            To create a schema, check out these instructions:
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/afresh/RDB-Create-customer-_db-schema-e4583c1969314ba585865e22e933615a?pvs=4',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <CustomerSetupTable setupDetailsExpanded={open} />
    </CardContainer>
  )
}
