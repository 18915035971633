import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import Button from 'app/components/Button/Button'
import { getTheme } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`

const StyledErrorMessage = styled.div`
  flex: 1;
  z-index: 1;
  padding-top: 10vh;
  width: 60vw;
  font-size: ${({ theme }) => theme.fontSizes.lg};
`

const StyledError = styled.p`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  letter-spacing: -3px;
`

const StyledBox = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.neutral_08};
  border-radius: 20px;
  margin: 30px 0;
  min-width: 60vw;
  min-height: 40vh;

  & hr {
    border-style: solid;
    color: ${({ theme }) => theme.colors.neutral_05};
  }
`

const StyledButton = styled(Button)`
  margin-right: 20px;
  & .MuiButton-label {
    margin-top: 3px;
  }
`

export default function internalError({ error, resetError }) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme('light')}>
        <StyledContainer>
          <StyledErrorMessage>
            <StyledError>Oops :(</StyledError>
            <StyledBox>
              <p>{error.toString()}</p>
            </StyledBox>
            <StyledButton
              variant="primary"
              color="primary"
              onClick={() => resetError()}>
              Try Again
            </StyledButton>
            <StyledButton
              variant="primary"
              color="primary"
              onClick={() => window.location.replace('/dashboard')}>
              Go to Dashboard
            </StyledButton>
          </StyledErrorMessage>
        </StyledContainer>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
