import {
  OffsetPageInfo,
  ResolvedWorkflowAvailability,
  Workflow,
} from '@api/__gen__/gql'
import { ArrowRight } from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRowParams,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import CaretDown from 'app/components/Icons/CaretDown'
import SearchBox from 'app/components/SearchBox/SearchBox'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TableCellTooltip from 'app/components/Tooltip/TableCellTooltip'
import { PAGE_SIZE_OPTIONS } from 'app/constants'
import { StoreWorkflowAvailabilityRow } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetStoreWorkflowsAvailability'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import { sortBy } from 'lodash-es'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 320px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 535px)' : 'calc(100vh - 320px)'};

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

const StyledFilters = styled.div`
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  column-gap: 12px;
  display: flex;
  padding: 12px 20px;
`

const StyledMuiTableContainer = styled(TableContainer)``

const StyledTableHead = styled(TableHead)`
  background-color: ${({ theme }) => theme.colors.neutral_07};
  padding-left: 16px;
`

const StyledTableHeadCell = styled(TableCell)`
  font-weight: bold;
`

export interface Props {
  isLoading?: boolean
  autoPageSize: boolean
  disableVirtualization?: boolean
  rows: StoreWorkflowAvailabilityRow[]
  pageInfo?: OffsetPageInfo
  setupDetailsExpanded: boolean
  onPageChange: (pageIndex: number, pageSize: number) => void
  workflowFilters: Option[] | null
  workflowFilter: string
  setWorkflowFilter: (value: string) => void
  departmentBundleFilters: Option[] | null
  departmentBundleFilter: string
  setDepartmentBundleFilter: (value: string) => void
  searchTerm: string
  setSearchTerm: (value: string) => void
}

export const WorkflowAvailabilityColumns: GridColDef[] = [
  {
    field: 'store_id',
    valueGetter: (_, row) => row.store.id,
    headerName: 'Store ID',
    width: 100,
    sortable: false,
  },
  {
    field: 'name',
    valueGetter: (_, row) => row.store.name,
    headerName: 'Store Name',
    width: 182,
    sortable: false,
  },
  {
    field: 'region',
    valueGetter: (_, row) => row.store.regionName,
    headerName: 'Region',
    width: 182,
    sortable: false,
  },
  {
    field: 'businessUnit',
    valueGetter: (_, row) => row.store.businessUnitName,
    headerName: 'Business Unit',
    width: 182,
    sortable: false,
  },
  {
    field: 'workflows',
    valueGetter: (_, row) => row.resolvedWorkflowAvailabilities,
    renderCell: (row) => {
      const workflows = row.value

      return (
        <TableCellTooltip
          title={workflows.map((workflow: { workflow: Workflow }) => {
            return (
              <>
                <span>
                  {workflow.workflow.name} ({workflow.workflow.id})
                </span>
                <br />
              </>
            )
          })}>
          <span
            style={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {workflows
              .map((workflow: { workflow: Workflow }) => {
                return `${workflow.workflow.name} (${workflow.workflow.id})`
              })
              .join(', ')}
          </span>
        </TableCellTooltip>
      )
    },
    headerName: 'Workflows',
    width: 600,
    sortable: false,
  },
]

export default function WorkflowAvailabilityTable(props: Props) {
  const {
    isLoading,
    rows,
    setupDetailsExpanded,
    pageInfo,
    onPageChange,
    disableVirtualization = false,
    workflowFilters,
    workflowFilter,
    setWorkflowFilter,
    departmentBundleFilters,
    departmentBundleFilter,
    setDepartmentBundleFilter,
    searchTerm,
    setSearchTerm,
    autoPageSize,
  } = props

  const columns = WorkflowAvailabilityColumns.filter((column) => {
    if (column.field === 'region') {
      return rows.some((row) => row.store.regionName)
    }
    if (column.field === 'businessUnit') {
      return rows.some((row) => row.store.businessUnitName)
    }
    return true
  })

  const getWorkflowPanelContent = React.useMemo(
    () => (params: GridRowParams) => {
      return (
        <StyledMuiTableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <StyledTableHead>
              <TableRow>
                <StyledTableHeadCell
                  style={{
                    paddingLeft: '76px',
                    width: '100px',
                  }}>
                  ID
                </StyledTableHeadCell>
                <StyledTableHeadCell
                  style={{
                    width: '160px',
                  }}>
                  Workflow Name
                </StyledTableHeadCell>
                <StyledTableHeadCell>Department Bundle</StyledTableHeadCell>
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {sortBy(params.row.resolvedWorkflowAvailabilities, [
                'workflow.departmentBundle.name',
                'workflow.id',
              ]).map((workflowAvailability: ResolvedWorkflowAvailability) => (
                <TableRow>
                  <TableCell
                    style={{
                      paddingLeft: '76px',
                      width: '100px',
                    }}>
                    {workflowAvailability.workflow.id}
                  </TableCell>
                  <TableCell
                    style={{
                      width: '160px',
                    }}>
                    {workflowAvailability.workflow.name}
                  </TableCell>
                  <TableCell>
                    {workflowAvailability.workflow.departmentBundle.name}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledMuiTableContainer>
      )
    },
    [],
  )

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <StyledFilters>
        <SearchBox
          placeholderText="Search for store"
          defaultValue={searchTerm}
          onSearchTextChange={(term) => setSearchTerm(term)}
        />
        <SingleSelect
          options={workflowFilters || []}
          value={workflowFilter}
          label="Workflow"
          handleChange={(e) => setWorkflowFilter(e.target.value)}
        />
        <SingleSelect
          options={departmentBundleFilters || []}
          value={departmentBundleFilter}
          label="Department Bundle"
          handleChange={(e) => setDepartmentBundleFilter(e.target.value)}
        />
      </StyledFilters>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row.store.id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
          detailPanelExpandIcon: ArrowRight,
          detailPanelCollapseIcon: CaretDown,
        }}
        paginationModel={{
          pageSize: pageInfo?.pageSize ?? DEFAULT_PAGE_SIZE,
          page: pageInfo?.currentPage ? pageInfo?.currentPage - 1 : 0,
        }}
        paginationMode="server"
        onPaginationModelChange={(paginationModel) => {
          onPageChange(paginationModel.page, paginationModel.pageSize)
        }}
        rowCount={pageInfo?.totalCount ?? 0}
        getDetailPanelContent={getWorkflowPanelContent}
        getDetailPanelHeight={() => 'auto'}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        columns={columns}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        pagination
        loading={isLoading}
        autoPageSize={autoPageSize}
      />
    </StyledTableContainer>
  )
}
