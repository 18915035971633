import {
  OperationVariables,
  QueryOptions,
  useApolloClient,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useRef } from 'react'

export default function useAsyncQuery<Data, Vars extends OperationVariables>(
  query: DocumentNode,
) {
  const client = useApolloClient()
  const fetchFn = useRef((options?: Partial<QueryOptions<Vars, Data>>) => {
    return client.query<Data, Vars>({ query, ...options })
  })
  return fetchFn.current
}
