export const ROLE_NAME_MAP = Object.freeze({
  AFRESH_ADMIN: 'Afresh (admin)',
  AFRESH_SYSTEM: 'Afresh (system)',
  CUSTOMER_ADMIN: 'Admin',
  CUSTOMER_MANAGER: 'Manager',
  CUSTOMER_STORE: 'Store',
  CUSTOMER_SUPERVISOR: 'Supervisor',
  CUSTOMER_DC_BUYER: 'DC Buyer',
  CUSTOMER_REVIEWER: 'Reviewer',
})

export const USER_SCOPE_MAP = Object.freeze({
  MANUAL_TEST: 'Manual Test',
  DEMO: 'Demo',
  DEFAULT: 'Default',
  E2E_TEST: 'E2E Test',
})

export const ACCESS_LEVEL_MAP = Object.freeze({
  STORE: 'Store',
  REGION: 'Region',
  BUSINESS_UNIT: 'Business Unit',
  CHAIN: 'Chainwide',
})

export const URL_PATH = '/users'
