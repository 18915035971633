import {
  OrderDeliveryScheduleQuery,
  OrderDeliveryScheduleQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { addDuration, getISODateString } from 'app/helpers/date'

export const GET_ORDER_DELIVERY_SCHEDULE = gql`
  query orderDeliverySchedule($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        orderDeliverySchedule {
          _id
          status
          editingCutoffDate
        }
      }
    }
  }
`

interface GetOrderDeliverySchedule {
  editingCutoffDate: string
  isEditing: boolean
  loading: boolean
  scheduleId: string | null
}

export default function useGetOrderDeliverySchedule(): GetOrderDeliverySchedule {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<
    OrderDeliveryScheduleQuery,
    OrderDeliveryScheduleQueryVariables
  >(GET_ORDER_DELIVERY_SCHEDULE, {
    onError: (error) => {
      Sentry.captureMessage(error.message, {
        level: 'warning',
        extra: {
          gqlErrors: JSON.stringify(error),
        },
      })
    },
    skip: !activeCustomerId,
    variables: {
      customerId: activeCustomerId!,
    },
  })

  if (
    data?.node?.__typename === 'Customer' &&
    data?.node?.orderDeliverySchedule
  ) {
    const { _id, editingCutoffDate, status } = data.node.orderDeliverySchedule

    return {
      editingCutoffDate: getISODateString(
        addDuration(editingCutoffDate, -1, 'days'),
      ),
      isEditing: status === 'DRAFT',
      loading,
      scheduleId: _id,
    }
  }

  return {
    editingCutoffDate: '',
    isEditing: false,
    loading,
    scheduleId: null,
  }
}
