import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TableHeader from 'app/components/Tables/TableHeader'
import Tag from 'app/components/Tag/Tag'
import { formatDatetime } from 'app/helpers/date'
import { ConfigurationsRow } from 'app/packages/internal/customerConfigs/configurations/api/queries/useGetConfigurations'
import { $activeConfigurationsConfig } from 'app/packages/internal/customerConfigs/configurations/pages/Configurations.variables'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  padding-top: 12px;

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

export interface Props {
  isLoading?: boolean
  disableVirtualization?: boolean
  rows: ConfigurationsRow[]
  setupDetailsExpanded: boolean
  onClickAddNew: () => void
  configNameFilters?: Option[]
  configNameFilter: string
  setConfigNameFilter: (value: string) => void
}

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${toRem(300)};
  line-height: 0;
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export const ConfigurationsColumns: GridColDef[] = [
  {
    field: 'config-name',
    valueGetter: (_, row) => row.configurationType.name,
    headerName: 'Config Name',
    width: 420,
  },
  {
    field: 'config-type',
    valueGetter: (_, row) => row.configurationType.valueType,
    renderCell: ({ value }) => {
      if (value === 'BOOLEAN')
        return <Tag color="highlight_06" text="BOOLEAN" />
      // Only BOOL and INT supported right now.
      return <Tag color="highlight_04" text="INT" />
    },
    headerName: 'Type',
    width: 100,
  },
  {
    field: 'workflow',
    valueGetter: (_, row) =>
      row.workflow
        ? `${row.workflow?.name} (${row.workflow?.id})`
        : 'All Workflows',
    headerName: 'Workflow',
    width: 150,
  },
  {
    field: 'store',
    valueGetter: (_, row) =>
      row.store ? `${row.store?.name} (${row.store?.id})` : 'All Stores',
    headerName: 'Store',
    width: 150,
  },
  {
    field: 'value',
    valueGetter: (_, row) => row.value,
    renderCell: ({ value }) => {
      if (value === 'true') return <Tag color="highlight_08" text="true" />
      if (value === 'false') return <Tag color="highlight_02" text="false" />
      return <Tag color="neutral_05" text={value} />
    },
    headerName: 'Value',
    width: 150,
  },
  {
    field: 'createdAt',
    valueGetter: (_, row) => formatDatetime(row.createdAt),
    headerName: 'Created At',
    width: 200,
  },
  {
    field: 'updatedAt',
    valueGetter: (_, row) => formatDatetime(row.updatedAt),
    headerName: 'Updated At',
    width: 200,
  },
]

export default function ConfigurationsTable(props: Props) {
  const {
    isLoading,
    rows,
    onClickAddNew,
    setupDetailsExpanded,
    disableVirtualization = false,
    configNameFilters,
    configNameFilter,
    setConfigNameFilter,
  } = props

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <TableHeader>
        <SingleSelect
          options={configNameFilters || []}
          value={configNameFilter}
          width={600}
          label="Config Name"
          handleChange={(e) => setConfigNameFilter(e.target.value)}
        />
        <StyledTableHeaderButtonsContainer>
          <StyledAddNewButton onClick={onClickAddNew}>
            <StyledAddNewIcon /> Add New
          </StyledAddNewButton>
        </StyledTableHeaderButtonsContainer>
      </TableHeader>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row._id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
        }}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        columns={ConfigurationsColumns}
        loading={isLoading}
        onRowClick={({ row }) => $activeConfigurationsConfig(row._id)}
      />
    </StyledTableContainer>
  )
}
