import {
  CircularProgress,
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton as MuiIconButton,
} from '@mui/material'
import { noop } from 'app/helpers'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

interface ButtonProps extends MuiButtonProps {
  children: React.ReactNode | string
  variant?: 'primary' | 'secondary' | 'tertiary' | 'tertiary-alt' | 'icon'
  edge?: 'start' | 'end' | false
  loading?: boolean
}

const StyledLoader = styled.div`
  color: ${({ theme }) => theme.colors.neutral_05};
  display: flex;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  visibility: visible;

  &&& .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.neutral_03};
  }
`

const StyledMuiButton = styled(MuiButton)<{ $isLoading?: boolean }>`
    font-size: 1rem;
    font-weight: bold;
    min-width: 0px;
    max-height: ${toRem(40)};
    text-transform: none;
    line-height: 1;
    padding: ${toRem(12)} ${toRem(16)};

    :focus-visible {
      outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
      outline-offset: 2px;
    }
    :focus:not(:focus-visible) {
      outline: none;
    }
  }

    ${({ $isLoading }) =>
      $isLoading &&
      `
      &&& {
        color: transparent;
      }
    `}

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
        background-color: ${theme.colors.primary};
        border: 1px solid ${theme.colors.primary};
        color: ${theme.colors.neutral_08};

        :hover {
            background-color: ${theme.colors.neutral_10};
            border-color: ${theme.colors.neutral_10};
        }
        :active {
            background-color: ${theme.colors.neutral_09};
            border-color: ${theme.colors.neutral_09};
        }
        :disabled {
            background-color: ${theme.colors.neutral_05};
            border-color: ${theme.colors.neutral_05};
            color: ${theme.colors.neutral_03};
        }
    `}

  ${({ variant, theme }) =>
    variant === 'secondary' &&
    `
        background-color: ${theme.colors.neutral_08};
        border: 2px solid ${theme.colors.primary};
        color: ${theme.colors.primary};

        :hover {
            background-color: ${theme.colors.neutral_08};
            border-color: ${theme.colors.neutral_10};
            color: ${theme.colors.neutral_10};
        }
        :active {
            background-color: ${theme.colors.neutral_08};
            border-color: ${theme.colors.neutral_09};
            color: ${theme.colors.neutral_09};
        }
        :disabled {
            border-color: ${theme.colors.neutral_05};
            border-width: 2px;
            color: ${theme.colors.neutral_03};
        }
    `}

  ${({ variant, theme }) =>
    (variant === 'tertiary' || variant === 'icon') &&
    `
        color: ${theme.colors.primary};
        :hover {
            background-color: transparent;
            color: ${theme.colors.neutral_10};
        }
        :active {
            background-color: transparent;
            color: ${theme.colors.neutral_09};
        }
        :disabled {
            background-color: transparent;
            color: ${theme.colors.neutral_03};
        }

        &&& .MuiCircularProgress-root {
          color:  ${theme.colors.primary};
        }
    `}
    
  ${({ variant, theme }) =>
    variant === 'tertiary-alt' &&
    `
        color: ${theme.colors.secondary};
        :hover {
            background-color: transparent;
            color: ${theme.colors.secondary};
        }
        :active {
            background-color: transparent;
            color: ${theme.colors.secondary};
        }
        :disabled {
            background-color: transparent;
            color: ${theme.colors.neutral_03};
        }
    `}
  }
`

const StyledMuiIconButton = styled(MuiIconButton)<{ $isLoading?: boolean }>`
  font-size: 1rem;
  font-weight: bold;
  min-width: 0px;
  max-height: 40px;
  text-transform: none;
  border: 2px solid transparent;

  :focus-visible {
    outline: none;
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    border-radius: 4px;
  }
  :focus:not(:focus-visible) {
    outline: none;
  }

  color: ${({ theme }) => theme.colors.primary};
  :hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral_10};
  }
  :active {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral_09};
  }
  :disabled {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral_03};
  }

  &&& .MuiCircularProgress-root {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ $isLoading }) =>
    $isLoading &&
    `
      &&& {
        color: transparent;
      }
    `}
`

/**
 * Button Design System Component
 * Figma Link: https://www.figma.com/file/6evhih2UqDkilnSXm8AH6T/Product-Design-System-for-Web?node-id=2602-1961&t=11eK0Jf5p73lDbrj-0
 */
function Button({
  children,
  loading,
  disabled = false,
  onClick = noop,
  startIcon = null,
  type = 'button',
  variant = 'primary',
  edge,
  ...props
}: ButtonProps) {
  if (variant === 'icon') {
    return (
      <StyledMuiIconButton
        disabled={disabled || loading}
        disableRipple
        onClick={onClick}
        type={type}
        edge={edge}
        $isLoading={loading}
        {...props}>
        {loading && (
          <StyledLoader>
            <CircularProgress size={24} />
          </StyledLoader>
        )}
        {children}
      </StyledMuiIconButton>
    )
  }

  return (
    <StyledMuiButton
      disabled={disabled || loading}
      disableRipple
      disableElevation
      onClick={onClick}
      startIcon={startIcon}
      type={type}
      variant={variant}
      $isLoading={loading}
      {...props}>
      {loading && (
        <StyledLoader>
          <CircularProgress size={24} />
        </StyledLoader>
      )}
      {children}
    </StyledMuiButton>
  )
}
export default Button
