import {
  RecurringOrdersQuery,
  RecurringOrdersQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import useWorkflowId from 'app/hooks/useWorkflowId'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import { StoreRecurringSchedule } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { isNil } from 'lodash-es'

export const GET_RECURRING_ORDERS = gql`
  ${STORE_FIELDS}
  query recurringOrders($scheduleId: ID!, $workflowId: ID!) {
    node(id: $scheduleId) {
      _id
      ... on OrderDeliverySchedule {
        _id
        schedules(workflowId: $workflowId) {
          edges {
            node {
              _id
              store {
                ...StoreFields
              }
              recurringOrders {
                _id
                orderDay
                deliveryDay
                deliveryLag
              }
            }
          }
        }
      }
    }
  }
`

export function useGetRecurringOrders() {
  const { scheduleId } = useGetOrderDeliverySchedule()
  const [workflowId] = useWorkflowId()

  const { data, loading } = useQuery<
    RecurringOrdersQuery,
    RecurringOrdersQueryVariables
  >(GET_RECURRING_ORDERS, {
    skip: isNil(scheduleId) || isNil(workflowId),
    variables: { scheduleId: scheduleId!, workflowId: workflowId! },
  })

  let recurringOrdersData: StoreRecurringSchedule[] = []
  if (data && data.node && data.node.__typename === 'OrderDeliverySchedule') {
    recurringOrdersData = data.node.schedules.edges
      .filter((i) => i.node)
      .map((i) => {
        return {
          storeScheduleId: i.node!._id,
          store: toStore(i.node!.store),
          recurringOrders: i
            .node!.recurringOrders.filter((o) => !!o)
            .map((o) => {
              return {
                id: o!._id,
                orderDay: o!.orderDay,
                deliveryDay: o!.deliveryDay,
                deliveryLag: o!.deliveryLag,
              }
            }),
        }
      })
  }

  return { loading, recurringOrdersData }
}
