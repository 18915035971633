import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import { Spinner } from 'app/components/Spinner/Spinner'
import { useGetMatchingStores } from 'app/packages/storeSolutions/schedules/api/queries/useGetMatchingStores'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useState } from 'react'
import styled from 'styled-components'

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  height: 64px;
`

const StyledSpinner = styled(Spinner)`
  padding-top: 32px;
`

export interface SelectBulkEditModalProps {
  onClose: () => void
  onSelectStores: (selectedStores: Store[]) => void
  selectedStore: Store
  storeScheduleId: string
}

export function SelectBulkEditModal(props: SelectBulkEditModalProps) {
  const { onClose, onSelectStores, selectedStore, storeScheduleId } = props
  const [selectedStores, setSelectedStores] = useState<Store[]>([])
  const { stores, errorMessage, loading } = useGetMatchingStores(
    storeScheduleId,
  )

  return (
    <Dialog isOpen onClose={onClose}>
      <Header onClose={onClose}>
        <Title>Edit Matching Stores</Title>
      </Header>
      <Body>
        {loading && <StyledSpinner />}

        {errorMessage && <div>{errorMessage}</div>}

        {!loading && !errorMessage && (
          <>
            Select from the list of stores that match{' '}
            <b>&apos;{selectedStore.name}&apos;</b>.
            <SelectContainer>
              <MultiSelect
                inputLabel="Matching stores"
                options={stores}
                onFilterOptions={(storeIds) =>
                  setSelectedStores(
                    stores.filter((store) => storeIds.includes(store.id)),
                  )
                }
                selectedOptions={selectedStores.map((s) => s.id)}
              />
            </SelectContainer>
          </>
        )}
      </Body>
      <Footer>
        <FooterActions>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={!selectedStores.length}
            onClick={() => {
              onSelectStores([selectedStore, ...selectedStores])
              onClose()
            }}>
            Next
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
