import {
  GetCustomerPermissionsQuery,
  GetCustomerPermissionsQueryVariables,
  UserCapabilities,
  UserScope,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'

export const GET_CUSTOMER_PERMISSIONS = gql`
  query getCustomerPermissions($customerId: ID) {
    viewer {
      user2 {
        _id
        name
        accessRole
        userScope
      }
      permissions {
        capabilities(customerId: $customerId)
      }
    }
  }
`

interface CustomerPermissions {
  accessRole?: string
  name?: string
  permissions?: UserCapabilities[]
  userScope?: UserScope | ''
  loading: boolean
}

export default function useGetCustomerPermissions(): CustomerPermissions {
  const { activeCustomerId } = useCustomers()
  const { data, loading } = useQuery<
    GetCustomerPermissionsQuery,
    GetCustomerPermissionsQueryVariables
  >(GET_CUSTOMER_PERMISSIONS, {
    skip: activeCustomerId === undefined,
    variables: { customerId: activeCustomerId },
  })
  if (data && data.viewer && data.viewer.__typename === 'Viewer') {
    const { permissions, user2: user } = data.viewer

    return {
      accessRole: user?.accessRole,
      name: user?.name,
      permissions: permissions?.capabilities,
      userScope: user?.userScope,
      loading,
    }
  }

  return {
    accessRole: '',
    name: '',
    permissions: [],
    userScope: '',
    loading: true, // show loading if active customer is undefined
  }
}
