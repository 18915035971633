import LinearProgress from 'app/components/LinearProgress/LinearProgress'
import DynamicVirtualizedList, {
  DynamicVirtualizedRowProps,
} from 'app/components/VirtualizedList/Dynamic'
import StickyVirtualizedList from 'app/components/VirtualizedList/Sticky'
import { VirtualizedListProps } from 'app/components/VirtualizedList/VirtualizedList.types'
import { TOP_BAR_HEIGHT } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import { LayoutContext } from 'app/layouts/Customer/CustomerPageLayout'
import React, { CSSProperties, useCallback, useContext } from 'react'
import { Column, Row, useBlockLayout, useTable } from 'react-table'
import styled from 'styled-components'

export const StyledTableContainer = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: calc(100% - 72px);
  user-select: none;
`

export const StyledTable = styled.div`
  width: 100%;
`

export const StyledTableHeaderRow = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral_07};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  color: ${({ theme }) => theme.colors.neutral_02};
  display: flex;
  font-weight: 700;
  height: 50px;
  width: 100%;

  & > :first-child {
    flex: 0 0 316px;
    padding-left: 16px;
  }
`

export const StyledTableHeaderRowGroup = styled.div`
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.neutral_07};
  height: 100%;
  justify-content: center;
  width: 100%;
`

const StyledDynamicList = styled(DynamicVirtualizedList)`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow-y: scroll;
` as <T extends DynamicVirtualizedRowProps>(
  props: VirtualizedListProps<T>,
) => React.ReactElement

const StyledStickyList = styled(StickyVirtualizedList)`
  overflow-y: scroll;
`

export interface TableProps<T extends DynamicVirtualizedRowProps> {
  className?: string
  columns: Column<T>[]
  data: T[]
  isLoading: boolean
  renderRow: (row: Row<T>, style: CSSProperties) => any
  stickyIndices?: number[]
  type: 'DYNAMIC' | 'STICKY'
}

export default function Table<T extends DynamicVirtualizedRowProps>({
  className,
  columns,
  data,
  isLoading,
  renderRow,
  stickyIndices,
  type,
}: TableProps<T>) {
  const { hasTopBar } = useContext(LayoutContext)

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  )

  const isDynamicTable = !isLoading && type === 'DYNAMIC'
  const isStickyTable = !isLoading && type === 'STICKY'

  const RenderRow = useCallback(
    ({ index, style }: { index: number; style: CSSProperties }) => {
      if (isNilOrEmpty(rows) || isNilOrEmpty(rows[index])) {
        return null
      }

      const row = rows[index]
      prepareRow(row)
      return renderRow(row, style)
    },
    [prepareRow, renderRow, rows],
  )

  const sharedTableProps = {
    height: hasTopBar
      ? `calc(100vh - 346px - ${TOP_BAR_HEIGHT}px)`
      : 'calc(100vh - 346px)',
    numOfRows: rows.length,
    renderRow: RenderRow,
  }

  return (
    <StyledTableContainer>
      <StyledTable className={className} {...getTableProps()}>
        {headerGroups.map((headerGroup, hdrIndex) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <StyledTableHeaderRow key={hdrIndex}>
              {headerGroup.headers.map((column) => {
                return (
                  <StyledTableHeaderRowGroup key={column.id}>
                    {column.render('Header')}
                  </StyledTableHeaderRowGroup>
                )
              })}
            </StyledTableHeaderRow>
          )
        })}

        <div {...getTableBodyProps()}>
          {isLoading && <LinearProgress />}
          {isDynamicTable && (
            <StyledDynamicList {...sharedTableProps} rows={rows} />
          )}
          {isStickyTable && (
            <StyledStickyList
              {...sharedTableProps}
              stickyIndices={stickyIndices}
            />
          )}
        </div>
      </StyledTable>
    </StyledTableContainer>
  )
}
