/* eslint-disable react/no-array-index-key */
import { Box, List, ListItem, ListItemText, Skeleton } from '@mui/material'
import LogoWithEnvironment from 'app/components/Logo/LogoWithEnvironment'
import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import useMakeNav from './useMakeNav'

// renders enough skeletons to fill up most of the side nav
export const ARBITRARY_SKELETON_NUM = 18

function SkeletonLoader() {
  return (
    <>
      {[...Array(ARBITRARY_SKELETON_NUM)].map((_, index) => {
        return (
          <ListItem role="listitem" key={index} sx={{ padding: '8px 20px' }}>
            <Skeleton variant="text" width="100%" height={32} />
          </ListItem>
        )
      })}
    </>
  )
}

export default function FileUploadsSideNav() {
  const location = useLocation()
  const navigate = useNavigate()
  const { isLoading, navItems } = useMakeNav()

  return (
    <Box
      sx={{
        borderRight: '1px solid rgba(0, 0, 0, .1)',
        height: '100vh',
      }}>
      <Box sx={{ padding: '20px' }}>
        <NavLink
          className={({ isActive }) => (isActive ? 'active' : undefined)}
          to="/">
          <LogoWithEnvironment />
        </NavLink>
      </Box>

      <List
        sx={{ height: 'calc(100% - 64px)', overflowY: 'auto', padding: '0' }}>
        {isLoading && <SkeletonLoader />}
        {!isLoading &&
          navItems.map((item) => {
            const selected = location.pathname.indexOf(item.path) === 0
            return (
              <ListItem
                sx={{ padding: '8px 20px' }}
                key={item.name}
                button
                disabled={item.disabled}
                selected={selected}
                onClick={() => navigate(item.path)}>
                <ListItemText primary={item.name} />
              </ListItem>
            )
          })}
      </List>
    </Box>
  )
}
