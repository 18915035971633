import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import tuple from 'shared/tuple'

export function useMultipleSearchParamsState<T extends Record<string, string>>(
  searchParamsConfig: T,
): [T, (newState: Partial<T>) => void] {
  const [searchParams, setSearchParams] = useSearchParams()

  // Returns an array of the values of the search params
  const acquiredSearchParams = Object.keys(
    searchParamsConfig,
  ).map((searchParamName) => searchParams.get(searchParamName))

  // Returns an object of the search params and their values
  const searchParamsState = Object.keys(searchParamsConfig).reduce<T>(
    (o, key, i) => ({
      ...o,
      [key]: acquiredSearchParams[i] || searchParamsConfig[key],
    }),
    {} as T,
  )

  const setSearchParamsState = useCallback(
    (newState: Partial<T>) => {
      let newStateEqualToParams = true
      Object.keys(newState).forEach((key) => {
        if (newState[key] !== searchParams.get(key)) {
          newStateEqualToParams = false
        }
      })

      if (newStateEqualToParams) return

      // eslint-disable-next-line prefer-object-spread
      const next = Object.assign(
        {},
        [...searchParams.entries()].reduce(
          (o, [key, value]) => ({ ...o, [key]: value }),
          {},
        ) as Record<string, string>,
        { ...newState },
      )

      Object.keys(newState).forEach((key) => {
        if (!newState[key]) {
          delete next[key]
        }
      })

      setSearchParams(next)
    },
    [searchParams, setSearchParams],
  )

  // If the search params are set in the local state but not in the URL, update the URL - this will happen most on first load
  useEffect(() => {
    if (
      Object.keys(searchParamsConfig).some(
        (searchParamName) =>
          searchParams.get(searchParamName) !==
          searchParamsState[searchParamName],
      )
    ) {
      setSearchParamsState(searchParamsState)
    }
  }, [
    searchParamsState,
    searchParams,
    setSearchParamsState,
    searchParamsConfig,
  ])

  return tuple([searchParamsState, setSearchParamsState])
}
