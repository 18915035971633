import { LocalShipping, SvgIconComponent } from '@mui/icons-material'
import InventoryResults from 'app/components/Icons/ProductBundles/InventoryResults'
import OpsInsights from 'app/components/Icons/ProductBundles/OpsInsights'
import DeliverySchedule from 'app/components/Icons/Products/DeliverySchedule'
import GuideOrganization from 'app/components/Icons/Products/GuideOrganization'
import InventoryGuide from 'app/components/Icons/Products/InventoryGuide'
import OrderGuide from 'app/components/Icons/Products/OrderGuide'
import Users from 'app/components/Icons/Products/Users'

export enum PRODUCT {
  ORDER_GUIDE = 'ORDER_GUIDE',
  INVENTORY_GUIDE = 'INVENTORY_GUIDE',
  GUIDE_ORGANIZATION = 'GUIDE_ORGANIZATION',
  DELIVERY_SCHEDULES = 'DELIVERY_SCHEDULES',
  INVENTORY_RESULTS = 'INVENTORY_RESULTS',
  DISTRIBUTION_CENTER_INSIGHTS = 'DISTRBUTION_CENTER_INSIGHTS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  OPS_INSIGHTS = 'OPS_INSIGHTS',
}

export enum PRODUCT_BUNDLE_TYPE {
  STORE_OPERATIONS = 'STORE_OPERATIONS',
  DISTRIBUTION_CENTER = 'DISTRIBUTION_CENTER',
  INVENTORY_RESULTS = 'INVENTORY_RESULTS',
  OPS_INSIGHTS = 'OPS_INSIGHTS',
}

export interface productRoute {
  productType: PRODUCT
  route: string
}

export const productSubrouteMap: {
  [key in string]: string
} = {
  items: 'Items',
  'scheduled-updates': 'Scheduled Updates',
  guides: 'Order Guides',
  sections: 'Sections',
  calendar: 'Calendar',
  closures: 'Closures',
  recurring: 'Recurring',
}

export const productRouteMap: {
  [key in PRODUCT]: string[]
} = {
  [PRODUCT.ORDER_GUIDE]: [
    'store-operations/order-guide/items',
    'store-operations/order-guide/scheduled-updates',
    'store-operations/order-guide/guides',
  ],
  [PRODUCT.INVENTORY_GUIDE]: ['store-operations/inventory-guide'],
  [PRODUCT.GUIDE_ORGANIZATION]: [
    'store-operations/guide-organization/sections',
    'store-operations/guide-organization/items',
  ],
  [PRODUCT.DELIVERY_SCHEDULES]: [
    'store-operations/schedules/calendar',
    'store-operations/schedules/closures',
    'store-operations/schedules/recurring',
  ],
  [PRODUCT.INVENTORY_RESULTS]: ['inventory-results'],
  [PRODUCT.DISTRIBUTION_CENTER_INSIGHTS]: [
    'dc/insights/items',
    'dc/insights/settings',
  ],
  [PRODUCT.USER_MANAGEMENT]: ['/internal'],
  [PRODUCT.OPS_INSIGHTS]: ['insights/dashboards'],
}

export const productNameMap: {
  [key in PRODUCT]: string
} = {
  [PRODUCT.ORDER_GUIDE]: 'Order Guide',
  [PRODUCT.INVENTORY_GUIDE]: 'Inventory Guide',
  [PRODUCT.GUIDE_ORGANIZATION]: 'Guide Organization',
  [PRODUCT.DELIVERY_SCHEDULES]: 'Delivery Schedules',
  [PRODUCT.INVENTORY_RESULTS]: 'Inventory Results',
  [PRODUCT.DISTRIBUTION_CENTER_INSIGHTS]: 'Distribution Center Insights',
  [PRODUCT.USER_MANAGEMENT]: 'User Management',
  [PRODUCT.OPS_INSIGHTS]: 'Ops Insights',
}

export const productIconMap: {
  [key in PRODUCT]: SvgIconComponent
} = {
  [PRODUCT.ORDER_GUIDE]: OrderGuide,
  [PRODUCT.INVENTORY_GUIDE]: InventoryGuide,
  [PRODUCT.GUIDE_ORGANIZATION]: GuideOrganization,
  [PRODUCT.DELIVERY_SCHEDULES]: DeliverySchedule,
  [PRODUCT.INVENTORY_RESULTS]: InventoryResults,
  [PRODUCT.DISTRIBUTION_CENTER_INSIGHTS]: LocalShipping,
  [PRODUCT.USER_MANAGEMENT]: Users,
  [PRODUCT.OPS_INSIGHTS]: OpsInsights,
}
