import SearchBox from 'app/components/SearchBox/SearchBox'
import SingleSelect, {
  getBooleanSelectOptions,
  getBooleanValueFromOptionValue,
} from 'app/components/SingleSelect/SingleSelect'
import { useSearchParamsState } from 'app/hooks/useSearchParamsState'
import { SelectedItems } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import useGetItemsTableData from 'app/packages/storeSolutions/guideOrganization/hooks/useGetItemsTableData'
import Filters from 'app/packages/storeSolutions/guideOrganization/pages/Filters/Filters'
import ItemOverrides from 'app/packages/storeSolutions/guideOrganization/pages/Items/ItemOverrides/ItemOverrides'
import SectionsSelect from 'app/packages/storeSolutions/guideOrganization/pages/SectionsSelect/SectionsSelect'
import React, { useState } from 'react'
import styled from 'styled-components'
import Table from './Table'

const StyledSearchBox = styled(SearchBox)`
  width: ${({ theme }) => theme.typography.pxToRem(340)};
`

export default function ItemsTable() {
  const [categoryId, setCategoryId] = useSearchParamsState('categoryId')
  const [searchTerm, setSearchTerm] = useSearchParamsState('searchTerm')
  const [hasBeenEdited, setHasBeenEdited] = useSearchParamsState(
    'hasBeenEdited',
  )
  const { properties, primaryKeyType } = useGetProperties()

  const {
    columns,
    data,
    error,
    fetchMore,
    isFetchingMore,
    isLoading,
  } = useGetItemsTableData({
    searchTerm: searchTerm || undefined,
    category: categoryId || undefined,
    hasBeenEdited: getBooleanValueFromOptionValue(hasBeenEdited),
  })

  const [selectedItems, setSelectedItems] = useState<SelectedItems>({})

  const isDraft = properties?.status === 'DRAFT'

  return (
    <>
      <Filters>
        <StyledSearchBox
          placeholderText={`Search by Item Name, Order Code, ${primaryKeyType}`}
          onSearchTextChange={setSearchTerm}
          defaultValue={searchTerm}
        />
        <SectionsSelect onSelect={setCategoryId} value={categoryId} />
        {isDraft && (
          <SingleSelect
            options={getBooleanSelectOptions({
              trueLabel: 'Edited',
              falseLabel: 'Not edited',
            })}
            value={hasBeenEdited}
            label="Draft Status"
            handleChange={(e) => setHasBeenEdited(e.target.value)}
          />
        )}
        {isDraft && (
          <ItemOverrides
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        )}
      </Filters>
      <Table
        columns={columns as any}
        data={data.rows}
        hasError={!!error}
        isFetching={isFetchingMore}
        isLoading={isLoading}
        onScroll={fetchMore}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        stickyIndices={data.stickyIndices}
        isDraft={isDraft}
      />
    </>
  )
}
