import { AuthMethodQuery, AuthMethodQueryVariables } from '@api/__gen__/gql'
import { Box } from '@mui/material'
import Button from 'app/components/Button/Button'
import CardTitle from 'app/components/CardTitle/CardTitle'
import TextField from 'app/components/TextField/TextField'
import { authMethodGql } from 'app/packages/core/api/login/queries/authMethod'
import React, { useCallback, useState } from 'react'
import assignLocation from 'shared/assignLocation'
import useAsyncQuery from 'shared/useAsyncQuery'
import useIsMounted from 'shared/useIsMounted'

interface Props {
  onEmailSubmit: (email: string) => unknown
  onNeedsReset: (email: string) => void
}

export default function Email(props: Props) {
  const { onEmailSubmit, onNeedsReset } = props
  const isMounted = useIsMounted()
  const [email, setEmail] = useState('')
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const getAuthMethod = useAsyncQuery<
    AuthMethodQuery,
    AuthMethodQueryVariables
  >(authMethodGql)

  const submit = useCallback(
    async (e: string) => {
      setSubmitInProgress(true)
      const result = await getAuthMethod({ variables: { email: e } })
      const authMethod = result.data?.user?.authenticationMethod
      if (authMethod?.__typename === 'AuthenticationMethodPassword') {
        if (authMethod.needsReset) {
          await Promise.resolve(onNeedsReset(e))
        } else {
          await Promise.resolve(onEmailSubmit(e))
        }
      } else if (authMethod?.__typename === 'AuthenticationMethodOidc') {
        assignLocation(authMethod.authUrl)
      } else {
        setErrorMessage('Account not found.')
      }
      if (isMounted()) {
        setSubmitInProgress(false)
      }
    },
    [getAuthMethod, isMounted, onEmailSubmit, onNeedsReset],
  )

  return (
    <>
      <CardTitle>Sign in</CardTitle>
      <form
        onSubmit={async (e) => {
          e.preventDefault()
          await submit(email.trim())
        }}>
        <Box>
          <TextField
            label="Email address"
            value={email}
            error={!!errorMessage}
            helperText={errorMessage}
            autoFocus
            fullWidth
            disabled={submitInProgress}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Button type="submit" disabled={!email.trim() || submitInProgress}>
            Next
          </Button>
        </Box>
      </form>
    </>
  )
}
