import { noop } from 'app/helpers'
import React, { SyntheticEvent } from 'react'
import { NavLink, Path } from 'react-router-dom'
import styled from 'styled-components'

const StyledTabsContainer = styled.nav`
  align-items: center;
  column-gap: ${({ theme }) => theme.typography.pxToRem(32)};
  display: flex;
`

export const StyledTab = styled(NavLink)`
  border-bottom: 4px solid transparent;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  font-weight: 700;
  padding-bottom: ${({ theme }) => theme.typography.pxToRem(4)};
  text-decoration: none;

  &:hover:not([aria-disabled='true']) {
    color: ${({ theme }) => theme.colors.neutral_01};
  }

  &:active:not([aria-disabled='true']) {
    color: ${({ theme }) => theme.colors.neutral_01};
  }

  &.active {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.primary}`};
    color: ${({ theme }) => theme.colors.primary};
  }

  &.active:hover {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.neutral_10}`};
    color: ${({ theme }) => theme.colors.neutral_10};
  }

  &.active:active {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.neutral_09}`};
    color: ${({ theme }) => theme.colors.neutral_09};
  }

  &:focus-visible {
    outline: ${({ theme }) => `2px auto ${theme.colors.primary}`};
    outline-offset: 4px;
    outline-radius: 4px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &.active[aria-disabled='true'] {
    border-bottom: ${({ theme }) => `4px solid ${theme.colors.neutral_05}`};
  }

  &[aria-disabled='true'] {
    color: ${({ theme }) => theme.colors.neutral_05};
    pointer-events: none;
  }
`

export interface Tabs {
  to: Partial<Path>
  name: string
  formatter?: (item: Tabs) => React.ReactNode
}

interface RouterTabsProps {
  className?: string
  isDisabled?: boolean
  onClick?: (event: SyntheticEvent) => void
  tabs: Tabs[]
}

export default function RouterTabs({
  className = '',
  isDisabled,
  onClick = noop,
  tabs,
}: RouterTabsProps) {
  return (
    <StyledTabsContainer className={className}>
      {tabs.map((tab) => {
        return tab.formatter ? (
          tab.formatter(tab)
        ) : (
          <StyledTab
            aria-label={`Click to navigate to the ${tab.name} view`}
            aria-disabled={isDisabled}
            onClick={onClick}
            key={tab.name}
            to={tab.to}
            tabIndex={isDisabled ? -1 : 0}>
            {tab.name}
          </StyledTab>
        )
      })}
    </StyledTabsContainer>
  )
}
