import { BusinessLevel } from '@api/__gen__/gql'
import { DialogContent } from '@mui/material'
import useGetCustomerBusinessLevelInstances from 'app/api/queries/useGetCustomerBusinessLevelInstances'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { isNilOrEmpty } from 'app/helpers'
import useDraftState from 'app/hooks/useDraftState'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { useAddWorkflowAvailability } from 'app/packages/internal/customerConfigs/workflowAvailability/api/mutations/useAddWorkflowAvailability'
import { useUpdateWorkflowAvailability } from 'app/packages/internal/customerConfigs/workflowAvailability/api/mutations/useUpdateWorkflowAvailability'
import { WorkflowAvailabilityRow } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetWorkflowAvailability'
import { formatBusinessLevelInstanceOptionLabel } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import { toRem } from 'app/styles'
import { uniqBy } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const FIELD_WIDTH = 304

export const StyledInnerWrapper = styled.div`
  max-height: ${toRem(450)};
`

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export const StyledAutocomplete = styled(Autocomplete)`
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export interface WorkflowAvailabilityAddEditModalProps {
  modalState: ModalState
  activeRow?: WorkflowAvailabilityRow
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
  businessLevelOptions?: Option[]
  workflowOptions?: Option[]
}

interface FormValues {
  workflowId: string
  businessLevelInstanceId: string
  available: boolean
}

export const CHAINWIDE_LABEL = 'Chainwide'

function getInitialFormState(activeRow?: WorkflowAvailabilityRow): FormValues {
  // if no active row
  if (!activeRow) {
    return {
      workflowId: '',
      businessLevelInstanceId: '',
      available: true,
    }
  }

  // get initial values for active row
  return {
    workflowId: activeRow.workflow._id,
    businessLevelInstanceId: activeRow.businessLevel._id,
    available: activeRow.available,
  }
}

function validateFormValues(formValues: FormValues) {
  const formValid = true

  if (formValues.workflowId === '') return false
  if (formValues.businessLevelInstanceId === '') return false

  return formValid
}

export default function WorkflowAvailabilityAddEditModal({
  modalState,
  activeRow,
  workflowOptions,
  onClose,
  onSuccess,
  onDelete,
  businessLevelOptions,
}: WorkflowAvailabilityAddEditModalProps) {
  const [businessLevel, setBusinessLevel] = useState<BusinessLevel | undefined>(
    activeRow?.businessLevel.businessLevelMetadata.level,
  )
  const [searchTerm, setSearchTerm] = useState('')

  const {
    data: businessLevelInstances,
    isLoading: businessLevelInstancesLoading,
  } = useGetCustomerBusinessLevelInstances({
    searchTerm,
    businessLevel,
  })

  const [addWorkflowAvailability] = useAddWorkflowAvailability()
  const [updateWorkflowAvailability] = useUpdateWorkflowAvailability()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [formValid, setFormValid] = useState(validateFormValues(formValues))
  const [
    businessLevelInstanceIdValue,
    setBusinessLevelInstanceIdValue,
  ] = useState<Option | null>(
    activeRow
      ? {
          value: activeRow.businessLevel._id,
          label: formatBusinessLevelInstanceOptionLabel(
            activeRow.businessLevel,
          ),
        }
      : null,
  )

  /*
    This function merges the activeRow's businessLevel with the businessLevelInstances,
    so that we can ensure the current businessLevel is always available in the dropdown.
  */
  const businessLevelInstanceOptions = useMemo(() => {
    let instanceOptions: Option[] = []
    if (activeRow) {
      instanceOptions.concat([
        {
          label: formatBusinessLevelInstanceOptionLabel(
            activeRow.businessLevel,
          ),
          value: activeRow.businessLevel._id,
        },
      ])
    }

    if (businessLevelInstances.length) {
      instanceOptions = instanceOptions.concat(
        businessLevelInstances.map((bl) => ({
          label: formatBusinessLevelInstanceOptionLabel(bl),
          value: bl._id,
        })),
      )
    }

    return uniqBy(instanceOptions, (option) => option.value)
  }, [businessLevelInstances, activeRow])

  useEffect(() => {
    setFormValid(validateFormValues(formValues))
  }, [formValues])

  useEffect(() => {
    if (businessLevel === 'CHAIN') {
      const chainBusinessLevelInstance = businessLevelInstanceOptions?.find(
        (option) => option.label === CHAINWIDE_LABEL,
      )
      if (chainBusinessLevelInstance) {
        setBusinessLevelInstanceIdValue({
          value: chainBusinessLevelInstance.value,
          label: CHAINWIDE_LABEL,
        })
        setFormValues((draft) => {
          draft.businessLevelInstanceId = chainBusinessLevelInstance.value
        })
      }
    }
  }, [businessLevel, businessLevelInstanceOptions, setFormValues])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      if (!activeRow) {
        await addWorkflowAvailability({
          workflowId: formValues.workflowId,
          businessLevelInstanceId: formValues.businessLevelInstanceId,
          available: formValues.available,
        })
      } else {
        await updateWorkflowAvailability({
          id: activeRow._id,
          workflowId: formValues.workflowId,
          businessLevelInstanceId: formValues.businessLevelInstanceId,
          available: formValues.available,
        })
      }
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Workflow Availability`}</Title>
      </Header>
      <Body>
        <StyledDialogContent>
          <SelectFieldContainer>
            <SingleSelect
              width={FIELD_WIDTH}
              disabled={isNilOrEmpty(workflowOptions)}
              options={workflowOptions ?? []}
              value={formValues.workflowId}
              label="Workflow"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.workflowId = e.target.value
                })
              }
            />
            <SingleSelect
              width={FIELD_WIDTH}
              options={businessLevelOptions || []}
              value={businessLevel || ''}
              label="Business Level"
              handleChange={(e) => {
                setBusinessLevelInstanceIdValue(null)
                setBusinessLevel(e.target.value as BusinessLevel)
                setFormValues((draft) => {
                  draft.businessLevelInstanceId = ''
                })
              }}
            />
            <StyledAutocomplete
              disabled={businessLevel === 'CHAIN' || businessLevel === null}
              options={businessLevelInstanceOptions || []}
              onInputChange={(event, value) => {
                if (event?.type === 'click') {
                  return
                }

                if (
                  (activeRow &&
                    value ===
                      `${activeRow.businessLevel.name} (${activeRow.businessLevel.id})`) ||
                  value === CHAINWIDE_LABEL
                )
                  return

                setSearchTerm(value)
              }}
              label="Business Level ID"
              loading={businessLevelInstancesLoading}
              value={businessLevelInstanceIdValue}
              onChange={(_, value) => {
                setFormValues((draft) => {
                  draft.businessLevelInstanceId = value?.value ?? ''
                })
                setBusinessLevelInstanceIdValue(value)
              }}
            />
            <SingleSelect
              width={FIELD_WIDTH}
              options={[
                {
                  label: 'Enabled',
                  value: 'true',
                },
                {
                  label: 'Disabled',
                  value: 'false',
                },
              ]}
              value={formValues.available ? 'true' : 'false'}
              label="Availability"
              handleChange={(e) => {
                setFormValues((draft) => {
                  draft.available = e.target.value === 'true'
                })
              }}
            />
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && (
          <FooterActions justify="start">
            <Button
              startIcon={<Delete />}
              onClick={onDelete}
              variant="tertiary">
              Delete
            </Button>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading || !formValid}
            loading={loading}
            onClick={handleSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
