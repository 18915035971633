import {
  CreateStoreClosureEventMutation,
  CreateStoreClosureEventMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import { STORE_CLOSURE_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreClosureEventFields'
import tuple from 'shared/tuple'

export const CREATE_STORE_CLOSURE_EVENT = gql`
  ${STORE_CLOSURE_EVENT_FIELDS}
  mutation createStoreClosureEvent(
    $closureType: StoreClosureType!
    $date: Date!
    $scheduleId: ID!
    $storeIds: [ID!]
  ) {
    createStoreClosureEvent(
      input: {
        closureType: $closureType
        date: $date
        scheduleId: $scheduleId
        storeIds: $storeIds
      }
    ) {
      storeClosureEvent {
        ...StoreClosureEventFields
      }
    }
  }
`

export default function useCreateStoreClosureEvent() {
  const [createStoreClosureEvent, result] = useMutation<
    CreateStoreClosureEventMutation,
    CreateStoreClosureEventMutationVariables
  >(CREATE_STORE_CLOSURE_EVENT, {
    errorPolicy: 'none',
    onError: (error) => {
      Sentry.captureMessage(error.message, {
        level: 'warning',
        extra: {
          gqlErrors: JSON.stringify(error),
        },
      })

      if (error.graphQLErrors.length) {
        throw new Error(
          SCHEDULE_ERRORS[getFirstErrorCode(error.graphQLErrors)] ||
            'Unable to create store closure event.',
        )
      } else {
        throw new Error(error.message)
      }
    },
    refetchQueries: ['getStoreClosures', 'getOrderDeliveryScheduleIssues'],
  })

  const createStoreClosureEventWrapper = async ({
    variables,
  }: {
    variables: CreateStoreClosureEventMutationVariables
  }) => {
    await createStoreClosureEvent({ variables })
  }

  return tuple([createStoreClosureEventWrapper, result])
}
