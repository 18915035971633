import { useVars } from 'app/VarsContext'
import { User, useUserContext } from 'app/context/userContext'
import React from 'react'

// Only show the debug menu for Afresh employees. This is for hygiene, not security.
// The client has access to the debug menu details regardless.
const showDebugMenu = (user: User | undefined) => {
  return user?.email.includes('@afresh')
}

export const DebugMenu: React.FC = () => {
  const { user } = useUserContext()
  const vars = useVars()

  if (!showDebugMenu(user)) {
    return null
  }

  // For now, we just write debug information onto a window namespace for easy access.
  // @ts-expect-error: Property 'AfreshDebug' does not exist on type 'Window & typeof globalThis'.ts(2339)
  window.AfreshDebug = {
    userId: user?.id,
    scope: user?.userScope,
    role: user?.accessRole,
    user,
    vars,
  }

  return null
}
