import { CaseUnit } from '@api/__gen__/gql'
import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import { Column, Row } from 'react-table'
import { Optional } from 'shared/types'

export interface OrderableOption {
  caseSize: number
  caseUnits: CaseUnit
  id: string
  name: string
  orderGuideDisplayName?: string
  orderCode: string
}

export interface GuideStructurePaginationArgs {
  category?: string
  hasBeenEdited?: boolean
  limit?: number
  offset?: number
  searchTerm?: string
}

export interface GuideStructureCategoryNode {
  categories: GuideStructureCategory[]
  maxSortIndex: number
  minSortIndex: number
}

interface ItemCategory {
  id: string
  databaseId: string
  name: string
  parentId: string | null
  sortIndex: number
}

export interface OrderingConfiguration {
  autoInventoryEnabled: boolean
  backInventoryRequired: boolean
  floorInventoryRequired: boolean
  floorInventoryUnits: InventoryInputUnitEnum
}

export interface InventoryConfiguration {
  backInventoryUnits: InventoryInputUnitEnum
  floorInventoryRequired: boolean
  floorInventoryUnits: InventoryInputUnitEnum
}

// When creating a category, let floorInventoryUnits and backInventoryUnits
// be undefined instead of defaulting to a unit.

export type OrderingConfigurationEditState = Optional<
  OrderingConfiguration,
  'floorInventoryUnits'
>
export type InventoryConfigurationEditState = Optional<
  InventoryConfiguration,
  'backInventoryUnits' | 'floorInventoryUnits'
>

export interface GuideStructureCategory {
  hasBeenEdited: boolean
  isEmpty: boolean
  id: string
  itemCategory: ItemCategory
  primaryMappings: PrimaryMapping[]
  orderingConfiguration?: OrderingConfiguration
  inventoryConfiguration?: InventoryConfiguration
}

export interface GuideStructureItem {
  afreshItemId: string
  afreshItemName: string
  category: string
  categoryReason: string
  hasBeenEdited: boolean
  id: string
  itemClass: string
  itemSubClass: string
  orderableOptions: OrderableOption[]
  variableHeight?: number
}

export interface TableProps<T extends {}> {
  className?: string
  columns: Column<T>[]
  data: T[]
  hasError: boolean
  isFetching: boolean
  isLoading: boolean
  onScroll: () => Promise<void>
  selectedItems: SelectedItems
  setSelectedItems: Dispatch<SetStateAction<SelectedItems>>
  stickyIndices: number[]
  isDraft: boolean
}

export interface RowItemProps {
  handleOnSelect: (event: React.KeyboardEvent | React.MouseEvent) => void
  isFetching: boolean
  isLastRow: boolean
  isSelectedRow: boolean
  isDraft: boolean
  row: Row<GuideStructureItem>
  style: CSSProperties
}

export interface SectionsListProps {
  categories: GuideStructureCategory[]
  hasError: boolean
  isLoading: boolean
}

export type GuideStructureOption = Partial<OrderableOption> & {
  name: string
  id: string
}

type FlatGuideStructureItem = Partial<GuideStructureItem> & GuideStructureOption

export interface SelectedItem extends FlatGuideStructureItem {
  substitutes: OrderableOption[]
}

export type SelectedItems = Record<string, GuideStructureItem>

export interface ItemDetailsProps {
  item: SelectedItem
  onClose: () => void
}

export interface ItemOverridesProps {
  selectedItems: SelectedItems
  setSelectedItems: Dispatch<SetStateAction<SelectedItems>>
}

export interface ItemOverride {
  category?: string
  itemCategoryId: string
  itemId: string
}

export interface PrimaryMapping {
  _id: string
  name: string
}

export interface MovedPrimaryMapping {
  newGuideStructureCategoryId: string
  primaryMappings: string[]
}

export interface EditCategoryPayload {
  addedPrimaryMappings?: string[]
  guideStructureCategoryId: string
  movedPrimaryMappings?: MovedPrimaryMapping
  name?: string
  parentId?: string | null
  sortIndex?: number
}

export enum InventoryInputUnitEnum {
  CASES = 'CASES',
  EACHES = 'EACHES',
}

export function isGuideStructureItem(
  rowItem: any,
): rowItem is Row<GuideStructureItem> {
  return (rowItem as Row<GuideStructureItem>).original.id !== undefined
}
