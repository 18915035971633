import { useGetStores } from 'app/api/queries/useGetStores'
import Button from 'app/components/Button/Button'
import Add from 'app/components/Icons/Add'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useGetStoreClosures } from 'app/packages/storeSolutions/schedules/api/queries/useGetStoreClosures'
import useStoreIdsSearchParam from 'app/packages/storeSolutions/schedules/hooks/useStoreIdsSearchParam'
import { StoreClosure } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { DeleteStoreClosureModal } from 'app/packages/storeSolutions/schedules/pages/StoreClosuresTable/DeleteStoreClosureModal/DeleteStoreClosureModal'
import StoreClosureModal from 'app/packages/storeSolutions/schedules/pages/StoreClosuresTable/StoreClosureModal/StoreClosureModal'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import StoreClosuresTableBody from './StoreClosuresTableBody'

const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

const StyledAddButton = styled(Button)<{ $isEditing: boolean }>`
  opacity: ${({ $isEditing }) => ($isEditing ? 1 : 0)};
  pointer-events: ${({ $isEditing }) => ($isEditing ? 'auto' : 'none')};
`

export default function StoreClosuresTable() {
  const { isEditing } = useGetOrderDeliverySchedule()
  const {
    storeClosures,
    isLoading: storeClosuresLoading,
  } = useGetStoreClosures()

  const { stores, isLoading: storesLoading } = useGetStores()
  const [selectedStoreIds, setSelectedStoreIds] = useStoreIdsSearchParam()

  const [showStoreClosureModal, setShowStoreClosureModal] = useState(false)
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false)
  const [
    selectedStoreClosure,
    setSelectedStoreClosure,
  ] = useState<StoreClosure | null>(null)

  const visibleData = useMemo(() => {
    if (!selectedStoreIds.length) {
      return storeClosures
    } else {
      return storeClosures.filter((storeClosure) => {
        return (
          storeClosure.stores === null ||
          storeClosure.stores.some((store) =>
            selectedStoreIds.includes(store.id),
          )
        )
      })
    }
  }, [storeClosures, selectedStoreIds])

  const handleOnShowStoreClosureModal = () => {
    setShowStoreClosureModal((prev) => !prev)
    setSelectedStoreClosure(null)
  }

  const onCloseDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal(false)
    setSelectedStoreClosure(null)
  }

  const onEdit = (closure: StoreClosure) => {
    setSelectedStoreClosure(closure)
    setShowStoreClosureModal(true)
  }

  const onDelete = (closure: StoreClosure) => {
    setSelectedStoreClosure(closure)
    setShowDeleteConfirmationModal(true)
  }

  const isLoading = storeClosuresLoading || storesLoading

  return (
    <>
      <StyledTableHeader>
        <MultiSelect
          inputLabel="Stores"
          options={stores}
          loading={isLoading}
          onFilterOptions={setSelectedStoreIds}
          selectedOptions={selectedStoreIds}
        />
        <StyledAddButton
          $isEditing={isEditing}
          onClick={handleOnShowStoreClosureModal}
          startIcon={<Add />}
          variant="tertiary">
          Add closure
        </StyledAddButton>
      </StyledTableHeader>
      <StoreClosuresTableBody
        data={visibleData}
        isLoading={isLoading}
        onEdit={onEdit}
        onDelete={onDelete}
      />

      {showStoreClosureModal && (
        <StoreClosureModal
          closureToEdit={selectedStoreClosure ?? undefined}
          closures={storeClosures}
          onClose={handleOnShowStoreClosureModal}
          stores={stores}
        />
      )}

      {showDeleteConfirmationModal && selectedStoreClosure && (
        <DeleteStoreClosureModal
          storeClosure={selectedStoreClosure}
          onClose={onCloseDeleteConfirmationModal}
        />
      )}
    </>
  )
}
