import { BusinessLevel } from '@api/__gen__/gql'
import { useCustomers } from 'app/CustomerContext'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  FooterActions,
  Header,
  LeftPane,
  LeftPaneListItem,
  RightPane,
  SideTabFooter,
  Title,
} from 'app/components/Dialog/Dialog'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TextField from 'app/components/TextField/TextField'
import useGetCustomerInfo from 'app/packages/core/api/users/queries/useGetCustomerInfo'
import useCreateCustomer from 'app/packages/internal/customerSetup/api/mutations/useCreateCustomer'
import useSetProductBundlePrimaryBusinessLevel from 'app/packages/internal/customerSetup/api/mutations/useSetProductBundlePrimaryBusinessLevel'
import { toRem } from 'app/styles'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

const StyledHelperText = styled.span`
  align-items: center;
  display: inline-flex;
  height: 20px;
  justify-content: space-between;
  width: 100%;
`

const StyledTextField = styled(TextField)`
  display: flex;
  margin-top: ${toRem(8)};
`
const StyledHeader = styled(Header)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral_05};
  padding-bottom: ${toRem(24)};
`

const StyledBody = styled(Body)`
  display: flex;
  flex-grow: 1;
  padding: 0;
`

interface ModalProps {
  onClose: () => void
}

const BUSINESS_LEVEL_OPTIONS = [
  {
    label: 'CHAIN',
    value: 'CHAIN',
  },
  {
    label: 'REGION',
    value: 'REGION',
  },
  {
    label: 'BUSINESS UNIT',
    value: 'BUSINESS UNIT',
  },
]

enum Step {
  BASIC_INFORMATION = 0,
  PRIMARY_BUSINESS_LEVEL = 1,
}

export default function CreateCustomerModal({ onClose }: ModalProps) {
  const [createCustomer, createCustomerResult] = useCreateCustomer()
  const customerInfo = useGetCustomerInfo()
  const { customers, setCustomers, setActiveCustomer } = useCustomers()
  const [
    setProductBundlePrimaryBusinessLevel,
  ] = useSetProductBundlePrimaryBusinessLevel()

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState<Step>(Step.BASIC_INFORMATION)

  const [name, setName] = useState('')
  const [key, setKey] = useState('')
  const [error, setError] = useState('')
  const [primaryBusinessLevel, setPrimaryBusinessLevel] = useState<
    BusinessLevel
  >('CHAIN')

  const newCustomerResult = useMemo(
    () => createCustomerResult.data?.createCustomer?.customer,
    [createCustomerResult],
  )

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKey(event.target.value)
  }

  const handleOnSave = async () => {
    try {
      setLoading(true)
      if (step === Step.BASIC_INFORMATION) {
        // destructure IDs from createCustomerResult
        const {
          data: {
            createCustomer: {
              customer: { _id, id },
            },
          },
        } = await createCustomer({
          name,
          key,
        })
        const newCustomer = {
          id: _id,
          databaseId: id,
          name,
          key,
        }
        // type safety
        if (customers) setCustomers([...customers, newCustomer])
        setActiveCustomer(newCustomer)
        setLoading(false)
        setStep(Step.PRIMARY_BUSINESS_LEVEL)
      }
      if (step === Step.PRIMARY_BUSINESS_LEVEL) {
        // We only want to set the primary business level for the Store Operations product bundle
        const productBundle = customerInfo.data?.productBundles?.find(
          (pb) => pb.type === 'STORE_OPERATIONS',
        )

        if (!productBundle?._id) {
          throw new Error('Product Bundle not found')
        } else {
          await setProductBundlePrimaryBusinessLevel({
            productBundleId: productBundle._id,
            primaryBusinessLevel,
          })
          onClose()
        }

        setLoading(false)
      }
    } catch (err) {
      setError((err as Error).message)
    }
  }

  const validateFields = () => {
    // Only validate the first step, which is Basic Information
    if (step === Step.BASIC_INFORMATION) {
      if (name === '' || key === '') {
        return false
      }

      // Ensure that name and key are unique
      return !customers?.some((customer) => {
        if (!customer) return false
        return (
          customer.name.toLowerCase() === name.toLowerCase() ||
          customer.key.toLowerCase() === key.toLowerCase()
        )
      })
    }
    return true
  }

  return (
    <Dialog isOpen onClose={onClose} size="lg">
      <StyledHeader onClose={onClose}>
        <Title>Create New Customer</Title>
      </StyledHeader>
      <StyledBody>
        <LeftPane>
          <LeftPaneListItem
            selected={step === Step.BASIC_INFORMATION}
            disabled={step !== Step.BASIC_INFORMATION}
            text="1. Basic Information"
          />
          <LeftPaneListItem
            disabled={step < Step.PRIMARY_BUSINESS_LEVEL}
            selected={step === Step.PRIMARY_BUSINESS_LEVEL}
            text="2. Set Primary Business Level and Seed Role Mappings"
          />
        </LeftPane>
        <RightPane>
          {step === Step.BASIC_INFORMATION && (
            <>
              <StyledHelperText>
                Customer details must be unique
              </StyledHelperText>
              <StyledTextField
                label="Name"
                onChange={handleNameChange}
                required
                value={name}
              />
              <br />
              <StyledHelperText>
                Key must match an already-created RDB Schema, ex: [key]_db
              </StyledHelperText>
              <StyledTextField
                label="Key"
                onChange={handleKeyChange}
                required
                value={key}
              />
            </>
          )}
          {step === Step.PRIMARY_BUSINESS_LEVEL && (
            <>
              <StyledHelperText>
                Set Primary Business Level for {newCustomerResult?.name} (id ={' '}
                {newCustomerResult?.id})
              </StyledHelperText>
              <br />
              <StyledHelperText>
                Product Bundle: Store Operations
              </StyledHelperText>
              <SingleSelect
                handleChange={(e) =>
                  setPrimaryBusinessLevel(e.target.value as BusinessLevel)
                }
                label="Primary Business Level"
                value={primaryBusinessLevel}
                aria-label="Primary Business Level"
                options={BUSINESS_LEVEL_OPTIONS}
              />
            </>
          )}
          {error && <AlertMessageBlock message={error} severityType="error" />}
          <SideTabFooter>
            <FooterActions>
              <Button onClick={onClose} variant="secondary">
                Cancel
              </Button>
              <Button
                disabled={!validateFields()}
                loading={loading}
                onClick={handleOnSave}>
                Save and Continue
              </Button>
            </FooterActions>
          </SideTabFooter>
        </RightPane>
      </StyledBody>
    </Dialog>
  )
}
