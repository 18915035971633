import Dialog, { Body, Header, Title } from 'app/components/Dialog/Dialog'
import useKeyPress from 'app/hooks/useKeyPress'
import {
  asDate,
  getLineColor,
} from 'app/packages/fileUploads/pages/dashboard/dashboard.helpers'
import { UploadResults } from 'app/packages/fileUploads/pages/dashboard/dashboard.types'
import { colorPalette } from 'app/styles'
import React from 'react'

const contentStyles = {
  background: colorPalette.neutral_01,
  borderRadius: '4px',
  padding: '8px',
  overflow: 'auto',
  height: '350px',
  marginBottom: '20px',
}

const hrStyles = {
  borderStyle: 'dashed',
  color: colorPalette.neutral_08,
}

const codeStyles = {
  color: colorPalette.validation,
  fontSize: '1.3em',
  fontWeight: 'bold',
  fontFamily: 'monospace',
  display: 'block',
  whiteSpace: 'pre',
  lineHeight: '1.6em',
} as React.CSSProperties

export interface Props {
  job: UploadResults
  onClose: () => void
}

export default function JobResultModal({ job, onClose }: Props) {
  const { fileName, fileType, id, log, status, taskId, updatedAt } = job

  useKeyPress('Escape', onClose)

  return (
    <Dialog onClose={onClose} isOpen size="xl">
      <Header onClose={onClose}>
        <Title>{fileType}</Title>
      </Header>
      <Body>
        <div style={contentStyles}>
          {log?.split('\n').map((line, index) => {
            return (
              <code
                key={line || index.toString()}
                style={{
                  ...codeStyles,
                  ...getLineColor(status, line),
                }}>
                {line}
              </code>
            )
          })}
          <br />
          <hr style={hrStyles} />
          <code style={codeStyles}>ID: {id}</code>
          <code style={codeStyles}>TaskId: {taskId}</code>
          <code style={codeStyles}>File(s): {fileName}</code>
          <code style={codeStyles}>Last Updated: {asDate(updatedAt)}</code>
        </div>
      </Body>
    </Dialog>
  )
}
