import { useAuthState, useIsAuthenticated } from 'app/state/authentication'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RedirectWhenAuthenticated() {
  const authState = useAuthState()
  const isAuthenticated = useIsAuthenticated(authState)
  const nextLocationRef = useRef(authState.nextLocation || '/')
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      authState.nextLocation = null
      navigate(nextLocationRef.current)
    }
  }, [authState, isAuthenticated, navigate, nextLocationRef])

  return null
}
