import {
  StyledList,
  StyledVirtualizedList,
} from 'app/components/VirtualizedList/styles'
import { ROW_HEIGHT_NUMBER } from 'app/components/VirtualizedList/VirtualizedList.constants'
import { VirtualizedListProps } from 'app/components/VirtualizedList/VirtualizedList.types'
import { colorPalette } from 'app/styles'
import React, { useCallback, useEffect, useRef } from 'react'
import { defaultRangeExtractor, Range, useVirtual } from 'react-virtual'

export interface ExtendedVirtualizedListProps {
  variableHeight?: number
}

export default function VirtualizedList<
  P extends ExtendedVirtualizedListProps
>({
  className = '',
  fetchMore,
  height,
  overscan = 10,
  renderRow,
  rows = [],
  stickyIndices = [],
  useStickyHeaders = false,
  useVariableHeight = false,
}: VirtualizedListProps<P>) {
  const activeStickyIndexRef = useRef<number>(0)
  const parentRef = useRef<HTMLDivElement>(null)

  const isActiveStickyRow = (index: number) =>
    stickyIndices.includes(index) && activeStickyIndexRef.current === index
  const isStickyRow = (index: number) => stickyIndices.includes(index)
  const numOfRows = rows.length

  const rowVirtualizer = useVirtual({
    estimateSize: useCallback(
      (index: number) => {
        if (useVariableHeight && !!rows.length) {
          // use variableHeight when each element has a unique,
          // knowable dimension at render time.
          return rows[index].original?.variableHeight ?? ROW_HEIGHT_NUMBER
        }

        return ROW_HEIGHT_NUMBER
      },
      [rows, useVariableHeight],
    ),
    overscan,
    parentRef,
    rangeExtractor: useCallback(
      (range: Range) => {
        if (useStickyHeaders) {
          activeStickyIndexRef.current =
            [...stickyIndices]
              .reverse()
              .find((index) => range.start >= index) || 0

          const next = new Set([
            activeStickyIndexRef.current,
            ...defaultRangeExtractor(range),
          ])

          return [...next].sort((a, b) => a - b)
        }

        return []
      },
      [stickyIndices, useStickyHeaders],
    ),
    size: numOfRows,
  })

  // infinite scroll support
  useEffect(() => {
    if (!fetchMore) {
      return
    }

    const [lastItem] = [...rowVirtualizer.virtualItems].reverse()

    if (lastItem?.index >= numOfRows - 1) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchMore()
    }
  }, [fetchMore, numOfRows, rowVirtualizer.virtualItems])

  return (
    <StyledList
      className={className}
      data-testid="VirtualizedList"
      height={height}
      ref={parentRef}>
      <StyledVirtualizedList totalSize={rowVirtualizer.totalSize}>
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return renderRow({
            index: virtualRow.index || 0,
            isLastRow: virtualRow.index === numOfRows - 1,
            isSticky: isStickyRow(virtualRow.index || 0),
            key: virtualRow.index || 0,
            style: {
              height: `${virtualRow.size}px`,
              left: 0,
              top: 0,
              width: '100%',
              ...(isStickyRow(virtualRow.index)
                ? {
                    backgroundColor: colorPalette.highlight_07,
                    color: colorPalette.secondary,
                    cursor: 'initial',
                    fontWeight: 700,
                    zIndex: 1,
                    transform: `translateY(${virtualRow.start}px)`,
                  }
                : {}),
              ...(isActiveStickyRow(virtualRow.index)
                ? {
                    position: 'sticky',
                    transform: undefined,
                  }
                : {
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`,
                  }),
            },
          })
        })}
      </StyledVirtualizedList>
    </StyledList>
  )
}
