/* eslint-disable no-param-reassign */
import {
  GetOrderDeliveryScheduleIssuesQuery,
  GetOrderDeliveryScheduleIssuesQueryVariables,
  IllogicalDeliverySequence,
  OrderDeliveryEvent,
  OrderDeliveryEventOverlapsWithStoreClosure,
  OverlappingOrderDeliveryEvents,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { ILLOGICAL_DELIVERY_SEQUENCE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/IllogicalDeliverySequenceFields'
import { ORDER_DELIVERY_EVENT_OVERLAPS_WITH_STORE_CLOSURE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/OrderDeliveryEventOverlapsWithStoreClosureFields'
import { OVERLAPPING_ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/OverlappingOrderDeliveryEventFields'
import { SCHEDULE_ISSUES_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/ScheduleIssuesFields'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import {
  IssueType,
  OrderDeliverySchedulesIssues,
  OrderDeliverySchedulesIssuesByEvents,
  OrderDeliverySchedulesIssuesByEventsAndWorkflows,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { isEqual } from 'lodash-es'
import tuple from 'shared/tuple'

export const GET_ORDER_DELIVERY_SCHEDULE_ISSUES = gql`
  ${SCHEDULE_ISSUES_FIELDS}
  ${ORDER_DELIVERY_EVENT_OVERLAPS_WITH_STORE_CLOSURE_FIELDS}
  ${ILLOGICAL_DELIVERY_SEQUENCE_FIELDS}
  ${OVERLAPPING_ORDER_DELIVERY_EVENT_FIELDS}
  query getOrderDeliveryScheduleIssues($scheduleId: ID!) {
    node(id: $scheduleId) {
      _id
      ... on OrderDeliverySchedule {
        _id
        hasBeenEdited
        issues {
          edges {
            node {
              ...ScheduleIssuesFields
              ... on OrderDeliveryEventOverlapsWithStoreClosure {
                ...OrderDeliveryEventOverlapsWithStoreClosureFields
              }
              ... on IllogicalDeliverySequence {
                ...IllogicalDeliverySequenceFields
              }
              ... on OverlappingOrderDeliveryEvents {
                ...OverlappingOrderDeliveryEventFields
              }
            }
          }
          totalCount
        }
      }
    }
  }
`

interface SetIssueTypeProps {
  byEvents: OrderDeliverySchedulesIssuesByEvents
  issueDate: string
  issueType:
    | 'IllogicalDeliverySequence'
    | 'OrderDeliveryEventOverlapsWithStoreClosure'
    | 'OverlappingOrderDeliveryEvents'
  orderDeliveryEvent: OrderDeliveryEvent
}

function setIssueType({
  byEvents,
  issueDate,
  issueType,
  orderDeliveryEvent,
}: SetIssueTypeProps) {
  const isDeliveryIssue = isEqual(orderDeliveryEvent?.delivery?.date, issueDate)
  const isOrderIssue = isEqual(orderDeliveryEvent?.order?.date, issueDate)

  const currentEvent = byEvents[orderDeliveryEvent._id]

  if (currentEvent) {
    if (isDeliveryIssue) {
      currentEvent.deliveryIssues.push(IssueType[issueType])
    }

    if (isOrderIssue) {
      currentEvent.orderIssues.push(IssueType[issueType])
    }
  } else {
    byEvents[orderDeliveryEvent._id] = {
      deliveryIssues: isDeliveryIssue ? [IssueType[issueType]] : [],
      orderIssues: isOrderIssue ? [IssueType[issueType]] : [],
    }
  }

  return byEvents
}

export default function useGetOrderDeliveryScheduleIssues() {
  const { isEditing, scheduleId } = useGetOrderDeliverySchedule()

  const { data, loading } = useQuery<
    GetOrderDeliveryScheduleIssuesQuery,
    GetOrderDeliveryScheduleIssuesQueryVariables
  >(GET_ORDER_DELIVERY_SCHEDULE_ISSUES, {
    fetchPolicy: 'no-cache',
    skip: !scheduleId || !isEditing,
    variables: {
      scheduleId: scheduleId!,
    },
  })

  let orderDeliveryScheduleIssues: OrderDeliverySchedulesIssues = {
    hasBeenEdited: false,
    issuesByWorkflows: {},
    issuesByEvents: {},
    totalCount: 0,
  }

  if (
    data &&
    data.node &&
    data.node.__typename === 'OrderDeliverySchedule' &&
    data.node.issues
  ) {
    const mappedIssues = data.node.issues.edges.reduce<
      OrderDeliverySchedulesIssuesByEventsAndWorkflows
    >(
      ({ byEvents, byWorkflows }, { node }) => {
        const issueDate = node!.date
        const issueType = node!.__typename
        const workflowId = node!.workflow?._id
        const workflowName = `${node!.workflow.name} - ${
          node!.workflow.department.name
        }`
        const store = {
          ...toStore(node!.store),
          totalOccurrences: 1,
        }

        if (
          byWorkflows[workflowId] &&
          byWorkflows[workflowId].storeIssuesByDate[issueDate]
        ) {
          const issuesByDate =
            byWorkflows[workflowId].storeIssuesByDate[issueDate]
          const currentStore = issuesByDate[store.id]

          if (currentStore) {
            currentStore.totalOccurrences += 1
          } else {
            issuesByDate[store.id] = store
          }
        } else if (byWorkflows[workflowId]) {
          byWorkflows[workflowId].storeIssuesByDate[issueDate] = {
            [store.id]: store,
          }
        } else {
          byWorkflows[workflowId] = {
            id: workflowId,
            name: workflowName,
            storeIssuesByDate: {
              [issueDate]: { [store.id]: store },
            },
          }
        }

        if (IssueType[issueType] === IssueType.IllogicalDeliverySequence) {
          const {
            firstOrderDeliveryEvent,
            secondOrderDeliveryEvent,
          } = node! as IllogicalDeliverySequence

          const firstEvent = byEvents[firstOrderDeliveryEvent._id]
          const secondEvent = byEvents[secondOrderDeliveryEvent._id]

          if (firstEvent) {
            firstEvent.deliveryIssues = [
              ...firstEvent.deliveryIssues,
              IssueType[issueType],
            ]
            firstEvent.orderIssues = [
              ...firstEvent.orderIssues,
              IssueType[issueType],
            ]
          } else {
            byEvents[firstOrderDeliveryEvent._id] = {
              deliveryIssues: [IssueType[issueType]],
              orderIssues: [IssueType[issueType]],
            }
          }

          if (secondEvent) {
            secondEvent.deliveryIssues = [
              ...secondEvent.deliveryIssues,
              IssueType[issueType],
            ]
            secondEvent.orderIssues = [
              ...secondEvent.orderIssues,
              IssueType[issueType],
            ]
          } else {
            byEvents[secondOrderDeliveryEvent._id] = {
              deliveryIssues: [IssueType[issueType]],
              orderIssues: [IssueType[issueType]],
            }
          }
        }

        if (
          IssueType[issueType] ===
          IssueType.OrderDeliveryEventOverlapsWithStoreClosure
        ) {
          const {
            orderDeliveryEvent,
          } = node! as OrderDeliveryEventOverlapsWithStoreClosure

          byEvents = setIssueType({
            byEvents,
            issueDate,
            issueType,
            orderDeliveryEvent,
          })
        }

        if (IssueType[issueType] === IssueType.OverlappingOrderDeliveryEvents) {
          const {
            orderDeliveryEvents,
          } = node! as OverlappingOrderDeliveryEvents

          orderDeliveryEvents.forEach((orderDeliveryEvent) => {
            byEvents = setIssueType({
              byEvents,
              issueDate,
              issueType,
              orderDeliveryEvent,
            })
          })
        }

        return { byEvents, byWorkflows }
      },
      {
        byEvents: {},
        byWorkflows: {},
      },
    )

    orderDeliveryScheduleIssues = {
      hasBeenEdited: data.node.hasBeenEdited,
      issuesByEvents: mappedIssues.byEvents,
      issuesByWorkflows: mappedIssues.byWorkflows,
      totalCount: data.node.issues.totalCount ?? 0,
    }
  }

  return tuple([orderDeliveryScheduleIssues, loading])
}
