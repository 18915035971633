import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

interface ButtonOptions {
  label: string
  value: string
  disabled?: boolean
}

export interface RadioButtonsProps {
  buttons: ButtonOptions[]
  disabled?: boolean
  className?: string
  value?: string
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
  required?: boolean
  row?: boolean
  label?: string
  name?: string
  showLabel?: boolean
}

const StyledRadio = styled(Radio)`
  color: ${({ theme }) => theme.colors.secondary};
  margin: ${toRem(4)};
  height: ${toRem(32)};
  width: ${toRem(32)};

  &&& .MuiSvgIcon-root {
    height: ${toRem(22)};
    width: ${toRem(22)};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
    color: ${({ theme }) => theme.colors.neutral_03};
  }

  &:active {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &.Mui-checked {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyleLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.colors.secondary};
  font-weight: bold;
  margin-top: 8px;

  &.Mui-focused {
    color: ${({ theme }) => theme.colors.secondary};
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  padding-right: ${toRem(8)};
  &:has(> .Mui-focusVisible) {
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    border-radius: 2px;
  }
`
export default function RadioButtons({
  buttons,
  className,
  disabled,
  onChange,
  value,
  required,
  row = true,
  label,
  name = '',
  showLabel,
}: RadioButtonsProps) {
  return (
    <FormControl className={className} aria-label={label} disabled={disabled}>
      {showLabel && <StyleLabel required={required}>{label}</StyleLabel>}
      <RadioGroup row={row} value={value}>
        {buttons.map((button) => {
          return (
            <StyledFormControlLabel
              data-checked={button.value === value}
              control={<StyledRadio name={name} onChange={onChange} />}
              disabled={button.disabled}
              key={button.label}
              label={button.label}
              value={button.value}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
