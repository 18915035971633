import { useQuery } from '@apollo/client'
import { Box, Paper } from '@mui/material'
import fileUploadsDashboardGql from 'app/packages/fileUploads/api/layout/queries/fileUploadsDashboard'
import FileUploadsSideNav from 'app/packages/fileUploads/pages/dashboard/FileUploadsSideNav/FileUploadsSideNav'
import React, { useEffect } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { DASHBOARD_ROOT_URI, ROOT_SCREEN } from './dashboard.constants'
import DataImportScripts from './DataImportScripts/DataImportScripts'
import RunningIntegrationJobList from './IntegrationJobList/RunningIntegrationJobList'

const Main = styled.main`
  grid-column: 2/4;
  min-width: 980px;
`

export default function FileUploadsDashboard() {
  const navigate = useNavigate()
  const location = useLocation()
  const { data } = useQuery(fileUploadsDashboardGql)

  useEffect(() => {
    if (location.pathname.replace(/\/$/, '') === ROOT_SCREEN) {
      navigate(DASHBOARD_ROOT_URI)
    }
  }, [location, navigate])

  if (!data) {
    return null
  }

  return (
    <Box display="grid" gridTemplateColumns="256px 1fr">
      <Box gridColumn="1">
        <FileUploadsSideNav />
      </Box>
      <Main>
        <Box
          sx={{
            backgroundColor: (theme) => theme.colors.neutral_08,
            height: '100vh',
            maxWidth: '100%',
            padding: 0,
            width: '100%',
          }}>
          <Paper
            sx={{
              boxShadow: 'none',
              height: '100%',
              margin: '0 auto',
              maxWidth: 1600,
              overflow: 'auto',
              padding: '14px 24px',
            }}>
            <Routes>
              <Route
                path="/integrations/:script"
                element={<DataImportScripts />}
              />
              <Route
                path="/integrations"
                element={<RunningIntegrationJobList />}
              />
            </Routes>
          </Paper>
        </Box>
      </Main>
    </Box>
  )
}
