import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.95996 8.97021V21.0402H6.02996V10.9702H11.03V21.0402H21.03V8.97021H2.95996ZM15.74 18.0402V15.3902H13.53L16.92 10.9702V13.6202H18.83L15.74 18.0402Z"
      fill="currentColor"
    />
    <path
      d="M12.3105 5.79021V2.97021H16.6905V5.78022C16.6905 6.36021 16.4605 6.92021 16.0505 7.33021C15.6405 7.74021 15.0805 7.97021 14.5005 7.97021C13.9205 7.97021 13.3605 7.74021 12.9505 7.33021C12.5405 6.92021 12.3105 6.36021 12.3105 5.78022V5.79021Z"
      fill="currentColor"
    />
    <path
      d="M21.9998 4.85021V5.63021C21.9998 6.25022 21.7498 6.85021 21.3098 7.29021C20.8698 7.73021 20.2698 7.98022 19.6498 7.98022C19.0298 7.98022 18.4298 7.73021 17.9898 7.29021C17.5498 6.85021 17.2998 6.25022 17.2998 5.63021V2.97021H20.1098L21.9798 4.84021L21.9998 4.85021Z"
      fill="currentColor"
    />
    <path
      d="M7.3098 5.79021V2.97021H11.6798V5.78022C11.6798 6.36021 11.4498 6.92021 11.0398 7.33021C10.6298 7.74021 10.0698 7.97021 9.4898 7.97021C8.9098 7.97021 8.3498 7.74021 7.9398 7.33021C7.5298 6.92021 7.2998 6.36021 7.2998 5.78022L7.3098 5.79021Z"
      fill="currentColor"
    />
    <path
      d="M4.34023 7.97021C3.72023 7.97021 3.12023 7.72022 2.68023 7.28022C2.24023 6.84022 1.99023 6.24021 1.99023 5.62021V4.84021L3.87023 2.97021H6.68023V5.63021C6.68023 6.25022 6.43023 6.85021 5.99023 7.29021C5.55023 7.73021 4.95023 7.98022 4.33023 7.98022L4.34023 7.97021Z"
      fill="currentColor"
    />
  </svg>,
  'StoreOperations',
)
