import * as Sentry from '@sentry/react'
import useGetProductBundles from 'app/api/queries/useGetProductBundles'
import { useSearchParamsState } from 'app/hooks/useSearchParamsState'

export const initializeAnalytics = (
  userId: string,
  scope: string,
  accessRole: string,
  customerId?: string,
  customerKey?: string,
  departmentKey?: string,
  storeId?: string,
) => {
  const traits = {
    userId,
    scope,
    accessRole,
    customerId,
    customerKey,
    departmentKey,
    storeId,
  }

  const captureSegmentAnalyticsSetupFailed = (e: any) => {
    try {
      Sentry.captureException(e, (sentryScope) => {
        sentryScope.setTransactionName('Segment analytics setup failed')
        sentryScope.setLevel('info')
        return sentryScope
      })
    } catch (errorWithSentry) {
      // No-op, just being extra safe
    }
  }

  try {
    // Initialize Segment SDK
    window.analytics.identify(userId, traits)

    // Segment doesn't add the traits to every event by default.
    // @ts-ignore Our typings are for V1 of analytics.js and don't know about `analytics.register`
    window.analytics
      .register({
        name: 'Add traits to events',
        type: 'enrichment',
        version: '1.0.0',
        isLoaded: () => true,
        load: () => Promise.resolve(),
        track: (ctx: any) => {
          const { event } = ctx
          event.context.traits = traits
          return ctx
        },
      })
      .catch(captureSegmentAnalyticsSetupFailed)
  } catch (e) {
    captureSegmentAnalyticsSetupFailed(e)
  }
}

export function useGetDepartmentBundleName() {
  const { activeProductBundle } = useGetProductBundles()
  const [deptBundleId] = useSearchParamsState('deptBundleId')

  return (
    activeProductBundle?.departmentBundles?.find((department) => {
      return department.id === deptBundleId
    })?.name ?? ''
  )
}

export function useNavigationProperties() {
  const departmentBundleName = useGetDepartmentBundleName()

  return {
    departmentBundleName,
  }
}
