import { Box, Card } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import makeStyles from '@mui/styles/makeStyles'
import LogoWithEnvironment from 'app/components/Logo/LogoWithEnvironment'
import React from 'react'

const MOBILE_HORIZ_MARGIN = 16

function useStyles(panelWidth: number) {
  // Media query for desktop breakpoint. Account for the left/right margins.
  const breakpoint = `(min-width: ${panelWidth + 2 * MOBILE_HORIZ_MARGIN}px)`

  return {
    classes: makeStyles({
      card: {
        boxShadow: 'none',
        marginBottom: 16,
        marginTop: 16,
        marginLeft: MOBILE_HORIZ_MARGIN,
        marginRight: MOBILE_HORIZ_MARGIN,
        padding: 24,
        [`@media ${breakpoint}`]: {
          marginBottom: 40,
          marginTop: 40,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: 32,
          width: panelWidth,
        },
      },
    })(),
    isDesktop: useMediaQuery(breakpoint),
  }
}

interface Props {
  width: number
  children: React.ReactNode
}

export default function SingleCardPage(props: Props) {
  const { classes } = useStyles(props.width)
  return (
    <Card className={classes.card}>
      <Box marginBottom={6}>
        <LogoWithEnvironment />
      </Box>
      {props.children}
    </Card>
  )
}
