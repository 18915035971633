import { InputAdornment, OutlinedInput } from '@mui/material'
import Button from 'app/components/Button/Button'
import Close from 'app/components/Icons/Close'
import Search from 'app/components/Icons/Search'
import usePrevious from 'app/hooks/usePrevious'
import { debounce } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const StyledInput = styled(OutlinedInput)`
  padding: 5px 15px 5px 5px;
  height: 40px;
  font-size: 0.875em;
  width: 200px;
  padding-left: 0;

  &.MuiInputBase-adornedEnd {
    padding-right: 28px;
  }

  & .MuiInputAdornment-positionEnd {
    margin: 0 4px 0 8px;
  }
`

const StyledCancelButton = styled(InputAdornment)`
  position: absolute;
  right: 12px;
`

const StyledIconButton = styled(Button)`
  color: ${({ theme }) => theme.colors.secondary};

  &:hover {
    color: ${({ theme }) => theme.colors.neutral_01};
  }
`

export interface Props {
  autoFocus?: boolean
  className?: string
  stopPropagation?: boolean
  onSearchTextChange: (searchText: string) => void
  placeholderText?: string
  defaultValue?: string
}

export default function SearchBox({
  autoFocus = false,
  className = '',
  stopPropagation = false,
  onSearchTextChange,
  placeholderText = 'Search...',
  defaultValue = '',
}: Props) {
  const [value, setValue] = useState(() => defaultValue)
  const prevDefault = usePrevious(defaultValue) || ''

  if (defaultValue !== value && defaultValue !== prevDefault) {
    setValue(defaultValue)
  }

  // https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
  const debouncedChangeHandler = useMemo(
    () =>
      debounce((onChangeFn: typeof onSearchTextChange, text: string) => {
        onChangeFn(text)
      }, 200),
    [],
  )

  const clearSearch = useCallback(() => {
    setValue('')
    debouncedChangeHandler.cancel()
    debouncedChangeHandler(onSearchTextChange, '')
  }, [debouncedChangeHandler, onSearchTextChange])

  return (
    <StyledInput
      autoFocus={autoFocus}
      className={className}
      inputProps={{
        label: 'Search',
        'aria-label': 'Search',
      }}
      placeholder={placeholderText}
      startAdornment={
        <InputAdornment position="end">
          <Search fontSize="xs" />
        </InputAdornment>
      }
      endAdornment={
        value ? (
          <StyledCancelButton position="end">
            <StyledIconButton
              variant="icon"
              aria-label="Clear search"
              onClick={clearSearch}
              onMouseDown={clearSearch}
              edge="end">
              <Close fontSize="sm" />
            </StyledIconButton>
          </StyledCancelButton>
        ) : null
      }
      onKeyDown={(event) => {
        // prevent event propogation if its used in a context where we need to prevent other default behavior
        if (stopPropagation) event.stopPropagation()
      }}
      onChange={(event) => {
        setValue(event.target.value)
        debouncedChangeHandler(onSearchTextChange, event.target.value)
      }}
      sx={{
        paddingLeft: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: (theme) => theme.colors.neutral_05,
        },
      }}
      value={value}
    />
  )
}
