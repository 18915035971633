import { LinearProgress as MuiLinearProgress } from '@mui/material'
import styled from 'styled-components'

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  backgroundColor: theme.colors.neutral_05,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: theme.colors.neutral_03,
  },
}))

export default LinearProgress
