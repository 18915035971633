import {
  CreateGuideCategoryMutation,
  CreateGuideCategoryMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { getFlagValue, useFeatureFlags } from 'app/context/rolloutContext'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { msgByErrorCode } from 'app/packages/storeSolutions/guideOrganization/api/errors'
import { GUIDE_STRUCTURE_CATEGORY_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureCategoryFields'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import {
  InventoryConfiguration,
  OrderingConfiguration,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { CreateEditSectionState } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_GUIDE_STRUCTURE_CATEGORY = gql`
  ${GUIDE_STRUCTURE_CATEGORY_FIELDS}
  mutation createGuideCategory(
    $categoryName: String!
    $guideStructureId: ID!
    $parentId: ID!
    $primaryMappings: [ID!]
    $sortIndex: Int!
    $inventoryConfiguration: GuideStructureCategoryInventoryConfigurationInput
    $orderingConfiguration: GuideStructureCategoryOrderingConfigurationInput
  ) {
    createGuideStructureCategory(
      input: {
        categoryName: $categoryName
        guideStructureId: $guideStructureId
        parentId: $parentId
        primaryMappings: $primaryMappings
        sortIndex: $sortIndex
        inventoryConfiguration: $inventoryConfiguration
        orderingConfiguration: $orderingConfiguration
      }
    ) {
      guideStructureCategory {
        ...GuideStructureCategoryFields
      }
    }
  }
`

export default function useCreateCategory() {
  const { properties, showInventorySettings } = useGetProperties()
  const guideStructureId = properties?._id
  const isCategoryConfigurationVisible = getFlagValue(
    useFeatureFlags().isGuideOrganizationCategoryConfigurationEnabled,
  )
  const [mutation, result] = useMutation<
    CreateGuideCategoryMutation,
    CreateGuideCategoryMutationVariables
  >(CREATE_GUIDE_STRUCTURE_CATEGORY, {
    errorPolicy: 'none',
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors.length) {
        throw new Error(
          msgByErrorCode[getFirstErrorCode(graphQLErrors)] ||
            'Unable to process request.',
        )
      }

      if (networkError) {
        Sentry.captureMessage('Web Portal Network Error', {
          level: 'warning',
          extra: {
            networkError: JSON.stringify(networkError),
          },
        })

        throw new Error(
          `Unable to save due to the following network error: ${networkError}`,
        )
      }
    },
    refetchQueries: [
      'getGuideStructureCategories',
      'getGuideStructureProperties',
    ],
  })

  return tuple([
    useCallback(
      async ({
        categoryName,
        parentId,
        primaryMappings,
        sortIndex,
        orderingConfiguration,
        inventoryConfiguration,
      }: CreateEditSectionState) => {
        if (!guideStructureId) {
          throw new Error('Guide structure ID not found')
        }
        if (isCategoryConfigurationVisible) {
          const newInventoryConfiguration = showInventorySettings
            ? ({
                ...inventoryConfiguration,
              } as InventoryConfiguration)
            : undefined
          await mutation({
            variables: {
              categoryName,
              guideStructureId,
              parentId: parentId as string,
              primaryMappings: primaryMappings.map(({ _id }) => _id),
              sortIndex: Number(sortIndex),
              orderingConfiguration: orderingConfiguration as OrderingConfiguration,
              inventoryConfiguration: newInventoryConfiguration,
            },
          })
        } else {
          await mutation({
            variables: {
              categoryName,
              guideStructureId,
              parentId: parentId as string,
              primaryMappings: primaryMappings.map(({ _id }) => _id),
              sortIndex: Number(sortIndex),
            },
          })
        }
      },
      [
        guideStructureId,
        mutation,
        isCategoryConfigurationVisible,
        showInventorySettings,
      ],
    ),
    result,
  ])
}
