/* eslint-disable camelcase */
import { gql } from '@apollo/client'

export const USER_DETAILS = gql`
  fragment UserDetails on UserBase {
    _id
    id
    accessRole
    email
    userScope
    departmentBundles {
      ...UserDepartmentBundle
    }

    ... on User {
      customer {
        ...UserCustomer
      }
      department {
        ...UserDepartment
      }
      store {
        ...UserStore
      }
    }

    ... on CustomerUser {
      customer {
        ...UserCustomer
      }
      maybeStore: store {
        ...UserStore
      }
    }
  }

  fragment UserCustomer on Customer {
    id
    key
  }

  fragment UserDepartment on Department {
    id
    key
    name
  }

  fragment UserDepartmentBundle on DepartmentBundle {
    _id
    name
  }

  fragment UserStore on Store {
    id
    businessUnitId
    orderingGroup
  }
`

export const USER_STORE_AND_DEPARTMENT_BUNDLE = gql`
  fragment UserStoreAndDepartmentBundle on UserBase {
    _id
    ... on CustomerUser {
      maybeStore: store {
        _id
        customerStoreId
        name
      }
      departmentBundles {
        _id
        name
      }
    }
    ... on User {
      store {
        _id
        customerStoreId
        name
      }
      department {
        id
        name
      }
      departmentBundles {
        _id
        name
      }
    }
  }
`
