import { noop } from 'app/helpers/index'
import { useCallback, useEffect, useState } from 'react'

export default function useKeyPress(target: string, callback = noop) {
  const [isKeyPressed, setIsKeyPressed] = useState(false)

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === target) {
        setIsKeyPressed(true)
        callback()
      }
    },
    [callback, target],
  )

  const handleOnKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === target) {
        setIsKeyPressed(false)
      }
    },
    [target],
  )

  useEffect(() => {
    window.addEventListener('keydown', handleOnKeyDown)
    window.addEventListener('keyup', handleOnKeyUp)

    return () => {
      window.removeEventListener('keydown', handleOnKeyDown)
      window.removeEventListener('keyup', handleOnKeyUp)
    }
  }, [handleOnKeyDown, handleOnKeyUp])

  return isKeyPressed
}
