import {
  DisableOrderAutoPlaceForWorkflowMutation,
  DisableOrderAutoPlaceForWorkflowMutationVariables,
  DisableOrderAutoPlaceMutation,
  DisableOrderAutoPlaceMutationVariables,
  EnableOrderAutoPlaceForWorkflowMutation,
  EnableOrderAutoPlaceForWorkflowMutationVariables,
  EnableOrderAutoPlaceMutation,
  EnableOrderAutoPlaceMutationVariables,
  OrderAutoUpdateQuery,
  OrderAutoUpdateQueryVariables,
} from '@api/__gen__/gql'
import { useMutation, useQuery } from '@apollo/client'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { useCustomers } from 'app/CustomerContext'
import {
  DISABLE_ORDER_AUTO_PLACE,
  DISABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW,
  ENABLE_ORDER_AUTO_PLACE,
  ENABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW,
} from 'app/packages/internal/customerConfigs/orderAutoUpdate/api/mutations/autoPlace'
import { ORDER_AUTO_UPDATE } from 'app/packages/internal/customerConfigs/orderAutoUpdate/api/queries/autoUpdate'
import { toRem } from 'app/styles'
import React, { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: ${toRem(4)};
  height: ${toRem(28)};
`

export default function OrderAutoUpdate() {
  const { activeCustomerId } = useCustomers()
  const customerId = activeCustomerId || '' // type narrowing
  const { data } = useQuery<
    OrderAutoUpdateQuery,
    OrderAutoUpdateQueryVariables
  >(ORDER_AUTO_UPDATE, { variables: { customerId } })

  // Flags that indicate whether an update is in progress for a control.
  // Controls are keyed by workflow ID except for the global one, which uses an
  // empty string.
  //
  // In order to update the state correctly when switches are toggled rapidly,
  // use a ref to record the instantaneous states for each switch. This way
  // we're always setting the state to the correct values.
  const pendingRowsRef = useRef<Record<string, boolean>>({})
  const [pendingRows, setPendingRows] = useState(pendingRowsRef.current)
  const updatePendingRow = useCallback(
    (workflowId: string | null, isPending: boolean) => {
      pendingRowsRef.current[workflowId ?? ''] = isPending
      setPendingRows({ ...pendingRowsRef.current })
    },
    [pendingRowsRef],
  )

  const enableGlobal = useMutation<
    EnableOrderAutoPlaceMutation,
    EnableOrderAutoPlaceMutationVariables
  >(ENABLE_ORDER_AUTO_PLACE)[0]
  const disableGlobal = useMutation<
    DisableOrderAutoPlaceMutation,
    DisableOrderAutoPlaceMutationVariables
  >(DISABLE_ORDER_AUTO_PLACE)[0]

  const enableForWorkflow = useMutation<
    EnableOrderAutoPlaceForWorkflowMutation,
    EnableOrderAutoPlaceForWorkflowMutationVariables
  >(ENABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW)[0]
  const disableForWorkflow = useMutation<
    DisableOrderAutoPlaceForWorkflowMutation,
    DisableOrderAutoPlaceForWorkflowMutationVariables
  >(DISABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW)[0]

  const setGlobal = useCallback(
    async (enable: boolean) => {
      updatePendingRow(null, true)
      await (enable ? enableGlobal : disableGlobal)({
        variables: { customerId },
      })
      updatePendingRow(null, false)
    },
    [customerId, disableGlobal, enableGlobal, updatePendingRow],
  )

  const setForWorkflow = useCallback(
    async (enable: boolean, workflowId: string) => {
      updatePendingRow(workflowId, true)
      await (enable ? enableForWorkflow : disableForWorkflow)({
        variables: { customerId, workflowId },
      })
      updatePendingRow(workflowId, false)
    },
    [customerId, disableForWorkflow, enableForWorkflow, updatePendingRow],
  )

  if (!data || data.node?.__typename !== 'Customer') {
    return null
  }

  const config = data.node.orderAutoUpdate

  return (
    <>
      <Title>Order Auto-Update</Title>
      <Box>
        <p>
          An order is subject to auto-placement if the global toggle for the
          feature is enabled AND the toggle for the order’s workflow is enabled.
        </p>
        <p>
          Auto-place is enabled by default for all customers and ordering
          workflows. If a new workflow is added, auto-place will be enabled for
          it unless it’s explicitly disabled here.
        </p>
        <FormControlLabel
          control={
            <Switch
              checked={config.autoPlaceEnabled}
              onChange={async () => setGlobal(!config.autoPlaceEnabled)}
            />
          }
          label="Auto-Place Orders"
          disabled={pendingRows['']}
        />
        <Box paddingLeft={4}>
          {config.autoPlaceWorkflows.map(({ workflow, enabled }) => (
            <Box key={workflow._id}>
              <FormControlLabel
                control={
                  <Switch
                    checked={enabled}
                    onChange={async () =>
                      setForWorkflow(!enabled, workflow._id)
                    }
                  />
                }
                label={`(${workflow.id}) ${workflow.name}`}
                disabled={pendingRows[workflow._id]}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </>
  )
}
