import { UserDetailsFragment } from '@api/__gen__/gql'
import { Box } from '@mui/material'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import { useUserContext } from 'app/context/userContext'
import Email from 'app/packages/core/pages/login/Email/Email'
import {
  applyAuthentication,
  initializeUserWithExternalSDKs,
} from 'app/packages/core/pages/login/login.helpers'
import Password from 'app/packages/core/pages/login/Password/Password'
import RedirectWhenAuthenticated from 'app/packages/core/pages/login/RedirectWhenAuthenticated/RedirectWhenAuthenticated'
import { SetPassword } from 'app/packages/core/pages/login/RequestPasswordReset/RequestPasswordReset'
import { useAuthState } from 'app/state/authentication'
import React, { useCallback, useState } from 'react'

export default function Login() {
  const authState = useAuthState()
  const { setUser } = useUserContext()

  const [step, setStep] = useState<
    'EMAIL' | 'PASSWORD' | 'RESET_REQUIRED' | 'NOT_ALLOWED'
  >('EMAIL')
  const [email, setEmail] = useState('')

  const onAuthenticate = useCallback(
    async (
      accessToken: string,
      refreshToken: string,
      userRaw: UserDetailsFragment | null,
    ) => {
      const success = await applyAuthentication(
        authState,
        accessToken,
        refreshToken,
      )
      if (userRaw) {
        const user = setUser(userRaw)
        initializeUserWithExternalSDKs(user)
      }

      if (!success) {
        setStep('NOT_ALLOWED')
      }
    },
    [authState, setUser],
  )

  return (
    <>
      <RedirectWhenAuthenticated />
      <SingleCardPage width={480}>
        {step === 'EMAIL' && (
          <Email
            onEmailSubmit={(e) => {
              setStep('PASSWORD')
              setEmail(e)
            }}
            onNeedsReset={(e) => {
              setEmail(e)
              setStep('RESET_REQUIRED')
            }}
          />
        )}
        {step === 'PASSWORD' && (
          <Password
            email={email}
            onAuthenticate={onAuthenticate}
            onChangeEmail={() => {
              setStep('EMAIL')
              setEmail('')
            }}
          />
        )}
        {step === 'RESET_REQUIRED' && (
          <SetPassword
            email={email}
            onChangeEmail={() => {
              setStep('EMAIL')
              setEmail('')
            }}
          />
        )}
        {step === 'NOT_ALLOWED' && (
          <Box>Access restricted: Only Afresh admins can log in.</Box>
        )}
      </SingleCardPage>
    </>
  )
}
