import { ConfigurationName, ConfigurationType } from '@api/__gen__/gql'
import { DialogContent } from '@mui/material'
import useGetAllWorkflows from 'app/api/queries/useGetAllWorkflows'
import { useGetStores } from 'app/api/queries/useGetStores'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  SubTitle,
  Title,
} from 'app/components/Dialog/Dialog'
import Delete from 'app/components/Icons/Delete'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TextField from 'app/components/TextField/TextField'
import { isNilOrEmpty } from 'app/helpers'
import useDraftState from 'app/hooks/useDraftState'
import { useCreateConfiguration } from 'app/packages/internal/customerConfigs/configurations/api/mutations/useCreateConfiguration'
import { useUpdateConfiguration } from 'app/packages/internal/customerConfigs/configurations/api/mutations/useUpdateConfiguration'
import { ConfigurationsRow } from 'app/packages/internal/customerConfigs/configurations/api/queries/useGetConfigurations'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { toRem } from 'app/styles'
import { isNaN } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const FIELD_WIDTH = 320
const NAME_FIELD_WIDTH = 600

export const StyledInnerWrapper = styled.div`
  max-height: ${toRem(450)};
`

const StyledDialogContent = styled(DialogContent)`
  overflow: hidden;
  padding: 0;
  margin-top: ${toRem(-16)};
`

const SelectFieldContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: ${toRem(16)};
  width: ${toRem(FIELD_WIDTH)};
  gap: ${toRem(16)};
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export const StyledAutocomplete = styled(Autocomplete)`
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export interface ConfigurationsCreateEditModalProps {
  modalState: ModalState
  activeRow?: ConfigurationsRow
  rows: ConfigurationsRow[]
  onClose: () => void
  onSuccess: () => void
  onDelete?: () => void
  configTypes: ConfigurationType[]
  configTypeOptions: Option[]
}

interface FormValues {
  configName: string
  value: string
  storeId: string | null
  workflowId: string | null
}

function getInitialFormState(activeRow?: ConfigurationsRow): FormValues {
  if (!activeRow) {
    return {
      configName: '',
      value: '',
      storeId: null,
      workflowId: null,
    }
  }

  // get initial values for active row
  return {
    configName: activeRow.configurationType.name,
    value: activeRow.value,
    storeId: activeRow.store?._id ?? null,
    workflowId: activeRow.workflow?._id ?? null,
  }
}

function validateFormValues(
  configType: ConfigurationType | undefined,
  formValues: FormValues,
  rows: ConfigurationsRow[],
) {
  if (!configType || formValues.configName === '' || formValues.value === '') {
    return false
  }

  // validate unique configuration against exisiting rows
  if (
    rows.some((row) => {
      return (
        row.configurationType.name === formValues.configName &&
        row.value === formValues.value &&
        (row.store?._id || null) === formValues.storeId &&
        (row.workflow?._id || null) === formValues.workflowId
      )
    })
  ) {
    return false
  }

  // validate boolean type
  if (
    configType.valueType === 'BOOLEAN' &&
    formValues.value !== 'true' &&
    formValues.value !== 'false'
  ) {
    return false
  }

  // validate integer type
  if (configType.valueType === 'INT' && isNaN(+formValues.value)) {
    return false
  }

  return true
}

function sortStores(a: Store, b: Store) {
  const aStoreNumber = +a.name.split(' ')[0]
  const bStoreNumber = +b.name.split(' ')[0]
  return aStoreNumber - bStoreNumber
}

export default function ConfigurationsAddEditModal({
  modalState,
  activeRow,
  rows,
  configTypes,
  configTypeOptions,
  onClose,
  onSuccess,
  onDelete,
}: ConfigurationsCreateEditModalProps) {
  const [createConfigurations] = useCreateConfiguration()
  const [updateConfigurations] = useUpdateConfiguration()
  const [formValues, setFormValues] = useDraftState(
    getInitialFormState(activeRow),
  )

  const { data: workflows } = useGetAllWorkflows()

  const { stores } = useGetStores()

  const workflowOptions = useMemo(() => {
    return [
      { label: '--', value: '' },
      ...workflows.map((workflow) => ({
        label: `${workflow.databaseId} - ${workflow.workflowName}`,
        value: workflow.id,
      })),
    ]
  }, [workflows])

  const storeOptions = useMemo(() => {
    return stores.sort(sortStores).map((store) => ({
      label: store.name,
      value: store.id,
    }))
  }, [stores])

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const activeConfigType = useMemo(() => {
    return configTypes.find(
      (configType) => configType.name === formValues.configName,
    )
  }, [configTypes, formValues.configName])

  const [formValid, setFormValid] = useState(
    validateFormValues(activeConfigType, formValues, rows),
  )

  useEffect(() => {
    setFormValid(validateFormValues(activeConfigType, formValues, rows))
  }, [formValues, activeConfigType, setFormValid, rows])

  useEffect(() => {
    setFormValues((draft) => {
      if (
        activeConfigType?.valueType !== 'BOOLEAN' &&
        (draft.value === 'true' || draft.value === 'false')
      ) {
        draft.value = ''
      }
    })
  }, [activeConfigType, setFormValues])

  const handleSave = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      if (!activeRow) {
        await createConfigurations({
          name: formValues.configName as ConfigurationName,
          value: formValues.value,
          storeId: formValues.storeId,
          workflowId: formValues.workflowId,
        })
      } else {
        await updateConfigurations({
          id: activeRow._id,
          name: formValues.configName as ConfigurationName,
          value: formValues.value,
          storeId: formValues.storeId,
          workflowId: formValues.workflowId,
        })
      }
      onSuccess()
    } catch (e) {
      setErrorMessage((e as Error).message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>{`${modalState} Workflow Availability`}</Title>
        {!activeConfigType && <SubTitle>Select a configuration type.</SubTitle>}
      </Header>
      <Body>
        <StyledDialogContent>
          <SelectFieldContainer>
            <SingleSelect
              width={NAME_FIELD_WIDTH}
              required
              disabled={isNilOrEmpty(configTypeOptions)}
              options={configTypeOptions ?? []}
              value={formValues.configName}
              label="Configuration Name"
              handleChange={(e) =>
                setFormValues((draft) => {
                  draft.configName = e.target.value
                })
              }
            />
            {activeConfigType?.allowedDimensions.includes('WORKFLOW') && (
              <SingleSelect
                width={FIELD_WIDTH}
                disabled={isNilOrEmpty(workflowOptions)}
                options={workflowOptions ?? []}
                value={formValues.workflowId || ''}
                label="Workflow"
                handleChange={(e) =>
                  setFormValues((draft) => {
                    draft.workflowId = e.target.value
                  })
                }
              />
            )}
            {activeConfigType?.allowedDimensions.includes('STORE') && (
              <StyledAutocomplete
                options={storeOptions ?? []}
                label="Store"
                value={
                  formValues.storeId
                    ? {
                        value: formValues.storeId || '',
                        label:
                          storeOptions.find(
                            (store) => store.value === formValues.storeId,
                          )?.label || 'All Stores',
                      }
                    : null
                }
                onChange={(_, value) => {
                  setFormValues((draft) => {
                    draft.storeId = value?.value || null
                  })
                }}
              />
            )}
            {activeConfigType && activeConfigType?.valueType === 'BOOLEAN' ? (
              <SingleSelect
                width={FIELD_WIDTH}
                required
                options={[
                  {
                    label: 'true',
                    value: 'true',
                  },
                  {
                    label: 'false',
                    value: 'false',
                  },
                ]}
                value={formValues.value}
                label="Value"
                handleChange={(e) => {
                  setFormValues((draft) => {
                    draft.value = e.target.value
                  })
                }}
              />
            ) : (
              activeConfigType && (
                <TextField
                  required
                  type="text"
                  value={formValues.value}
                  label={`Value (${activeConfigType.valueType})`}
                  onChange={(e) => {
                    setFormValues((draft) => {
                      draft.value = e.target.value
                    })
                  }}
                />
              )
            )}
          </SelectFieldContainer>
          {errorMessage && (
            <StyledErrorMessage message={errorMessage} severityType="error" />
          )}
        </StyledDialogContent>
      </Body>
      <Footer>
        {onDelete && (
          <FooterActions justify="start">
            <Button
              startIcon={<Delete />}
              onClick={onDelete}
              variant="tertiary">
              Delete
            </Button>
          </FooterActions>
        )}
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={loading || !formValid}
            loading={loading}
            onClick={handleSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
