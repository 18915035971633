import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import TableHeader from 'app/components/Tables/TableHeader'
import Tooltip from 'app/components/Tooltip/TableCellTooltip'
import { DepartmentBundleRow } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartmentBundles'
import { $activeDepartmentBundleConfig } from 'app/packages/internal/customerConfigs/departmentBundleManager/pages/DepartmentBundleManager.variables'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  padding-top: 12px;

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

export interface Props {
  isLoading?: boolean
  disableVirtualization?: boolean
  rows: DepartmentBundleRow[]
  setupDetailsExpanded: boolean
  onClickAddNew: () => void
}

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${toRem(300)};
  line-height: 0;
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export const UnloadOrderSequenceColumns: GridColDef[] = [
  {
    field: 'id',
    valueGetter: (_, row) => row.id,
    headerName: 'ID',
    width: 75,
  },
  {
    field: 'name',
    valueGetter: (_, row) => row.name,
    headerName: 'Name',
    width: 200,
  },
  {
    field: 'type',
    valueGetter: (_, row) => row.type,
    headerName: 'Type',
    width: 200,
  },
  {
    field: 'departments',
    valueGetter: (_, row) => row.departments,
    headerName: 'Departments',
    renderCell: (row) => {
      let departmentCopy = ''
      const { length } = row.value
      if (row.value.length === 0) departmentCopy = 'No departments'
      if (row.value.length === 1) departmentCopy = row.value[0].name
      if (row.value.length > 1)
        departmentCopy = `${row.value.length} departments`
      return (
        <Tooltip
          title={row.value?.map(({ name }: { name: string }, index: number) => {
            if (index === length - 1) {
              return name
            }

            return `${name}, `
          })}>
          <span>{departmentCopy}</span>
        </Tooltip>
      )
    },
    width: 250,
  },
  {
    field: 'products',
    valueGetter: (_, row) => row.products,
    headerName: 'Products',
    renderCell: (row) => {
      let productsCopy = ''
      const { length } = row.value
      if (row.value.length === 0) productsCopy = 'No products'
      if (row.value.length === 1) productsCopy = row.value[0].type
      if (row.value.length > 1) productsCopy = `${row.value.length} products`
      return (
        <Tooltip
          title={row.value?.map(({ type }: { type: string }, index: number) => {
            if (index === length - 1) {
              return type
            }

            return `${type}, `
          })}>
          <span>{productsCopy}</span>
        </Tooltip>
      )
    },
    width: 182,
  },
]

export default function DepartmentBundleManagerTable(props: Props) {
  const {
    isLoading,
    rows,
    onClickAddNew,
    setupDetailsExpanded,
    disableVirtualization = false,
  } = props

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <TableHeader>
        <StyledTableHeaderButtonsContainer>
          <StyledAddNewButton onClick={onClickAddNew}>
            <StyledAddNewIcon /> Add New
          </StyledAddNewButton>
        </StyledTableHeaderButtonsContainer>
      </TableHeader>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row._id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
        }}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        columns={UnloadOrderSequenceColumns}
        loading={isLoading}
        onRowClick={({ row }) => $activeDepartmentBundleConfig(row.id)}
      />
    </StyledTableContainer>
  )
}
