import { gql } from '@apollo/client'

export const ORDER_AUTO_UPDATE_FIELDS = gql`
  fragment OrderAutoUpdateFields on OrderAutoUpdate {
    autoPlaceEnabled
    autoPlaceWorkflows {
      workflow {
        _id
        id
        name
      }
      enabled
    }
  }
`
