import { AccessRole } from '@api/__gen__/gql'
import { Box, Typography } from '@mui/material'
import Button from 'app/components/Button/Button'
import PasswordReset from 'app/components/PasswordReset/PasswordReset'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

const StyledBox = styled(Box)`
  text-align: center;
`

export default function Welcome() {
  const navigate = useNavigate()
  const [role, setRole] = useState<AccessRole | null>(null)
  const params = useParams()
  const token = params.token ?? ''

  return (
    <SingleCardPage width={480}>
      {role === null ? (
        <PasswordReset
          token={token}
          onSuccess={(user) => setRole(user.accessRole)}
        />
      ) : (
        <>
          <Typography variant="h6" paragraph align="center">
            Password Saved
          </Typography>
          <StyledBox>
            {role === 'CUSTOMER_STORE' ? (
              'Please return to the app to log in.'
            ) : (
              <Button
                href="/"
                onClick={(e) => {
                  e.preventDefault()
                  navigate('/')
                }}>
                Continue
              </Button>
            )}
          </StyledBox>
        </>
      )}
    </SingleCardPage>
  )
}
