import { gql, useMutation } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { BUSINESS_LEVEL_RESULT } from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/api/fragments/BusinessLevelResult'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

const UPDATE_BUSINESS_LEVEL_CONFIG = gql`
  ${BUSINESS_LEVEL_RESULT}
  mutation updateBusinessLevelConfig(
    $payload: UpdateConfigBusinessLevelsInput!
  ) {
    updateConfigBusinessLevels(input: $payload) {
      results {
        ...BusinessLevelResult
      }
    }
  }
`

export default function useUpdateBusinessLevelConfig() {
  const { activeCustomerId } = useCustomers()
  const [mutation, result] = useMutation(UPDATE_BUSINESS_LEVEL_CONFIG, {
    onError: ({ graphQLErrors }) => {
      throw new Error(getFirstErrorCode(graphQLErrors))
    },
    refetchQueries: ['getBusinessLevelConfigs'],
  })

  return tuple([
    useCallback(
      async (businessLevel: string, displayName: string) => {
        await mutation({
          variables: {
            payload: {
              configBusinessLevels: {
                businessLevel,
                displayName,
              },
              customerId: activeCustomerId,
            },
          },
        })
      },
      [activeCustomerId, mutation],
    ),
    result,
  ])
}
