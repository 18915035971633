import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1878 13.0801C17.2278 12.84 17.2545 12.6266 17.2678 12.4666C17.2811 12.3066 17.2945 12.1465 17.2945 11.9998C17.2945 11.8398 17.2945 11.6798 17.2945 11.5197C17.2945 11.3597 17.2545 11.173 17.2011 10.973L18.7348 9.82604C19.0015 9.62599 19.0815 9.25258 18.9082 8.95918L18.1213 7.59889C17.948 7.30549 17.6012 7.18546 17.2945 7.31883L15.5474 8.02565C15.2407 7.77226 14.9606 7.55888 14.6806 7.38551C14.4005 7.22547 14.0671 7.07877 13.6937 6.94541L13.467 5.09168C13.427 4.75827 13.1469 4.50488 12.8002 4.50488H11.2132C10.8798 4.50488 10.5997 4.75827 10.5464 5.07834L10.3063 6.91874C9.98623 7.02543 9.66616 7.17213 9.31942 7.35884C8.98601 7.55888 8.66594 7.77226 8.38588 8.01231L6.73219 7.31883C6.42546 7.18546 6.06538 7.31883 5.89201 7.59889L5.09183 8.93251C4.91846 9.22591 4.99848 9.59932 5.26521 9.8127L6.70552 10.9063L6.6255 11.5197C6.59883 11.6931 6.59883 11.8665 6.59883 12.0265C6.59883 12.1999 6.59883 12.3733 6.6255 12.56C6.65217 12.7467 6.67885 12.92 6.70552 13.0934L5.26521 14.187C4.99848 14.387 4.91846 14.7604 5.09183 15.0538L5.89201 16.4408C6.06538 16.7342 6.42546 16.8542 6.73219 16.7209L8.41256 16.0007C8.65261 16.2274 8.95934 16.4408 9.33275 16.6542C9.70617 16.8542 10.0396 17.0009 10.333 17.0809L10.5597 18.908C10.5997 19.2414 10.8798 19.4948 11.2265 19.4948H12.8135C13.1469 19.4948 13.4403 19.2414 13.4803 18.908L13.707 17.0543C14.0404 16.9476 14.3605 16.8142 14.6539 16.6275C14.9606 16.4541 15.254 16.2274 15.5608 15.974L17.2545 16.7075C17.5612 16.8409 17.9346 16.7209 18.0947 16.4275L18.8815 15.0405C19.0549 14.7471 18.9748 14.3737 18.7081 14.1736L17.2145 13.0667L17.1878 13.0801ZM13.6404 13.6535C13.1869 14.107 12.6401 14.3337 11.9867 14.3337C11.3332 14.3337 10.7864 14.107 10.333 13.6535C9.87954 13.2001 9.65282 12.6533 9.65282 11.9998C9.65282 11.3464 9.87954 10.7996 10.333 10.3461C10.7864 9.89272 11.3332 9.666 11.9867 9.666C12.6401 9.666 13.1869 9.89272 13.6404 10.3461C14.0938 10.7996 14.3205 11.3464 14.3205 11.9998C14.3205 12.6533 14.0938 13.2001 13.6404 13.6535Z"
      fill="currentColor"
    />
  </svg>,
  'Settings',
)
