import { useCustomers } from 'app/CustomerContext'
import useGetProductBundles from 'app/api/queries/useGetProductBundles'
import FallbackLoader from 'app/components/FallbackLoader/FallbackLoader'
import { getRedirectRoute } from 'app/layouts/Customer/CustomerPageLayout.helpers'

import React from 'react'
import { Navigate } from 'react-router'
import styled from 'styled-components'

const StyledNoProducts = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30vh;
  align-items: center;
  height: 100%;
`

export default function CustomerRootRedirect() {
  const {
    productBundles,
    loading: productBundlesLoading,
  } = useGetProductBundles()
  const { activeCustomerKey } = useCustomers()

  if (productBundles) {
    if (productBundlesLoading) {
      return <FallbackLoader />
    }

    if (
      activeCustomerKey &&
      getRedirectRoute(productBundles, activeCustomerKey)
    ) {
      return (
        <Navigate to={getRedirectRoute(productBundles, activeCustomerKey)} />
      )
    }
  }

  return (
    <StyledNoProducts data-testid="AfreshHomeRoute">
      Please contact support@afresh.com to receive access to Afresh products.
    </StyledNoProducts>
  )
}
