import LinearProgress from 'app/components/LinearProgress/LinearProgress'
import VirtualizedList from 'app/components/VirtualizedList/VirtualizedList'
import { ROW_HEIGHT_NUMBER } from 'app/components/VirtualizedList/VirtualizedList.constants'
import { ENTER, SPACE, TOP_BAR_HEIGHT } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import { LayoutContext } from 'app/layouts/Customer/CustomerPageLayout'
import {
  TableProps,
  isGuideStructureItem,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import { toRem } from 'app/styles'
import produce from 'immer'
import React, { CSSProperties, Fragment, useCallback, useContext } from 'react'
import { Row, useBlockLayout, useTable } from 'react-table'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'
import { RowItem, RowSection } from './Row'

const StyledMessage = styled.span`
  display: inline-block;
  padding: ${toRem(20)};
`

export const StyledTableContainer = styled.div`
  max-height: calc(100% - 72px);
  user-select: none;
`

export const StyledTable = styled.div`
  width: 100%;
`

export const StyledTableHeaderRow = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral_07};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  color: ${({ theme }) => theme.colors.neutral_02};
  display: flex;
  font-weight: 700;
  height: ${ROW_HEIGHT_NUMBER}px;
  line-height: ${ROW_HEIGHT_NUMBER}px;
  padding-right: 15px;
  width: 100%;
`

export default function Table<T extends {}>({
  className,
  columns,
  data,
  hasError = false,
  isFetching = false,
  isLoading = false,
  isDraft,
  onScroll,
  selectedItems,
  setSelectedItems,
  stickyIndices,
}: TableProps<T>) {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  )
  const { hasTopBar } = useContext(LayoutContext)

  const RenderRow = useCallback(
    ({
      index,
      isLastRow,
      style,
    }: {
      index: number
      isLastRow: boolean
      style: CSSProperties
    }) => {
      if (isNilOrEmpty(rows) || isNilOrEmpty(rows[index])) {
        return null
      }

      const row = rows[index]
      prepareRow(row)

      const isSelectedRow =
        isGuideStructureItem(row) && !!selectedItems[row.original.id]

      const handleOnSelect = (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        event.stopPropagation()

        if (
          event.type === 'keydown' &&
          [ENTER, SPACE].indexOf((event as React.KeyboardEvent).key) === -1
        ) {
          return
        }

        if (isGuideStructureItem(row)) {
          const updatedItems = produce(selectedItems, (draft) => {
            const { original } = row

            if (draft[original.id]) {
              segment.guideOrganizationItemRowDeselected({
                afreshItemId: original.afreshItemId,
                itemName: original.afreshItemName,
                ...defaultProperties,
              })
              delete draft[original.id]
            } else {
              segment.guideOrganizationItemRowSelected({
                afreshItemId: original.afreshItemId,
                itemName: original.afreshItemName,
                ...defaultProperties,
              })
              draft[original.id] = original
            }
          })

          setSelectedItems(updatedItems)
        }
      }

      if (isGuideStructureItem(row)) {
        return (
          <RowItem
            handleOnSelect={handleOnSelect}
            isDraft={isDraft}
            isFetching={isFetching}
            isLastRow={isLastRow}
            isSelectedRow={isSelectedRow}
            key={row.id}
            row={row}
            style={style}
          />
        )
      } else {
        const { sectionName } = ((row as unknown) as Row<{
          sectionName: string
        }>).original

        return (
          <RowSection
            sectionName={sectionName}
            {...row.getRowProps({ style })}
          />
        )
      }
    },
    [
      isDraft,
      isFetching,
      prepareRow,
      rows,
      selectedItems,
      setSelectedItems,
      defaultProperties,
    ],
  )

  const showLinearProgress = isLoading && !hasError
  const showErrorMessage = hasError && !isLoading
  const isEmpty =
    isNilOrEmpty(rows) && !(showLinearProgress || showErrorMessage)
  const renderTable = !(showLinearProgress || showErrorMessage || isEmpty)

  return (
    <StyledTableContainer>
      <StyledTable className={className} {...getTableProps()}>
        {headerGroups.map((headerGroup, hdrIndex) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <StyledTableHeaderRow key={hdrIndex}>
              {headerGroup.headers.map((column) => {
                return (
                  <Fragment key={column.id}>
                    {column.render('Header', { isDraft })}
                  </Fragment>
                )
              })}
            </StyledTableHeaderRow>
          )
        })}

        <div {...getTableBodyProps()}>
          {showLinearProgress && <LinearProgress />}
          {showErrorMessage && (
            <StyledMessage>Failed to fetch items.</StyledMessage>
          )}
          {isEmpty && <StyledMessage>No results found.</StyledMessage>}
          {renderTable && (
            <>
              <VirtualizedList
                fetchMore={onScroll}
                height={
                  hasTopBar
                    ? `calc(100vh - 306px - ${TOP_BAR_HEIGHT + 10}px)`
                    : 'calc(100vh - 306px)'
                }
                numOfRows={0}
                renderRow={RenderRow}
                rows={rows}
                stickyIndices={stickyIndices}
                useStickyHeaders
                useVariableHeight
              />
            </>
          )}
        </div>
      </StyledTable>
    </StyledTableContainer>
  )
}
