import { colorPalette } from 'app/styles'

export function asDate(value: string) {
  return new Date(value)
    .toLocaleString()
    .replace(/[,(AM)(PM)]/g, '')
    .trim()
}

export function getLineColor(status: string = '', line: string = '') {
  let color

  if (status === 'FAILED') color = colorPalette.error
  else if (line.indexOf('ERROR:') !== -1) color = colorPalette.error
  else if (line.indexOf('WARNING:') !== -1) color = colorPalette.caution
  else color = colorPalette.validation

  return { color }
}
