import { gql } from '@apollo/client'

export const STORE_CLOSURE_FIELDS = gql`
  fragment StoreClosureFields on StoreClosureEvent {
    _id
    date
    isChainwide
    stores {
      _id
    }
  }
`
