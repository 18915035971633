import Feed from 'app/components/Icons/Feed'
import Import from 'app/components/Icons/Import'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

const StyledCloudUpload = styled(Import)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 36px;
`

const StyledFeed = styled(Feed)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 36px;
`

const StyledDropzone = styled.div<{ $isDragActive: boolean }>`
  align-items: center;
  border: 2px dashed;
  background-color: ${({ $isDragActive, theme }) =>
    $isDragActive ? theme.colors.neutral_06 : theme.colors.neutral_07};
  border-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.neutral_02};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  outline: none;
  padding: 24px;
  text-align: center;

  &:hover,
  &:focus,
  &[aria-disabled='true'] {
    border-color: ${({ theme }) => theme.colors.neutral_10};
    ${StyledCloudUpload} {
      fill: ${({ theme }) => theme.colors.neutral_10};
    }
  }

  &:active {
    border-color: ${({ theme }) => theme.colors.neutral_09};
    ${StyledCloudUpload} {
      fill: ${({ theme }) => theme.colors.neutral_09};
    }
  }
`
export interface Props {
  className?: string
  acceptedFiles?: string[]
  description?: string
  disabled?: boolean
  maxFiles?: number
  maxFileSize?: number
  onDrop: (files: File[]) => void
}

export default function Dropzone(props: Props) {
  const {
    className = '',
    acceptedFiles,
    description,
    disabled,
    maxFiles,
    maxFileSize,
    onDrop,
  } = props

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    accept: acceptedFiles,
    disabled,
    maxFiles: maxFiles !== undefined ? maxFiles : 1,
    maxSize: maxFileSize,
    onDrop,
  })

  return (
    <StyledDropzone
      {...getRootProps()}
      className={className}
      aria-disabled={disabled}
      $isDragActive={isDragActive}>
      <input {...getInputProps()} />
      {description ? <StyledFeed /> : <StyledCloudUpload />}
      <p>
        {description !== undefined
          ? description
          : 'Drag and drop a file here or click to upload'}
      </p>
    </StyledDropzone>
  )
}
