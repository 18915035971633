import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5998 11.7597L17.2098 8.36973H15.0498V4.96973H4.34977C3.60977 4.96973 3.00977 5.56973 3.00977 6.30973V16.8797H4.66162C4.70918 18.0485 5.67989 18.9898 6.85979 18.9898C8.03969 18.9898 9.0104 18.0485 9.05796 16.8797H14.7616C14.8092 18.0485 15.7799 18.9898 16.9598 18.9898C18.1397 18.9898 19.1104 18.0485 19.158 16.8797H21.0198V12.6997C21.0198 12.3397 20.8798 11.9997 20.6298 11.7497L20.5998 11.7597ZM15.0398 11.9497V9.59973H16.8298L19.1498 11.9497H15.0398ZM6.85979 17.6198C6.39979 17.6198 6.02979 17.2498 6.02979 16.7898C6.02979 16.3298 6.39979 15.9598 6.85979 15.9598C7.31979 15.9598 7.68979 16.3298 7.68979 16.7898C7.68979 17.2498 7.31979 17.6198 6.85979 17.6198ZM16.1298 16.7898C16.1298 17.2498 16.4998 17.6198 16.9598 17.6198C17.4198 17.6198 17.7898 17.2498 17.7898 16.7898C17.7898 16.3298 17.4198 15.9598 16.9598 15.9598C16.4998 15.9598 16.1298 16.3298 16.1298 16.7898Z"
      fill="currentColor"
    />
  </svg>,
  'DeliverySchedule',
)
