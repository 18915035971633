import Accordion from 'app/components/Accordion/Accordion'
import useToggleState from 'app/hooks/useToggleState'
import BusinessLevelDisplayNamesTable from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/pages/BusinessLevelDisplayNamesTable'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import React from 'react'

export default function BusinessLevelDisplayNames() {
  const [open, { toggle }] = useToggleState()

  return (
    <>
      <StyledHeader>Business Level Display Names</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={open}
        onChangeExpanded={toggle}>
        <StyledSubheader>
          Click the row to edit the display names for this customer&apos;s
          Business Levels. If there is no customization, the default will be
          Afresh&apos;s generic business level names (“Business Unit” and
          “Region”) if these Business Levels exist.
          <p>
            To see examples of this customer&apos;s Business Levels, click into
            the Ad Schedules customer config and see the selection options
            available there.
          </p>
        </StyledSubheader>
      </Accordion>

      <BusinessLevelDisplayNamesTable />
    </>
  )
}
