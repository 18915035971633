import { Paper, Popper, PopperPlacementType } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

// These offset values position where the popover renders in relation to the anchor el. OFFSET_X moves it left or right; OFFSET_Y moves it up or down.
const OFFSET_X = 0
const OFFSET_Y = 4
const MUI_ZINDEX_VALUE = '1300'

const StyledPopoverContent = styled(Paper)`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledPopper = styled(Popper)`
  width: 262px;
  z-index: 1000;
`

interface RenderAnchorParams {
  onClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => void
}

export interface PopoverProps {
  anchorPlacement?: PopperPlacementType
  children: React.ReactElement
  className?: string
  renderAnchor: (params: RenderAnchorParams) => React.ReactElement
  handleOnCloseCallback?(): void
  withOverlay?: boolean
}

export function Popover({
  anchorPlacement = 'bottom-start',
  children,
  className,
  renderAnchor,
  handleOnCloseCallback,
  withOverlay = false,
}: PopoverProps) {
  const overlay = useRef<HTMLElement | null>(
    withOverlay ? document.getElementById('overlay')! : null,
  )
  const popperRef = useRef<HTMLDivElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  useEffect(() => {
    if (!anchorEl || !overlay.current) {
      return
    }

    const o = overlay.current
    o.style.display = 'block'
    o.style.position = 'absolute'
    o.style.left = '0'
    o.style.right = '0'
    o.style.top = '0'
    o.style.bottom = '0'
    o.style.zIndex = MUI_ZINDEX_VALUE

    // eslint-disable-next-line consistent-return
    return () => {
      o.style.display = 'none'
      o.style.zIndex = '-1'
    }
  }, [anchorEl, overlay])

  const customModifier = React.useMemo(() => {
    if (!anchorEl) {
      return []
    }

    return [
      {
        name: 'offset',
        options: {
          offset: [OFFSET_X, OFFSET_Y],
        },
      },
    ]
  }, [anchorEl])

  const handleOnClick = (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => {
    if (anchorEl) {
      anchorEl.blur()
    }

    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleOnClose = () => {
    if (anchorEl) {
      anchorEl.blur()
    }

    setAnchorEl(null)

    if (handleOnCloseCallback) {
      handleOnCloseCallback()
    }
  }

  const open = Boolean(anchorEl)

  return (
    <>
      {renderAnchor({ onClick: handleOnClick })}
      <StyledPopper
        className={className}
        anchorEl={anchorEl}
        container={withOverlay ? document.getElementById('overlay')! : null}
        modifiers={customModifier}
        open={open}
        placement={anchorPlacement}
        ref={popperRef}>
        <StyledPopoverContent>
          {React.Children.map(children, (child) => {
            return React.cloneElement(child, {
              onClose: handleOnClose,
            })
          })}
        </StyledPopoverContent>
      </StyledPopper>
    </>
  )
}
