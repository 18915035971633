import { dispatchEvent, useEventListener } from 'app/state/useEventListener'
import { rawAPICall } from './rawAPICall'

export const datalayer = (() => {
  return {
    useEvent: (...args) => useEventListener(window.document, ...args),
    dispatchEvent: (type, data) => dispatchEvent(type, data, window.document),
    rawAPICall,
    importScript: {
      status: (apiUrl, id = 'all') =>
        rawAPICall(`${apiUrl}/file-uploads/status/${id}`),
      upload: (apiUrl, body, script) =>
        rawAPICall(`${apiUrl}/file-uploads/import-script/${script}`, {
          method: 'POST',
          body,
        }),
    },
  }
})()
