import CautionIcon from 'app/components/Icons/CautionSystemStatusFilled'
import { ENTER, SPACE } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import { DateRange } from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import { CELL_DISPLAY_VALUE } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import { Order } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

const ICON_WIDTH_DEFAULT = 32

const StyledEvent = styled.div<{
  $isFrozen: boolean
  $isEditing: boolean
}>`
  align-items: center;
  border: 2px solid transparent;
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 0px 4px;
  color: ${({ theme }) => theme.colors.secondary};

  ${({ $isFrozen, $isEditing, theme }) =>
    $isFrozen &&
    $isEditing &&
    `
    color: ${theme.colors.neutral_03} !important;
    border: 2px solid transparent !important;
    background-color: transparent !important;
  `};
`

const DashedLine = styled.div<{
  $isActive: boolean
  $isEditing: boolean
  width: number
}>`
  width: 100%;
  height: 1px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.secondary};
  border-style: dashed;

  &[aria-disabled='true'] {
    border-color: ${({ theme }) => theme.colors.neutral_03};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    height: 36px;
    width: ${(props) => `${props.width}px`};
  }
`

const StyledCautionIcon = styled(CautionIcon)`
  padding: 0 2px;
`

const OrderAndDeliveryPair = styled.div<{
  $isActive: boolean
  $isApplied: boolean
  $isEditing: boolean
  $isSameDay: boolean
  width: number
  top: number
  left: number
}>`
    align-items: center;
    border: 2px solid transparent;
    display: flex;
    justify-content: space-between;
    left: ${(props) => `${props.left}px`};
    position: absolute;
    top: ${(props) => `${props.top}px`};
    width: ${(props) => `${props.width}px`};
    z-index: 1;

  &:focus:not(:hover) {
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.neutral_08};
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
  }

  ${({ $isEditing }) => $isEditing && `cursor: pointer;`}

  ${({ $isActive, theme }) =>
    $isActive &&
    `
      border-color: ${theme.colors.neutral_09};
      border-radius: 4px;
      box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
      background: ${theme.colors.neutral_08};

      &:focus:not(:hover) {
        outline: none;
      }

      ${DashedLine} {
        border-color: ${theme.colors.neutral_09};
        border-style: solid;
      }

      ${StyledEvent} {
        color: ${theme.colors.neutral_09};
      }
  `}

  ${({ $isActive, $isEditing, theme }) =>
    $isEditing &&
    !$isActive &&
    `
      &:focus {
        ${DashedLine} {
          border-color: ${theme.colors.primary};
          border-style: solid;
          outline-style: none;
        }

        ${StyledEvent} {
          cursor: pointer;
        }
      }

      &:hover:not(:focus), :active, :target {
        ${DashedLine} {
					border-color: ${theme.colors.neutral_10};
          border-style: solid;
        }

        ${StyledEvent} {
					border: 2px solid ${theme.colors.neutral_10};
          color: ${theme.colors.neutral_10};
          cursor: pointer;
        }
      }

      ${DashedLine} {
        border-color: ${theme.colors.primary};
      }

      ${StyledEvent} {
        color: ${theme.colors.primary};
      }
  `}
  
  ${({ $isApplied, theme }) =>
    $isApplied &&
    `
      border-color: ${theme.colors.highlight_05};
      background: ${theme.colors.neutral_08};

      &:focus:not(:hover) {
        outline: none;
      }

      ${DashedLine} {
        border-color: ${theme.colors.highlight_05};

        &[aria-disabled='true'] {
          border-color: ${theme.colors.highlight_05};
        }
      }

      ${StyledEvent} {
        color: ${theme.colors.highlight_05} !important;
      }
  `}

  ${({ $isSameDay }) =>
    $isSameDay &&
    `
      &:focus:not(:hover),
      &:hover:not(:focus) {
        ${StyledEvent} {
          &:first-of-type {
            border-right: none;
          }

          &:last-of-type {
            border-left: none;
          }
        }
      }

      ${StyledEvent} {
        &:first-of-type {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:last-of-type {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
  `}

  `

export interface PositionInformation {
  left: number
  top: number
  width: number
}

export interface OrderDeliveryPairProps {
  isActive: boolean
  isEditing: boolean
  isSameDay: boolean
  dateRange: DateRange
  onChangeActiveOrder: (order: Order | null) => void
  onClick: (
    event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
  ) => void
  orderDeliveryEvent: Order
  position: PositionInformation
}

// https://mui.com/material-ui/guides/composition/#caveat-with-refs
// To use this within `Tooltip`, this needs to be able to take a ref, which
// functional components can't do.
const OrderDeliveryPair = forwardRef<any, OrderDeliveryPairProps>(
  (props, ref) => {
    const {
      isActive,
      isEditing,
      isSameDay,
      dateRange,
      onChangeActiveOrder,
      onClick,
      orderDeliveryEvent,
      position,
      // The tooltip component adds some additional props which need to
      // be spread on the component.
      ...rest
    } = props

    const orderDate = orderDeliveryEvent.order.date
    const deliveryDate = orderDeliveryEvent.delivery.date

    const { startDate, endDate } = dateRange
    const showOrderIcon = orderDate >= startDate
    const showDeliveryIcon = deliveryDate <= endDate

    const isOrderConflict = !isNilOrEmpty(orderDeliveryEvent.order.issueTypes)
    const isDeliveryConflict = !isNilOrEmpty(
      orderDeliveryEvent.delivery.issueTypes,
    )

    const canEdit =
      isEditing &&
      (orderDeliveryEvent.order.isEditable ||
        orderDeliveryEvent.delivery.isEditable)

    const { left, top, width } = position

    return (
      <OrderAndDeliveryPair
        {...rest}
        ref={ref}
        $isActive={isActive}
        $isApplied={orderDeliveryEvent.applied}
        $isEditing={canEdit}
        $isSameDay={isSameDay}
        top={top}
        left={left}
        width={width}
        key={orderDeliveryEvent.id}
        tabIndex={canEdit ? 0 : undefined}
        role={canEdit ? 'button' : undefined}
        aria-label={`Order and delivery on ${orderDate} through ${deliveryDate}`}
        onClick={(e) => {
          if (!canEdit) {
            return
          }

          onChangeActiveOrder(isActive ? null : orderDeliveryEvent)
          onClick(e)
        }}
        onKeyDown={(e) => {
          if (!canEdit || [ENTER, SPACE].indexOf(e.key) === -1) {
            return
          }

          onChangeActiveOrder(isActive ? null : orderDeliveryEvent)
          onClick(e)
          e.preventDefault()
        }}>
        {showOrderIcon && (
          <StyledEvent
            $isFrozen={!orderDeliveryEvent.order.isEditable}
            $isEditing={isEditing}>
            <>
              {isOrderConflict && <StyledCautionIcon />}
              {CELL_DISPLAY_VALUE.ORDER}
            </>
          </StyledEvent>
        )}

        {!isSameDay && (
          <DashedLine
            $isActive={isActive}
            $isEditing={isEditing}
            aria-disabled={!canEdit}
            width={width - ICON_WIDTH_DEFAULT * 2}
          />
        )}

        {showDeliveryIcon && (
          <StyledEvent
            $isFrozen={!orderDeliveryEvent.delivery.isEditable}
            $isEditing={isEditing}>
            <>
              {isDeliveryConflict && <StyledCautionIcon />}
              {CELL_DISPLAY_VALUE.DELIVERY}
            </>
          </StyledEvent>
        )}
      </OrderAndDeliveryPair>
    )
  },
)

export default OrderDeliveryPair
