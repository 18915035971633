import {
  UpdateConfigurationsMutation,
  UpdateConfigurationsMutationVariables,
} from '@api/__gen__/gql'
import { useMutation } from '@apollo/client'
import { Box } from '@mui/material'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import TextField from 'app/components/TextField/TextField'
import { UPDATE_CONFIGURATIONS } from 'app/packages/internal/engTools/configurations/api/mutations/updateConfigurations'
import { toRem } from 'app/styles'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  margin-top: 0;
  margin-bottom: ${toRem(4)};
  height: ${toRem(28)};
`

const example = `
{ "name": "requires_floor_inventory", "type": "BOOLEAN", "value": "true" }
{ "name": "requires_back_inventory", "type": "BOOLEAN", "value": "true", "storeId": "169", "workflowid": "84", "categoryId": 27001, "itemid": "5234" }
`.trim()

function userInputToGqlVariables(
  configText: string,
): UpdateConfigurationsMutationVariables | null {
  const lines = configText
    .split('\n')
    .map((l) => l.trim())
    .filter((l) => l !== '')

  // Just make sure it's well-formed JSON. (This will throw an exception if it's
  // malformed.) Let the server complain if the contents are invalid.
  const items = lines.map((l) => JSON.parse(l))

  if (items.length) {
    return {
      configurationInputs: items,
    }
  }

  return null
}

export default function Configurations() {
  const [userInput, setUserInput] = useState('')

  // GQL variables, or null if the input is empty, or an Error if it's malformed
  const [gqlVariables, setGqlVariables] = useState<
    UpdateConfigurationsMutationVariables | null | Error
  >(null)

  const [
    updateConfigurations,
    { loading: isUpdating, data, error, reset },
  ] = useMutation<
    UpdateConfigurationsMutation,
    UpdateConfigurationsMutationVariables
  >(UPDATE_CONFIGURATIONS)

  // Format an error string if an error occurred. This is a little tricky
  // because the mutation can set an `errors` array on the
  // `updateConfigurations` object, which GQL doesn't recognize as an error.
  let errorString: string | null = null
  if (data?.updateConfigurations?.errors.length) {
    errorString = JSON.stringify(data?.updateConfigurations?.errors, null, 4)
  } else if (error) {
    errorString = JSON.stringify(error, null, 4)
  }

  const onInputChange = useCallback(
    (text: string) => {
      setUserInput(text)

      // If the mutation had previously succeeded, clear the state. This hides
      // the success message and makes it clearer that the current change has
      // not been applied.
      if (errorString === null) {
        reset()
      }

      // To see if the user's input is valid, we need to parse it. And if we're
      // parsing it, we might as well save the result for later.
      try {
        setGqlVariables(userInputToGqlVariables(text))
      } catch (e) {
        if (e instanceof Error) {
          setGqlVariables(e)
        }
      }
    },
    [errorString, reset],
  )

  const submit = useCallback(async () => {
    if (gqlVariables === null || gqlVariables instanceof Error) {
      return // this shouldn't happen
    }

    reset()
    await updateConfigurations({ variables: gqlVariables })
  }, [gqlVariables, reset, updateConfigurations])

  return (
    <>
      <Title>Configs Updater Tool</Title>
      <p>
        Update or create entries in the{' '}
        <span style={{ fontFamily: 'monospace' }}>
          {'{'}customerSchema{'}'}.configurations
        </span>{' '}
        table. Specify one configuration per line:
      </p>
      <pre style={{ fontSize: '0.68em' }}>{example}</pre>
      <Box style={{ overflow: 'auto', maxHeight: 'calc(100vh - 340px)' }}>
        <TextField
          value={userInput}
          multiline
          fullWidth
          autoFocus
          disabled={isUpdating}
          error={gqlVariables instanceof Error}
          onChange={(e) => onInputChange(e.target.value)}
        />
        {(data || error) && (
          <AlertMessageBlock
            message={errorString ?? 'Success!'}
            severityType={errorString === null ? 'success' : 'error'}
            stylesOverride={{
              marginTop: '12px',
              ...(errorString !== null && {
                fontFamily: 'monospace',
                whiteSpace: 'pre-wrap',
              }),
            }}
          />
        )}
      </Box>
      <Box marginTop={2}>
        <Button
          disabled={
            isUpdating || gqlVariables === null || gqlVariables instanceof Error
          }
          onClick={submit}>
          Update configurations
        </Button>
      </Box>
    </>
  )
}
