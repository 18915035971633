import {
  AccountingPeriodDataFragment,
  GetInventoryPeriodDataQuery,
  GetInventoryPeriodDataQueryVariables,
} from '@api/__gen__/gql'
import { ApolloError, gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { ACCOUNTING_PERIOD_FRAGMENTS } from 'app/packages/inventoryManagement/inventoryResults/api/fragments/EndingInventories'
import dayjs, { Dayjs } from 'dayjs'
import { isNil } from 'lodash-es'

export const GET_INVENTORY_PERIOD_DATA = gql`
  ${ACCOUNTING_PERIOD_FRAGMENTS}
  query GetInventoryPeriodData($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        currentAccountingPeriod {
          ...AccountingPeriodData
        }
        nextCountingPeriodStartDate
        nextCountingPeriodEndDate
      }
    }
  }
`

/**
 * Returns whether we are in an active counting period.
 * During an active counting period, the iPad shows the option to open
 * a PEI for the just-ended accounting period.
 *
 * For us, that has two UI implications. We either:
 * - Show an opened or submitted PEI in a table of active PEIs
 * - Show a mock (non-existent) PEI in the table with a "not started" status
 *    This mock isn't tied to any real data since PEI creation has yet to happen
 *
 * Why do we just compare the end date and start date? ASCII art will explain.
 *
 * let offset = 0 and interval = 3
 * Accounting Period A     |------------------------|
 * Counting Period A                                |---|
 * Accounting Period B                              |------------------------|
 * Counting Period B                                                         |---|
 * Current Time                                       ^
 * nextCountingPeriodStartDate                                               ^
 * nextCountingPeriodEndDate                            ^
 *
 * Since the nextCountingPeriod resolvers will always return the closest not-yet-reached start or end date,
 * the nextCountingPeriodEndDate will always return the end date of the current period, if active. Once the current period
 * ends, then the nextCountingPeriodEndDate will return Counting Period B's end date.
 */
export const isCountingPeriodActive = (
  nextCountingPeriodStartDate: dayjs.Dayjs,
  nextCountingPeriodEndDate: dayjs.Dayjs,
): boolean => {
  return nextCountingPeriodEndDate < nextCountingPeriodStartDate
}

export interface AccountingPeriodData {
  id: string
  periodNumber: number
  periodYearName: string
}

/**
 * Returns the active accounting period.
 * This logic is to make sure we are always returning the accounting period
 * related to the active inventory (counting) period
 *
 * CASE A
 * let offset = 0 and interval = 3
 * Accounting Period A     |------------------------|
 * Counting Period A                                |---|
 * Accounting Period B                              |------------------------|
 * Counting Period B                                                         |---|
 * Current Accounting Period = B
 * Accounting Period B End Date                                               ^
 * Current Time                                       ^
 * nextCountingPeriodEndDate                            ^
 *
 * Should return Accounting Period A
 *
 * CASE B
 * let offset = -1 and interval = 4
 * Accounting Period A     |------------------------|
 * Counting Period A                               |----|
 * Accounting Period B                              |------------------------|
 * Counting Period B                                                        |---|
 * Current Accounting Period = A
 * Accounting Period A End Date                     ^
 * Current Time                                    ^
 * nextCountingPeriodEndDate                            ^
 *
 * Should return Accounting Period A
 *
 * If we are currently within an inventory (counting) period, then we can determine
 * the "active" accounting period by comparing the accounting end date with the next inventory
 * period end date. Note the one assumption is that our accounting period end date will
 * always be before it's inventory period end date, which is a safe assumption.
 */
export const getActiveAccountingPeriod = (
  currentAccountingPeriod: AccountingPeriodDataFragment | undefined,
  nextCountingPeriodEndDate: dayjs.Dayjs,
): AccountingPeriodData | null | undefined => {
  if (dayjs(currentAccountingPeriod?.endDate) < nextCountingPeriodEndDate) {
    return currentAccountingPeriod
  } else if (currentAccountingPeriod) {
    return currentAccountingPeriod.previousPeriod
  }
  return null
}

export default function useGetInventoryPeriodData(): {
  isLoadingPeriodData: boolean
  periodDataError: ApolloError | undefined
  nextCountingPeriodStartDate: dayjs.Dayjs
  nextCountingPeriodEndDate: dayjs.Dayjs
  currentAccountingPeriod: AccountingPeriodDataFragment | undefined
  isCountingPeriodActive: boolean
} {
  const { activeCustomerId } = useCustomers()

  const { error, data, loading } = useQuery<
    GetInventoryPeriodDataQuery,
    GetInventoryPeriodDataQueryVariables
  >(GET_INVENTORY_PERIOD_DATA, {
    skip: isNil(activeCustomerId),
    variables: {
      customerId: activeCustomerId!,
    },
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    Sentry.captureException(error, (sentryScope) => {
      sentryScope.setContext('inventory', {
        activeCustomerId,
      })
      sentryScope.setTransactionName(`Failed to fetch inventory period data`)
      sentryScope.setLevel('warning')
      return sentryScope
    })
  }

  let currentAccountingPeriod: undefined | AccountingPeriodDataFragment
  let nextCountingPeriodStartDate: Dayjs = dayjs()
  let nextCountingPeriodEndDate: Dayjs = dayjs()
  if (data?.node?.__typename === 'Customer') {
    currentAccountingPeriod = data?.node.currentAccountingPeriod
    nextCountingPeriodStartDate = dayjs(data?.node.nextCountingPeriodStartDate)
    nextCountingPeriodEndDate = dayjs(data?.node.nextCountingPeriodEndDate)
  }

  return {
    isLoadingPeriodData: loading,
    nextCountingPeriodEndDate,
    nextCountingPeriodStartDate,
    currentAccountingPeriod,
    isCountingPeriodActive: isCountingPeriodActive(
      nextCountingPeriodStartDate,
      nextCountingPeriodEndDate,
    ),
    periodDataError: error,
  }
}
