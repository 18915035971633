import { Checkbox } from '@mui/material'
import { ENTER, SPACE } from 'app/constants'
import usePrevious from 'app/hooks/usePrevious'
import {
  MovedPrimaryMapping,
  PrimaryMapping,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import { CreateEditSectionState } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import { toRem } from 'app/styles'
import { isEqual } from 'lodash-es'
import React from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledTable = styled.table`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-radius: 4px;
  border-spacing: 0px;
  width: 100%;
`

const StyledTableTd = styled.td`
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  height: 40px;
  padding: 0 16px;
`

const StyledTableTh = styled.th`
  background-color: ${({ theme }) => theme.colors.neutral_07};
  height: 40px;
  padding: 0 16px;
  text-align: left;

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }
`

const StyledCheckbox = styled(Checkbox)`
  left: 0;
  margin: 0 ${toRem(12)} ${toRem(2)} 0;

  &.Mui-focusVisible {
    border-radius: 0;
    outline-offset: 2px;
  }
`

interface AssignCategoriesTableProps {
  onChange?: (producer: (draft: CreateEditSectionState) => void) => void
  canReassign?: boolean
  movedPrimaryMappings?: MovedPrimaryMapping
  primaryMappings: PrimaryMapping[]
}

export default function AssignCategoriesTable({
  canReassign = false,
  movedPrimaryMappings,
  onChange = () => {},
  primaryMappings,
}: AssignCategoriesTableProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { primaryKeyTypePlural } = useGetProperties()

  const previousMovedMappings = usePrevious(
    movedPrimaryMappings?.primaryMappings ?? [],
  )

  if (
    !!previousMovedMappings &&
    !isEqual(previousMovedMappings, movedPrimaryMappings?.primaryMappings ?? [])
  ) {
    segment.guideOrganizationReassignCategoriesSelected({
      selectedPrimaryMappings:
        movedPrimaryMappings?.primaryMappings.map(
          (mappingId) =>
            `${mappingId}:${
              primaryMappings.find(({ _id }) => _id === mappingId)?.name
            }`,
        ) ?? [],
      ...defaultProperties,
    })
  }

  const handleOnSelect = (id: string, isSelected: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      [ENTER, SPACE].indexOf((event as React.KeyboardEvent).key) === -1
    ) {
      return
    }

    onChange((draft) => {
      if (isSelected) {
        draft.movedPrimaryMappings.primaryMappings = movedPrimaryMappings!.primaryMappings.filter(
          (mappingId) => mappingId !== id,
        )
      } else {
        draft.movedPrimaryMappings.primaryMappings.push(id)
      }
    })
  }

  return (
    <StyledTable>
      <thead>
        <tr>
          <StyledTableTh>Assigned {primaryKeyTypePlural}</StyledTableTh>
        </tr>
      </thead>
      <tbody>
        {primaryMappings.map((option) => {
          const label = {
            inputProps: { 'aria-label': `${option.name} checkbox` },
          }

          const isSelected = canReassign
            ? movedPrimaryMappings!.primaryMappings.includes(option._id)
            : false

          return (
            <tr key={option._id}>
              <StyledTableTd>
                {canReassign && (
                  <StyledCheckbox
                    {...label}
                    checked={isSelected}
                    onClick={handleOnSelect(option._id, isSelected)}
                    onKeyDown={handleOnSelect(option._id, isSelected)}
                  />
                )}
                {option.name}
              </StyledTableTd>
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}
