import { StoreRecurringScheduleRow } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components'

const StyledRecurringScheduleTableHeader = styled.div`
  align-items: center;
  display: flex;
  padding: 0 16px;
  width: 100%;

  &:first-child {
    flex: 0 0 280px;
  }
`

const StyledDeliveryLagHeader = styled(StyledRecurringScheduleTableHeader)`
  justify-content: center;
`

export function useGetTableColumns() {
  return useMemo(() => {
    return [
      {
        accessor: (schedule) => schedule.store.name,
        id: 'store',
        Header: 'Store',
      },
      {
        accessor: 'recurringOrders',
        id: 'recurringOrders',
        Header: () => {
          return (
            <>
              <StyledRecurringScheduleTableHeader>
                Order Day
              </StyledRecurringScheduleTableHeader>
              <StyledRecurringScheduleTableHeader>
                Delivery Day
              </StyledRecurringScheduleTableHeader>
              <StyledDeliveryLagHeader>Delivery Lag</StyledDeliveryLagHeader>
              <StyledRecurringScheduleTableHeader />
            </>
          )
        },
      },
    ] as Column<StoreRecurringScheduleRow>[]
  }, [])
}
