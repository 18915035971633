import { gql } from '@apollo/client'

const fileUploadsDashboardGql = gql`
  query dashboard {
    viewer {
      user2 {
        _id
        name
      }
    }
  }
`

export default fileUploadsDashboardGql
