import { Accordion, AccordionSummary } from '@mui/material'
import styled from 'styled-components'

export const StyledAccordion = styled(Accordion)`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-radius: 4px;
  box-shadow: none;

  &.Mui-expanded {
    margin: 0;
  }
`

export const StyledAccordionSummary = styled(AccordionSummary)<{
  $isEditing: boolean
}>`
  font-weight: bold;
  pointer-events: ${({ $isEditing }) => ($isEditing ? 'auto' : 'none')};

  &.Mui-expanded {
    min-height: 50px;
  }

  [class*='MuiAccordionSummary-content'] {
    align-items: center;
    column-gap: 8px;
    margin: 0;
  }
`
