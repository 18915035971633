export const NUM_OF_DAYS = 7
export const ITEM_HEIGHT = 50

// This is based on the minimum size of the store name and buttons on a row.
// 32px for padding + 28 * 3 for name + 2 buttons.
export const MIN_ROW_HEIGHT = 116
export const MAX_STORE_NAMES_SHOWN = 8
export const STORE_NAME_HEIGHT = 28
export const STORE_NAME_PADDING = 32

export const ORDER_DELIVERY_ERROR_CODES = {
  DATES_IN_PAST: 'ORDER_DELIVERY_EVENT_ORDER_DATE_BEFORE_CUTOFF',
  DATES_MISORDERED: 'ORDER_DELIVERY_EVENT_DELIVERY_DATE_BEFORE_ORDER_DATE',
  IS_OLDER_VERSION: 'ORDER_DELIVERY_SCHEDULE_EDIT_PUBLISHED_SCHEDULE',
  IS_OLDER_DRAFT: 'ORDER_DELIVERY_SCHEDULE_EDIT_OLD_DRAFT',
  STORE_CLOSURE_EXISTS:
    'ORDER_DELIVERY_SCHEDULE_STORE_CLOSURE_EVENT_ALREADY_EXISTS',
  STORE_CLOSURE_IN_PAST:
    'ORDER_DELIVERY_SCHEDULE_STORE_CLOSURE_EDIT_IN_THE_PAST',
}
