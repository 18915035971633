import {
  SsoFetchUserInfoQuery,
  SsoFetchUserInfoQueryVariables,
} from '@api/__gen__/gql'
import { gql, useApolloClient } from '@apollo/client'
import { Box } from '@mui/material'
import { USER_DETAILS } from 'app/api/fragments/UserDetails'
import CardTitle from 'app/components/CardTitle/CardTitle'
import SingleCardPage from 'app/components/SingleCardPage/SingleCardPage'
import { useUserContext } from 'app/context/userContext'
import {
  CONSOLE_REDIRECT_PATH_PREFIX,
  LOGIN_PATH,
} from 'app/packages/core/pages/login/login.constants'
import {
  applyAuthentication,
  initializeUserWithExternalSDKs,
} from 'app/packages/core/pages/login/login.helpers'
import RedirectWhenAuthenticated from 'app/packages/core/pages/login/RedirectWhenAuthenticated/RedirectWhenAuthenticated'
import { useAuthState } from 'app/state/authentication'
import { useVars } from 'app/VarsContext'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'

export const fetchUserInfoGql = gql`
  ${USER_DETAILS}
  query ssoFetchUserInfo {
    viewer {
      user2 {
        ...UserDetails
      }
    }
  }
`

interface Response {
  accessToken: string
  refreshToken: string
}

export default function TokenExchange() {
  const authState = useAuthState()
  const apolloClient = useApolloClient()
  const { pathname, search, hash } = useLocation()
  const { apiUrl: host } = useVars()
  const { setUser } = useUserContext()
  const [step, setStep] = useState<'EXCHANGE' | 'ERROR' | 'NOT_ALLOWED'>(
    'EXCHANGE',
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      try {
        const path = pathname.replace(CONSOLE_REDIRECT_PATH_PREFIX, '')
        const url = new URL(`${host}${path}${search}`)
        url.searchParams.append('clientType', 'CONSOLE')
        url.hash = hash

        // exchange code for tokens
        const response = await axios.get<Response>(url.toString(), {
          withCredentials: true,
        })
        await authState.setTokens({
          access: response.data.accessToken,
          refresh: response.data.refreshToken,
        })

        // get user info
        const userInfoResponse = await apolloClient.query<
          SsoFetchUserInfoQuery,
          SsoFetchUserInfoQueryVariables
        >({ query: fetchUserInfoGql })
        const userRaw = userInfoResponse.data.viewer?.user2

        if (userRaw) {
          const success = await applyAuthentication(
            authState,
            response.data.accessToken,
            response.data.refreshToken,
          )

          const user = setUser(userRaw)
          initializeUserWithExternalSDKs(user)

          if (!success) {
            await authState.logout()
            setStep('NOT_ALLOWED')
          }
        } else {
          setStep('ERROR')
        }
      } catch (e) {
        // If token exchange failed, the response may contain an errorDescription
        // from the SSO provider.
        // @ts-ignore
        if (e?.response?.data?.errorDescription) {
          // @ts-ignore
          setErrorMessage(e.response.data.errorDescription)
        } else {
          setErrorMessage('Something went wrong.')
        }

        setStep('ERROR')
      }
    })()
  }, [apolloClient, authState, hash, host, pathname, search, setUser])

  return (
    <>
      <RedirectWhenAuthenticated />
      <SingleCardPage width={480}>
        <CardTitle>Sign in</CardTitle>
        {step === 'EXCHANGE' && <Box>Obtaining credentials...</Box>}
        {step === 'ERROR' && (
          <Box>
            {errorMessage} <Link to={LOGIN_PATH}>Try again?</Link>
          </Box>
        )}
        {step === 'NOT_ALLOWED' && (
          <Box>Access restricted: Only Afresh admins can log in.</Box>
        )}
      </SingleCardPage>
    </>
  )
}
