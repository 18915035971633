import { makeVar } from '@apollo/client'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'

export interface BulkEditingInformation {
  stores: Store[]
  matchingEvents: { [eventId: string]: string[] }
}

export const $schedulesBulkEditCalendarStores = makeVar<Store[]>([])
export const $schedulesBulkEditCalendarMatchingEvents = makeVar<{
  [eventId: string]: string[]
}>({})

export const $schedulesBulkEditRecurringStores = makeVar<Store[]>([])
export const $schedulesBulkEditRecurringMatchingEvents = makeVar<{
  [eventId: string]: string[]
}>({})
