import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0097 3C10.7197 3 9.67969 4.04 9.67969 5.33C9.67969 6.62 10.7197 7.66 12.0097 7.66C13.2997 7.66 14.3397 6.62 14.3397 5.33C14.3397 4.04 13.2997 3 12.0097 3ZM12.0097 6.2C11.5297 6.2 11.1397 5.81 11.1397 5.33C11.1397 4.85 11.5297 4.46 12.0097 4.46C12.4897 4.46 12.8797 4.85 12.8797 5.33C12.8797 5.81 12.4897 6.2 12.0097 6.2Z"
      fill="currentColor"
    />
    <path
      d="M17.63 4.83984H12.72C12.82 4.97984 12.89 5.13984 12.89 5.32984C12.89 5.80984 12.5 6.19984 12.02 6.19984C11.54 6.19984 11.15 5.80984 11.15 5.32984C11.15 5.14984 11.22 4.97984 11.32 4.83984H6.46004C5.67004 4.83984 5.04004 5.47984 5.04004 6.25984V19.5998C5.04004 20.3898 5.68004 21.0198 6.46004 21.0198H17.63C18.42 21.0198 19.05 20.3798 19.05 19.5998V6.25984C19.05 5.46984 18.41 4.83984 17.63 4.83984ZM9.09004 17.2498L7.46004 17.5098L7.72004 15.8798L13.38 10.2198L14.75 11.5898L9.09004 17.2498ZM16.42 9.96984L15.48 10.9098L14.07 9.49984L15.01 8.55984C15.27 8.29984 15.69 8.29984 15.95 8.55984L16.42 9.02984C16.68 9.28984 16.68 9.70984 16.42 9.96984Z"
      fill="currentColor"
    />
  </svg>,
  'InventoryGuide',
)
