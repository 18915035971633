import { Box } from '@mui/material'
import Button from 'app/components/Button/Button'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HeaderText = styled.h2`
  margin-top: 0;
  margin-bottom: ${toRem(16)};
`

const StyledButton = styled(Button)`
  margin-top: ${toRem(32)};
`

interface NoRowsOverlayProps {
  headingText: string
  subText: string
  buttonText?: string
  buttonFunction?(): any
}

const NoRowsOverlay = ({
  headingText,
  subText,
  buttonText = '',
  buttonFunction,
}: NoRowsOverlayProps) => {
  return (
    <Container>
      <HeaderText>{headingText}</HeaderText>
      <span>{subText}</span>
      {buttonFunction && buttonText && (
        <StyledButton variant="secondary" onClick={buttonFunction}>
          {buttonText}
        </StyledButton>
      )}
    </Container>
  )
}

export default NoRowsOverlay
