import { SelectChangeEvent } from '@mui/material'
import { styled } from '@mui/material/styles'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import Tag from 'app/components/Tag/Tag'
import { ExternalCustomer, useCustomers } from 'app/CustomerContext'
import { toRem } from 'app/styles'
import { isNil } from 'lodash-es'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const SingleCustomerDisplay = styled(`div`)(({ theme }) => ({
  backgroundColor: theme.colors.neutral_06,
  padding: `${toRem(16.5)} ${toRem(14)}`,
  fontWeight: 'bold',
  color: theme.colors.secondary,
  borderRadius: toRem(4),
}))

const StyledSingleSelect = styled(SingleSelect)`
  .MuiSelect-select {
    padding-left: 8px;
  }
  .MuiOutlinedInput-root {
    height: 36px;
  }
`
const StyledCustomerTag = styled(Tag)`
  max-width: 220px;
  margin-top: 8px;
`

export default function CustomerSelector({
  className,
}: {
  className?: string
}) {
  const { activeCustomerId, customers, activeCustomer } = useCustomers()
  const navigate = useNavigate()

  const externalCustomers: ExternalCustomer[] | undefined = customers?.filter(
    (c): c is ExternalCustomer => c !== null,
  )

  if (
    isNil(activeCustomerId) ||
    isNil(activeCustomer) ||
    externalCustomers === undefined
  ) {
    return null
  }

  if (externalCustomers.length === 1) {
    if (externalCustomers[0].name) {
      return (
        <SingleCustomerDisplay data-testid="customer-name">
          {externalCustomers[0].name}
        </SingleCustomerDisplay>
      )
    }
  }

  function handleChangeCustomer(e: SelectChangeEvent<string>) {
    const newCustomerId = e.target.value as string
    navigate(`/${newCustomerId}`)
  }

  return (
    <StyledSingleSelect
      className={className}
      aria-label="Select Customer"
      value={activeCustomer.key}
      // eslint-disable-next-line react/jsx-no-bind
      handleChange={handleChangeCustomer}
      renderValue={() => (
        <StyledCustomerTag text={activeCustomer.name} color="highlight_04" />
      )}
      options={externalCustomers.map((customer) => {
        return {
          label: customer.name,
          value: customer.key,
        }
      })}
      width={320}
    />
  )
}
