import Drawer from 'app/components/Drawer/Drawer'
import {
  DRAWER_WIDTH,
  PRIMARY_SIDE_NAV_WIDTH,
} from 'app/layouts/Layout.constants'
import styled from 'styled-components'

export const SideNavDrawer = styled(Drawer).withConfig({
  shouldForwardProp: (prop) => prop !== 'open',
})<{ $openWidth?: number; $closedWidth?: number }>`
  width: ${({ $openWidth = DRAWER_WIDTH, theme }) =>
    theme.typography.pxToRem($openWidth)};
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;

  ${({
    open,
    theme,
    $openWidth = DRAWER_WIDTH,
    $closedWidth = PRIMARY_SIDE_NAV_WIDTH,
  }) =>
    open
      ? `
      width: ${theme.typography.pxToRem($openWidth)};   
      transition: ${theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      })};
      overflow: hidden;
      border-right: none;
      
      & .MuiDrawer-paper {
        width: ${theme.typography.pxToRem($openWidth)};
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.enteringScreen,
        })};
        overflow: hidden;
        border-right: none;
      }
      `
      : `width: calc(${theme.typography.pxToRem($closedWidth)});
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        })};
       overflow: hidden;
       border-right: none;

       & .MuiDrawer-paper {
        width: calc(${theme.typography.pxToRem($closedWidth)});
        transition: ${theme.transitions.create('width', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.leavingScreen,
        })};
        overflow: hidden;
        border-right: none;
       }
      `}
`
