import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      fill="currentColor"
    />
    <path
      d="M12.3198 15.7305C12.0498 15.7305 11.8198 15.8305 11.6298 16.0205C11.4398 16.2105 11.3398 16.4505 11.3398 16.7405C11.3398 17.0305 11.4398 17.2705 11.6298 17.4605C11.8198 17.6505 12.0598 17.7505 12.3498 17.7505C12.6198 17.7505 12.8498 17.6505 13.0398 17.4605C13.2298 17.2705 13.3298 17.0305 13.3298 16.7405C13.3298 16.4505 13.2298 16.2105 13.0398 16.0205C12.8498 15.8305 12.6098 15.7305 12.3198 15.7305Z"
      fill="currentColor"
    />
    <path
      d="M12.2501 13.7502C12.2501 13.3902 12.2901 12.9002 12.3801 12.6002C12.4701 12.3102 12.7401 11.9702 13.1901 11.6002C13.6101 11.2602 13.9201 10.9102 14.0901 10.5602C14.2701 10.2102 14.3601 9.83023 14.3601 9.42023C14.3601 8.68023 14.1301 8.09023 13.6601 7.65023C13.1901 7.21023 12.5601 6.99023 11.7701 6.99023C11.1001 6.99023 10.5001 7.16023 9.97008 7.50023C9.44008 7.84023 9.06008 8.32023 8.83008 8.92023"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>,
  'QuestionMark',
)
