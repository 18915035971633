import {
  DeleteOrderDeliveryEventsMutation,
  DeleteOrderDeliveryEventsMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import { ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/OrderDeliveryEvent'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_ORDER_DELIVERY_EVENTS = gql`
  ${ORDER_DELIVERY_EVENT_FIELDS}
  mutation deleteOrderDeliveryEvents(
    $scheduleId: ID!
    $orderDeliveryEventIds: [ID!]!
  ) {
    deleteOrderDeliveryEvents(
      input: {
        scheduleId: $scheduleId
        orderDeliveryEventIds: $orderDeliveryEventIds
      }
    ) {
      orderDeliveryEvents {
        ...OrderDeliveryEventFields
      }
    }
  }
`

export default function useDeleteOrderDeliveryEvents() {
  const { scheduleId } = useGetOrderDeliverySchedule()

  const [mutation, result] = useMutation<
    DeleteOrderDeliveryEventsMutation,
    DeleteOrderDeliveryEventsMutationVariables
  >(DELETE_ORDER_DELIVERY_EVENTS, {
    refetchQueries: ['orderDeliveryEvents', 'getOrderDeliveryScheduleIssues'],
    awaitRefetchQueries: true,
  })

  return tuple([
    useCallback(
      async (orderDeliveryEventIds: string[]) => {
        const { errors } = await mutation({
          variables: {
            scheduleId: scheduleId!,
            orderDeliveryEventIds,
          },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [mutation, scheduleId],
    ),
    result,
  ])
}
