import { UpsertUnloadOrderSequenceInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPSERT_UNLOAD_ORDER_SEQUENCE = gql`
  mutation upsertUnloadOrderSequence($input: UpdateUnloadOrderSequenceInput!) {
    updateUnloadOrderSequence(input: $input) {
      results {
        _id
        workflow {
          id
        }
        store {
          id
        }
        dayOfWeek
        unloadOrderSequence
      }
    }
  }
`

export function useUpsertUnloadOrderSequence() {
  const [upsertUnloadOrderSequence, result] = useMutation(
    UPSERT_UNLOAD_ORDER_SEQUENCE,
    {
      refetchQueries: ['CustomerConfigUnloadOrderSequence'],
    },
  )

  return tuple([
    useCallback(
      async (input: UpsertUnloadOrderSequenceInput) => {
        const { errors } = await upsertUnloadOrderSequence({
          variables: {
            input: {
              toUpsert: [input],
            },
          },
        })

        if (errors?.length) {
          throw new Error('Upsert new Unload Order Sequence config failed')
        }
      },
      [upsertUnloadOrderSequence],
    ),
    result,
  ])
}
