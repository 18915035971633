export const DEPARTMENT_BUNDLE_ERRORS: { [key: string]: string } = {
  DEPARTMENT_BUNDLE_NAME_DUPLICATED: 'Department bundle name already exists.',
  DEPARTMENT_BUNDLE_NAME_TOO_LONG: 'Department bundle name is too long.',
  DEPARTMENT_BUNDLE_TYPE_NOT_FOUND: 'Department bundle type not found.',
  DEPARTMENT_BUNDLE__NO_DEPARTMENTS:
    'Department bundle must have at least one department.',
  DEPARTMENT_BUNDLE_HAS_GUIDE_STRUCTURE:
    'Cannot Delete Department Bundle. Department bundle has guide structure.',
  DEPARTMENT_BUNDLE_HAS_USER:
    'Cannot Delete Department Bundle. Department bundle has one or more users associated with it.',
  DEPARTMENT_BUNDLE_HAS_WORKFLOW:
    'Cannot Delete Department Bundle. Department bundle has one or more workflows associated with it.',
  INTERNAL_SERVER_ERROR: 'Something went wrong.',
}
