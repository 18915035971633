import { SelectChangeEvent } from '@mui/material'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  FooterActions,
  Header,
  LeftPane,
  LeftPaneListItem,
  RightPane,
  SideTabFooter,
  Title,
} from 'app/components/Dialog/Dialog'
import { getFlagValue, useFeatureFlags } from 'app/context/rolloutContext'
import { isNilOrEmpty } from 'app/helpers'
import useDebounce from 'app/hooks/useDebounce'
import useDraftState from 'app/hooks/useDraftState'
import useCreateCategory from 'app/packages/storeSolutions/guideOrganization/api/mutations/useCreateCategory'
import useDeleteCategory from 'app/packages/storeSolutions/guideOrganization/api/mutations/useDeleteCategory'
import useUpdateCategory from 'app/packages/storeSolutions/guideOrganization/api/mutations/useUpdateCategory'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { CATEGORY_NAME_MAX_LIMIT } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.constants'
import {
  GuideStructureCategory,
  InventoryConfigurationEditState,
  MovedPrimaryMapping,
  OrderingConfigurationEditState,
  PrimaryMapping,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import AddCategories from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/AddCategories'
import ReassignCategories from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/ReassignCategories'
import AssignCategories from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/AssignCategories'
import DeleteCategory from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/DeleteCategory'
import InventorySettings from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/InventorySettings'
import OrderingSettings from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/OrderingSettings'
import SectionName from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/Flows/SectionName'
import { toRem } from 'app/styles'
import { toString } from 'lodash-es'
import React, { useMemo, useState } from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledHeader = styled(Header)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral_05};
  padding-bottom: ${toRem(24)};
`

const StyledBody = styled(Body)`
  display: flex;
  flex-grow: 1;
  padding: 0;
`

export const StyledP = styled.p`
  margin: 0;
`

const DEFAULT_ORDERING_CONFIGURATION = {
  autoInventoryEnabled: false,
  backInventoryRequired: false,
  floorInventoryRequired: false,
  floorInventoryUnits: undefined,
}

const DEFAULT_INVENTORY_CONFIGURATION = {
  backInventoryUnits: undefined,
  floorInventoryRequired: false,
  floorInventoryUnits: undefined,
}

export interface Props {
  categoryEditContext?: GuideStructureCategory
  onClose: () => void
  viewOnly?: boolean
}

export interface CreateEditSectionState {
  categoryId?: string
  categoryName: string
  movedPrimaryMappings: MovedPrimaryMapping
  openAssignCategoriesAccordion: boolean
  openReassignCategoriesAccordion: boolean
  parentId: string | null
  primaryMappings: PrimaryMapping[]
  sortIndex: string
  orderingConfiguration: OrderingConfigurationEditState
  inventoryConfiguration: InventoryConfigurationEditState
}

const sharedState = {
  movedPrimaryMappings: {
    newGuideStructureCategoryId: '',
    primaryMappings: [],
  },
  openAssignCategoriesAccordion: false,
  openReassignCategoriesAccordion: false,
  primaryMappings: [],
}

const initialState = {
  ...sharedState,
  categoryName: '',
  parentId: '',
  sortIndex: '',
  orderingConfiguration: DEFAULT_ORDERING_CONFIGURATION,
  inventoryConfiguration: DEFAULT_INVENTORY_CONFIGURATION,
}

enum Step {
  NAME_AND_SETTINGS = 1,
  ORDERING_SETTINGS = 2,
  INVENTORY_SETTINGS = 3,
  ASSIGN_CATEGORIES = 4,
  DELETE = 5,
}

export default function EditorModal({
  categoryEditContext,
  onClose,
  viewOnly,
}: Props) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { categories } = useGetCategories()
  const { primaryKeyTypePlural, showInventorySettings } = useGetProperties()
  const isCategoryConfigurationVisible = getFlagValue(
    useFeatureFlags().isGuideOrganizationCategoryConfigurationEnabled,
  )

  const [createCategory, { loading: isCreating }] = useCreateCategory()
  const [updateCategory, { loading: isUpdating }] = useUpdateCategory()
  const [deleteCategory, { loading: isDeleting }] = useDeleteCategory()

  const [currentStep, setCurrentStep] = useState(Step.NAME_AND_SETTINGS)
  const [
    selectedDeleteCategoryForMove,
    setSelectedDeleteCategoryForMove,
  ] = useState('')

  const [sectionData, setSectionData] = useDraftState<CreateEditSectionState>(
    categoryEditContext
      ? {
          categoryId: categoryEditContext.itemCategory.id,
          categoryName: categoryEditContext.itemCategory.name,
          parentId: categoryEditContext.itemCategory.parentId,
          sortIndex: toString(categoryEditContext.itemCategory.sortIndex),
          orderingConfiguration:
            categoryEditContext.orderingConfiguration ??
            DEFAULT_ORDERING_CONFIGURATION,
          inventoryConfiguration:
            categoryEditContext.inventoryConfiguration ??
            DEFAULT_INVENTORY_CONFIGURATION,
          ...sharedState,
        }
      : initialState,
  )

  const [error, setError] = useState('')
  const categoryNameDebounced = useDebounce(sectionData.categoryName)

  const isDuplicateCategoryName = useMemo(() => {
    if (
      !categories.length ||
      categoryEditContext?.itemCategory.name === categoryNameDebounced
    ) {
      return false
    }

    return categories.some(
      (category) => category.itemCategory.name === categoryNameDebounced,
    )
  }, [categories, categoryNameDebounced, categoryEditContext])

  const isEditing = !!categoryEditContext
  const isPending = isCreating || isUpdating

  const title = isEditing
    ? `${viewOnly ? 'View' : 'Edit'} Section: ${
        categoryEditContext.itemCategory.name
      }`
    : 'Create New Section'

  const handleOnClose = () => {
    segment.guideOrganizationSectionEditorModalCancelButtonClicked({
      isEditing,
      ...defaultProperties,
    })
    onClose()
  }

  const handleNextStep = () => {
    segment.guideOrganizationSectionEditorModalNextButtonClicked(
      defaultProperties,
    )
    // If the ordering and inventory settings are not visible, we skip
    // over them to the assign categories step.
    if (
      currentStep === Step.NAME_AND_SETTINGS &&
      !isCategoryConfigurationVisible
    ) {
      setCurrentStep(Step.ASSIGN_CATEGORIES)
    } else if (
      currentStep === Step.ORDERING_SETTINGS &&
      !showInventorySettings
    ) {
      setCurrentStep(Step.ASSIGN_CATEGORIES)
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const handleOnSave = async () => {
    try {
      if (isEditing) {
        await updateCategory(sectionData, categoryEditContext)
      } else {
        await createCategory(sectionData)
      }

      segment.guideOrganizationSectionEditorModalSaveAllChangesButtonClicked({
        isEditing,
        ...defaultProperties,
      })
      onClose()
    } catch (e) {
      const err = e as Error
      setError(err.message)
      segment.guideOrganizationSectionEditorModalChangesSavedFailed({
        error: err,
        isEditing,
        ...defaultProperties,
      })
    }
  }

  const handleOnDelete = async () => {
    try {
      if (isEditing) {
        await deleteCategory({
          categoryId: categoryEditContext.itemCategory.id,
          categoryIdForMappings: selectedDeleteCategoryForMove || undefined,
        })
        onClose()
      }
    } catch (e) {
      const err = e as Error
      setError(err.message)
      segment.guideOrganizationSectionEditorModalDeleteFailed({
        error: err,
        isEditing,
        ...defaultProperties,
      })
    }
  }
  const canSave = isEditing || currentStep === Step.ASSIGN_CATEGORIES
  const isUncategorized =
    categoryEditContext?.itemCategory.name === 'Uncategorized'

  const isNameAndSettingsValid =
    !isDuplicateCategoryName &&
    (isUncategorized || !!sectionData.parentId) &&
    !!sectionData.categoryName &&
    sectionData.categoryName.length <= CATEGORY_NAME_MAX_LIMIT &&
    !!sectionData.sortIndex

  const isOrderingSettingsValid = isCategoryConfigurationVisible
    ? sectionData.orderingConfiguration.floorInventoryUnits
    : true

  const isInventorySettingsValid =
    isCategoryConfigurationVisible && showInventorySettings
      ? !!(
          sectionData.inventoryConfiguration.floorInventoryUnits &&
          sectionData.inventoryConfiguration.backInventoryUnits
        )
      : true

  const isAssignCategoriesValid =
    (!sectionData.movedPrimaryMappings.newGuideStructureCategoryId &&
      isNilOrEmpty(sectionData.movedPrimaryMappings.primaryMappings)) ||
    (!!sectionData.movedPrimaryMappings.newGuideStructureCategoryId &&
      !isNilOrEmpty(sectionData.movedPrimaryMappings.primaryMappings))

  // if adding, ensure first form is valid
  // if uncategorized, and no primary mappings, dont allow reassign to be clicked
  const disableAssignCategoriesStep =
    (!isEditing && !isNameAndSettingsValid) ||
    (isUncategorized && categoryEditContext?.primaryMappings.length === 0) ||
    (!isEditing && showInventorySettings && !isInventorySettingsValid) ||
    (!isEditing && !showInventorySettings && !isOrderingSettingsValid)

  const disableNext =
    (currentStep === Step.NAME_AND_SETTINGS && !isNameAndSettingsValid) ||
    (currentStep === Step.ORDERING_SETTINGS &&
      (!isOrderingSettingsValid || !isNameAndSettingsValid)) ||
    (currentStep === Step.INVENTORY_SETTINGS &&
      (!isOrderingSettingsValid ||
        !isNameAndSettingsValid ||
        !isInventorySettingsValid)) ||
    (currentStep === Step.ASSIGN_CATEGORIES &&
      (!isAssignCategoriesValid ||
        !isNameAndSettingsValid ||
        !isOrderingSettingsValid ||
        !isInventorySettingsValid))

  return (
    <Dialog isOpen onClose={onClose} size="lg">
      <StyledHeader disabled={isPending} onClose={onClose}>
        <Title>{title}</Title>
      </StyledHeader>
      <StyledBody>
        <LeftPane>
          <LeftPaneListItem
            onClick={() => {
              segment.guideOrganizationSectionNameAndSettingsButtonClicked({
                isEditing,
                ...defaultProperties,
              })
              setCurrentStep(Step.NAME_AND_SETTINGS)
            }}
            selected={currentStep === Step.NAME_AND_SETTINGS}
            showStepNumber={!isEditing}
            text="Section Name and Settings"
          />
          {isCategoryConfigurationVisible && (
            <>
              <LeftPaneListItem
                disabled={!isEditing && !isNameAndSettingsValid}
                onClick={() => {
                  segment.guideOrganizationOrderingSettingsButtonClicked({
                    isEditing,
                    ...defaultProperties,
                  })
                  setCurrentStep(Step.ORDERING_SETTINGS)
                }}
                selected={currentStep === Step.ORDERING_SETTINGS}
                showStepNumber={!isEditing}
                text="Ordering Settings"
              />
              {showInventorySettings && (
                <LeftPaneListItem
                  disabled={
                    (!isEditing && !isOrderingSettingsValid) ||
                    (!isEditing && !isNameAndSettingsValid)
                  }
                  onClick={() => {
                    segment.guideOrganizationInventorySettingsButtonClicked({
                      isEditing,
                      ...defaultProperties,
                    })
                    setCurrentStep(Step.INVENTORY_SETTINGS)
                  }}
                  selected={currentStep === Step.INVENTORY_SETTINGS}
                  showStepNumber={!isEditing}
                  text="Inventory Settings"
                />
              )}
            </>
          )}
          <LeftPaneListItem
            disabled={disableAssignCategoriesStep}
            onClick={() => {
              segment.guideOrganizationAssignCategoriesButtonClicked({
                isEditing,
                ...defaultProperties,
              })
              setCurrentStep(Step.ASSIGN_CATEGORIES)
            }}
            selected={currentStep === Step.ASSIGN_CATEGORIES}
            showStepNumber={!isEditing}
            text={`Assign ${primaryKeyTypePlural}`}
          />
          {isEditing && !viewOnly && (
            <LeftPaneListItem
              onClick={() => setCurrentStep(Step.DELETE)}
              selected={currentStep === Step.DELETE}
              text="Delete Section"
            />
          )}
        </LeftPane>
        <RightPane>
          {currentStep === Step.NAME_AND_SETTINGS && (
            <SectionName
              isDuplicateCategoryName={isDuplicateCategoryName}
              categoryEditContext={categoryEditContext}
              sectionData={sectionData}
              onChange={setSectionData}
              isUncategorized={isUncategorized}
              viewOnly={!!viewOnly}
            />
          )}
          {currentStep === Step.ORDERING_SETTINGS && (
            <OrderingSettings
              orderingConfiguration={sectionData.orderingConfiguration}
              onChange={(newOrderingConfiguration) =>
                setSectionData((draft) => {
                  draft.orderingConfiguration = newOrderingConfiguration
                })
              }
              viewOnly={!!viewOnly}
            />
          )}
          {currentStep === Step.INVENTORY_SETTINGS && (
            <InventorySettings
              inventoryConfiguration={sectionData.inventoryConfiguration}
              onChange={(newInventoryConfiguration) =>
                setSectionData((draft) => {
                  draft.inventoryConfiguration = newInventoryConfiguration
                })
              }
              viewOnly={!!viewOnly}
            />
          )}
          {currentStep === Step.ASSIGN_CATEGORIES && (
            <AssignCategories
              hasExistingMappings={
                !isNilOrEmpty(categoryEditContext?.primaryMappings)
              }
              isEditing={isEditing}
              isUncategorized={isUncategorized}
              addCategories={
                <AddCategories
                  categoryEditContext={categoryEditContext}
                  onChange={setSectionData}
                  sectionData={sectionData}
                  viewOnly={!!viewOnly}
                />
              }
              reassignCategories={
                !viewOnly &&
                categoryEditContext?.primaryMappings &&
                categoryEditContext?.primaryMappings.length > 0 && (
                  <ReassignCategories
                    onChange={setSectionData}
                    primaryMappings={categoryEditContext?.primaryMappings}
                    sectionData={sectionData}
                  />
                )
              }
            />
          )}
          {currentStep === Step.DELETE && !viewOnly && (
            <DeleteCategory
              currentCategoryId={categoryEditContext?.itemCategory.id || ''}
              isEmpty={categoryEditContext?.isEmpty || false}
              isUncategorized={isUncategorized}
              categories={categories}
              handleSelectDeleteCategoryForMove={(e: SelectChangeEvent) => {
                setSelectedDeleteCategoryForMove(e.target.value)
              }}
              selectedDeleteCategoryForMove={selectedDeleteCategoryForMove}
            />
          )}
          {error && <AlertMessageBlock message={error} severityType="error" />}
          <SideTabFooter>
            <FooterActions>
              <Button
                disabled={isPending}
                onClick={handleOnClose}
                variant="secondary">
                {viewOnly ? 'Close' : 'Cancel'}
              </Button>
              {currentStep !== Step.DELETE &&
                !viewOnly &&
                (!isUncategorized ||
                  (isUncategorized &&
                    currentStep !== Step.NAME_AND_SETTINGS)) && (
                  <Button
                    disabled={disableNext}
                    loading={isPending}
                    onClick={canSave ? handleOnSave : handleNextStep}>
                    {canSave ? 'Save All Changes' : 'Next'}
                  </Button>
                )}
              {currentStep === Step.DELETE && !isUncategorized && (
                <Button
                  loading={isDeleting}
                  disabled={
                    !categoryEditContext?.isEmpty &&
                    isNilOrEmpty(selectedDeleteCategoryForMove)
                  }
                  onClick={handleOnDelete}
                  data-testid="confirm-delete-category-button">
                  Delete Section
                </Button>
              )}
            </FooterActions>
          </SideTabFooter>
        </RightPane>
      </StyledBody>
    </Dialog>
  )
}
