import { BusinessLevelInstance } from '@api/__gen__/gql'
import { useCustomers } from 'app/CustomerContext'
import Accordion from 'app/components/Accordion/Accordion'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import useGetBusinessLevelOptions from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetBusinessLevelOptions'
import { CHAINWIDE_LABEL } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailabilityAddEditModal'
import { toRem } from 'app/styles'
import React, { useMemo, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router'
import styled from 'styled-components'

import RouterTabs from 'app/components/Tabs/RouterTabs'
import WorkflowAvailabilityConfigure from './WorkflowAvailabilityConfigure'
import WorkflowAvailabilityStores from './WorkflowAvailabilityStores'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`
const StyledRouterTabs = styled(RouterTabs)`
  margin-top: 16px;
`

const getTabs = (activeCustomerKey: string) => {
  return [
    {
      name: 'Configuration',
      to: {
        pathname: `/internal/${activeCustomerKey}/configs/workflow-availability/config`,
      },
    },
    {
      name: 'Stores',
      to: {
        pathname: `/internal/${activeCustomerKey}/configs/workflow-availability/stores`,
      },
    },
  ]
}

const RouterTabsContainer = styled.div`
  margin-top: 16px;
  padding: 0 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export const DEFAULT_PAGE_SIZE = 25

export function formatBusinessLevelInstanceOptionLabel(
  businessLevelInstance: BusinessLevelInstance,
) {
  if (businessLevelInstance.businessLevelMetadata.level === 'CHAIN') {
    return CHAINWIDE_LABEL
  }
  return `${businessLevelInstance.name} (${businessLevelInstance.id})`
}

export default function WorkflowAvailability({
  disableVirtualization = false,
}: {
  disableVirtualization?: boolean
}) {
  const { activeCustomerKey } = useCustomers()

  const { data: businessLevelData } = useGetBusinessLevelOptions()

  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)

  const businessLevelOptions = useMemo(() => {
    return [{ label: '--', value: '' }, ...businessLevelData]
  }, [businessLevelData])

  return (
    <>
      <StyledHeader>Workflow Availability</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          <p>
            This tool enables configuring the availability of workflows at any
            available business level. You can view, add, edit, and delete
            workflow availability configurations.
            <br />
            The business logic determining the availability of workflows based
            on these configs is that more specific business level instances take
            precedence, for example:
            <br />
            <br />
            {['STORE', 'REGION', 'BUSINESS_UNIT', 'CHAIN'].map((level) => {
              const businessLevelOption = businessLevelOptions.find(
                (option) => option.value === level,
              )

              if (businessLevelOption) {
                if (businessLevelOption.value === 'CHAIN') {
                  return `${businessLevelOption.label}`
                }
                return `${businessLevelOption.label} > `
              }
              return null
            })}
            <br />
            <br />
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/afresh/Workflow-Availability-Config-Tool-e6033f43bfc849e0a811e9126f054a51?pvs=4',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <RouterTabsContainer>
        <StyledRouterTabs tabs={getTabs(activeCustomerKey)} />
      </RouterTabsContainer>
      <Routes>
        <Route
          path="config"
          element={
            <WorkflowAvailabilityConfigure
              disableVirtualization={disableVirtualization}
              setupDetailsExpanded={setupDetailsExpanded}
            />
          }
        />
        <Route
          path="stores"
          element={
            <WorkflowAvailabilityStores
              disableVirtualization={disableVirtualization}
              setupDetailsExpanded={setupDetailsExpanded}
            />
          }
        />
        <Route path="*" element={<Navigate to="stores" />} />
      </Routes>
    </>
  )
}
