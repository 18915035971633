import { useCallback, useState } from 'react'

export function calculatePageIndexOnPageSizeChange(
  currentPageIndex: number,
  currentPageSize: number,
  newPageSize: number,
) {
  return Math.floor(currentPageIndex / (newPageSize / currentPageSize))
}

/* This hook is intended to be used when a component needs 
   access to page information
   before or while querying for data
*/
export default function useOffsetPagination(DEFAULT_PAGE_SIZE: number) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [pageIndex, setPageIndex] = useState(0)

  const handlePageChange = useCallback(
    (newPageIndex: number, newPageSize: number) => {
      if (newPageSize !== pageSize) {
        setPageSize(newPageSize)
        setPageIndex(
          calculatePageIndexOnPageSizeChange(pageIndex, pageSize, newPageSize),
        )
      }
      if (newPageIndex !== pageIndex) {
        setPageIndex(newPageIndex)
      }
    },
    [pageSize, pageIndex],
  )

  return {
    pageSize,
    pageIndex,
    handlePageChange,
    setPageIndex,
  }
}
