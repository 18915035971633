import {
  OrderDeliveryEventsQuery,
  OrderDeliveryEventsQueryVariables,
  StoreClosureFieldsFragment,
  StoreWorkflowScheduleFieldsFragment,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { isNilOrEmpty } from 'app/helpers'
import { addDuration, getISODateString } from 'app/helpers/date'
import useWorkflowId from 'app/hooks/useWorkflowId'
import { STORE_CLOSURE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreClosure'
import { STORE_WORKFLOW_SCHEDULE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreWorkflowSchedule'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import useGetOrderDeliveryScheduleIssues from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliveryScheduleIssues'
import useDateRangeSearchParam from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import { NUM_OF_DAYS } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import {
  CalendarDay,
  OrderDeliverySchedulesIssuesByEvents,
  StoreSchedule,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { keyBy } from 'lodash-es'
import { nanoid } from 'nanoid'
import { useSearchParams } from 'react-router-dom'

export const GET_ORDER_DELIVERY_EVENTS = gql`
  ${STORE_CLOSURE_FIELDS}
  ${STORE_WORKFLOW_SCHEDULE_FIELDS}
  query orderDeliveryEvents(
    $scheduleId: ID!
    $startDate: Date!
    $endDate: Date!
    $workflowId: ID!
    $includeApplied: Boolean
  ) {
    node(id: $scheduleId) {
      _id
      ... on OrderDeliverySchedule {
        _id
        closures(startDate: $startDate, endDate: $endDate) {
          edges {
            node {
              ...StoreClosureFields
            }
          }
        }
        schedules(workflowId: $workflowId) {
          edges {
            node {
              ...StoreWorkflowScheduleFields
            }
          }
        }
      }
    }
  }
`

interface Dictionary<T> {
  [index: string]: T
}

function toStoreSchedule(
  data: StoreWorkflowScheduleFieldsFragment,
  calendarDays: CalendarDay[],
  closuresByDate: Dictionary<StoreClosureFieldsFragment>,
  issuesByEvents: OrderDeliverySchedulesIssuesByEvents,
): StoreSchedule {
  const orders = data.orderDeliveryEvents.filter((o) => !!o)

  const schedule = calendarDays.map(({ date }) => {
    const closure = closuresByDate[date]
    const isClosed =
      closure &&
      (closure.isChainwide ||
        (closure.stores || []).some((store) => store?._id === data.store._id))

    return {
      date,
      isClosed,
    }
  })

  return {
    storeScheduleId: data._id,
    store: toStore(data.store),
    schedule,
    orders: orders.map((o) => {
      // This is also a hack around applied events sometimes duplicating other
      // event keys. Assign them a random id, they are not editable.
      const orderDeliveryEventId = o?.applied ? nanoid() : o!._id

      const { order } = o!
      const { delivery } = o!

      const issueByEvent = issuesByEvents[orderDeliveryEventId] || {}

      return {
        id: orderDeliveryEventId,
        order: {
          ...order,
          issueTypes: isNilOrEmpty(issueByEvent.orderIssues)
            ? []
            : issueByEvent.orderIssues,
        },
        delivery: {
          ...delivery,
          issueTypes: isNilOrEmpty(issueByEvent.deliveryIssues)
            ? []
            : issueByEvent.deliveryIssues,
        },
        applied: o!.applied,
      }
    }),
  }
}

export function useGetStoreSchedules() {
  const { scheduleId } = useGetOrderDeliverySchedule()
  const [{ issuesByEvents }] = useGetOrderDeliveryScheduleIssues()
  const [{ startDate, endDate }] = useDateRangeSearchParam()

  const [workflowId] = useWorkflowId()
  const [searchParams] = useSearchParams()
  const includeApplied = !!searchParams.get('compareApplied')

  const { data, loading } = useQuery<
    OrderDeliveryEventsQuery,
    OrderDeliveryEventsQueryVariables
  >(GET_ORDER_DELIVERY_EVENTS, {
    skip: !scheduleId || !workflowId,
    variables: {
      scheduleId: scheduleId!,
      startDate,
      endDate,
      workflowId: workflowId!,
      includeApplied,
    },
    fetchPolicy: 'no-cache',
  })

  let orderDeliveryScheduleData: StoreSchedule[] = []
  if (data && data.node && data.node.__typename === 'OrderDeliverySchedule') {
    const calendarDays: CalendarDay[] = [...Array(NUM_OF_DAYS)].map(
      (_, dayIndex) => {
        return {
          date: getISODateString(addDuration(startDate, dayIndex, 'days')),
          isClosed: false,
        }
      },
    )

    const closuresByDate = keyBy(
      data.node.closures.edges.filter((i) => i.node).map((i) => i.node!),
      'date',
    )

    orderDeliveryScheduleData = data.node.schedules.edges
      .filter((i) => i.node)
      .map((i) => {
        return toStoreSchedule(
          i.node!,
          calendarDays,
          closuresByDate,
          issuesByEvents,
        )
      })
  }

  return { loading, orderDeliveryScheduleData }
}
