import { ROLES } from 'app/constants'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components'

const Row = styled.div`
  display: flex;
`

const InventoryDueDuration = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
  white-space: pre; /* Render the leading space char */
  color: ${({ theme }) => theme.colors.error};
`

export const InventoryDueMessage = ({
  dueDate,
  accessRole,
}: {
  dueDate: dayjs.Dayjs
  accessRole: string | undefined
}) => {
  const hoursDiff = dueDate.diff(dayjs(), 'hours')
  const dueInHours = hoursDiff % 24
  const dueInDays = (hoursDiff - dueInHours) / 24
  const dueIn =
    dueInDays > 0 ? `${dueInDays}d ${dueInHours}hrs` : `${dueInHours}hrs`

  return (
    <Row>
      {accessRole && accessRole === ROLES.CUSTOMER_REVIEWER ? (
        <>
          <div>Inventory due by</div>
          <InventoryDueDuration>{` 11:59pm, ${dueDate
            .subtract(1, 'day')
            .format('M/D/YYYY')}`}</InventoryDueDuration>
        </>
      ) : (
        <>
          <div>Inventory due in:</div>
          <InventoryDueDuration>{` ${dueIn}`}</InventoryDueDuration>
        </>
      )}
    </Row>
  )
}
