import { Box } from '@mui/system'
import React from 'react'
import styled from 'styled-components'

import { Fade } from '@mui/material'
import { ProductBundle } from 'app/api/queries/useGetProductBundles'
import Button from 'app/components/Button/Button'
import Collapse from 'app/components/Icons/Collapse'
import Logo from 'app/components/Logo/Logo'
import LogoIcon from 'app/components/Logo/LogoIcon'
import { PRODUCT_BUNDLE_TYPE } from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import PrimarySideNav from 'app/layouts/Customer/AppSideNav/PrimarySideNav'
import SecondarySideNav from 'app/layouts/Customer/AppSideNav/SecondarySideNav'
import { HEADER_HEIGHT } from 'app/layouts/Layout.constants'
import { SideNavDrawer } from 'app/layouts/SideNavDrawer'
import { getTheme } from 'app/styles'

const DrawerHeader = styled.div<{ $open: boolean; $showTopBar: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: ${({ theme }) => theme.typography.pxToRem(HEADER_HEIGHT)};
  border-bottom: ${({ $open, theme }) =>
    $open && `1px solid ${theme.colors.neutral_06}`};
  border-right: ${({ $showTopBar, $open, theme }) =>
    !$showTopBar && $open && `1px solid ${theme.colors.neutral_06}`};
  background-color: ${({ $open, theme }) =>
    !$open && theme.colors.highlight_07};
`

const StyledOpenLogo = styled(Logo)`
  position: absolute;
  left: ${({ theme }) => theme.typography.pxToRem(20)};
  top: ${({ theme }) => theme.typography.pxToRem(12)};
  width ${({ theme }) => theme.typography.pxToRem(126)};
  height: ${({ theme }) => theme.typography.pxToRem(28)};
`

const StyledClosedLogo = styled(LogoIcon)`
  position: absolute;
  left: ${({ theme }) => theme.typography.pxToRem(20)};
  top: ${({ theme }) => theme.typography.pxToRem(12)};
  width ${({ theme }) => theme.typography.pxToRem(28)};
  height: ${({ theme }) => theme.typography.pxToRem(28)};
`
const SideNavFlex = styled.div`
  display: flex;
  overflow: hidden;
`

const StyledCollapse = styled(Collapse)`
  color: ${({ theme }) => theme.colors.neutral_03};
  opacity: 1;
  transition: opacity ${({ theme }) => theme.transitions.duration.shortest}ms;
`

export default function AppSideNav({
  showTopBar,
  sideNavOpen = true,
  openTransitioning = false,
  toggleSideNav,
  activeProductBundle,
  productBundles,
  closeTransitioning,
  setCloseTransitioning,
}: {
  showTopBar: boolean
  sideNavOpen: boolean
  openTransitioning?: boolean
  toggleSideNav: (toggle: boolean) => void
  activeProductBundle?: PRODUCT_BUNDLE_TYPE
  productBundles?: ProductBundle[]
  closeTransitioning: boolean
  setCloseTransitioning: (closeTransitioning: boolean) => void
}) {
  const handleDrawerClose = () => {
    toggleSideNav(false)
    setCloseTransitioning(true)
    setTimeout(() => {
      setCloseTransitioning(false)
    }, getTheme().transitions.duration.leavingScreen + 100)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SideNavDrawer anchor="left" variant="permanent" open={sideNavOpen}>
        {sideNavOpen ? (
          <StyledOpenLogo color="highlight_05" />
        ) : (
          <StyledClosedLogo color="highlight_05" />
        )}
        <DrawerHeader $open={sideNavOpen} $showTopBar={showTopBar}>
          {sideNavOpen && !openTransitioning && (
            <Button
              variant="icon"
              onClick={handleDrawerClose}
              aria-label="Collapse Sidebar">
              <Fade in={sideNavOpen}>
                <StyledCollapse />
              </Fade>
            </Button>
          )}
        </DrawerHeader>
        <SideNavFlex>
          <PrimarySideNav
            activeProductBundle={activeProductBundle}
            productBundles={productBundles}
          />
          {(sideNavOpen || closeTransitioning) && (
            <SecondarySideNav
              activeProductBundle={activeProductBundle}
              productBundles={productBundles}
            />
          )}
        </SideNavFlex>
      </SideNavDrawer>
    </Box>
  )
}
