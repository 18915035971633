import {
  UpdateStoreClosureEventMutation,
  UpdateStoreClosureEventMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { ORDER_DELIVERY_ERROR_CODES } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { STORE_CLOSURE_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreClosureEventFields'
import { hasErrorCode } from 'app/utils'
import tuple from 'shared/tuple'

export const UPDATE_STORE_CLOSURE_EVENT = gql`
  ${STORE_CLOSURE_EVENT_FIELDS}
  mutation updateStoreClosureEvent(
    $closureType: StoreClosureType!
    $date: Date!
    $id: ID!
    $storeIds: [ID!]
  ) {
    updateStoreClosureEvent(
      input: {
        closureType: $closureType
        date: $date
        id: $id
        storeIds: $storeIds
      }
    ) {
      storeClosureEvent {
        ...StoreClosureEventFields
      }
    }
  }
`

export default function useUpdateStoreClosureEvent() {
  const [updateStoreClosureEvent, result] = useMutation<
    UpdateStoreClosureEventMutation,
    UpdateStoreClosureEventMutationVariables
  >(UPDATE_STORE_CLOSURE_EVENT, {
    refetchQueries: ['getStoreClosures', 'getOrderDeliveryScheduleIssues'],
  })

  const updateStoreClosureEventWrapper = async ({
    variables,
  }: {
    variables: UpdateStoreClosureEventMutationVariables
  }) => {
    const { errors } = await updateStoreClosureEvent({ variables })
    if (errors && errors.length) {
      if (hasErrorCode(errors, ORDER_DELIVERY_ERROR_CODES.IS_OLDER_VERSION)) {
        throw new Error(
          'Cannot edit a schedule that has already been published.',
        )
      } else if (
        hasErrorCode(errors, ORDER_DELIVERY_ERROR_CODES.IS_OLDER_DRAFT)
      ) {
        throw new Error(
          "Cannot edit a draft order delivery schedule that's not the latest.",
        )
      } else if (
        hasErrorCode(errors, ORDER_DELIVERY_ERROR_CODES.STORE_CLOSURE_EXISTS)
      ) {
        throw new Error(
          'A store closure already exists on this day, please update it instead.',
        )
      } else if (
        hasErrorCode(errors, ORDER_DELIVERY_ERROR_CODES.STORE_CLOSURE_IN_PAST)
      ) {
        throw new Error('Store closure must be in the future.')
      } else {
        throw new Error('Unable to edit store closure. Please try again.')
      }
    }
  }

  return tuple([updateStoreClosureEventWrapper, result])
}
