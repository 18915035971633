import { GridColDef, GridColTypeDef } from '@mui/x-data-grid-premium'
import Button from 'app/components/Button/Button'
import ChevronRight from 'app/components/Icons/ChevronRight'
import {
  EndingInventoryProps,
  PeriodType,
} from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/EndingInventoriesTable'
import { STATUS_NOT_STARTED } from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.constants'
import {
  numberToCostString,
  periodName,
} from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.helpers'
import StatusTag from 'app/packages/inventoryManagement/inventoryResults/pages/StatusTag/StatusTag'
import { toTitleCase } from 'app/utils'
import { findIndex } from 'lodash-es'
import React from 'react'
import styled from 'styled-components'

const allColumnSettings: GridColTypeDef = {
  groupable: false,
  hideable: false,
  aggregable: false,
  filterable: false,
  pinnable: false,
  sortable: false,
  disableColumnMenu: true,
}

const COLUMN_FLEX = 3

const CenterCell = styled.div`
  display: inline-flex;
  align-items: center;
`

export const getInventoryTableBodyColumns = (
  periodType: PeriodType,
  businessUnitName: string | undefined,
  regionName: string | undefined,
  onClickInventory: (inventory: EndingInventoryProps) => void,
) => {
  const tableColumns: GridColDef<EndingInventoryProps>[] = [
    {
      ...allColumnSettings,
      field: 'storeId',
      valueFormatter: (value) => {
        return value ? `Store ${value}` : ''
      },
      headerName: 'Store',
      minWidth: 110,
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'departmentBundleName',
      headerName: 'Department',
      minWidth: 140, // Fit "Fresh Produce"
      width: 200,
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'businessUnitName',
      headerName: toTitleCase(businessUnitName ?? 'Business Unit'),
      minWidth: 140,
      width: 160,
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'regionName',
      headerName: toTitleCase(regionName ?? 'Region'),
      minWidth: 140,
      width: 160,
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'accountingPeriod',
      valueFormatter: (value) => periodName(value),
      headerName: 'Period',
      minWidth: 100,
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'status',
      renderCell: ({ value }) => (
        <CenterCell>
          <StatusTag status={value} />
        </CenterCell>
      ),
      headerName: 'Status',
      minWidth: 110,
      width: 160, // Must fit NOT STARTED
      flex: COLUMN_FLEX,
    },
    {
      ...allColumnSettings,
      field: 'totalCost',
      valueFormatter: (value) =>
        Number(value) > 0 ? numberToCostString(Number(value)) : '-',
      headerName: 'Cost',
      width: 100,
      flex: COLUMN_FLEX - 1, // Move closer to the "View" button
    },
    {
      ...allColumnSettings,
      field: 'view',
      headerName: '',
      renderCell: (params) => {
        const endingInventory = params.row
        if (endingInventory.status === STATUS_NOT_STARTED) {
          return undefined
        }
        return (
          <Button
            sx={{ marginLeft: 'auto' }}
            onClick={() => onClickInventory(endingInventory)}
            variant="tertiary"
            id={endingInventory.id.toLocaleString()}>
            {periodType === PeriodType.CURRENT ? 'Review' : 'View'}
            <ChevronRight viewBox="-5 0 24 24" fontSize="small" />
          </Button>
        )
      },
      minWidth: 100,
      flex: 0, // Don't take up extra room, let other columns grow towards it
    },
  ]

  if (periodType === PeriodType.CURRENT) {
    tableColumns.splice(
      findIndex(tableColumns, (c) => c.field === 'accountingPeriod'),
      1,
    )
  }

  if (periodType === PeriodType.PAST) {
    tableColumns.splice(
      findIndex(tableColumns, (c) => c.field === 'status'),
      1,
    )
  }

  if (!regionName) {
    tableColumns.splice(
      findIndex(tableColumns, (c) => c.field === 'regionName'),
      1,
    )
  }
  if (!businessUnitName) {
    tableColumns.splice(
      findIndex(tableColumns, (c) => c.field === 'businessUnitName'),
      1,
    )
  }

  return tableColumns
}
