import { Checkbox, FormControlLabel } from '@mui/material'
import Info from 'app/components/Icons/Info'
import Tooltip from 'app/components/Tooltip/Tooltip'
import React from 'react'
import styled from 'styled-components'

const StyledSettingsBox = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_06}`};
  border-radius: 4px;
  padding: 16px 24px;
`

const StyledSettingsGroupTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledTooltip = styled(Tooltip)`
  color: ${({ theme }) => theme.colors.neutral_02};
  margin-left: 8px;
`

export const StyledFormControlLabel = styled(FormControlLabel)`
  padding: 4px 8px;
  margin-right: 4px;
`

export const StyledCheckbox = styled(Checkbox)`
  margin-right: 8px;
`

interface SettingsGroupProps {
  children: React.ReactNode
  title: string
  tooltipText: string
  showBorder?: boolean
}

export function SettingsGroup({
  children,
  title,
  tooltipText,
  showBorder = true,
}: SettingsGroupProps) {
  const component = (
    <>
      <StyledSettingsGroupTitleWrapper>
        <b>{title}</b>
        <StyledTooltip title={tooltipText}>
          <Info />
        </StyledTooltip>
      </StyledSettingsGroupTitleWrapper>
      <div>{children}</div>
    </>
  )
  return showBorder ? (
    <StyledSettingsBox>{component}</StyledSettingsBox>
  ) : (
    <div>{component}</div>
  )
}
