import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { formatDatetime } from 'app/helpers/date'
import useDeleteStoreClosureEvent from 'app/packages/storeSolutions/schedules/api/mutations/useDeleteStoreClosureEvent'
import { StoreClosure } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useState } from 'react'
import styled from 'styled-components'

const StyledStoresList = styled.div`
  max-height: 180px;
  overflow-y: auto;
  padding: 10px;
`

const AlertMessageBlockContainer = styled.div`
  padding-top: 10px;
`

export interface DeleteStoreClosureModalProps {
  storeClosure: StoreClosure
  onClose: () => void
}

export function DeleteStoreClosureModal(props: DeleteStoreClosureModalProps) {
  const { storeClosure, onClose } = props
  const [deleteStoreClosureEvent, { loading }] = useDeleteStoreClosureEvent()
  const [errorMessage, setErrorMessage] = useState('')

  const handleOnDelete = async () => {
    try {
      await deleteStoreClosureEvent({
        variables: { id: storeClosure.id },
      })

      onClose()
    } catch (err) {
      if (err instanceof Error) {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <Dialog disabled={loading} onClose={onClose} isOpen>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete Store Closure</Title>
      </Header>
      <Body>
        {storeClosure.stores ? (
          <>
            Delete store closure on{' '}
            <strong>
              {formatDatetime(storeClosure.closureDate, {
                format: 'ddd, YYYY-MM-DD',
              })}
            </strong>{' '}
            for the stores below?{' '}
          </>
        ) : (
          <>
            Delete store closure on{' '}
            <strong>
              {formatDatetime(storeClosure.closureDate, {
                format: 'ddd, YYYY-MM-DD',
              })}
            </strong>{' '}
            for all stores?{' '}
          </>
        )}
        Deleting a store closure will affect all delivery schedules at the
        selected stores.
        {storeClosure.stores && (
          <StyledStoresList>
            {storeClosure.stores.map((store) => {
              return <div key={store.id}>{store.name}</div>
            })}
          </StyledStoresList>
        )}
        {errorMessage && (
          <AlertMessageBlockContainer>
            <AlertMessageBlock message={errorMessage} severityType="error" />
          </AlertMessageBlockContainer>
        )}
      </Body>
      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleOnDelete}>
            Confirm
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
