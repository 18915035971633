import { Box } from '@mui/material'
import { toRem } from 'app/styles'
import styled from 'styled-components'

export const StyledHeader = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: bold;
  margin-bottom: ${toRem(8)};
  color: ${({ theme }) => theme.colors.neutral_02};
`

export const StyledSubheader = styled(Box)`
  font-size: ${({ theme }) => theme.fontSizes.m};
  margin-top: ${toRem(8)};
  padding-right: ${toRem(24)};
  padding-left: ${toRem(24)};
  color: ${({ theme }) => theme.colors.neutral_02};
`

export const StyledEmptyState = styled.div`
  padding: 20px;
`

export const StyledTableContainer = styled.div<{
  $setupDetailsExpanded: boolean
}>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 635px)' : 'calc(100vh - 310px)'};
  padding-top: 12px;
`
