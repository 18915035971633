import {
  UpdateOrderDeliveryEventsMutation,
  UpdateOrderDeliveryEventsMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import { ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/OrderDeliveryEvent'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_ORDER_DELIVERY_EVENTS = gql`
  ${ORDER_DELIVERY_EVENT_FIELDS}
  mutation updateOrderDeliveryEvents(
    $scheduleId: ID!
    $orderDeliveryEvents: [UpdateOrderDeliveryEventArgs!]!
  ) {
    updateOrderDeliveryEvents(
      input: {
        scheduleId: $scheduleId
        orderDeliveryEvents: $orderDeliveryEvents
      }
    ) {
      orderDeliveryEvents {
        ...OrderDeliveryEventFields
      }
    }
  }
`

export default function useUpdateOrderDeliveryEvents() {
  const { scheduleId } = useGetOrderDeliverySchedule()

  const [updateOrderDeliveryEvents, result] = useMutation<
    UpdateOrderDeliveryEventsMutation,
    UpdateOrderDeliveryEventsMutationVariables
  >(UPDATE_ORDER_DELIVERY_EVENTS, {
    refetchQueries: ['orderDeliveryEvents', 'getOrderDeliveryScheduleIssues'],
    awaitRefetchQueries: true,
  })

  return tuple([
    useCallback(
      async (
        orderDeliveryEventIds: string[],
        newOrderDate: string,
        newDeliveryDate: string,
      ) => {
        const { errors } = await updateOrderDeliveryEvents({
          variables: {
            scheduleId: scheduleId!,
            orderDeliveryEvents: orderDeliveryEventIds.map(
              (orderDeliveryEventId) => {
                return {
                  orderDeliveryEventId,
                  newOrderDate,
                  newDeliveryDate,
                }
              },
            ),
          },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [scheduleId, updateOrderDeliveryEvents],
    ),
    result,
  ])
}
