import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6805 7.13965L3.89047 15.9296L3.48047 20.5196L8.07047 20.1096L16.8605 11.3196L12.6805 7.13965Z"
      fill="currentColor"
    />
    <path
      d="M19.6809 4.97012L19.0309 4.32012C18.0509 3.34012 16.4709 3.35012 15.4909 4.32012L13.6309 6.18012L17.8209 10.3701L19.6809 8.51012C20.6509 7.54012 20.6509 5.95012 19.6809 4.97012Z"
      fill="currentColor"
    />
  </svg>,
  'Edit',
)
