import { gql } from '@apollo/client'

export const ORDER_DELIVERY_EVENT_FIELDS = gql`
  fragment OrderDeliveryEventFields on OrderDeliveryEvent {
    _id
    applied
    order {
      date
      isEditable
    }
    delivery {
      date
      isEditable
    }
  }
`
