import {
  StyledList,
  StyledVirtualizedList,
} from 'app/components/VirtualizedList/styles'
import { VirtualizedListProps } from 'app/components/VirtualizedList/VirtualizedList.types'
import React, { useCallback, useRef } from 'react'
import { useVirtual } from 'react-virtual'

export interface DynamicVirtualizedRowProps {
  itemHeight: number
}

export default function DynamicVirtualizedList<
  P extends DynamicVirtualizedRowProps
>({ className, height, numOfRows, renderRow, rows }: VirtualizedListProps<P>) {
  const parentRef = useRef<HTMLDivElement>(null)

  const rowVirtualizer = useVirtual({
    estimateSize: useCallback(
      (index: number) => {
        // itemHeight is derived based on the data returned from the server.
        // It determines the height for each row item, e.g.virtualRow.size
        return rows![index].original.itemHeight
      },
      [rows],
    ),
    parentRef,
    size: numOfRows,
    overscan: 10,
  })

  return (
    <StyledList
      className={className}
      data-testid="DynamicList"
      height={height}
      id="orderDeliveryTable"
      ref={parentRef}>
      <StyledVirtualizedList totalSize={rowVirtualizer.totalSize}>
        {rowVirtualizer.virtualItems.map((virtualRow) => {
          return renderRow({
            index: virtualRow.index || 0,
            key: virtualRow.index || 0,
            style: {
              height: `${virtualRow.size}px`,
              left: 0,
              position: 'absolute',
              top: 0,
              transform: `translateY(${virtualRow.start}px)`,
              width: '100%',
            },
          })
        })}
      </StyledVirtualizedList>
    </StyledList>
  )
}
