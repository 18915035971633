import { Box } from '@mui/material'
import React from 'react'
import styled from 'styled-components'

import { useVars } from 'app/VarsContext'

import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import EnvironmentLabel from 'app/components/EnvironmentLabel/EnvironmentLabel'
import { ENVIRONMENTS, ROLES } from 'app/constants'
import Logo, { LogoColorOptions } from './Logo'

const StyledWrapperBox = styled(Box)`
  display: flex;
  align-items: center;
  column-gap: 16px;
  width: fit-content;
  height: 40px;
`

const StyledLogo = styled(Logo)`
  margin-top: -12px;
  margin-left: -2px;
`

const StyledEnvironmentLabel = styled(EnvironmentLabel)`
  margin-top: -12px;
`

export default function LogoWithEnvironment({
  logoColor,
  inverted,
}: {
  logoColor?: LogoColorOptions
  inverted?: boolean
}) {
  const environmentVars = useVars()
  const { accessRole } = useGetCustomerPermissions()

  if (environmentVars.environment === ENVIRONMENTS.STAGING) {
    return (
      <StyledWrapperBox>
        <StyledLogo color={inverted ? 'neutral_08' : 'primary'} width={130} />
        <StyledEnvironmentLabel
          env={ENVIRONMENTS.STAGING}
          inverted={inverted}
        />
      </StyledWrapperBox>
    )
  }

  if (
    (accessRole === ROLES.AFRESH_ADMIN || accessRole === ROLES.AFRESH_SYSTEM) &&
    environmentVars.environment === ENVIRONMENTS.PRODUCTION
  ) {
    return (
      <StyledWrapperBox>
        <StyledLogo color={inverted ? 'neutral_08' : 'primary'} width={130} />
        <StyledEnvironmentLabel env={ENVIRONMENTS.PRODUCTION} />
      </StyledWrapperBox>
    )
  }

  return <Logo color={logoColor} />
}
