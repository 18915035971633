export const SCHEDULE_ERRORS: { [key: string]: string } = {
  ORDER_DELIVERY_SCHEDULE_ARCHIVED:
    'Cannot edit a schedule that has been archived.',
  ORDER_DELIVERY_SCHEDULE_DRAFT_ALREADY_EXISTS:
    'Cannot create a draft when a draft already exists.',
  ORDER_DELIVERY_SCHEDULE_EDIT_PUBLISHED_SCHEDULE:
    'Cannot edit a schedule that has already been published.',
  ORDER_DELIVERY_SCHEDULE_EDIT_OLD_DRAFT:
    "Cannot edit a draft order delivery schedule that's not the latest.",
  ORDER_DELIVERY_SCHEDULE__STORE_WORKFLOW_SCHEDULE_NOT_FOUND:
    'Store/workflow schedule not found.',
  ORDER_DELIVERY_SCHEDULE__RECURRING_SCHEDULE_NOT_FOUND:
    'Recurring schedule not found.',
  ORDER_DELIVERY_SCHEDULE__RECURRING_ILLOGICAL_DELIVERY_SEQUENCE:
    'Deliveries for a later order cannot be scheduled before a delivery for an earlier order.',
  ORDER_DELIVERY_SCHEDULE__ORDER_DAY_ALREADY_SCHEDULED:
    'Recurring order day already exists in the schedule.',
  ORDER_DELIVERY_SCHEDULE__DELIVERY_DAY_ALREADY_SCHEDULED:
    'Recurring delivery day already exists in the schedule.',
  ORDER_DELIVERY_EVENT_ORDER_DATE_BEFORE_CUTOFF:
    'Order and delivery dates must be in the future.',
  ORDER_DELIVERY_EVENT_DELIVERY_DATE_BEFORE_ORDER_DATE:
    'Delivery date must be after order date.',
  ORDER_DELIVERY_SCHEDULE_STORE_CLOSURE_EVENT_ALREADY_EXISTS:
    'A store closure already exists on this day, please update it instead.',
  ORDER_DELIVERY_SCHEDULE_STORE_CLOSURE_EDIT_IN_THE_PAST:
    'Store closure must be in the future.',
  ORDER_DELIVERY_INPUT_CUSTOMER_ID_MISMATCH:
    'Cannot create or edit schedule for this customer.',
  ORDER_DELIVERY_SCHEDULE_NOT_PUBLISHABLE:
    'Cannot publish this schedule as is.',
  ORDER_DELIVERY_SCHEDULE__NO_RECURRING_SCHEDULE:
    'Cannot add an event to a store with no recurring schedule.',
  ORDER_DELIVERY_SCHEDULE_REPUBLISH_PUBLISHED_SCHEDULE:
    "Cannot republish a schedule that's already published.",
  ORDER_DELIVERY_SCHEDULE_ARCHIVE_PUBLISHED_SCHEDULE:
    "Cannot archive a schedule that's already published.",
  INTERNAL_SERVER_ERROR: 'Something went wrong.',
}
