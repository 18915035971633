import { useCallback, useState } from 'react'
import tuple from 'shared/tuple'

export default function useToggleState(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState)

  const setClose = useCallback(() => setIsOpen(false), [])
  const setOpen = useCallback(() => setIsOpen(true), [])
  const toggle = useCallback(() => setIsOpen((prev) => !prev), [])

  return tuple([isOpen, { setClose, setOpen, toggle }])
}
