import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z"
      fill="currentColor"
    />
    <path
      d="M12 11.25V17"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 8.81006C12.6904 8.81006 13.25 8.25041 13.25 7.56006C13.25 6.8697 12.6904 6.31006 12 6.31006C11.3096 6.31006 10.75 6.8697 10.75 7.56006C10.75 8.25041 11.3096 8.81006 12 8.81006Z"
      fill="currentColor"
    />
  </svg>,
  'Info',
)
