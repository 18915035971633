import { useRef } from 'react'

let counter = 0

export function generateId() {
  counter += 1
  return counter
}

export function useId() {
  const id = useRef(generateId())
  return id.current
}
