import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5853_19419)">
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19Z"
        fill="currentColor"
      />
      <path d="M15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_5853_19419">
        <rect width="14" height="18" fill="white" transform="translate(5 3)" />
      </clipPath>
    </defs>
  </svg>,
  'Delete',
)
