import Button from 'app/components/Button/Button'

import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { useDeleteWorkflowAvailability } from 'app/packages/internal/customerConfigs/workflowAvailability/api/mutations/useDeleteWorkflowAvailability'
import { WorkflowAvailabilityRow } from 'app/packages/internal/customerConfigs/workflowAvailability/api/queries/useGetWorkflowAvailability'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

export const StyledInnerWrapper = styled.div`
  max-height: 450px;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export interface WorkflowAvailabilityDeleteModalProps {
  customerName?: string
  activeRow: WorkflowAvailabilityRow
  onClose: () => void
  onSuccess: () => void
}

export default function WorkflowAvailabilityDeleteModal({
  activeRow,
  onClose,
  onSuccess,
  customerName,
}: WorkflowAvailabilityDeleteModalProps) {
  const [loading, setLoading] = useState(false)
  const [deleteWorkflowAvailability] = useDeleteWorkflowAvailability()
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await deleteWorkflowAvailability(activeRow._id)
      setLoading(false)
      onSuccess()
    } catch (error) {
      setLoading(false)
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete Workflow Availability</Title>
      </Header>

      <Body>
        Delete the workflow availability configuration for workflow{' '}
        <b>
          {activeRow.workflow.name} ({activeRow.workflow.id})
        </b>{' '}
        and {activeRow.businessLevel.businessLevelMetadata.displayName}{' '}
        <b>
          {activeRow.businessLevel.name} ({activeRow.businessLevel.id})
        </b>{' '}
        for {customerName}?
        {errorMessage && (
          <StyledErrorMessage message={errorMessage} severityType="error" />
        )}
      </Body>

      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleDelete}>
            Delete
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
