import { gql } from '@apollo/client'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'

export const SCHEDULE_ISSUES_FIELDS = gql`
  ${STORE_FIELDS}
  fragment ScheduleIssuesFields on OrderDeliveryScheduleIssue {
    workflow {
      _id
      department {
        name
      }
      name
    }
    store {
      ...StoreFields
    }
    date
  }
`
