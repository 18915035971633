import { InventoryResultsDataFilters } from '@api/__gen__/gql'
import { useGetPastInventories } from 'app/packages/inventoryManagement/inventoryResults/api/queries/useGetPastInventories'
import EndingInventoriesTable, {
  EMPTY_FILTERS,
  PeriodType,
} from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/EndingInventoriesTable'
import React, { useState } from 'react'

export default function InventoryResultsPast() {
  const [selectedFilters, setSelectedFilters] = useState<
    InventoryResultsDataFilters
  >(EMPTY_FILTERS)
  const pastInventoriesData = useGetPastInventories(selectedFilters)

  const setPartialFilters = (
    partialFilters: Partial<InventoryResultsDataFilters>,
  ) =>
    setSelectedFilters((currentFilters) => ({
      ...currentFilters,
      ...partialFilters,
    }))

  return (
    <EndingInventoriesTable
      periodType={PeriodType.PAST}
      inventoriesData={pastInventoriesData}
      selectedFilters={selectedFilters}
      setSelectedFilters={setPartialFilters}
      clearFiltersFunction={() => setSelectedFilters(EMPTY_FILTERS)}
    />
  )
}
