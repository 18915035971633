import {
  GetCustomerInfoQuery,
  GetCustomerInfoQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { HEADER_KEYS } from 'app/api/common/constants'
import { SelectValue } from 'app/packages/core/pages/users/UserInvite/state'

export const GET_CUSTOMER_INFO = gql`
  query getCustomerInfo($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        departments {
          _id
          customerDepartmentId
          name
        }
        oidcProvider {
          _id
          isActive
        }
        departmentBundles {
          _id
          name
        }
        internalAfreshAdmin {
          productBundles {
            _id
            type
          }
        }
      }
    }
  }
`

export interface CustomerInfo {
  departmentBundles: SelectValue[]
  departments: SelectValue[]
  ssoEnabled: boolean
  productBundles?: { _id: string; type: string }[]
}

function reshapeData(data?: GetCustomerInfoQuery): CustomerInfo {
  if (data?.node?.__typename === 'Customer') {
    return {
      departmentBundles:
        data.node.departmentBundles?.map((bundle) => ({
          label: bundle.name,
          value: bundle._id,
        })) ?? [],
      departments:
        data.node.departments?.map((dpt) => ({
          label: `${dpt.customerDepartmentId} - ${dpt.name}`,
          value: dpt._id,
        })) ?? [],
      ssoEnabled: data.node.oidcProvider?.isActive ?? false,
      productBundles: data.node.internalAfreshAdmin?.productBundles ?? [],
    }
  }

  return {
    departmentBundles: [],
    departments: [],
    ssoEnabled: false,
    productBundles: [],
  }
}

export default function useGetCustomerInfo() {
  const { activeCustomerId, activeCustomerKey } = useCustomers()

  const { data, loading } = useQuery<
    GetCustomerInfoQuery,
    GetCustomerInfoQueryVariables
  >(GET_CUSTOMER_INFO, {
    skip: !activeCustomerId,
    variables: { customerId: activeCustomerId! },
    context: {
      headers: {
        [HEADER_KEYS.AFRESH_CUSTOMER_KEY]: activeCustomerKey,
      },
    },
  })

  return {
    data: reshapeData(data),
    loading,
  }
}
