import { DASHBOARD_ROOT_URI } from 'app/packages/fileUploads/pages/dashboard/dashboard.constants'
import useFetchTaskConfigs from 'app/packages/fileUploads/pages/dashboard/useFetchTaskConfigs.hook'
import { isEmpty, orderBy } from 'lodash-es'
import { useMemo } from 'react'

export default function useMakeNav() {
  const { config, isLoading, errored } = useFetchTaskConfigs()

  const navItems = useMemo(() => {
    if (errored) {
      throw new Error(
        'Failed to load task configurations for file uploads. Please refresh the page to try again.',
      )
    }

    if (isLoading || isEmpty(config)) {
      return []
    }

    return orderBy(
      Object.values(config).map((task) => ({
        customerId: task.customerId,
        name: task.name,
        path: `${DASHBOARD_ROOT_URI}/${task.taskIdentifier}`,
        disabled: !task.enabled,
        hidden: task.hidden,
      })),
      ['customerId'],
      ['asc'],
    )
  }, [config, isLoading, errored])

  return { isLoading, navItems }
}
