import Button from 'app/components/Button/Button'

import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { useDeleteDepartmentBundle } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/mutations/useDeleteDepartmentBundle'
import { DepartmentBundleRow } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetDepartmentBundles'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

export const StyledInnerWrapper = styled.div`
  max-height: 450px;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export interface DepartmentBundleDeleteModalProps {
  activeRow: DepartmentBundleRow
  onClose: () => void
  onSuccess: () => void
}

export default function DepartmentBundleManagerDeleteModal({
  activeRow,
  onClose,
  onSuccess,
}: DepartmentBundleDeleteModalProps) {
  const [loading, setLoading] = useState(false)
  const [deleteConfigDepartmentBundle] = useDeleteDepartmentBundle()
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await deleteConfigDepartmentBundle(activeRow._id)
      setLoading(false)
      onSuccess()
    } catch (error) {
      setLoading(false)
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete Department Bundle</Title>
      </Header>

      <Body>
        <strong>Are you sure you want to delete {activeRow?.name}?</strong>
        <p>
          Deleting a department bundle actively used in production could have
          significant and catastrophic impact on app functionality.
          <br />
          <br />
          You will not be able to delete a department bundle if:
          <ol>
            <li>It has any users associated with it.</li>
            <li>It has any guide structures associated with it.</li>
            <li>It has any workflows associated with it.</li>
          </ol>
        </p>
        {errorMessage && (
          <StyledErrorMessage message={errorMessage} severityType="error" />
        )}
      </Body>

      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleDelete}>
            Delete
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
