import { useReactiveVar } from '@apollo/client'
import useGetProductBundles from 'app/api/queries/useGetProductBundles'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { $activeBusinessLevel } from 'app/layouts/Customer/CustomerPageLayout.variables'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const SingleBusinessLevel = styled.div`
  margin-left: ${toRem(16)};
  margin-bottom: ${toRem(24)};
`

const StyledSingleSelect = styled(SingleSelect)`
  margin-left: ${toRem(16)};
  margin-bottom: ${toRem(24)};
  margin-top: ${toRem(4)};

  & .MuiInputBase-inputSizeSmall {
    font-weight: bold;
  }
`

export default function BusinessLevelSelector() {
  const { activeProductBundle } = useGetProductBundles()
  const activeBusinessLevel = useReactiveVar($activeBusinessLevel)

  // If level is chain, there's no need to display the business level selector
  if (activeProductBundle?.primaryBusinessLevel?.level === 'CHAIN') return null

  if (activeProductBundle?.businessLevelInstances?.length === 1) {
    return (
      <SingleBusinessLevel>
        <strong>
          {activeProductBundle?.primaryBusinessLevel?.displayName}{' '}
        </strong>
        {activeProductBundle?.businessLevelInstances[0].label}
      </SingleBusinessLevel>
    )
  }
  return (
    <StyledSingleSelect
      aria-label={activeProductBundle?.primaryBusinessLevel?.displayName}
      label={activeProductBundle?.primaryBusinessLevel?.displayName}
      width={208}
      value={activeBusinessLevel || ''}
      handleChange={(e) => $activeBusinessLevel(e.target.value)}
      options={activeProductBundle?.businessLevelInstances ?? []}
    />
  )
}
