import { gql } from '@apollo/client'
import { ENDING_INVENTORIES_FRAGMENTS } from 'app/packages/inventoryManagement/inventoryResults/api/fragments/EndingInventories'

export const inventoryResultsQuery = gql`
  ${ENDING_INVENTORIES_FRAGMENTS}
  query GetInventoryResults($input: InventoryResultsDataInput!) {
    inventoryResultsData(input: $input) {
      edges {
        node {
          ...EndingInventoryData
        }
      }
      pageInfo {
        currentPage
        pageSize
        totalPages
      }
      totalCount
    }
  }
`
