import {
  BusinessLevel,
  BusinessLevelInstance,
  GetCustomerBusinessLevelInstancesQuery,
  GetCustomerBusinessLevelInstancesQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { isNil } from 'lodash-es'

export const GET_BUSINESS_LEVEL_INSTANCES = gql`
  query getCustomerBusinessLevelInstances(
    $customerId: ID!
    $searchTerm: String
    $businessLevel: BusinessLevel
  ) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        businessLevelInstances(
          searchTerm: $searchTerm
          businessLevel: $businessLevel
        ) {
          edges {
            node {
              _id
              id
              name
              businessLevelMetadata {
                level
                displayName
              }
            }
          }
        }
      }
    }
  }
`

export default function useGetCustomerBusinessLevelInstances({
  searchTerm,
  businessLevel,
}: {
  searchTerm?: string
  businessLevel?: BusinessLevel
}) {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<
    GetCustomerBusinessLevelInstancesQuery,
    GetCustomerBusinessLevelInstancesQueryVariables
  >(GET_BUSINESS_LEVEL_INSTANCES, {
    skip: isNil(activeCustomerId) || isNil(businessLevel),
    variables: {
      customerId: activeCustomerId!,
      searchTerm: searchTerm || undefined,
      businessLevel: businessLevel || undefined,
    },
  })

  let businessLevelInstances: BusinessLevelInstance[] = []

  if (
    data &&
    data.node &&
    data.node.__typename === 'Customer' &&
    data.node.businessLevelInstances.edges
  ) {
    businessLevelInstances = data.node.businessLevelInstances.edges.map(
      (edge) => {
        return {
          __typename: 'BusinessLevelInstance',
          _id: edge.node._id,
          id: edge.node.id,
          name: edge.node.name,
          businessLevelMetadata: edge.node.businessLevelMetadata,
        }
      },
    )
  }

  return {
    data: businessLevelInstances,
    isLoading: loading,
  }
}
