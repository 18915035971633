import { Box } from '@mui/material'
import Table from 'app/components/Tables/Table'
import { BaseRow, TableProps } from 'app/components/Tables/Tables.types'
import { datalayer } from 'app/datalayer'
import { asDate } from 'app/packages/fileUploads/pages/dashboard/dashboard.helpers'
import { UploadResults } from 'app/packages/fileUploads/pages/dashboard/dashboard.types'
import JobResultModal from 'app/packages/fileUploads/pages/dashboard/JobResultModal/JobResultModal'
import { useVars } from 'app/VarsContext'
import React, { useEffect, useState } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components'

const StyledLabelAndDescription = styled(Box)({
  marginBottom: 20,
  '& h2': {
    fontWeight: 'bold',
    margin: '0',
  },
})

const StyledContainer = styled(Box)`
  height: calc(100vh - 130px);
`

const EmptyStateContainer = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const StyledTable = styled(Table)`
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
` as <T extends BaseRow>(props: TableProps<T>) => React.ReactElement

const columns: Column<UploadResults>[] = [
  { accessor: 'status', Header: 'Status' },
  { accessor: 'fileType', Header: 'Type' },
  {
    accessor: 'createdAt',
    Header: 'Created At',
    Cell: ({ row }) => <>{asDate(row.original.createdAt)}</>,
  },
  { accessor: 'userId', Header: 'User' },
  { accessor: 'customerId', Header: 'Customer' },
]

export default function RunningIntegrationJobList() {
  const { apiUrl } = useVars()
  const [isLoading, setIsLoading] = useState(true)
  const [job, setJob] = useState<UploadResults | null>(null)
  const [jobs, setJobs] = useState<{ err: string; items: UploadResults[] }>({
    err: '',
    items: [],
  })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ;(async () => {
      try {
        const result = await datalayer.importScript.status(apiUrl)
        setJobs({ err: '', items: result })
      } catch (e) {
        setJobs({ err: 'Error connecting to server', items: [] })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [apiUrl])

  const onRowClick = (row: UploadResults) => setJob(row)

  return (
    <>
      <StyledLabelAndDescription>
        <h2>Import Data History</h2>
        <p>Click the integration you’re interested in to view details.</p>
      </StyledLabelAndDescription>

      <StyledContainer>
        <StyledTable
          columns={columns}
          data={jobs.items}
          emptyStateContent={
            <EmptyStateContainer>No items found.</EmptyStateContainer>
          }
          getRowLabel={(row) => row.fileName}
          height="calc(100vh - 130px)"
          isLoading={isLoading}
          onRowClick={onRowClick}
        />
      </StyledContainer>
      {!!job && <JobResultModal job={job} onClose={() => setJob(null)} />}
    </>
  )
}
