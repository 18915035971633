import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { CONFIGURATIONS_ERRORS } from 'app/packages/internal/customerConfigs/configurations/api/errors'
import { CONFIGURATION_ROW } from 'app/packages/internal/customerConfigs/configurations/api/fragments/ConfigurationRow'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_CONFIGURATION = gql`
  ${CONFIGURATION_ROW}
  mutation deleteConfiguration(
    $deleteConfigurationInput: DeleteConfigurationInput!
  ) {
    deleteConfiguration(input: $deleteConfigurationInput) {
      configuration {
        ...ConfigurationRow
      }
    }
  }
`

export function useDeleteConfiguration() {
  const [deleteConfiguration, result] = useMutation(DELETE_CONFIGURATION, {
    refetchQueries: ['Configurations'],
  })

  return tuple([
    useCallback(
      async (id: string) => {
        const { errors } = await deleteConfiguration({
          variables: {
            deleteConfigurationInput: {
              id,
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            CONFIGURATIONS_ERRORS[getFirstErrorCode(errors)] ||
              `Delete configuration failed: ${errors[0].message}`,
          )
        }
      },
      [deleteConfiguration],
    ),
    result,
  ])
}
