import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { CELL_DISPLAY_VALUE } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import {
  DayOfWeek,
  DayOfWeekValues,
  RecurringOrder,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { ScheduleForm } from 'app/packages/storeSolutions/schedules/pages/Table/ScheduleForm'
import { isDirty } from 'app/utils'
import React, { useState } from 'react'
import styled from 'styled-components'

const Field = styled.div`
  align-items: center;
  column-gap: 4px;
  display: flex;
  margin: 8px 0px 16px;

  &:first-of-type {
    margin-bottom: 8px;
  }

  & > svg {
    margin-top: 6px;
  }
`

function getDayOfWeekOptions(
  schedule: RecurringOrder[],
  eventType: 'orderDay' | 'deliveryDay',
) {
  return Object.keys(DayOfWeekValues).map((dayOfWeek) => {
    const disabled = schedule.some((order) => order[eventType] === dayOfWeek)
    return {
      label: DayOfWeekValues[dayOfWeek as DayOfWeek],
      value: dayOfWeek,
      disabled,
    }
  })
}

export interface RecurringScheduleFormProps {
  initialOrderDay?: DayOfWeek
  initialDeliveryDay?: DayOfWeek
  isSaving: boolean
  onClose?: () => void
  onSave: (orderDay: DayOfWeek, deliveryDay: DayOfWeek) => Promise<void>
  recurringOrders: RecurringOrder[]
}

export default function RecurringScheduleForm({
  initialOrderDay,
  initialDeliveryDay,
  isSaving,
  onClose,
  onSave,
  recurringOrders,
}: RecurringScheduleFormProps) {
  const [orderDay, setOrderDay] = useState<DayOfWeek | ''>(
    initialOrderDay ?? '',
  )
  const [deliveryDay, setDeliveryDay] = useState<DayOfWeek | ''>(
    initialDeliveryDay ?? '',
  )

  const [errorMessage, setErrorMessage] = useState('')

  const orderDayOptions = getDayOfWeekOptions(recurringOrders, 'orderDay')
  const deliveryDayOptions = getDayOfWeekOptions(recurringOrders, 'deliveryDay')

  const handleDeliveryDayChange = (day: DayOfWeek) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    setDeliveryDay(day)
  }

  const handleOrderDayChange = (day: DayOfWeek) => {
    if (errorMessage) {
      setErrorMessage('')
    }
    setOrderDay(day)
  }

  const handleOnSave = async () => {
    try {
      if (errorMessage) {
        setErrorMessage('')
      }

      if (orderDay && deliveryDay) {
        await onSave(orderDay, deliveryDay)
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage((error as Error).message)
    }
  }

  const isEdit = !!initialOrderDay

  const isSaveDisabled =
    !orderDay ||
    !deliveryDay ||
    (isEdit &&
      !isDirty(initialOrderDay, orderDay) &&
      !isDirty(initialDeliveryDay, deliveryDay))

  return (
    <ScheduleForm
      isEdit={isEdit}
      isSaving={isSaving}
      isSaveDisabled={isSaveDisabled}
      onSave={handleOnSave}
      onClose={onClose}>
      <Field>
        {CELL_DISPLAY_VALUE.ORDER}
        <SingleSelect
          label="Order Day"
          options={orderDayOptions}
          handleChange={(e) =>
            handleOrderDayChange(e.target.value as DayOfWeek)
          }
          value={orderDay}
        />
      </Field>
      <Field>
        {CELL_DISPLAY_VALUE.DELIVERY}
        <SingleSelect
          label="Delivery Day"
          options={deliveryDayOptions}
          handleChange={(e) =>
            handleDeliveryDayChange(e.target.value as DayOfWeek)
          }
          value={deliveryDay}
        />
      </Field>
      {errorMessage && (
        <AlertMessageBlock message={errorMessage} severityType="error" />
      )}
    </ScheduleForm>
  )
}
