import {
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables,
} from '@api/__gen__/gql'
import {
  FetchResult,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client'

export const resetPasswordGql = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(input: { email: $email }) {
      success
    }
  }
`

export function useRequestPasswordReset(): (
  options?: MutationFunctionOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >,
) => Promise<FetchResult<RequestPasswordResetMutation>> {
  const [mutation] = useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(resetPasswordGql)

  return mutation
}
