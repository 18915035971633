import Button from 'app/components/Button/Button'
import React from 'react'
import styled from 'styled-components'

export const StyledErrorContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`

export const StyledErrorTitle = styled.title`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(24)};
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(8)};
`

const ERROR_TEXT_WIDTH = 350

export const StyledErrorMessage = styled.div`
  display: flex;
  text-align: center;
  max-width: ${({ theme }) => theme.typography.pxToRem(ERROR_TEXT_WIDTH)};
  justify-content: center;
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(32)};
`

export const DEFAULT_ERROR_TITLE = 'Something went wrong'

export const DEFAULT_ERROR_MESSAGE =
  'We were unable to load the page due to a system error.'

export const DEFAULT_ERROR_BUTTON_TEXT = 'Retry'

export interface ErrorDisplayProps {
  title?: string
  message?: string
  buttonText?: string
  onClickButton?: () => void
}

export default function ErrorDisplay({
  title = DEFAULT_ERROR_TITLE,
  message,
  buttonText = DEFAULT_ERROR_BUTTON_TEXT,
  onClickButton,
}: ErrorDisplayProps) {
  return (
    <StyledErrorContainer>
      <StyledErrorTitle>{title}</StyledErrorTitle>
      <StyledErrorMessage>
        {message || DEFAULT_ERROR_MESSAGE}
      </StyledErrorMessage>
      {onClickButton ? (
        <Button
          data-testid="error-back-button"
          variant="secondary"
          onClick={() => onClickButton()}>
          {buttonText}
        </Button>
      ) : null}
    </StyledErrorContainer>
  )
}
