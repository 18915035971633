import { useReactiveVar } from '@apollo/client'
import Accordion from 'app/components/Accordion/Accordion'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import { useGetConfigurationTypes } from 'app/packages/internal/customerConfigs/configurations/api/queries/useGetConfigurationTypes'
import { useGetConfigurations } from 'app/packages/internal/customerConfigs/configurations/api/queries/useGetConfigurations'
import { $activeConfigurationsConfig } from 'app/packages/internal/customerConfigs/configurations/pages/Configurations.variables'
import ConfigurationsAddEditModal from 'app/packages/internal/customerConfigs/configurations/pages/ConfigurationsAddEditModal'
import ConfigurationsDeleteModal from 'app/packages/internal/customerConfigs/configurations/pages/ConfigurationsDeleteModal'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import { toRem } from 'app/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import ConfigurationsTable from './ConfigurationsTable'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`

export default function Configurations({
  disableVirtualization = false,
}: {
  disableVirtualization?: boolean
}) {
  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const activeConfigurationsConfig = useReactiveVar($activeConfigurationsConfig)

  const [configNameFilter, setConfigNameFilter] = useState('')

  const { rows, loading } = useGetConfigurations({
    configNameFilter,
  })

  const {
    types,
    loading: configurationTypesLoading,
  } = useGetConfigurationTypes()

  const activeRow =
    activeConfigurationsConfig !== null &&
    rows.find((row) => activeConfigurationsConfig === row._id)

  useEffect(() => {
    const shouldSetEditState =
      activeConfigurationsConfig !== null &&
      modalState !== ModalState.edit &&
      modalState !== ModalState.delete
    if (shouldSetEditState) {
      setModalState(ModalState.edit)
    }
  }, [activeConfigurationsConfig, modalState, setModalState])

  const configNameOptions = useMemo(() => {
    if (types && types.length === 0)
      return [
        {
          label: '--',
          value: '',
        },
      ]

    return [
      {
        label: '--',
        value: '',
      },
      ...types.map((configurationType) => ({
        label: configurationType.name,
        value: configurationType.name,
      })),
    ]
  }, [types])

  const onSuccessfulSave = useCallback(async () => {
    setModalState(null)
    $activeConfigurationsConfig(null)
  }, [setModalState])

  return (
    <>
      <StyledHeader>RDB Configurations</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          This tool is for managing all configurations in the rdb.configurations
          table
          <p>
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/afresh/RDB-Configurations-Management-Tool-803d87e38012499ca0b1a3c9cdbc82df?pvs=4',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <ConfigurationsTable
        configNameFilters={configNameOptions}
        configNameFilter={configNameFilter}
        setConfigNameFilter={setConfigNameFilter}
        rows={rows}
        disableVirtualization={disableVirtualization}
        isLoading={loading || configurationTypesLoading}
        onClickAddNew={() => setModalState(ModalState.add)}
        setupDetailsExpanded={setupDetailsExpanded}
      />
      {modalState === ModalState.add && (
        <ConfigurationsAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeConfigurationsConfig(null)
            setModalState(null)
          }}
          configTypes={types}
          configTypeOptions={configNameOptions}
          onSuccess={onSuccessfulSave}
          rows={rows}
        />
      )}
      {modalState === ModalState.edit && activeRow && (
        <ConfigurationsAddEditModal
          modalState={modalState}
          onClose={() => {
            $activeConfigurationsConfig(null)
            setModalState(null)
          }}
          configTypes={types}
          configTypeOptions={configNameOptions}
          onSuccess={onSuccessfulSave}
          onDelete={() => setModalState(ModalState.delete)}
          activeRow={activeRow}
          rows={rows}
        />
      )}
      {modalState === ModalState.delete && activeRow && (
        <ConfigurationsDeleteModal
          onClose={() => {
            $activeConfigurationsConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
        />
      )}
    </>
  )
}
