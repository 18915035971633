import { OffsetPageInfo } from '@api/__gen__/gql'
import { AddCircleOutline } from '@mui/icons-material'
import {
  DataGridPremium,
  GridColDef,
  GridRowsProp,
} from '@mui/x-data-grid-premium'
import { Autocomplete } from 'app/components/Autocomplete/Autocomplete'
import Button from 'app/components/Button/Button'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TableHeader from 'app/components/Tables/TableHeader'
import Tag from 'app/components/Tag/Tag'
import { PAGE_SIZE_OPTIONS } from 'app/constants'
import { formatDatetime } from 'app/helpers/date'
import { UnloadOrderSequenceRow } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/queries/useGetUnloadOrderSequence'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequence'
import { $activeUnloadOrderSequenceConfig } from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequence.variables'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'

const StyledEmptyState = styled.div`
  margin: 20px;
`

const StyledTableContainer = styled.div<{ $setupDetailsExpanded: boolean }>`
  max-height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  height: ${({ $setupDetailsExpanded }) =>
    $setupDetailsExpanded ? 'calc(100vh - 420px)' : 'calc(100vh - 310px)'};
  padding-top: 12px;

  & .MuiDataGrid-row:hover {
    cursor: pointer;
  }

  & .MuiDataGrid-cell:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus {
    outline: none;
  }
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .MuiDataGrid-withBorderColor {
    border-top: none;
    border-radius: 0;
  }
`

export interface Props {
  isLoading?: boolean
  disableVirtualization?: boolean
  rows: UnloadOrderSequenceRow[]
  pageInfo?: OffsetPageInfo
  setupDetailsExpanded: boolean
  onPageChange: (pageIndex: number, pageSize: number) => void
  onClickAddNew: () => void
  storeFilters: Option[]
  storeFilter: Option | null
  setStoreFilter: (value: Option | null) => void
  workflowFilters: Option[] | null
  workflowFilter: string
  setWorkflowFilter: (value: string) => void
}

const StyledAddNewButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const StyledAddNewIcon = styled(AddCircleOutline)`
  margin-right: 8px;
`

const StyledTableHeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  flex-grow: 1;
`

export const StyledAutocomplete = styled(Autocomplete)`
  width: ${toRem(300)};
  line-height: 0;
  &&& .MuiFormControl-root {
    margin: 0;
  }
` as typeof Autocomplete

export const UnloadOrderSequenceColumns: GridColDef[] = [
  {
    field: 'id',
    valueGetter: (_, row) => row.databaseId,
    headerName: 'ID',
    width: 75,
    sortable: false,
  },
  {
    field: 'store',
    valueGetter: (_, row) => row.store,
    renderCell: (row) => {
      if (!row.value) return 'CHAINWIDE (-1)'
      return `${row.value.customerStoreId} - ${row.value.name}`
    },
    headerName: 'Store',
    width: 200,
    sortable: false,
  },
  {
    field: 'workflow',
    valueGetter: (_, row) => row.workflow,
    headerName: 'Workflow',
    renderCell: (row) => {
      if (!row.value) return 'ALL (-1)'
      return `${row.value.name} (${row.value.id})`
    },
    width: 182,
    sortable: false,
  },
  {
    field: 'dayOfWeek',
    valueGetter: (_, row) => row.dayOfWeek,
    renderCell: (row) => {
      return row.value === -1 ? 'ALL (-1)' : row.value
    },
    headerName: 'Day Of Week',
    width: 152,
    sortable: false,
  },
  {
    field: 'unloadOrderSequence',
    headerName: 'Sequence',
    width: 182,
    renderCell: (row) => {
      return row.value === 'ORDER_THEN_UNLOAD' ? (
        <Tag text="Order Then Unload" color="highlight_02" />
      ) : (
        <Tag text="Unload Then Order" color="highlight_04" />
      )
    },
    sortable: false,
  },
  {
    field: 'createdAt',
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Created At',
    width: 150,
    sortable: false,
  },
  {
    field: 'updatedAt',
    renderCell: (row) => formatDatetime(row.value),
    headerName: 'Updated At',
    width: 150,
    sortable: false,
  },
]

export default function UnloadOrderSequenceTable(props: Props) {
  const {
    isLoading,
    rows,
    onClickAddNew,
    setupDetailsExpanded,
    pageInfo,
    onPageChange,
    disableVirtualization = false,
    storeFilters,
    storeFilter,
    setStoreFilter,
    workflowFilters,
    workflowFilter,
    setWorkflowFilter,
  } = props

  return (
    <StyledTableContainer $setupDetailsExpanded={setupDetailsExpanded}>
      <TableHeader>
        <StyledAutocomplete
          options={storeFilters}
          value={storeFilter}
          label="Store"
          onChange={(_, value) => setStoreFilter(value)}
        />
        <SingleSelect
          options={workflowFilters || []}
          value={workflowFilter}
          label="Workflow"
          handleChange={(e) => setWorkflowFilter(e.target.value)}
        />
        <StyledTableHeaderButtonsContainer>
          <StyledAddNewButton onClick={onClickAddNew}>
            <StyledAddNewIcon /> Add New
          </StyledAddNewButton>
        </StyledTableHeaderButtonsContainer>
      </TableHeader>
      <DataGridPremium
        rows={rows as GridRowsProp}
        getRowId={(row) => {
          return row._id
        }}
        slots={{
          noRowsOverlay: () => (
            <StyledEmptyState>
              No config found for this customer
            </StyledEmptyState>
          ),
        }}
        paginationModel={{
          pageSize: pageInfo?.pageSize ?? DEFAULT_PAGE_SIZE,
          page: pageInfo?.currentPage ? pageInfo?.currentPage - 1 : 0,
        }}
        paginationMode="server"
        onPaginationModelChange={(paginationModel) => {
          onPageChange(paginationModel.page, paginationModel.pageSize)
        }}
        rowCount={pageInfo?.totalCount ?? 0}
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableVirtualization={disableVirtualization}
        columns={UnloadOrderSequenceColumns}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
        pagination
        loading={isLoading}
        onRowClick={({ row }) =>
          $activeUnloadOrderSequenceConfig(row.databaseId)
        }
      />
    </StyledTableContainer>
  )
}
