import {
  GetInventoryResultsQuery,
  GetInventoryResultsQueryVariables,
  InventoryResultsDataFilters,
} from '@api/__gen__/gql'
import { ApolloQueryResult, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { inventoryResultsQuery } from 'app/packages/inventoryManagement/inventoryResults/api/queries/inventoryResultsQuery'
import useGetEndingInventoryFilters, {
  areStoreFiltersValid,
} from 'app/packages/inventoryManagement/inventoryResults/api/queries/useGetInventoryResultsFilters'
import { InventoryResultsData } from 'app/packages/inventoryManagement/inventoryResults/context/inventoryResultsContext'
import { EndingInventoryProps } from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/EndingInventoriesTable'
import { convertGQLStatusToUIStatus } from 'app/packages/inventoryManagement/inventoryResults/pages/InventoryResults.helpers'
import { toTitleCase } from 'app/utils'
import { isNil } from 'lodash-es'
import { useMemo } from 'react'

export function useGetPastInventories(
  filters: InventoryResultsDataFilters,
): InventoryResultsData {
  const { activeCustomerId } = useCustomers()

  const {
    stores,
    regions,
    businessUnits,
    departmentBundles,
    isLoadingFilters,
    accountingPeriods,
    filtersError,
    refetch: refetchFilters,
  } = useGetEndingInventoryFilters(filters)

  const areFiltersValid = areStoreFiltersValid(
    stores,
    regions,
    businessUnits,
    filters.storeIds,
    filters.regionIds,
    filters.businessUnitIds,
  )

  const { error, data, loading, fetchMore, refetch: refetchResults } = useQuery<
    GetInventoryResultsQuery,
    GetInventoryResultsQueryVariables
  >(inventoryResultsQuery, {
    skip: isNil(activeCustomerId) || isLoadingFilters || !areFiltersValid,
    variables: {
      input: {
        offset: 0,
        filters,
      },
    },
    notifyOnNetworkStatusChange: true, // Show loader when fetchMore loads the next page
    fetchPolicy: 'cache-and-network',
  })

  const fetchNextPage = async (): Promise<void | ApolloQueryResult<
    GetInventoryResultsQuery
  >> => {
    const currentPage = data?.inventoryResultsData.pageInfo.currentPage
    const pageSize = data?.inventoryResultsData.pageInfo.pageSize
    const totalPages = data?.inventoryResultsData.pageInfo.totalPages
    if (isNil(currentPage) || !pageSize || !totalPages) {
      Sentry.captureMessage(
        "Missing pagination data: couldn't fetch next page for inventories",
        {
          level: 'warning',
        },
      )
      return Promise.resolve()
    }

    if (currentPage >= totalPages) {
      Sentry.captureMessage('Attempted to fetch a page after the final page.', {
        level: 'warning',
      })
      return Promise.resolve()
    }

    return fetchMore({
      variables: {
        input: {
          offset: currentPage * pageSize,
          filters,
        },
      },
    }).catch((e) => {
      Sentry.captureException(e)
    })
  }

  if (error) {
    Sentry.captureException(error, (sentryScope) => {
      sentryScope.setContext('inventory', {
        activeCustomerId,
      })
      sentryScope.setTransactionName(`Failed to fetch ending inventories`)
      sentryScope.setLevel('warning')
      return sentryScope
    })
  }

  const endingInventories: EndingInventoryProps[] = useMemo(() => {
    const transformedData: EndingInventoryProps[] = []
    if (data?.inventoryResultsData) {
      data.inventoryResultsData.edges.forEach(({ node: ei }) => {
        if (ei) {
          transformedData.push({
            ...ei,
            status: ei.status && convertGQLStatusToUIStatus(ei.status),
            storeId: ei.store.id,
            businessUnitName: toTitleCase(ei.store.businessUnitName ?? ''),
            regionName: toTitleCase(ei.store.regionName ?? ''),
            departmentBundleName: toTitleCase(
              ei.workflow.departmentBundle.name,
            ),
          })
        }
      })
    }
    return transformedData
  }, [data])

  const currentPage = data?.inventoryResultsData.pageInfo.currentPage ?? 0
  const finalPage = data?.inventoryResultsData.pageInfo.totalPages ?? 0
  const hasNextPage = currentPage < finalPage

  return {
    isLoadingEndingInventories: isLoadingFilters || loading,
    hasNextPage,
    fetchNextPage,
    endingInventories,
    stores,
    regions: regions.map((region) => ({
      ...region,
      name: toTitleCase(region.name ?? ''),
    })),
    businessUnits: businessUnits.map((bu) => ({
      ...bu,
      name: toTitleCase(bu.name ?? ''),
    })),
    departmentBundles: departmentBundles.map((dept) => ({
      ...dept,
      name: toTitleCase(dept.name ?? ''),
    })),
    accountingPeriods,
    inventoryStatuses: [],
    error: filtersError ?? error,
    refetch: async () => {
      await refetchFilters?.()
      await refetchResults?.()
    },
  }
}
