import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { DEPARTMENT_BUNDLE_ERRORS } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/errors'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_DEPARTMENT_BUNDLE = gql`
  mutation deleteDepartmentBundle($input: DeleteDepartmentBundleInput!) {
    deleteDepartmentBundle(input: $input) {
      departmentBundle {
        _id
      }
    }
  }
`

export function useDeleteDepartmentBundle() {
  const [deleteDepartmentBundle, result] = useMutation(
    DELETE_DEPARTMENT_BUNDLE,
    {
      refetchQueries: ['getDepartmentBundles'],
    },
  )

  return tuple([
    useCallback(
      async (id: string) => {
        const { errors } = await deleteDepartmentBundle({
          variables: {
            input: {
              id,
            },
          },
        })

        if (errors?.length) {
          throw new Error(
            DEPARTMENT_BUNDLE_ERRORS[getFirstErrorCode(errors)] ||
              'Delete Department Bundle Failed',
          )
        }
      },
      [deleteDepartmentBundle],
    ),
    result,
  ])
}
