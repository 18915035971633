import { styled } from '@mui/material'
import Button from 'app/components/Button/Button'
import React from 'react'

const StyledBulkEditDone = styled(Button)`
  margin-left: auto;
`

interface BulkEditDoneButtonProps {
  className?: string
  onDone: () => void
}

export function BulkEditDoneButton(props: BulkEditDoneButtonProps) {
  const { className, onDone } = props
  return (
    <StyledBulkEditDone
      className={className}
      variant="secondary"
      onClick={onDone}>
      Done
    </StyledBulkEditDone>
  )
}
