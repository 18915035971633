// This client was automatically generated by Segment Typewriter. ** Do Not Edit **
// To update this file, run:
//   npx typewriter

export interface BackToInventoryResultsScreen {
  afreshItemId?: string
  backCaseCount?: number
  class?: string
  /**
   * The ID that's displayed on the page. Logic: storeOrderableItem.displayableItemId or
   * otherwise AfreshId
   */
  displayId?: string
  floorCaseCount?: number
  name?: string
  orderableItemId?: string
  section?: string
  subclass?: string
  [property: string]: any
}

export interface EndingInventoryClosed {
  endingInventoryId?: string
  /**
   * If the inventory is part of an active counting period
   */
  isActiveInventory?: boolean
  /**
   * Whether a counting period is currently active
   */
  isCountingPeriodActive?: boolean
  /**
   * Feature flag for inventory adjustments - set on an inventory level
   */
  isInventoryAdjustmentsEnabled?: boolean
  /**
   * Feature flag for inventory approval - set on a user permissions level
   */
  isInventoryApprovalEnabled?: boolean
  /**
   * The name of the period: e.g. "P2 2023"
   */
  periodName?: string
  /**
   * Examples: "OPENED", "SUBMITTED"
   */
  status?: string
  [property: string]: any
}

export interface EndingInventoryOpened {
  endingInventoryId?: string
  /**
   * If the inventory is part of an active counting period
   */
  isActiveInventory?: boolean
  /**
   * Whether a counting period is currently active
   */
  isCountingPeriodActive?: boolean
  /**
   * Feature flag for inventory adjustments - set on an inventory level
   */
  isInventoryAdjustmentsEnabled?: boolean
  /**
   * Feature flag for inventory approval - set on a user permissions level
   */
  isInventoryApprovalEnabled?: boolean
  /**
   * The name of the period: e.g. "P2 2023"
   */
  periodName?: string
  /**
   * Examples: "OPENED", "SUBMITTED"
   */
  status?: string
  [property: string]: any
}

/**
 * Event triggered when the user expands or collapses the Assign Categories accordion in the
 * Section editor modal
 */
export interface GuideOrganizationAssignCategoriesAccordionClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the accordion is opened or closed
   */
  isExpanded: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Assign Categories' nav button in the Section editor
 * modal
 */
export interface GuideOrganizationAssignCategoriesButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies which flow a user is in
   */
  isEditing: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user selects categories to assign to a Section
 */
export interface GuideOrganizationAssignCategoriesSelected {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies which flow a user is in
   */
  isEditing: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  /**
   * A list of selected IDs
   */
  selectedPrimaryMappings: any
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Create Section'
 */
export interface GuideOrganizationCreateSectionButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Edit Guide Organization'
 */
export interface GuideOrganizationDraftCreated {
  /**
   * Name of the department bundle
   */
  departmentBundleName?: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId?: null | string
  /**
   * Product area name
   */
  productName?: null | string
  [property: string]: any
}

/**
 * Event triggered with then the created event fails
 */
export interface GuideOrganizationDraftCreatedFailed {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * The GQL errors as a result of the failed API call
   */
  error?: any
  /**
   * GQL ID of the current guide
   */
  guideStructureId?: null | string
  /**
   * Product area name
   */
  productName?: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Cancel' inside the discard changes confirmation
 * modal
 */
export interface GuideOrganizationDraftDiscardCanceled {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user discards draft changes
 */
export interface GuideOrganizationDraftDiscarded {
  /**
   * Name of the department bundle
   */
  departmentBundleName?: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId?: null | string
  /**
   * Product area name
   */
  productName?: null | string
  [property: string]: any
}

/**
 * Event triggered with then the discard event fails
 */
export interface GuideOrganizationDraftDiscardedFailed {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * The GQL errors as a result of the failed API call
   */
  error?: any
  /**
   * GQL ID of the current guide
   */
  guideStructureId?: null | string
  /**
   * Product area name
   */
  productName?: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Cancel' inside the publish changes confirmation
 * modal
 */
export interface GuideOrganizationDraftPublishCanceled {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user publishes the draft guide
 */
export interface GuideOrganizationDraftPublished {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the publish event fails
 */
export interface GuideOrganizationDraftPublishedFailed {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * The GQL errors as a result of the failed API call
   */
  error?: any
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Edit' for a section
 */
export interface GuideOrganizationEditSectionButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Name of the section being edited
   */
  sectionName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Inventory Settings' in the Section modal
 */
export interface GuideOrganizationInventorySettingsButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Whether the modal is for editing an existing section or create
   */
  isEditing: boolean
  /**
   * Product area name
   */
  productName: string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Move To' from the Items table
 */
export interface GuideOrganizationItemOverridesCancelButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * List of items actively selected
   */
  selectedItems?: any
  [property: string]: any
}

/**
 * Event triggered when the user closes the Item Overrides modal by clicking 'Cancel'
 */
export interface GuideOrganizationItemOverridesMoveToButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user saves changes inside the Item Overrides modal
 */
export interface GuideOrganizationItemOverridesSaved {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  itemOverridesResult?: any
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks a specific item row in the Items table
 */
export interface GuideOrganizationItemRowClicked {
  afreshItemId?: null | string
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  itemCategory?: null | string
  itemClass?: null | string
  itemId?: null | string
  itemName?: null | string
  itemSubClass?: null | string
  orderCode?: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user unchecks the checkbox next to an item name
 */
export interface GuideOrganizationItemRowDeselected {
  afreshItemId?: null | string
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  itemName?: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user checks the checkbox next to an item name
 */
export interface GuideOrganizationItemRowSelected {
  afreshItemId?: null | string
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  itemName?: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when a filter has been applied to Items table
 */
export interface GuideOrganizationItemsTableFiltered {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Filter value for showing items that have been edited in draft
   */
  hasBeenEdited?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Filter value for the search query
   */
  searchQuery?: null | string
  /**
   * Filter value for the selected category ID
   */
  selectedCategoryId?: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Ordering Settings' in the Section modal
 */
export interface GuideOrganizationOrderingSettingsButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Whether the modal is for editing an existing section or creating a new one
   */
  isEditing: boolean
  /**
   * Product area name
   */
  productName: string
  [property: string]: any
}

/**
 * Event triggered when the user expands or collapses the Reassign Categories accordion in
 * the Section editor modal
 */
export interface GuideOrganizationReassignCategoriesAccordionClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the accordion is opened or closed
   */
  isExpanded: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user selects the new section to reassign categories to
 */
export interface GuideOrganizationReassignCategoriesNewSectionSelected {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * ID of the selected section
   */
  newSectionId: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user selects categories to reassign to another Section
 */
export interface GuideOrganizationReassignCategoriesSelected {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * A list of selected IDs
   */
  selectedPrimaryMappings: any
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Cancel' inside the Section editor modal
 */
export interface GuideOrganizationSectionEditorModalCancelButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Save All Changes' inside the Section editor modal
 */
export interface GuideOrganizationSectionEditorModalChangesSavedFailed {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Save All Changes' inside the Section editor modal
 */
export interface GuideOrganizationSectionEditorModalDeleteFailed {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Next' inside the Section editor modal
 */
export interface GuideOrganizationSectionEditorModalNextButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user modifies the 'Parent Category' field in the Section editor
 */
export interface GuideOrganizationSectionEditorModalParentCategoryValueChanged {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing: boolean | null
  /**
   * Value of the new parent category id
   */
  parentCategoryId: null | string
  /**
   * Value of the current parent category id
   */
  previousParentCategoryId?: null | string
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Save All Changes' inside the Section editor modal
 */
export interface GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing?: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user modifies the 'Section Name' field in the Section editor
 */
export interface GuideOrganizationSectionEditorModalSectionNameValueChanged {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing: boolean | null
  /**
   * Value of the current section name
   */
  previousSectionName?: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Value of the new section name
   */
  sectionName: null | string
  [property: string]: any
}

/**
 * Event triggered when the user modifies the 'Sort this to' field in the Section editor
 */
export interface GuideOrganizationSectionEditorModalSortThisToValueChanged {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies if the user is creating or editing a Section
   */
  isEditing: boolean | null
  /**
   * Value of the current sort index
   */
  previousSortIndex?: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Value of the new sort index
   */
  sortIndex: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks 'Section Name and Settings' nav button in the
 * Section editor modal
 */
export interface GuideOrganizationSectionNameAndSettingsButtonClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Identifies which flow a user is in
   */
  isEditing: boolean | null
  /**
   * Product area name
   */
  productName: null | string
  [property: string]: any
}

/**
 * Event triggered when a filter has been applied to Sections table
 */
export interface GuideOrganizationSectionsTableFiltered {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Filter value for the search query
   */
  searchQuery?: null | string
  /**
   * Filter value for the selected category ID
   */
  selectedCategoryId?: null | string
  [property: string]: any
}

/**
 * Event triggered when the user clicks on the table nav routers: 'Sections' and 'Items'
 */
export interface GuideOrganizationTableRouterTabClicked {
  /**
   * Name of the department bundle
   */
  departmentBundleName?: null | string
  /**
   * GQL ID of the current guide
   */
  guideStructureId?: null | string
  /**
   * Product area name
   */
  productName?: null | string
  /**
   * Name of the route clicked
   */
  route?: null | string
  /**
   * The current url state
   */
  urlSearchParams?: null | string
  [property: string]: any
}

/**
 * Clicked "Approve Inventory" and the confirmation button
 */
export interface InventoryApproveClicked {
  endingInventoryId?: string
  /**
   * If the inventory is part of an active counting period
   */
  isActiveInventory?: boolean
  /**
   * Whether the approval succeeded or otherwise an error message was shown
   */
  isApprovalSuccessful: boolean
  /**
   * Whether a counting period is currently active
   */
  isCountingPeriodActive?: boolean
  /**
   * Feature flag for inventory adjustments - set on an inventory level
   */
  isInventoryAdjustmentsEnabled?: boolean
  /**
   * Feature flag for inventory approval - set on a user permissions level
   */
  isInventoryApprovalEnabled?: boolean
  /**
   * The name of the period: e.g. "P2 2023"
   */
  periodName?: string
  /**
   * Examples: "OPENED", "SUBMITTED"
   */
  status?: string
  [property: string]: any
}

export interface InventoryItemViewedDetails {
  afreshItemId?: string
  backCaseCount?: number
  class?: string
  /**
   * The ID that's displayed on the page. Logic: storeOrderableItem.displayableItemId or
   * otherwise AfreshId
   */
  displayId?: string
  endingInventoryId?: string
  floorCaseCount?: number
  /**
   * If the inventory is part of an active counting period
   */
  isActiveInventory?: boolean
  /**
   * Whether a counting period is currently active
   */
  isCountingPeriodActive?: boolean
  /**
   * Feature flag for inventory adjustments - set on an inventory level
   */
  isInventoryAdjustmentsEnabled?: boolean
  /**
   * Feature flag for inventory approval - set on a user permissions level
   */
  isInventoryApprovalEnabled?: boolean
  name?: string
  orderableItemId?: string
  /**
   * The name of the period: e.g. "P2 2023"
   */
  periodName?: string
  section?: string
  /**
   * Examples: "OPENED", "SUBMITTED"
   */
  status?: string
  subclass?: string
  [property: string]: any
}

export interface InventoryItemsSearched {
  endingInventoryId?: string
  /**
   * If the inventory is part of an active counting period
   */
  isActiveInventory?: boolean
  /**
   * Whether a counting period is currently active
   */
  isCountingPeriodActive?: boolean
  /**
   * Feature flag for inventory adjustments - set on an inventory level
   */
  isInventoryAdjustmentsEnabled?: boolean
  /**
   * Feature flag for inventory approval - set on a user permissions level
   */
  isInventoryApprovalEnabled?: boolean
  /**
   * The name of the period: e.g. "P2 2023"
   */
  periodName?: string
  query: string
  /**
   * Examples: "OPENED", "SUBMITTED"
   */
  status?: string
  [property: string]: any
}

/**
 * Event triggered when a user clicks the download button
 */
export interface OrderGuideDownloadButtonClicked {
  departmentBundleName?: null | string
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when a user clicks the pencil icon in the item details drawer
 */
export interface OrderGuideEditItemNameButtonClicked {
  departmentBundleName?: null | string
  item?: any
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when a user clicks the 'Exclude' button
 */
export interface OrderGuideExcludeButtonClicked {
  departmentBundleName?: null | string
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when a user clicks the 'Include' button
 */
export interface OrderGuideIncludeButtonClicked {
  departmentBundleName?: null | string
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Order Guide Item name was changed manually by a user
 */
export interface OrderGuideItemNameEdited {
  departmentBundleName?: null | string
  itemId?: null | string
  name?: null | string
  orderCode?: null | string
  previousName?: null | string
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when an item update is canceled from the Scheduled Updates table
 */
export interface OrderGuideItemUpdateCanceled {
  departmentBundleName?: null | string
  item?: any
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when an item exclusion is successfully added
 */
export interface OrderGuideItemsExclusionSuccessfullySaved {
  departmentBundleName?: null | string
  productName?: null | string
  selectedItems?: any
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when an item inclusion is successfully added
 */
export interface OrderGuideItemsInclusionSuccessfullySaved {
  departmentBundleName?: null | string
  productName?: null | string
  selectedItems?: any
  workflowName?: null | string
  [property: string]: any
}

/**
 * Event triggered when user publishes the draft schedule
 */
export interface OrderAndDeliveriesDraftSchedulePublished {
  /**
   * Name of the department bundle
   */
  departmentBundleName: null | string
  /**
   * Product area name
   */
  productName: null | string
  /**
   * Id of the workflow
   */
  workflowId: null | string
  /**
   * Name of the workflow
   */
  workflowName: null | string
  [property: string]: any
}

/**
 * Page load: Inventory Results
 */
export interface PageInventoryResultsLoaded {
  isCountingPeriodActive?: boolean
  [property: string]: any
}

/**
 * Event triggered when a user clicks the secondary side nav link
 */
export interface SecondarySideNavLinkClicked {
  departmentBundleName?: null | string
  productName?: null | string
  workflowName?: null | string
  [property: string]: any
}

/**
 * The analytics.js snippet should be available via window.analytics.
 * You can install it by following instructions at: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 * Make sure to also include the TypeScript declarations with: `npm install @segment/analytics-next` (install with --save-dev for type definitions only).
 *
 * If you don't want to use the snippet, you can also install the `@segment/analytics-next` library as a *production* dependency and use it like this:
 * ```ts
 * import { AnalyticsBrowser } from '@segment/analytics-next'
 * import { setTypewriterOptions } from './analytics'
 *
 * const analytics = AnalyticsBrowser.load({ writeKey: 'SEGMENT_WRITE_KEY' })
 *
 * setTypewriterOptions({ analytics: analytics })
 */
import type {
  AnalyticsSnippet,
  Analytics,
  AnalyticsBrowser,
  Options,
} from '@segment/analytics-next'

declare global {
  interface Window {
    analytics: AnalyticsSnippet
  }
}

type AnyAnalytics = AnalyticsSnippet | Analytics | AnalyticsBrowser

/** The callback exposed by analytics.js. */
export type Callback = () => void

export type ViolationHandler = (
  message: Record<string, any>,
  violations: any[],
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
  message,
  violations,
) => {
  const msg = JSON.stringify(
    {
      type: 'Typewriter JSON Schema Validation Error',
      description:
        `You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
        'Tracking Plan spec.',
      errors: violations,
    },
    undefined,
    2,
  )

  console.warn(msg)
}

let analytics: () => AnyAnalytics | undefined = () => {
  return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
  /**
   * Underlying analytics instance where analytics calls are forwarded on to.
   * Defaults to window.analytics.
   */
  analytics?: AnyAnalytics
  /**
   * Handler fired when if an event does not match its spec. This handler
   * does not fire in production mode, because it requires inlining the full
   * JSON Schema spec for each event in your Tracking Plan.
   *
   * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
   * if a message does not match the spec. Otherwise, errors will be logged to stderr.
   */
  onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
  analytics = options.analytics
    ? () => options.analytics || window.analytics
    : analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Options = {}): Options {
  return {
    ...message,
    context: {
      ...(message.context || {}),
      typewriter: {
        language: 'typescript',
        version: '9.2.0',
      },
    },
  }
}

/**
 * Fires a 'BackToInventoryResultsScreen' track call.
 *
 * @param BackToInventoryResultsScreen props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function backToInventoryResultsScreen(
  props: BackToInventoryResultsScreen,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Back to Inventory Results Screen',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EndingInventoryClosed' track call.
 *
 * @param EndingInventoryClosed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function endingInventoryClosed(
  props: EndingInventoryClosed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Ending Inventory Closed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'EndingInventoryOpened' track call.
 *
 * @param EndingInventoryOpened props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function endingInventoryOpened(
  props: EndingInventoryOpened,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Ending Inventory Opened',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationAssignCategoriesAccordionClicked' track call.
 *
 * @param GuideOrganizationAssignCategoriesAccordionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationAssignCategoriesAccordionClicked(
  props: GuideOrganizationAssignCategoriesAccordionClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Assign Categories Accordion Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationAssignCategoriesButtonClicked' track call.
 *
 * @param GuideOrganizationAssignCategoriesButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationAssignCategoriesButtonClicked(
  props: GuideOrganizationAssignCategoriesButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Assign Categories Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationAssignCategoriesSelected' track call.
 *
 * @param GuideOrganizationAssignCategoriesSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationAssignCategoriesSelected(
  props: GuideOrganizationAssignCategoriesSelected,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Assign Categories Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationCreateSectionButtonClicked' track call.
 *
 * @param GuideOrganizationCreateSectionButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationCreateSectionButtonClicked(
  props: GuideOrganizationCreateSectionButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Create Section Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftCreated' track call.
 *
 * @param GuideOrganizationDraftCreated props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftCreated(
  props: GuideOrganizationDraftCreated,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Created',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftCreatedFailed' track call.
 *
 * @param GuideOrganizationDraftCreatedFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftCreatedFailed(
  props: GuideOrganizationDraftCreatedFailed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Created Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftDiscardCanceled' track call.
 *
 * @param GuideOrganizationDraftDiscardCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftDiscardCanceled(
  props: GuideOrganizationDraftDiscardCanceled,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Discard Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftDiscarded' track call.
 *
 * @param GuideOrganizationDraftDiscarded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftDiscarded(
  props: GuideOrganizationDraftDiscarded,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Discarded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftDiscardedFailed' track call.
 *
 * @param GuideOrganizationDraftDiscardedFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftDiscardedFailed(
  props: GuideOrganizationDraftDiscardedFailed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Discarded Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftPublishCanceled' track call.
 *
 * @param GuideOrganizationDraftPublishCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftPublishCanceled(
  props: GuideOrganizationDraftPublishCanceled,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Publish Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftPublished' track call.
 *
 * @param GuideOrganizationDraftPublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftPublished(
  props: GuideOrganizationDraftPublished,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Published',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationDraftPublishedFailed' track call.
 *
 * @param GuideOrganizationDraftPublishedFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationDraftPublishedFailed(
  props: GuideOrganizationDraftPublishedFailed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Draft Published Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationEditSectionButtonClicked' track call.
 *
 * @param GuideOrganizationEditSectionButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationEditSectionButtonClicked(
  props: GuideOrganizationEditSectionButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Edit Section Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationInventorySettingsButtonClicked' track call.
 *
 * @param GuideOrganizationInventorySettingsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationInventorySettingsButtonClicked(
  props: GuideOrganizationInventorySettingsButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Inventory Settings Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemOverridesCancelButtonClicked' track call.
 *
 * @param GuideOrganizationItemOverridesCancelButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemOverridesCancelButtonClicked(
  props: GuideOrganizationItemOverridesCancelButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Overrides Cancel Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemOverridesMoveToButtonClicked' track call.
 *
 * @param GuideOrganizationItemOverridesMoveToButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemOverridesMoveToButtonClicked(
  props: GuideOrganizationItemOverridesMoveToButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Overrides Move To Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemOverridesSaved' track call.
 *
 * @param GuideOrganizationItemOverridesSaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemOverridesSaved(
  props: GuideOrganizationItemOverridesSaved,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Overrides Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemRowClicked' track call.
 *
 * @param GuideOrganizationItemRowClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemRowClicked(
  props: GuideOrganizationItemRowClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Row Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemRowDeselected' track call.
 *
 * @param GuideOrganizationItemRowDeselected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemRowDeselected(
  props: GuideOrganizationItemRowDeselected,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Row Deselected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemRowSelected' track call.
 *
 * @param GuideOrganizationItemRowSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemRowSelected(
  props: GuideOrganizationItemRowSelected,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Item Row Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationItemsTableFiltered' track call.
 *
 * @param GuideOrganizationItemsTableFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationItemsTableFiltered(
  props: GuideOrganizationItemsTableFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Items Table Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationOrderingSettingsButtonClicked' track call.
 *
 * @param GuideOrganizationOrderingSettingsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationOrderingSettingsButtonClicked(
  props: GuideOrganizationOrderingSettingsButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Ordering Settings Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationReassignCategoriesAccordionClicked' track call.
 *
 * @param GuideOrganizationReassignCategoriesAccordionClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationReassignCategoriesAccordionClicked(
  props: GuideOrganizationReassignCategoriesAccordionClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Reassign Categories Accordion Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationReassignCategoriesNewSectionSelected' track call.
 *
 * @param GuideOrganizationReassignCategoriesNewSectionSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationReassignCategoriesNewSectionSelected(
  props: GuideOrganizationReassignCategoriesNewSectionSelected,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Reassign Categories New Section Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationReassignCategoriesSelected' track call.
 *
 * @param GuideOrganizationReassignCategoriesSelected props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationReassignCategoriesSelected(
  props: GuideOrganizationReassignCategoriesSelected,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Reassign Categories Selected',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalCancelButtonClicked' track call.
 *
 * @param GuideOrganizationSectionEditorModalCancelButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalCancelButtonClicked(
  props: GuideOrganizationSectionEditorModalCancelButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Cancel Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalChangesSavedFailed' track call.
 *
 * @param GuideOrganizationSectionEditorModalChangesSavedFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalChangesSavedFailed(
  props: GuideOrganizationSectionEditorModalChangesSavedFailed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Changes Saved Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalDeleteFailed' track call.
 *
 * @param GuideOrganizationSectionEditorModalDeleteFailed props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalDeleteFailed(
  props: GuideOrganizationSectionEditorModalDeleteFailed,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Delete Failed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalNextButtonClicked' track call.
 *
 * @param GuideOrganizationSectionEditorModalNextButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalNextButtonClicked(
  props: GuideOrganizationSectionEditorModalNextButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Next Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalParentCategoryValueChanged' track call.
 *
 * @param GuideOrganizationSectionEditorModalParentCategoryValueChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalParentCategoryValueChanged(
  props: GuideOrganizationSectionEditorModalParentCategoryValueChanged,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Parent Category Value Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked' track call.
 *
 * @param GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalSaveAllChangesButtonClicked(
  props: GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Save All Changes Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalSectionNameValueChanged' track call.
 *
 * @param GuideOrganizationSectionEditorModalSectionNameValueChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalSectionNameValueChanged(
  props: GuideOrganizationSectionEditorModalSectionNameValueChanged,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Section Name Value Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionEditorModalSortThisToValueChanged' track call.
 *
 * @param GuideOrganizationSectionEditorModalSortThisToValueChanged props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionEditorModalSortThisToValueChanged(
  props: GuideOrganizationSectionEditorModalSortThisToValueChanged,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Editor Modal Sort This To Value Changed',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionNameAndSettingsButtonClicked' track call.
 *
 * @param GuideOrganizationSectionNameAndSettingsButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionNameAndSettingsButtonClicked(
  props: GuideOrganizationSectionNameAndSettingsButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Section Name And Settings Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationSectionsTableFiltered' track call.
 *
 * @param GuideOrganizationSectionsTableFiltered props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationSectionsTableFiltered(
  props: GuideOrganizationSectionsTableFiltered,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Sections Table Filtered',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'GuideOrganizationTableRouterTabClicked' track call.
 *
 * @param GuideOrganizationTableRouterTabClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function guideOrganizationTableRouterTabClicked(
  props: GuideOrganizationTableRouterTabClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Guide Organization Table Router Tab Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InventoryApproveClicked' track call.
 *
 * @param InventoryApproveClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function inventoryApproveClicked(
  props: InventoryApproveClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Inventory Approve Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InventoryItemViewedDetails' track call.
 *
 * @param InventoryItemViewedDetails props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function inventoryItemViewedDetails(
  props: InventoryItemViewedDetails,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Inventory Item Viewed Details',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'InventoryItemsSearched' track call.
 *
 * @param InventoryItemsSearched props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function inventoryItemsSearched(
  props: InventoryItemsSearched,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Inventory Items Searched',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideDownloadButtonClicked' track call.
 *
 * @param OrderGuideDownloadButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideDownloadButtonClicked(
  props: OrderGuideDownloadButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Download Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideEditItemNameButtonClicked' track call.
 *
 * @param OrderGuideEditItemNameButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideEditItemNameButtonClicked(
  props: OrderGuideEditItemNameButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Edit Item Name Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideExcludeButtonClicked' track call.
 *
 * @param OrderGuideExcludeButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideExcludeButtonClicked(
  props: OrderGuideExcludeButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Exclude Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideIncludeButtonClicked' track call.
 *
 * @param OrderGuideIncludeButtonClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideIncludeButtonClicked(
  props: OrderGuideIncludeButtonClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Include Button Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideItemNameEdited' track call.
 *
 * @param OrderGuideItemNameEdited props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideItemNameEdited(
  props: OrderGuideItemNameEdited,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Item Name Edited',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideItemUpdateCanceled' track call.
 *
 * @param OrderGuideItemUpdateCanceled props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideItemUpdateCanceled(
  props: OrderGuideItemUpdateCanceled,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Item Update Canceled',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideItemsExclusionSuccessfullySaved' track call.
 *
 * @param OrderGuideItemsExclusionSuccessfullySaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideItemsExclusionSuccessfullySaved(
  props: OrderGuideItemsExclusionSuccessfullySaved,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Items Exclusion Successfully Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderGuideItemsInclusionSuccessfullySaved' track call.
 *
 * @param OrderGuideItemsInclusionSuccessfullySaved props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderGuideItemsInclusionSuccessfullySaved(
  props: OrderGuideItemsInclusionSuccessfullySaved,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order Guide Items Inclusion Successfully Saved',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'OrderAndDeliveriesDraftSchedulePublished' track call.
 *
 * @param OrderAndDeliveriesDraftSchedulePublished props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function orderAndDeliveriesDraftSchedulePublished(
  props: OrderAndDeliveriesDraftSchedulePublished,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Order and Deliveries Draft Schedule Published',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'PageInventoryResultsLoaded' track call.
 *
 * @param PageInventoryResultsLoaded props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function pageInventoryResultsLoaded(
  props: PageInventoryResultsLoaded,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Page Inventory Results Loaded',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}
/**
 * Fires a 'SecondarySideNavLinkClicked' track call.
 *
 * @param SecondarySideNavLinkClicked props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 	call is fired.
 */
export function secondarySideNavLinkClicked(
  props: SecondarySideNavLinkClicked,
  options?: Options,
  callback?: Callback,
): void {
  const a = analytics()
  if (a) {
    a.track(
      'Secondary Side Nav Link Clicked',
      props || {},
      {
        ...options,
        context: {
          ...(options?.context || {}),
          typewriter: {
            language: 'typescript',
            version: '',
          },
        },
      },
      callback,
    )
  }
}

const clientAPI = {
  /**
   * Updates the run-time configuration of this Typewriter client.
   *
   * @param {TypewriterOptions} options - the options to upsert
   *
   * @typedef {Object} TypewriterOptions
   * @property {AnyAnalytics} [analytics] - Underlying analytics instance where analytics
   * 		calls are forwarded on to. Defaults to window.analytics.
   * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
   * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
   * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
   * 		will be logged to stderr.
   */
  setTypewriterOptions,

  /**
   * Fires a 'BackToInventoryResultsScreen' track call.
   *
   * @param BackToInventoryResultsScreen props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  backToInventoryResultsScreen,
  /**
   * Fires a 'EndingInventoryClosed' track call.
   *
   * @param EndingInventoryClosed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  endingInventoryClosed,
  /**
   * Fires a 'EndingInventoryOpened' track call.
   *
   * @param EndingInventoryOpened props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  endingInventoryOpened,
  /**
   * Fires a 'GuideOrganizationAssignCategoriesAccordionClicked' track call.
   *
   * @param GuideOrganizationAssignCategoriesAccordionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationAssignCategoriesAccordionClicked,
  /**
   * Fires a 'GuideOrganizationAssignCategoriesButtonClicked' track call.
   *
   * @param GuideOrganizationAssignCategoriesButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationAssignCategoriesButtonClicked,
  /**
   * Fires a 'GuideOrganizationAssignCategoriesSelected' track call.
   *
   * @param GuideOrganizationAssignCategoriesSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationAssignCategoriesSelected,
  /**
   * Fires a 'GuideOrganizationCreateSectionButtonClicked' track call.
   *
   * @param GuideOrganizationCreateSectionButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationCreateSectionButtonClicked,
  /**
   * Fires a 'GuideOrganizationDraftCreated' track call.
   *
   * @param GuideOrganizationDraftCreated props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftCreated,
  /**
   * Fires a 'GuideOrganizationDraftCreatedFailed' track call.
   *
   * @param GuideOrganizationDraftCreatedFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftCreatedFailed,
  /**
   * Fires a 'GuideOrganizationDraftDiscardCanceled' track call.
   *
   * @param GuideOrganizationDraftDiscardCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftDiscardCanceled,
  /**
   * Fires a 'GuideOrganizationDraftDiscarded' track call.
   *
   * @param GuideOrganizationDraftDiscarded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftDiscarded,
  /**
   * Fires a 'GuideOrganizationDraftDiscardedFailed' track call.
   *
   * @param GuideOrganizationDraftDiscardedFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftDiscardedFailed,
  /**
   * Fires a 'GuideOrganizationDraftPublishCanceled' track call.
   *
   * @param GuideOrganizationDraftPublishCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftPublishCanceled,
  /**
   * Fires a 'GuideOrganizationDraftPublished' track call.
   *
   * @param GuideOrganizationDraftPublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftPublished,
  /**
   * Fires a 'GuideOrganizationDraftPublishedFailed' track call.
   *
   * @param GuideOrganizationDraftPublishedFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationDraftPublishedFailed,
  /**
   * Fires a 'GuideOrganizationEditSectionButtonClicked' track call.
   *
   * @param GuideOrganizationEditSectionButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationEditSectionButtonClicked,
  /**
   * Fires a 'GuideOrganizationInventorySettingsButtonClicked' track call.
   *
   * @param GuideOrganizationInventorySettingsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationInventorySettingsButtonClicked,
  /**
   * Fires a 'GuideOrganizationItemOverridesCancelButtonClicked' track call.
   *
   * @param GuideOrganizationItemOverridesCancelButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemOverridesCancelButtonClicked,
  /**
   * Fires a 'GuideOrganizationItemOverridesMoveToButtonClicked' track call.
   *
   * @param GuideOrganizationItemOverridesMoveToButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemOverridesMoveToButtonClicked,
  /**
   * Fires a 'GuideOrganizationItemOverridesSaved' track call.
   *
   * @param GuideOrganizationItemOverridesSaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemOverridesSaved,
  /**
   * Fires a 'GuideOrganizationItemRowClicked' track call.
   *
   * @param GuideOrganizationItemRowClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemRowClicked,
  /**
   * Fires a 'GuideOrganizationItemRowDeselected' track call.
   *
   * @param GuideOrganizationItemRowDeselected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemRowDeselected,
  /**
   * Fires a 'GuideOrganizationItemRowSelected' track call.
   *
   * @param GuideOrganizationItemRowSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemRowSelected,
  /**
   * Fires a 'GuideOrganizationItemsTableFiltered' track call.
   *
   * @param GuideOrganizationItemsTableFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationItemsTableFiltered,
  /**
   * Fires a 'GuideOrganizationOrderingSettingsButtonClicked' track call.
   *
   * @param GuideOrganizationOrderingSettingsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationOrderingSettingsButtonClicked,
  /**
   * Fires a 'GuideOrganizationReassignCategoriesAccordionClicked' track call.
   *
   * @param GuideOrganizationReassignCategoriesAccordionClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationReassignCategoriesAccordionClicked,
  /**
   * Fires a 'GuideOrganizationReassignCategoriesNewSectionSelected' track call.
   *
   * @param GuideOrganizationReassignCategoriesNewSectionSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationReassignCategoriesNewSectionSelected,
  /**
   * Fires a 'GuideOrganizationReassignCategoriesSelected' track call.
   *
   * @param GuideOrganizationReassignCategoriesSelected props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationReassignCategoriesSelected,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalCancelButtonClicked' track call.
   *
   * @param GuideOrganizationSectionEditorModalCancelButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalCancelButtonClicked,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalChangesSavedFailed' track call.
   *
   * @param GuideOrganizationSectionEditorModalChangesSavedFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalChangesSavedFailed,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalDeleteFailed' track call.
   *
   * @param GuideOrganizationSectionEditorModalDeleteFailed props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalDeleteFailed,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalNextButtonClicked' track call.
   *
   * @param GuideOrganizationSectionEditorModalNextButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalNextButtonClicked,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalParentCategoryValueChanged' track call.
   *
   * @param GuideOrganizationSectionEditorModalParentCategoryValueChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalParentCategoryValueChanged,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked' track call.
   *
   * @param GuideOrganizationSectionEditorModalSaveAllChangesButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalSaveAllChangesButtonClicked,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalSectionNameValueChanged' track call.
   *
   * @param GuideOrganizationSectionEditorModalSectionNameValueChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalSectionNameValueChanged,
  /**
   * Fires a 'GuideOrganizationSectionEditorModalSortThisToValueChanged' track call.
   *
   * @param GuideOrganizationSectionEditorModalSortThisToValueChanged props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionEditorModalSortThisToValueChanged,
  /**
   * Fires a 'GuideOrganizationSectionNameAndSettingsButtonClicked' track call.
   *
   * @param GuideOrganizationSectionNameAndSettingsButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionNameAndSettingsButtonClicked,
  /**
   * Fires a 'GuideOrganizationSectionsTableFiltered' track call.
   *
   * @param GuideOrganizationSectionsTableFiltered props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationSectionsTableFiltered,
  /**
   * Fires a 'GuideOrganizationTableRouterTabClicked' track call.
   *
   * @param GuideOrganizationTableRouterTabClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  guideOrganizationTableRouterTabClicked,
  /**
   * Fires a 'InventoryApproveClicked' track call.
   *
   * @param InventoryApproveClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  inventoryApproveClicked,
  /**
   * Fires a 'InventoryItemViewedDetails' track call.
   *
   * @param InventoryItemViewedDetails props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  inventoryItemViewedDetails,
  /**
   * Fires a 'InventoryItemsSearched' track call.
   *
   * @param InventoryItemsSearched props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  inventoryItemsSearched,
  /**
   * Fires a 'OrderGuideDownloadButtonClicked' track call.
   *
   * @param OrderGuideDownloadButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideDownloadButtonClicked,
  /**
   * Fires a 'OrderGuideEditItemNameButtonClicked' track call.
   *
   * @param OrderGuideEditItemNameButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideEditItemNameButtonClicked,
  /**
   * Fires a 'OrderGuideExcludeButtonClicked' track call.
   *
   * @param OrderGuideExcludeButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideExcludeButtonClicked,
  /**
   * Fires a 'OrderGuideIncludeButtonClicked' track call.
   *
   * @param OrderGuideIncludeButtonClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideIncludeButtonClicked,
  /**
   * Fires a 'OrderGuideItemNameEdited' track call.
   *
   * @param OrderGuideItemNameEdited props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideItemNameEdited,
  /**
   * Fires a 'OrderGuideItemUpdateCanceled' track call.
   *
   * @param OrderGuideItemUpdateCanceled props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideItemUpdateCanceled,
  /**
   * Fires a 'OrderGuideItemsExclusionSuccessfullySaved' track call.
   *
   * @param OrderGuideItemsExclusionSuccessfullySaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideItemsExclusionSuccessfullySaved,
  /**
   * Fires a 'OrderGuideItemsInclusionSuccessfullySaved' track call.
   *
   * @param OrderGuideItemsInclusionSuccessfullySaved props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderGuideItemsInclusionSuccessfullySaved,
  /**
   * Fires a 'OrderAndDeliveriesDraftSchedulePublished' track call.
   *
   * @param OrderAndDeliveriesDraftSchedulePublished props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  orderAndDeliveriesDraftSchedulePublished,
  /**
   * Fires a 'PageInventoryResultsLoaded' track call.
   *
   * @param PageInventoryResultsLoaded props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  pageInventoryResultsLoaded,
  /**
   * Fires a 'SecondarySideNavLinkClicked' track call.
   *
   * @param SecondarySideNavLinkClicked props - The analytics properties that will be sent to Segment.
   * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
   * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
   * 	call is fired.
   */
  secondarySideNavLinkClicked,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
  get(target, method) {
    if (typeof method === 'string' && target.hasOwnProperty(method)) {
      return target[method as keyof typeof clientAPI]
    }

    return () => {
      console.warn(`⚠️  You made an analytics call (${String(
        method,
      )}) that can't be found. Either:
         a) Re-generate your typewriter client: \`npx typewriter\`
         b) Add it to your Tracking Plan: https://app.segment.com/segment-oscb/protocols/tracking-plans/rs_1zTHJU9fd5mt7cndWnd4PgJbMCE`)
      const a = analytics()
      if (a) {
        a.track(
          'Unknown Analytics Call Fired',
          {
            method,
          },
          withTypewriterContext(),
        )
      }
    }
  },
})
