export enum ModalState {
  add = 'Add',
  edit = 'Edit',
  delete = 'Delete',
}

export interface AddConfigAdSchedulesArgs {
  businessLevel: string
  businessLevelId: string | null
  intervalWeeks: number
  startDate: string
  workflowId: string
}

export interface EditConfigAdSchedulesArgs extends AddConfigAdSchedulesArgs {
  id: string
}
