/**
 * This hook sets document.title, and resets to our default string on component unmount
 *  we should only be directly modifying document.title from this hook
 */
import { useEffect } from 'react'

export default function useSetDocumentTitle(title: string) {
  useEffect(() => {
    document.title = title

    return () => {
      document.title = 'Afresh'
    }
  })
}
