import { useSearchParams } from 'react-router-dom'
import tuple from 'shared/tuple'

// yanked from https://blog.logrocket.com/use-state-url-persist-state-usesearchparams/
export function useSearchParamsState(
  searchParamName: string,
  defaultValue: string = '',
) {
  const [searchParams, setSearchParams] = useSearchParams()

  const acquiredSearchParam = searchParams.get(searchParamName)
  const searchParamsState = acquiredSearchParam ?? defaultValue

  const setSearchParamsState = (newState: string) => {
    if (newState === searchParams.get(searchParamName)) {
      return
    }

    // eslint-disable-next-line prefer-object-spread
    const next = Object.assign(
      {},
      [...searchParams.entries()].reduce(
        (o, [key, value]) => ({ ...o, [key]: value }),
        {},
      ),
      { [searchParamName]: newState },
    )

    if (!newState) {
      delete next[searchParamName]
    }

    setSearchParams(next)
  }

  return tuple([searchParamsState, setSearchParamsState])
}
