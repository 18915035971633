import { StoreClosureType } from '@api/__gen__/gql'
import { DynamicVirtualizedRowProps } from 'app/components/VirtualizedList/Dynamic'

export enum IssueType {
  IllogicalDeliverySequence = 'IllogicalDeliverySequence',
  OrderDeliveryEventOverlapsWithStoreClosure = 'OrderDeliveryEventOverlapsWithStoreClosure',
  OverlappingOrderDeliveryEvents = 'OverlappingOrderDeliveryEvents',
}

export interface Store {
  id: string
  name: string
}

export interface StoreWithIssues extends Store {
  totalOccurrences: number
}

export interface Dimensions {
  [key: string]: number
}

export interface Order {
  id: string
  order: {
    date: string
    isEditable: boolean
    issueTypes: IssueType[]
  }
  delivery: {
    date: string
    isEditable: boolean
    issueTypes: IssueType[]
  }
  applied: boolean
}

export interface CalendarDay {
  date: string
  isClosed: boolean
}

export interface StoreSchedule {
  storeScheduleId: string
  store: Store
  schedule: CalendarDay[]
  orders: Order[]
}

export interface StoreScheduleRow
  extends StoreSchedule,
    DynamicVirtualizedRowProps {}

export interface StoreClosure {
  id: string
  closureDate: string
  closureType: StoreClosureType
  isEditable: boolean
  itemHeight: number
  stores: Store[] | null
}

export type DayOfWeek =
  | 'SUNDAY'
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY'

export const DayOfWeekValues: { [name in DayOfWeek]: string } = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
}

export interface RecurringOrder {
  id: string
  orderDay: DayOfWeek
  deliveryDay: DayOfWeek
  deliveryLag: number
}

export interface StoreRecurringSchedule {
  storeScheduleId: string
  store: Store
  recurringOrders: RecurringOrder[]
}

export interface StoreRecurringScheduleRow
  extends StoreRecurringSchedule,
    DynamicVirtualizedRowProps {}

export interface BulkEditingOptions {
  originalStore: Store
  matchingStores: Store[]
}

export interface OrderDeliverySchedulesIssuesByStore {
  [storeId: string]: StoreWithIssues
}

interface OrderDeliverySchedulesIssuesByDate {
  [date: string]: OrderDeliverySchedulesIssuesByStore
}

interface OrderDeliverySchedulesIssuesWithWorkflow {
  id: string
  name: string
  storeIssuesByDate: OrderDeliverySchedulesIssuesByDate
}

export interface OrderDeliverySchedulesIssuesByWorkflow {
  [workflowId: string]: OrderDeliverySchedulesIssuesWithWorkflow
}

export interface OrderDeliverySchedulesEventIssues {
  deliveryIssues: IssueType[]
  orderIssues: IssueType[]
}

export interface OrderDeliverySchedulesIssuesByEvents {
  [orderDeliveryEventId: string]: OrderDeliverySchedulesEventIssues
}

export interface OrderDeliverySchedulesIssuesByEventsAndWorkflows {
  byEvents: OrderDeliverySchedulesIssuesByEvents
  byWorkflows: OrderDeliverySchedulesIssuesByWorkflow
}

export interface OrderDeliverySchedulesIssues {
  hasBeenEdited: boolean
  issuesByEvents: OrderDeliverySchedulesIssuesByEvents
  issuesByWorkflows: OrderDeliverySchedulesIssuesByWorkflow
  totalCount: number
}
