declare global {
  interface Window {
    Beacon: (action: 'open' | 'close' | 'toggle') => void
  }
}

export interface Option {
  disabled?: boolean
  label: string
  value: string
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>

export enum DayOfWeek {
  SUNDAY = '0',
  // Some DWH tables use MONDAY: 0
  MONDAY = '1',
  TUESDAY = '2',
  WEDNESDAY = '3',
  THURSDAY = '4',
  FRIDAY = '5',
  SATURDAY = '6',
}
