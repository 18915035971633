import { CustomerConfigAdSchedulesQuery } from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'
import { CONFIG_AD_SCHEDULE_ROW_FIELDS } from 'app/packages/internal/customerConfigs/adSchedules/api/fragments/ConfigAdScheduleRowFields'
import { compact } from 'lodash-es'

export const GET_CONFIG_AD_SCHEDULES = gql`
  ${CONFIG_AD_SCHEDULE_ROW_FIELDS}
  query CustomerConfigAdSchedules($customerId: ID!, $offset: Int, $limit: Int) {
    customerConfig(customerId: $customerId) {
      adSchedules(offset: $offset, limit: $limit) {
        edges {
          node {
            ...ConfigAdSchedulesRowFields
          }
        }
        pageInfo {
          totalCount
        }
      }
    }
  }
`

export function useGetAdSchedules({
  customerId,
}: {
  customerId: CustomerId | undefined
}) {
  const { data, loading, refetch } = useQuery<CustomerConfigAdSchedulesQuery>(
    GET_CONFIG_AD_SCHEDULES,
    {
      variables: { customerId },
    },
  )
  if (
    data &&
    data.customerConfig &&
    data.customerConfig?.adSchedules &&
    data.customerConfig.adSchedules?.edges
  ) {
    return {
      rows: compact(
        data.customerConfig.adSchedules.edges.map((edge) => edge?.node),
      ),
      pageInfo: data.customerConfig.adSchedules.pageInfo,
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    pageInfo: undefined,
    loading,
    refetch,
  }
}
