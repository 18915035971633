export const msgByErrorCode: { [code: string]: string } = {
  GUIDE_STRUCTURE_ARCHIVED: 'Guide organization has already been discarded.',
  GUIDE_STRUCTURE_CATEGORY_EXISTS: 'Section name already exists.',
  GUIDE_STRUCTURE_EXISTING_USER_DRAFT:
    'A guide organization draft already exists for this user. Discard changes and try again.',
  GUIDE_STRUCTURE_NO_PUBLISHED_VERSION:
    'Unable to edit guide organization with no published version.',
  GUIDE_STRUCTURE_PUBLISHED: 'Guide organization has already been published.',
  GUIDE_STRUCTURE_PUBLISHING_CONFLICT:
    'Another user has already published this current guide.',
  GUIDE_STRUCTURE_REPUBLISH_PUBLISHED:
    'Unable to publish a guide structure that has already been published.',
  INTERNAL_SERVER_ERROR:
    'Unable to process your request due to a server error.',
  RESOURCE_NOT_FOUND: 'Guide organization does not exist.',
}
