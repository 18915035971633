import { StoreClosureType } from '@api/__gen__/gql'
import { DoorBackOutlined } from '@mui/icons-material'
import { TextField, Tooltip } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import RadioButtons from 'app/components/RadioButtons/RadioButtons'
import { isNilOrEmpty } from 'app/helpers'
import { getISODateString } from 'app/helpers/date'
import useCreateStoreClosureEvent from 'app/packages/storeSolutions/schedules/api/mutations/useCreateStoreClosureEvent'
import useUpdateStoreClosureEvent from 'app/packages/storeSolutions/schedules/api/mutations/useUpdateStoreClosureEvent'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import {
  Store,
  StoreClosure,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { toRem } from 'app/styles'
import dayjs from 'dayjs'
import { isEqual } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const RADIO_BUTTONS = [
  { label: 'Holiday', value: 'HOLIDAY' },
  { label: 'One-off', value: 'ANOMALOUS' },
]

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${toRem(32)};
  row-gap: 8px;
`

const StyledDatePickerTextField = styled(TextField)`
  width: 250px;
`

const StyledBackDoorIcon = styled(DoorBackOutlined)<{ $isDisabled: boolean }>`
  color: ${({ $isDisabled, theme }) =>
    $isDisabled ? theme.colors.neutral_05 : 'inherit'};
  margin-top: 4px;
  width: 40px;
`

const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
`

export interface StoreClosureModalProps {
  closures: StoreClosure[]
  closureToEdit?: StoreClosure
  onClose: () => void
  stores: Store[]
}

export default function StoreClosureModal({
  closures,
  closureToEdit,
  onClose,
  stores,
}: StoreClosureModalProps) {
  const [
    createStoreClosure,
    { loading: createLoading },
  ] = useCreateStoreClosureEvent()
  const [
    updateStoreClosure,
    { loading: updateLoading },
  ] = useUpdateStoreClosureEvent()
  const { scheduleId } = useGetOrderDeliverySchedule()

  const [closureDate, setClosureDate] = useState<dayjs.Dayjs | null>(
    closureToEdit ? dayjs(closureToEdit.closureDate) : null,
  )
  const [closureType, setClosureType] = useState<StoreClosureType>(
    closureToEdit?.closureType ?? 'HOLIDAY',
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  // For an existing closure event, if stores is null, that means it is chainwide and
  // we should use all stores.
  const [selectedStores, setSelectedStores] = useState<string[] | null>(
    closureToEdit ? (closureToEdit.stores ?? stores).map((s) => s.id) : null,
  )

  const currentClosureDates = useMemo(() => {
    return closures.map((d) => d.closureDate)
  }, [closures])

  const handleClosureDateChange = (newClosureDate: dayjs.Dayjs | null) => {
    setClosureDate(newClosureDate)
  }

  const handleClosureTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target
    setClosureType(value as StoreClosureType)
  }

  const onDone = async () => {
    const isChainwide = isEqual(selectedStores?.length, stores.length)

    try {
      if (closureToEdit) {
        await updateStoreClosure({
          variables: {
            closureType: closureType!,
            date: closureDate!.format('YYYY-MM-DD'),
            id: closureToEdit.id,
            storeIds: isChainwide ? null : selectedStores,
          },
        })
      } else {
        await createStoreClosure({
          variables: {
            closureType: closureType!,
            date: closureDate!.format('YYYY-MM-DD'),
            scheduleId: scheduleId!,
            storeIds: isChainwide ? null : selectedStores,
          },
        })
      }

      onClose()
    } catch (e) {
      if (e instanceof Error) {
        setErrorMessage(e.message)
      }
    }
  }

  const renderDayPicker = useCallback(
    (
      date: dayjs.Dayjs,
      _: (dayjs.Dayjs | null)[],
      pickersDayProps: PickersDayProps<dayjs.Dayjs>,
    ) => {
      const formattedDate = date.format('YYYY-MM-DD')
      const isStoreClosure = currentClosureDates.includes(
        getISODateString(formattedDate),
      )

      if (!isStoreClosure) {
        return <PickersDay {...pickersDayProps} />
      } else {
        return (
          <Tooltip
            arrow
            enterDelay={400}
            enterNextDelay={400}
            key={formattedDate}
            placement="top"
            title={`${formattedDate} is an existing store closure`}>
            <StyledBackDoorIcon
              $isDisabled={pickersDayProps.disabled ?? false}
            />
          </Tooltip>
        )
      }
    },
    [currentClosureDates],
  )

  const isDisabled =
    !closureDate || !closureType || isNilOrEmpty(selectedStores)

  const isSaving = createLoading || updateLoading

  return (
    <Dialog disabled={isSaving} onClose={onClose} isOpen>
      <Header disabled={isSaving} onClose={onClose}>
        <Title>
          {closureToEdit ? 'Edit Store Closure' : 'Add Store Closure'}
        </Title>
      </Header>
      <Body>
        <strong>Note</strong>: Store closures apply to every delivery schedule
        for the selected stores.
        <StyledContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              inputFormat="YYYY-MM-DD"
              minDate={dayjs().add(1, 'days')}
              onChange={handleClosureDateChange}
              renderDay={renderDayPicker}
              renderInput={(params) => (
                <StyledDatePickerTextField {...params} label="Closure Date" />
              )}
              value={closureDate}
            />
          </LocalizationProvider>
          <MultiSelect
            inputLabel="Stores"
            options={stores}
            onFilterOptions={setSelectedStores}
            selectedOptions={selectedStores ?? []}
          />
          <RadioButtons
            buttons={RADIO_BUTTONS}
            value={closureType}
            onChange={handleClosureTypeChange}
          />
          {errorMessage && (
            <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
          )}
        </StyledContent>
      </Body>
      <Footer>
        <FooterActions>
          <Button disabled={isSaving} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button disabled={isDisabled} loading={isSaving} onClick={onDone}>
            {closureToEdit ? 'Done' : 'Add'}
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
