import { Box } from '@mui/material'
import React from 'react'

interface Props {
  children: React.ReactNode
}

export default function CardTitle(props: Props) {
  return (
    <Box fontSize={32} fontWeight="fontWeightBold" mb={2}>
      {props.children}
    </Box>
  )
}
