import Button from 'app/components/Button/Button'
import Close from 'app/components/Icons/Close'
import React from 'react'
import styled from 'styled-components'

interface Props {
  ariaLabel?: string
  className?: string
  onClose: () => void
  disabled?: boolean
}

const StyledCloseButton = styled(Button)`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.neutral_01};
  }
`
export function CloseDialogButton({
  ariaLabel = 'Close dialog',
  className = '',
  disabled = false,
  onClose,
}: Props) {
  return (
    <StyledCloseButton
      variant="icon"
      aria-label={ariaLabel}
      className={className}
      disabled={disabled}
      onClick={onClose}
      size="large">
      <Close />
    </StyledCloseButton>
  )
}
