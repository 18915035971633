import { useEffect } from 'react'

export const useEventListener = (target, type, fn, ...options) => {
  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    const targetIsRef = target.hasOwnProperty('current')
    const currentTarget = targetIsRef ? target.current : target
    if (currentTarget) {
      currentTarget.addEventListener(type, fn, ...options)
    }

    return () => {
      if (currentTarget) currentTarget.removeEventListener(type, fn, ...options)
    }
  }, [target, type, fn, options])
}

export function dispatchEvent(type, detail, target = window.document) {
  const event = new CustomEvent(type, { detail })
  target.dispatchEvent(event)
}
