import { MIN_HEADER_HEIGHT } from 'app/constants'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

const StyledCardContainer = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  padding: ${toRem(24)};
`

export interface CardContainerProps {
  children: React.ReactNode
  height?: string
}

export default function CardContainer({
  children,
  height = `calc(100vh - ${MIN_HEADER_HEIGHT}px)`,
}: CardContainerProps) {
  return <StyledCardContainer height={height}>{children}</StyledCardContainer>
}
