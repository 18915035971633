import {
  GetGuideStructureParentCategoriesQuery,
  GetGuideStructureParentCategoriesQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { isNil } from 'lodash-es'

const PARENT_CATEGORY_LEVEL = 1

export const GET_GUIDE_STRUCTURE_PARENT_CATEGORIES = gql`
  query getGuideStructureParentCategories($guideStructureId: ID!, $level: Int) {
    node(id: $guideStructureId) {
      ... on GuideStructure {
        _id
        categories(level: $level) {
          edges {
            node {
              _id
              itemCategory {
                _id
                name
              }
            }
          }
        }
      }
    }
  }
`

export default function useGetParentCategories() {
  const { properties } = useGetProperties()
  const guideStructureId = properties?._id

  const shouldSkip = isNil(guideStructureId)
  const { data, error, loading } = useQuery<
    GetGuideStructureParentCategoriesQuery,
    GetGuideStructureParentCategoriesQueryVariables
  >(GET_GUIDE_STRUCTURE_PARENT_CATEGORIES, {
    onError: (err) => {
      Sentry.captureMessage(
        `Failed to fetch guide parent categories for ${guideStructureId}: ${err}`,
        {
          level: 'warning',
        },
      )
    },
    skip: shouldSkip,
    variables: {
      guideStructureId: guideStructureId!,
      level: PARENT_CATEGORY_LEVEL,
    },
  })

  return {
    error,
    loading: shouldSkip || loading,
    parentCategories:
      data?.node?.__typename === 'GuideStructure'
        ? data.node.categories.edges
        : [],
  }
}
