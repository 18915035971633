import CautionSystemStatusFilled from 'app/components/Icons/CautionSystemStatusFilled'
import RecurringOrderDeliveryEvent from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/RecurringOrderDeliveryEvent/RecurringOrderDeliveryEvent'
import { RecurringOrder } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React from 'react'
import styled from 'styled-components'

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const OrdersCell = styled.div`
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  padding: 16px 0;
`

const EmptySchedule = styled(Flex)`
  height: 100%;
  justify-content: center;
`

const StyledWarning = styled(CautionSystemStatusFilled)`
  width: 16px;
  margin-right: 4px;
`

export interface RecurringSchedulesTableCellProps {
  recurringOrders: RecurringOrder[]
  storeName: string
}

export function RecurringSchedulesTableCell({
  recurringOrders,
  storeName,
}: RecurringSchedulesTableCellProps) {
  return (
    <OrdersCell>
      {recurringOrders.length ? (
        recurringOrders.map((order) => {
          return (
            <RecurringOrderDeliveryEvent
              key={order.id}
              order={order}
              recurringOrders={recurringOrders}
              storeName={storeName}
            />
          )
        })
      ) : (
        <EmptySchedule>
          <StyledWarning />
          No recurring schedule
        </EmptySchedule>
      )}
    </OrdersCell>
  )
}
