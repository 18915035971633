import {
  GetProductBundlesQuery,
  GetProductBundlesQueryVariables,
  ProductBundle as GqlProductBundle,
  UserCapabilities,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'

import { USER_ACCESS_PERMISSIONS } from 'app/constants'
import { getFlagValue, useFeatureFlags } from 'app/context/rolloutContext'
import { useCustomers } from 'app/CustomerContext'
import {
  PRODUCT,
  PRODUCT_BUNDLE_TYPE,
  productNameMap,
  productRouteMap,
} from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import { getActiveProductBundle } from 'app/layouts/Customer/CustomerPageLayout.helpers'
import { toTitleCase } from 'app/utils'
import { memoize } from 'lodash-es'
import { useLocation } from 'react-router-dom'

export const GET_PRODUCT_BUNDLES = gql`
  query getProductBundles($customerId: ID) {
    viewer {
      productBundles(customerId: $customerId) {
        _id
        type
        departmentBundles {
          _id
          name
          products {
            type
            workflows {
              _id
              name
            }
          }
        }
        primaryBusinessLevel {
          level
          displayName
        }
        businessLevelInstances {
          _id
          id
          name
        }
      }
    }
  }
`

export interface Workflow {
  id: string
  name: string
}

export interface Product {
  type: PRODUCT
  name: string
  routes: string[]
  workflows?: Workflow[]
}

export interface DepartmentBundle {
  id: string
  name: string
  products: Product[]
}

export interface BusinessLevelInstance {
  label: string
  value: string
}

interface ProductBundleConfig {
  type: PRODUCT_BUNDLE_TYPE
  name: string
  products?: Product[]
  showSecondaryNav: boolean
}

export type ProductBundle = {
  id: string
  primaryBusinessLevel?: {
    level: string
    displayName: string
  }
  businessLevelInstances?: BusinessLevelInstance[]
  departmentBundles?: DepartmentBundle[]
} & ProductBundleConfig

interface ProductBundles {
  productBundles?: ProductBundle[]
  activeProductBundle?: ProductBundle
  loading: boolean
}

export const PRODUCT_BUNDLE_CONFIG_MAP: {
  [key in PRODUCT_BUNDLE_TYPE]: ProductBundleConfig
} = {
  [PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER]: {
    type: PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER,
    name: 'Distribution Center',
    products: [
      {
        type: PRODUCT.DISTRIBUTION_CENTER_INSIGHTS,
        name: productNameMap[PRODUCT.DISTRIBUTION_CENTER_INSIGHTS],
        routes: productRouteMap[PRODUCT.DISTRIBUTION_CENTER_INSIGHTS],
      },
    ],
    showSecondaryNav: true,
  },
  [PRODUCT_BUNDLE_TYPE.INVENTORY_RESULTS]: {
    type: PRODUCT_BUNDLE_TYPE.INVENTORY_RESULTS,
    name: 'Inventory Results',
    products: [
      {
        type: PRODUCT.INVENTORY_RESULTS,
        name: productNameMap[PRODUCT.INVENTORY_RESULTS],
        routes: productRouteMap[PRODUCT.INVENTORY_RESULTS],
      },
    ],
    showSecondaryNav: false,
  },
  [PRODUCT_BUNDLE_TYPE.OPS_INSIGHTS]: {
    type: PRODUCT_BUNDLE_TYPE.OPS_INSIGHTS,
    name: 'Insights',
    products: [
      {
        type: PRODUCT.OPS_INSIGHTS,
        name: productNameMap[PRODUCT.OPS_INSIGHTS],
        routes: productRouteMap[PRODUCT.OPS_INSIGHTS],
      },
    ],
    showSecondaryNav: false,
  },
  [PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS]: {
    type: PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS,
    name: 'Store Operations',
    showSecondaryNav: true,
  },
}

function getOtherProductBundles({
  permissions,
  isEndingInventoryActiveConfig,
}: {
  permissions?: UserCapabilities[]
  isEndingInventoryActiveConfig: boolean
}) {
  // Note 1/8/2024: work is in progress to deprecate UserCapabilities and
  // populate the frontend list of product bundles purely from the backend response;
  // more details here: https://www.notion.so/afresh/Q4-2023-roles-perms-tech-debt-proposal-cf8f684ade7f43a9851b78811337afa2?pvs=4#db48e65c01674505bd62480818290f1d
  // Please coordinate with @mhiley if you need to add a new product bundle in the meantime!

  const bundles = []

  if (
    permissions?.length &&
    isEndingInventoryActiveConfig &&
    permissions?.includes(USER_ACCESS_PERMISSIONS.INVENTORY_VALIDATION)
  ) {
    bundles.push({
      id: 'inventory-results',
      ...PRODUCT_BUNDLE_CONFIG_MAP[PRODUCT_BUNDLE_TYPE.INVENTORY_RESULTS],
    })
  }

  if (
    (permissions?.length &&
      permissions?.includes(USER_ACCESS_PERMISSIONS.DC_INSIGHTS_MANAGE)) ||
    permissions?.includes(USER_ACCESS_PERMISSIONS.DC_REPLENISHMENT_MANAGE)
  ) {
    bundles.push({
      id: 'distribution-center',
      ...PRODUCT_BUNDLE_CONFIG_MAP[PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER],
    })
  }

  if (
    permissions?.length &&
    permissions?.includes(USER_ACCESS_PERMISSIONS.OPS_INSIGHTS)
  ) {
    bundles.push({
      id: 'ops-insights',
      ...PRODUCT_BUNDLE_CONFIG_MAP[PRODUCT_BUNDLE_TYPE.OPS_INSIGHTS],
    })
  }
  return bundles
}

function constructProductBundles({
  productBundles,
  permissions,
  isEndingInventoryActiveConfig,
}: {
  productBundles?: GqlProductBundle[]
  permissions?: UserCapabilities[]
  isEndingInventoryActiveConfig: boolean
}): ProductBundle[] {
  const resultBundles: ProductBundle[] = []
  // Store Operations Product Bundle, currently the only server driven bundle
  productBundles?.forEach((productBundle) => {
    if (productBundle.type === PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS) {
      resultBundles.push({
        id: productBundle._id,
        ...PRODUCT_BUNDLE_CONFIG_MAP[PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS],
        primaryBusinessLevel: productBundle.primaryBusinessLevel,
        businessLevelInstances: productBundle.businessLevelInstances.map(
          ({ _id, id, name }) => ({
            value: _id,
            // show the DB id if the business level instance doesn't have a name
            label: name ? `${toTitleCase(name)} (${id})` : id,
          }),
        ),
        departmentBundles: productBundle?.departmentBundles.map(
          ({ _id: departmentBundleId, name, products }) => ({
            id: departmentBundleId,
            name,
            products: products.map(({ type: productType, workflows }) => ({
              type: productType as PRODUCT,
              name: productNameMap[productType],
              routes: productRouteMap[productType],
              workflows: workflows?.map(
                ({ _id: workflowId, name: workflowName }) => ({
                  id: workflowId,
                  name: workflowName,
                }),
              ),
            })),
          }),
        ),
      })
    }
  })

  const otherProductBundles = getOtherProductBundles({
    permissions,
    isEndingInventoryActiveConfig,
  })

  return [...resultBundles, ...otherProductBundles]
}

// This memoize is so that consumers don't rerender unncessarily, the payload should only change when product bundles change (which should be rare)
const constructProductBundlesMemoized = memoize(
  constructProductBundles,
  (args) => JSON.stringify(args), // Not the most performant, but this shouldn't happen often - only when switching customers
)

export default function useGetProductBundles(): ProductBundles {
  const { activeCustomerId } = useCustomers()
  const { permissions } = useGetCustomerPermissions()
  const location = useLocation()
  const { data, loading } = useQuery<
    GetProductBundlesQuery,
    GetProductBundlesQueryVariables
  >(GET_PRODUCT_BUNDLES, {
    skip: activeCustomerId === undefined,
    variables: { customerId: activeCustomerId },
  })

  const { isEndingInventoryActiveConfig } = useFeatureFlags()
  if (data && data.viewer && data.viewer.__typename === 'Viewer') {
    const { productBundles } = data.viewer

    const memoizedResultBundles = constructProductBundlesMemoized({
      // @ts-ignore - For some reason the workflow type is throwing an error
      productBundles,
      permissions: permissions || [],
      isEndingInventoryActiveConfig: getFlagValue(
        isEndingInventoryActiveConfig,
      ),
    })

    return {
      productBundles: memoizedResultBundles,
      activeProductBundle: getActiveProductBundle(
        memoizedResultBundles,
        location.pathname,
      ),
      loading,
    }
  }

  return {
    productBundles: undefined,
    loading,
  }
}
