import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5802 4.79004H12.6702C12.7702 4.93004 12.8402 5.09004 12.8402 5.28004C12.8402 5.76004 12.4502 6.15004 11.9702 6.15004C11.4902 6.15004 11.1002 5.76004 11.1002 5.28004C11.1002 5.10004 11.1702 4.93004 11.2702 4.79004H6.41023C5.62023 4.79004 4.99023 5.43004 4.99023 6.21004V19.55C4.99023 20.34 5.63023 20.97 6.41023 20.97H17.5802C18.3702 20.97 19.0002 20.33 19.0002 19.55V6.21004C19.0002 5.42004 18.3602 4.79004 17.5802 4.79004ZM13.9802 17.93H8.02024V16.03H13.9802V17.93ZM15.9702 13.87H8.02024V11.97H15.9702V13.87ZM15.9702 9.81004H8.02024V7.91004H15.9702V9.81004Z"
      fill="currentColor"
    />
    <path
      d="M11.9599 2.9502C10.6699 2.9502 9.62988 3.9902 9.62988 5.2802C9.62988 6.5702 10.6699 7.6102 11.9599 7.6102C13.2499 7.6102 14.2899 6.5702 14.2899 5.2802C14.2899 3.9902 13.2499 2.9502 11.9599 2.9502ZM11.9599 6.1502C11.4799 6.1502 11.0899 5.7602 11.0899 5.2802C11.0899 4.8002 11.4799 4.4102 11.9599 4.4102C12.4399 4.4102 12.8299 4.8002 12.8299 5.2802C12.8299 5.7602 12.4399 6.1502 11.9599 6.1502Z"
      fill="currentColor"
    />
  </svg>,
  'OrderGuide',
)
