import {
  addDuration,
  getEndOfWeekDateString,
  getISODateString,
  getStartOfWeekDateString,
  todayISODateString,
} from 'app/helpers/date'
import { useMultipleSearchParamsState } from 'app/hooks/useMultipleSearchParamsState'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import tuple from 'shared/tuple'

export interface DateRange {
  startDate: string
  endDate: string
  [key: string]: string
}

// eslint-disable-next-line no-useless-escape
const VALID_DATE_PATTERN = /\d{4}\-\d{2}\-\d{2}/g

function isValidDate(date: string) {
  return dayjs(date).isValid()
}

function validateDates(range: Partial<DateRange>) {
  const dateRange = {
    startDate: range?.startDate ?? '',
    endDate: range?.endDate ?? '',
  }

  const extractedStartDate =
    dateRange.startDate?.match(VALID_DATE_PATTERN)?.[0] ?? ''
  const extractedEndDate =
    dateRange.endDate?.match(VALID_DATE_PATTERN)?.[0] ?? ''

  if (isValidDate(extractedStartDate)) {
    dateRange.startDate = extractedStartDate
  } else {
    dateRange.startDate = getStartOfWeekDateString(todayISODateString())
    dateRange.endDate = getISODateString(
      addDuration(dateRange.startDate, 6, 'days'),
    )

    return dateRange
  }

  if (isValidDate(extractedEndDate)) {
    dateRange.endDate = extractedEndDate
  } else {
    dateRange.endDate = getISODateString(
      addDuration(dateRange.startDate, 6, 'days'),
    )

    return dateRange
  }

  return dateRange
}

export default function useDateRangeSearchParam() {
  const [{ startDate, endDate }, setDateRange] = useMultipleSearchParamsState<
    DateRange
  >({
    startDate: getStartOfWeekDateString(new Date()),
    endDate: getEndOfWeekDateString(new Date()),
  })

  const validatedDates = useMemo(() => {
    return validateDates({
      startDate,
      endDate,
    })
  }, [startDate, endDate])

  return tuple([validatedDates, setDateRange])
}
