import Button from 'app/components/Button/Button'

import { ConfigAdScheduleRow } from '@api/__gen__/gql'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { useDeleteConfigAdSchedules } from 'app/packages/internal/customerConfigs/adSchedules/api/mutations/useDeleteConfigAdSchedules'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

export const StyledInnerWrapper = styled.div`
  max-height: 450px;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export interface AdSchedulesDeleteModalProps {
  customerName?: string
  activeRow: ConfigAdScheduleRow
  onClose: () => void
  onSuccess: () => void
}

export default function AdSchedulesDeleteModal({
  activeRow,
  onClose,
  onSuccess,
  customerName,
}: AdSchedulesDeleteModalProps) {
  const [loading, setLoading] = useState(false)
  const [deleteConfigAdSchedules] = useDeleteConfigAdSchedules()
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await deleteConfigAdSchedules(activeRow._id)
      setLoading(false)
      onSuccess()
    } catch (error) {
      setLoading(false)
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete Ad Schedule</Title>
      </Header>

      <Body>
        Delete this ad schedule (
        <strong>Config ID: {activeRow?.id.value}</strong>) for {customerName}
        {errorMessage && (
          <StyledErrorMessage message={errorMessage} severityType="error" />
        )}
      </Body>

      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleDelete}>
            Delete
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
