import {
  OffsetPageInfo,
  StoreWorkflowAvailabilityQuery,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/workflowAvailability/pages/WorkflowAvailability'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'

export const GET_CONFIG_STORE_WORKFLOW_AVAILABILITY = gql`
  query StoreWorkflowAvailability(
    $customerId: ID!
    $offset: Int
    $limit: Int
    $sortOrder: [StoreSortOrder!]
    $searchTerm: String
    $businessUnitId: String
    $regionId: String
    $workflowId: ID
    $workflowDepartmentBundleId: ID
  ) {
    customerConfig(customerId: $customerId) {
      storeWorkflowsAvailability(
        offset: $offset
        limit: $limit
        sortOrder: $sortOrder
        searchTerm: $searchTerm
        businessUnitId: $businessUnitId
        regionId: $regionId
        workflowId: $workflowId
        workflowDepartmentBundleId: $workflowDepartmentBundleId
      ) {
        edges {
          node {
            _id
            store {
              _id
              id
              name
              regionName
              businessUnitName
            }
            resolvedWorkflowAvailabilities {
              workflow {
                id
                name
                departmentBundle {
                  id
                  name
                }
              }
            }
          }
        }
        pageInfo {
          totalCount
          totalPages
          currentPage
          pageSize
        }
      }
    }
  }
`

export interface StoreWorkflowAvailabilityRow {
  _id: string
  store: {
    _id: string
    id: string
    name: string
    regionName?: string | null
    businessUnitName?: string | null
  }
  resolvedWorkflowAvailabilities: {
    workflow: {
      id: string
      name: string
      departmentBundle: {
        id: string
        name: string
      }
    }
  }[]
}

export function useGetStoreWorkflowsAvailability({
  customerId,
  offset,
  limit,
  workflowId,
  workflowDepartmentBundleId,
  regionId,
  businessUnitId,
  searchTerm,
}: {
  customerId: CustomerId | undefined
  offset: number
  limit: number
  workflowId: string | null
  workflowDepartmentBundleId: string | null
  regionId: string | null
  businessUnitId: string | null
  searchTerm: string | null
}) {
  const [storedPageInfo, setStoredPageInfo] = useState<OffsetPageInfo>({
    __typename: 'OffsetPageInfo',
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const { data, loading, refetch } = useQuery<StoreWorkflowAvailabilityQuery>(
    GET_CONFIG_STORE_WORKFLOW_AVAILABILITY,
    {
      variables: {
        customerId,
        offset,
        limit,
        workflowId,
        workflowDepartmentBundleId,
        regionId,
        businessUnitId,
        searchTerm,
      },
      skip: !customerId,
    },
  )

  useEffect(() => {
    if (data?.customerConfig?.storeWorkflowsAvailability?.pageInfo) {
      setStoredPageInfo(data.customerConfig.storeWorkflowsAvailability.pageInfo)
    }
  }, [data?.customerConfig?.storeWorkflowsAvailability?.pageInfo])

  if (
    data &&
    data.customerConfig &&
    data.customerConfig?.storeWorkflowsAvailability &&
    data.customerConfig.storeWorkflowsAvailability?.edges
  ) {
    return {
      rows: compact(
        data.customerConfig.storeWorkflowsAvailability.edges.map(
          (edge) => edge?.node,
        ),
      ),
      pageInfo: data.customerConfig.storeWorkflowsAvailability.pageInfo,
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    pageInfo: storedPageInfo,
    loading,
    refetch,
  }
}
