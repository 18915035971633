import { gql } from '@apollo/client'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'

export const STORE_CLOSURE_EVENT_FIELDS = gql`
  ${STORE_FIELDS}
  fragment StoreClosureEventFields on StoreClosureEvent {
    _id
    closureType
    date
    isChainwide
    stores {
      ...StoreFields
    }
  }
`
