import { UserCapabilities } from '@api/__gen__/gql'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import useGetProductBundles, {
  ProductBundle,
} from 'app/api/queries/useGetProductBundles'
import FallbackLoader from 'app/components/FallbackLoader/FallbackLoader'
import { PRODUCT } from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import CatchAll from 'app/packages/core/pages/CatchAll/CatchAll'
import React from 'react'

interface Props {
  /** This is a user capability, used to match against a users available capabilities */
  permission?: UserCapabilities
  /** This is a product type enum, used to match against a users available productBundles */
  product?: PRODUCT
  children: React.ReactNode
}

function productAvailableToUser(
  product: PRODUCT,
  productBundles: ProductBundle[],
) {
  let productAvailable = false
  productBundles?.forEach((productBundle) => {
    productBundle.departmentBundles?.forEach((departmentBundle) => {
      if (departmentBundle.products) {
        departmentBundle.products.forEach((departmentBundleProduct) => {
          if (departmentBundleProduct.type === product) {
            productAvailable = true
          }
        })
      }
    })
  })
  return productAvailable
}

export default function Authorized({ children, permission, product }: Props) {
  const { loading, permissions: userPermissions } = useGetCustomerPermissions()
  const {
    productBundles,
    loading: productBundlesLoading,
  } = useGetProductBundles()

  if (loading || productBundlesLoading) {
    return <FallbackLoader />
  }

  // Gate the component with a capability(aka permission) requirement
  if (
    (permission && !userPermissions?.includes(permission)) ||
    (product &&
      productBundles &&
      !productAvailableToUser(product, productBundles))
  ) {
    return (
      <CatchAll
        message="You do not have the proper requirements to access this page"
        title="Insufficient Permissions"
      />
    )
  }

  return <>{children}</>
}
