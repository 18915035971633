import { GetStoresQuery, GetStoresQueryVariables } from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { STORE_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/StoreFields'
import { toStore } from 'app/packages/storeSolutions/schedules/pages/Schedules.helpers'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { isNil } from 'lodash-es'

export const GET_STORES = gql`
  ${STORE_FIELDS}
  query getStores($customerId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        stores {
          edges {
            node {
              ...StoreFields
            }
          }
        }
      }
    }
  }
`

export function useGetStores() {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<GetStoresQuery, GetStoresQueryVariables>(
    GET_STORES,
    {
      skip: isNil(activeCustomerId),
      variables: { customerId: activeCustomerId! },
    },
  )

  let stores: Store[] = []

  if (data && data.node && data.node.__typename === 'Customer') {
    stores = data.node.stores.edges
      .filter(({ node }) => node)
      .map(({ node }) => toStore(node!))
  }

  return {
    stores,
    isLoading: loading,
  }
}
