import Header from 'app/components/Header/Header'
import ErrorSystemStatus from 'app/components/Icons/ErrorSystemStatus'
import Link from 'app/components/Link/Link'
import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  flex: 1;
`

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  z-index: 1;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.lg};
`

const StyledError = styled.span`
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
`

const StyledErrorOutline = styled(ErrorSystemStatus)`
  margin-bottom: 4px;
  margin-right: 8px;
  vertical-align: middle;
`

interface Props {
  message?: string
  showHeader?: boolean
  title?: string
}

export default function CatchAll({
  showHeader = false,
  title = 'Page Not Found',
  message = 'Make a fresh start',
}: Props) {
  return (
    <>
      {showHeader && <Header />}
      <StyledContainer>
        <StyledMessage>
          <StyledError>
            <StyledErrorOutline />
            {title}
          </StyledError>
          <span>{message}</span>
          <Link to={{ pathname: '/' }}>Go Home</Link>
        </StyledMessage>
      </StyledContainer>
    </>
  )
}
