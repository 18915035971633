import { ENVIRONMENTS } from 'app/constants'
import React from 'react'
import styled from 'styled-components'

const StyledTextBox = styled.span<{ $env: ENVIRONMENTS; $inverted?: boolean }>`
  background-color: ${({ $env, theme }) =>
    $env === ENVIRONMENTS.PRODUCTION
      ? theme.colors.highlight_02
      : theme.colors.secondary};
  border: ${({ $env, theme }) =>
    `1px solid ${
      $env === ENVIRONMENTS.PRODUCTION
        ? theme.colors.highlight_02
        : theme.colors.neutral_08
    }`};
  border-radius: 4px;
  color: ${({ $env, theme }) =>
    $env === ENVIRONMENTS.PRODUCTION
      ? theme.colors.secondary
      : theme.colors.neutral_08};
  display: inline-flex;
  font-weight: bold;
  padding: 6px 8px 4px;

  ${({ $inverted, theme }) =>
    $inverted &&
    `
    color: ${theme.colors.secondary};
    background-color: ${theme.colors.neutral_08};
  `}
`

export default function EnvironmentLabel({
  env,
  inverted,
  className,
}: {
  env: ENVIRONMENTS
  inverted?: boolean
  className?: string
}) {
  return (
    <StyledTextBox $env={env} $inverted={inverted} className={className}>
      {env === ENVIRONMENTS.PRODUCTION ? 'PROD' : 'STAGING'}
    </StyledTextBox>
  )
}
