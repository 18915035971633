import { gql } from '@apollo/client'
import { ORDER_AUTO_UPDATE_FIELDS } from 'app/packages/internal/customerConfigs/orderAutoUpdate/api/fragments/OrderAutoUpdate'

export const ENABLE_ORDER_AUTO_PLACE = gql`
  ${ORDER_AUTO_UPDATE_FIELDS}

  mutation enableOrderAutoPlace($customerId: ID!) {
    enableOrderAutoPlace {
      query {
        node(id: $customerId) {
          ... on Customer {
            orderAutoUpdate {
              ...OrderAutoUpdateFields
            }
          }
        }
      }
    }
  }
`

export const DISABLE_ORDER_AUTO_PLACE = gql`
  ${ORDER_AUTO_UPDATE_FIELDS}

  mutation disableOrderAutoPlace($customerId: ID!) {
    disableOrderAutoPlace {
      query {
        node(id: $customerId) {
          ... on Customer {
            orderAutoUpdate {
              ...OrderAutoUpdateFields
            }
          }
        }
      }
    }
  }
`

export const ENABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW = gql`
  ${ORDER_AUTO_UPDATE_FIELDS}

  mutation enableOrderAutoPlaceForWorkflow($customerId: ID!, $workflowId: ID!) {
    enableOrderAutoPlaceForWorkflow(input: { workflowId: $workflowId }) {
      query {
        node(id: $customerId) {
          ... on Customer {
            orderAutoUpdate {
              ...OrderAutoUpdateFields
            }
          }
        }
      }
    }
  }
`

export const DISABLE_ORDER_AUTO_PLACE_FOR_WORKFLOW = gql`
  ${ORDER_AUTO_UPDATE_FIELDS}

  mutation disableOrderAutoPlaceForWorkflow(
    $customerId: ID!
    $workflowId: ID!
  ) {
    disableOrderAutoPlaceForWorkflow(input: { workflowId: $workflowId }) {
      query {
        node(id: $customerId) {
          ... on Customer {
            orderAutoUpdate {
              ...OrderAutoUpdateFields
            }
          }
        }
      }
    }
  }
`
