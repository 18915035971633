// Many of these are sourced from overrides
import { OutlinedInput } from '@mui/material'
import styled from 'styled-components'

export default styled(OutlinedInput)`
  border-radius: 2px;
  min-width: ${({ theme }) => theme.typography.pxToRem(120)};
  height: ${({ theme }) => theme.typography.pxToRem(40)};

  &:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.neutral_03};
  }

  &:active .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.primary};
  }

  &.Mui-focused .MuiOutlinedInput-notchedOutline,
  &:active .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.primary};
    border-width: 1px;
  }

  &.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.error};
    color: ${({ theme }) => theme.colors.neutral_02};
  }

  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.neutral_06};
    border-width: 1px;
    pointer-events: none;
  }

  & .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.colors.secondary};
    right: ${({ theme }) => theme.typography.pxToRem(8)};
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    &.Mui-disabled {
      fill: ${({ theme }) => theme.colors.neutral_06};
    }
  }

  & .MuiSelect-select {
    border-color: ${({ theme }) => theme.colors.neutral_05};
    padding: 0.5rem 1rem;
  }
`
