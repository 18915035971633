import { SelectChangeEvent } from '@mui/material'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { GuideStructureCategory } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { StyledP } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import { getCategoryOptions } from 'app/packages/storeSolutions/guideOrganization/pages/guideOrganization.helpers'
import { toRem } from 'app/styles'
import React from 'react'
import styled from 'styled-components'

export interface DeleteCategoryModalProps {
  isEmpty: boolean
  categories: GuideStructureCategory[]
  currentCategoryId: string
  handleSelectDeleteCategoryForMove: (e: SelectChangeEvent) => void
  selectedDeleteCategoryForMove: string
  isUncategorized: boolean
}

const StyledDeleteCategoryContainer = styled.div`
  min-height: ${toRem(250)};
`

const StyledSingleSelect = styled(SingleSelect)`
  margin-top: ${toRem(24)};
`

export default function DeleteCategory({
  isEmpty,
  categories,
  currentCategoryId,
  handleSelectDeleteCategoryForMove,
  selectedDeleteCategoryForMove,
  isUncategorized,
}: DeleteCategoryModalProps) {
  const { primaryKeyTypePlural } = useGetProperties()

  if (isUncategorized) {
    return (
      <StyledDeleteCategoryContainer>
        <StyledP>
          This section cannot be deleted. Store teams will no longer be able to
          see Uncategorized section when all items and {primaryKeyTypePlural}{' '}
          have been moved or reassigned to other sections.{' '}
        </StyledP>
      </StyledDeleteCategoryContainer>
    )
  }

  if (isEmpty) {
    return (
      <StyledDeleteCategoryContainer>
        <StyledP>
          This section is empty and can be deleted from the guide.
        </StyledP>
      </StyledDeleteCategoryContainer>
    )
  }
  return (
    <StyledDeleteCategoryContainer>
      <StyledP>
        In order to delete this section, please move all assigned{' '}
        {primaryKeyTypePlural} and manually moved items to another section
        first.
      </StyledP>
      <br />
      <StyledP>
        You can use the bulk action below to reassign all {primaryKeyTypePlural}{' '}
        and items to another Section.
      </StyledP>
      <StyledSingleSelect
        handleChange={handleSelectDeleteCategoryForMove}
        options={getCategoryOptions(categories).filter(
          (category) => category.value !== currentCategoryId,
        )}
        label="Move to Section"
        width={320}
        value={selectedDeleteCategoryForMove}
      />
    </StyledDeleteCategoryContainer>
  )
}
