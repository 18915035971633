import {
  GetGuideStructureItemsQuery,
  GetGuideStructureItemsQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery, useReactiveVar } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'
import { useDepartmentBundleId } from 'app/hooks/useDepartmentBundleId'
import { $activeBusinessLevel } from 'app/layouts/Customer/CustomerPageLayout.variables'
import { GUIDE_STRUCTURE_ITEM_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureItem'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import { GuideStructurePaginationArgs } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import produce from 'immer'
import { concat, isEqual, isNil } from 'lodash-es'
import { useRef } from 'react'
import segment from 'src/__generated__/segment'

export const GET_GUIDE_STRUCTURE_ITEMS = gql`
  ${GUIDE_STRUCTURE_ITEM_FIELDS}
  query getGuideStructureItems(
    $category: ID
    $customerId: ID!
    $departmentBundleId: ID!
    $businessLevelInstanceId: ID!
    $hasBeenEdited: Boolean
    $limit: Int
    $offset: Int
    $searchTerm: String
  ) {
    node(id: $customerId) {
      _id
      ... on Customer {
        _id
        guideStructure(
          departmentBundleId: $departmentBundleId
          businessLevelInstanceId: $businessLevelInstanceId
        ) {
          _id
          items(
            category: $category
            hasBeenEdited: $hasBeenEdited
            limit: $limit
            offset: $offset
            searchTerm: $searchTerm
          ) {
            edges {
              node {
                ...GuideStructureItemFields
              }
            }
            pageInfo {
              currentPage
              pageSize
              totalPages
            }
          }
        }
      }
    }
  }
`

function getGuideStructureFromResult(
  data: GetGuideStructureItemsQuery | undefined,
) {
  if (data?.node?.__typename === 'Customer') {
    return data?.node?.guideStructure ?? null
  }

  return null
}

function getItemsFromResult(data: GetGuideStructureItemsQuery | undefined) {
  const guideStructure = getGuideStructureFromResult(data)

  if (guideStructure) {
    return guideStructure.items?.edges ?? []
  }

  return []
}

function getPageInfoFromResult(data: GetGuideStructureItemsQuery | undefined) {
  const defaultPageInfo = {
    currentPage: 0,
    pageSize: 100,
    totalPages: 0,
  }

  const guideStructure = getGuideStructureFromResult(data)

  if (guideStructure) {
    const pageInfo = guideStructure.items?.pageInfo ?? defaultPageInfo

    return {
      currentPage: pageInfo?.currentPage ?? 0,
      pageSize: pageInfo?.pageSize ?? 100,
      totalPages: pageInfo?.totalPages ?? 0,
    }
  }

  return defaultPageInfo
}

export default function useGetItems(
  args?: GuideStructurePaginationArgs | null,
) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { activeCustomerId } = useCustomers()
  const [departmentBundleId] = useDepartmentBundleId()
  const businessLevelInstanceId = useReactiveVar($activeBusinessLevel)
  const { categories } = useGetCategories()
  const isFetchingMore = useRef(false)

  const { data, error, fetchMore, loading } = useQuery<
    GetGuideStructureItemsQuery,
    GetGuideStructureItemsQueryVariables
  >(GET_GUIDE_STRUCTURE_ITEMS, {
    // https://github.com/apollographql/apollo-client/issues/6916#issuecomment-811203002
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      if (args?.searchTerm || args?.category || !isNil(args?.hasBeenEdited)) {
        segment.guideOrganizationItemsTableFiltered({
          hasBeenEdited: args?.hasBeenEdited ?? null,
          searchQuery: args?.searchTerm ?? null,
          selectedCategoryId: args?.category ?? null,
          selectedCategoryName:
            categories.find(
              (category) => category.itemCategory.id === args?.category,
            )?.itemCategory.name ?? null,
          ...defaultProperties,
        })
      }
    },
    onError: (err) => {
      Sentry.captureMessage(
        `Failed to fetch guide items for ${activeCustomerId}: ${err}`,
        {
          level: 'warning',
        },
      )
    },
    skip:
      isNil(activeCustomerId) ||
      !departmentBundleId ||
      isNil(businessLevelInstanceId),
    variables: {
      ...(args ?? {}),
      customerId: activeCustomerId!,
      departmentBundleId,
      businessLevelInstanceId: businessLevelInstanceId!,
    },
  })

  const pageInfo = getPageInfoFromResult(data)

  return {
    error,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    fetchMore: async (args?: GuideStructurePaginationArgs | null) => {
      if (loading || isEqual(pageInfo.currentPage + 1, pageInfo.totalPages)) {
        return
      }

      try {
        isFetchingMore.current = true
        await fetchMore({
          variables: {
            ...(args ?? {}),
            offset: (pageInfo.currentPage + 1) * pageInfo.pageSize,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (
              !fetchMoreResult ||
              !getGuideStructureFromResult(fetchMoreResult)
            ) {
              return prev
            }

            const prevItems = getItemsFromResult(prev)
            const nextItems = getItemsFromResult(fetchMoreResult)

            const newResult = produce(fetchMoreResult, (draft) => {
              const draftGuideStructure = getGuideStructureFromResult(draft)

              if (draftGuideStructure) {
                draftGuideStructure.items.edges = concat(prevItems, nextItems)
              }
            })

            return newResult
          },
        })
      } catch (err) {
        Sentry.captureMessage(
          `Failed to fetch additional guide items for ${activeCustomerId}: ${err}`,
          {
            level: 'warning',
          },
        )
      } finally {
        isFetchingMore.current = false
      }
    },
    items: getItemsFromResult(data),
    isFetchingMore: isFetchingMore.current,
    isLoading: loading && !isFetchingMore.current,
    pageInfo,
  }
}
