import {
  CustomerConfigUnloadOrderSequenceQuery,
  OffsetPageInfo,
  UnloadOrderSequence,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'
import { DEFAULT_PAGE_SIZE } from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequence'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'

export const GET_CONFIG_UNLOAD_ORDER_SEQUENCE = gql`
  query CustomerConfigUnloadOrderSequence(
    $customerId: ID!
    $offset: Int
    $limit: Int
    $workflowId: ID
    $storeId: ID
  ) {
    customerConfig(customerId: $customerId) {
      unloadOrderSequenceOverrides(
        offset: $offset
        limit: $limit
        workflowId: $workflowId
        storeId: $storeId
      ) {
        edges {
          node {
            _id
            databaseId
            store {
              _id
              name
              customerStoreId
            }
            workflow {
              _id
              id
              name
            }
            dayOfWeek
            unloadOrderSequence
            createdAt
            updatedAt
          }
        }
        pageInfo {
          totalCount
          totalPages
          currentPage
          pageSize
        }
      }
    }
  }
`

export interface UnloadOrderSequenceRow {
  _id: string
  databaseId: string
  store?: {
    __typename: 'Store'
    _id: string
    customerStoreId: string
    name: string
  } | null
  workflow?: {
    __typename: 'Workflow'
    _id: string
    id: string
    name: string
  } | null
  dayOfWeek: number
  unloadOrderSequence: UnloadOrderSequence
  createdAt: string
  updatedAt: string
}

export function useGetUnloadOrderSequence({
  customerId,
  offset,
  limit,
  workflowId,
  storeId,
}: {
  customerId: CustomerId | undefined
  offset: number
  limit: number
  storeId: string | null
  workflowId: string | null
}) {
  const [storedPageInfo, setStoredPageInfo] = useState<OffsetPageInfo>({
    __typename: 'OffsetPageInfo',
    totalCount: 0,
    totalPages: 0,
    currentPage: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  const { data, loading, refetch } = useQuery<
    CustomerConfigUnloadOrderSequenceQuery
  >(GET_CONFIG_UNLOAD_ORDER_SEQUENCE, {
    variables: { customerId, offset, limit, workflowId, storeId },
    skip: !customerId,
  })

  useEffect(() => {
    if (data?.customerConfig?.unloadOrderSequenceOverrides?.pageInfo) {
      setStoredPageInfo(
        data.customerConfig.unloadOrderSequenceOverrides.pageInfo,
      )
    }
  }, [data?.customerConfig?.unloadOrderSequenceOverrides?.pageInfo])

  if (
    data &&
    data.customerConfig &&
    data.customerConfig?.unloadOrderSequenceOverrides &&
    data.customerConfig.unloadOrderSequenceOverrides?.edges
  ) {
    return {
      rows: compact(
        data.customerConfig.unloadOrderSequenceOverrides.edges.map(
          (edge) => edge?.node,
        ),
      ),
      pageInfo: data.customerConfig.unloadOrderSequenceOverrides.pageInfo,
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    pageInfo: storedPageInfo,
    loading,
    refetch,
  }
}
