import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3.5L21 19.5H3L12 3.5Z"
      fill="#FFB257"
      stroke="#FFB257"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.75V8.75"
      stroke="#313131"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
      fill="#313131"
    />
  </svg>,
  'CautionSystemStatusFilled',
)
