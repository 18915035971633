import { Chip, Popover } from '@mui/material'
import Button from 'app/components/Button/Button'
import CaretDown from 'app/components/Icons/CaretDown'
import Close from 'app/components/Icons/Close'
import SelectMenu from 'app/components/MultiSelect/SelectMenu'
import { Spinner } from 'app/components/Spinner/Spinner'
import { toRem } from 'app/styles'
import { nanoid } from 'nanoid'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

const StyledButton = styled(Button)<{ $isLoading?: boolean; $error?: boolean }>`
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    min-width: 0px;
    width: auto;
    text-wrap: nowrap;
    max-height: ${toRem(40)};
    text-transform: none;
    line-height: 1;
    padding: ${toRem(12)} ${toRem(16)};
    border-radius: 8px;

    :focus-visible {
      outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
      outline-offset: 2px;
    }
    :focus:not(:focus-visible) {
      outline: none;
    }
  }

    ${({ $isLoading }) =>
      $isLoading &&
      `
      &&& {
        color: transparent;
      }
    `}

  ${({ theme }) =>
    `
        background-color: ${theme.colors.neutral_08};
        border: 1px solid ${theme.colors.neutral_05};
        color:  ${theme.colors.secondary};
        :hover {
            background-color: ${theme.colors.neutral_08};
            border-color: ${theme.colors.neutral_10};
            color: ${theme.colors.neutral_10};
        }
        :active {
            background-color: ${theme.colors.neutral_08};
            border-color: ${theme.colors.neutral_09};
            color: ${theme.colors.neutral_09};
        }
        :disabled {
            border-color: ${theme.colors.neutral_05};
            color: ${theme.colors.neutral_03};
        }
    `}

    ${({ $error, theme }) =>
      $error &&
      `
        border-color: ${theme.colors.error};
    `}

`

const StyledSpinner = styled(Spinner)`
  font-size: ${toRem(14)};
  width: ${toRem(100)};

  && .MuiCircularProgress-root {
    height: ${toRem(20)} !important;
    width: ${toRem(20)} !important;
  }
`

export const StyledChip = styled(Chip)`
  height: ${toRem(40)};
  max-height: ${toRem(40)};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.highlight_01};
  font-weight: bold;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.neutral_01};
`

const StyledCloseIcon = styled(Close)`
  color: ${({ theme }) => theme.colors.neutral_01} !important;
`

export interface Option {
  id: string
  name: string
}

export interface MultiSelectProps {
  options: Option[]
  inputLabel: string
  width?: number
  disabled?: boolean
  error?: boolean
  required?: boolean
  virtualize?: boolean
  loading?: boolean
}

/**
 * @deprecated
 * ⚠️ Consider use MultiSelectSetProps instead for better performance.
 */
export interface MultiSelectArrayProps extends MultiSelectProps {
  selectedOptions: string[]
  onFilterOptions: (optionIds: string[]) => void
}

/**
 * Props for best performance.
 * When selected items change, checking Set.has() is an O(1) access.
 */
export interface MultiSelectSetProps extends MultiSelectProps {
  selected: Set<string>
  onChangeSelected: (selected: Set<string>) => void
}

export default function MultiSelect(
  props: MultiSelectArrayProps | MultiSelectSetProps,
) {
  const {
    inputLabel,
    options,
    width = 250,
    disabled = false,
    error = false,
    required = false,
    virtualize = false,
    loading = false,
  } = props

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [buttonAnchorId] = React.useState(nanoid())
  const [chipAnchorId] = React.useState(nanoid())

  let selected: Set<string>
  let onChangeSelected: (selected: Set<string>) => void
  if ('selectedOptions' in props) {
    // Convert array props into a map
    selected = new Set(props.selectedOptions)
    onChangeSelected = (selectedSet) =>
      props.onFilterOptions(Array.from(selectedSet))
  } else {
    ;({ selected, onChangeSelected } = props)
  }

  const selectedArray = useMemo(() => Array.from(selected), [selected])

  const [isOpen, setIsOpen] = useState(false)

  const handleOnClear = () => {
    onChangeSelected(new Set())
    if (isOpen)
      setAnchorEl(
        document.querySelector(
          `#button-anchor-${buttonAnchorId}`,
        ) as HTMLButtonElement,
      )
  }

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement>,
  ) => {
    setAnchorEl(event.currentTarget as HTMLButtonElement)
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
    setAnchorEl(null)
  }
  const handleOnSelect = (selectedId: string) => {
    const newSelected = new Set(selected)

    if (newSelected.has(selectedId)) {
      newSelected.delete(selectedId)
    } else {
      newSelected.add(selectedId)
    }

    if (newSelected.size > 0) {
      setAnchorEl(
        document.querySelector(
          `#chip-anchor-${chipAnchorId}`,
        ) as HTMLButtonElement,
      )
      setIsOpen(true)
    }

    if (newSelected.size === 0) {
      setAnchorEl(
        document.querySelector(
          `#button-anchor-${buttonAnchorId}`,
        ) as HTMLButtonElement,
      )
      setIsOpen(true)
    }

    onChangeSelected(newSelected)
  }

  const handleOnSelectAll = () => {
    onChangeSelected(new Set(options.map((opt) => opt.id)))
    setAnchorEl(
      document.querySelector(
        `#chip-anchor-${chipAnchorId}`,
      ) as HTMLButtonElement,
    )
    setIsOpen(true)
  }

  const numSelected = selected.size

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const getChipLabel = useCallback(() => {
    if (loading) {
      return <StyledSpinner />
    }

    if (numSelected === 0 && options && options.length === 0) {
      return inputLabel
    }
    return (
      options.find((option) => option.id === selectedArray[0])?.name +
      (numSelected > 1 ? ` + ${numSelected - 1}` : '')
    )
  }, [loading, numSelected, options, selectedArray, inputLabel])

  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '40px',
          minWidth: '150px',
          maxWidth: '245px',
        }}>
        <StyledButton
          variant="secondary"
          id={`button-anchor-${buttonAnchorId}`}
          onClick={handleClick}
          disabled={disabled}
          loading={loading}
          $error={error}
          endIcon={<CaretDown />}
          style={numSelected !== 0 ? { display: 'none' } : {}}>
          {inputLabel} {required && '*'}
        </StyledButton>
        <StyledChip
          label={getChipLabel()}
          clickable
          onClick={(event) => {
            handleClick(event)
          }}
          deleteIcon={<StyledCloseIcon />}
          id={`chip-anchor-${chipAnchorId}`}
          onDelete={handleOnClear}
          style={numSelected === 0 ? { display: 'none' } : {}}
        />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: `calc(100vh - 288px)`,
              minHeight: toRem(150),
              maxWidth: toRem(width),
              paddingTop: toRem(8),
              paddingBottom: toRem(8),
              overflow: 'hidden',
              width,
            },
          },
        }}>
        <SelectMenu
          width={width}
          virtualize={virtualize}
          numSelected={numSelected}
          handleOnClear={handleOnClear}
          handleOnSelectAll={handleOnSelectAll}
          handleOnSelect={handleOnSelect}
          options={options}
          selectedOptions={selected}
        />
      </Popover>
    </>
  )
}
