import { AccordionDetails } from '@mui/material'
import ChevronDown from 'app/components/Icons/ChevronDown'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import { PrimaryMapping } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import AssignCategoriesTable from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/AssignedCategoriesTable'
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/styles'
import { CreateEditSectionState } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import React from 'react'
import segment from 'src/__generated__/segment'

interface ReassignCategoriesProps {
  onChange: (producer: (draft: CreateEditSectionState) => void) => void
  sectionData: CreateEditSectionState
  primaryMappings: PrimaryMapping[]
}

export default function ReassignCategories({
  onChange,
  primaryMappings,
  sectionData,
}: ReassignCategoriesProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { primaryKeyTypePlural } = useGetProperties()

  const { categories } = useGetCategories()

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((draft) => {
      draft.movedPrimaryMappings.newGuideStructureCategoryId =
        event.target.value
    })

    segment.guideOrganizationReassignCategoriesNewSectionSelected({
      newSectionId: event.target.value,
      newSectionName: categories.find(
        ({ itemCategory }) => itemCategory.id === event.target.value,
      )?.itemCategory.name,
      ...defaultProperties,
    })
  }

  const handleToggle = () => {
    segment.guideOrganizationReassignCategoriesAccordionClicked({
      isExpanded: !sectionData.openReassignCategoriesAccordion,
      ...defaultProperties,
    })

    onChange((draft) => {
      draft.openReassignCategoriesAccordion = !draft.openReassignCategoriesAccordion
    })
  }

  const options = categories.map(({ itemCategory }) => ({
    label: itemCategory.name,
    value: itemCategory.id,
  }))

  return (
    <StyledAccordion
      expanded={sectionData.openReassignCategoriesAccordion}
      onChange={handleToggle}>
      <StyledAccordionSummary $isEditing expandIcon={<ChevronDown />}>
        Reassign {primaryKeyTypePlural}
      </StyledAccordionSummary>
      <AccordionDetails data-testid="reassignCategories">
        <AssignCategoriesTable
          canReassign
          movedPrimaryMappings={sectionData.movedPrimaryMappings}
          onChange={onChange}
          primaryMappings={primaryMappings}
        />
        <p>Selected {primaryKeyTypePlural} will be reassigned to:</p>
        <SingleSelect
          handleChange={handleOnChange}
          label="Select Section"
          options={[{ label: '--', value: '' }, ...options]}
          value={sectionData.movedPrimaryMappings.newGuideStructureCategoryId}
          width={320}
        />
      </AccordionDetails>
    </StyledAccordion>
  )
}
