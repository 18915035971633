import { gql } from '@apollo/client'

export const OVERLAPPING_ORDER_DELIVERY_EVENT_FIELDS = gql`
  fragment OverlappingOrderDeliveryEventFields on OverlappingOrderDeliveryEvents {
    orderDeliveryEvents {
      _id
      order {
        date
      }
      delivery {
        date
      }
    }
  }
`
