import { CreateConfigurationInput } from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { CONFIGURATIONS_ERRORS } from 'app/packages/internal/customerConfigs/configurations/api/errors'
import { CONFIGURATION_ROW } from 'app/packages/internal/customerConfigs/configurations/api/fragments/ConfigurationRow'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_CONFIGURATION = gql`
  ${CONFIGURATION_ROW}
  mutation createConfiguration(
    $createConfigurationInput: CreateConfigurationInput!
  ) {
    createConfiguration(input: $createConfigurationInput) {
      configuration {
        ...ConfigurationRow
      }
    }
  }
`

export function useCreateConfiguration() {
  const [createConfiguration, result] = useMutation(CREATE_CONFIGURATION, {
    refetchQueries: ['Configurations'],
  })

  return tuple([
    useCallback(
      async (input: CreateConfigurationInput) => {
        const { errors } = await createConfiguration({
          variables: {
            createConfigurationInput: input,
          },
        })

        if (errors?.length) {
          throw new Error(
            CONFIGURATIONS_ERRORS[getFirstErrorCode(errors)] ||
              `Add new configuration failed: ${errors[0].message}`,
          )
        }
      },
      [createConfiguration],
    ),
    result,
  ])
}
