/* eslint-disable react/no-array-index-key */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import ChevronDown from 'app/components/Icons/ChevronDown'
import { isNilOrEmpty } from 'app/helpers'
import { getLineColor } from 'app/packages/fileUploads/pages/dashboard/dashboard.helpers'
import {
  UploadResults,
  UploadResultsResult,
} from 'app/packages/fileUploads/pages/dashboard/dashboard.types'
import React from 'react'
import styled from 'styled-components'

const StyledH2 = styled.h2`
  font-weight: 700;
  margin: 0;
`

const AlertWrapper = styled(Box)`
  margin: 16px 0;
  .MuiAlert-message {
    flex: 1;
  }
`

const StyledAlertMessage = styled.strong`
  color: ${({ theme }) => theme.colors.neutral_02};
`

const ScrollableBox = styled(Box)`
  color: ${({ theme }) => theme.colors.neutral_02};
  max-height: 250px;
  overflow-y: auto;
`

const SpanHeader = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-left: 24px;
`

const SpanHeaderMain = styled(SpanHeader)`
  font-size: 20px;
  font-weight: 700;
  margin: 16px 0;
`

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.colors.neutral_05}`,
  '& .rdg': {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
  },
}))

const StyledCodeBlock = styled(Box)`
  background: ${({ theme }) => theme.colors.neutral_01};
  padding: 16px;
  overflow: auto;
  height: 250px;
  > code {
    color: ${({ theme }) => theme.colors.validation};
    font-size: 1.3em;
    font-weight: 700;
    font-family: monospace;
    display: block;
    white-space: pre;
    line-height: 1.6em;
  },
`

const StyledInnerUL = styled(List)`
  margin-left: 24px;
`

const StyledListItem = styled(ListItem)`
  margin: '4px 0';
  padding: 0;
`

export interface UploadErrorsProps {
  error?: string
  result: UploadResultsResult | null
}

function UploadErrors({ error, result }: UploadErrorsProps) {
  // if API fails
  if (error) {
    return <Box>{error}</Box>
  }

  if (!result) {
    return null
  }

  const { uncaughtException } = result
  // if celery throws an exception
  if (uncaughtException) {
    return <Box>{uncaughtException}</Box>
  }

  return (
    <ScrollableBox>
      {result?.errorReport?.map((report, index) => {
        return (
          <Box key={index} sx={{ marginTop: '8px' }}>
            <SpanHeaderMain
              key={
                report.fileName
              }>{`File: ${report.fileName}`}</SpanHeaderMain>
            {!isNilOrEmpty(report.fileErrors) && (
              <>
                <SpanHeader key="fileLevelErrors">File errors</SpanHeader>
                <StyledInnerUL>
                  {report.fileErrors.map((fileError, fileErrorIndex) => {
                    return (
                      <StyledListItem key={fileErrorIndex}>
                        {fileError.message}
                      </StyledListItem>
                    )
                  })}
                </StyledInnerUL>
              </>
            )}
            <List sx={{ padding: 0 }}>
              {report.sheets.map((sheet, sheetIndex) => {
                return (
                  <Box key={sheetIndex}>
                    <SpanHeader
                      key={
                        sheet.sheetName
                      }>{`Sheet: ${sheet.sheetName}`}</SpanHeader>
                    <StyledInnerUL>
                      {!isNilOrEmpty(sheet.sheetErrors) &&
                        sheet.sheetErrors.map((sheetError, sheetErrorIndex) => {
                          return (
                            <StyledListItem key={sheetErrorIndex}>
                              {sheetError.message}
                            </StyledListItem>
                          )
                        })}
                      {!isNilOrEmpty(sheet.cellErrors) &&
                        sheet.cellErrors.map((cellError, cellErrorIndex) => {
                          return (
                            <StyledListItem key={cellErrorIndex}>
                              {`Row ${cellError.cell[0]}, column ${cellError.cell[1]}: ${cellError.message}`}
                            </StyledListItem>
                          )
                        })}
                    </StyledInnerUL>
                  </Box>
                )
              })}
            </List>
          </Box>
        )
      })}
    </ScrollableBox>
  )
}

export interface FileUploadResultsProps {
  error?: string
  onDone: () => void
  uploadedResults: UploadResults
}

export default function FileUploadResults({
  error,
  onDone,
  uploadedResults,
}: FileUploadResultsProps) {
  const uploadDone = uploadedResults?.status === 'DONE'
  const uploadFailed =
    uploadedResults?.status === 'FAILED' ||
    uploadedResults?.result?.uncaughtException ||
    error

  return (
    <>
      <StyledH2>Upload results</StyledH2>

      <AlertWrapper>
        {uploadDone && (
          <AlertMessageBlock
            message="File successfully uploaded"
            severityType="success"
          />
        )}

        {uploadFailed && (
          <AlertMessageBlock
            includeDescription
            message={
              <UploadErrors error={error} result={uploadedResults?.result} />
            }
            severityType="error"
            title={
              <StyledAlertMessage>File upload unsuccessful.</StyledAlertMessage>
            }
          />
        )}
      </AlertWrapper>

      {uploadedResults?.log && (
        <StyledAccordion>
          <AccordionSummary
            sx={{ flexDirection: 'row-reverse' }}
            expandIcon={<ChevronDown />}>
            <Box marginLeft={2} sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 'bold' }}>JOB LOG</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px' }}>
            <StyledCodeBlock>
              {uploadedResults.log.split('\n').map((line, index) => (
                <code
                  key={index}
                  style={getLineColor(uploadedResults.status, line)}>
                  {line}
                </code>
              ))}
            </StyledCodeBlock>
          </AccordionDetails>
        </StyledAccordion>
      )}
      <Button variant="primary" onClick={onDone} sx={{ marginTop: '24px' }}>
        Done
      </Button>
    </>
  )
}
