import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { StyledP } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import React from 'react'

interface AssignCategoriesProps {
  addCategories: React.ReactNode
  hasExistingMappings: boolean
  isEditing: boolean
  isUncategorized: boolean
  reassignCategories?: React.ReactNode
}

export default function AssignCategories({
  addCategories,
  hasExistingMappings,
  isEditing,
  reassignCategories,
  isUncategorized,
}: AssignCategoriesProps) {
  const { primaryKeyTypePlural } = useGetProperties()

  if (isUncategorized) {
    return (
      <>
        <StyledP>
          The following {primaryKeyTypePlural} are not assigned to any section,
          so will appear under Uncategorized. Reassign {primaryKeyTypePlural}{' '}
          and move Items in Uncategorized to another Section.
        </StyledP>
        {reassignCategories}
      </>
    )
  }

  return (
    <>
      {hasExistingMappings ? (
        <>
          <StyledP>
            Current and future items with Assigned {primaryKeyTypePlural} will
            automatically be organized into this section (unless items are
            manually moved to another section).
          </StyledP>
          <StyledP>
            You can also reassign {primaryKeyTypePlural} to another section.
          </StyledP>
        </>
      ) : (
        <>
          <StyledP>
            Current and future items with Assigned {primaryKeyTypePlural} will
            automatically be organized into this section (unless items are
            manually moved to another section).{' '}
            {!isEditing && (
              <>
                Click Edit on the section to review and assign{' '}
                {primaryKeyTypePlural} later.
              </>
            )}
          </StyledP>
          <StyledP>
            Select {primaryKeyTypePlural} to assign to this section.
          </StyledP>
        </>
      )}
      {addCategories}
      {reassignCategories}
    </>
  )
}
