import { GetAvailableProductsQuery } from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'

export const GET_AVAILABLE_PRODUCTS = gql`
  query getAvailableProducts {
    products {
      _id
      type
    }
  }
`

export function useGetAvailableProducts() {
  const { data, loading, refetch } = useQuery<GetAvailableProductsQuery>(
    GET_AVAILABLE_PRODUCTS,
  )

  if (data && data.products) {
    return {
      products: data.products,
      refetch,
      loading,
    }
  }

  return {
    products: [],
    loading,
    refetch,
  }
}
