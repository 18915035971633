import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Button from 'app/components/Button/Button'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { EM_DASH } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import useToggleState from 'app/hooks/useToggleState'
import {
  GuideStructureItem,
  ItemOverridesProps,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useUpdateItemOverrides from 'app/packages/storeSolutions/guideOrganization/api/mutations/useUpdateItemOverrides'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import SectionsSelect from 'app/packages/storeSolutions/guideOrganization/pages/SectionsSelect/SectionsSelect'
import React, { useState } from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledMoveToButton = styled(Button)`
  margin-left: auto;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledSectionsSelect = styled(SectionsSelect)`
  margin: 20px 0;
`

const StyledTable = styled.table`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-radius: 4px;
  border-spacing: 0px;
  width: 100%;
`

const StyledTableTd = styled.td`
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  line-height: 1.1;
  height: 50px;
  padding: 0 16px;
`

const StyledTableTh = styled.th`
  background-color: ${({ theme }) => theme.colors.neutral_07};
  height: 50px;
  padding: 0 16px;
  text-align: left;

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }
`

const StyledAlert = styled(AlertMessageBlock)`
  margin-top: 12px;
`

function ItemOverridesModal({
  clearSelection,
  onClose,
  selectedItems,
}: {
  clearSelection: () => void
  onClose: () => void
  selectedItems: GuideStructureItem[]
}) {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const [errorMessage, setErrorMessage] = useState('')
  const [section, setSection] = useState('')
  const [updateItemOverrides, { loading }] = useUpdateItemOverrides()

  const handleOnCancel = () => {
    segment.guideOrganizationItemOverridesCancelButtonClicked(defaultProperties)
    onClose()
  }

  const handleOnSave = async () => {
    try {
      const overrides = selectedItems.map((item) => {
        return {
          itemCategoryId: section,
          itemId: item.id,
        }
      })

      await updateItemOverrides(overrides)
      clearSelection()
      onClose()
    } catch (error) {
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog isOpen onClose={onClose} size="lg">
      <Header disabled={loading} onClose={onClose}>
        <Title>Move to Section</Title>
      </Header>
      <Body>
        <StyledContent>
          The selected items and their substitutes will be moved to:
          <StyledSectionsSelect
            includeDefault={false}
            onSelect={setSection}
            title="Select Section"
            value={section}
          />
          <StyledTable role="table">
            <thead>
              <tr>
                <StyledTableTh>Selected Item</StyledTableTh>
                <StyledTableTh>Category</StyledTableTh>
                <StyledTableTh>Substitutes</StyledTableTh>
              </tr>
            </thead>
            <tbody>
              {selectedItems.map((item) => {
                const len = item.orderableOptions.length
                const optionId =
                  len > 0 ? item.orderableOptions[0].id : item.afreshItemId
                const optionName =
                  len > 0 ? item.orderableOptions[0].name : item.afreshItemName
                const substitutes = len - 1 > 0 ? len - 1 : EM_DASH

                return (
                  <tr key={optionId}>
                    <StyledTableTd>{optionName || EM_DASH}</StyledTableTd>
                    <StyledTableTd>{item.category || EM_DASH}</StyledTableTd>
                    <StyledTableTd>{substitutes}</StyledTableTd>
                  </tr>
                )
              })}
            </tbody>
          </StyledTable>
          {errorMessage && (
            <StyledAlert message={errorMessage} severityType="error" />
          )}
        </StyledContent>
      </Body>
      <Footer>
        <FooterActions>
          <Button
            disabled={loading}
            onClick={handleOnCancel}
            variant="secondary">
            Cancel
          </Button>
          <Button
            disabled={isNilOrEmpty(section)}
            loading={loading}
            onClick={handleOnSave}>
            Save
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}

export default function ItemOverrides({
  selectedItems,
  setSelectedItems,
}: ItemOverridesProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const [opened, { toggle }] = useToggleState()

  const handleOnClick = () => {
    segment.guideOrganizationItemOverridesMoveToButtonClicked({
      selectedItems,
      ...defaultProperties,
    })
    toggle()
  }

  return (
    <>
      <StyledMoveToButton
        disabled={isNilOrEmpty(selectedItems)}
        onClick={handleOnClick}>
        Move To
      </StyledMoveToButton>
      {opened && (
        <ItemOverridesModal
          onClose={toggle}
          selectedItems={Object.values(selectedItems)}
          clearSelection={() => setSelectedItems({})}
        />
      )}
    </>
  )
}
