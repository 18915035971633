import { useReactiveVar } from '@apollo/client'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import { useGetStoreSchedules } from 'app/packages/storeSolutions/schedules/api/queries/useGetStoreSchedules'
import useStoreIdsSearchParam from 'app/packages/storeSolutions/schedules/hooks/useStoreIdsSearchParam'
import { useSetBulkEditMatchingEventsForCalendar } from 'app/packages/storeSolutions/schedules/pages/BulkEdit/useSetBulkEditMatchingEvents.hook'
import OrdersAndDeliveriesTableBody from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/OrdersAndDeliveriesTableBody'
import {
  ITEM_HEIGHT,
  MIN_ROW_HEIGHT,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { StoreScheduleRow } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { $schedulesBulkEditCalendarStores } from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import { useGetVisibleData } from 'app/packages/storeSolutions/schedules/pages/Table/useGetVisibleData'
import React, { useMemo } from 'react'
import { OrdersAndDeliveriesTableHeader } from './TableHeader/TableHeader'

export default function OrdersAndDeliveriesTable() {
  const { orderDeliveryScheduleData, loading } = useGetStoreSchedules()
  const bulkEditStores = useReactiveVar($schedulesBulkEditCalendarStores)
  const [selectedStoreIds, setSelectedStoreIds] = useStoreIdsSearchParam()

  useSetBulkEditMatchingEventsForCalendar(
    bulkEditStores,
    orderDeliveryScheduleData,
    loading,
  )

  const storeScheduleRows: StoreScheduleRow[] = useMemo(() => {
    return orderDeliveryScheduleData.map((storeSchedule) => {
      const totalOrders = storeSchedule.orders.length
      const hasClosure = storeSchedule.schedule.some((day) => day.isClosed)
      return {
        ...storeSchedule,
        itemHeight: Math.max(
          totalOrders * ITEM_HEIGHT + (hasClosure ? ITEM_HEIGHT : 0),
          MIN_ROW_HEIGHT,
        ),
      }
    })
  }, [orderDeliveryScheduleData])

  const visibleData = useGetVisibleData(
    bulkEditStores,
    storeScheduleRows,
    selectedStoreIds,
  )

  const allStores = useMemo(() => {
    return orderDeliveryScheduleData.map((d) => d.store)
  }, [orderDeliveryScheduleData])

  return (
    <div data-testid="orderAndDeliveriesTable">
      <OrdersAndDeliveriesTableHeader
        renderMultiSelect={
          <MultiSelect
            inputLabel="Stores"
            loading={loading}
            onFilterOptions={setSelectedStoreIds}
            options={allStores}
            selectedOptions={selectedStoreIds}
          />
        }
      />
      <OrdersAndDeliveriesTableBody data={visibleData} isLoading={loading} />
    </div>
  )
}
