import {
  StyledCell,
  StyledRow,
  StyledTable,
  StyledTableBody,
  StyledTableCellDiv,
  StyledTableHeaderRow,
  StyledTableLoader,
} from 'app/components/Tables/StyledTableComponents'
import { BaseRow, BaseTableProps } from 'app/components/Tables/Tables.types'
import StickyVirtualizedList from 'app/components/VirtualizedList/Sticky'
import { ENTER, SPACE } from 'app/constants'
import { isNilOrEmpty } from 'app/helpers'
import useGetScrollbarWidth from 'app/hooks/useGetScrollbarWidth'
import React, { CSSProperties, useCallback } from 'react'

export default function BaseTable<T extends BaseRow>(props: BaseTableProps<T>) {
  const {
    justifyColumns = 'space-around',
    emptyStateContent,
    getRowLabel,
    height,
    isLoading,
    onRowClick,
    selectedRowId,
    stickyIndices,
    tableInstance: {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    },
    isEmptyState,
    style,
    bodyStyle,
  } = props

  const scrollbarWidth = useGetScrollbarWidth()

  const RenderRow = useCallback(
    ({
      index,
      isSticky,
      style: rowStyle,
    }: {
      index: number
      isSticky: boolean
      style: CSSProperties
    }) => {
      if (isNilOrEmpty(rows) || isNilOrEmpty(rows[index])) {
        return null
      }
      const row = rows[index]
      prepareRow(row)
      const handleOnClick = () => {
        if (isSticky || !onRowClick) {
          return
        }

        onRowClick(row.original)
      }

      const handleOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
        if (
          isSticky ||
          !onRowClick ||
          [ENTER, SPACE].indexOf(event.key) === -1
        ) {
          return
        }

        onRowClick(row.original)
      }

      return (
        <StyledRow
          {...row.getRowProps({ style: rowStyle })}
          aria-label={
            getRowLabel ? getRowLabel(row.original) : row.original.name
          }
          $isSelected={selectedRowId === row.original.id}
          role="button"
          data-testid="afresh-table-row"
          onClick={handleOnClick}
          onKeyDown={handleOnKeyDown}
          tabIndex={isSticky || !onRowClick ? undefined : 0}
          $isSelectable={!onRowClick}
          $justifyColumns={justifyColumns}>
          {row.cells.map((cell) => {
            return (
              <StyledCell {...cell.getCellProps()}>
                {cell.render('Cell')}
              </StyledCell>
            )
          })}
        </StyledRow>
      )
    },
    [getRowLabel, onRowClick, prepareRow, rows, selectedRowId, justifyColumns],
  )

  return (
    <StyledTable {...getTableProps()} style={style}>
      {headerGroups.map((headerGroup) => (
        <StyledTableHeaderRow
          {...headerGroup.getHeaderGroupProps({ style: { width: '100%' } })}
          $scrollbarWidth={scrollbarWidth}
          $justifyColumns={justifyColumns}>
          {headerGroup.headers.map((column) => {
            return (
              <StyledTableCellDiv {...column.getHeaderProps()}>
                {column.render('Header')}
              </StyledTableCellDiv>
            )
          })}
        </StyledTableHeaderRow>
      ))}
      <StyledTableBody {...getTableBodyProps()} style={bodyStyle}>
        {isLoading && <StyledTableLoader />}
        {isEmptyState && <div>{emptyStateContent}</div>}
        {!isLoading && !isNilOrEmpty(rows) && (
          <StickyVirtualizedList
            height={height ? `calc(${height} - 52px)` : undefined} // Full table height minus header
            numOfRows={rows.length}
            renderRow={RenderRow}
            stickyIndices={stickyIndices}
          />
        )}
      </StyledTableBody>
    </StyledTable>
  )
}
