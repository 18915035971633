import Logo from 'app/components/Logo/Logo'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledHeader = styled.header`
  height: 50px;
  border-bottom: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.neutral_05};
  border-bottom-style: solid;
  padding: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.neutral_08};
  z-index: 1000;
  display: flex;
  align-items: center;

  & a: {
    height: 16px;
  }
`

export default function Header() {
  return (
    <StyledHeader>
      <NavLink
        className={({ isActive }) => (isActive ? 'active' : undefined)}
        to="/">
        <Logo />
      </NavLink>
    </StyledHeader>
  )
}
