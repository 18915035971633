import {
  BusinessUnit,
  EndingInventoryStatus,
  GetInventoryResultsQuery,
  Region,
} from '@api/__gen__/gql'
import { ApolloError, ApolloQueryResult } from '@apollo/client'
import useGetCustomerPermissions from 'app/api/queries/useGetCustomerPermissions'
import { USER_ACCESS_PERMISSIONS } from 'app/constants'
import useGetInventoryPeriodData, {
  AccountingPeriodData,
  getActiveAccountingPeriod,
} from 'app/packages/inventoryManagement/inventoryResults/api/queries/useGetInventoryPeriodData'
import { Store } from 'app/packages/inventoryManagement/inventoryResults/api/queries/useGetInventoryResultsFilters'
import { EndingInventoryProps } from 'app/packages/inventoryManagement/inventoryResults/pages/EndingInventoriesTable/EndingInventoriesTable'
import dayjs from 'dayjs'
import React, { ReactElement } from 'react'

export interface InventoryResultsData {
  fetchNextPage: () => Promise<void | ApolloQueryResult<
    GetInventoryResultsQuery
  >>
  refetch: () => Promise<void>
  isLoadingEndingInventories: boolean
  hasNextPage: boolean
  endingInventories: EndingInventoryProps[]
  error: ApolloError | undefined
  stores: Store[]
  regions: Region[]
  businessUnits: BusinessUnit[]
  inventoryStatuses: EndingInventoryStatus[]
  accountingPeriods: {
    id: string
    periodNumber: number
    periodYearName: string
  }[]
  departmentBundles: { id: string; name: string }[]
}

const InventoryResultsContext = React.createContext<
  | undefined
  | {
      nextCountingPeriodStartDate: dayjs.Dayjs
      nextCountingPeriodEndDate: dayjs.Dayjs
      activeAccountingPeriod: AccountingPeriodData | null | undefined
      isCountingPeriodActive: boolean
      isInventoryApprovalEnabled: boolean
    }
>(undefined)

export function useInventoryResultsContext() {
  const context = React.useContext(InventoryResultsContext)
  if (context === undefined) {
    throw new Error(
      'useInventoryResultsContext must be used within a InventoryResultsContextProvider',
    )
  }
  return context
}

export const InventoryResultsContextProvider = ({
  children,
}: {
  children: ReactElement
}) => {
  const { permissions: userPermissions } = useGetCustomerPermissions()
  const {
    nextCountingPeriodEndDate,
    currentAccountingPeriod,
    nextCountingPeriodStartDate,
    isCountingPeriodActive,
  } = useGetInventoryPeriodData()
  const activeAccountingPeriod = getActiveAccountingPeriod(
    currentAccountingPeriod,
    nextCountingPeriodEndDate,
  )

  const isInventoryApprovalEnabled =
    userPermissions?.includes(USER_ACCESS_PERMISSIONS.INVENTORY_APPROVAL) ??
    false

  return (
    <InventoryResultsContext.Provider
      value={{
        nextCountingPeriodEndDate,
        nextCountingPeriodStartDate,
        activeAccountingPeriod,
        isCountingPeriodActive,
        isInventoryApprovalEnabled,
      }}>
      {children}
    </InventoryResultsContext.Provider>
  )
}
