import { useReactiveVar } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import useGetOrderWorkflows from 'app/api/queries/useGetOrderWorkflows'
import { useGetStores } from 'app/api/queries/useGetStores'
import Accordion from 'app/components/Accordion/Accordion'
import OpenInNew from 'app/components/Icons/OpenInNew'
import Link from 'app/components/Link/Link'
import useOffsetPagination from 'app/components/Tables/OffsetPaginatedTable/useOffsetPagination.hook'
import { ModalState } from 'app/packages/internal/customerConfigs/customerConfigs.types'
import {
  StyledHeader,
  StyledSubheader,
} from 'app/packages/internal/customerConfigs/styles'
import { useGetUnloadOrderSequence } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/queries/useGetUnloadOrderSequence'
import { $activeUnloadOrderSequenceConfig } from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequence.variables'
import UnloadOrderSequenceAddEditModal from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequenceAddEditModal'
import UnloadOrderSequenceDeleteModal from 'app/packages/internal/customerConfigs/unloadOrderSequence/pages/UnloadOrderSequenceDeleteModal'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { toRem } from 'app/styles'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Option } from 'shared/types'
import styled from 'styled-components'
import UnloadOrderSequenceTable from './UnloadOrderSequenceTable'

const StyledLaunchIcon = styled(OpenInNew)`
  margin-left: ${toRem(8)};
`

export const DEFAULT_PAGE_SIZE = 25

function sortStores(a: Store, b: Store) {
  const aStoreNumber = +a.name.split(' ')[0]
  const bStoreNumber = +b.name.split(' ')[0]
  return aStoreNumber - bStoreNumber
}

export default function UnloadOrderSequence({
  disableVirtualization = false,
}: {
  disableVirtualization?: boolean
}) {
  const { activeCustomerId, activeCustomer } = useCustomers()

  const [storeFilter, setStoreFilter] = useState<Option | null>(null)
  const [workflowFilter, setWorkflowFilter] = useState('')

  const [setupDetailsExpanded, setSetupDetailsExpanded] = useState(false)
  const [modalState, setModalState] = useState<ModalState | null>(null)
  const activeUnloadOrderSequenceConfig = useReactiveVar(
    $activeUnloadOrderSequenceConfig,
  )
  const {
    pageSize,
    pageIndex,
    handlePageChange,
    setPageIndex,
  } = useOffsetPagination(DEFAULT_PAGE_SIZE)

  const { rows, pageInfo, loading } = useGetUnloadOrderSequence({
    customerId: activeCustomerId,
    offset: pageIndex * pageSize,
    limit: pageSize,
    storeId: storeFilter?.value || null,
    workflowId: workflowFilter || null,
  })

  const { stores, isLoading: storeOptionsLoading } = useGetStores()

  const storeOptions = useMemo(() => {
    return [
      { label: 'ALL (-1)', value: '-1' },
      ...stores.sort(sortStores).map((store) => ({
        label: store.name,
        value: store.id,
      })),
    ]
  }, [stores])

  const {
    data: workflows,
    isLoading: workflowsLoading,
  } = useGetOrderWorkflows()

  const workflowOptions = useMemo(() => {
    return [
      { label: '--', value: '' },
      { label: 'ALL (-1)', value: '-1' },
      ...workflows.map((workflow) => ({
        label: `${workflow.databaseId} - ${workflow.workflowName}`,
        value: workflow.id,
      })),
    ]
  }, [workflows])

  useEffect(() => {
    const shouldSetEditState =
      activeUnloadOrderSequenceConfig !== null &&
      modalState !== ModalState.edit &&
      modalState !== ModalState.delete
    if (shouldSetEditState) {
      setModalState(ModalState.edit)
    }
  }, [activeUnloadOrderSequenceConfig, modalState, setModalState])

  const onSuccessfulSave = useCallback(async () => {
    setModalState(null)
    $activeUnloadOrderSequenceConfig(null)
  }, [setModalState])

  const activeRow =
    activeUnloadOrderSequenceConfig !== null &&
    rows.find((row) => activeUnloadOrderSequenceConfig === row.databaseId)

  return (
    <>
      <StyledHeader>Unload Order Sequence</StyledHeader>
      <Accordion
        header="Setup instructions and details"
        expanded={setupDetailsExpanded}
        onChangeExpanded={(expanded: boolean) =>
          setSetupDetailsExpanded(expanded)
        }>
        <StyledSubheader>
          This configuration is used to define the unload order sequence for a
          customer at the right level of granularity. The store, workflow, and
          day fields are used in a scoring algorithm to determine the correct
          unload order sequence for an order to ensure accuracy. The
          unload/order sequence configuration is only applicable for days where
          the delivery is expected to arrive on an order day.
          <p>
            <Link
              launch
              external
              to={{
                pathname:
                  'https://www.notion.so/afresh/Product-Documentation-Delivery-Unload-OrderingSequence-Config-f7b54be4f4b747e99f5658a7c0dd68eb',
              }}>
              Additional Documentation in Notion
              <StyledLaunchIcon fontSize="sm" />
            </Link>
          </p>
        </StyledSubheader>
      </Accordion>
      <UnloadOrderSequenceTable
        rows={rows}
        disableVirtualization={disableVirtualization}
        pageInfo={pageInfo}
        isLoading={loading || workflowsLoading || storeOptionsLoading}
        onPageChange={handlePageChange}
        onClickAddNew={() => setModalState(ModalState.add)}
        setupDetailsExpanded={setupDetailsExpanded}
        storeFilters={storeOptions}
        storeFilter={storeFilter}
        setStoreFilter={(value) => {
          setStoreFilter(value)
          setPageIndex(0)
        }}
        workflowFilters={workflowOptions}
        workflowFilter={workflowFilter}
        setWorkflowFilter={(value) => {
          setWorkflowFilter(value)
          setPageIndex(0)
        }}
      />
      {modalState === ModalState.add && (
        <UnloadOrderSequenceAddEditModal
          modalState={modalState}
          storeOptions={storeOptions}
          onClose={() => {
            $activeUnloadOrderSequenceConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          storeSearchLoading={storeOptionsLoading}
          rows={rows}
        />
      )}
      {modalState === ModalState.edit && activeRow && (
        <UnloadOrderSequenceAddEditModal
          modalState={modalState}
          storeOptions={storeOptions} // merge options and searched store options
          onClose={() => {
            $activeUnloadOrderSequenceConfig(null)
            setModalState(null)
          }}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
          onDelete={() => setModalState(ModalState.delete)}
          storeSearchLoading={storeOptionsLoading}
          rows={rows}
        />
      )}
      {modalState === ModalState.delete && activeRow && (
        <UnloadOrderSequenceDeleteModal
          onClose={() => {
            $activeUnloadOrderSequenceConfig(null)
            setModalState(null)
          }}
          customerName={activeCustomer?.name}
          onSuccess={onSuccessfulSave}
          activeRow={activeRow}
        />
      )}
    </>
  )
}
