import Button from 'app/components/Button/Button'
import Close from 'app/components/Icons/Close'
import Delete from 'app/components/Icons/Delete'
import useFocusTrap from 'app/hooks/useFocusTrap'
import React, { useRef } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  user-select: none;
`

const StyledCloseButton = styled(Button)`
  align-self: flex-end;
  color: ${({ theme }) => theme.colors.secondary};
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.colors.neutral_01};
  }
`

const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-top: 12px;
  padding-left: 4px;
`

const StyledDelete = styled(Button)`
  margin-right: auto;
  padding: 0;
`

export interface ScheduleFormProps {
  canDelete?: boolean
  children: React.ReactNode
  isEdit?: boolean
  isSaving?: boolean
  isDeleteDisabled?: boolean
  isSaveDisabled?: boolean
  onClose?: () => void
  onDelete?: () => void
  onSave: () => Promise<void>
}

export function ScheduleForm({
  canDelete = false,
  children,
  isEdit = false,
  isSaving = false,
  isDeleteDisabled = false,
  isSaveDisabled = false,
  onClose,
  onDelete,
  onSave,
}: ScheduleFormProps) {
  const scheduleFormRef = useRef<HTMLDivElement | null>(null)
  useFocusTrap(scheduleFormRef)

  return (
    <StyledContainer ref={scheduleFormRef}>
      <StyledCloseButton
        variant="icon"
        disabled={isSaving}
        aria-label="Close popover form"
        onClick={onClose}
        size="large">
        <Close />
      </StyledCloseButton>
      {children}

      <StyledButtonContainer>
        {isEdit && (
          <>
            {canDelete && (
              <StyledDelete
                aria-label="Delete event"
                disabled={isDeleteDisabled}
                onClick={onDelete}
                startIcon={<Delete />}
                variant="tertiary">
                Delete
              </StyledDelete>
            )}
            <Button
              disabled={isSaveDisabled}
              loading={isSaving}
              onClick={onSave}
              variant="secondary">
              Save
            </Button>
          </>
        )}
        {!isEdit && (
          <Button
            disabled={isSaveDisabled}
            loading={isSaving}
            onClick={onSave}
            variant="secondary">
            Add
          </Button>
        )}
      </StyledButtonContainer>
    </StyledContainer>
  )
}
