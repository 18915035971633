import React from 'react'
import styled from 'styled-components'

export const StyledTag = styled.span<{ color: string }>`
  background-color: ${({ color, theme }) => theme.colors[color] ?? ''};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.neutral_02};
  cursor: default;
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: bolder;
  height: ${({ theme }) => theme.typography.pxToRem(24)};
  line-height: ${({ theme }) =>
    theme.typography.pxToRem(26)}; /* to offset the current font alignment */
  max-width: ${({ theme }) => theme.typography.pxToRem(200)};
  overflow: hidden;
  padding: ${({ theme }) =>
    `${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(4)}`};
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
`

export interface TagProps {
  className?: string
  color?:
    | 'neutral_05'
    | 'highlight_02'
    | 'highlight_04'
    | 'highlight_06'
    | 'highlight_08'
    | 'highlight_09'
  text: string
}

export default function Tag({
  className = '',
  color = 'neutral_05',
  text,
  ...otherProps
}: TagProps) {
  return (
    <StyledTag className={className} title={text} color={color} {...otherProps}>
      {text}
    </StyledTag>
  )
}
