import { GuideStructureCategory } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { Option } from 'shared/types'

export function getCategoryOptions(
  categories: GuideStructureCategory[],
): Option[] {
  return categories.reduce((result: Option[], category) => {
    const { itemCategory } = category

    if (!itemCategory) {
      return result
    }

    result.push({
      value: itemCategory.id,
      label: itemCategory.name,
    })

    return result
  }, [])
}
