import { useReactiveVar } from '@apollo/client'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import CautionIcon from 'app/components/Icons/CautionSystemStatusFilled'
import { Spinner } from 'app/components/Spinner/Spinner'
import TextField from 'app/components/TextField/TextField'
import { isNilOrEmpty } from 'app/helpers'
import {
  addDuration,
  getISODateString,
  isBefore,
  isSame,
} from 'app/helpers/date'
import useCreateOrderDeliveryEvents from 'app/packages/storeSolutions/schedules/api/mutations/useCreateOrderDeliveryEvents'
import useDeleteOrderDeliveryEvents from 'app/packages/storeSolutions/schedules/api/mutations/useDeleteOrderDeliveryEvents'
import useUpdateOrderDeliveryEvents from 'app/packages/storeSolutions/schedules/api/mutations/useUpdateOrderDeliveryEvents'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import useGetTypicalDeliveryLag from 'app/packages/storeSolutions/schedules/api/queries/useGetTypicalDeliveryLag'
import useDateRangeSearchParam from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import DeleteConfirmationModal from 'app/packages/storeSolutions/schedules/pages/DeleteConfirmationModal/DeleteConfirmationModal'
import DiscardChangesModal from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/DiscardChangesModal'
import { CELL_DISPLAY_VALUE } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import {
  IssueType,
  Order,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import {
  $schedulesBulkEditCalendarMatchingEvents,
  $schedulesBulkEditCalendarStores,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import { ScheduleForm } from 'app/packages/storeSolutions/schedules/pages/Table/ScheduleForm'
import { isDirty } from 'app/utils'
import dayjs from 'dayjs'
import { uniq } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const issueTypeMessage: {
  [issueType in IssueType]: (
    eventType: 'ORDER' | 'DELIVERY' | 'MULTI',
  ) => string
} = {
  [IssueType.IllogicalDeliverySequence]: (
    eventType: 'ORDER' | 'DELIVERY' | 'MULTI',
  ) => {
    if (eventType !== 'MULTI') {
      return ''
    }

    return 'An order and delivery cannot be scheduled within the period of another order and delivery.'
  },

  [IssueType.OverlappingOrderDeliveryEvents]: (eventType: string) => {
    if (eventType === 'MULTI') {
      return ''
    }

    return `Multiple ${
      eventType === 'ORDER' ? 'orders' : 'deliveries'
    } on the same day.`
  },
  [IssueType.OrderDeliveryEventOverlapsWithStoreClosure]: (
    eventType: string,
  ) => {
    if (eventType === 'MULTI') {
      return ''
    }

    return 'Event scheduled on closed day.'
  },
}

const Field = styled.div`
  align-items: center;
  column-gap: 4px;
  display: flex;
  position: relative;

  & > svg {
    margin-top: 6px;
  }
`

const StyledSpinner = styled(Spinner)`
  height: 128px;
`

const StyledCautionIcon = styled(CautionIcon)`
  bottom: 11px;
  padding: 0 2px;
  position: absolute;
  right: 40px;
`

const StyledConflictMessage = styled.span`
  display: block;
  line-height: 1.1;
  margin: 8px 0 8px 24px;
`

const StyledStoreList = styled.ul`
  list-style: none;
  margin: 16px 0;
  max-height: 180px;
  overflow-y: auto;
  padding: 0;
`

const getInitialOrderDate = (startDate: string, cutoffDate: string) => {
  if (isBefore(startDate, cutoffDate) || isSame(startDate, cutoffDate)) {
    return getISODateString(addDuration(cutoffDate, 1, 'days'))
  } else {
    return startDate
  }
}

const generateConflictMessages = (
  issueTypes: IssueType[],
  eventType: 'ORDER' | 'DELIVERY' | 'MULTI',
) => {
  if (!issueTypes) {
    return null
  }

  return (
    <div>
      {uniq(issueTypes).map((type) => {
        return (
          <StyledConflictMessage key={type}>
            {issueTypeMessage[type] ? issueTypeMessage[type](eventType) : ''}
          </StyledConflictMessage>
        )
      })}
    </div>
  )
}

export interface OneTimeScheduleFormProps {
  orderDeliveryEvent?: Order
  onClose?: () => void
  storeId: string
  storeScheduleId?: string
  storeName?: string
}

export function OrderDeliveryEventForm({
  orderDeliveryEvent,
  onClose,
  storeId,
  storeName,
  storeScheduleId,
}: OneTimeScheduleFormProps) {
  const { editingCutoffDate } = useGetOrderDeliverySchedule()
  const [{ startDate }] = useDateRangeSearchParam()
  const bulkEditMatchingEvents = useReactiveVar(
    $schedulesBulkEditCalendarMatchingEvents,
  )
  const bulkEditStores = useReactiveVar($schedulesBulkEditCalendarStores)

  const { id: orderDeliveryEventId, order, delivery } = orderDeliveryEvent || {}
  const initialDeliveryDate = delivery ? delivery.date : ''
  const initialOrderDate =
    order?.date ?? getInitialOrderDate(startDate, editingCutoffDate)
  const isDeliveryConflict = !isNilOrEmpty(delivery?.issueTypes)
  const isOrderConflict = !isNilOrEmpty(order?.issueTypes)
  const isOrderFrozen = order ? !order.isEditable : false

  const [typicalDeliveryLag, isLoading] = useGetTypicalDeliveryLag(
    storeScheduleId,
  )

  const [
    createOrderDeliveryEvents,
    { loading: addLoading },
  ] = useCreateOrderDeliveryEvents()
  const [
    updateOrderDeliveryEvents,
    { loading: updateLoading },
  ] = useUpdateOrderDeliveryEvents()
  const [
    deleteOrderDeliveryEvents,
    { error: deleteError, loading: isDeleting },
  ] = useDeleteOrderDeliveryEvents()

  const [errorMessage, setErrorMessage] = useState('')
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false)
  const [showDiscardChangesModal, setShowDiscardChangesModal] = useState(false)

  const [orderDate, setOrderDate] = useState<dayjs.Dayjs | null>(
    dayjs(initialOrderDate),
  )
  const [deliveryDate, setDeliveryDate] = useState<dayjs.Dayjs | null>(null)

  useEffect(() => {
    setDeliveryDate(() => {
      return storeScheduleId
        ? dayjs(initialOrderDate).add(typicalDeliveryLag, 'days')
        : dayjs(initialDeliveryDate)
    })
  }, [
    initialDeliveryDate,
    initialOrderDate,
    storeScheduleId,
    typicalDeliveryLag,
  ])

  const isOrderDateDirty = isDirty(
    initialOrderDate,
    orderDate?.format('YYYY-MM-DD'),
  )
  const isDeliveryDateDirty = isDirty(
    initialDeliveryDate,
    deliveryDate?.format('YYYY-MM-DD'),
  )

  const toggleDiscardChangesModal = () =>
    setShowDiscardChangesModal((prev) => !prev)

  const handleOnDelete = async () => {
    try {
      const orderDeliveryEventIds = bulkEditMatchingEvents[
        orderDeliveryEventId!
      ] ?? [orderDeliveryEventId]
      await deleteOrderDeliveryEvents(orderDeliveryEventIds)

      if (onClose) {
        onClose()
      }
    } catch (err) {
      // GQL error
    }
  }

  const handleOnClose = () => {
    if (orderDeliveryEventId && (isOrderDateDirty || isDeliveryDateDirty)) {
      toggleDiscardChangesModal()
    } else if (onClose) {
      onClose()
    }
  }

  const handleOrderDateChange = (newOrderDate: dayjs.Dayjs | null) => {
    if (newOrderDate?.isAfter(deliveryDate)) {
      setDeliveryDate(newOrderDate?.clone().add(typicalDeliveryLag, 'days'))
    }

    setOrderDate(newOrderDate)
  }

  const toggleDeleteConfirmationModal = () => {
    setShowDeleteConfirmationModal((prev) => !prev)
  }

  const handleOnSave = async (): Promise<void> => {
    const newOrderDate = orderDate!.format('YYYY-MM-DD')
    const newDeliveryDate = deliveryDate!.format('YYYY-MM-DD')

    try {
      if (orderDeliveryEventId) {
        const orderDeliveryEventIds = bulkEditMatchingEvents[
          orderDeliveryEventId
        ] ?? [orderDeliveryEventId]

        await updateOrderDeliveryEvents(
          orderDeliveryEventIds,
          newOrderDate,
          newDeliveryDate,
        )
      } else {
        const storeIds = bulkEditStores.length
          ? bulkEditStores.map((store) => store.id)
          : [storeId]

        await createOrderDeliveryEvents(storeIds, newOrderDate, newDeliveryDate)
      }

      if (onClose) {
        onClose()
      }
    } catch (error) {
      setErrorMessage((error as Error).message)
    }
  }

  const storeNames = isNilOrEmpty(bulkEditStores)
    ? [storeName!]
    : bulkEditStores?.map(({ name }) => name)

  const isSaveDisabled =
    !orderDate ||
    !deliveryDate ||
    isLoading ||
    (!!orderDeliveryEventId && !isOrderDateDirty && !isDeliveryDateDirty)

  return (
    <>
      <ScheduleForm
        canDelete={!isOrderFrozen}
        isEdit={!!orderDeliveryEventId}
        isSaving={addLoading || updateLoading}
        isSaveDisabled={isSaveDisabled}
        onClose={handleOnClose}
        onDelete={toggleDeleteConfirmationModal}
        onSave={handleOnSave}>
        {isLoading && <StyledSpinner />}
        {!isLoading && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Field>
              {CELL_DISPLAY_VALUE.ORDER}
              <DesktopDatePicker
                value={orderDate}
                onChange={handleOrderDateChange}
                renderInput={(params) => {
                  return (
                    <>
                      <TextField {...params} label="Order Date" />
                      {isOrderConflict && <StyledCautionIcon />}
                    </>
                  )
                }}
                minDate={isOrderFrozen ? undefined : dayjs().add(1, 'days')}
                disabled={isOrderFrozen}
              />
            </Field>
            {isOrderConflict &&
              generateConflictMessages(order!.issueTypes, 'ORDER')}
            <Field>
              {CELL_DISPLAY_VALUE.DELIVERY}
              <DesktopDatePicker
                value={deliveryDate}
                onChange={setDeliveryDate}
                renderInput={(params) => {
                  return (
                    <>
                      <TextField {...params} label="Delivery Date" />
                      {isDeliveryConflict && <StyledCautionIcon />}
                    </>
                  )
                }}
                minDate={
                  isOrderFrozen
                    ? dayjs(editingCutoffDate).add(1, 'days')
                    : orderDate?.clone()
                }
              />
            </Field>
            {isDeliveryConflict &&
              generateConflictMessages(delivery!.issueTypes, 'DELIVERY')}
          </LocalizationProvider>
        )}
        {isDeliveryConflict &&
          isOrderConflict &&
          generateConflictMessages(
            [...delivery!.issueTypes, ...order!.issueTypes],
            'MULTI',
          )}
        {errorMessage && (
          <AlertMessageBlock message={errorMessage} severityType="error" />
        )}
      </ScheduleForm>

      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          confirmationMessage={
            <>
              Delete order on <strong>{orderDate?.format('YYYY-MM-DD')}</strong>{' '}
              and delivery on{' '}
              <strong>{deliveryDate?.format('YYYY-MM-DD')}</strong> for the
              following stores?
              <StyledStoreList>
                {storeNames.map((store) => {
                  return <li key={store}>{store}</li>
                })}
              </StyledStoreList>
            </>
          }
          errorMessage={deleteError?.message}
          isLoading={isDeleting}
          onClose={toggleDeleteConfirmationModal}
          onDone={handleOnDelete}
          title="Delete Order and Delivery"
        />
      )}

      {showDiscardChangesModal && (
        <DiscardChangesModal
          onClose={toggleDiscardChangesModal}
          onDone={onClose!}
          storeName={storeName!}
        />
      )}
    </>
  )
}
