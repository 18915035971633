import { OffsetPageInfo, PageInfo } from '@api/__gen__/gql'
import {
  FieldFunctionOptions,
  FieldPolicy,
} from '@apollo/client/cache/inmemory/policies'

type KeyArgs = FieldPolicy<any>['keyArgs']

interface GenericEdge {
  cursor?: string
  node?: unknown
}

type GenericConnection = Readonly<{
  edges: GenericEdge[]
  pageInfo: OffsetPageInfo | PageInfo
  totalCount?: number | null
}>

interface OffsetConnection extends GenericConnection {
  pageInfo: OffsetPageInfo
}

// Inspiration: https://github.com/apollographql/apollo-client/blob/7d39e26250b5a7838ed43d786f3eebd7fb98d568/src/utilities/policies/pagination.ts#L142
function makeEmptyOffsetData(): OffsetConnection {
  return {
    edges: [],
    pageInfo: {
      __typename: 'OffsetPageInfo',
    },
  }
}

// Based off of Apollo's `relayStylePagination`
// https://github.com/apollographql/apollo-client/blob/7d39e26250b5a7838ed43d786f3eebd7fb98d568/src/utilities/policies/pagination.ts#L94
export function offsetPagination<TConnection extends OffsetConnection>(
  keyArgs: KeyArgs = false,
) {
  // eslint-disable-next-line no-return-assign
  return {
    keyArgs,
    merge(
      existing: TConnection,
      incoming: TConnection,
      options: FieldFunctionOptions<any>,
    ) {
      const offset = options.args?.input?.offset ?? 0
      if (!existing) {
        // eslint-disable-next-line no-param-reassign
        existing = makeEmptyOffsetData() as TConnection
      }

      if (!incoming) {
        return existing
      }

      const incomingEdges = incoming.edges ?? []
      const mergedEdges = existing.edges.slice() // Don't mutate immutable data
      for (let i = 0; i < incomingEdges.length; ++i) {
        mergedEdges[offset + i] = incomingEdges[i]
      }

      return {
        ...incoming, // The data must match the original model shape to update the useQuery data
        edges: mergedEdges,
        pageInfo: incoming.pageInfo,
      }
    },
  }
}
