import SearchBox from 'app/components/SearchBox/SearchBox'
import SingleSelect, {
  getBooleanSelectOptions,
  getBooleanValueFromOptionValue,
} from 'app/components/SingleSelect/SingleSelect'
import { useSearchParamsState } from 'app/hooks/useSearchParamsState'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import Filters from 'app/packages/storeSolutions/guideOrganization/pages/Filters/Filters'
import CreateSection from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/CreateButton'
import SectionsList from 'app/packages/storeSolutions/guideOrganization/pages/Sections/SectionsList'
import SectionsSelect from 'app/packages/storeSolutions/guideOrganization/pages/SectionsSelect/SectionsSelect'
import React from 'react'
import styled from 'styled-components'

const StyledSearchBox = styled(SearchBox)`
  width: ${({ theme }) => theme.typography.pxToRem(340)};
`

export default function SectionsTable() {
  const { properties } = useGetProperties()

  const [categoryId, setCategoryId] = useSearchParamsState('categoryId')
  const [searchTerm, setSearchTerm] = useSearchParamsState('searchTerm')
  const [hasBeenEdited, setHasBeenEdited] = useSearchParamsState(
    'hasBeenEdited',
  )

  const { categories, error, loading } = useGetCategories({
    searchTerm: searchTerm || undefined,
    category: categoryId || undefined,
    hasBeenEdited: getBooleanValueFromOptionValue(hasBeenEdited),
  })

  const isDraft = properties?.status === 'DRAFT'

  return (
    <>
      <Filters>
        <StyledSearchBox
          placeholderText="Search by Section Name"
          onSearchTextChange={setSearchTerm}
          defaultValue={searchTerm}
        />
        <SectionsSelect onSelect={setCategoryId} value={categoryId} />
        {isDraft && (
          <SingleSelect
            options={getBooleanSelectOptions({
              trueLabel: 'Edited',
              falseLabel: 'Not edited',
            })}
            value={hasBeenEdited}
            label="Draft Status"
            handleChange={(e) => setHasBeenEdited(e.target.value)}
          />
        )}
        <CreateSection />
      </Filters>
      <SectionsList
        categories={categories}
        hasError={Boolean(error)}
        isLoading={loading}
      />
    </>
  )
}
