import LinearProgress from 'app/components/LinearProgress/LinearProgress'
import { ROW_HEIGHT } from 'app/components/Tables/Tables.constants'
import styled, { css } from 'styled-components'

export const StyledTableContainer = styled.div<{
  height?: string
  $isEmptyState: boolean
}>`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-right: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: ${(props) => (props.$isEmptyState ? 'calc(100vh - 150px)' : '100%')};
  ${({ height }) =>
    height &&
    css`
      max-height: ${height};
    `}
`

export const StyledTable = styled.div`
  white-space: nowrap;
  border-spacing: 0;
  width: 100%;
`

export const StyledTableHeaderRow = styled.div<{
  $scrollbarWidth: number
  $justifyColumns: string
}>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral_07};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  line-height: ${ROW_HEIGHT};
  justify-content: ${(props) => props.$justifyColumns};
  text-align: left;
  padding-right: ${(props) => `${props.$scrollbarWidth}px`};
`

export const StyledTableBody = styled.div`
  position: relative;

  &:last-child {
    border-bottom-style: none !important;
  }
`

export const StyledRow = styled.div<{
  $isSelected: boolean
  $isSelectable?: boolean
  $justifyColumns: string
}>`
  align-items: center;
  background-color: ${({ $isSelected, theme }) =>
    $isSelected ? theme.colors.neutral_07 : theme.colors.neutral_08};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  cursor: pointer;
  display: flex;
  justify-content: ${(props) => props.$justifyColumns};

  &:hover:not(:focus),
  :active,
  :target {
    background-color: ${({ $isSelectable, theme }) =>
      !$isSelectable && theme.colors.neutral_07};
    cursor: ${({ $isSelectable }) => $isSelectable && 'initial'};
  }

  &:hover > div span.item-status {
    background-color: ${({ theme }) => theme.colors.neutral_02};
    color: ${({ theme }) => theme.colors.neutral_08};
  }

  &:focus-visible {
    outline: ${({ theme }) => `2px solid ${theme.colors.primary}`};
    outline-offset: -4px;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
`

export const StyledTableCellDiv = styled.div`
  color: ${({ theme }) => theme.colors.neutral_02};
  font-weight: bold;
  padding: 0 16px;
`

export const StyledCell = styled.div`
  height: 49px;
  line-height: 49px;
  overflow: hidden;
  padding: 0 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const StyledTableFooter = styled.div`
  display: flex;
  justify-content: right;
`

export const StyledTableLoader = styled(LinearProgress)`
  margin: -1px;
`
