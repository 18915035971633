import {
  MAX_STORE_NAMES_SHOWN,
  STORE_NAME_HEIGHT,
  STORE_NAME_PADDING,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { Store } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { useMemo } from 'react'

export function useGetVisibleData<
  T extends { store: Store; itemHeight: number }
>(bulkEditStores: Store[], data: T[], selectedStoreIds: string[]) {
  return useMemo(() => {
    if (bulkEditStores.length) {
      // We only want to render one row if bulk editing, choose the
      // first row of the stores being bulk edited. Since we need
      // to show a list of names, also adjust the height to make
      // sure we have enough space.
      const numStoresShown = Math.min(
        bulkEditStores.length,
        MAX_STORE_NAMES_SHOWN,
      )

      // If there are more than our max number of stores, we show
      // an additional button to toggle viewing more, otherwise there
      // is just the add button.
      const numStoresAndButtons =
        bulkEditStores.length > MAX_STORE_NAMES_SHOWN
          ? numStoresShown + 2
          : numStoresShown + 1

      return data
        .filter(({ store }) => store.id === bulkEditStores[0].id)
        .map((schedule) => {
          return {
            ...schedule,
            itemHeight: Math.max(
              schedule.itemHeight,
              numStoresAndButtons * STORE_NAME_HEIGHT + STORE_NAME_PADDING,
            ),
          }
        })
    }

    if (!selectedStoreIds.length) {
      return data
    } else {
      return data.filter(({ store }) => {
        return selectedStoreIds.includes(store.id)
      })
    }
  }, [bulkEditStores, data, selectedStoreIds])
}
