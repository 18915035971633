import produce, { Draft } from 'immer'
import { useCallback, useState } from 'react'

export default function useDraftState<T>(
  initialValue: T,
): [T, (updater: (newState: Draft<T>) => void) => void] {
  const [val, updateValue] = useState(initialValue)

  return [val, useCallback((updater) => updateValue(produce<T>(updater)), [])]
}
