import { gql } from '@apollo/client'

export const ORDER_DELIVERY_EVENT_OVERLAPS_WITH_STORE_CLOSURE_FIELDS = gql`
  fragment OrderDeliveryEventOverlapsWithStoreClosureFields on OrderDeliveryEventOverlapsWithStoreClosure {
    orderDeliveryEvent {
      _id
      order {
        date
      }
      delivery {
        date
      }
    }
  }
`
