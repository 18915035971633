import { Divider, Drawer } from '@mui/material'
import { CloseDialogButton } from 'app/components/CloseDialogButton/CloseDialogButton'
import { EM_DASH } from 'app/constants'
import {
  ItemDetailsProps,
  SelectedItem,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import React, { Fragment } from 'react'
import styled from 'styled-components'

const StyledDrawer = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral_08};
  height: 100%;
  overflow: auto;
  padding: 24px;
  position: relative;
  width: 520px;
`

const StyledSectionHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const StyledDrawerTitle = styled.h2`
  margin: 0;
`

const StyledDivider = styled(Divider)`
  margin: 20px 0;
`

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`

const StyledSectionName = styled.h4`
  margin: 0;
  text-transform: uppercase;
`
const StyledFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const StyledFieldName = styled.span`
  flex: 1;
`

const StyledFieldNameEmphasized = styled(StyledFieldName)`
  font-weight: bold;
`

const StyledTable = styled.table`
  border: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  border-radius: 4px;
  border-spacing: 0px;
`

const StyledTableTd = styled.td`
  border-top: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  height: 50px;
  padding: 0 16px;
`

const StyledTableTh = styled.th`
  background-color: ${({ theme }) => theme.colors.neutral_07};
  height: 50px;
  padding: 0 16px;
  text-align: left;

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }
`

const FALLBACK_VALUE = 'XXXXX'

const sections: {
  [section: string]: (
    item: SelectedItem,
    onClose?: () => void,
  ) => React.ReactNode
} = {
  categorization: (item) => {
    const { itemClass, itemSubClass } = item

    return (
      <>
        <StyledDivider />
        <StyledSection>
          <StyledSectionName>Categorization</StyledSectionName>
          <StyledFlexContainer>
            <StyledFieldName>Category</StyledFieldName>
            <StyledFieldName>{itemSubClass}</StyledFieldName>
          </StyledFlexContainer>
          <StyledFlexContainer>
            <StyledFieldName>Major Category</StyledFieldName>
            <StyledFieldName>{itemClass}</StyledFieldName>
          </StyledFlexContainer>
        </StyledSection>
      </>
    )
  },
  orderableOption: (item, onClose) => {
    const { name, caseSize, caseUnits, orderCode } = item

    return (
      <StyledSection>
        <StyledSectionHeader>
          <StyledDrawerTitle>{name}</StyledDrawerTitle>
          <CloseDialogButton
            ariaLabel={`Close ${name} details window`}
            onClose={onClose!}
          />
        </StyledSectionHeader>
        <StyledFlexContainer>
          <StyledFieldName>Order Code</StyledFieldName>
          <StyledFieldNameEmphasized>
            {orderCode || FALLBACK_VALUE}
          </StyledFieldNameEmphasized>
        </StyledFlexContainer>
        <StyledFlexContainer>
          <StyledFieldName>Case Size</StyledFieldName>
          <StyledFieldNameEmphasized>
            {caseSize ? `${caseSize} ${caseUnits}` : EM_DASH}
          </StyledFieldNameEmphasized>
        </StyledFlexContainer>
      </StyledSection>
    )
  },
  organization: (item) => {
    const { category, categoryReason, itemSubClass } = item

    const detailedReason: { [key: string]: string } = {
      ITEM: `This item was manually moved to ${category}`,
      PRIMARY_KEY: `Item Category ${itemSubClass} is assigned to Section ${category}`,
    }

    return (
      <>
        <StyledDivider />
        <StyledSection>
          <StyledSectionName>Item Organization</StyledSectionName>
          <StyledFlexContainer>
            <StyledFieldName>Section</StyledFieldName>
            <StyledFieldName>{category}</StyledFieldName>
          </StyledFlexContainer>
          <StyledFlexContainer>
            <StyledFieldName>Section Reason</StyledFieldName>
            <StyledFieldName>
              {detailedReason[categoryReason!] || EM_DASH}
            </StyledFieldName>
          </StyledFlexContainer>
        </StyledSection>
      </>
    )
  },
  reference: (item) => {
    return (
      <>
        <StyledDivider />
        <StyledSection>
          <StyledSectionName>Internal Reference</StyledSectionName>
          <StyledFlexContainer>
            <StyledFieldName>Afresh Item ID #</StyledFieldName>
            <StyledFieldNameEmphasized>
              {item.afreshItemId}
            </StyledFieldNameEmphasized>
          </StyledFlexContainer>
        </StyledSection>
      </>
    )
  },
  substitutes: (item) => {
    const { substitutes } = item

    const buildTable = () => {
      return (
        <StyledTable data-testid="SubstitutesTable">
          <thead>
            <tr>
              <StyledTableTh>Name</StyledTableTh>
              <StyledTableTh>Order Code</StyledTableTh>
              <StyledTableTh>Case Size</StyledTableTh>
            </tr>
          </thead>
          <tbody>
            {substitutes.map(({ caseSize, caseUnits, id, name, orderCode }) => (
              <tr key={id}>
                <StyledTableTd>{name}</StyledTableTd>
                <StyledTableTd>{orderCode || FALLBACK_VALUE}</StyledTableTd>
                <StyledTableTd>{`${caseSize} ${caseUnits}`}</StyledTableTd>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )
    }

    return (
      <>
        <StyledDivider />
        <StyledSection>
          <StyledSectionName>Substitutes</StyledSectionName>
          {substitutes.length > 0 ? (
            buildTable()
          ) : (
            <StyledFieldName>There are no substitutes</StyledFieldName>
          )}
        </StyledSection>
      </>
    )
  },
}

const SECTIONS = [
  'orderableOption',
  'substitutes',
  'organization',
  'categorization',
  'reference',
]

export default function ItemDetails({ item, onClose }: ItemDetailsProps) {
  return (
    <Drawer anchor="right" onClose={onClose} open>
      <StyledDrawer>
        {SECTIONS.map((section) => (
          <Fragment key={section}>{sections[section](item, onClose)}</Fragment>
        ))}
      </StyledDrawer>
    </Drawer>
  )
}
