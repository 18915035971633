import { AccessRole } from '@api/__gen__/gql'
import { LOGOUT_PATH } from 'app/packages/core/pages/logout/logout.constants'
import { createContext, useContext, useEffect, useRef, useState } from 'react'

export const LOCAL_STORAGE_KEY = '_AS$'

export type Tokens = {
  access: string
  refresh: string
} | null

// the type of the auth data saved in local storage
interface StoredState {
  tokens: Tokens
  nextLocation: string | null
}

type AuthChangeListener = (isAuthenticated: boolean | undefined) => void
type TokenChangeListener = (tokens: Tokens) => void

// read data from local storage, ignoring errors
export function readLocalState(): StoredState {
  const state: StoredState = { tokens: null, nextLocation: null }
  const s = localStorage.getItem(LOCAL_STORAGE_KEY)

  try {
    const obj = JSON.parse(s || '')
    const { tokens, nextLocation } = obj

    // parse tokens
    try {
      const { access, refresh } = tokens
      if (typeof access === 'string' && typeof refresh === 'string') {
        state.tokens = {
          access: obj.tokens.access,
          refresh: obj.tokens.refresh,
        }
      }
    } catch {
      // ignore all errors
    }

    // parse nextLocation
    if (typeof nextLocation === 'string') {
      state.nextLocation = obj.nextLocation
    }
  } catch (e) {
    // ignore all errors
  }

  return state
}

export function writeLocalState(state: StoredState) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
}

export class AuthenticationState {
  _state: StoredState

  // true if authenticated, false if not, undefined if we don't know
  _isAuthenticated: boolean | undefined

  _authSubscribers: AuthChangeListener[]

  _tokenSubscribers: TokenChangeListener[]

  _accessRole: AccessRole | null

  constructor() {
    this._state = readLocalState()
    this._isAuthenticated = this._state.tokens === null ? false : undefined
    this._authSubscribers = []
    this._tokenSubscribers = []
    this._accessRole = null
  }

  get isAuthenticated() {
    return this._isAuthenticated
  }

  set isAuthenticated(auth: boolean | undefined) {
    if (this._isAuthenticated !== auth) {
      this._isAuthenticated = auth
      // loop over a copy in case one of the callbacks modifies the list
      ;[...this._authSubscribers].forEach((sub) => sub(auth))
    }
  }

  get accessRole() {
    return this._accessRole
  }

  set accessRole(role: AccessRole | null) {
    this._accessRole = role
  }

  getTokens(): Tokens {
    return this._state.tokens ? { ...this._state.tokens } : null
  }

  setTokens(tokens: Tokens) {
    const { access: curAccess, refresh: curRefresh } = this._state.tokens || {}
    const { access: newAccess, refresh: newRefresh } = tokens || {}
    if (curAccess !== newAccess || curRefresh !== newRefresh) {
      this._state.tokens = tokens ? { ...tokens } : null
      writeLocalState(this._state)
      return Promise.all(
        // loop over a copy in case one of the callbacks modifies the list
        [...this._tokenSubscribers].map((sub) =>
          Promise.resolve(sub(tokens ? { ...tokens } : null)),
        ),
      ).then(() => {})
    }
    return Promise.resolve()
  }

  get nextLocation() {
    return this._state.nextLocation
  }

  set nextLocation(location: string | null) {
    // never set nextLocation to the logout URL because that would immediately
    // log the user out after they authenticate
    this._state.nextLocation = (location || '').startsWith(LOGOUT_PATH)
      ? null
      : location
    writeLocalState(this._state)
  }

  logout() {
    this.isAuthenticated = false
    return this.setTokens(null)
  }

  addAuthChangeListener(cb: AuthChangeListener) {
    this._authSubscribers.push(cb)
  }

  removeAuthChangeListener(cb: AuthChangeListener) {
    this._authSubscribers.splice(this._authSubscribers.indexOf(cb), 1)
  }

  addTokenChangeListener(cb: TokenChangeListener) {
    this._tokenSubscribers.push(cb)
  }

  removeTokenChangeListener(cb: TokenChangeListener) {
    this._tokenSubscribers.splice(this._tokenSubscribers.indexOf(cb), 1)
  }
}

export function useIsAuthenticated(
  authState: AuthenticationState,
): boolean | undefined {
  const [isAuthenticated, setIsAuthenticated] = useState(
    authState.isAuthenticated,
  )

  useEffect(() => {
    authState.addAuthChangeListener(setIsAuthenticated)
    return () => {
      authState.removeAuthChangeListener(setIsAuthenticated)
    }
  }, [authState, setIsAuthenticated])

  return isAuthenticated
}

export function useTokens(
  authState: AuthenticationState,
  onTokensChange: (tokens: Tokens) => unknown,
) {
  const callbackRef = useRef((t: Tokens) => onTokensChange(t))

  useEffect(() => {
    const callback = callbackRef.current
    authState.addTokenChangeListener(callback)
    return () => {
      authState.removeTokenChangeListener(callback)
    }
  }, [authState])
}

export const AuthContext = createContext<AuthenticationState>(
  new AuthenticationState(),
)

export const useAuthState = () => {
  return useContext(AuthContext)
}
