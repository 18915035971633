import {
  GetBusinessLevelConfigsQuery,
  GetBusinessLevelConfigsQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { useCustomers } from 'app/CustomerContext'
import { BUSINESS_LEVEL_RESULT } from 'app/packages/internal/customerConfigs/businessLevelDisplayNames/api/fragments/BusinessLevelResult'
import { nanoid } from 'nanoid'
import { Option } from 'shared/types'

export const GET_BUSINESS_LEVEL_CONFIGS = gql`
  ${BUSINESS_LEVEL_RESULT}
  query getBusinessLevelConfigs($customerId: ID!, $offset: Int, $limit: Int) {
    customerConfig(customerId: $customerId) {
      businessLevels(limit: $limit, offset: $offset) {
        edges {
          node {
            ...BusinessLevelResult
          }
        }
      }
    }
  }
`

export interface BusinessLevelConfigs {
  id: string
  businessLevel: Option
  displayName: Option
  createdAt: Option
  updatedAt: Option
  updatedBy: Option
}

interface IdName {
  id?: string | null | undefined
  name?: string | null | undefined
}

function toLabelValue({ id, name }: IdName) {
  return {
    label: name ?? '',
    value: id ?? '',
  }
}

function reshapeData(
  data?: GetBusinessLevelConfigsQuery,
): BusinessLevelConfigs[] {
  if (!data) {
    return []
  }

  if (data?.customerConfig?.__typename === 'CustomerConfigQueries') {
    return data.customerConfig?.businessLevels?.edges?.map(({ node }) => {
      return {
        id: nanoid(),
        businessLevel: toLabelValue(node.businessLevel),
        displayName: toLabelValue(node.displayName),
        createdAt: toLabelValue(node.createdAt),
        updatedAt: toLabelValue(node.updatedAt),
        updatedBy: toLabelValue(node.updatedBy),
      }
    })
  }

  return []
}

export default function useGetBusinessLevelConfigs() {
  const { activeCustomerId } = useCustomers()

  const { data, loading } = useQuery<
    GetBusinessLevelConfigsQuery,
    GetBusinessLevelConfigsQueryVariables
  >(GET_BUSINESS_LEVEL_CONFIGS, {
    skip: !activeCustomerId,
    variables: { customerId: activeCustomerId! },
  })

  return {
    data: reshapeData(data),
    loading,
  }
}
