import Button from 'app/components/Button/Button'

import AlertMessageBlock from 'app/components/AlertMessageBlock/AlertMessageBlock'
import Dialog, {
  Body,
  Footer,
  FooterActions,
  Header,
  Title,
} from 'app/components/Dialog/Dialog'
import { useDeleteUnloadOrderSequence } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/mutations/useDeleteUnloadOrderSequence'
import { UnloadOrderSequenceRow } from 'app/packages/internal/customerConfigs/unloadOrderSequence/api/queries/useGetUnloadOrderSequence'
import { toRem } from 'app/styles'
import React, { useState } from 'react'
import styled from 'styled-components'

export const StyledInnerWrapper = styled.div`
  max-height: 450px;
`

export const StyledErrorMessage = styled(AlertMessageBlock)`
  margin-top: ${toRem(16)};
`

export interface UnloadOrderSequenceDeleteModalProps {
  customerName?: string
  activeRow: UnloadOrderSequenceRow
  onClose: () => void
  onSuccess: () => void
}

export default function UnloadOrderSequenceDeleteModal({
  activeRow,
  onClose,
  onSuccess,
  customerName,
}: UnloadOrderSequenceDeleteModalProps) {
  const [loading, setLoading] = useState(false)
  const [deleteConfigUnloadOrderSequence] = useDeleteUnloadOrderSequence()
  const [errorMessage, setErrorMessage] = useState('')

  const handleDelete = async () => {
    setLoading(true)
    setErrorMessage('')
    try {
      await deleteConfigUnloadOrderSequence(activeRow._id)
      setLoading(false)
      onSuccess()
    } catch (error) {
      setLoading(false)
      setErrorMessage((error as Error).message)
    }
  }

  return (
    <Dialog disabled={loading} isOpen onClose={onClose}>
      <Header disabled={loading} onClose={onClose}>
        <Title>Delete Unload Order Sequence Config</Title>
      </Header>

      <Body>
        Delete this config (<strong>ID: {activeRow?.databaseId}</strong>) for{' '}
        {customerName}
        {errorMessage && (
          <StyledErrorMessage message={errorMessage} severityType="error" />
        )}
      </Body>

      <Footer>
        <FooterActions>
          <Button disabled={loading} onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button loading={loading} onClick={handleDelete}>
            Delete
          </Button>
        </FooterActions>
      </Footer>
    </Dialog>
  )
}
