import { ConfigurationTypesQuery } from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'

export const GET_CONFIGURATION_TYPES = gql`
  query ConfigurationTypes {
    configurationTypes {
      name
      valueType
      allowedDimensions
    }
  }
`

export function useGetConfigurationTypes() {
  const { data, loading, refetch } = useQuery<ConfigurationTypesQuery>(
    GET_CONFIGURATION_TYPES,
  )

  if (data?.configurationTypes) {
    return {
      types: data.configurationTypes,
      refetch,
      loading,
    }
  }

  return {
    types: [],
    loading,
    refetch,
  }
}
