import { gql } from '@apollo/client'

export const RECURRING_ORDER_DELIVERY_EVENT_FIELDS = gql`
  fragment RecurringOrderDeliveryEventFields on OrderDeliveryRecurringSchedule {
    _id
    orderDay
    deliveryDay
    deliveryLag
  }
`
