import {
  AccordionDetails,
  Autocomplete,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import ChevronDown from 'app/components/Icons/ChevronDown'
import TextField from 'app/components/TextField/TextField'
import { isNilOrEmpty } from 'app/helpers'
import useToggleState from 'app/hooks/useToggleState'
import { GuideStructureCategory } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetAvailablePrimaryMappings from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetAvailablePrimaryMappings'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import AssignCategoriesTable from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/AssignedCategoriesTable'
import {
  StyledAccordion,
  StyledAccordionSummary,
} from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditFlow/styles'
import { CreateEditSectionState } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import React from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledTextField = styled(TextField)`
  margin-top: 0;
  min-height: 50px;

  & .MuiInputBase-root {
    align-items: flex-start;
    flex-direction: column;

    .MuiInputBase-input {
      width: 100%;
    }
  }

  & .MuiChip-root {
    background-color: ${({ theme }) => theme.colors.highlight_01};
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
  }
`

const StyledLoadingIndicator = styled(CircularProgress)`
  position: absolute;
  right: 38px;
  top: 10px;
`

interface AddCategoriesProps {
  categoryEditContext?: GuideStructureCategory
  onChange: (producer: (draft: CreateEditSectionState) => void) => void
  sectionData: CreateEditSectionState
  viewOnly: boolean
}

export default function AddCategories({
  categoryEditContext,
  onChange,
  sectionData,
  viewOnly,
}: AddCategoriesProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()
  const { primaryKeyTypePlural } = useGetProperties()

  const [open, { toggle }] = useToggleState()

  const { availablePrimaryMappings, loading } = useGetAvailablePrimaryMappings(
    open,
    categoryEditContext?.primaryMappings ?? [],
  )

  const hasPrimaryMappings = !isNilOrEmpty(categoryEditContext?.primaryMappings)

  const accordionProps = hasPrimaryMappings
    ? {
        expanded: sectionData.openAssignCategoriesAccordion,
        onChange: () => {
          segment.guideOrganizationAssignCategoriesAccordionClicked({
            isExpanded: !sectionData.openAssignCategoriesAccordion,
            ...defaultProperties,
          })

          onChange((draft) => {
            draft.openAssignCategoriesAccordion = !draft.openAssignCategoriesAccordion
          })
        },
      }
    : { expanded: true }

  return (
    <StyledAccordion
      data-testid="assignCategoriesAccordion"
      {...accordionProps}>
      <StyledAccordionSummary
        $isEditing={!!hasPrimaryMappings}
        expandIcon={hasPrimaryMappings ? <ChevronDown /> : null}
        tabIndex={hasPrimaryMappings ? 0 : -1}>
        Assign {primaryKeyTypePlural}
      </StyledAccordionSummary>
      <AccordionDetails>
        {hasPrimaryMappings && categoryEditContext && (
          <AssignCategoriesTable
            primaryMappings={categoryEditContext.primaryMappings}
          />
        )}

        {!viewOnly && (
          <>
            <p>Select {primaryKeyTypePlural} to assign to this section.</p>
            <Autocomplete
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              loading={loading}
              multiple
              onBlur={() => {
                segment.guideOrganizationAssignCategoriesSelected({
                  isEditing: hasPrimaryMappings,
                  selectedPrimaryMappings: sectionData.primaryMappings.map(
                    (primaryMapping) =>
                      `${primaryMapping._id}:${primaryMapping.name}`,
                  ),
                  ...defaultProperties,
                })
              }}
              onChange={async (_, state) => {
                onChange((draft) => {
                  draft.primaryMappings = state
                })
              }}
              onClose={toggle}
              onOpen={toggle}
              options={availablePrimaryMappings}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label={`Select ${primaryKeyTypePlural}`}
                  placeholder={`Search ${primaryKeyTypePlural}`}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? (
                          <StyledLoadingIndicator color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <li {...props} key={option._id}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                  </li>
                )
              }}
              value={sectionData.primaryMappings}
            />
          </>
        )}
      </AccordionDetails>
    </StyledAccordion>
  )
}
