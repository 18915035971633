import {
  UpdateGuideCategoryMutation,
  UpdateGuideCategoryMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { getFlagValue, useFeatureFlags } from 'app/context/rolloutContext'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { msgByErrorCode } from 'app/packages/storeSolutions/guideOrganization/api/errors'
import { GUIDE_STRUCTURE_CATEGORY_FIELDS } from 'app/packages/storeSolutions/guideOrganization/api/fragments/GuideStructureCategoryFields'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import {
  GuideStructureCategory,
  InventoryConfiguration,
  MovedPrimaryMapping,
  OrderingConfiguration,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { CreateEditSectionState } from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import { toString } from 'lodash-es'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const UPDATE_GUIDE_STRUCTURE_CATEGORY = gql`
  ${GUIDE_STRUCTURE_CATEGORY_FIELDS}
  mutation updateGuideCategory(
    $guideStructureId: ID!
    $guideStructureCategoryId: ID!
    $name: String
    $sortIndex: Int
    $parentId: ID
    $addedPrimaryMappings: [ID!]
    $movedPrimaryMappings: UpdateGuideStructureCategoryMovedPrimaryMappings
    $inventoryConfiguration: GuideStructureCategoryInventoryConfigurationInput
    $orderingConfiguration: GuideStructureCategoryOrderingConfigurationInput
  ) {
    updateGuideStructureCategory(
      input: {
        guideStructureId: $guideStructureId
        guideStructureCategoryId: $guideStructureCategoryId
        name: $name
        sortIndex: $sortIndex
        parentId: $parentId
        addedPrimaryMappings: $addedPrimaryMappings
        movedPrimaryMappings: $movedPrimaryMappings
        inventoryConfiguration: $inventoryConfiguration
        orderingConfiguration: $orderingConfiguration
      }
    ) {
      guideStructureCategory {
        ...GuideStructureCategoryFields
      }
    }
  }
`

interface EditCategoryPayload {
  addedPrimaryMappings?: string[]
  guideStructureCategoryId: string
  movedPrimaryMappings?: MovedPrimaryMapping
  name?: string
  parentId?: string | null
  sortIndex?: number
  orderingConfiguration?: OrderingConfiguration
  inventoryConfiguration?: InventoryConfiguration
}

function derivePayloadFromUpdatedFields(
  newState: CreateEditSectionState,
  prevState: GuideStructureCategory,
  isCategoryConfigurationVisible: boolean,
  showInventorySettings: boolean,
) {
  const payload: EditCategoryPayload = {
    guideStructureCategoryId: newState.categoryId!,
  }

  if (newState.categoryName !== prevState.itemCategory.name) {
    payload.name = newState.categoryName
  }

  if (newState.sortIndex !== toString(prevState.itemCategory.sortIndex)) {
    payload.sortIndex = Number(newState.sortIndex)
  }

  if (newState.parentId !== prevState.itemCategory.parentId) {
    payload.parentId = newState.parentId
  }

  if (newState.movedPrimaryMappings.newGuideStructureCategoryId) {
    payload.movedPrimaryMappings = newState.movedPrimaryMappings
  }

  if (
    isCategoryConfigurationVisible &&
    (newState.orderingConfiguration.autoInventoryEnabled !==
      prevState.orderingConfiguration?.autoInventoryEnabled ||
      newState.orderingConfiguration.backInventoryRequired !==
        prevState.orderingConfiguration.backInventoryRequired ||
      newState.orderingConfiguration.floorInventoryRequired !==
        prevState.orderingConfiguration?.floorInventoryRequired ||
      newState.orderingConfiguration.floorInventoryUnits !==
        prevState.orderingConfiguration.floorInventoryUnits)
  ) {
    payload.orderingConfiguration = newState.orderingConfiguration as OrderingConfiguration
  }

  if (
    isCategoryConfigurationVisible &&
    showInventorySettings &&
    (newState.inventoryConfiguration.backInventoryUnits !==
      prevState.inventoryConfiguration?.backInventoryUnits ||
      newState.inventoryConfiguration.floorInventoryRequired !==
        prevState.inventoryConfiguration?.floorInventoryRequired ||
      newState.inventoryConfiguration.floorInventoryUnits !==
        prevState.inventoryConfiguration?.floorInventoryUnits)
  ) {
    payload.inventoryConfiguration = {
      ...newState.inventoryConfiguration,
    } as InventoryConfiguration
  }
  if (newState.primaryMappings.length) {
    payload.addedPrimaryMappings = newState.primaryMappings.map(
      ({ _id }) => _id,
    )
  }

  return payload
}

export default function useUpdateCategory() {
  const { properties, showInventorySettings } = useGetProperties()
  const guideStructureId = properties?._id
  const isCategoryConfigurationVisible = getFlagValue(
    useFeatureFlags().isGuideOrganizationCategoryConfigurationEnabled,
  )
  const [mutation, result] = useMutation<
    UpdateGuideCategoryMutation,
    UpdateGuideCategoryMutationVariables
  >(UPDATE_GUIDE_STRUCTURE_CATEGORY, {
    errorPolicy: 'none',
    onError: ({ graphQLErrors, networkError }) => {
      if (graphQLErrors.length) {
        throw new Error(msgByErrorCode[getFirstErrorCode(graphQLErrors)])
      }

      if (networkError) {
        Sentry.captureMessage('Web Portal Network Error', {
          level: 'warning',
          extra: {
            networkError: JSON.stringify(networkError),
          },
        })

        throw new Error(
          `Unable to save due to the following network error: ${networkError}`,
        )
      }
    },
    refetchQueries: [
      'getGuideStructureCategories',
      'getGuideStructureProperties',
    ],
  })

  return tuple([
    useCallback(
      async (
        newState: CreateEditSectionState,
        prevState: GuideStructureCategory,
      ) => {
        if (!guideStructureId) {
          throw new Error('Guide structure ID not found')
        }

        await mutation({
          variables: {
            ...derivePayloadFromUpdatedFields(
              newState,
              prevState,
              isCategoryConfigurationVisible,
              showInventorySettings,
            ),
            guideStructureId,
          },
        })
      },
      [
        guideStructureId,
        mutation,
        isCategoryConfigurationVisible,
        showInventorySettings,
      ],
    ),
    result,
  ])
}
