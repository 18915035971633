import {
  EndOrderDeliveryRecurringSchedulesMutation,
  EndOrderDeliveryRecurringSchedulesMutationVariables,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import { RECURRING_ORDER_DELIVERY_EVENT_FIELDS } from 'app/packages/storeSolutions/schedules/api/fragments/RecurringOrderDeliveryEvent'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const DELETE_RECURRING_SCHEDULES = gql`
  ${RECURRING_ORDER_DELIVERY_EVENT_FIELDS}
  mutation endOrderDeliveryRecurringSchedules(
    $scheduleId: ID!
    $recurringScheduleIds: [ID!]!
  ) {
    endOrderDeliveryRecurringSchedules(
      input: {
        scheduleId: $scheduleId
        recurringScheduleIds: $recurringScheduleIds
      }
    ) {
      orderDeliveryRecurringSchedules {
        ...RecurringOrderDeliveryEventFields
      }
    }
  }
`

export default function useDeleteRecurringOrderDeliverySchedules() {
  const { scheduleId } = useGetOrderDeliverySchedule()

  const [deleteRecurringOrderDeliveryEvent, result] = useMutation<
    EndOrderDeliveryRecurringSchedulesMutation,
    EndOrderDeliveryRecurringSchedulesMutationVariables
  >(DELETE_RECURRING_SCHEDULES, {
    refetchQueries: ['recurringOrders', 'getOrderDeliveryScheduleIssues'],
    awaitRefetchQueries: true,
  })

  return tuple([
    useCallback(
      async (recurringScheduleIds: string[]) => {
        const { errors } = await deleteRecurringOrderDeliveryEvent({
          variables: { scheduleId: scheduleId!, recurringScheduleIds },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [deleteRecurringOrderDeliveryEvent, scheduleId],
    ),
    result,
  ])
}
