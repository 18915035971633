import {
  UserCustomerFragment,
  UserDepartmentBundleFragment,
  UserDepartmentFragment,
  UserDetailsFragment,
  UserStoreFragment,
} from '@api/__gen__/gql'
import React, { ReactElement, useCallback, useState } from 'react'

export interface User {
  _id: UserDetailsFragment['_id']
  id: UserDetailsFragment['id']
  accessRole: UserDetailsFragment['accessRole']
  email: UserDetailsFragment['email']
  userScope: UserDetailsFragment['userScope']
  departmentBundles: UserDepartmentBundleFragment[]
  customer: undefined | UserCustomerFragment
  store: undefined | UserStoreFragment
  department: undefined | UserDepartmentFragment
}

export interface UserContextValue {
  /**
   * Set the user with a raw API user model and return the transformed User model.
   */
  setUser: (user: UserDetailsFragment) => User
  user?: User
}

const UserContext = React.createContext<UserContextValue | undefined>(undefined)

export function useUserContext() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserContextProvider')
  }
  return context
}

const getValueForUser = (user: UserDetailsFragment): User => ({
  _id: user._id,
  id: user.id,
  accessRole: user.accessRole,
  email: user.email,
  userScope: user.userScope,
  departmentBundles: user.departmentBundles,
  customer: 'customer' in user ? user.customer : undefined,
  store:
    // eslint-disable-next-line no-nested-ternary
    'store' in user
      ? user.store
      : 'maybeStore' in user
      ? user.maybeStore ?? undefined
      : undefined,
  department: 'department' in user ? user.department : undefined,
})

export const UserContextProvider = ({
  children,
}: {
  children: ReactElement
}) => {
  const [user, setUserState] = useState<undefined | User>()

  const setUser = useCallback(
    (userRaw: UserDetailsFragment): User => {
      const transformedUser = getValueForUser(userRaw)
      setUserState(transformedUser)
      return transformedUser
    },
    [setUserState],
  )
  const value = {
    user,
    setUser,
  }
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
