import { Divider, InputAdornment } from '@mui/material'
import FallbackLoader from 'app/components/FallbackLoader/FallbackLoader'
import CautionIcon from 'app/components/Icons/CautionSystemStatusFilled'
import ErrorIcon from 'app/components/Icons/ErrorSystemStatus'
import SingleSelect from 'app/components/SingleSelect/SingleSelect'
import TextField from 'app/components/TextField/TextField'
import { isNilOrEmpty } from 'app/helpers'
import {
  CATEGORY_NAME_MAX_LIMIT,
  CATEGORY_NAME_SUGGESTED_CUTOFF_LIMIT,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.constants'
import { GuideStructureCategory } from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import useGetCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetCategories'
import useGetParentCategories from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetParentCategories'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import {
  CreateEditSectionState,
  StyledP,
} from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import { toRem } from 'app/styles'
import React from 'react'
import { Option } from 'shared/types'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const FIXED_SELECT_WIDTH = 320

const StyledLoader = styled(FallbackLoader)`
  height: ${toRem(240)};
`

const StyledH4 = styled.h4`
  margin: 0;
`

const StyledHelperText = styled.span`
  align-items: center;
  display: inline-flex;
  height: 20px;
  justify-content: space-between;
  width: 100%;
`

const StyledSectionName = styled(TextField)`
  margin: 0;
  width: ${toRem(344)};
`

function useSortIndexOptions(existingSortIndex?: number): Option[] {
  const { categories, minSortIndex } = useGetCategories()

  return categories.reduce(
    (options: Option[], category, index) => {
      if (isNilOrEmpty(category.itemCategory)) {
        return options
      }

      const { name, sortIndex } = category.itemCategory
      if (existingSortIndex === sortIndex) {
        return options
      }

      const nextCategory = categories[index + 1]
      if (nextCategory) {
        options.push({
          label: `after ${name}`,
          value: String(nextCategory.itemCategory?.sortIndex),
        })
      }

      return options
    },
    [
      {
        label: 'Top of list',
        value: String(minSortIndex),
      },
    ],
  )
}

function useParentCategoryOptions() {
  const { loading, parentCategories } = useGetParentCategories()

  return {
    loading,
    parentCategoryOptions: parentCategories.reduce(
      (options: Option[], category) => {
        if (isNilOrEmpty(category?.node?.itemCategory)) {
          return options
        }

        const { _id, name } = category!.node!.itemCategory!

        options.push({
          label: name,
          value: _id,
        })

        return options
      },
      [],
    ),
  }
}

const HelperText = ({
  charLength,
  isDuplicate,
}: {
  charLength: number
  isDuplicate: boolean
}) => {
  let text = ''

  if (
    charLength > CATEGORY_NAME_SUGGESTED_CUTOFF_LIMIT &&
    charLength <= CATEGORY_NAME_MAX_LIMIT
  ) {
    text = '25 characters or less is recommended.'
  }

  if (charLength > CATEGORY_NAME_MAX_LIMIT) {
    text = 'Section names cannot be longer than 40 characters.'
  }

  if (isDuplicate) {
    text = 'This section name exists. Please create a unique name.'
  }

  const charLimitValue =
    charLength > CATEGORY_NAME_MAX_LIMIT
      ? CATEGORY_NAME_MAX_LIMIT - charLength
      : charLength

  return (
    <StyledHelperText>
      <span>{text}</span>
      <span>{`${charLimitValue}/${CATEGORY_NAME_MAX_LIMIT}`}</span>
    </StyledHelperText>
  )
}

const InputAdornmentIcon = ({
  charLength,
  isDuplicate,
}: {
  charLength: number
  isDuplicate: boolean
}) => {
  if (
    charLength > CATEGORY_NAME_SUGGESTED_CUTOFF_LIMIT &&
    charLength <= CATEGORY_NAME_MAX_LIMIT
  ) {
    return (
      <InputAdornment position="end">
        <CautionIcon />
      </InputAdornment>
    )
  }

  if (charLength > CATEGORY_NAME_MAX_LIMIT || isDuplicate) {
    return (
      <InputAdornment position="end">
        <ErrorIcon />
      </InputAdornment>
    )
  }

  return null
}

interface SectionNameProps {
  categoryEditContext?: GuideStructureCategory
  isDuplicateCategoryName: boolean
  onChange: (producer: (draft: CreateEditSectionState) => void) => void
  sectionData: CreateEditSectionState
  isUncategorized: boolean
  viewOnly: boolean
}

export default function SectionName({
  categoryEditContext,
  isDuplicateCategoryName,
  onChange,
  sectionData,
  isUncategorized,
  viewOnly,
}: SectionNameProps) {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const { loading, parentCategoryOptions } = useParentCategoryOptions()
  const sortIndexOptions = useSortIndexOptions(
    Number(categoryEditContext?.itemCategory.sortIndex),
  )

  const isEditing = !!categoryEditContext

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((draft) => {
      const { name, value } = event.target
      draft[name as 'categoryName' | 'sortIndex' | 'parentId'] = value
    })
  }

  const charLength = sectionData.categoryName.length

  if (isUncategorized) {
    return (
      <>
        <StyledP>
          Section Name cannot be edited for this Section. <br />
          <strong>Uncategorized</strong>
        </StyledP>
        <StyledP>
          Section Sort Order cannot be edited for this Section. <br />
          <strong>Bottom of the List</strong>
        </StyledP>
      </>
    )
  }

  return (
    <>
      {loading && <StyledLoader />}
      {!loading && (
        <>
          <StyledP>
            Set section name and additional settings for the section here.
          </StyledP>
          <StyledSectionName
            error={
              charLength > CATEGORY_NAME_MAX_LIMIT || isDuplicateCategoryName
            }
            name="categoryName"
            helperText={
              <HelperText
                charLength={charLength}
                isDuplicate={isDuplicateCategoryName}
              />
            }
            label="Section Name"
            onBlur={() => {
              segment.guideOrganizationSectionEditorModalSectionNameValueChanged(
                {
                  isEditing,
                  ...(isEditing
                    ? {
                        previousSectionName:
                          categoryEditContext.itemCategory.name,
                      }
                    : {}),
                  sectionName: sectionData.categoryName,
                  ...defaultProperties,
                },
              )
            }}
            onChange={handleChange}
            placeholder="Enter Section Name"
            required
            disabled={viewOnly}
            InputProps={{
              endAdornment: (
                <InputAdornmentIcon
                  charLength={charLength}
                  isDuplicate={isDuplicateCategoryName}
                />
              ),
            }}
            value={sectionData.categoryName}
          />
          <SingleSelect
            handleChange={handleChange}
            name="sortIndex"
            onBlur={() => {
              segment.guideOrganizationSectionEditorModalSortThisToValueChanged(
                {
                  isEditing,
                  ...(isEditing
                    ? {
                        previousSortIndex: String(
                          categoryEditContext.itemCategory.sortIndex,
                        ),
                      }
                    : {}),
                  sortIndex: sectionData.sortIndex,
                  ...defaultProperties,
                },
              )
            }}
            options={sortIndexOptions}
            label="Sort this to"
            required
            disabled={viewOnly}
            value={sectionData.sortIndex}
            width={FIXED_SELECT_WIDTH}
          />
          <Divider />
          <StyledH4>Required Settings</StyledH4>
          <SingleSelect
            handleChange={handleChange}
            name="parentId"
            onBlur={() => {
              segment.guideOrganizationSectionEditorModalParentCategoryValueChanged(
                {
                  isEditing,
                  ...(isEditing
                    ? {
                        previousParentCategoryId:
                          categoryEditContext.itemCategory.parentId,
                      }
                    : {}),
                  parentCategoryId: sectionData.parentId || null,
                  ...defaultProperties,
                },
              )
            }}
            options={parentCategoryOptions}
            label="Parent Category"
            required
            disabled={viewOnly}
            value={sectionData.parentId || ''}
            width={FIXED_SELECT_WIDTH}
          />
        </>
      )}
    </>
  )
}
