import { GuideStructureItemFieldsFragment } from '@api/__gen__/gql'
import { getStickyRows } from 'app/components/Tables/Table.helpers'
import { ROW_HEIGHT_NUMBER } from 'app/components/VirtualizedList/VirtualizedList.constants'
import { EM_DASH } from 'app/constants'
import useGetItems from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetItems'
import {
  GuideStructureItem,
  GuideStructurePaginationArgs,
} from 'app/packages/storeSolutions/guideOrganization/GuideOrganization.types'
import { groupBy } from 'lodash-es'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const StyledTableColumnHeader = styled.span`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.neutral_07};
  box-sizing: border-box;
  display: inline-flex;
  height: 100%;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`

const StyledItemHeader = styled(StyledTableColumnHeader)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(280)}`};
  margin-left: ${({ theme }) => theme.typography.pxToRem(52)};
`

const StyledOrderCodeHeader = styled(StyledTableColumnHeader)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(132)}`};
`

const StyledMajorCategoryHeader = styled(StyledTableColumnHeader)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(175)}`};
`

const StyledCategoryHeader = styled(StyledTableColumnHeader)`
  flex: ${({ theme }) => `0 0 ${theme.typography.pxToRem(175)}`};
`

const makeColumns = () => [
  {
    accessor: 'item',
    Header: () => <StyledItemHeader>Item</StyledItemHeader>,
  },
  {
    accessor: 'vendorItemId',
    Header: () => <StyledOrderCodeHeader>Order Code</StyledOrderCodeHeader>,
  },
  {
    accessor: 'itemClass',
    Header: () => (
      <StyledMajorCategoryHeader>Major Category</StyledMajorCategoryHeader>
    ),
  },
  {
    accessor: 'category',
    Header: () => <StyledCategoryHeader>Category</StyledCategoryHeader>,
  },
  {
    accessor: 'substitutes',
    Header: () => (
      <StyledTableColumnHeader>Substitutes</StyledTableColumnHeader>
    ),
  },
  {
    accessor: 'status',
    Header: ({ isDraft }: { isDraft: boolean }) => {
      return isDraft ? (
        <StyledTableColumnHeader> Draft Status</StyledTableColumnHeader>
      ) : null
    },
  },
]

function format(items: { node?: GuideStructureItemFieldsFragment | null }[]) {
  return items.reduce((result: GuideStructureItem[], { node }) => {
    if (!node) {
      return result
    }

    const {
      _id,
      afreshItemId,
      afreshItemName,
      category,
      categoryReason,
      class: itemClass,
      hasBeenEdited,
      orderableOptions,
      subclass,
    } = node

    result.push({
      afreshItemId,
      afreshItemName,
      category: category.name,
      categoryReason,
      hasBeenEdited,
      id: _id,
      itemClass: itemClass?.description ?? '',
      itemSubClass: subclass?.description ?? '',
      orderableOptions: orderableOptions.map((option) => ({
        caseSize: option.caseSize,
        caseUnits: option.caseUnits,
        id: option._id,
        name:
          option.orderGuideDisplayName ||
          option.name ||
          afreshItemName ||
          EM_DASH,
        orderCode: option.orderCode || '',
      })),
      variableHeight: Math.max(
        orderableOptions.length * ROW_HEIGHT_NUMBER,
        ROW_HEIGHT_NUMBER,
      ),
    })

    return result
  }, [])
}

export default function useGetItemsTableData(
  args?: GuideStructurePaginationArgs,
) {
  const { items, ...rest } = useGetItems(args)

  const columns = useMemo(makeColumns, [])

  const data = useMemo(() => {
    return getStickyRows(groupBy(format(items), (item) => item.category))
  }, [items])

  return {
    ...rest,
    columns,
    data,
  }
}
