import styled from 'styled-components'

export default styled.main<{
  $open: boolean
  $showTopBar?: boolean
  $hideSideNav?: boolean
}>`
  flex-grow: 1;
  min-width: ${({ theme }) => theme.typography.pxToRem(1100)};
  ${({ $showTopBar, theme }) =>
    $showTopBar &&
    `
    padding-top: ${theme.typography.pxToRem(56)};
  `}
  padding-left: 0;
  transition: ${({ theme }) =>
    `${theme.transitions.create(['padding-left'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    })}`};

  ${({ $open, $hideSideNav, theme }) =>
    !$open &&
    !$hideSideNav &&
    `
    padding-left: ${theme.typography.pxToRem(24)}; 
    transition: 
      ${theme.transitions.create(['padding-left'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      })};
  `}
`
