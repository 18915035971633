import { DayOfWeek } from '@api/__gen__/gql'
import { useReactiveVar } from '@apollo/client'
import MultiSelect from 'app/components/MultiSelect/MultiSelect'
import useCreateRecurringSchedules from 'app/packages/storeSolutions/schedules/api/mutations/useCreateOrderDeliveryRecurringSchedules'
import { useGetRecurringOrders } from 'app/packages/storeSolutions/schedules/api/queries/useGetRecurringOrders'
import useStoreIdsSearchParam from 'app/packages/storeSolutions/schedules/hooks/useStoreIdsSearchParam'
import { BulkEditDoneButton } from 'app/packages/storeSolutions/schedules/pages/BulkEdit/BulkEditDoneButton'
import { useSetBulkEditMatchingEventsForRecurring } from 'app/packages/storeSolutions/schedules/pages/BulkEdit/useSetBulkEditMatchingEvents.hook'
import StoreName from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/StoreName'
import RecurringScheduleForm from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/RecurringScheduleForm/RecurringScheduleForm'
import { RecurringSchedulesTableCell } from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/RecurringSchedulesTableCell'
import { useGetTableColumns } from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/useGetTableColumns.hook'
import {
  ITEM_HEIGHT,
  MIN_ROW_HEIGHT,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { StoreRecurringScheduleRow } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { $schedulesBulkEditRecurringStores } from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import Table from 'app/packages/storeSolutions/schedules/pages/Table/Table'
import { useGetVisibleData } from 'app/packages/storeSolutions/schedules/pages/Table/useGetVisibleData'
import React, { CSSProperties, useCallback, useMemo } from 'react'
import { Row } from 'react-table'
import styled from 'styled-components'

const RECURRING_SETTINGS_PADDING = 32

const StyledTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`

export const StyledRow = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  display: flex;
  width: 100%;

  & > :not(:first-child) {
    flex: 1;
  }

  & > :first-child {
    align-items: flex-start;
    cursor: auto;
    flex: 0 0 316px;
    font-weight: normal;
    height: 100%;
  }
`

const StyledBulkEditDoneButton = styled(BulkEditDoneButton)`
  margin-left: auto;
`

export default function RecurringSchedulesTable() {
  const columns = useGetTableColumns()
  const { loading, recurringOrdersData } = useGetRecurringOrders()
  const bulkEditStores = useReactiveVar($schedulesBulkEditRecurringStores)

  const [
    createRecurringSchedules,
    { loading: isSavingSchedule },
  ] = useCreateRecurringSchedules()

  const [selectedStoreIds, setSelectedStoreIds] = useStoreIdsSearchParam()

  useSetBulkEditMatchingEventsForRecurring(bulkEditStores, recurringOrdersData)

  const recurringOrdersRows: StoreRecurringScheduleRow[] = useMemo(() => {
    return recurringOrdersData.map((storeSchedule) => {
      const totalOrders = storeSchedule.recurringOrders.length
      return {
        ...storeSchedule,
        itemHeight: Math.max(
          totalOrders * ITEM_HEIGHT + RECURRING_SETTINGS_PADDING,
          MIN_ROW_HEIGHT,
        ),
      }
    })
  }, [recurringOrdersData])

  const visibleData = useGetVisibleData(
    bulkEditStores,
    recurringOrdersRows,
    selectedStoreIds,
  )

  const isBulkEditing = bulkEditStores.length !== 0

  const renderRow = useCallback(
    (row: Row<StoreRecurringScheduleRow>, style: CSSProperties) => {
      const { storeScheduleId, store, recurringOrders } = row.original

      const handleOnAdd = async (
        orderDay: DayOfWeek,
        deliveryDay: DayOfWeek,
      ) => {
        const storeIds = isBulkEditing
          ? bulkEditStores.map((s) => s.id)
          : [store.id]

        await createRecurringSchedules(orderDay, deliveryDay, storeIds)
      }

      return (
        <StyledRow {...row.getRowProps({ style })}>
          {row.cells.map((_, index) => {
            return index === 0 ? (
              <StoreName
                key={store.id}
                addForm={
                  <RecurringScheduleForm
                    isSaving={isSavingSchedule}
                    recurringOrders={recurringOrders}
                    onSave={handleOnAdd}
                  />
                }
                ariaLabel={`Add a new recurring order to ${store.name}`}
                bulkEditStores={bulkEditStores}
                onSelectStores={(stores) => {
                  $schedulesBulkEditRecurringStores(stores)
                }}
                store={store}
                storeScheduleId={storeScheduleId}
              />
            ) : (
              <RecurringSchedulesTableCell
                key={row.id}
                recurringOrders={recurringOrders}
                storeName={store.name}
              />
            )
          })}
        </StyledRow>
      )
    },
    [bulkEditStores, createRecurringSchedules, isBulkEditing, isSavingSchedule],
  )

  const allStores = useMemo(() => {
    return recurringOrdersData.map((d) => d.store)
  }, [recurringOrdersData])

  return (
    <>
      <StyledTableHeader>
        {!isBulkEditing && (
          <MultiSelect
            inputLabel="Stores"
            loading={loading}
            options={allStores}
            onFilterOptions={setSelectedStoreIds}
            selectedOptions={selectedStoreIds}
          />
        )}

        {isBulkEditing && (
          <StyledBulkEditDoneButton
            onDone={() => $schedulesBulkEditRecurringStores([])}
          />
        )}
      </StyledTableHeader>
      <Table
        columns={columns}
        data={visibleData}
        isLoading={loading}
        renderRow={renderRow}
        type="DYNAMIC"
      />
    </>
  )
}
