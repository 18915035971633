import { gql } from '@apollo/client'

export const DEPARTMENT_BUNDLE_FIELDS = gql`
  fragment DepartmentBundleFields on DepartmentBundle {
    _id
    id
    name
    type
    departments {
      _id
      id
      name
    }
    products {
      type
    }
  }
`
