import Button from 'app/components/Button/Button'
import Add from 'app/components/Icons/Add'
import useToggleState from 'app/hooks/useToggleState'
import useGetProperties from 'app/packages/storeSolutions/guideOrganization/api/queries/useGetProperties'
import { useGuideOrganizationInitializeProperties } from 'app/packages/storeSolutions/guideOrganization/hooks/useGuideOrganizationInitializeProperties'
import EditorModal from 'app/packages/storeSolutions/guideOrganization/pages/Sections/EditorModal/EditorModal'
import React from 'react'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  margin-left: auto;
`

export default function CreateSection() {
  const defaultProperties = useGuideOrganizationInitializeProperties()

  const { properties } = useGetProperties()
  const [opened, { toggle }] = useToggleState()

  const isDraft = properties?.status === 'DRAFT'

  if (!isDraft) {
    return null
  }

  const handleOnClick = () => {
    segment.guideOrganizationCreateSectionButtonClicked(defaultProperties)
    toggle()
  }

  return (
    <>
      <StyledButton onClick={handleOnClick} startIcon={<Add />}>
        Create Section
      </StyledButton>
      {opened && <EditorModal onClose={toggle} />}
    </>
  )
}
