import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0702 8.03021V7.44021C20.0702 6.65021 19.4302 6.02021 18.6502 6.02021H11.8402C11.3202 6.02021 10.8302 5.73021 10.5902 5.27021L10.3002 4.72021C10.0502 4.26021 9.57016 3.97021 9.05016 3.97021H5.33016C4.54016 3.97021 3.91016 4.61021 3.91016 5.39021V8.03021H20.0702Z"
      fill="currentColor"
    />
    <path
      d="M3.87988 9.1001V18.6101C3.87988 19.4001 4.51988 20.0301 5.29988 20.0301H18.6399C19.4299 20.0301 20.0599 19.3901 20.0599 18.6101V9.1001H3.87988Z"
      fill="currentColor"
    />
  </svg>,
  'GuideOrganization',
)
