import { useReactiveVar } from '@apollo/client'
import Button from 'app/components/Button/Button'
import Delete from 'app/components/Icons/Delete'
import Edit from 'app/components/Icons/Edit'
import { Popover } from 'app/components/Popover/Popover'
import { isNilOrEmpty } from 'app/helpers'
import useDeleteRecurringOrderDeliverySchedules from 'app/packages/storeSolutions/schedules/api/mutations/useDeleteOrderDeliveryRecurringSchedules'
import useUpdateRecurringSchedules from 'app/packages/storeSolutions/schedules/api/mutations/useUpdateOrderDeliveryRecurringSchedules'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import DeleteConfirmationModal from 'app/packages/storeSolutions/schedules/pages/DeleteConfirmationModal/DeleteConfirmationModal'
import { CELL_DISPLAY_VALUE } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/useGetTableColumns.hook'
import RecurringScheduleForm from 'app/packages/storeSolutions/schedules/pages/RecurringSchedulesTable/RecurringScheduleForm/RecurringScheduleForm'
import {
  DayOfWeek,
  DayOfWeekValues,
  RecurringOrder,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import {
  $schedulesBulkEditRecurringMatchingEvents,
  $schedulesBulkEditRecurringStores,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import React, { useState } from 'react'
import styled from 'styled-components'

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
`

const Flex = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 16px;
  width: 100%;
`

const FlexOrderDay = styled(Flex)`
  flex: 0 0 280px;
  padding-right: 0;
`

const DeliveryLag = styled(Flex)`
  justify-content: center;
`

const DashedLine = styled(Flex)`
  border: ${({ theme }) => `1px dashed ${theme.colors.neutral_05}`};
  width: 100%;
  height: 1px;
  margin-left: 12px;
`

const DisplayDay = styled.div`
  margin-left: 8px;
  position: relative;
`

const StyledActions = styled(Flex)`
  flex-direction: row;
`

const StyledButton = styled(Button)`
  margin: 0 16px;
  padding: 0;
`

const StyledStoreList = styled.ul`
  list-style: none;
  margin: 16px 0;
  max-height: 180px;
  overflow-y: auto;
  padding: 0;
`

export interface RecurringOrderDeliveryEventProps {
  order: RecurringOrder
  recurringOrders: RecurringOrder[]
  storeName: string
}

export default function RecurringOrderDeliveryEvent({
  order,
  recurringOrders,
  storeName,
}: RecurringOrderDeliveryEventProps) {
  const { isEditing } = useGetOrderDeliverySchedule()
  const bulkEditStores = useReactiveVar($schedulesBulkEditRecurringStores)
  const bulkEditMatchingEvents = useReactiveVar(
    $schedulesBulkEditRecurringMatchingEvents,
  )
  const [
    deleteRecurringSchedules,
    { loading },
  ] = useDeleteRecurringOrderDeliverySchedules()
  const [
    updateRecurringSchedules,
    { loading: isUpdateSaving },
  ] = useUpdateRecurringSchedules()

  const [errorWhileDeleting, setErrorWhileDeleting] = useState('')
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = useState(false)

  const handleOnUpdate = async (
    orderDay: DayOfWeek,
    deliveryDay: DayOfWeek,
  ) => {
    const bulkEditScheduleIds = bulkEditMatchingEvents[order.id]
    const scheduleIds = bulkEditScheduleIds ?? [order.id]

    await updateRecurringSchedules(scheduleIds, orderDay, deliveryDay)
  }

  const handleOnDelete = async () => {
    try {
      if (errorWhileDeleting) {
        setErrorWhileDeleting('')
      }

      const recurringScheduleIds = bulkEditMatchingEvents[order.id] ?? [
        order.id,
      ]
      await deleteRecurringSchedules(recurringScheduleIds)
    } catch (error) {
      setErrorWhileDeleting((error as Error).message)
    }
  }

  const toggleModal = () => {
    setShowDeleteConfirmationModal((prev) => !prev)
  }

  const storeNames = isNilOrEmpty(bulkEditStores)
    ? [storeName!]
    : bulkEditStores?.map(({ name }) => name)

  return (
    <>
      <CellContainer>
        <FlexOrderDay>
          {CELL_DISPLAY_VALUE.ORDER}
          <DisplayDay>{DayOfWeekValues[order.orderDay]}</DisplayDay>
          <DashedLine />
        </FlexOrderDay>
        <Flex>
          {CELL_DISPLAY_VALUE.DELIVERY}
          <DisplayDay>{DayOfWeekValues[order.deliveryDay]}</DisplayDay>
        </Flex>

        <DeliveryLag>{order.deliveryLag}</DeliveryLag>

        <StyledActions>
          {isEditing && (
            <>
              <Popover
                anchorPlacement="bottom"
                renderAnchor={({ onClick }) => (
                  <StyledButton
                    aria-label="Delete recurring order"
                    onClick={onClick}
                    startIcon={<Edit />}
                    variant="tertiary">
                    Edit
                  </StyledButton>
                )}
                withOverlay>
                <RecurringScheduleForm
                  initialOrderDay={order.orderDay}
                  initialDeliveryDay={order.deliveryDay}
                  isSaving={isUpdateSaving}
                  onSave={handleOnUpdate}
                  recurringOrders={recurringOrders}
                />
              </Popover>
              <StyledButton
                aria-label="Delete recurring order"
                onClick={toggleModal}
                startIcon={<Delete />}
                variant="tertiary">
                Delete
              </StyledButton>
            </>
          )}
        </StyledActions>
      </CellContainer>

      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          confirmationMessage={
            <>
              Delete recurring order on{' '}
              <strong>{DayOfWeekValues[order.orderDay]}</strong> and recurring
              delivery on <strong>{DayOfWeekValues[order.deliveryDay]}</strong>{' '}
              for the following stores?
              <StyledStoreList>
                {storeNames.map((store) => {
                  return <li key={store}>{store}</li>
                })}
              </StyledStoreList>
            </>
          }
          errorMessage={errorWhileDeleting}
          isLoading={loading}
          onClose={toggleModal}
          onDone={handleOnDelete}
          title="Delete Recurring Order and Delivery"
        />
      )}
    </>
  )
}
