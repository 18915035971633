import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/react'
import { initializeAnalytics } from 'app/Analytics'
import { Customer } from 'app/CustomerContext'
import { PORTAL_ACCESS_ROLES } from 'app/constants'
import {
  setActiveCustomerForFeatureFlags,
  setUserForFeatureFlags,
} from 'app/context/rolloutContext'
import { User } from 'app/context/userContext'
import { parseJWT } from 'app/helpers/parseJWT'
import { AuthenticationState } from 'app/state/authentication'

/**
 * Initialize the authenticated user with all our 3rd party SDKs and services.
 *
 * This should be done when the user authenticates, as well as each time the
 * app is opened.
 */
export const initializeUserWithExternalSDKs = (user: User) => {
  // tell Sentry who the user is
  Sentry.setUser({
    id: user._id,
    email: user.email,
    customer: user.customer?.key,
  })

  datadogRum.setUser({
    accessRole: user.accessRole,
    email: user.email,
    userId: user.id,
    userScope: user.userScope,
    customer: user.customer?.key,
  })

  setUserForFeatureFlags(user)

  initializeAnalytics(
    user.id,
    user.userScope,
    user.accessRole,
    user.customer?.id,
    user.customer?.key,
    user.department?.key,
    user.store?.id,
  )
}

/**
 * Initialize the authenticated user with all our 3rd party SDKs and services.
 *
 * This should be done when the user authenticates, as well as each time the
 * app is opened.
 */
export const reidentifyUserWithExternalSDKs = (
  user: User,
  customer: Customer,
) => {
  // tell Sentry who the user is
  Sentry.setUser({ id: user._id, email: user.email, customer: customer?.key })

  datadogRum.setUser({
    accessRole: user.accessRole,
    email: user.email,
    userId: user.id,
    userScope: user.userScope,
    customer: customer?.key,
  })

  if (customer) setActiveCustomerForFeatureFlags(customer.key)

  initializeAnalytics(
    user.id,
    user.userScope,
    user.accessRole,
    customer?.id,
    customer?.key,
    user.department?.key,
    user.store?.id,
  )
}

// Do all the things that are needed after we authenticate the user.
export async function applyAuthentication(
  authState: AuthenticationState,
  accessToken: string,
  refreshToken: string,
): Promise<boolean> {
  const as = authState
  const { accessRole } = parseJWT(accessToken)

  // permissions check
  if (!PORTAL_ACCESS_ROLES.includes(accessRole)) {
    return false
  }

  // causes useTokens hook to update value:
  await as.setTokens({
    access: accessToken,
    refresh: refreshToken,
  })

  // causes useIsAuthenticated hook to update value:
  as.isAuthenticated = true
  as.accessRole = accessRole

  return true
}
