import { Desk, SvgIconComponent } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Theme,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  DepartmentBundle,
  PRODUCT_BUNDLE_CONFIG_MAP,
  Product,
  ProductBundle,
  Workflow,
} from 'app/api/queries/useGetProductBundles'
import ChevronDown from 'app/components/Icons/ChevronDown'
import Settings from 'app/components/Icons/Settings'
import { stringifyParams } from 'app/helpers/queryString'
import { useDepartmentBundleId } from 'app/hooks/useDepartmentBundleId'
import useWorkflowId from 'app/hooks/useWorkflowId'
import {
  PRODUCT_BUNDLE_TYPE,
  productIconMap,
  productNameMap,
} from 'app/layouts/Customer/AppSideNav/AppSideNav.constants'
import BusinessLevelSelector from 'app/layouts/Customer/AppSideNav/BusinessLevelSelector'
import { matchRoutes } from 'app/layouts/Customer/CustomerPageLayout.helpers'
import { SECONDARY_SIDE_NAV_WIDTH } from 'app/layouts/Layout.constants'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import segment from 'src/__generated__/segment'
import styled from 'styled-components'

const SideNav = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  width: ${({ theme }) => theme.typography.pxToRem(SECONDARY_SIDE_NAV_WIDTH)};
  border-right: ${({ theme }) => `1px solid ${theme.colors.neutral_06}`};
`

const NavHeader = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.pxToRem(20)};
  font-weight: bold;
  margin-left: ${({ theme }) => theme.typography.pxToRem(16)};
  margin-top: ${({ theme }) => theme.typography.pxToRem(24)};
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(4)};
`

const DepartmentBundleAccordion = styled(Accordion)`
  width: ${({ theme }) => theme.typography.pxToRem(228)};
  margin-left: ${({ theme }) => theme.typography.pxToRem(6)};
  margin-right: ${({ theme }) => theme.typography.pxToRem(6)};
  border: none;
  box-shadow: none;

  margin-bottom: ${({ theme }) => theme.typography.pxToRem(4)};

  &.Mui-expanded {
    margin-left: ${({ theme }) => theme.typography.pxToRem(6)};
    margin-right: ${({ theme }) => theme.typography.pxToRem(6)};
  }

  &:before {
    display: none;
  }
`

const StyledDepartmentAccordionSummary = styled(AccordionSummary)<{
  $static: boolean
  $active: boolean
}>`
  border-radius: 4px;
  height: ${({ theme }) => theme.typography.pxToRem(40)};
  min-height: ${({ theme }) => theme.typography.pxToRem(40)};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  padding: ${({ theme }) =>
    `0 ${theme.typography.pxToRem(8)} 0 ${theme.typography.pxToRem(16)} `};
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }

  ${({ $static, theme }) =>
    $static &&
    `
    cursor: none;
    &:hover {
      background-color: ${theme.colors.neutral_08};
      cursor: default;

      &.Mui-expanded {
        cursor: default;
        background-color: ${theme.colors.neutral_08};
      }
    }
  `}

  ${({ $active, theme }) =>
    $active &&
    `
      background-color: ${theme.colors.highlight_07};
  `}
`

const ProductAccordion = styled(Accordion)`
  border: none;
  box-shadow: none;

  &:before {
    display: none;
  }
`

const StyledProductAccordionSummary = styled(AccordionSummary)`
  height: ${({ theme }) => theme.typography.pxToRem(40)};
  min-height: ${({ theme }) => theme.typography.pxToRem(40)};
  border-radius: 4px;
  padding: ${({ theme }) =>
    `0 ${theme.typography.pxToRem(8)} 0 ${theme.typography.pxToRem(16)} `};
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }

  transition: none;
  &.MuiAccordionSummary-content {
    max-width: ${({ theme }) => theme.typography.pxToRem(160)};
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0px;
`

const StyledNavLink = styled(Link)<{
  theme: Theme
  $active: boolean
  $isWorkflow?: boolean
}>`
  align-items: center;
  color: inherit;
  display: flex;
  height: 40px;
  padding-left: 16px;
  border-radius: 4px;
  text-decoration: none;

  ${({ $isWorkflow, theme }) =>
    $isWorkflow &&
    `
    padding-left: ${theme.typography.pxToRem(52)};
  `}

  ${({ $active, theme }) =>
    $active &&
    `
    background-color: ${theme.colors.highlight_03};
    font-weight: ${theme.typography.fontWeightBold};
  `}


  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }
`

const StyledNavItem = styled(Box)`
  align-items: center;
  column-gap: 10px;
  display: flex;

  &:active {
    color: ${({ theme }) => theme.colors.highlight_03};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral_07};
  }
`

export interface SideNavLinkProps {
  end?: boolean
  icon?: SvgIconComponent
  isWorkflow?: boolean
  hideActive?: boolean
  onClick?: () => void
  label: string
  to: string
}

interface SideNavLinkParams {
  product: Product
  departmentBundle?: DepartmentBundle
  workflow?: Workflow
  singleWorkflow?: boolean
}

function getProductSearchParams({
  departmentBundle,
  workflow,
}: {
  departmentBundle?: DepartmentBundle
  workflow?: Workflow
}): string {
  const params: { deptBundleId?: string; workflowId?: string } = {}
  if (departmentBundle) params.deptBundleId = departmentBundle.id
  if (workflow) params.workflowId = workflow.id
  return stringifyParams({
    ...params,
  })
}

function getLinkDetails({
  product,
  departmentBundle,
  workflow,
  singleWorkflow,
}: SideNavLinkParams) {
  return {
    name:
      workflow && !singleWorkflow
        ? workflow.name
        : productNameMap[product.type],
    icon: (!workflow || singleWorkflow) && productIconMap[product.type],
    to: {
      pathname: product.routes[0],
      search: getProductSearchParams({ departmentBundle, workflow }),
    },
    routes: product.routes,
  }
}

function checkIsActive(
  location: Location,
  routes: string[],
  { deptBundleId, workflowId }: { deptBundleId?: string; workflowId?: string },
) {
  let queryParamsMatch = true
  if (deptBundleId || workflowId) {
    const searchParams = new URLSearchParams(location.search)
    if (deptBundleId && deptBundleId !== searchParams.get('deptBundleId')) {
      queryParamsMatch = false
    }
    if (workflowId && workflowId !== searchParams.get('workflowId')) {
      queryParamsMatch = false
    }
  }
  return (
    routes.some((route) => location.pathname?.includes(route)) &&
    queryParamsMatch
  )
}

function SideNavLink({
  departmentBundle,
  product,
  workflow,
  singleWorkflow,
}: SideNavLinkParams) {
  const { name, to, icon, routes } = getLinkDetails({
    departmentBundle,
    product,
    workflow,
    singleWorkflow,
  })

  if (!to) return null
  const Icon = icon
  const isWorkflow = Boolean(workflow && !singleWorkflow)

  const handleOnClick = () => {
    segment.secondarySideNavLinkClicked({
      departmentBundleName: departmentBundle?.name ?? null,
      productName: name,
      workflowName: workflow?.name ?? null,
    })
  }

  return (
    <StyledNavLink
      data-testid={`product-link-${product.name}-${workflow?.name}`}
      key={to.pathname}
      onClick={handleOnClick}
      to={to}
      $active={checkIsActive(window.location, routes, {
        deptBundleId: departmentBundle?.id,
        workflowId: workflow?.id,
      })}
      $isWorkflow={isWorkflow}>
      <StyledNavItem>
        {Icon && <Icon sx={{ width: '18px' }} />}
        {name}
      </StyledNavItem>
    </StyledNavLink>
  )
}

function WorkflowAccordion({ product }: { product: Product }) {
  const { name, icon } = getLinkDetails({ product })
  const Icon = icon

  return (
    <StyledNavItem>
      {Icon && <Icon sx={{ width: '18px' }} />}
      {name}
    </StyledNavItem>
  )
}

function ProductAccordionWrapper({
  index,
  departmentBundle,
  product,
  activeWorkflowId,
}: {
  index: number
  departmentBundle: DepartmentBundle
  product: Product
  activeWorkflowId: string
}) {
  const firstLoad = useRef(true)
  const [expanded, setExpanded] = useState(
    product?.workflows?.some((workflow) => workflow.id === activeWorkflowId) &&
      matchRoutes(product.routes, window.location.pathname),
  )

  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded)
  }

  // Check whether the product accordion should be expanded by default when these params change
  useEffect(() => {
    const shouldExpand =
      product?.workflows?.some(
        (workflow) => workflow.id === activeWorkflowId,
      ) && matchRoutes(product.routes, window.location.pathname)
    if (firstLoad.current && shouldExpand) {
      setExpanded(shouldExpand)
      firstLoad.current = false
    }
    return () => {
      // reset ref when unmounting
      firstLoad.current = true
    }
  }, [product, activeWorkflowId])

  return (
    <ProductAccordion
      key={product.name + index}
      disableGutters
      elevation={0}
      expanded={expanded}
      onChange={handleAccordionChange}>
      <StyledProductAccordionSummary
        expandIcon={<ChevronDown />}
        aria-controls={`expand ${departmentBundle.name}`}
        id="panel1a-header">
        <WorkflowAccordion product={product} />
      </StyledProductAccordionSummary>
      <StyledAccordionDetails>
        {product.workflows?.map((workflow) => (
          <SideNavLink
            key={workflow.id}
            departmentBundle={departmentBundle}
            product={product}
            workflow={workflow}
          />
        ))}
      </StyledAccordionDetails>
    </ProductAccordion>
  )
}

function DepartmentBundleNav({
  departmentBundles,
}: {
  departmentBundles: DepartmentBundle[]
}) {
  const [activeDepartmentBundleId] = useDepartmentBundleId()
  const [activeWorkflowId] = useWorkflowId()

  const firstBundleName =
    departmentBundles.find(
      (departmentBundle) => departmentBundle.id === activeDepartmentBundleId,
    )?.name || departmentBundles[0]?.name

  const [expandedBundles, setExpandedBundles] = useState<string[] | undefined>([
    firstBundleName,
  ])

  // when department bundles change, set the first bundle to be expanded
  useEffect(() => {
    setExpandedBundles([firstBundleName])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentBundles])

  const allowCollapse =
    departmentBundles &&
    departmentBundles.length &&
    departmentBundles.length > 1

  const handleAccordionChange = (departmentName: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    if (allowCollapse)
      setExpandedBundles(
        isExpanded
          ? expandedBundles?.concat([...expandedBundles, departmentName])
          : expandedBundles?.filter((bundle) => bundle !== departmentName),
      )
  }
  return (
    <SideNav data-testid="secondary-side-nav">
      <NavHeader>Store Operations</NavHeader>
      <BusinessLevelSelector />
      {departmentBundles.map(
        (departmentBundle: DepartmentBundle, index: number) => {
          return (
            <DepartmentBundleAccordion
              key={departmentBundle.id}
              disableGutters
              elevation={0}
              expanded={expandedBundles?.includes(departmentBundle.name)}
              onChange={handleAccordionChange(departmentBundle.name)}>
              <StyledDepartmentAccordionSummary
                expandIcon={allowCollapse && <ChevronDown />}
                $active={departmentBundle.id === activeDepartmentBundleId}
                tabIndex={!allowCollapse ? -1 : undefined}
                $static={!allowCollapse}
                aria-controls={`expand ${departmentBundle.name}`}
                id={`department-accordion-summary-${index}`}>
                {departmentBundle.name}
              </StyledDepartmentAccordionSummary>
              <StyledAccordionDetails>
                {departmentBundle.products.map((product, productIndex) => {
                  if (product.workflows?.length === 1) {
                    return (
                      <SideNavLink
                        key={product.workflows[0].id}
                        departmentBundle={departmentBundle}
                        product={product}
                        workflow={product.workflows[0]}
                        singleWorkflow
                      />
                    )
                  }

                  if (
                    product.workflows?.length &&
                    product.workflows?.length > 1
                  ) {
                    return (
                      <ProductAccordionWrapper
                        key={product.name}
                        index={productIndex}
                        departmentBundle={departmentBundle}
                        product={product}
                        activeWorkflowId={activeWorkflowId}
                      />
                    )
                  }
                  return (
                    <SideNavLink
                      key={product.name}
                      departmentBundle={departmentBundle}
                      product={product}
                    />
                  )
                })}
                <Divider sx={{ marginTop: '4px' }} />
              </StyledAccordionDetails>
            </DepartmentBundleAccordion>
          )
        },
      )}
    </SideNav>
  )
}

export default function SecondarySideNav({
  activeProductBundle = PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS,
  productBundles,
}: {
  activeProductBundle?: PRODUCT_BUNDLE_TYPE
  productBundles?: ProductBundle[]
}) {
  if (
    !activeProductBundle ||
    !PRODUCT_BUNDLE_CONFIG_MAP[activeProductBundle].showSecondaryNav
  ) {
    return null
  }

  if (activeProductBundle === PRODUCT_BUNDLE_TYPE.DISTRIBUTION_CENTER) {
    return (
      <SideNav>
        <NavHeader>Upstream Insights</NavHeader>
        <StyledNavLink
          $active={window.location.pathname.includes('/dc/insights/items')}
          to="dc/insights/items">
          <StyledNavItem>
            <Desk />
            My Desk
          </StyledNavItem>
        </StyledNavLink>

        <StyledNavLink
          $active={window.location.pathname.includes('/dc/insights/settings')}
          to="dc/insights/settings">
          <StyledNavItem>
            <Settings />
            Settings
          </StyledNavItem>
        </StyledNavLink>
      </SideNav>
    )
  }

  const storeOperationsProductBundle = productBundles?.find(
    (bundle) => bundle.type === PRODUCT_BUNDLE_TYPE.STORE_OPERATIONS,
  )
  if (storeOperationsProductBundle?.departmentBundles) {
    return (
      <DepartmentBundleNav
        departmentBundles={storeOperationsProductBundle?.departmentBundles}
      />
    )
  }

  return null
}
