import {
  GetOpsInsightsDashboardQuery,
  GetOpsInsightsDashboardQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useCustomers } from 'app/CustomerContext'

export interface GetOpsInsightsLookerDashboardsArgs {
  portalDashboardId?: string
}

export const GET_OPS_INSIGHTS_LOOKER_DASHBOARDS = gql`
  query getOpsInsightsDashboard($customerId: ID!, $portalDashboardId: ID!) {
    node(id: $customerId) {
      _id
      ... on Customer {
        opsInsightsPortalDashboard(portalDashboardId: $portalDashboardId) {
          _id
          name
          categoryName
          lookerDashboards {
            embedUrl
          }
        }
        __typename
      }
      __typename
    }
  }
`

export default function useGetLookerDashboards({
  portalDashboardId: dashboardId,
}: GetOpsInsightsLookerDashboardsArgs) {
  const { activeCustomerId } = useCustomers()

  const { data, error, loading } = useQuery<
    GetOpsInsightsDashboardQuery,
    GetOpsInsightsDashboardQueryVariables
  >(GET_OPS_INSIGHTS_LOOKER_DASHBOARDS, {
    // Looker embed URLs expire after some fixed session length,
    // so we need to disable caching here to avoid
    // trying to render a stale embed url.
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (e) => {
      Sentry.captureMessage(
        `Failed to fetch dashboard for customer ${activeCustomerId}: ${e}`,
        {
          level: 'warning',
        },
      )
    },
    skip: !activeCustomerId || !dashboardId,
    variables: {
      customerId: activeCustomerId!,
      portalDashboardId: dashboardId!,
    },
  })

  return {
    portalDashboardDetails:
      data && data.node && data.node.__typename === 'Customer'
        ? data.node.opsInsightsPortalDashboard
        : null,
    isLoading: loading,
    error,
  }
}
