// @ts-nocheck
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined'
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper as StyledPaper,
  TextField,
} from '@mui/material'
import * as Sentry from '@sentry/react'
import { useVars } from 'app/VarsContext'
import BreadcrumbsNavigation from 'app/components/Breadcrumbs/Breadcrumbs'
import Button from 'app/components/Button/Button'
import Dropzone from 'app/components/Dropzone/Dropzone'
import Delete from 'app/components/Icons/Delete'
import { datalayer } from 'app/datalayer'
import { isNilOrEmpty } from 'app/helpers'
import usePrevious from 'app/hooks/usePrevious'
import CatchAll from 'app/packages/core/pages/CatchAll/CatchAll'
import { DASHBOARD_ROOT_URI } from 'app/packages/fileUploads/pages/dashboard/dashboard.constants'
import useFetchTaskConfigs from 'app/packages/fileUploads/pages/dashboard/useFetchTaskConfigs.hook'
import { isEqual, isNil } from 'lodash-es'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import fileUploadLimits from 'shared/fileUploadLimits'
import styled from 'styled-components'
import { getLineColor } from '../dashboard.helpers'
import FileUploadResults from './UploadResults/UploadResults'

const INIT_STATE = {
  activeStep: 0,
  files: [],
  details: {},
  err: null,
  result: null,
}

const StyledPage = styled(StyledPaper)`
  margin: 20px 0;
  padding: 20px;
`

const StyledButton = styled(Button)`
  margin-top: 24px;
`

const StyledH2 = styled.h2`
  font-weight: bold;
  margin: 0;
`

const StyledOutput = styled.div`
  background: ${({ theme }) => theme.colors.neutral_01};
  border-radius: 4px;
  margin: 16px 0 0;
  padding: 0;
  overflow: auto;
  height: 350px;
`

const StyledCode = styled.code`
  color: ${({ theme }) => theme.colors.validation};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: bold;
  font-family: monospace;
  height: 100%;
  display: block;
  white-space: pre;
  line-height: 1.6em;
`

const StyledTextField = styled(TextField)`
  margin-top: 24px;
  min-width: 304px;

  & input[type='number']::-webkit-inner-spin-button,
  & input[type='number']::-webkit-outer-spin-button {
    webkit-appearance: none;
    margin: 0;
  }
`

const StyledFormControlLabel = styled(FormControlLabel)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`

const StyledLoader = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.neutral_08};
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 12px;
  justify-content: center;
`

const StyledCircularLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.neutral_08};
`

// TODO: move to a client side datalayer
const postData = (apiUrl, files, details, script) => {
  const formData = new FormData()

  files.forEach((file, ix) => {
    formData.append(`file${ix ? ix.toString() : ''}`, file, file.name)
  })

  Object.keys(details).forEach((name) => {
    formData.append(name, details[name])
  })

  return datalayer.importScript.upload(apiUrl, formData, script)
}

const InputFields = ({ inputFieldDefinitions, onChange }) => {
  return inputFieldDefinitions.map((field) =>
    field.type === 'boolean' ? (
      <StyledFormControlLabel
        control={
          <Checkbox
            key={field.name}
            onChange={(e) => onChange({ [field.name]: e.target.checked })}
          />
        }
        label={field.label}
      />
    ) : (
      <StyledTextField
        {...field}
        key={field.name}
        onChange={(e) => {
          onChange(e.target.value ? { [field.name]: e.target.value } : {})
        }}
      />
    ),
  )
}

const Upload = ({ setState, config, state }) => {
  const [inputData, setInputData] = useState({})

  const next = () => {
    setState({
      ...state,
      activeStep: 1,
      details: inputData,
    })
  }

  const onChange = (data) => {
    setInputData(data)
  }

  const csvLimit = config.csvLimit || 1

  const canSubmit =
    !isNilOrEmpty(state.files) &&
    (!isNilOrEmpty(config.inputField) ? !isNilOrEmpty(inputData) : true)

  return (
    <StyledPage square>
      <Box>
        <StyledH2>{config.name}</StyledH2>
        {!!config.inputFieldDescription && (
          <p>{config.inputFieldDescription}</p>
        )}
      </Box>
      <Box>
        <h3>Select file(s) to upload</h3>
        {!!config.csvFieldDescription && <p>{config.csvFieldDescription}</p>}
      </Box>
      <Grid container>
        <Grid item xs={7}>
          <Dropzone
            acceptedFiles={config.acceptedFiles}
            description={config.dropzoneText || 'Drag and drop the files'}
            maxFiles={csvLimit}
            maxFileSize={fileUploadLimits.fileSize}
            onDrop={(items) => {
              setState({
                ...state,
                files: items.concat(state.files).slice(0, csvLimit),
              })
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <List dense>
            {state.files.map((file) => (
              <ListItem key={nanoid()}>
                <ListItemAvatar>
                  <Avatar>
                    <InsertDriveFileOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <Button
                    variant="icon"
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      setState({
                        ...state,
                        files: state.files.filter((f) => f !== file),
                      })
                    }}
                    size="large">
                    <Delete />
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        {config.inputField && (
          <InputFields
            inputFieldDefinitions={config.inputField}
            onChange={onChange}
          />
        )}
      </Grid>
      <StyledButton
        disabled={!canSubmit}
        variant="primary"
        color="primary"
        onClick={next}>
        Submit
      </StyledButton>
    </StyledPage>
  )
}

const PendingScreen = ({ state, script, config, setState }) => {
  const { apiUrl } = useVars()
  const { result, files, details, err } = state

  useEffect(() => {
    let timer
    postData(apiUrl, files, details, script)
      .then((response) => {
        if (response.error) {
          setState({
            err: `${response.error}: ${response.message}`,
            activeStep: 2,
          })
        } else {
          timer = setInterval(async () => {
            try {
              const pollStatusResult = await datalayer.importScript.status(
                apiUrl,
                response.id,
              )

              if (pollStatusResult.error) {
                clearInterval(timer)
                setState({
                  id: response.id,
                  err: `${pollStatusResult.error}: ${pollStatusResult.message}`,
                  activeStep: 2,
                })
                return
              }

              const firstResult = pollStatusResult[0]
              if (
                !isNilOrEmpty(firstResult) &&
                (firstResult.status === 'DONE' ||
                  firstResult.status === 'FAILED')
              ) {
                clearInterval(timer)
                setState({
                  id: response.id,
                  result: firstResult,
                  activeStep: 2,
                })
              }
            } catch (e) {
              Sentry.captureException(e)
              clearInterval(timer)
              setState({
                id: response.id,
                activeStep: 2,
                err: `Stopped watching because: ${e.message}`,
              })
            }
          }, 2000)
        }
      })
      .catch((e) => {
        Sentry.captureException(e)
        setState({
          err: `Networking error (${e.message})`,
          activeStep: 2,
        })
      })

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [files, details, script, setState, apiUrl])

  return (
    <>
      <StyledPage square>
        <Box>
          <StyledH2>Processing {config.name}</StyledH2>
          {config.processingHelpMessage && (
            <h3>{config.processingHelpMessage}</h3>
          )}
        </Box>
        {err ? (
          <StyledOutput>
            <StyledCode style={getLineColor('FAILED')}>Error:</StyledCode>
            <StyledCode style={getLineColor('FAILED')}>{err}</StyledCode>
          </StyledOutput>
        ) : (
          <StyledOutput>
            {result &&
              result
                .split('\n')
                .map((line) => (
                  <StyledCode style={getLineColor(result.status, line)}>
                    {line}
                  </StyledCode>
                ))}
            <StyledCode>
              <StyledLoader>
                Submitting
                <StyledCircularLoader />
              </StyledLoader>
            </StyledCode>
          </StyledOutput>
        )}
      </StyledPage>
    </>
  )
}

const Result = ({ state: { result, err }, setState }) => {
  return (
    <StyledPage square>
      <FileUploadResults
        error={err}
        uploadedResults={result}
        onDone={() => setState(INIT_STATE)}
      />
    </StyledPage>
  )
}

export default function DataImportScripts() {
  const { script } = useParams()
  const { config } = useFetchTaskConfigs()
  const [state, setState] = useState(INIT_STATE)

  const integration = config[script]
  const prevIntegration = usePrevious(integration)

  useEffect(() => {
    if (
      isNil(prevIntegration) ||
      isEqual(state, INIT_STATE) ||
      isEqual(integration, prevIntegration)
    ) {
      return
    }

    // reset state if user navigates away from current job
    setState(INIT_STATE)
  }, [integration, prevIntegration, state])

  if (isNil(integration)) {
    return <CatchAll />
  }

  const breadCrumbs = [
    {
      name: 'Dashboard',
      route: DASHBOARD_ROOT_URI,
    },
    {
      name: integration.name,
      route: null,
    },
  ]

  const { activeStep } = state

  const props = {
    state,
    setState,
    script,
    config: integration,
  }

  return (
    <>
      <BreadcrumbsNavigation crumbs={breadCrumbs} />
      {activeStep === 0 && <Upload {...props} />}
      {activeStep === 1 && <PendingScreen {...props} />}
      {activeStep >= 2 && <Result {...props} />}
    </>
  )
}
