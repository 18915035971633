import DeliverySchedule from 'app/components/Icons/Products/DeliverySchedule'
import OrderGuide from 'app/components/Icons/Products/OrderGuide'
import Tag from 'app/components/Tag/Tag'
import { addDuration, formatDatetime, getISODateString } from 'app/helpers/date'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import useDateRangeSearchParam from 'app/packages/storeSolutions/schedules/hooks/useDateRangeSearchParam'
import { NUM_OF_DAYS } from 'app/packages/storeSolutions/schedules/pages/Schedules.constants'
import { StoreScheduleRow } from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import React, { useMemo } from 'react'
import { Column } from 'react-table'
import styled from 'styled-components'

const StyledTableHeader = styled.div<{
  $isThresholdDate: boolean
  $isEditing: boolean
}>`
  align-items: center;
  border-left: ${({ theme }) => `1px solid ${theme.colors.neutral_05}`};
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
  width: 100%;
  position: relative;

  ${({ $isThresholdDate, $isEditing, theme }) =>
    $isThresholdDate &&
    $isEditing &&
    `
    ::after {
      border-right: 6px solid ${theme.colors.neutral_03};
      content: '';
      height: calc(100%);
      left: 1px;
      position: absolute;
      width: 100%;
      z-index: 1; 
    }`};
`

const StyledTableDateHeadMMDD = styled.span`
  font-weight: normal;
  margin-top: -4px;
`

export const CELL_DISPLAY_VALUE: { [key: string]: React.ReactNode } = {
  CLOSED: <Tag text="Closed" />,
  DELIVERY: <DeliverySchedule fontSize="sm" />,
  ORDER: <OrderGuide fontSize="sm" />,
}

export default function useGetTableColumns() {
  const { editingCutoffDate, isEditing } = useGetOrderDeliverySchedule()
  const [{ startDate }] = useDateRangeSearchParam()

  return useMemo(() => {
    return [
      {
        accessor: 'store',
        Header: 'Store',
      },
      {
        accessor: 'schedule',
        Header: () => {
          return [...Array(NUM_OF_DAYS)].map((_, i) => {
            const date = addDuration(startDate, i, 'days')

            return (
              <StyledTableHeader
                key={date.toString()}
                $isThresholdDate={getISODateString(date) === editingCutoffDate}
                $isEditing={isEditing}>
                <span>{formatDatetime(date, { format: 'ddd' })}</span>
                <StyledTableDateHeadMMDD>
                  {formatDatetime(date, { format: 'MM-DD' })}
                </StyledTableDateHeadMMDD>
              </StyledTableHeader>
            )
          })
        },

        width: 'auto',
      },
    ] as Column<StoreScheduleRow>[]
  }, [startDate, isEditing, editingCutoffDate])
}
