import { useVars } from 'app/VarsContext'
import { getDefaultHeaders } from 'app/utils'
import axios from 'axios'
import { delay, isEmpty } from 'lodash-es'
import { useEffect, useState } from 'react'
import { TaskConfigs } from './dashboard.types'

const MAX_FETCH_RETRIES = 5
const BASE_RETRY_WAITING_PERIOD = 500

let taskConfigsCache: TaskConfigs = {}

export default function useFetchTaskConfigs() {
  const [isLoading, setIsLoading] = useState(false)
  const [errored, setErrored] = useState(false)
  const { apiUrl } = useVars()

  useEffect(() => {
    async function fetchAllTaskConfigs() {
      try {
        const { data } = await axios({
          headers: getDefaultHeaders(),
          method: 'GET',
          url: `${apiUrl}/file-uploads/task-configs/all`,
        })

        taskConfigsCache = data
      } catch (e) {
        throw new Error('Unable to fetch all task configs. Please try again.')
      }
    }

    async function retryFetchAllTaskConfigs() {
      setIsLoading(true)

      for (let i = 1; i <= MAX_FETCH_RETRIES; i++) {
        try {
          await fetchAllTaskConfigs()
          break
        } catch (e) {
          if (i === MAX_FETCH_RETRIES) {
            setErrored(true)
          } else {
            await new Promise((resolve) =>
              delay(resolve, BASE_RETRY_WAITING_PERIOD * i),
            )
          }
        } finally {
          setIsLoading(false)
        }
      }
    }

    if (isEmpty(taskConfigsCache)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      retryFetchAllTaskConfigs()
    }
  }, [apiUrl])

  return { config: taskConfigsCache, isLoading, errored }
}
