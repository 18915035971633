import { gql } from '@apollo/client'

export const CONFIGURATION_ROW = gql`
  fragment ConfigurationRow on Configuration {
    _id
    value
    configurationType {
      name
    }
    store {
      _id
      name
    }
    workflow {
      _id
      name
    }
    createdAt
    updatedAt
  }
`
