import { createSvgIcon } from '@mui/material/utils'
import React from 'react'

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 18.0305V16.7705C12 16.0005 14.54 15.0105 16.52 15.0105C18.5 15.0105 21.04 16.0005 21.04 16.7705V18.0305H12ZM2.95996 18.0305V16.5205C2.95996 15.2805 6.43996 14.0205 8.95996 14.0105C8.14996 15.0505 7.98996 16.1105 7.98996 16.7805V18.0405H2.95996V18.0305ZM16.52 11.0005C15.69 11.0005 15.01 10.3205 15.01 9.49047C15.01 8.66047 15.69 7.98047 16.52 7.98047C17.35 7.98047 18.02 8.66047 18.02 9.49047C18.02 10.3205 17.35 11.0005 16.52 11.0005ZM8.98996 10.0005C7.87996 10.0005 6.97996 9.10047 6.97996 7.99047C6.97996 6.88047 7.87996 5.98047 8.98996 5.98047C10.1 5.98047 10.99 6.88047 10.99 7.99047C10.99 9.10047 10.09 10.0005 8.98996 10.0005Z"
      fill="currentColor"
    />
    <path
      d="M8.98996 6.97021C9.54996 6.97021 9.98996 7.41022 9.98996 7.98022C9.98996 8.55022 9.54996 8.99021 8.98996 8.99021C8.42996 8.99021 7.97996 8.54022 7.97996 7.98022C7.97996 7.42022 8.42996 6.97021 8.98996 6.97021ZM16.52 8.98021C16.8 8.98021 17.02 9.20021 17.02 9.49021C17.02 9.78021 16.8 10.0002 16.52 10.0002C16.24 10.0002 16.01 9.77022 16.01 9.49021C16.01 9.21021 16.24 8.98021 16.52 8.98021ZM7.21996 15.2002C7.05996 15.7002 6.97996 16.2302 6.97996 16.7702V17.0302H3.95996V16.5802C4.24996 16.1102 5.69996 15.5002 7.22996 15.1902M16.53 16.0002C17.83 16.0002 19.56 16.5502 20.05 16.9802V17.0202H13V16.9802C13.49 16.5402 15.23 16.0002 16.52 16.0002M8.98996 4.97021C7.31996 4.97021 5.97996 6.32022 5.97996 7.98022C5.97996 9.64021 7.32996 10.9902 8.98996 10.9902C10.65 10.9902 11.99 9.64021 11.99 7.98022C11.99 6.32022 10.65 4.97021 8.98996 4.97021ZM16.52 6.98022C15.13 6.98022 14.01 8.10021 14.01 9.49021C14.01 10.8802 15.13 12.0002 16.52 12.0002C17.91 12.0002 19.02 10.8802 19.02 9.49021C19.02 8.10021 17.91 6.98022 16.52 6.98022ZM8.98996 13.0102C6.64996 13.0102 1.95996 14.1802 1.95996 16.5202V19.0302H8.98996V16.7702C8.98996 15.9202 9.31996 14.4202 11.37 13.2902C10.5 13.1102 9.64996 13.0102 8.98996 13.0102ZM16.52 14.0102C14.68 14.0102 11 14.9302 11 16.7702V19.0302H22.05V16.7702C22.05 14.9302 18.36 14.0102 16.53 14.0102H16.52Z"
      fill="currentColor"
    />
  </svg>,
  'Users',
)
