import { useReactiveVar } from '@apollo/client'
import { OrderDeliveryEventForm } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/OrderDeliveryEventForm'
import { StoreCalendar } from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/StoreCalendar'
import StoreName from 'app/packages/storeSolutions/schedules/pages/OrdersAndDeliveriesTable/RowGroup/StoreName'
import {
  Order,
  StoreScheduleRow,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import { $schedulesBulkEditCalendarStores } from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import React from 'react'
import { Row } from 'react-table'
import styled from 'styled-components'

const RowContainer = styled.div`
  width: 100%;
`
export interface RowGroupProps {
  activeOrder: Order | null
  onChangeActiveOrder: (order: Order | null) => void
  row: Row<StoreScheduleRow>
}

export default function RowGroup({
  activeOrder,
  onChangeActiveOrder,
  row,
}: RowGroupProps) {
  const storeSchedule = row.original
  const bulkEditStores = useReactiveVar($schedulesBulkEditCalendarStores)

  return (
    <>
      {row.cells.map((_, cellIndex: number) => {
        const rowGroupId = `${row.id}_${cellIndex}`
        return (
          <RowContainer key={rowGroupId}>
            {cellIndex === 0 && (
              <StoreName
                addForm={
                  <OrderDeliveryEventForm
                    storeId={storeSchedule.store.id}
                    storeScheduleId={storeSchedule.storeScheduleId}
                  />
                }
                ariaLabel={`Add a new order and delivery to ${storeSchedule.store.name}`}
                bulkEditStores={bulkEditStores}
                store={storeSchedule.store}
                storeScheduleId={storeSchedule.storeScheduleId}
                onSelectStores={(stores) =>
                  $schedulesBulkEditCalendarStores(stores)
                }
              />
            )}
            {cellIndex > 0 && (
              <StoreCalendar
                activeOrder={activeOrder}
                itemHeight={storeSchedule.itemHeight}
                schedule={storeSchedule.schedule}
                storeId={storeSchedule.store.id}
                storeName={storeSchedule.store.name}
                onChangeActiveOrder={onChangeActiveOrder}
                orders={storeSchedule.orders}
              />
            )}
          </RowContainer>
        )
      })}
    </>
  )
}
