import {
  Store,
  StoreRecurringSchedule,
  StoreSchedule,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.types'
import {
  $schedulesBulkEditCalendarMatchingEvents,
  $schedulesBulkEditRecurringMatchingEvents,
} from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'

function getMatchingEventsForCalendar(
  bulkEditStores: Store[],
  storeScheduleRows: StoreSchedule[],
): { [eventId: string]: string[] } {
  const bulkEditData = storeScheduleRows.filter((storeSchedule) =>
    bulkEditStores.some((s) => s.id === storeSchedule.store.id),
  )
  const visibleStore = storeScheduleRows.find(
    (storeSchedule) => storeSchedule.store.id === bulkEditStores[0].id,
  )

  // We don't expect this to ever happen
  if (!visibleStore) {
    throw new Error(`Unable to find data for store ${bulkEditStores[0].name}`)
  }

  // The selected stores should be exactly matching in schedules, so
  // we assume that their recurring order arrays are ordered in the
  // same way. Only the first store's recurring orders and shown, we
  // need to be able to look up the orders of the other stores that
  // correspond to those.
  return visibleStore.orders.reduce(
    (matchingEventsMap, orderDeliveryEvent, i) => {
      // eslint-disable-next-line no-param-reassign
      matchingEventsMap[orderDeliveryEvent.id] = bulkEditData.map(
        (matchingStoreSchedules) => {
          const matchingOrder = matchingStoreSchedules.orders[i]

          // This would only happen if the recurring orders data is stale
          if (
            !matchingOrder ||
            matchingOrder.order.date !== orderDeliveryEvent.order.date ||
            matchingOrder.delivery.date !== orderDeliveryEvent.delivery.date
          ) {
            throw new Error(
              'Something went wrong with finding matching schedules, please refresh the page and try again.',
            )
          }

          return matchingOrder.id
        },
      )

      return matchingEventsMap
    },
    {} as { [eventId: string]: string[] },
  )
}

export function useSetBulkEditMatchingEventsForCalendar(
  bulkEditStores: Store[],
  storeSchedules: StoreSchedule[],
  loading: boolean,
) {
  const { enqueueSnackbar } = useSnackbar()
  useEffect(() => {
    if (loading) {
      return
    }

    if (bulkEditStores.length === 0) {
      $schedulesBulkEditCalendarMatchingEvents({})
    } else {
      try {
        const matchingEvents = getMatchingEventsForCalendar(
          bulkEditStores,
          storeSchedules,
        )

        $schedulesBulkEditCalendarMatchingEvents(matchingEvents)
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message)
        }
      }
    }
  }, [bulkEditStores, enqueueSnackbar, loading, storeSchedules])
}

function getMatchingEvents(
  bulkEditStores: Store[],
  recurringOrdersData: StoreRecurringSchedule[],
): { [eventId: string]: string[] } {
  const bulkEditData = recurringOrdersData.filter((storeRecurringSchedule) =>
    bulkEditStores.some((s) => s.id === storeRecurringSchedule.store.id),
  )
  const visibleStore = recurringOrdersData.find(
    (storeRecurringSchedule) =>
      storeRecurringSchedule.store.id === bulkEditStores[0].id,
  )

  // We don't expect this to ever happen
  if (!visibleStore) {
    throw new Error(`Unable to find data for store ${bulkEditStores[0].name}`)
  }

  // The selected stores should be exactly matching in schedules, so
  // we assume that their order delivery arrays are ordered in the
  // same way. Only the first store's order delivery is shown, we
  // need to be able to look up the orders of the other stores that
  // correspond to those.
  return visibleStore.recurringOrders.reduce(
    (matchingEventsMap, recurringOrder, i) => {
      // eslint-disable-next-line no-param-reassign
      matchingEventsMap[recurringOrder.id] = bulkEditData.map(
        (matchingStoreSchedules) => {
          const matchingOrder = matchingStoreSchedules.recurringOrders[i]

          // This would only happen if the data is stale
          if (
            !matchingOrder ||
            matchingOrder.orderDay !== recurringOrder.orderDay ||
            matchingOrder.deliveryDay !== recurringOrder.deliveryDay
          ) {
            throw new Error(
              'Something went wrong with finding matching schedules, please refresh the page and try again.',
            )
          }

          return matchingOrder.id
        },
      )

      return matchingEventsMap
    },
    {} as { [eventId: string]: string[] },
  )
}

export function useSetBulkEditMatchingEventsForRecurring(
  bulkEditStores: Store[],
  recurringOrders: StoreRecurringSchedule[],
) {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (bulkEditStores.length === 0) {
      $schedulesBulkEditRecurringMatchingEvents({})
    } else {
      try {
        const matchingEvents = getMatchingEvents(
          bulkEditStores,
          recurringOrders,
        )

        $schedulesBulkEditRecurringMatchingEvents(matchingEvents)
      } catch (e) {
        if (e instanceof Error) {
          enqueueSnackbar(e.message)
        }
      }
    }
  }, [bulkEditStores, enqueueSnackbar, recurringOrders])
}
