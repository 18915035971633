const fileUploadLimits = {
  fieldNameSize: 100,
  fieldSize: 10000,
  fields: 5,
  fileSize: 10000000, // 10 MB
  files: 5,
  headerPairs: 10,
}

export default fileUploadLimits
