import { gql } from '@apollo/client'

export const GUIDE_STRUCTURE_ITEM_FIELDS = gql`
  fragment GuideStructureItemFields on GuideStructureItem {
    _id
    afreshItemId
    afreshItemName
    category {
      _id
      id
      name
    }
    categoryReason
    class {
      _id
      description
    }
    hasBeenEdited
    orderableOptions {
      _id
      caseSize
      caseUnits
      name
      orderGuideDisplayName
      orderCode
    }
    subclass {
      _id
      description
    }
  }
`
