import {
  AvailableProduct,
  GetDepartmentBundlesQuery,
  Product,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import { CustomerId } from 'app/CustomerContext'
import { DEPARTMENT_BUNDLE_FIELDS } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/fragments/departmentBundleFields'
import { useGetAvailableProducts } from 'app/packages/internal/customerConfigs/departmentBundleManager/api/queries/useGetAvailableProducts'
import { compact } from 'lodash-es'

export const GET_CONFIG_DEPARTMENT_BUNDLES = gql`
  ${DEPARTMENT_BUNDLE_FIELDS}
  query getDepartmentBundles($customerId: ID!) {
    node(id: $customerId) {
      ... on Customer {
        departmentBundles {
          ...DepartmentBundleFields
        }
      }
    }
  }
`

export interface DepartmentBundleRow {
  _id: string
  id: string
  name: string
  type: string
  products: AvailableProduct[] | Product[]
  departments?:
    | {
        _id: string
        name: string
      }[]
    | null
}

export function useGetDepartmentBundles({
  customerId,
}: {
  customerId: CustomerId | undefined
}) {
  const { data, loading, refetch } = useQuery<GetDepartmentBundlesQuery>(
    GET_CONFIG_DEPARTMENT_BUNDLES,
    {
      variables: { customerId },
      skip: !customerId,
    },
  )
  const {
    products: availableProducts,
    loading: availableProductsLoading,
  } = useGetAvailableProducts()

  if (
    availableProducts.length > 0 &&
    !availableProductsLoading &&
    data &&
    data.node &&
    data.node.__typename === 'Customer' &&
    data.node?.departmentBundles &&
    data.node?.departmentBundles?.length > 0
  ) {
    return {
      rows: data.node?.departmentBundles.map((bundle) => ({
        ...bundle,
        // We want to remap the products from the department bundle to the non-customer specific
        // available products. This is because the update API expects the non-customer specific
        // product ids, and its simpler to do the mapping here rather than in the component.
        products: compact(
          bundle.products.map((product) =>
            availableProducts.find((p) => p.type === product.type),
          ),
        ),
      })),
      refetch,
      loading,
    }
  }

  return {
    rows: [],
    loading: loading || availableProductsLoading,
    refetch,
  }
}
