import {
  CreateOrderDeliveryRecurringSchedulesMutation,
  CreateOrderDeliveryRecurringSchedulesMutationVariables,
  DayOfWeek,
} from '@api/__gen__/gql'
import { gql, useMutation } from '@apollo/client'
import useWorkflowId from 'app/hooks/useWorkflowId'
import { getFirstErrorCode } from 'app/packages/core/api/helpers'
import { SCHEDULE_ERRORS } from 'app/packages/storeSolutions/schedules/api/errors'
import useGetOrderDeliverySchedule from 'app/packages/storeSolutions/schedules/api/queries/useGetOrderDeliverySchedule'
import { useCallback } from 'react'
import tuple from 'shared/tuple'

export const CREATE_RECURRING_ORDER_DELIVERY_SCHEDULES = gql`
  mutation createOrderDeliveryRecurringSchedules(
    $scheduleId: ID!
    $recurringSchedules: [NewOrderDeliveryRecurringScheduleArgs!]!
  ) {
    createOrderDeliveryRecurringSchedules(
      input: {
        scheduleId: $scheduleId
        recurringSchedules: $recurringSchedules
      }
    ) {
      orderDeliveryRecurringSchedules {
        _id
        deliveryDay
        orderDay
        deliveryLag
      }
    }
  }
`

export default function useCreateRecurringSchedules() {
  const { scheduleId } = useGetOrderDeliverySchedule()
  const [workflowId] = useWorkflowId()

  const [mutation, result] = useMutation<
    CreateOrderDeliveryRecurringSchedulesMutation,
    CreateOrderDeliveryRecurringSchedulesMutationVariables
  >(CREATE_RECURRING_ORDER_DELIVERY_SCHEDULES, {
    refetchQueries: ['recurringOrders', 'getOrderDeliveryScheduleIssues'],
    awaitRefetchQueries: true,
  })

  return tuple([
    useCallback(
      async (
        orderDay: DayOfWeek,
        deliveryDay: DayOfWeek,
        storeIds: string[],
      ) => {
        const { errors } = await mutation({
          variables: {
            scheduleId: scheduleId!,
            recurringSchedules: storeIds.map((storeId) => {
              return {
                deliveryDay,
                orderDay,
                storeId,
                workflowId: workflowId!,
              }
            }),
          },
        })

        if (errors?.length) {
          throw new Error(SCHEDULE_ERRORS[getFirstErrorCode(errors)])
        }
      },
      [mutation, scheduleId, workflowId],
    ),
    result,
  ])
}
