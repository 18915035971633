import { gql } from '@apollo/client'

export const BUSINESS_LEVEL_RESULT = gql`
  fragment BusinessLevelResult on BusinessLevelConfig {
    businessLevel {
      id
      name
    }
    displayName {
      id
      name
    }
    createdAt {
      id
      name
    }
    updatedAt {
      id
      name
    }
    updatedBy {
      id
      name
    }
  }
`
