import {
  GetTypicalDeliveryLagQuery,
  GetTypicalDeliveryLagQueryVariables,
} from '@api/__gen__/gql'
import { gql, useQuery } from '@apollo/client'
import tuple from 'shared/tuple'

export const GET_TYPICAL_DELIVERY_LAG = gql`
  query getTypicalDeliveryLag($storeWorkflowScheduleId: ID!) {
    node(id: $storeWorkflowScheduleId) {
      _id
      ... on OrderDeliveryStoreWorkflowSchedule {
        _id
        typicalDeliveryLag
      }
    }
  }
`

export default function useGetTypicalDeliveryLag(storeScheduleId?: string) {
  const { data, loading } = useQuery<
    GetTypicalDeliveryLagQuery,
    GetTypicalDeliveryLagQueryVariables
  >(GET_TYPICAL_DELIVERY_LAG, {
    skip: !storeScheduleId,
    variables: {
      storeWorkflowScheduleId: storeScheduleId!,
    },
  })

  const typicalDeliveryLag =
    data &&
    data.node &&
    data.node.__typename === 'OrderDeliveryStoreWorkflowSchedule'
      ? data.node.typicalDeliveryLag
      : 2

  return tuple([typicalDeliveryLag, loading])
}
