import { useReactiveVar } from '@apollo/client'
import { BulkEditDoneButton } from 'app/packages/storeSolutions/schedules/pages/BulkEdit/BulkEditDoneButton'
import { $schedulesBulkEditCalendarStores } from 'app/packages/storeSolutions/schedules/pages/Schedules.variables'
import React from 'react'
import styled from 'styled-components'
import { OrdersAndDeliveriesDatePicker } from './DatePicker/DatePicker'

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  position: relative;
`

const StyledOrdersAndDeliveriesDatePicker = styled(
  OrdersAndDeliveriesDatePicker,
)`
  align-items: center;
  display: flex;
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
`

export interface OrdersAndDeliveriesTableHeaderProps {
  renderMultiSelect: React.ReactNode
}

export function OrdersAndDeliveriesTableHeader({
  renderMultiSelect,
}: OrdersAndDeliveriesTableHeaderProps) {
  const bulkEditStores = useReactiveVar($schedulesBulkEditCalendarStores)
  const isBulkEditing = bulkEditStores.length !== 0

  return (
    <StyledContainer>
      {!isBulkEditing && renderMultiSelect}

      <StyledOrdersAndDeliveriesDatePicker />

      {isBulkEditing && (
        <BulkEditDoneButton
          onDone={() => $schedulesBulkEditCalendarStores([])}
        />
      )}
    </StyledContainer>
  )
}
